<template>
    <div class="step_item">
        <div class="item_tag" v-for="(item,index) of 20" :key="index">
            <Tag title="Attributes" size="big">{{index + 1}} %</Tag>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.step_item{
    .item_info{
        height:100%;
        overflow: auto;
        .info_detail{
            margin-bottom:20px;
            margin-right:20px;
            float: left;
        }
    }
    .item_tag{
        margin-right:20px;
        height:70px;
        float: left;
        margin-bottom:20px;
    }
    background:var(--color_white);
    height:400px;
    margin-top:20px;
    border-radius: 12px;
    padding:20px;
    box-sizing: border-box;
    overflow: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
}
</style>