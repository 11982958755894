<template>
    <ModelV1 v-model="show">
        <div class="editor_main" slot="main">
            <div :style="{ height }" class="my-editor"></div>

            <div v-show="upload_file" class="mask">
                <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
            </div>
        </div>
    </ModelV1>
</template>

<script>
export default {
    data() {
        return {
            pintura: null,
            upload_file: false,
            height:"100%"
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        id: {
            type: [String, Number],
            default: '',
        },
    },
    watch: {
        show(n) {
            if (n) {
                this.$nextTick(() => {
                    this.init()
                })
            }
        },
    },
    mounted() {
        if(this.isPhone) {
            this.height = `${document.documentElement.clientHeight * 0.8}px`
        }else {
            this.height = `${document.documentElement.clientHeight}px`
        }
        
    },
    methods: {
        close() {
            this.$emit('change', false)
        },
        async detailGet() {
            try {
                let { dispatch } = this.$store
                await dispatch('compaignDetail', {
                    id: this.id,
                })
            } catch (err) {
                console.log(err)
            }
        },
        async init() {
            let icons = []
            if (this.id) {
                await this.detailGet()
                let { stickers } = this.compaignDetail
                icons = stickers.split(',').filter((item) => {
                    return !!item
                })
            }

            this.pintura = appendDefaultEditor('.my-editor', {
                src: this.url,
                stickers: icons,
                // imageCropAspectRatio: 1 / 1,
                cropSelectPresetFilter: 'landscape',

                cropSelectPresetOptions: [
                    [undefined, 'Custom'],
                    [1, 'Square'],

                    // shown when cropSelectPresetFilter is set to 'landscape'
                    [2 / 1, '2:1'],
                    [3 / 2, '3:2'],
                    [4 / 3, '4:3'],
                    [4 / 1, '4:1'],
                    [16 / 10, '16:10'],
                    [16 / 9, '16:9'],

                    // shown when cropSelectPresetFilter is set to 'portrait'
                    [1 / 2, '1:2'],
                    [2 / 3, '2:3'],
                    [3 / 4, '3:4'],
                    [1 / 4, '1:4'],
                    [10 / 16, '10:16'],
                    [9 / 16, '9:16'],
                ],
                utils: ['crop', 'filter', 'finetune', 'annotate', 'sticker', 'frame', 'redact', 'resize'],
            })

            this.pintura.on('process', async (imageState) => {
                console.log(imageState)
                this.downloadFile(imageState.dest)
            })
        },
        async downloadFile(file) {
            this.upload_file = true

            try {
                let { dispatch } = this.$store
                let result = await dispatch('uploadFile', file)
                let success = result && result.code == 200
                if (success) {
                    this.$emit('update', result.data.url)
                    this.pushMsg('success', this.langJson('Uploading success'))
                } else {
                    this.pushMsg('error', this.langJson('Uploading fail'))
                }
            } catch (err) {
                console.log(err)
            }
            this.upload_file = false
            this.close()
        },
    },
}
</script>
<style lang="scss" scoped>
@import url(@/assets/css/pintura.css);
@import url(@/assets/css/styles.css);
.editor_main {
    .my-editor {
        width: 100vw;
        // height: 100vh;
    }
    .mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        backdrop-filter: blur(5px);
        background-color: rgba($color: #fff, $alpha: 0.3);
    }
    width: 100vw;
    // height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color_white);
    position: relative;
}

@media screen and (max-width: 500px) {
    .editor_main {
        .my-editor {
            width: 100vw;
        }
    }
}
</style>