<template>
    <div class="submit_artwork">
        <Go :prev="langJson('Back')" @prev="backPrev"></Go>
        <div id="top"></div>
        <div class="submit_step">
            <StepRun ref="step">
                <SubmitFirst slot="step1" @next="next"></SubmitFirst>
                <SubmitSecond slot="step2" @next="next" @prev="prev"></SubmitSecond>
                <SubmitThird slot="step3" @next="next" @prev="prev"></SubmitThird>
            </StepRun>
        </div>
    </div>
</template>
<script>
export default {
    provide() {},
    data() {
        return {
            backActive: '',
        }
    },
    created() {
        this.backActive = localStorage.getItem('backActive')
    },
    methods: {
        next() {
            this.$refs.step.next()
            this.handleScroll()
        },
        prev() {
            this.$refs.step.prev()
            this.handleScroll()
        },
        backPrev() {
            if (this.$refs.step.step > 0) {
                this.prev()
            } else {
                if (this.backActive) {
                    let { id } = this.$route.params
                    this.jump_participant_compaign_preview({ id })
                    localStorage.setItem('backActive', '')
                } else {
                    this.back()
                }
            }
        },
        handleScroll() {
            var element = document.getElementById('top')
            element.scrollIntoView() // 参数 false 代表 Bottom
        },
    },
}
</script>
<style lang="scss" scoped>
.submit_artwork {
    .submit_step {
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>
