<template>
  <div>
    <slot name="start"></slot>
    <slot name="end"></slot>
    <div class="block" :class="[globalThemeColor]">
      <el-date-picker @change="getTime" v-model="value"  format="yyyy-MM-dd HH:mm" type="datetime" :placeholder="langJson('Select Date Time')" ref="tiemPick" class="timePicker1"> </el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
    }
  },
  methods: {
    uploadTime() {
      document.querySelector('.timePicker1').querySelector('input').focus()
    },
    getTime(val){
        this.$emit('getEndTime',val)
    },
 
  },
}
</script>

<style lang="scss" scoped>
.timePicker1 {
  position: absolute;
  top: 0px;
  left: 0px;
  :deep(.el-input__inner) {
    width: 0;
    padding: 0;
    border: 0;
  }
  :deep(.el-input__prefix) {
    display: none;
  }
}
.block {
  position: relative;
}
</style>
