import Vue, { onMounted } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'
import download from '@/assets/js/download'
Vue.prototype.$download = download
// 动画
import 'animate.css'

// ElementUI
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, { locale })
Vue.use(ElementUI)
Vue.config.productionTip = false

// mixin引入
import mixin from './mixin'
Vue.mixin(mixin)

// 自动加载自定义组件
let map = require.context('./components', true, /\.vue$/)
map.keys().map((item) => {
    let name = item.split('/').pop().split('.')[0]
    Vue.component(name, map(item).default)
})
// 屏幕适应
let getSize = () => {
    let w = document.documentElement.clientWidth || document.body.clientWidth
    if (w > 1081) {
        store.commit('saveIsPhone', false)
        document.documentElement.style.setProperty('--max_width', '1280px')
    } else if (w <= 1081) {
        store.commit('saveIsPhone', true)
        document.documentElement.style.setProperty('--max_width', 'calc(100vw - 40px)')
        document.documentElement.style.setProperty('--max_form_width', 'calc(100vw - 40px)')
    }
    return w
}
getSize()
window.addEventListener('resize', () => {
    getSize()
})
// 多语言源
Vue.prototype.langSource = {}
// 全局状态
Vue.prototype.$store = {}

Vue.prototype.$bus = new Vue()

// 挂载app
let mountApp = (root) => {
    // 全局状态挂载
    Vue.prototype.$store = store
    // 退出加载状态
    let app = document.getElementById('app')
    app.className = 'app_loading_status app_loading_exit'
    // 页面加载完成,网站内容挂载
    setTimeout(() => {
        new Vue({
            render: (h) => h(root),
            router,
        }).$mount('#app')
    }, 1000)
}

// 导入api 后台配置
import api from '@/api/config'

let langGet = async () => {
    let lang
    let lang_version = await api.langVersion()
    let reGet = localStorage.getItem('lang_version') && localStorage.getItem('lang_sourse') && localStorage.getItem('lang_version') == lang_version.data
    if (reGet) {
        lang = {
            data: JSON.parse(localStorage.getItem('lang_sourse')),
        }
    } else {
        localStorage.setItem('lang_version', lang_version.data)
        lang = await api.getLanguage()
        localStorage.setItem('lang_sourse', JSON.stringify(lang.data))
    }
    if (JSON.stringify(lang.data) != '{}') {
        Vue.prototype.langSource = lang.data
    }
    // lang.data.forEach((item) => {
    //     Vue.prototype.langSource[item.configKey] = item.content
    // })
}

// 替换网站标签内容
let webTab = (title = '', icon = '') => {
    document.title = title
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.rel = 'shortcut icon'
    link.type = 'image/x-icon'
    link.href = icon
    document.getElementsByTagName('head')[0].appendChild(link)
}

let run = async () => {
    let failed = false
    try {
        // 获取网站默认图片配置
        await store.dispatch('configGet')
        let { companyName, webIcon } = store.state.config.defaultConfig
        webTab(companyName, webIcon)
        // 获取多语言
        await langGet()
        await store.dispatch('categoryGet')
        // 获取支持币种
        await store.dispatch('symbolGet')
        // 获取支持主链
        await store.dispatch('chainGet')
        // 获取网站平台合约
        await store.dispatch('contractGet')
        // 获取社交媒体
        await store.dispatch('socialMediaGet')
        if (localStorage.getItem('walletDetail')) {
            store.commit('walletDetail', JSON.parse(localStorage.getItem('walletDetail')))
        }
    } catch (err) {
        console.log(err)
        failed = true
    }
    mountApp(App)
    if (failed) {
        router.replace('/404')
    }
}

run()
