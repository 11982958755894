<template>
    <Model :title="type == 1 ? langJson('Create new NFT') : langJson('Create artwork')" padding="24px 28px" v-model="show">
        <div class="model_main" slot="main">
            <div class="select_box">
                <div :class="['select_item', type == item.type && 'select_active']" v-for="(item, index) in list" :key="index" @click="type = item.type">
                    <div class="item_left">
                        <GI :name="type == item.type ? item.iconActive : item.icon" ws="24" hs="24"></GI>
                        <div class="select_title">{{ item.title }}</div>
                    </div>
                    <GI v-show="type == item.type" name="mint_check" ws="24" hs="24"></GI>
                </div>
            </div>

            <div class="model_btns">
                <div class="model_btn">
                    <BTN class="btn_item" type="detail" bh="52" @handle="close">{{ langJson('Cancel') }}</BTN>
                </div>
                <div class="btn_grap"></div>
                <div class="model_btn">
                    <BTN class="btn_item" bh="52" type="active" @handle="nextRun">{{ langJson('Next') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
            type: 1,
            list: [
                {
                    type: 2,
                    title: this.langJson('Join a campaign'),
                    icon: 'join_campaign',
                    iconActive: 'join_campaign_active',
                },
                {
                    type: 1,
                    title: this.langJson('Mint for self'),
                    icon: 'mint_self',
                    iconActive: 'mint_self_active',
                },
            ],
            show: false,
        }
    },
    props: {
        image: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState({ showMintOrJoin: (state) => state.config.showMintOrJoin }),
        // ...mapState('config', ['showShare']),
    },
    watch: {
        showMintOrJoin(val) {
            this.show = val
        },
        show(val) {
            this.$store.commit('changeshowMintOrJoin', val)
        },
    },
    methods: {
        nextRun() {
            this.close()
            if (this.type == 1) {
                this.jump_participant_mint()
            } else if (this.type == 2) {
                // Join a campaign
                this.jump_participant_campaign_artwork_joinCampaign()
            }
        },
        close() {
            this.$store.commit('changeshowMintOrJoin', false)
            window.closeH5Menu && window.closeH5Menu()
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .select_box {
        .select_item {
            .item_left {
                .select_title {
                    margin-left: 24px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                }
                display: flex;
                align-items: center;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--bg_color);
            padding: 0 24px;
            height: 56px;
            box-sizing: border-box;
            border-radius: var(--borderRadius);
            margin-bottom: 24px;
            cursor: pointer;
            border: 1px solid rgba($color: #000000, $alpha: 0);
        }
        .select_active {
            background: var(--bg4) !important;
            border: 1px solid var(--primary_color);
        }
        margin-top: 32px;
    }
    .nft_preview {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 16px;
        background-color: var(--bg_color);
        margin: 20px 0;
    }
    .tip {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--font_deep_color);
        margin-top: 32px;
        text-align: center;
    }
    .information_item {
        margin-top: 32px;
    }
    .model_btns {
        .btn_grap {
            width: 20px;
        }
        .model_btn {
            flex: 1;
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    box-sizing: border-box;
    width: 502px;
}
@media screen and (max-width: 800px) {
    .model_main {
        /* 内边距为24 */
        /* width: 76.534vw; */
        /* 内外边距 为28 */
        width: 74.4vw;
    }
}
@media screen and (max-width: 1080px) {
    .model_main {
    }
}
</style>
