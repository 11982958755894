<template>
    <div class="foot">
        <div class="foot_center">
            <div class="foot_top">
                <div class="top_left">
                    <div class="logo cursor" :style="logoStyle" @click="jumpHome()"></div>
                    <!-- <div v-if="!isPhone" class="nameLogo">{{ globalImage.companyName }}</div> -->
                </div>
                <div class="top_right">
                    <div class="article_list">
                        <div class="article_item" v-for="(item, index) in list" :key="index" @click="item.path()">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="foot_bottom">
                <div class="bottom_left">
                    {{ langJson('Copyright © 2023 NFTWerks Inc.') }}
                </div>
                <!-- <div class="bottom_right cursor">
                    <div class="foot_icon" v-for="(item, index) in socialMedia" :key="index" @click="openUrl(item.url)">
                        <GI :src="item.logo" ws="24" hs="24"></GI>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        let that = this
        return {
            list: [
                {
                    title: this.langJson('What are NFTs'),
                    path: () => {
                        this.jump_page_path('/startup/index')
                    },
                },
                {
                    title: this.langJson('Use cases of NFT'),
                    path: () => {
                        this.jump_page_path('/startup/article/unleash')
                    },
                },
                {
                    title: this.langJson('About NFTWerks'),
                    path: () => {
                        this.jump_page_path('/startup/article/graphic')
                    },
                },
                {
                    title: this.langJson('Term & Conditions'),
                    path: () => {
                        this.jump_article_detail({ articleId: 5 })
                    },
                },
                {
                    title: 'FAQs',
                    path: () => {
                        this.jump_page_path('/startup/article/faq')
                    },
                },
            ],
        }
    },
    computed: {
        logoStyle() {
            try {
                let { webLogo,webDarknessLogo } = this.globalImage
                if(this.globalTheme != 'light') {
                    webLogo = webDarknessLogo
                }
                return { backgroundImage: `url(${webLogo})`, width: this.isPhone ? `102px` : `52px`, borderRadius: `50%`, height: this.isPhone ? `102px` : `52px` }
            } catch (error) {
                return {}
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.nameLogo {
    margin-left: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    background: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.foot {
    .foot_center {
        .foot_top {
            .top_right {
                flex: 1;
                .article_list {
                    .article_item {
                        margin-left: 24px;
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        cursor: pointer;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
            .top_left {
                width: 100%;
                max-width: 300px;
                margin-right: 50px;
                display: flex;
                align-items: center;

                .desc {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_modif_color);
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
                .logo {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }

            min-height: 144px;
            width: 100%;
            flex: 1;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .foot_bottom {
            .bottom_right {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .foot_icon {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .bottom_left {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
            }
            border-top: 1px solid var(--border_color);
            width: 100%;
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    width: 100%;
    min-height: 244px;
    background-color: var(--bg_color);
    border-top: 1px solid;
    border-image: linear-gradient(to right, var(--modif_color), var(--main_color)) 1;
    padding-top: 24px;
    padding-bottom: 0;
}
@media screen and (max-width: 1080px) {
    .foot {
        .foot_center {
            .foot_top {
                .top_right {
                    .article_list {
                        .article_item {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
        padding-bottom: 82px;
    }
    .nameLogo {
        font-size: 16px;
    }
    .foot_top {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
    }
    .foot_center {
        gap: 24px;
    }
    .top_left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px !important;
    }
    .article_list {
        flex-direction: column;
        gap: 24px;
    }
    .foot_bottom {
        border-top: 0px !important;
        flex-direction: column-reverse;
        min-height: 70px !important;
        .bottom_left {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 16px !important;
            color: var(--font_modif_color) !important;
            opacity: 0.7 !important;
        }
    }
}
</style>
