<template>
    <div :id="components_id" class="group" @mousedown="startPoint" @mouseup="endPoint" @touchstart="startH5" @touchend="endH5">
        <div
            class="group_inner"
            :id="inner_id"
            :style="{
                width: `${fullWidth}px`,
            }"
        >
            <slot></slot>
        </div>
    </div>
</template>
<script>
import move from 'move-js'
export default {
    data() {
        return {
            limit: 0,
            current: 0,
            total: 0,
            width: 0,
            inner_id: '',

            sx: 0,
            sy: 0,
            mx: 0,
            my: 0,
            playElement: null,
        }
    },

    computed: {
        fullWidth() {
            return this.width * this.total
        },
        status() {
            if (Math.abs(this.limit * this.current) < this.fullWidth - this.limit && this.current >= 1) {
                return {
                    next: true,
                    prev: true,
                }
            } else if (Math.abs(this.limit * this.current) < this.fullWidth - this.limit) {
                return {
                    next: true,
                    prev: false,
                }
            } else if (this.current >= 1) {
                return {
                    next: false,
                    prev: true,
                }
            } else {
                return {
                    next: false,
                    prev: false,
                }
            }
        },
    },
    inject: ['regCode'],
    props: {
        steplen: {
            type: Number,
            default: 0,
        },
    },
    created() {
        let code = this.regCode()
        this.components_id = 'group_' + code
        code = this.regCode()
        this.inner_id = 'group_inner_' + code
    },
    mounted() {
        this.init()
        window.addEventListener('resize', () => {
            this.init()
        })
        this.playElement = document.getElementById(this.components_id)
    },
    methods: {
        startPoint(event) {
            this.sx = event.clientX
            this.mx = event.clientX
            this.sy = event.clientY
            this.playElement.onmousemove = this.movePoint
        },
        movePoint(event) {
            this.mx = event.clientX
            this.my = event.clientY
        },
        endPoint(event) {
            let result = this.mx - this.sx
            if (result > 0) {
                Math.abs(result) > 200 && this.prev()
            } else {
                Math.abs(result) > 200 && this.next()
            }
            this.playElement.onmousemove = null
        },

        startH5({ touches }) {
            this.sx = touches[0].clientX
            this.sy = touches[0].clientY
            this.playElement.ontouchmove = this.moveH5
        },
        moveH5({ touches }) {
            this.mx = touches[0].clientX
            this.my = touches[0].clientY
        },
        endH5() {
            let result = this.mx - this.sx
            if (result > 0) {
                Math.abs(result) > 80 && this.prev()
            } else {
                Math.abs(result) > 80 && this.next()
            }
            this.playElement.ontouchmove = null
        },
        init() {
            try {
                let group = document.getElementById(this.components_id)
                if (group) {
                    if (!this.steplen) {
                        this.limit = group.clientWidth
                    } else {
                        this.limit = this.steplen
                    }
                    let inner = document.getElementById(this.inner_id)
                    this.total = inner.childNodes.length
                    if (this.total) {
                        this.width = inner.childNodes[0].getBoundingClientRect().width
                    }
                    if (!this.width) {
                        this.width = this.driveWidth()
                    } else {
                        this.width = this.width + 32
                    }
                }
                move.defaults = {
                    duration: 1000,
                }
                this.update()
            } catch (err) {
                console.log(err)
            }
        },
        update() {
            this.$emit('change', {
                current: this.current,
                ...this.status,
            })
        },
        next() {
            if (this.status.next) {
                this.current += 1
                move(`#${this.components_id} .group_inner`)
                    .x(-this.limit * this.current)
                    .end()
            }
            this.update()
        },
        jump(index) {
            this.current = index
            move(`#${this.components_id} .group_inner`)
                .x(-this.limit * this.current)
                .end()
            this.$emit('change', this.current)
            this.update()
        },
        prev() {
            if (this.status.prev) {
                this.current -= 1
                move(`#${this.components_id} .group_inner`)
                    .x(-this.limit * this.current)
                    .end()
                this.$emit('change', this.current)
            }
            this.update()
        },
    },
}
</script>
<style lang="scss" scoped>
.group {
    .group_inner {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        transition: all 0s;
    }
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    user-select: none;
}
</style>
