<template>
    <div>
        <Go :prev="infoByUserId.nickName" @prev="back">
            <div class="right_go">
                <BTN v-if="!isPhone" @handle="jump_brand_compaign_participants_profile({userId:$route.query?.user,tab:1})" icon_left="eye" type="detail" bh="52" bw="166">{{ langJson('View profile') }}</BTN>

                <BtnPop v-if="isPhone" :left="-100">
                    <div slot="target" class="more_icon">
                        <GI ws="24" hs="24" name="brand_more"></GI>
                    </div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                                <span class="title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
            </div>
        </Go>
        <div v-if="!isPhone" class="line"></div>
        <div class="form">
            <div class="form_box">
                <h2 class="form_title">{{ langJson('Information') }}</h2>
                <div class="form_input">
                    <FormItem v-model="infoByUserId.nickName" :disabled="true" class="information_item" type="text" :title="langJson('Full name')"></FormItem>
                    <FormItem v-model="infoByUserId.email" :disabled="true" class="information_item" type="text" :title="langJson('Email ')"></FormItem>
                </div>
            </div>
        </div>
        <Votegroup :itemPadding="'12px'" :itemWidth="isPhone ? 252 : 276" :title="langJson('Submitted Artwork for ' + $route.query.actName || compaignDetail.name)" :list="campaignArtwork">
            <template v-slot:item="data">
                <Votecard :item="data.item" :hs="isPhone ? 136 : 204"></Votecard>
            </template>
        </Votegroup>
        <JoinedGroup class="joinAlso" :itemWidth="isPhone ? 252 : 405" :itemPadding="isPhone ? '12px' : '16px 24px 24px'" :title="langJson(infoByUserId.nickName +' Also joined')">
            <template v-slot:item="data">
                <JoinedCard :item="data.item" :hs="isPhone ? 124 : 200"></JoinedCard>
            </template>
        </JoinedGroup>
        <div class="place_hold"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tagList: [{ icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'View Profile', number: 1 }],
        }
    },
    created() {
        this.init()
    },
    mounted(){
        if (!this.campaignArtwork || !this.lenArray(this.campaignArtwork)) {
            this.artworkInit()
        }
    },
    methods: {
        async artworkInit() {
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                let result = await dispatch('campaignArtwork', {
                    // pageNum: this.pageNum,
                    // pageSize: this.pageSize,
                    campaignId: idActive,
                    // artworkTitle: , // this.keyword,
                    isAsc: 'desc',
                    // orderByColumn: false // this.tab == 2 && this.sortType,
                })
            } catch (err) {
                console.log(err)
            }
        },
        async init() {
            try {
                let { idActive, user } = this.$route.query
                let { dispatch } = this.$store
                await dispatch('infoByUserId', {
                    userId: user,
                })
                await dispatch('joinCampaignList', {
                    userId: user,
                })
                await dispatch('selectCampaignWorks', {
                    campaignId: idActive,
                    type: 1,
                })
            } catch (err) {
                console.log(err)
            }
        },
        goRoute(item) {
            switch (item.number) {
                case 1:
                    this.jump_brand_compaign_participants_profile({ userId: this.$route.query?.user })
                    break

                default:
                    break
            }
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.line {
    width: 100%;
    border-bottom: 1px solid var(--border_color);
}

.form {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;

    .form_box {
        width: 100%;
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .form_title {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            font-family: 'Inter';
            color: var(--font_deep_color);
        }
        .codename {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font_color_black);

            .optional {
                font-family: 'Lato';
                font-size: 14px;
                line-height: 20px;
                color: #c9ccdd;
            }
        }

        .artname {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
        }

        .information_item {
            width: 100%;
        }

        .form_input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
        }

        .payment {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .eye_box {
                display: flex;
                align-items: center;
                gap: 12px;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--font_modif_color);
                }
            }
        }
    }

    .credit_card {
        width: 100%;
        margin-top: 24px;
        display: flex;
        gap: 32px;

        .left_box {
            width: 592px;

            .bank_account {
                display: flex;
                align-items: center;
                gap: 8px;

                .card {
                    width: 143px;
                    height: 52px;
                    padding: 12px 24px;
                    text-align: center;
                    line-height: 52px;
                    /* Title/3/Regular */
                    font-family: 'Inter';
                    font-weight: 400;
                    font-size: 18px;
                    /* Neutral/3 */
                    color: var(--font_color);
                }
            }

            .information_item {
                width: 100%;
                height: 84px;
            }
        }

        .right_box {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .address {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .cpoy_address {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 40px;
                    color: var(--font_color);
                }
            }
        }
    }
}
.place_hold {
    height: 91px;
}
@media screen and (max-width: 1080px) {
    .form {
        padding: 16px;
        margin-top: 12px !important;
    }
    .form_box {
        gap: 12px !important;
    }
    .more_icon {
        box-sizing: border-box;
        width: 36px;
        height: 36px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .form_title {
        font-size: 16px !important;
        font-family: 'Lato' !important;
    }
    .form_input {
        flex-direction: column;
    }
    .type_main {
        padding: 24px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 24px;
        cursor: pointer;

        .type_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .type_line {
                display: flex;
                align-items: center;
                gap: 8px;

                .title {
                    white-space: nowrap;
                    font-size: 14px;
                    color: var(--font_deep_color);
                }
            }
        }
    }
    .place_hold {
        height: 74px;
    }

    .detail_box{
        .inner_box{
            display: block;
        }
    }
    .joinAlso{
        /deep/ &>.group{
            .group_inner{
                .group_item{
                    .item_main{
                        .item{
                            .item_info{
                                .item_desc{
                                    height: 48px;
                                    -webkit-line-clamp: 3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
