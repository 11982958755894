<template>
    <div id="app">
        <FrameMain>
            <template slot="head">
                <!-- <FrameHead v-if="!isStart && headShow" :navlist="navlist" :headStyle="headStyle" :viewObj="headView" ></FrameHead> -->
                <FrameHead v-if="loginStatus" :navlist="navlist" :headStyle="headStyle" :viewObj="headView" @handleOut="FormatData" v-show="showStartHead"></FrameHead>
                <StartHead v-else v-show="showStartHead" ref="StartHead"></StartHead>
            </template>

            <div class="view_main" id="view_main" slot="main">
                <transition :duration="200" mode="out-in" appear enter-active-class="animated animate__fadeInDown" leave-active-class="animated animate__fadeOutDown">
                    <router-view />
                </transition>

                <FrameFoot v-show="isStart && footShow"></FrameFoot>
            </div>
            <div slot="expand">
                <StartMenu v-model="menuShow"></StartMenu>
                <FrameMenuMobile :navlist="navlist" :viewObj="headView" v-model="menuFrameMobile"></FrameMenuMobile>
            </div>
        </FrameMain>

        <!-- 全局提示 -->
        <GT></GT>
        <!-- 选择Nft铸造页面 -->
        <MintOrJoin></MintOrJoin>
        <WalletLink></WalletLink>
        <!-- 登录提示 -->
        <LoginTip></LoginTip>
        <!-- 分享页面 -->
        <BrandShare></BrandShare>
        <!-- 活动详情投票 个人投票 -->
        <BrandVote></BrandVote>
    </div>
</template>

<script>
import { getCookie } from '@/assets/js/cookie'
export default {
    name: 'App',
    data() {
        return {
            showStartHead: false,
            menuShow: false,
            menuFrameMobile: false,
            headShow: true,
            headStyle: {},
            footShow: true,
            code: 0,
            isStart: false,
            navlist: [],
            brand_nav: [
                {
                    title: this.langJson('My campaigns'),
                    path: '/brand/campaign/index',
                    matching: /^\/brand\/campaign.*$/,
                },
                {
                    title: this.langJson('Clients'),
                    path: '/brand/clients/index',
                    matching: /^\/brand\/clients.*$/,
                },
                // {
                //   title: this.langJson('Resources'),
                //   path: '/brand/resources/index',
                //   matching: /^\/brand\/resources.*$/,
                // },
            ],
            participant_nav: [
                {
                    title: this.langJson('Campaigns'),
                    path: '/participant/campaign/index',
                    matching: /^\/participant\/campaign.*$/,
                },
                {
                    title: this.langJson('My Contacts'),
                    path: '/participant/clients/index',
                    matching: /^\/participant\/clients.*$/,
                },
            ],
            loginStatus: false,
        }
    },
    provide() {
        return {
            showHead: this.showHead,
            showFoot: this.showFoot,
            headViewSet: this.headViewSet,
            resetHeadView: this.resetHeadView,
            regCode: this.regCode,
            headStyleSet: this.headStyleSet,
            resetHeadStyle: this.resetHeadStyle,
            showMenu: this.showMenu,
            showFrameMenu: this.showFrameMenu,
        }
    },
    watch: {
        $route({ path }) {
            if (/^\/startup.*$/.test(path) || path == '/') {
                this.isStart = true
            } else {
                this.isStart = false
            }
            let userType = localStorage.getItem('userType')
            if (/^\/brand.*$/.test(path)) {
                this.navlist = this.brand_nav
            } else if (/^\/participant.*$/.test(path)) {
                this.navlist = this.participant_nav
            } else if (userType == 1) {
                this.navlist = this.brand_nav
            } else if (userType == 0) {
                this.navlist = this.participant_nav
            }
            this.menuShow = false
            this.routerChangeHeard(path)
            this.notLogHeard(path)

            this.loginStatus = false
            if (getCookie('token')) {
                this.loginStatus = true
            }
        },
    },
    beforeMount() {
        this.routerChangeHeard(this.$route.path)
        this.notLogHeard(this.$route.path)
    },
    created() {
        this.initState()
        // this.autoConnect()
    },
    mounted() {
        // this.$bus.$off('showFoot')
        // this.$bus.$on('showFoot', (footShow) => {
        //     console.log('showFoot', this.footShow)
        //     this.footShow = footShow
        //     this.isStart = footShow
        // })
    },

    methods: {
        FormatData(val) {
            if (val === 'quit') {
                this.loginStatus = false
            }
        },
        async initState() {
            let token = getCookie('token')
            let { dispatch } = this.$store
            if (token) {
                await dispatch('userGet')
            }
        },

        // 未登录也不显示头部
        notLogHeard(path) {
            let isNoHeardPathList = ['/brand/login', '/brand/signup', '/brand/company', '/brand/resetpsd', '/participant/login', '/participant/resetpsd', '/participant/signup', '/brand/campaign/edit/plan']
            let isNoHeard = isNoHeardPathList.some((item) => {
                return item == path
            })
            if (isNoHeard) {
                this.showStartHead = false
            } else {
                this.showStartHead = true
            }
        },
        routerChangeHeard(path) {
            // 监听路由变化 来判断是否显示头部
            let isNoHeardPathList = ['/brand/login', '/brand/signup', '/brand/company', '/brand/resetpsd', '/participant/login', '/participant/resetpsd', '/participant/signup', '/brand/campaign/edit/plans'].concat(['/startup/index', '/startup/article/whatNft', '/startup/article/unleash', '/startup/article/graphic', '/startup/article/detail', '/startup/article/faq']).concat(['/404'])
            // /brand/compaign/edit/plan
            let isNoHeard = isNoHeardPathList.some((item) => {
                return item == path
            })
            if (isNoHeard) {
                this.showHead(false)
            } else {
                this.showHead(true)
            }
        },
        showMenu(status) {
            this.menuShow = status
        },
        showFrameMenu(status) {
            this.menuFrameMobile = status
        },
        showHead(status) {
            this.headShow = status
        },
        headViewSet(obj) {
            this.headView = Object.assign(this.headView, obj)
        },
        headStyleSet(obj) {
            this.headStyle = Object.assign(this.headStyle, obj)
        },
        resetHeadStyle() {
            this.headStyle = {}
        },
        resetHeadView() {
            this.headView = Object.assign(this.headView, {
                menu: false,
                logo: true,
                search: true,
                link: true,
                create: true, //true用户 false 商户
                portrait: true,
                wallet: true,
                theme: true,
            })
        },
        showFoot(status) {
            this.footShow = status
        },
        regCode() {
            this.code += 1
            return this.code
        },
    },
}
</script>

<style lang="scss">
#app {
    .view_main {
        height: 100%;
        overflow: auto;
    }
}
</style>
