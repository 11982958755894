<template>
    <div :class="['tag',type,size]" @click="$emit('click')">
        <div class="tag_txt">
            <div v-if="title" class="hide">{{title}}</div>
            <slot></slot>
        </div>
        <div class="icon" v-if="icon">
            <GI :name="icon" ws="20" hs="20"></GI>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        icon:{
            type:String,
            default:""
        },
        type:{
            type:String,
            default:"default"
        },
        title:{
            type:String,
            default:""
        },
        size:{
            type:String,
            default:"mini"
        }
    }
}
</script>
<style lang="scss" scoped>
.tag{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 8px;
    width:100%;
    cursor: pointer;
    .icon{
        margin-left:20px;
    }
    .tag_space{
        width:4px;
    }
    .tag_txt{
        .hide{
           color:var(--font_modif_color);
           margin-bottom:2px;
        }
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        box-sizing: border-box;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.mini{
    padding: 8px 16px;
    
}
.medium{
    padding: 10px 22px;
    .tag_txt{
        .hide{
           margin-bottom:4px;
        }
        font-size: 14px;
        line-height: 18px;
    }
}
.big{
    padding: 16px 28px;
    height:80px;
    box-sizing: border-box;
    .tag_txt{
        .hide{
           margin-bottom:8px;
        }
        font-size: 16px;
        line-height: 20px;
    }
}
.warning{
    background: #FFEAE0;
    .tag_txt{
        color: var(--warning_color);
    }
}
.primary{
    background: #E0F0FF;
    .tag_txt{
        color: var(--primary_color);
    }
}

.danger{
    background: #FFE0E0;
    .tag_txt{
        color: var(--primary_color);
    }
}

.default{
    background-color: rgba($color: #ccc, $alpha: 0.2);
    .tag_txt{
        color: var(--font_deep_color);
    }
}

.success{
    background: #E7F8E8;
    .tag_txt{
        color: var(--success_color);
    }
}
</style>