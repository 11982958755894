<template>
    <div>
        <div class="item" :style="{ padding: `${itemPadding}` }">
            <div class="image_out cursor" :style="{ height: `${hs}px`, width: `${ws}px` }">
                <div class="item_image" :style="{ backgroundImage: `url(${item.artworkImg})` }" @click="isParticipant ? jump_participant_campaign_artwork_detail({ idArt: item.id, idActive: $route.query.idActive, idUser: item.userId }) : jump_brand_compaign_artwork_detail({ idArt: item.id, idActive: $route.query.idActive, idUser: item.userId })"></div>
            </div>
            <div class="item_info">
                <div class="item_title">
                    {{ item.artworkTitle }}
                    <br />
                    <span class="item_subtitle">@{{ item.artworkCredit }}</span>
                </div>
                <div v-if="showFestYear && isPhone" class="fest_year">LUMA Projection Arts Festival: Year 8</div>
                <div class="item_desc">
                    <span>{{ item.voteNum }} {{ langJson('votes') }}</span>
                    <BTN v-show="isParticipant" @handle="vote(item)" type="detail" bw="88" bh="36" :class="[item.voteFlag && 'voted']" borderWidth='1' size="mini">{{ langJson('Vote') }}</BTN>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        itemPadding: {
            type: String,
            default: '',
        },
        showFestYear: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            active: false,
            voteShow: false,
        }
    },
    created() {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.voted {
    border-color: var(--font3) !important;
    color: var(--font3) !important;
}

.item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 12px;
    gap: 16px;

    .item_info {
        .item_desc {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .fest_year {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #3993e6;
            margin-top: 4px;
        }
        .item_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .item_subtitle {
            /* Body/1/Regular */
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Neutral/3 */

            color: var(--font_color);
        }
        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: space-between;
        height: 32px;
        .rank_vote {
            width: 61px;
            background: #ffeae0;
            border-radius: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            color: #ff9466;
        }
    }

    .image_out {
        border-radius: 12px;
        overflow: hidden;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
.dialog_main {
    width: 422px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    h1 {
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: var(--font_deep_color);
    }
    span {
        text-align: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--font_deep_color);
    }
}
@media screen and (max-width: 1081px) {
    .item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 12px;
        gap: 12px;

        .item_info {
            .item_desc {
                font-family: 'Lato';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;
            }

            .item_title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .item_subtitle {
                /* Body/1/Regular */
                font-family: 'Lato';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                /* Neutral/3 */

                color: var(--font_color);
            }
            flex: 1;
        }
        .brand_detail {
            display: flex;
            justify-content: space-between;
            height: 32px;
            .rank_vote {
                width: 61px;
                background: #ffeae0;
                border-radius: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: #ff9466;
            }
        }

        .image_out {
            border-radius: 20px;
            overflow: hidden;
            background-color: rgba($color: #ccc, $alpha: 0.2);
            .item_image {
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                transition: all 2s;
            }
            .item_image:hover {
                transform: scale(2);
            }
        }
    }
    .dialog_main {
        width: 76.534vw !important;
    }
}
</style>
