<template>
    <div>
        <div class="request">
            <div class="header" @click="$emit('back')">
                <GI name="global_previous" ws="24" hs="15"></GI>
                <h2>{{ langJson('Your submitted requests') }}</h2>
            </div>
            <div class="table_box">
                <div class="table_box_scroll">
                    <TableForRequesthistory :list="commitRecord" :title="title" :commonStyle="true">
                        <template slot="createTime" slot-scope="{ data }">
                            <div>
                                {{ timerIo(data.createTime) }}
                            </div>
                        </template>

                        <template slot="status" slot-scope="{ data }">
                            <div class="status">
                                {{ statusText(data.status) }}
                            </div>
                        </template>

                        <div class="more" slot="foot" v-if="total">
                            <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="false"></Page>
                        </div>
                    </TableForRequesthistory>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            commitRecord: [],
            total: 0,
            pageSize: 24,
            pageNum: 1,
            title: {
                createTime: {
                    title: this.langJson('Date'),
                },
                helpTypeName: {
                    title: this.langJson('Category'),
                    type: 'text',
                },
                description: {
                    title: this.langJson('Message'),
                    type: 'text',
                },
                status: {
                    title: this.langJson('Status'),
                },
            },
        }
    },
    computed: {
        statusText() {
            // 状态 0-待处理 1-已处理
            return (status) => {
                if (status - 0 === 0) {
                    return this.langJson('Pending')
                }
                if (status - 0 === 1) {
                    return this.langJson('Processed')
                }
            }
        },
    },
    created() {
        this.init()
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        // 获取提交记录
        async init() {
            try {
                let { dispatch } = this.$store
                let res = await dispatch('contentHelpRecordUser', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                })
                if (res && res.code == 200) {
                    this.total = res.total
                    this.commitRecord = res.rows
                }
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.more {
    height: 50px;
    background: var(--color_white);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px -5px 10px var(--bg_color);
    padding: 0 20px;
}

.status {
    width: 88.89px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffeae0;
    border-radius: 32px;
    color: #ff9466;
    font-weight: 400;
    font-size: 14px;
}

.request {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    width: 100%;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 32px;
    overflow: auto;
    .header {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
    }
    .table_box {
        width: 100%;
        overflow: auto;
        .table_box_scroll {
            width: 100%;
        }
    }
}
@media screen and (max-width: 1080px) {
    .request {
        box-sizing: border-box;
        max-width: 100vw;
        width: 100%;
        background: var(--color_white);
        border-radius: 32px;
        padding: 20px;
        margin-bottom: 32px;
        .header {
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: pointer;
            h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: var(--font_deep_color);
            }
        }
    }
}
@media screen and (max-width: 750px) {
    .table_box {
        width: 100%;
        overflow: auto;
        .table_box_scroll {
            width: 744px !important;
        }
    }
}
</style>
