<template>
    <div>
        <div class="player_cpntainer">
            <div v-if="detail.imgType == 1" class="player bgCover">
                <Source v-if="detail.descriptionImg" :isDetail="true" :Preview="detail.descriptionImg" :Data="detail.descriptionImg" :type="typeHand(detail.descriptionImg)" fit="cover"></Source>
            </div>
            <div v-if="detail.imgType == 2" class="player bgCover" v-html="detail.descriptionImg"></div>

            <div class="sub_text">
                <!-- <h2 class="descriptions">{{ langJson('Campaign Descriptions') }}</h2> -->
                <h2 class="sub_text_title">
                    <div class="title_bg" :style="{ backgroundImage: `url(/source/png/ellipse_14.png)` }"></div>
                    {{ detail.subtitle }}
                </h2>
                <div class="text v-html" v-html="detail.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        typeHand(val) {
            var resultIndex = val.lastIndexOf('.')
            var srt = (val + '').substring(resultIndex - 0 + 1).toLowerCase()
            return srt
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.player_cpntainer {
    padding: 117px 0;
    width: 100%;
    /* box-sizing: border-box; */
    max-width: var(--max_width);
    margin: auto;
    display: flex;
    align-items: center;
    /* background-color: #fff; */

    .player {
        width: 577px;
        height: 324px;
        border-radius: 8px;
        overflow: hidden;
        *{
            width:100% !important;
            height: 100% !important;
            border-radius: 32px;
        }
        .video_player {
            border-radius: 32px;
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    .sub_text {
        width: 623px;
        /* height: 224px; */
        margin-left: 113px;

        .descriptions {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            background-image: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
            -webkit-background-clip: text;
            color: transparent;
            font-family: 'Lato';
        }

        .sub_text_title {
            display: inline-block;
            position: relative;
            word-break: break-all;
            font-family: 'Lato';
            font-weight: 700;
            font-size: 56px;
            line-height: 64px;
            color: var(--font_deep_color);

            /* width:100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; */
            .title_bg {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                height: 180px;
                width: 278px;
                position: absolute;
                top: 50%;
                /* top: calc(50% - 30px); */
                left: 50%;
                transform: translate(-50%, -50%);
                @media screen and (max-width: 1259px) {
                    top: calc(50% - 30px);
                }
                @media screen and (max-width: 1080px) {
                    top: 50%;
                    height: 90px;
                    width: 200px;
                }
            }
        }

        .text {
            margin-top: 31px;
            font-family: 'Lato';
            font-weight: 400;
            line-height: 24px;
            font-size: 16px;
            color: var(--font_color);
        }
    }
}

@media screen and (max-width: 1080px) {
    .player_cpntainer {
        margin-top: 55px;
        flex-direction: column;
        padding: 0px;
        height: auto;
        gap: 28px;
        /* margin-bottom: 40px; */
        .sub_text {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            gap: 4px;
            margin-left: 0px;
            width: 100%;
            height: auto;
            margin-top: 48px;
            .sub_text_title {
                position: relative;
                text-align: left;
                font-size: 32px;
                line-height: 40px;
                .title_bg {
                }
            }
            .text {
                margin-top: 24px;
                padding-bottom: 52px;
            }
        }

        .player {
            width: 335px;
            height: 188px;
            border-radius: 4px;
            .video_player {
                border-radius: 20px;
            }
        }
    }
}
</style>
