<template>
    <div class="information">
        <div class="title">{{langJson("Personal information")}}</div>
        <FormItem v-model="form.image" class="information_item" 
        type="image" :title="langJson('Profile picture')"></FormItem>
        
        <FormItem v-model="form.banner" class="information_item" 
        type="image" :image="imageConfig" :title="langJson('Introduction cover')"></FormItem>
        
        <FormItem v-model="form.name" class="information_item" 
        type="text" :title="langJson('Name')" :placeholder="langJson('Please enter a name')"></FormItem>
        
        <FormItem v-model="form.bio" class="information_item" 
        type="text" :title="langJson('Bio')" :placeholder="langJson('Please enter Bio')"></FormItem>
        
        <FormItem v-model="form.email" class="information_item" 
        type="text" :title="langJson('Email')" :placeholder="langJson('Please enter a Email')"></FormItem>
        
        <FormItem v-model="form.phone" class="information_item" 
        type="text" :title="langJson('Phone number')" :placeholder="langJson('Please enter Phone number')"></FormItem>

        <div class="btns">
            <BTN class="btn_item" type="active">{{langJson("Save change")}}</BTN>
            <!-- <BTN class="btn_item" type="main">{{langJson("Change password")}}</BTN> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form:{
                image:"",
                banner:"",
                name:"",
                bio:"",
                email:"",
                phone:""
            },
            imageConfig:{
                type:"big"
            }
        }
    },
    created() {
        this.$watch("form",(n) => {
            console.log(n)
        },{
            deep:true
        })
    }
}
</script>
<style lang="scss" scoped>
.information{
    .btns{
        display: flex;
        align-items: center;
        margin-top:30px;
        .btn_item{
            margin-right:20px;
        }
    }
    .information_item{
        margin-top:32px;
    }
    .title{
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color:var(--font_deep_color);
    }
}
</style>