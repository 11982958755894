<template>
    <div>
        <Go v-if="!isPhone" :prev="langJson('Create multiple NFTs')" @prev="back"></Go>
        <div v-if="!isPhone" class="line"></div>

        <div class="mint_container">
            <div class="mint_top">
                <h2 v-if="!isPhone" class="select_count">{{ langJson('Selected Artworks') }} ({{ globalBrand.mintNftList.length }})</h2>
                <h2 v-if="isPhone" class="mobile_title">{{ langJson('Create multiple NFTs') }}</h2>
                <BTN v-if="!isPhone" type="warn" icon_left="brand_trash" bw="138" :disable="!lenArray(this.selete_list)" @handle="removeHand">{{ langJson('Remove') }}</BTN>
            </div>
            <div class="mint_box">
                <div class="artworks_item" v-for="(item, index) of globalBrand.mintNftList" :key="index">
                    <MintCard @select="selectItem(item)" :item="item" :ws="isPhone ? 224 : 272" hs="204" :selectlist="selete_list"></MintCard>
                </div>
                <NuData height="300" v-if="!lenArray(globalBrand.mintNftList)" />
            </div>
            <BTN v-if="isPhone" type="warn" icon_left="brand_trash" bw="160" bh="36">{{ langJson('Remove') }}</BTN>
        </div>
        <div v-if="!isPhone" class="line"></div>
        <div class="form">
            <div class="form_box">
                <FormItem v-model="form.chainId" @change="chainChange" :list="chainlist" type="select" :matchs="['unull']" :title="langJson('Network')" :placeholder="langJson('Network')"></FormItem>
                <div class="select_contract">
                    <span>{{ langJson('Collective contract type') }}</span>
                    <div>
                        <MintSel class="status_item" v-model="form.contractType" type="contract" :list="statuslist" size="big"></MintSel>
                    </div>
                    <!-- <div class="form_ERC1155" v-if="status == 1155">
                        <FormItem :title="langJson('Number of tokens')" class="information_item" height="188px" type="text"></FormItem>
                    </div> -->
                    <div class="form_ERC2891">
                        <h2 class="title">{{ langJson('Royalty payments') }}</h2>
                        <div class="input_item">
                            <FormItem v-model="form.royaltyAddress" :title="langJson('Public address')" :matchs="['unull']" class="information_item" height="188px" type="text"></FormItem>
                            <FormItem v-model="form.royaltyRate" :title="langJson('Royalty proportion')" :matchs="['unull', 'float']" class="information_item" height="188px" type="text">
                                <span class="append" slot="text_append">%</span>
                            </FormItem>
                        </div>
                        <FormItem v-model="form.circulation" v-if="form.contractType == 1155" :title="langJson('Number of tokens')" :matchs="['unull', 'int']" class="information_item item_number" height="188px" type="text"></FormItem>
                    </div>
                </div>
                <div class="estimate">
                    <span>{{ langJson('NFTWerks Mint Fee') }}</span>
                    <div class="estimate_btn">
                        <BTN @handle="pay" bh="40" type="estimate">{{ langJson('Estimate') }}</BTN>
                        <h2 class="gas_estimate">{{ amount }} {{ symbolDetail.name }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_box">
            <BTN type="active" :disable="!pass" @handle="submitMint" bw="328">{{ langJson('Create multiple NFTs') }}</BTN>

            <Runload v-model="runModel"></Runload>
            <MintCompleted v-model="completeModel"></MintCompleted>
            <!-- Processing... -->
            <Model :showHeader="false" :padding="isPhone ? '108.93px 59px 108.93px 59px' : '108.93px 186px 108.93px 186px'" v-model="payShow">
                <template v-slot:main>
                    <div class="pay_dialog_main">
                        <GI name="pay_processing" ws="123" hs=" 123"></GI>

                        <div class="process">
                            <p class="proecss_tips">{{ langJson('Processing...') }}</p>
                            <p class="proecss_tips">{{ langJson('Please wait a few seconds') }}</p>
                        </div>
                    </div>
                </template>
            </Model>
            <!-- Minting Completed -->
            <Model :showHeader="false" :padding="isPhone ? '32px 20px' : '38.5px 85px 38.5px 85px'" v-model="mintShow">
                <template v-slot:main>
                    <div class="mint_dialog_main">
                        <GI name="mint_completed" :ws="isPhone ? 157 : 200" :hs="isPhone ? 184 : 233"></GI>

                        <div class="bottom">
                            <div class="tips">
                                <p class="tips_info">{{ langJson('Minting Completed') }}</p>
                            </div>
                            <div class="btns">
                                <BTN type="detail" @handle="cancelMint" :bw="isPhone ? 295 : 210">{{ langJson('Back to My Campaign') }}</BTN>
                                <BTN @handle="shareShow" :bw="isPhone ? 295 : 210" type="active">{{ langJson('Share') }}</BTN>
                            </div>
                        </div>
                    </div>
                </template>
            </Model>
        </div>
    </div>
</template>

<script>
let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    data() {
        return {
            runModel: false,
            completeModel: false,
            selete_list: [],
            statuslist: [
                {
                    lable: 'ERC721',
                    value: 721,
                },
                {
                    lable: 'ERC1155',
                    value: 1155,
                },
            ],

            form: {
                chainId: '', // 网络下拉选择
                contractType: 721, // 合约类型 ERC第三种合约类型
                royaltyAddress: '', // 版税接收地址
                royaltyRate: 0, // 版税百分比
                circulation: 1, // 数量
            },
            symbolDetail: {},
            amount: 0,

            payShow: false,
            mintShow: false,
        }
    },
    mounted() {},
    beforeDestroy() {
        let { commit } = this.$store
        commit('mintNftList', [])
    },
    computed: {
        pass() {
            return this.checkPass(this.form.chainId, ['unull'], false) && this.checkPass(this.form.royaltyAddress, ['unull'], false) && this.checkPass(this.form.royaltyRate, ['unull', 'float'], false) && !!this.selete_list.length
        },
        tokens() {
            try {
                let result = []
                let { chainDetail } = this.walletDetail
                if (chainDetail && chainDetail.id) {
                    result = this.globalToken.filter((item) => {
                        return item.chainId == chainDetail.id
                    })
                }
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
        chainlist() {
            try {
                let result = this.chainMapping.map((item) => {
                    return {
                        lable: item.chainName,
                        value: item.id,
                    }
                })
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
        getlink() {
            let protocol = window.location.protocol
            let host = window.location.host
            let port = window.location.port
            return `${protocol}//${host}/myster/detail/${this.$route.params.id}`
        },
    },
    methods: {
        // 开始铸造
        async submitMint() {
            this.runModel = true
            try {
                let { dispatch } = this.$store
                await this.connect()
                let { store } = await this.storeState()

                let { contractType } = this.form
                let { chainDetail } = this.walletDetail

                // 活动时部署的合约
                let platformContract = this.findContractObj(chainDetail.id)
                console.log('platformContract', this.platformContract)

                //检查合约地址是否正常
                // store.platformSupport(nft合约地址)

                //检查 钱包 是否 有该合约权限
                // store.isOwner(nft合约地址)

                let { ERC721, ERC1155 } = platformContract
                let contractAddress = ''
                if (contractType == 721) {
                    contractAddress = ERC721
                } else if (contractType == 1155) {
                    contractAddress = ERC1155
                }
                console.log(contractAddress)

                let params = {
                    uid: this.userInfo.id,
                    nft: contractAddress,
                    list: this.selete_list.map((item) => {
                        return {
                            recipient: this.form.royaltyAddress,
                            tokenId: item.id,
                            amount: this.form.circulation,
                            fee: multiplication(this.form.royaltyRate, 100),
                            uri: item.tokenUri,
                        }
                    }),
                }
                console.log(params)
                let transition = await store.mint(params)
                if (transition) {
                    this.runModel = false
                    this.completeModel = true
                }
            } catch (err) {
                console.log(err)
            }
            this.runModel = false
        },
        async chainChange(val) {
            let chain = this.chainMapping.find((item) => {
                return item.id == val
            })
            await this.switchNetwork(chain)
            await this.mintFee()
        },
        // 初始化手续费
        async mintFee() {
            await this.connect()
            let { store } = await this.storeState()
            let { contractType } = this.form
            let { chainDetail } = this.walletDetail
            let platformContract = this.findContractObj(chainDetail.id)
            let { ERC721, ERC1155 } = platformContract
            let contractAddress = ''
            if (contractType == 721) {
                contractAddress = ERC721
            } else if (contractType == 1155) {
                contractAddress = ERC1155
            }
            console.log(contractAddress)

            let feeObj = await store.mintPrice(contractAddress)

            this.symbolDetail = this.tokens.find((item) => {
                return charCompare(item.contractAddr, feeObj.token)
            })
            this.amount = this.toMain(feeObj.amount, this.symbolDetail.id)
            this.amount = multiplication(this.selete_list.length, this.amount)
        },
        removeHand() {
            // 移除
            let arr = this.selete_list.map((item) => {
                return item.id
            })

            let arr1 = JSON.parse(JSON.stringify(this.globalBrand.mintNftList))

            let newArr = arr1.filter((item) => {
                let have = arr.find((item2) => {
                    return item2 == item.id
                })
                return !have
            })
            let { commit } = this.$store
            commit('mintNftList', newArr)
        },
        selectItem(obj) {
            let exit = this.selete_list.some((item) => {
                return item.id == obj.id
            })
            if (exit) {
                this.selete_list = this.selete_list.filter((item) => {
                    return item.id != obj.id
                })
            } else {
                this.selete_list.push(obj)
            }
            console.log('this.selete_list', this.selete_list)
            this.mintFee()
        },
        pay() {
            this.payShow = true
        },
        showMint() {
            this.mintShow = true
        },
        shareShow() {
            // 当前页面没有使用
            // this.$store.commit('changeShowShare', true)
        },
        cancelMint() {
            this.mintShow = false
            // 跳转到活动页面
            this.jump_page_path('/brand/campaign/index', { status: 'Archive' })
        },
    },
}
</script>
<style lang="scss" scoped>
.line {
    width: var(--max_width);
    margin: auto;
    border-bottom: 1px solid var(--border_color);
}
.mint_container {
    width: var(--max_width);
    margin: auto;
    margin-top: 32px;
    .mint_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select_count {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            /* identical to box height, or 167% */

            /* Neutral/1 */

            color: var(--font_deep_color);
        }
        .mobile_title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            /* or 120% */

            /* Neutral/1 */

            color: var(--font_deep_color);
        }
    }
    .mint_box {
        margin-top: 24px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;
        .artworks_item {
            background: var(--color_white);
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
            padding: 12px;
            box-sizing: border-box;
        }
    }
}
.form {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    .form_box {
        max-width: 733px;
        width: 100%;
        gap: 42px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        .information_item {
            width: 100%;
        }
        .select_contract {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .form_ERC1155 {
                max-width: 544px;
            }
            .form_ERC2891 {
                max-width: 732px;
                .item_number {
                    margin-top: 22px;
                }
                .title {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                    margin-bottom: 24px;
                }
                .input_item {
                    display: flex;
                    gap: 16px;
                    .append {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                    }
                }
            }
        }

        .estimate {
            display: flex;
            justify-content: space-between;
            .estimate_btn {
                display: flex;
                align-items: center;
                gap: 16px;
                .gas_estimate {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 40px;
                    color: var(--font_deep_color);
                }
            }
        }
    }
}
.btn_box {
    max-width: var(--max_width);
    margin: auto;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 64px;
}
.pay_dialog_main {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .process {
        display: grid;
        margin-top: 23px;
        gap: 13px;
        .proecss_tips {
            place-self: center;
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
    }
}
.mint_dialog_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .bottom {
        margin-top: 24px;
        .tips {
            .tips_info {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
                text-align: center;
            }
        }

        .btns {
            display: flex;
            margin-top: 16px;
            gap: 16px;
            justify-content: space-evenly;
        }
    }
}

@media screen and (max-width: 1080px) {
    .mint_box {
        flex-wrap: nowrap !important;
        overflow: auto;
        gap: 12px !important;
    }

    .estimate {
        flex-direction: column;
        justify-content: center !important;
        align-items: flex-start;
        gap: 12px;
    }
    .estimate_btn {
        .gas_estimate {
            font-size: 20px !important;
            line-height: 32px !important;
        }
    }

    .form_ERC2891 {
        .input_item {
            flex-direction: column;
        }
    }
}
@media screen and (max-width: 500px) {
    .pay_dialog_main {
    }
    .mint_dialog_main {
        width: 100% !important;
        .btns {
            flex-direction: column;
            .share_btn {
                flex: 1;
            }
        }
    }
}
</style>
