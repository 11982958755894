<template>
    <div data-v-5df26d7e="" class="progress-inner">
          <div data-v-5df26d7e="" class="progress">
              <div data-v-5df26d7e="" 
              role="progressbar"
              class="progress-bar progress-bar-striped" :style="{width:`${progress}%`}">
              </div>
          </div>
      </div>
</template>
<script>
export default {
    props:{
        progress:{
            type:Number,
            default:0
        }
    }
}
</script>
<style lang="scss" scoped>
.progress-inner {
    position: relative;
    z-index: 3;
    
}
.progress-inner .progress {
    border-radius: 0;
}
.progress-inner .progress, .progress-inner .progress-bar {
    background-color: rgba(255, 255, 255, 0);
    background-size: 14px 32px;
    background-image: linear-gradient(60deg, #ffffff 26%, transparent 25%, transparent 50%, #ffffff 54%, #ffffff 82%, transparent 75%, transparent);
    height: 12px;
}
.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
.progress-inner .progress-bar {
    background-image: linear-gradient(60deg, var(--success_color) 26%, transparent 25%, transparent 50%, var(--success_color) 54%, var(--success_color) 82%, transparent 75%, transparent);
}

</style>