import api from '@/api/config'
const configModule = {
    state: {
        // globalConfig
        // 网站所有支持的币种
        globalToken: [],
        // 网站支持的主链
        chainMapping: [],
        // 网站支持的平台nft
        platformSupport: [],
        // 网站默认配置
        defaultConfig: {},
        // kyc配置
        kycConfig: {},
        // 网站主题
        theme: localStorage.getItem('themeColor'),
        // 网站支持的nft类型
        nftCategory: [],
        // 平台合约列表
        platformContract: [],
        // 语种列表
        langTypeList: [],
        // 用户部署的nft合约
        personalContract: [],
        // 社交媒体
        socialMedia: [],

        headView: {
            menu: false,
            logo: true,
            search: true,
            link: true,
            create: true,
            portrait: true,
            wallet: true,
            theme: true,
        },
        isPhone: false, // 是否是手机
        tips: [],
        showShare: false, // 全局分享
        showVote: false, // 全局投票
        showMintOrJoin: false,
        shareObj: {
            img: '',
            title: '',
            linkName: '',
            url: '',
        },

        authBinding: {},
        authLogin: [],
    },
    getters: {
        symbolOf(state) {
            return (key, value) => {
                return state.globalToken.find((item) => {
                    return item[key] == value
                })
            }
        },
        chainOf(state) {
            return (key, value) => {
                return state.chainMapping.find((item) => {
                    return item[key] == value
                })
            }
        },
        // 平台nft合约
        nftContract(state) {
            return () => {
                return state.platformContract.filter((item) => {
                    return item.name == 'platform_contract_721' || item.name == 'platform_contract_1155'
                })
            }
        },
    },
    mutations: {
        authBinding(state, authBinding) {
            state.authBinding = authBinding
        },
        authLogin(state, authLogin) {
            state.authLogin = authLogin
        },
        socialMedia(state, socialMedia) {
            state.socialMedia = socialMedia
        },
        changeShowShare(state, showShare) {
            state.showShare = showShare
        },
        showVote(state, showVote) {
            state.showVote = showVote
        },
        shareObj(state, shareObj) {
            state.shareObj = shareObj
        },
        changeshowMintOrJoin(state, showMintOrJoin) {
            state.showMintOrJoin = showMintOrJoin
        },
        saveHeadView(state, obj) {
            Object.assign(state.headView, obj)
        },
        cleanTips(state, result = []) {
            state.tips = result
        },
        tips(state, tips) {
            state.tips = tips
        },
        theme(state, theme) {
            state.theme = theme
        },
        globalToken(state, globalToken) {
            state.globalToken = globalToken
        },
        chainMapping(state, chainMapping) {
            state.chainMapping = chainMapping
        },
        saveIsPhone(state, isPhone) {
            state.isPhone = isPhone
        },

        platformContract(state, platformContract) {
            state.platformContract = platformContract
        },
        platformSupport(state, platformSupport) {
            state.platformSupport = platformSupport
        },
        defaultConfig(state, defaultConfig) {
            state.defaultConfig = defaultConfig
        },
        kycConfig(state, kycConfig) {
            state.kycConfig = kycConfig
        },
        nftCategory(state, nftCategory) {
            state.nftCategory = nftCategory
        },
        langConfig(state, langTypeList) {
            state.langTypeList = langTypeList
        },
        personalContract(state, personalContract) {
            state.personalContract = personalContract
        },
    },
    actions: {
        async authBinding(context, params) {
            try {
                let result = await api.authBinding(params)
                result = this.apiResult(result)
                context.commit('authBinding', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async authLogin(context, params) {
            try {
                let result = await api.authLogin(params)
                result = this.apiResult(result)
                context.commit('authLogin', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取社交媒体
        async socialMediaGet(context) {
            try {
                let result = await api.socialMediaConfig()
                result = this.apiResult(result)
                context.commit('socialMedia', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 上传文件
        async uploadFile(context, params) {
            try {
                let result = await api.uploadFile(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async contractGet(context) {
            try {
                let result = await api.platformContract()
                result = this.apiResult(result)
                context.commit('platformContract', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取nft的支持类型
        async categoryGet(context) {
            try {
                let result = await api.nftCategory()
                result = this.apiResult(result)
                result.data && context.commit('nftCategory', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的币种
        async symbolGet(context) {
            try {
                let result = await api.allCoins()
                result = this.apiResult(result)
                context.commit('globalToken', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的主链
        async chainGet(context) {
            try {
                let result = await api.getChainMapping()
                result = this.apiResult(result)
                context.commit('chainMapping', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的平台NFT
        async platformGet(context) {
            try {
                let result = await api.getPlatformContractList()
                result = this.apiResult(result)
                context.commit('platformSupport', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取网站默认配置
        async configGet(context) {
            try {
                let result = await api.getWebConfig()
                result = this.apiResult(result)
                context.commit('defaultConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取Kyc配置
        async kycGet(context) {
            try {
                let result = await api.getKycConfigGuide()
                result = this.apiResult(result)
                context.commit('kycConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 语种列表
        async getLangType(context, params) {
            try {
                let result = await api.getLangType(params)
                result = this.apiResult(result)
                result.data && context.commit('langConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // /ipfs 主域名
        async getIpfsDomain(context, params) {
            try {
                let result = await api.getIpfsDomain(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取平台国家配置
        async configCountryConfig(context, params) {
            try {
                let result = await api.configCountryConfig(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default configModule
