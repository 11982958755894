<template>
    <div class="activity">
        <div class="btns">
            <Tab v-model="tab" :list="tablist" align="flex-end"></Tab>
        </div>

        <div class="table_box">
            <Table :list="list" :title="title" :play="play">
                <template slot="from" slot-scope="{data}">
                    <Link>{{data.from | Abbre}}</Link>
                </template>
                <template slot="to" slot-scope="{data}">
                    <Link>{{data.to | Abbre}}</Link>
                </template>
                <template slot="contract" slot-scope="{data}">
                    <Link>{{data.contract | Abbre}}</Link>
                </template>
                <div class="more" slot="foot">
                    <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="false"></Page>
                </div>
            </Table>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            play:[],
            tab:1,
            tablist:[
                {
                    lable:this.langJson("All"),
                    value:""
                },
                {
                    lable:this.langJson("Transfer"),
                    value:0
                },
                {
                    lable:this.langJson("Buy Now"),
                    value:1
                },
                {
                    lable:this.langJson("Bid Auction"),
                    value:2
                },
                {
                    lable:this.langJson("Bidding"),
                    value:3
                },
                {
                    lable:this.langJson("Auction"),
                    value:4
                }
            ],
            list:[
                {
                    image:"https://w.wallhaven.cc/full/1k/wallhaven-1ky369.jpg",
                    chain:"Ethereum",
                    from:"0xDe126800f0dd36013B1C903aCb3a736e1762e1Dc",
                    to:"0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1",
                    contractType:"ERC721",
                    contract:"0xa88bcaf54cdd8041509b2e71ee748afa68289e97",
                    tokenId:"1",
                    amount:"10",
                    time:"2022-09-10 20:00"
                },
                {
                    image:"https://w.wallhaven.cc/full/1k/wallhaven-1ky369.jpg",
                    chain:"Ethereum",
                    from:"0xDe126800f0dd36013B1C903aCb3a736e1762e1Dc",
                    to:"0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1",
                    contractType:"ERC1155",
                    contract:"0xa88bcaf54cdd8041509b2e71ee748afa68289e97",
                    tokenId:"2",
                    amount:"2",
                    time:"2022-09-10 20:00"
                },
                {
                    image:"https://w.wallhaven.cc/full/1k/wallhaven-1ky369.jpg",
                    chain:"Ethereum",
                    from:"0xDe126800f0dd36013B1C903aCb3a736e1762e1Dc",
                    to:"0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1",
                    contractType:"ERC1155",
                    contract:"0xa88bcaf54cdd8041509b2e71ee748afa68289e97",
                    tokenId:"3",
                    amount:"3",
                    time:"2022-09-10 20:00"
                }
            ].concat(new Array(17).fill({
                image:"https://w.wallhaven.cc/full/1k/wallhaven-1ky369.jpg",
                chain:"Ethereum",
                from:"0xDe126800f0dd36013B1C903aCb3a736e1762e1Dc",
                to:"0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1",
                contractType:"ERC1155",
                contract:"0xa88bcaf54cdd8041509b2e71ee748afa68289e97",
                tokenId:"3",
                amount:"3",
                time:"2022-09-10 20:00"
            })),
            title:{
                image:{
                    title:"Preview",
                    type:"image"
                },
                chain:{
                    title:"Network",
                    type:"text"
                },
                from:{
                    title:"From",
                    type:"address"
                },
                to:{
                    title:"To",
                    type:"address"
                },
                // contractType:{
                //     title:"Token Standard",
                //     type:"text"
                // },
                // contract:{
                //     title:"Contract Address",
                //     type:"address"
                // },
                // tokenId:{
                //     title:"Token ID",
                //     type:"text"
                // },
                amount:{
                    title:"Amount",
                    type:"text"
                },
                time:{
                    title:"Time",
                    type:"text"
                }
            }
        }
    },
    computed:{
        haveData() {
            return this.list && this.list.length
        },
        listKey() {
            return Object.keys(this.title)
        }
    }
}
</script>
<style lang="scss" scoped>
.more{
    height:50px;
    background:var(--color_white);
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px -5px 10px var(--bg_color);
    padding: 0 20px;

}
.more .page_main{
    justify-content: space-between!important;
    width:100%;
}
.activity{
    .btns{
        margin-bottom:20px;
    }
    .table_box{
        height:800px;
    }
    
    margin-top:20px;
}
</style>