<template>
    <!-- <div class="border"> -->
    <div
        :class="['btn cursor', type, disable || load ? 'disable' : '', size, themeColor]"
        @click="emitEvent"
        :style="
            Object.assign(styleCustom, {
                'min-width': bw ? `${bw}px` : `auto`,
                'min-height': bh ? `${bh}px` : `auto`,
                'max-height': bh ? `${bh}px` : `auto`,
                fontSize: fontSize ? `${fontSize}px` : ``,
                borderWidth: borderWidth ? `${borderWidth}px` : ``,
            })
        "
    >
        <GI v-if="icon_left" class="icon_left" :name="icon_left" :ws="iconSize ? iconSize : ws" :hs="iconSize ? iconSize : hs" @click="emitEvent"></GI>

        <slot></slot>

        <GI v-if="icon_right" class="icon_right" :name="icon_right" :ws="iconSize ? iconSize : ws" :hs="iconSize ? iconSize : hs" @click="emitEvent"></GI>
        <div class="mask" v-if="load">
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
export default {
    name: 'BTN',
    data() {
        return {
            lockStatus: false,
            lockTime: 500,
        }
    },
    props: {
        icon_left: {
            type: String,
            default: '',
        },
        icon_right: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'default',
        },
        disable: {
            type: Boolean,
            default: false,
        },
        load: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: [String, Number],
            default: '',
        },
        ws: {
            type: [String, Number],
            default: '20',
        },
        hs: {
            type: [String, Number],
            default: '20',
        },
        bw: {
            type: [String, Number],
            default: '',
        },
        bh: {
            type: [String, Number],
            default: '',
        },
        height: {
            type: String,
            default: '44px',
        },
        styleCustom: {
            type: Object,
            default: () => {
                return {}
            },
        },
        size: {
            type: String,
            default: 'medium',
        },
        fontSize: {
            type: String,
            default: '',
        },
        borderWidth: {
            type: String,
            default: '',
        },
    },
    computed: {
        isActive() {
            return this?.globalTheme == 'night'
        },
        themeColor() {
            let str = ''
            if (this?.globalTheme == 'night') {
                str = 'theme_dark'
                if (this.type === 'active') {
                    str += ' theme_dark_bg'
                }
                if (this.disable) {
                    str += ' theme_dark_disable'
                }
            }
            return str
        },
    },
    methods: {
        emitEvent() {
            if (!this.disable && !this.load && !this.lockStatus) {
                this.lockStatus = true
                setTimeout(() => {
                    this.lockStatus = false
                }, this.lockTime)
                this.$emit('handle')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.btn {
    user-select: none;

    .icon_left {
        margin-right: 10px;
    }

    .icon_right {
        margin-left: 10px;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 1px 10px 1px 1px;

    gap: 4px;
    box-sizing: border-box;
    color: var(--font_deep_color);

    font-family: 'Cabinet Grotesk';
    font-style: normal;

    // letter-spacing: 2px;

    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.mini {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 16px !important;
}

.medium {
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    padding: 12px 22px !important;
}

.big {
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding: 16px 28px !important;
}
.bigger {
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    padding: 12px 32px;
}

.default {
    border: 1px solid var(--nft_btn_border);
    // background: var(--color_white);
    font-size: 16px;
    color: var(--font_deep_color) !important;
    border-radius: 32px !important;
    font-weight: 400;
    font-family: 'Lato';
}

.main {
    border: 2px solid var(--main_color);
    color: var(--main_color) !important;
    border-radius: 32px !important;
}

.cancle {
    background: var(--bg_color);
    color: var(--font_modif_color) !important;
    border-radius: 32px !important;
}

.active {
    background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
    color: var(--font_deep_color) !important;
    border-radius: 32px !important;
}

.yes {
    background: linear-gradient(90deg, #c589f9 27.34%, #ec52d4 100%) !important;
    box-shadow: 0px 20px 25px -5px rgba(213, 115, 234, 0.1), 0px 8px 10px -6px rgba(186, 0, 251, 0.1) !important;
    border-radius: 32px !important;
    color: #fff !important;
}

.skip {
    background: #dcd7e5 !important;
    box-shadow: 0px 0px 0px rgba(134, 134, 134, 0) !important;
    border-radius: 32px !important;
    color: #fff !important;
}

.empty {
    border: 2px solid #ffffff;
    border-radius: 32px !important;
    color: #fff !important;
    border-top-color: #ffc5ac;
    border-right-color: #dbaed7;
    border-bottom-color: #ba99ff;
    border-left-color: #dbaed7;
}

.detail {
    border: 2px solid var(--primary_color);
    border-radius: 32px !important;
    color: var(--primary_color) !important;
}
.detail_mobile {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid var(--primary_color);
    border-radius: 32px !important;
    color: var(--primary_color) !important;
}

.warn {
    color: var(--danger_color);
    border-radius: 32px !important;
    border: 2px solid var(--danger_color);
}
.mobile_warn {
    color: var(--danger_color);
    border-radius: 32px !important;
}

.disable {
    background: var(--border_color);
    color: var(--font_modif_color) !important;
    border-radius: 32px !important;
}
.success {
    background: var(--success_main);
    color: var(--success_font) !important;
    border-radius: 32px !important;
}

.selectAll {
    border: 2px solid var(--border_color);
    color: var(--border_color);
    border-radius: 32px !important;
}

.estimate {
    background: var(--bg4);
    color: var(--font_deep_color);
    font-weight: 800;
    border-radius: 32px !important;
    font-size: 16px;
    padding: 8px 16px;
}

.bank {
    background: var(--primary_color);
    color: var(--color_white);
    border-radius: 32px !important;
}

.border_moible {
    font-weight: 800;
    border: 2px solid transparent;
    border-radius: 32px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, var(--color_white), var(--color_white)), linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
}
.blank {
    background: var(--color_white);
    color: var(--font_deep_color);
    border: 1px solid var(--color_white);
    border-radius: 32px;
}
.loading {
}

.theme_dark {
    border-radius: 12px !important;
}
.theme_dark_bg {
    background: var(--nft_btn_bg_active) !important;
    color: var(--nft_btn_font_active) !important;
}
.theme_dark_disable {
    background: #a1a1a1 !important;
    color: #fdfdfd !important;
    cursor: no-drop !important;
}
</style>
