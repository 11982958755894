<template>
    <div class="item">
        <!-- 商户活动 -->
        <div class="brand_detail">
            <BtnPop :left="-104">
                <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                <GI slot="target" name="brand_more" ws="24" hs="24" class="pointer icon_"></GI>
                <div slot="main" class="type_main">
                    <div v-for="(tag, index) in tagList" :key="index" class="type_content">
                        <div @click="goRoute(tag)" class="type_line">
                            <GI ws="20" hs="20" :name="tag.icon"></GI>
                            <span class="title">{{ tag.val }}</span>
                        </div>
                    </div>
                </div>
            </BtnPop>
        </div>
        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image cursor" :style="{ backgroundImage: `url(${item.banner})` }" @click="jump_brand_compaign_preview({ status: item.status, id: item.id, operateStop: item.operateStop })"></div>
        </div>

        <div class="item_info">
            <div class="item_title">
                {{ item.name }}
            </div>
            <div class="item_desc">
                {{ item.description }}
            </div>

            <CompaignVI :coll="item.voteNum" :zan="item.joinNum"></CompaignVI>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {}
    },
    computed: {
        tagList() {
            return [
                {
                    icon: 'brand_eye',
                    val: this.langJson('Preview'),
                    isChecked: false,
                    number: 1,
                    status: 'live',
                },
            ]
        },
        status() {
            let result = 'live'
            switch (this.item.status) {
                case 1:
                    result = 'live'
                    break
                case 0:
                    result = 'Unpublished'
                    break
                case 2:
                    result = 'archive'
                    break
            }
            return result
        },
    },
    methods: {
        goRoute(item) {
            switch (item.number) {
                case 1:
                    this.jump_brand_compaign_preview({ status: this.status, id: this.item.id, operateStop: this.item.operateStop })
                    break
                case 2:
                    break
                case 3:
                    this.jump_brand_compaign_submissions({
                        status: this.status,
                        id: this.item.id,
                    })
                    break

                default:
                    break
            }
            // console.log(item)
        },
    },
}
</script>
<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: column;
    // margin-right: 24px;
    // margin-left: 24px;
    gap: 16px;
    .item_info {
        .item_desc {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: 4px;
            color: var(--font_deep_color);
            min-height: 32px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            white-space: pre-wrap;
        }
        .item_belong {
            .belong_name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-left: 8px;
            }
            .belong_image {
                height: 28px;
                width: 28px;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
            }
            margin-top: 8px;
            display: flex;
            align-items: center;
        }
        .item_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: flex-end;
        height: 24px;
    }

    .image_out {
        border-radius: 12px;
        overflow: hidden;

        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
.type_main {
    padding: 24px;
    background: var(--nft_dialog_bg);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
@media screen and (max-width: 1081px) {
    .item {
        display: flex;
        flex-direction: column;
        // margin-right: 24px;
        // margin-left: 24px;
        gap: 12px;
        .item_info {
            .item_desc {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                margin-top: 4px;
                color: var(--font_deep_color);

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                white-space: pre-wrap;
            }
            .item_belong {
                .belong_name {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                    margin-left: 8px;
                }
                .belong_image {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    overflow: hidden;
                }
                margin-top: 8px;
                display: flex;
                align-items: center;
            }
            .item_title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            flex: 1;
        }
        .brand_detail {
            display: flex;
            justify-content: flex-end;
        }

        .image_out {
            border-radius: 16px;
            overflow: hidden;
            background-color: rgba($color: #ccc, $alpha: 0.2);
            .item_image {
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                transition: all 2s;
            }
            .item_image:hover {
                transform: scale(2);
            }
        }
    }
    .type_main {
        padding: 24px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 24px;
        cursor: pointer;
        .type_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &:last-child:hover {
                color: var(--danger_color);
                transition: all 1s;
            }
            .type_line {
                display: flex;
                align-items: center;
                gap: 8px;

                .title {
                    white-space: nowrap;
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
