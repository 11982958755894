<template>
    <div class="graphic">
        <!-- 登录就隐藏，没登录就显示 -->
        <!-- <StartHead v-if="showHead"></StartHead> -->
        <div class="graphic_main">
            <div class="main_content">
                <div v-if="!isPhone" class="title">
                    {{ langJson('About NFTWerks') }}
                    <div class="cycle" :style="{ backgroundImage: `url(/source/png/about_cycle.png)` }"></div>
                    <div class="star" :style="{ backgroundImage: `url(/source/png/about_star.png)` }"></div>
                </div>
                <div v-if="isPhone" class="title">
                    {{ langJson('About NFTWerks') }}
                    <div class="cycle" :style="{ backgroundImage: `url(/source/png/about_mobile_cycle.png)` }"></div>
                    <div class="star" :style="{ backgroundImage: `url(/source/png/about_mobile_star.png)` }"></div>
                </div>

                <div class="desc">
                    {{ langJson('NFTWerks is guided by a vision of NFTs for everyone. NFTWerks is focused on simplifying creation of NFTs, and using benefits of NFT for use cases that are only limited by your imagination. NFTWerks is founded in Singapore by four like-minded NFT believers') }}
                </div>
            </div>
            <div class="list">
                <div v-for="(item, index) in infolist" :key="index" :style="{ backgroundImage:`url(/source/png/about_border.png)` }" class="item">
                    <div class="item_preview" :style="{ backgroundImage: `url(${item.image})` }"></div>
                    <div class="item_title">{{ item.title }}</div>
                    <div class="item_desc">{{ item.desc }}</div>
                </div>
            </div>
        </div>

        <div v-if="!isPhone" class="about_bg" :style="{ backgroundImage: `url(/source/png/about_bg.png)` }"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showHead: true,
        }
    },
    computed: {
        infolist() {
            let result = [
                {
                    image: '/source/png/about_1.png',
                    title: this.langJson('VK'),
                    desc: this.langJson('Big believer in NFTs, Global management experience in senior leadership roles, set up and led digital assets for a global bank, investor and adviser to several disruptive models. Believes it is easy to be complex, very hard to be simple.'),
                },
                {
                    image: '/source/png/about_2.png',
                    title: this.langJson('JS'),
                    desc: this.langJson("Code whisperer that believes blockchain is like a Cheese - it is only wonderful when done right. Experienced technologist across startup to global corporates, across academia to degeneracy. Likens NFT use-cases to Gorgonzola, difficult to get right... once you do, it's worth it."),
                },
                {
                    image: '/source/png/about_3.png',
                    title: this.langJson('AG'),
                    desc: this.langJson("A seasoned professional with extensive expertise in commercial operations across Southeast Asia and Africa. The goal is to drive inclusive and impactful market strategies as NFTs, like any other product, can be harnessed as a powerful tool for... the benefit of all. Her diverse background enables her to bring a fresh perspective and unlock the true potential of NFTs in today's dynamic landscape."),
                },
                {
                    image: '/source/png/about_4.png',
                    title: this.langJson('AT'),
                    desc: this.langJson('An advocate for demystifying NFTs and empowering the public to engage with this asset class in a secure and enjoyable way. With a background in project management within SaaS and events startups, he brings a unique blend of expertise to bridge the gap... between technology and consumer education.'),
                },
            ]
            return result
        },
    },
    mounted() {
        if (eval(this.$route.query.showHead) == false) {
            this.showHead = eval(this.$route.query.showHead)
        }
    },
}
</script>
<style lang="scss" scoped>
.graphic {
    .about_bg {
        background-position: center -180px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top:0px;
        right: 0px;
        left:0px;
        bottom:0px;
        z-index: -1;
    }
    
    .graphic_main {
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        margin-top: 50px;
        
        .list{
            // width: 1184px;
            .item {
                .item_preview {
                    width: 224px;
                    height: 200px;
                    border-radius: 20px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .item_desc {
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 8;
                    color:var(--font_color);
                    font-family: Lato;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
                .item_title{
                    font-family: Cabinet Grotesk;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    color:var(--font_deep_color);
                }
                display: flex;
                width: 272px;
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                border-radius: 24px;
                background-color: var(--white_color);
                box-shadow: 9px 7px 47px 0px rgba(0, 0, 0, 0.07);
                backdrop-filter: blur(5.997468948364258px);
                box-sizing: border-box;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center center;
            }
            display: flex;
            // align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 32px;
            padding:64px 0;
        }
        .main_content {
            width: 532px;

            max-width: 50%;
            margin: 0 auto;
            .desc {
                text-align: center;
                width: 100%;
                font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                color: var(--font_color);
            }

            .title {
                // .bg{
                //     position: absolute;
                //     top:0;
                //     right:0;
                // }
                height: 120px;
                font-family: Cabinet Grotesk;
                font-size: 56px;
                font-style: normal;
                font-weight: 700;
                line-height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--font_deep_color);
                position: relative;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
                .star {
                    position: absolute;
                    top:-12px;
                    left:130px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width:44px;
                    height:44px;
                }
                .cycle {
                    position: absolute;
                    top:0px;
                    left:0px;
                    background-position: 70px center;
                    background-repeat: no-repeat;
                    background-size: calc(100% - 70px) 100%;
                    width:100%;
                    height:110px;
                }
            }
        }

        
    }
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // overflow: auto;
    // height: 100vh;
    padding-bottom:200px;
    position: relative;
}

@media only screen and (max-width: 1225px) {
    .graphic {
        .graphic_main {
            .main_content {
                .title {
                    font-family: Cabinet Grotesk;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 48px;
                    height:76px;
                    .star {
                        position: absolute;
                        top:-10px !important;
                        right:40px !important;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width:30px !important;
                        height:30px !important;
                    }
                    .cycle {
                        position: absolute;
                        top:0px !important;
                        left:0px !important;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: auto 100%;
                        width:100% !important;
                        height:72px !important;
                    }
                }
                .desc {
                    font-family: Lato;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; 
                }
            }
        }
    }
    .graphic_main {
        flex-direction: column;
        justify-content: flex-start !important;
        height: fit-content !important;
        margin-top: 40px !important;
        margin-bottom: 24px !important;

        .main_content {
            max-width: var(--max_width) !important;
            
        }

        .title {
            text-align: center;
            font-size: 32px !important;
        }

        .bg {
            background-position: center center !important;
            max-width: var(--max_width);
        }

        
        .img {
            width: 100vw !important;
            height: 90vw !important;
            transform: translate(0, 0) !important;
            overflow: visible !important;
            max-width: 100vw !important;
            .main_image1 {
                height: auto !important;
                width: calc(100vw + 58px) !important;
                transform: translate(calc(-50% - 29px), -50%) !important;
            }
            .mask {
                display: none;
            }
            .main_image2 {
                transform: translate(-48%, -50%) !important;
                width: 100vw !important;
                max-width: 554px !important;
            }
        }
    }
}
</style>
