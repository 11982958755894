<template>
    <!--移动端圆角40 pc24 -->

    <div class="model_main" slot="main">
        <div class="type_main">
            <div class="start_join">
                <div v-if="!isPhone" class="article_why" :style="{ backgroundImage: `url(/source/png/index_article_title.png)` }">
                    {{ langJson('Get started with NFTWerks') }}
                </div>
                <div v-if="isPhone" class="article_why" :style="{ backgroundImage: `url(/source/png/startup_cycle_mobile.png)` }">
                    {{ langJson('Get started with NFTWerks') }}
                </div>
                <div class="join_list">
                    <div class="join_item" v-for="(item, index) of joinlist" :key="index">
                        <div class="join_preview" :style="{ backgroundImage: `url(${item.image})` }"></div>
                        <div class="join_title">{{ item.title }}</div>
                        <div class="join_desc">{{ item.desc }}</div>
                        <BTN class="btn_item" type="active" bw="224" :bh="isPhone ? 52 : 60" @handle="item.handle">
                            {{ item.title }}
                        </BTN>
                    </div>
                </div>
            </div>
            <!-- <div class="type_title">{{ langJson('Who are you ?') }}</div>
            <div class="btns">
                
                <div class="type_btn">
                    <div class="title">{{ langJson("Brand") }}</div>
                    <div class="desc">{{ langJson("Unlock the power of NFTs for your brand! Log in or sign up to create your very own thrilling NFT campaigns and start making waves in the NFT world!") }}</div>
                    <BTN @handle="goBrandSignup" type="active" :bh="isPhone ? 56 : 60">{{ langJson('Join as Brand') }}</BTN>
                </div>
                <div class="type_btn">
                    <div class="title">{{ langJson("Individual") }}</div>
                    <div class="desc">{{ langJson("Get in on the action! Dive into thrilling NFT campaigns or mint your own NFTs by joining us as an individual. Don't miss out on the excitement—join us today!") }}</div>
                    <BTN @handle="goParticipant" type="active" :bh="isPhone ? 56 : 60">{{ langJson('Join as Individual') }}</BTN>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
        }
    },
    computed: {

        joinlist() {
            return [
                {
                    image: '/source/png/startup_join_1.png',
                    title: this.langJson('Join as an Individual'),
                    desc: this.langJson("Get in on the action! Dive into a variety of unique NFT campaigns or mint your own NFTs by joining us as an individual. Campaigns are free and simple to enter. Don't miss out on the excitement—join us today!"),
                    handle: () => {
                        this.goParticipant()
                    },
                },
                {
                    image: '/source/png/startup_join_2.png',
                    title: this.langJson('Join as a Brand'),
                    desc: this.langJson('Unlock the power of NFTs for your brand! Launch your very own campaign to engage your audience & strengthen your reach within your industry. You can share your campaign with your audience where participation is simple and free!'),
                    handle: () => {
                        this.goBrandSignup()
                    },
                },
            ]
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        image: {
            type: String,
            default: '',
        },
    },
    methods: {
        goParticipant() {
            this.close()
            this.jump_participant_signup()
        },
        goBrandSignup() {
            this.close()
            this.jump_brand_signup()
        },
        close() {
            this.$emit('closeSign', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .type_main {
        .start_join {
            .join_list {
                .join_item {
                    .join_desc {
                        font-family: Lato;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        color: var(--font_color);
                        // text-align: center;
                    }
                    .join_title {
                        font-family: Cabinet Grotesk;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;
                        color: var(--font_deep_color);
                        // margin-top: 20px;
                        // text-align: center;
                    }
                    .join_preview {
                        max-width: 400px;
                        width: 100%;
                        height: 150px;
                        border-radius: 20px;
                        background: var(--main_color);
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        text-align: center;
                    }
                    max-width: 320px;
                    width: 100%;
                    // height: 450px;
                    // background: rgba(255, 255, 255, 0.13);
                    // backdrop-filter: blur(2.5px);
                    // border-radius: 32px;
                    box-sizing: border-box;
                    // padding: 17px;
                    // background-position: center center;
                    // background-repeat: no-repeat;
                    // background-size: 100% 100%;

                    display: flex;
                    padding: 24px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 24px;
                    border-radius: 24px;
                    background: var(--white_color);
                    box-shadow: 9px 7px 47px 0px rgba(0, 0, 0, 0.05);
                    backdrop-filter: blur(5.997468948364258px);
                }
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                column-gap: 32px;
                row-gap: 24;
                width:100%;
                margin-bottom:24px;
            }
            .article_why {
                // width: 469.24px;
                height: 76px;
                margin: auto;
                margin-top: 40px;
                margin-bottom: 32px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 76px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 64px;
                color: var(--font_deep_color);
            }
            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            // margin-top: 200px;
        }
        .btns {
            .type_btn {
                .title {
                    text-align: center;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22px;
                    color: var(--font_deep_color);
                }
                .desc {
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--font_deep_color);
                    padding: 16px;
                    font-family: 'Inter';
                    background-color: var(--bg_color);
                    margin: 30px 0;
                    border-radius: 6px;
                }
                margin-top: 16px;
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            margin-top: 32px;
            display: flex;
            gap: 30px;
        }

        .type_title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        height: auto;
        // margin-left: 28px;
        // margin-right: 28px;
        margin:0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 800px;
        width:90vw;
        box-sizing: border-box;
        background: var(--nft_dialog_bg);
        border-radius: 24px;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    box-sizing: border-box;
}

@media screen and (max-width: 1080px) {
    .model_main {
        .type_main {
            .start_join {
                .article_why {
                    text-align: center;
                    font-family: Cabinet Grotesk;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 38px;
                    height: 76px;
                    background-position: 200px 0px;
                    background-size: auto 76px;
                }
                .join_list {
                    .join_item {
                        .btn_item {
                            width: 100% !important;
                        }
                    }
                }
                margin-top: 0;
            }
            .btns {
                .type_btn {
                    margin-top: 16px;
                }
                margin-top: 24px;
                padding-left: 0px;
                padding-right: 0px;
                flex-direction: column;
            }

            .type_title {
                font-size: 16px;
                line-height: 24px;
            }
            height: auto;
            width: 271px;
            margin-left: 0px;
            margin-right: 0px;
            box-sizing: border-box;
            background: var(--nft_dialog_bg);
            border-radius: 40px;
        }
        .model_top {
            .top_right {
                cursor: pointer;
            }
            .top_left {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        box-sizing: border-box;
    }
}
</style>
