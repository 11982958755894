<template>
    <div class="item">
        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image" :style="{ backgroundImage: `url(/source/png/image.jpeg)` }" @click="jump_brand_compaign_preview"></div>
        </div>

        <div class="item_info">
            <div class="item_title">
                {{ langJson('Skechers Sundown Festival') }}
            </div>
            <div class="item_belong">
                <div
                    class="belong_image"
                    :style="{
                        backgroundImage: `url(/source/png/profile.jpeg)`,
                    }"
                ></div>
                <div class="belong_name">{{ langJson('Asians For Asia') }}</div>
            </div>
            <div class="item_desc">
                {{ langJson('Art Meets Tech at Fast-Growing Festival Highlighting Innovative Storytellers from Around the World') }}
            </div>
            <VI v-show="showVI" coll="334" zan="13" view="3343"></VI>
        </div>
    </div>
</template>
<script>
import GI from '../../../base/GI'
export default {
    components: { GI },
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        tagList: {
            type: Array,
            default: [],
        },
        showVI: {
            type: Boolean,
            default: true,
        },
        staus: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
    methods: {
        goRoute(item) {
            switch (item.number) {
                case 1:
                    this.jump_brand_compaign_preview()
                    break
                case 2:
                    this.jump_brand_compaign_overview({ status: item.status })
                    break
                case 3:
                    this.jump_brand_compaign_submissions({ status: item.status })
                    break

                default:
                    break
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: column;
    .item_info {
        margin-top: 16px;
        .item_desc {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
            color: var(--font_deep_color);

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .item_belong {
            .belong_name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-left: 8px;
            }
            .belong_image {
                height: 28px;
                width: 28px;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
            }
            margin-top: 8px;
            display: flex;
            align-items: center;
        }
        .item_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 6px;
    }

    .image_out {
        border-radius: 12px;
        overflow: hidden;

        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
            }
        }
    }
}
</style>
