<template>
    <div>
        <div>
            <div class="form">
                <div class="form_box">
                    <div class="header">
                        <h2>{{ langJson('Notification methods') }}</h2>
                        <span>{{ langJson('Select your desired ways to receive real-time notification for your sales') }}</span>
                    </div>
                    <div class="overview_box">
                        <div class="overview_item">
                            <GI hs="82" ws="82" name="profile_email"></GI>
                            <div class="item_detail">
                                <h2>{{ langJson('Email') }}</h2>
                                <!-- <span>{{ langJson('Connected') }}</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="event_types">
                    <div class="header">
                        <h2>{{ langJson('Event types') }}</h2>
                        <span>{{ langJson('Select the notifications you would like to receive') }}</span>
                    </div>
                    <div class="switch_box">
                        <div class="switch_item" v-for="(item, index) in list" :key="index">
                            <div class="item_left">
                                <GI hs="24" ws="24" name="event_dingdang"></GI>
                                <div class="item_detail">
                                    <h2>{{ item.description }}</h2>
                                    <span>{{ item.description }}</span>
                                </div>
                            </div>
                            <div class="item_right">
                                <SW v-model="item.isOpen" @change="change"></SW>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            selectItem: [],
            bannerConfig: {
                type: 'big',
            },
            list: [],
        }
    },
    created() {
        this.init()
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        // 提交修改类型
        async edit() {
            try {
                let { dispatch } = this.$store
                this.loading = true
                let result = await dispatch('contentNoticeSwitch', this.selectItem)
                this.loading = false
                if (result) {
                    this.pushMsg('success', this.langJson('Save success', 'saas_nft.txt.v1.402'))
                } else {
                    this.init()
                }
            } catch (err) {
                console.log('err', err)
            }
        },
        // 改变类型
        change() {
            this.selectItem = []
            for (let index1 = 0; index1 < this.list.length; index1++) {
                if (this.list[index1].isOpen == true) {
                    this.selectItem.push(this.list[index1].id)
                }
            }

            this.edit()
        },
        // 初始化 通知类型列表
        async init() {
            try {
                let { dispatch } = this.$store
                let res = await dispatch('contentNoticeType')
                if (res && res.code == 200) {
                    this.list = res.data
                }
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 156px;

    .form_box {
        gap: 40px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .header {
            h2 {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
            }
            span {
                font-family: 'Lato';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color_black);
            }
        }
        .overview_box {
            width: 100%;
            border-radius: 32px;
            background: var(--color_white);

            .overview_item {
                display: flex;
                align-items: center;
                gap: 20px;
                box-sizing: border-box;
                .item_detail {
                    h2 {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 28px;
                        color: var(--font_deep_color);
                    }
                    span {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--font_modif_color);
                        display: inline-block;
                        margin-top: 6px;
                    }
                }
            }
        }
        .event_types {
        }
    }
    .event_types {
        margin-top: 48px;
        .header {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h2 {
                font-family: 'Inter';
                font-weight: 700;
                font-size: 24px;
                line-height: 40px;
                color: var(--font_deep_color);
            }
            span {
                font-family: 'Lato';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color_black);
            }
        }
        .switch_box {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .switch_item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .item_left {
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    .item_detail {
                        h2 {
                            font-family: 'Inter';
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 28px;
                            color: var(--font_deep_color);
                        }
                        span {
                            font-family: 'Lato';
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--font_modif_color);
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1080px) {
    .form {
        margin: 0px;
        margin-bottom: 60px;
        max-width: 100%;
        padding: 20px !important;
    }
    .event_types {
        .header {
            h2 {
                font-size: 24px !important;
                line-height: 40px !important;
            }
            span {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
        .item_detail {
            h2 {
                font-size: 16px !important;
                line-height: 24px !important;
            }
            span {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }
    .form_box {
        .header {
            h2 {
                font-size: 32px !important;
            }
            span {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }
}
</style>
