<template>
  <div class="go">
    <div class="go_center">
      <div class="go_right">
        <div class="go_prev" @click="trigglePrev">
          <GI v-show="!!prev" name="global_previous" ws="32" hs="16" @click="trigglePrev"></GI>
          <div v-show="!!prev" class="go_title">{{ prev }}</div>
        </div>
      </div>

      <div class="go_next" @click="triggleNext">
        <div v-show="!!next" class="go_title">
          {{ next }}
        </div>
        <GI v-show="!!next" :name="globalTheme == 'light' ? 'global_next' : 'global_next_light'" ws="32" hs="16" @click="triggleNext"></GI>
        <slot></slot>
      </div>
    </div>
    <div class="go_title">
      <h2 :style="{ fontSize: status == 'live' ? '32px' : '24px' }" class="title">{{ title }}</h2>
      <!-- 如果是live 则显示，archive 则不显示 -->
      <BtnPop v-if="status == 'live'" :left="-100">
        <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
        <div slot="target" class="more_icon" v-if="lenArrayShow"><GI ws="24" hs="24" name="brand_more"></GI></div>
        <div slot="main" class="type_main">
          <div v-for="(item, index) in tagList" :key="index" class="type_content">
            <div @click="goRoute(item)" class="type_line">
              <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
              <span class="type_title">{{ item.val }}</span>
            </div>
          </div>
        </div>
      </BtnPop>
    </div>
    <div class="go_hoster">
      <h2>{{ langJson('By LUMA Events') }}</h2>
      <div v-if="status == 'live'" class="live" :style="{ backgroundImage: `url(/source/png/brand/live_mobile.png)` }"></div>
      <div v-if="status == 'archive'" class="archive" :style="{ backgroundImage: `url(/source/png/brand/archive_mobile.png)` }"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prev: {
      type: String,
      default: '',
    },
    next: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'live',
    },
    title: {
      type: String,
      default: 'Skechers Sundown Festival',
    },
    tagList: {
      type: Array,
      default: [],
    },
    lenArrayShow:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    trigglePrev() {
      this.$emit('prev')
    },
    triggleNext() {
      this.$emit('next')
    },
    goRoute(item) {
      switch (item.number) {
        case 1:
          this.jump_brand_compaign_preview({ isBrand: true })
          break

        default:
          break
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.go {
  .go_center {
    .go_next {
      display: flex;
      align-items: center;
      cursor: pointer;
      .go_title {
        margin-right: 16px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
      }
    }
    .go_prev {
      display: flex;
      align-items: center;
      cursor: pointer;
      .go_title {
        margin-left: 16px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_deep_color);
      }
      .live {
        width: 88px;
        height: 36px;
        background: #e7f8e8;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #2ca631;
        border-radius: 32px;
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .archive {
        width: 88px;
        height: 36px;
        background: #ffe0e0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--danger_color);
        border-radius: 32px;
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    width: 100%;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  padding-bottom: 24px;
  width: 100%;
  margin: auto;
  max-width: var(--max_width);
  box-sizing: border-box;
  .go_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      width: 335px;
      text-overflow: ellipsis;
      line-height: 48px;
      white-space: nowrap;
      overflow: hidden;
      color: var(--font_deep_color);
    }
    .more_icon {
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      background: var(--color_white);
      border: 1px solid var(--border_color);
      border-radius: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .type_main {
      padding: 24px;
      background: var(--color_white);
      border: 1px solid var(--border_color);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
      border-radius: 24px;
      cursor: pointer;

      .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child {
          color: var(--danger_color);
        }
        &:not(:last-child) {
          color: var(--font_deep_color);
        }

        .type_line {
          display: flex;
          align-items: center;
          gap: 8px;

          .title {
            white-space: nowrap;
            font-size: 14px;
            font-family: 'Lato';
          }
        }
      }
    }
  }
}
.go_hoster {
  display: flex;
  align-items: center;
  gap: 13px;
  h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--font_deep_color);
  }
  .live {
    width: 72px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .archive {
    width: 90px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
