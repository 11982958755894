<template>
  <div class="item">
    <div
      class="item_image"
      :style="{
        height: `${hs}px`,
        width: `${ws}px`,
        backgroundImage: `url(/source/png/image.jpeg)`,
      }"
    ></div>
    <div class="item_info">
      <div class="item_title">
        {{ langJson('Skechers Sundown Festival') }}
      </div>
      <div class="item_belong">
        <div
          class="belong_image"
          :style="{
            backgroundImage: `url(/source/png/profile.jpeg)`,
          }"
        ></div>
        <div class="belong_name">{{ langJson('Asians For Asia') }}</div>
      </div>
      <div class="item_desc">
        {{ langJson('Art Meets Tech at Fast-Growing Festival Highlighting Innovative Storytellers from Around the World') }}
      </div>
      <VI coll="334" zan="13" view="3343"></VI>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ws: {
      type: [String, Number],
      default: '100%',
    },
    // 图片高度
    hs: {
      type: [String, Number],
      default: 'auto',
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  .item_info {
    .item_tags {
      .tag_item {
        margin-right: 12px;
        &:last-child {
          margin-right: 0px;
        }
      }
      margin-top: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .item_desc {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
      color: var(--font_deep_color);

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .item_belong {
      .belong_name {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_deep_color);
        margin-left: 8px;
      }
      .belong_image {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
      }
      margin-top: 8px;
      display: flex;
      align-items: center;
    }
    .item_title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: var(--font_deep_color);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    flex: 1;
  }
  .item_image {
    width: 100%;
    border-radius: 32px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 16px;
  }
  display: flex;
}
@media screen and (max-width:1081px) {
  .item_image {
    border-radius: 12px;
  }
  
}
</style>
