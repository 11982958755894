<template>
    <Model :title="langJson('Add new participants')" :padding="'24px 28px'" :headerGap="isPhone ? '36px' : '82px'" v-model="modelShow" @close="closePage">
        <div class="notify_main" slot="main">
            <div class="notify_middle notify_common">
                <FormItem v-model="form.firstName" type="text" :matchs="['unull','maxName']" :title="langJson('First Name')"></FormItem>
            </div>

            <div class="notify_middle notify_common">
                <FormItem v-model="form.lastName" type="text" :matchs="['unull','maxName']" :title="langJson('Last Name')"></FormItem>
            </div>

            <div class="notify_middle notify_common">
                <FormItem v-model="form.contactNo" type="text" :matchs="['unull','maxName']" :title="langJson('Contact No.')"></FormItem>
            </div>
            <div class="notify_middle notify_common">
                <FormItem v-model="form.email" type="text" :matchs="['unull', 'email']" :title="langJson('Email')"></FormItem>
            </div>
            <div class="notify_btns notify_common">
                <BTN type="detail" class="btn1" :bh="isPhone ? '48' : '52'" @handle="close">{{ langJson('Cancel') }}</BTN>
                <!-- 需要判断 -->
                <BTN type="active" class="btn2" :disable="formSetSend || !pass" :load="load_btn" :bh="isPhone ? '48' : '52'" @handle="submit">{{ langJson('Confirm') }}</BTN>
            </div>
        </div>
    </Model>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: '',
                contactNo: '',
                firstName: '',
                lastName: ''
            },
            modelShow: false,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show(n) {
            this.modelShow = n
        },
        modelShow(n) {
            this.$emit('change', n)
        },
    },
    computed:{
        formSetSend(){
            let str = false
            for (let key in this.form) {
                if (!this.form[key]) {
                    return true
                }
            }
            return str
        },
        pass() {
            try {
                let { email, firstName, contactNo, lastName } = this.form
                let result = this.checkPass(firstName, ['unull','maxName'], false) && 
                this.checkPass(email, ['unull', 'email'], false) && 
                this.checkPass(contactNo, ['unull','maxName'], false) && 
                this.checkPass(lastName, ['unull','maxName'], false)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    model: {
        event: 'change',
        prop: 'show',
    },
    methods: {
        async submit() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let result = await dispatch('clientSave', this.form)
                if(result && result.code == 200) {
                    this.close()
                }
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
        close() {
            this.closePage()
            this.$emit('change', false)
        },
        closePage(){
            this.form = {
                email: '',
                contactNo: '',
                firstName: '',
                lastName: ''
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.notify_main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    width: 557px;
    .notify_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 82px;
        h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
        }
    }
    .tips {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color);
    }
    .notify_btns {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
    }
}

@media only screen and (max-width: 1080px) {
    /* 上面padding 25-12 = 13 */
    .notify_main {
    }
    .notify_btns {
        row-gap: 20px;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-template-areas: 'btn1' 'btn2';
        .btn1 {
            grid-area: btn2;
        }
        .btn2 {
            grid-area: btn1;
        }
    }
    .notify_top {
        gap: 36px !important;
        align-items: center;
        h2 {
            font-weight: 700;
            font-size: 18px !important;

            line-height: 28px;
        }
    }
}
@media screen and (max-width: 500px) {
    .notify_main {
        width: 74.4vw;
    }
}
</style>