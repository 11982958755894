import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 默认路由
let routes = [
    {
        path: '*',
        name: '*',
        component: () => import('@/page/404.vue'),
        meta: {
            roles: [],
        },
    },
    {
        path: '/',
        name: '/',
        component: () => import('@/page/startup/index.vue'),
        meta: {
            roles: [],
        },
    },
]

// 自动读取路由
let map = require.context('../page', true, /\/.+\.vue$/)
map.keys().forEach((item) => {
    let name = item.replace(/^\.\//, '').split('.')[0]
    let path = item.split('.')[1]
    let component = () => import(`@/page${item.slice(1)}`)
    routes.push({
        path,
        name,
        component,
        meta: {
            roles: [],
        },
    })
})

// 重写传参路由
let replacePath = [
    {
        before: '/participant/campaign/artwork/submit',
        after: '/participant/campaign/artwork/submit/:id',
    },
]

routes = routes.map((item) => {
    let exit = replacePath.find((subItem) => {
        return item.path == subItem.before
    })
    if (exit) {
        item.path = exit.after
    }
    return item
})

// 实例化
let router = new Router({
    mode: 'history',
    base: '/',
    routes,
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
