<template>
    <div>
        <div class="header">
            <h2>{{ langJson('Add your rewards') }}</h2>
            <span>{{ langJson('Elevate user engagement and foster loyalty by integrating reward programs into your campaign.') }}</span>
        </div>
        <div>
            <div v-for="(item, index) in form" :key="index" class="form">
                <div class="form_box">
                    <h2 class="form_box_title">{{ langJson('Prize') }} {{ index + 1 }}</h2>
                    <div class="form_input">
                        <FormItem v-model="item.title" class="information_item" type="text" :title="langJson('Title')"></FormItem>
                    </div>
                    <div class="form_input">
                        <FormItem v-model="item.desc" class="information_item" type="textarea" :title="langJson('Description')"></FormItem>
                    </div>
                    <div class="form_money">
                        <div class="title">
                            {{ langJson('Amount') }}
                            <!-- <span class="required">*</span> -->
                        </div>

                        <div class="money_item">
                            <SearchPop v-model="item.open">
                                <div slot="target" class="symbol_main" @click="item.open = true">
                                    <div class="symbol_icon bgCover" :style="{ backgroundImage: `url(${item.symbol.icon})` }"></div>
                                    <div class="symbol_name">{{ item.symbol.name }}</div>
                                    <GI class="icon_down" name="amount-arrow" ws="16" hs="16"></GI>
                                </div>
                                <div slot="main" class="symbol_list">
                                    <div class="symbol_item" v-for="(token, tokenIndex) in globalToken" :key="tokenIndex" @click="setSymbol(index, token)">
                                        <div class="symbol_icon bgCover" :style="{ backgroundImage: `url(${token.icon})` }"></div>
                                        <div class="symbol_name">{{ token.name }}</div>
                                    </div>
                                </div>
                            </SearchPop>
                            <div class="line"></div>
                            <IN class="money_amount" :placeholder="langJson('Amount')" v-model="item.amount" icon="" :noBorder="noBorder"></IN>
                        </div>
                    </div>
                    <div class="form_input">
                        <FormItem v-model="item.image" :title="langJson('Brand Description')" class="information_item" type="image" :image="bannerConfig" :uploadMsg="langJson('Recommended ratio: 1 × 1')" :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')"></FormItem>
                    </div>
                </div>
            </div>
            <div class="btns">
                <div class="btnLeft">
                    <BTN @handle="pushPrize" icon_left="add-rewards" type="detail" bh="52" bw="165">{{ langJson('Add reward') }}</BTN>
                    <BTN v-show="form.length > 1" @handle="delPrize" icon_left="add-rewards_1" type="detail" bh="52" bw="165">{{ langJson('Delete reward') }}</BTN>
                </div>
                <div class="next_step">
                    <span>{{ langJson('Your changes have been saved') }}</span>
                    <div>
                        <BTN @handle="goBasics" type="active" bh="52" bw="152" class="BtnLeft">{{ langJson('Last: Basics') }}</BTN>
                        <BTN @handle="goLinks" type="active" bh="52" bw="152">{{ langJson('Next: Links') }}</BTN>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bannerConfig: {
                type: 'big',
            },
            form: [
                {
                    title: '',
                    desc: '',
                    symbol: {},
                    amount: '',
                    image: '',
                    open: false,
                },
            ],
            model: {
                title: '',
                desc: '',
                symbol: {},
                amount: '',
                image: '',
                open: false,
            },

            list: [
                {
                    lable: this.langJson('USD'),
                    value: '',
                },
            ],
        }
    },
    props: {
        screenValue: {
            type: [String, Number],
            default: '1081',
        },
    },
    inject: ['switchTab'],
    computed: {
        noBorder() {
            if (this?.globalTheme == 'night') {
                return true
            }
            return false
        },
    },
    watch: {
        form: {
            handler(newVal) {
                let arr = newVal.map((item) => {
                    return {
                        id: item.id,
                        rewardTitle: item.title,
                        rewardDescription: item.desc,
                        rewardCoinId: item.symbol.id,
                        rewardAmount: item.amount,
                        rewardBrandDescription: item.image,
                    }
                })
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    campaignAwards: arr,
                })
            },
            deep: true,
        },
        screenValue: {
            handler(newVal) {
                if (newVal >= 1081) {
                    this.bannerConfig.type = 'big'
                } else if (newVal < 1081 && newVal > 500) {
                    this.bannerConfig.type = 'big'
                } else {
                    this.bannerConfig.type = 'small'
                }
            },
            immediate: true,
        },
    },
    created() {
        this.setSymbol(0, this.globalToken[0])
        this.initData()
    },
    methods: {
        initData() {
            let { campaignAwards } = this.compaignDetail

            campaignAwards &&
                campaignAwards.forEach((item, index) => {
                    let { rewardTitle, rewardDescription, rewardCoinId, rewardAmount, rewardBrandDescription, id } = item
                    this.$set(this.form, index, {
                        id,
                        title: rewardTitle,
                        desc: rewardDescription,
                        symbol: this.globalToken.find((tokenItem) => {
                            return tokenItem.id == rewardCoinId
                        }),
                        amount: rewardAmount,
                        image: rewardBrandDescription,
                        open: false,
                    })
                })
        },
        goBasics() {
            this.switchTab(1)
        },
        goLinks() {
            this.switchTab(3)
        },
        setSymbol(index, symbol) {
            this.$set(this.form[index], 'symbol', symbol)
        },
        pushPrize() {
            this.form.push(JSON.parse(JSON.stringify(this.model)))
            this.setSymbol(this.form.length - 1, this.globalToken[0])
        },
        delPrize() {
            this.form.pop()
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.required {
    color: var(--danger_color);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }
    span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_modif_color);
    }
}
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    .form_box {
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .information_item {
            width: 100%;
        }
        .form_box_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        .form_money {
            .title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_color);
            }
            .symbol_list {
                .symbol_item {
                    .symbol_icon {
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        background-color: var(--color_white);
                    }
                    .symbol_name {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        margin: 0 8px;
                        width: calc(100% - 36px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 16px;
                    cursor: pointer;
                    height: 40px;
                    border-bottom: 1px solid var(--color_white);
                }

                .symbol_item:last-child {
                    border-bottom: none;
                }
                .symbol_item:hover {
                    background-color: var(--color_white);
                }
                width: 100%;
                background: var(--bg_color);
                border-radius: 32px;
            }
            .symbol_main {
                .symbol_icon {
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    background-color: #fff;
                }
                .symbol_name {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                    margin: 0 8px;
                    width: 60px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 16px;
                height: 52px;
            }
            .money_item {
                .money_amount {
                    flex: 1;
                    /deep/ .input_style {
                        border: none;
                    }
                }
                .line {
                    height: 24px;
                    border-right: 1px solid var(--border_color);
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                background: var(--bg_color);
                border-radius: 32px;
                margin-top: 12px;
            }
        }
        .form_input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
        }
    }
}
.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 32px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btnLeft {
        display: flex;
        align-items: center;
        column-gap: 24px;
    }
    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        /* margin-bottom: 65px; */
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
        div {
            display: flex;
            .BtnLeft {
                margin-right: 15px;
            }
        }
    }
}
@media screen and (max-width: 1080px) {
    .form {
        padding: 20px;
    }
    .btns {
        flex-direction: column;
        gap: 24px;
    }
    .header {
        h2 {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        span {
            font-size: 14px;
        }
    }
    .next_step {
        gap: 12px !important;
        flex-direction: column;
        align-items: flex-start !important;
    }
}
@media screen and (max-width: 580px) {
    .form {
        .form_box {
            .form_money {
                .money_item {
                    .money_amount {
                        /deep/ .input_main {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
</style>
