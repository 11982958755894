<template>
    <div :class="['form_item', `form_${type}`]">
        <div :class="[typeConfig('titleStyle') ? typeConfig('titleStyle') : 'title']">
            {{ title }}
            <span class="optional" v-if="optional">{{ langJson('(optional)') }}</span>
            <span class="required" v-if="!!matchs.length">*</span>
        </div>
        <div class="input_content">
            <UploadFileSass :disabled="disabled2" :support="support" ref="UploadFile" :size="size" :isDetail="isDetail" v-if="type == 'mp4'" @change="triggleSource" :msg="uploadMsg" :tip="uploadTip" v-model="target" :type="typeConfig('type')"></UploadFileSass>

            <UploadFile v-if="type == 'image'" :size="size" @source="triggleSource" :msg="uploadMsg" :tip="uploadTip" v-model="target" :type="typeConfig('type')"></UploadFile>

            <!-- 拍照 -->
            <div class="input_text" :style="{ background: mobileColor }" v-else-if="type == 'camera'">
                <IN :placeholder="placeholder" :matchs="matchs" v-model="target" :icon="icon" type="file" accept="image/*" capture="camera">
                    <div slot="append">
                        <slot name="text_append"></slot>
                    </div>
                </IN>
            </div>

            <div class="input_text" :style="{ background: mobileColor }" v-else-if="type == 'text'">
                <IN :placeholder="placeholder" :disabled="disabled" :matchs="matchs" :max="max" v-model="target" :icon="icon" inputNumber="255">
                    <div slot="append">
                        <slot name="text_append"></slot>
                    </div>
                </IN>
            </div>

            <div class="input_text" :style="{ background: mobileColor }" v-else-if="type == 'password'">
                <IN type="password" :disabled="disabled" :matchs="matchs" :max="max" :placeholder="placeholder" v-model="target" icon=""></IN>
            </div>

            <div class="input_text" v-else-if="type == 'select'">
                <Sel :list="list" :disabled="disabled" :placeholder="placeholder" :size="0" v-model="target"></Sel>
            </div>

            <div class="input_text" v-else-if="type == 'textarea'">
                <textarea class="input" :disabled="disabled" :style="height && { height }" :placeholder="placeholder" v-model="target"></textarea>
            </div>

            <div class="input_text" v-else-if="type == 'collection'">
                <div class="project_list">
                    <div class="item_add item_tag" @click="$emit('pushColl')">
                        <GI name="mint_addpic" ws="24" hs="24" @click="$emit('pushColl')"></GI>
                    </div>
                    <div class="item_tag">
                        <Tag :title="`Collection Name`" type="primary" size="big">0x55959...F123B1</Tag>
                    </div>
                    <div class="item_tag" v-for="(item, index) of 6" :key="index">
                        <Tag :title="`Collection Name`" size="big">0x55959...F123B1</Tag>
                    </div>
                </div>
            </div>

            <div class="input_text" v-else-if="type == 'properties'">
                <div class="project_list">
                    <div class="item_add item_tag" @click="$emit('pushProp')">
                        <GI name="mint_addpic" ws="24" hs="24" @click="$emit('pushProp')"></GI>
                    </div>
                    <div class="item_tag" v-for="(item, index) of 2" :key="index">
                        <Tag :title="`Propertie`" size="big">Propertie Value</Tag>
                    </div>
                </div>
            </div>
            <div class="input_text" v-else-if="type == 'amount'">
                <div class="project_list">
                    <div class="item_sel">
                        <AmountSel :list="list" :placeholder="placeholder" v-model="target"></AmountSel>
                        <div class="line"></div>
                        <IN :placeholder="placeholder" :matchs="matchs" :max="max" v-model="target" icon=""></IN>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            target: '',
            disabled2: false,
        }
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        isDetail: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        // text image password select textarea collection properties
        type: {
            type: String,
            default: 'text',
        },
        mobileColor: {
            type: String,
            default: '',
        },
        uploadTip: {
            type: String,
            default: '',
        },
        uploadMsg: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        image: {
            type: Object,
            default: () => {
                return {
                    type: 'small',
                    titleStyle: 'title',
                }
            },
        },
        mp4: {
            type: Object,
            default: () => {
                return {
                    type: 'small',
                    titleStyle: 'title',
                }
            },
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        matchs: {
            type: Array,
            default: () => {
                return []
            },
        },
        max: {
            type: [Number, String],
            default: '',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        size: {
            type: [Number, String],
            default: 5,
        },
        support: {
            type: Array,
            default: () => {
                return ['video']
                // return ['image', 'video', 'audio', 'glb']
                // 'text' 并不支持 text/html
            },
        },
    },
    created() {
        this.target = this.value
    },
    watch: {
        value(n) {
            this.target = n
        },
        target(n) {
            this.$emit('update', n)
            this.$emit('change', n)
        },
        disabled: {
            handler(val) {
                this.disabled2 = val
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        typeConfig() {
            return (key) => {
                return this[this.type] && key in this[this.type] ? this[this.type][key] : ''

                // mp4
            }
        },
    },
    methods: {
        triggleSource(val) {
            return this.$emit('source', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.optional {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--border_color);
    margin-left: 4px;
}
.required {
    color: var(--danger_color);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
}
.form_properties {
    .input_content {
        .input_text {
            .project_list {
                .item_add {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                    width: 70px;
                    height: 70px;
                    border-radius: 16px;
                    cursor: pointer;
                }
                .item_tag {
                    margin: 12px 12px 0 0;
                }

                display: flex;
                align-items: center;
                align-content: flex-start;
                flex-wrap: wrap;
            }
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* color: var(--font_color); */
        color: var(--font_color_black);
    }
}
.form_collection {
    .input_content {
        .input_text {
            .project_list {
                .item_add {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                    width: 70px;
                    height: 70px;
                    border-radius: 16px;
                    cursor: pointer;
                }
                .item_tag {
                    margin: 12px 12px 0 0;
                }
                display: flex;
                align-items: center;
                align-content: flex-start;
                flex-wrap: wrap;
            }
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* color: var(--font_color); */
        color: var(--font_color_black);
    }
}
.form_image,
.form_mp4 {
    .input_content {
        margin-top: 24px;
        display: flex;
    }
    .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--font_deep_color);
    }
    .title_small {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_deep_color);
    }
}

.form_password {
    .input_content {
        margin-top: 12px;
        .input_text {
            background: var(--bg6);
            border-radius: var(--borderRadius);
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--font_color_black);
        /* color: var(--font_color); */
    }
}
.form_text {
    .input_content {
        margin-top: 12px;
        .input_text {
            background: var(--bg6);
            border-radius: var(--borderRadius);
            position: relative;
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* color: var(--font_color); */
        color: var(--font_color_black);
    }
}

.form_textarea {
    .input_content {
        margin-top: 12px;
        .input_text {
            .input {
                border: none;
                outline: none;
                background: transparent;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
                width: 100%;
                padding: 10px 24px;
                border-radius: 10px;
                box-sizing: border-box;
                height: 160px;
            }
            border: 1px solid var(--borderColor1);
            background: var(--bg6);
            border-radius: var(--borderRadius);
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* color: var(--font_color); */
        color: var(--font_color_black);
    }
}

.form_select {
    .input_content {
        margin-top: 12px;
        .input_text {
            background: var(--bg6);
            border-radius: var(--borderRadius);
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* color: var(--font_color); */
        color: var(--font_color_black);
    }
}
.form_amount {
    .input_content {
        margin-top: 12px;
        .input_text {
            background: var(--bg6);
            border-radius: var(--borderRadius);
            .item_sel {
                display: flex;
                align-items: center;
                .line {
                    height: 24px;
                    border-right: 1px solid var(--border_color);
                    margin-left: 16px;
                }
            }
        }
    }
    .title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* color: var(--font_color); */
        color: var(--font_color_black);
    }
}
@media screen and (max-width: 1080px) {
    .form {
        .form_box_upload {
            .information_item {
                .textarea {
                    /* margin-top:0 !important; */
                }
            }
        }
    }
}
</style>
