<template>
    <div class="table_main">
        <div class="table_body" :style="{ gap: showStatus == 'live' ? '0px' : '20px' }">
            <div class="table_column_all" v-for="(item, index) in list" :key="index">
                <!-- 表头 -->

                <div ref="row1" class="row_1">
                    <div class="check_name">
                        <CheckBox v-if="CheckBoxShow" @click.native="selectOneItem(item)" :active="selected(item)"></CheckBox>
                        {{ list[index]['nickName'] }}
                    </div>
                    <div class="date">
                        <div>{{ list[index]['date'] }}</div>

                        <div class="arrow" @click="showHide(index)" ref="arrow"><GI class="pointer" name="select_down" ws="24" hs="24"></GI></div>
                    </div>
                </div>

                <div ref="all_rows" class="all_rows">
                    <div class="table_row" v-for="(subItem, subIndex) in listKey" :key="subIndex">
                        <div v-if="title[subItem].type == 'text' && subIndex != 0">
                            <div style="display: flex; justify-content: space-between">
                                <div>{{ title[subItem].title }}</div>
                                <div class="text">{{ list[index][subItem] }}</div>
                            </div>
                        </div>
                        <div v-else>
                            <slot :name="subItem" :data="item"></slot>
                        </div>
                    </div>

                    <!-- 按钮，根据play数组和 -->
                    <div v-if="play.length" class="table_column play">
                        <BTN class="btn_item" v-for="(subItem, subIndex) in play" @handle="triggleEvent(subItem.event, list[index])" :key="subIndex" :type="subItem.type" size="mini" bh="44" :load="!!loadItem">{{ subItem.title }}</BTN>
                    </div>
                </div>
            </div>
            <nu-data v-if="!lenArray(list)"></nu-data>
        </div>

        <div class="table_foot">
            <slot name="foot"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        play: {
            type: Array,
            default: () => {
                return []
            },
        },
        title: {
            type: Object,
            default: () => {
                return {}
            },
        },
        loadItem: {
            type: [String, Number],
            default: '',
        },
        commonStyle: {
            type: Boolean,
            default: false,
        },
        gap: {
            type: String,
            default: '0px',
        },
        rowRadius: {
            type: String,
            default: '0px',
        },
        showStatus: {
            type: String,
            default: 'live',
        },
        CheckBoxShow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            seletelist: [],
        }
    },
    mounted() {
        Object.keys(this.$slots).forEach((item) => {
            // console.log(item)
        })
        // 进入页面遍历元素
        this.list.forEach((item, index) => {
            this.showHide(index)
        })
    },
    watch: {
        seletelist(n) {
            this.$emit('change', n)
        },
    },
    computed: {
        haveData() {
            return this.list && this.list.length
        },
        // title数组中的key值组成的表头数组
        listKey() {
            return Object.keys(this.title)
        },
        selected() {
            return (item) => {
                try {
                    let result = this.seletelist.some((select) => {
                        return select == item.userId
                    })
                    return result
                } catch (err) {
                    console.log(err)
                }
                return false
            }
        },
    },
    methods: {
        triggleEvent(event, item) {
            this.$emit(event, item)
        },
        showHide(index) {
            //点击展开收起
            let contant = this.$refs.all_rows[index] //这里我们通过参数index来让浏览器判断你点击的是哪一个列表
            let height = contant.getBoundingClientRect().height //获取页面元素的当前高度
            let row1 = this.$refs.row1[index]
            this.$refs.arrow[index].style.transform = !!height ? `rotate(${-90}deg)` : `rotate(${0}deg)`
            // 如果有高度
            if (!!height) {
                contant.style.height = height + 'px'
                let f = document.body.offsetHeight //强制相应dom重绘，使最新的样式得到应用
                contant.style.height = '0px'
                row1.style.borderRadius = '0px'
                // 展示底部边框
                row1.className = 'row_1'
            } else {
                // 没有高度
                contant.style.height = 'auto'
                height = contant.getBoundingClientRect().height
                contant.style.height = '0'
                let f = document.body.offsetHeight
                contant.style.height = height + 'px'
                // 修改类名隐藏底部边框
                row1.className = 'row_2'
                row1.style.borderRadius = '16px'
            }
        },
        selectOneItem(item) {
            let exit = this.seletelist.some((select) => {
                return select == item.userId
            })
            if (exit) {
                this.seletelist = this.seletelist.filter((select) => {
                    return select != item.userId
                })
            } else {
                this.seletelist.push(item.userId)
            }
        },
        selectAll() {
            this.isAllChecked = !this.isAllChecked
            if (this.isAllChecked) {
                this.seletelist = this.list.map((item) => {
                    return item.userId
                })
            } else {
                this.seletelist = []
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.table_main {
    box-sizing: border-box;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_deep_color);
    letter-spacing: 1px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.table_body {
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.table_column_all {
    overflow: hidden;
    box-sizing: border-box;
    .check_name {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 700;
    }
    .date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
    }
    .row_1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--color_white);
        padding: 12px 16px;
        box-sizing: border-box;
        position: relative;
    }
    &:not(:last-child) {
        .row_1::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid var(--border_color);
            box-sizing: border-box;
        }
    }
    .row_2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--color_white);
        padding: 12px 16px;
        box-sizing: border-box;
    }
    .all_rows {
        display: flex;
        flex-direction: column;
        gap: 12px;
        background: var(--bg_color);
        .text {
            color: var(--font_color);
        }
    }
}
.play {
    margin-bottom: 20px;
}
.arrow {
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
}
.fade-enter-active,
.fade-leave-active {
    transition: all 0.6s ease;
}
.fade-leave-to .fade-enter {
    transition: all 0.6s ease;
    opacity: 0;
}
</style>
