var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_parent"},[_c('div',{staticClass:"main_box",style:({ transform: `rotateX(-0deg) rotateY(${_vm.boxRotate}deg)` })},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"main_item",style:({
            transform: `rotateY(${_vm.currentRotate(index)}deg) translateZ(48vw) scale(${_vm.scale(index)})`,
            width: `${_vm.width * 1}px`,
            height: `${_vm.height * 1}px`,
            opacity: `${_vm.scale(index) >= 0.7 ? 1 : 0}`,
            left:`calc(50% - ${_vm.width / 2}px)`,
        }),on:{"click":function($event){return _vm.jump_staking_detail(1)}}},[_c('div',{staticClass:"item_image",style:({ backgroundImage:`url(${item.image})` })}),_c('div',{staticClass:"item_position"},[_c('div',{staticClass:"title"},[_vm._v("Why is it all so abstract?")]),_c('div',{staticClass:"desc"},[_vm._v("Official is a NFT collection of 20,001 PF Avatars that will give you membership access to The Yard. It starts with exclusive Giveaways among other top collections, regular NFT drops, contests and Treasury Hunt even, and much more that will be revealed over time. ")]),_c('div',{staticClass:"item_price"},[_c('div',{staticClass:"item_lable"},[_vm._v(" "+_vm._s(_vm.langJson("Profit"))+" ")]),_vm._m(0,true)])])])}),0),_c('div',{staticClass:"main_left",on:{"click":_vm.prev}},[_c('div',{staticClass:"icon"},[_c('GI',{attrs:{"name":"msyter_left_white","ws":"18","hs":"18"},on:{"click":_vm.prev}})],1)]),_c('div',{staticClass:"main_right",on:{"click":_vm.next}},[_c('div',{staticClass:"icon"},[_c('GI',{attrs:{"name":"msyter_right_white","ws":"18","hs":"18"},on:{"click":_vm.next}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_value"},[_c('div',{staticClass:"amount"},[_vm._v("1 ETH")]),_c('div',{staticClass:"usdtAmount"},[_vm._v("≈ $1284.45")])])
}]

export { render, staticRenderFns }