<template>
    <div class="head">
        <div class="head_center">
            <div class="head_left">
                <div v-show="viewObj.logo" @click="viewObj.create ? jump_participant_compaign() : jump_brand_compaign()" class="logo logo_bg cursor" :style="logoStyle"></div>
                <!-- <GI v-if="isPhone" ws="24" hs="24" :name="globalTheme == 'light' ? 'search' : 'search_light'"></GI> -->

                <!-- <template v-if="isPhone">
                    <Search v-show="viewObj.create" v-model="globalMarket.campaignBrandName" class="searchTop">
                        <template v-slot:text_append><GI name="search"></GI></template>
                    </Search>
                    <Search v-show="!viewObj.create" v-model="globalBrand.myCompaignName" class="searchTop">
                        <template v-slot:text_append><GI name="search"></GI></template>
                    </Search>
                </template> -->

                <div v-show="viewObj.search && !isPhone" class="search">
                    <IN v-show="viewObj.create" v-model="globalMarket.campaignBrandName" :placeholder="langJson('Search')"></IN>
                    <IN v-show="!viewObj.create" v-model="globalBrand.myCompaignName" :placeholder="langJson('Search')"></IN>
                </div>

                <div v-if="isPhone" @click="openMenu" class="menu">
                    <GI name="index_menu" ws="24" hs="24"></GI>
                </div>
            </div>
            <div v-if="!isPhone" class="head_right">
                <div v-show="viewObj.link" class="link_item" v-for="(item, index) in navlist" :key="index">
                    <div :class="['link_inner', item.matching.test(routePath) && 'link_active']" @click="jump_page_path(item.path)">
                        {{ item.title }}
                    </div>
                </div>
                <div v-show="viewObj.link" class="link_item">
                    <BtnPop>
                        <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                        <div class="link_inner" slot="target">
                            {{ langJson('Resources') }}
                        </div>
                        <div slot="main" class="type_main_resources">
                            <div v-for="(item, index) in tagListResources" :key="index" class="type_content">
                                <div @click="goRouteResources(item)" class="type_line">
                                    <span class="title">{{ item.val }}</span>
                                </div>
                            </div>
                        </div>
                    </BtnPop>
                </div>
                <div v-show="viewObj.create" class="btn_item">
                    <!-- jump_mint -->
                    <BTN type="active" @handle="showSubmit">{{ langJson('Create NFTs') }}</BTN>
                </div>
                <div v-show="!viewObj.create" class="btn_item">
                    <BTN type="active" @handle="goCreate">{{ langJson('Create new campaign') }}</BTN>
                </div>
                <!-- <div v-show="viewObj.portrait" class="portrait" @click="jump_personal('0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1')" :style="{ backgroundImage: `url(/source/png/user.jpeg)` }"></div> -->
                <!-- <div v-show="viewObj.portrait" class="portrait" @click="jump_personal('0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1')" :style="{ backgroundImage: `url(/source/png/user.jpeg)` }"></div> -->
                <BtnPop v-if="viewObj.portrait">
                    <div slot="target" class="portrait" :style="{ backgroundImage: `url(${userInfo.imageUrl})` }"></div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="item.icon"></GI>
                                <span class="title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
                <!-- <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI> -->
                <!-- 准备链接钱包 -->
                <!-- <BtnPop>
        
          <div slot="target" v-show="viewObj.wallet" class="wallet">
            <GI name="global_wallet" ws="30" hs="30"></GI>
          </div>
          <div slot="main" class="type_main_wallet">
            <h2 class="title_wallet">{{ langJson('Connect wallet') }}</h2>
            <div>
              <BTN icon_left="metamask">{{ langJson('Metamask') }}</BTN>
            </div>
          </div>
        </BtnPop> -->
                <!-- 链接钱包成功 -->
                <!-- <BtnPop v-if="walletDetail.address" :left="-270">
                    <div slot="target" v-show="viewObj.wallet" class="wallet">
                        <GI name="global_wallet" ws="30" hs="30"></GI>
                    </div>
                    <div slot="main" class="type_main_wallet_success">
                        <div class="upper">
                            <h2 class="title_wallet">{{ langJson('Connect with Metamask') }}</h2>
                            <div class="wallet_address">
                                <span>{{ walletDetail.address | hideStr }}</span>
                                <div class="wallet_copy" @click="copy(walletDetail.address)">
                                    <GI ws="24" hs="24" name="wallet_copy"></GI>
                                </div>
                            </div>

                            <div class="line"></div>
                            <div class="total_balance">
                                <span>{{ langJson('Total balance') }}</span>
                                <h2>$ 1,000</h2>
                            </div>
                        </div>
                        <div>
                            <BTN @handle="disconnect" type="warn" bw="248">{{ langJson('Disconnect') }}</BTN>
                        </div>
                    </div>
                </BtnPop> -->
                <div class="wallet" @click="showlink">
                    <!-- <GI name="global_wallet" ws="30" hs="30"></GI> -->
                    <BtnPop v-if="viewObj.portrait">
                        <GI slot="target" name="global_wallet" ws="30" hs="30"></GI>
                        <div v-if="!wallet" slot="main" class="connect_wallet">
                            <h2 class="title_wallet">{{ langJson('Connect wallet') }}</h2>
                            <div>
                                <BTN bh="48" fontSize="14px" @handle="modelShow = true" icon_left="metamask_left">{{ langJson('Metamask') }}</BTN>
                            </div>
                        </div>
                    </BtnPop>
                </div>

                <div v-show="viewObj.theme" class="theme">
                    <Theme></Theme>
                </div>
            </div>
        </div>

        <!-- <WalletTip v-model="head_tip" @confirm="showSubmit"></WalletTip>
        <WalletTip v-model="create_tip" @confirm="goCreate"></WalletTip> -->
    </div>
</template>
<script>
import { setCookie } from '@/assets/js/cookie'
export default {
    props: {
        viewObj: {
            type: Object,
            default: () => {
                return {
                    menu: true,
                    logo: true,
                    search: true,
                    link: true,
                    create: true,
                    newCompagin: false,
                    portrait: true,
                    wallet: true,
                    theme: true,
                }
            },
        },
        navlist: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            head_tip: false,
            create_tip: false,
            // 判断 viewObj.create 为true 则是 participant
            tagListResources: [
                { val: this.langJson('Account Support'), number: 1 },
                { val: this.viewObj.create ? this.langJson('Terms & Condition') : this.langJson('Terms of Service'), number: 2 },
                { val: this.langJson('About NFTWerks'), number: 3 },
            ],
            // brand_nav: [
            //   {
            //     title: this.langJson('My campaigns'),
            //     path: '/brand/compaign/index',
            //     matching: /^\/brand\/compaign.*$/,
            //   },
            //   {
            //     title: this.langJson('Clients'),
            //     path: '/brand/clients/index',
            //     matching: /^\/brand\/clients.*$/,
            //   },
            //   // {
            //   //   title: this.langJson('Resources'),
            //   //   path: '/brand/resources/index',
            //   //   matching: /^\/brand\/resources.*$/,
            //   // },
            // ],
            // participant_nav: [
            //   {
            //     title: this.langJson('Campaigns'),
            //     path: '/participant/campaign/index',
            //     matching: /^\/participant\/campaign.*$/,
            //   },
            //   {
            //     title: this.langJson('My Contacts'),
            //     path: '/participant/contact/index',
            //     matching: /^\/participant\/contact.*$/,
            //   },
            // ],
            tagList: [
                { icon: 'profile_my', val: this.langJson('My profile'), number: 1 },
                { icon: 'profile_setting', val: this.langJson('Settings'), number: 2 },
                { icon: 'brand_exit', val: this.langJson('Log out'), number: 3 },
            ],
            tokenlist: [],
        }
    },
    inject: ['showFrameMenu'],

    computed: {
        wallet() {
            return this.walletDetail.address
        },
        logoStyle() {
            try {
                let { webLogo, webDarknessLogo } = this.globalImage
                if (this.globalTheme != 'light') {
                    webLogo = webDarknessLogo
                }
                return { backgroundImage: `url(${webLogo})`, width: this.isPhone ? `52px` : `80px`, borderRadius: `50%`, height: this.isPhone ? `52px` : `80px` }
            } catch (error) {
                return {}
            }
        },
        routePath() {
            return this.$route.path
        },
    },
    watch: {
        'globalMarket.campaignBrandName'() {
            this.jump_participant_campaign_artwork_joinCampaign()
        },
        'globalBrand.myCompaignName'() {
            this.jump_brand_compaign()
        },
        walletDetail: {
            handler(val) {
                let { chainDetail, address } = val
                this.tokenlist = []
                if (chainDetail && address) {
                    let tokenlist = this.globalToken.filter((item) => {
                        return item.chainId == chainDetail.id
                    })
                    this.tokenlist = tokenlist.map((item) => {
                        return {
                            ...item,
                            balance: 0,
                        }
                    })
                } else {
                    this.tokenlist = []
                }
            },
            deep: true,
        },
    },
    created() {},
    mounted() {},
    methods: {
        create_handle() {
            this.create_tip = true
        },
        mint_nft_handle() {
            this.head_tip = true
        },

        logout() {
            localStorage.setItem('isGoogle', false)
            setCookie('token', '')
            this.$emit('handleOut', 'quit')
            this.jump_startup()
        },
        disconnect() {
            this.$store.commit('walletDetail', {})
            if (window.provider && window.provider.close) {
                window.provider.close()
            }
        },
        showlink() {
            if (this.walletDetail.address) {
                this.$store.commit('AssetStatus', true)
            } else {
                this.$store.commit('linkShow', true)
            }
        },
        goCreate() {
            this.$store.commit('compaignDetail', {})
            this.jump_brand_create_compaign()
        },

        goRoute(item) {
            switch (item.number) {
                case 1:
                    // 判断 viewObj.create 为true 则是 participant
                    if (this.viewObj.create) {
                        // this.jump_participant_campaign_participants_profile({ userId: this.userInfo.id })
                        this.jump_participant_profile_myprofile({ userId: this.userInfo.id })
                    } else {
                        this.jump_brand_profile_myprofile({ idUser: this.userInfo.id })
                        // this.jump_page_path('/brand/profile/myprofile',query)

                        // { idActive: $route.query.id, idUser: detail.brandUserId }
                        // this.jump_brand_profile_editProfile({ userId: this.userInfo.id })
                    }
                    break
                case 2:
                    if (this.viewObj.create) {
                        this.jump_participant_profile_editProfile()
                    } else {
                        this.jump_brand_profile_editProfile()
                    }

                    break
                case 3:
                    this.logout()
                    break

                default:
                    break
            }
        },
        goRouteResources(item) {
            switch (item.number) {
                case 1:
                    if (!this.viewObj.create) {
                        this.jump_brand_profile_editProfile({ tab: 5 })
                    } else {
                        this.jump_participant_profile_editProfile({ tab: 3 })
                    }

                    break
                case 2:
                    if (!this.viewObj.create) {
                        this.jump_article_detail({ articleId: 4 })
                    } else {
                        this.jump_article_detail({ articleId: 5 })
                    }
                    break
                case 3:
                    this.jump_page_path('/startup/article/graphic', { showHead: false })
                    break

                default:
                    break
            }
        },
        openMenu(open = true) {
            this.showFrameMenu(open)
        },
        showSubmit() {
            this.$store.commit('changeshowMintOrJoin', true)
        },
    },
}
</script>
<style lang="scss" scoped>
.theme {
    /deep/ {
        .switch_theme {
            background-color: var(--bg_color3) !important;
        }
    }
}

.head {
    .head_center {
        .head_right {
            .theme {
                margin-left: 24px;
            }

            .wallet {
                .connect_wallet {
                    padding: 24px;
                    background: var(--nft_dialog_bg);
                    border: 1px solid var(--border_color);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                    border-radius: 24px;
                    .title_wallet {
                        // font-family: 'Inter';
                        // font-style: normal;
                        // font-weight: 700;
                        font-size: 14px;
                        font-family: 'Lato';
                        line-height: 18px;
                        /* identical to box height, or 156% */

                        /* Neutral/1 */

                        color: var(--font_deep_color);
                    }
                }
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .portrait {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                margin-right: 24px;

                cursor: pointer;
            }

            .btn_item {
                margin-right: 48px;
            }

            .link_item {
                .link_inner {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                    padding: 12px 0;
                    border-bottom: 2px solid transparent;
                }

                .link_active {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--primary_color);
                    border-bottom: 2px solid var(--primary_color);
                }

                cursor: pointer;
                margin-right: 32px;
            }

            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        .head_left {
            .menu {
                cursor: pointer;
                margin-right: 40px;
            }

            .search {
                margin-left: 32px;
                max-width: 284px;
                background: var(--bg_color);
                border-radius: 10px;
                /* border: 1px solid #FFFFFF; */
            }

            .logo_icon {
                height: 64px;
                width: 64px;
                border-radius: 50%;
                background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
            }

            .logo {
                height: 64px;
                width: 64px;
                border-radius: 50%;
            }

            .logo_bg {
                /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .searchTop {
                /deep/ .input_text_disappear {
                    width: 35px;
                }
                /deep/ .input_text_appear_mobile {
                    width: 130px;
                }
                /deep/ .search_icon {
                    width: 35px;
                    height: 34px;
                }
            }

            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 100px;
    }
    border-bottom: 1px solid var(--header_border);
    background-color: var(--header_bg);
    box-sizing: border-box;
}

.type_main {
    padding: 24px;
    background: var(--nft_dialog_bg);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;

    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child {
            color: var(--danger_color);
        }

        &:not(:last-child) {
            color: var(--font_deep_color);
        }

        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                font-family: 'Lato';
            }
        }
    }
}

.type_main_resources {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;

    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                font-family: 'Lato';
                color: var(--font_deep_color);
            }
        }
    }
}

.type_main_wallet {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;

    .title_wallet {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_deep_color);
    }
}

.type_main_wallet_success {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;

    .upper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .symbol_main {
            .symbol_item {
            }
        }

        .title_wallet {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }

        .wallet_address {
            display: flex;
            gap: 8px;
            align-items: center;

            .wallet_copy {
                cursor: pointer;
            }
        }

        .line {
            width: 248px;
            border: 1px solid var(--border_color);
        }

        .total_balance {
            h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }

            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .head {
        .head_center {
            /* min-height: 52px; */
            min-height: 100px;
        }

        .head_left {
            justify-content: space-between !important;
            max-width: 100% !important;
            flex: 1;
            /* justify-content: space-between !important; */

            .search {
                max-width: 257px;
                margin-left: 12px !important;
                width: 100%;
            }

            .menu {
                margin-right: 0px !important;
                // flex: 1;
            }
            .searchTop {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                margin-left: 10px;
            }
        }

        justify-content: flex-start;
        padding: 0 15px;
    }
}
@media screen and (max-width: 1220px) and (min-width: 1160px) {
    .head {
        .head_center {
            /* min-height: 52px; */
            min-height: 100px;
        }

        .head_left {
            justify-content: space-between !important;
            max-width: 100% !important;
            flex: 1;
            /* justify-content: space-between !important; */
            .search {
                max-width: 245px !important;
                margin-left: 12px !important;
                width: 100%;
            }

            .menu {
                margin-right: 0px !important;
                // flex: 1;
            }
            .searchTop {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                margin-left: 10px;
            }
        }

        justify-content: flex-start;
        padding: 0 15px;
    }
}
@media screen and (max-width: 1159px) and (min-width: 1082px) {
    .head {
        .head_center {
            /* min-height: 52px; */
            min-height: 100px;
        }

        .head_left {
            justify-content: space-between !important;
            max-width: 100% !important;
            flex: 1;
            /* justify-content: space-between !important; */
            .search {
                max-width: 175px !important;
                margin-left: 6px !important;
                width: 100%;
            }

            .menu {
                margin-right: 0px !important;
                // flex: 1;
            }
            .searchTop {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                margin-left: 10px;
            }
        }

        justify-content: flex-start;
        padding: 0 15px;
    }
}
@media screen and (max-width: 1081px) {
    .head {
        .head_center {
            min-height: 52px;
        }
        .head_left {
            .search {
                margin-left: 0px;
                width: 100%;
            }
        }
        justify-content: flex-start;
        padding: 0 15px;
    }
}
</style>
