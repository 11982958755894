import api from '@/api/brand'
import tools from '@/mixin/tools'
const brandModule = {
    state: {
        // globalBrand
        // 我的活动
        myCompaignName: '',
        myCompaign: [],
        compaignDetail: {},
        brandClient: [],
        campaignArtwork: [],
        removedArtwork: [],
        campaignParticipants: [],
        selectCampaignWorks: [],
        joinCampaignList: [],

        mintNftList: [],
    },
    getters: {},
    mutations: {
        compaignDetail(state, compaignDetail) {
            if (compaignDetail) {
                state.compaignDetail = compaignDetail
            }
        },
        mintNftList(state, mintNftList) {
            state.mintNftList = mintNftList
        },
        joinCampaignList(state, joinCampaignList) {
            state.joinCampaignList = joinCampaignList
        },
        selectCampaignWorks(state, selectCampaignWorks) {
            state.selectCampaignWorks = selectCampaignWorks
        },
        campaignArtwork(state, campaignArtwork) {
            state.campaignArtwork = campaignArtwork
        },
        removedArtwork(state, removedArtwork) {
            state.removedArtwork = removedArtwork
        },
        campaignParticipants(state, campaignParticipants) {
            state.campaignParticipants = campaignParticipants
        },
        myCompaign(state, myCompaign) {
            state.myCompaign = myCompaign
        },
        brandClient(state, brandClient) {
            state.brandClient = brandClient
        },
    },
    actions: {
        async draftSave(context, params = {}) {
            try {
                let result = await api.draftSave(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async draftClear(context, params = {}) {
            try {
                let result = await api.draftClear(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async getDraft(context, params = {}) {
            try {
                let result = await api.getDraft(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async joinCampaignList(context, params = {}) {
            try {
                let result = await api.joinCampaignList(params)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('joinCampaignList', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async selectCampaignWorks(context, params = {}) {
            try {
                let result = await api.selectCampaignWorks(params)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('selectCampaignWorks', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async recoverArtwork(context, params = {}) {
            try {
                let result = await api.recoverArtwork(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async notifyWinners(context, params = {}) {
            try {
                let result = await api.notifyWinners(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async campaignParticipants(context, params = {}) {
            try {
                let result = await api.campaignParticipants(params)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('campaignParticipants', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async artworkDelete(context, params = []) {
            try {
                let result = await api.artworkDelete(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async completelyDelete(context, params = []) {
            try {
                let result = await api.completelyDelete(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async removedArtwork(context, params = {}) {
            try {
                let result = await api.removedArtwork(params)

                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('removedArtwork', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async campaignArtwork(context, params = {}) {
            try {
                let result = await api.campaignArtwork(params)

                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('campaignArtwork', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 删除活动
        async campaignDelete(context, params = {}) {
            try {
                let result = await api.campaignDelete(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async clientDelete(context, params = []) {
            try {
                let result = await api.clientDelete(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async clientInvite(context, params = {}) {
            try {
                let result = await api.clientInvite(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 新增客户信息
        async clientSave(context, params = {}) {
            try {
                let result = await api.clientSave(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取客户信息
        async clientData(context, params = {}) {
            try {
                let result = await api.clientData(params)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('brandClient', result.rows.map((item,index) => {
                        return {
                            ...item,
                            no:parseInt(params.pageSize * (params.pageNum - 1) + index + 1)
                        }
                    }))
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 上传文件
        async importData(context, params) {
            try {
                let result = await api.importData(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async compaignDetail(context, params = {}) {
            let result = await api.campaignDetail(params)
            result = this.apiResult(result)
            if (result && result.data) {
                context.commit('compaignDetail', result.data)
            }
            return result
        },
        async compaignInfo(context, params = {}) {
            let result = await api.campaignDetail(params)
            result = this.apiResult(result)
            return result
        },
        // 获取我的活动
        async myCompaign(context, params = {}) {
            let obj = Object.assign({ name: context.state.myCompaignName }, params)
            if (obj.name == '') {
                delete obj.name
            }
            let result = await api.myCampaigns(obj)
            result = this.apiResult(result)
            if (result && result.data && result.data.rows) {
                context.commit('myCompaign', result.data.rows)
            }
            return result
        },
        // 新增活动
        async newCompaign(context, params = {}) {
            let result = await api.newCampaign(params)
            result = this.apiResult(result)
            return result
        },
        // 修改活动
        async updateCampaign(context, params = {}) {
            let result = await api.updateCampaign(params)
            result = this.apiResult(result)
            return result
        },
        // 修改活动
        async updateApply(context, params = {}) {
            let result = await api.updateApply(params)
            result = this.apiResult(result)
            return result
        },
    },
}
export default brandModule
