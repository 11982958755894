<template>
  <div class="unleash">
    <!-- <StartHead></StartHead> -->
    <div class="unleash_title" :style="{ backgroundImage: `url(/source/png/unleash_title.png)` }">{{ langJson('Earn with talent and creativity') }}</div>
    <div class="unleash_desc">{{ langJson('You are only limited by your imagination') }}</div>
    <div class="unleash_btns">
      <div class="unleash_btnitem">
        <BTN class="btn_item" :fontSize="isPhone?'16':'18'" type="active" @handle="jumpOther('/brand/createCampaign/createOverview')">{{ langJson('Unleash your creativity') }}</BTN>
      </div>
      <div class="unleash_btnitem" @click="jumpOther('/brand/campaign/index')">
        <div class="border_tab">
          {{ langJson('Explore campaigns') }}
        </div>
      </div>
    </div>

    <div class="unleash_bgs">
      <div class="bg_item bg_line_1" :style="{ backgroundImage: `url(/source/png/unleash_bg/line_1.png)` }"></div>
      <div class="bg_item bg_line_2" :style="{ backgroundImage: `url(/source/png/unleash_bg/line_2.png)` }"></div>
      <div class="bg_center">
        <img class="bg_star_1" src="/source/png/unleash_bg/star_1.png" />
        <img class="bg_star_2" src="/source/png/unleash_bg/star_2.png" />
        <img class="bg_image_1" src="/source/png/unleash_bg/image_1.png" />
        <img class="bg_image_2" src="/source/png/unleash_bg/image_2.png" />
        <img class="bg_image_3" src="/source/png/unleash_bg/image_3.png" />
      </div>
    </div>

    <div class="start_bottom">
      <div
        class="bottom_home"
        :style="{
          backgroundImage: `url(/source/png/unleash_custom/unleash_customer.png)`,
        }"
      ></div>
      <div v-if="!isPhone" class="shadow"></div>
      <div
        class="title_bg"
        :style="{
          backgroundImage: `url(/source/png/unleash_custom/unleash_title.png)`,
        }"
      ></div>
      <div
        class="icon_bg"
        v-if="!isPhone"
        :style="{
          backgroundImage: `url(/source/png/unleash_custom/unleash_icon.png)`,
        }"
      ></div>
      <div class="bottom_content">
        <div v-if="!isPhone" class="bottom_title">{{ langJson('Engage your customers') }}</div>
        <div v-if="isPhone" class="bottom_title">
          {{ langJson('Engage your') }} <br />
          {{ langJson('customers') }}
        </div>
        <div
          class="bottom_desc"
        >
          {{ langJson('Create campaigns for your brand or event to captivate your customers and communities') }}
        </div>
        <div class="bottom_btns">
          <BTN class="btn_item" type="active" :bw="isPhone ? 255 : 204" :bh="isPhone ? 48 : 52" @handle="jumpOther('/brand/createCampaign/createOverview')">{{ langJson('Create Campaign') }}</BTN>
          <BTN class="btn_item" type="main" :bw="isPhone ? 255 : 200" :bh="isPhone ? 48 : 52" @handle="jumpOther('/brand/campaign/index')">{{ langJson('Explore Artwork') }}</BTN>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="isPhone"
      :style="{
        backgroundImage: `url(/source/png/unleash_bg/start_botton_bgc.png)`,
      }"
      class="start_bottom_mobile"
    >
      <div class="bottom_content">
        <div class="bottom_title">{{ langJson('Engage your customers') }}</div>
        <div class="bottom_desc">{{ langJson('Create campaigns for your brand or event to captivate your customers and communities') }}</div>
        <div class="bottom_btns">
          <BTN class="btn_item" type="active" :bh="isPhone ? 48 : 60">{{ langJson('Create Campaign') }}</BTN>
          <BTN class="btn_item" type="main" :bh="isPhone ? 48 : 60">{{ langJson('Explore Artwork') }}</BTN>
        </div>
      </div>
      <div class="palce_hold"></div>
    </div> -->
    <!-- index_article_title.png -->
    <div class="unleash_bottom">
      <div class="title">
        {{ langJson('The cheapest & easiest way possible') }}
      </div>
      <div class="title_bg" :style="{ backgroundImage: `url(/source/png/index_article_title.png)` }"></div>
      <div class="desc">{{ langJson("It's as simple as taking a picture. NFTWerks makes it as seamless as possible for you.") }}</div>

      <BTN class="btn_item" type="active" bw="286" bh="52" @handle="jumpOther('/brand/campaign/index')">{{ langJson('Let’s get started !') }}</BTN>

      <div
        class="home_bg"
        :style="{
          backgroundImage: `url(/source/png/index_home.png)`,
        }"
      ></div>
      <div v-if="isPhone" class="shadow"></div>
      <div
        class="mycursor"
        :style="{
          backgroundImage: `url(/source/png/unleash_bg/cursor_1.png)`,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
import { getCookie } from '@/assets/js/cookie'
export default {
  methods:{
    jumpOther(path) {
      if (getCookie('token')) {
        let type = localStorage.getItem('userType')
        if (type == 1) { // 商户
          this.jump_page_path(path) // 跳转到xx页面
        } else { // 用户
          this.jump_page_path('/participant/campaign/index')
        }
      }else{
        this.jump_root()
      }
      
    },
  }
}
</script>
<style lang="scss" scoped>
.unleash {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .unleash_bottom {
    .shadow {
      position: absolute;
      width: 153.67px;
      height: 153.67px;
      right: 0px;
      top: 600px;

      background: linear-gradient(0deg, var(--main_color), var(--main_color));
      filter: blur(150px);
      transform: matrix(-0.55, -0.83, -0.83, 0.55, 0, 0);
    }
    .mycursor {
      position: absolute;
      width: 78px;
      height: 78px;
      right: 0px;
      top: 600px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .home_bg {
      width: 810px;
      height: 576px;
      margin: auto;
      margin-top: 80px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 111px;
    }
    .btn_item {
      margin-top: 40px;
    }
    .desc {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--font_color);
      margin-top: 20px;
    }
    .title_bg {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      height: 120px;
      width: 500px;
      position: absolute;
      top: -30px;
      left: 40px;
    }
    .title {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      color: var(--font_deep_color);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 222px;
  }
  .start_bottom {
    .icon_bg {
      position: absolute;
      bottom: 120px;
      right: 0;
      width: 120px;
      height: 120px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 100%;
    }
    .title_bg {
      position: absolute;
      top: 140px;
      left: 615px;
      width: 114px;
      height: 114px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 100%;
    }
    .bottom_content {
      .bottom_btns {
        .btn_item {
          margin-right: 24px;
        }
        margin-top: 44px;
        display: flex;
        align-items: center;
      }

      .bottom_title {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 56px;

        color: var(--font_deep_color);
        height: 114px;
        display: flex;
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }
      .bottom_desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color);
        margin-top: 8px;
      }

      width: 586px;
      padding-top: 140px;
      margin-left: 655px;
    }
    .bottom_home {
      position: absolute;
      top: 0px;
      left: -80px;
      width: 692px;
      height: 538px;
      background-position: top right;
      background-repeat: no-repeat;
      background-size: auto 100%;
    }
    .shadow {
      width: 153.67px;
      height: 153.67px;
      background: linear-gradient(0deg, var(--main_color), var(--main_color));
      filter: blur(150px);
      transform: matrix(-0.55, -0.83, -0.83, 0.55, 0, 0);
      position: absolute;
      top: 350px;
      left: 300px;
      pointer-events: none;
    }

    position: relative;
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 220px;
    height: 576px;
  }
  .unleash_bgs {
    height: 516px;
    position: relative;
    width: 100%;
    margin-top: 180px;
    display: flex;
    justify-content: center;
    .bg_center {
      width: var(--max_width);
      min-width: var(--max_width);
      max-width: var(--max_width);
      margin: auto;
      height: 100%;
      position: relative;
      .bg_image_3 {
        position: absolute;
        right: 80px;
      }
      .bg_image_2 {
        position: absolute;
        left: calc(50% - 220px);
        top: -80px;
        z-index: 3;
      }
      .bg_image_1 {
        position: absolute;
        left: 90px;
      }
      .bg_star_2 {
        position: absolute;
        right: 0;
        top: 140px;
      }
      .bg_star_1 {
        position: absolute;
        left: 0;
        top: 360px;
      }
    }
    .bg_line_1 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .bg_line_2 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .bg_item {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .unleash_btns {
    display: flex;
    align-items: center;
    margin-top: 27px;
    .unleash_btnitem {
      margin: 0 12px;
      .btn_item {
        padding: 12px 32px !important;
        font-family: 'Cabinet Grotesk';
        /* font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;  */
      }
    }
    .border_tab {
      border: 2px solid transparent;
      border-radius: var(--nft_select_border_radius);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, var(--bg_color), var(--bg_color)), linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
      height: 52px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: var(--font_deep_color);
      padding: 0 32px;
    }
  }
  .unleash_desc {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    color: var(--font_color);
    text-align: center;
  }
  .unleash_title {
    margin-top: 103px;
    font-family: 'Cabinet Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    color: var(--font_deep_color);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}
@media screen and (max-width: 1081px) {
  .unleash {
    .unleash_bottom {
      .mycursor {
        position: absolute;
        width: 78px;
        height: 78px;
        right: 0px;
        top: 600px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .home_bg {
        height: 63.52vw;
        width: 89.33vw;
        margin: auto;
        margin-top: 32px !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 24px !important;
      }
      .btn_item {
        margin-top: 40px;
      }
      .desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color);
        margin-top: 20px;
      }
      .title_bg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        height: 83.8px;

        width: 500px;
        position: absolute;
        top: -12px !important;
        left: -64px !important;
      }
      .title {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
        color: var(--font_deep_color);
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-top: 32px !important;
      padding-left: 20px;
      padding-right: 20px;
    }
    .unleash_bgs {
      height: 52vw;
      position: relative;
      width: 100%;
      margin-top: 19vw;
      .bg_center {
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        position: relative;
        .bg_image_3 {
          width: 46vw;
          height: 51.2vw;
          position: absolute;
          left: 50%;
          transform: translate(30%, 0%);
        }
        .bg_image_2 {
          top: 0;
          width: 46vw;
          height: 51.2vw;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -15%);
          z-index: 3;
        }
        .bg_image_1 {
          /* 0.8983 */
          width: 46vw;
          height: 51.2vw;
          position: absolute;
          left: 50%;
          transform: translate(-125%, 0%);
        }
        .bg_star_2 {
          width: 10vw;
          max-width: 68px;
          height: 10vw;
          max-height: 68px;
          position: absolute;
          right: 18vw;
          top: -5vw;
        }
        .bg_star_1 {
          width: 10vw;
          max-width: 68px;
          height: 10vw;
          max-height: 68px;
          position: absolute;
          left: 18vw;
          top: 41vw;
        }
      }
      .bg_line_1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .bg_line_2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .bg_item {
        width: 100vw;
        height: 60vw;
        background-position: center center;
        background-repeat: no-repeat;
        /* background-size: 100% 100%; */
      }
    }
    .unleash_btns {
      display: inline-block;
      margin-top: 20px;

      .unleash_btnitem {
        margin: 0 12px;
        margin-top: 16px;
        .btn_item {
          min-width: 215px !important;
          /* padding: 8px 24px !important; */
          height: 48px !important;
          font-family: 'Cabinet Grotesk';
        }
      }
      .border_tab {
        justify-content: center;
        height: 48px;
        font-size: 16px;
      }
    }
    .unleash_desc {
      margin-top: 0;
    }
    .unleash_title {
      font-weight: 800;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: var(--font_deep_color);
      background-position: center 10px;
      background-repeat: no-repeat;
      background-size: 45px 45px;
      padding-bottom: 12px;
      margin-top: 20px;
    }
    .start_bottom {
      .title_bg {
        position: absolute;
        top: 0px;
        left:18vw;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        height:60px;
        width:60px;
      }
      .bottom_content {
        .bottom_btns {
          .btn_item {
            margin-right: 0px;
          }
          margin-top: 36px;
          display: flex;
          max-width: 255px;
          width: 100%;
          flex-direction: column;
          gap: 16px;
          order: 3;
        }

        .bottom_title {
          font-family: 'Cabinet Grotesk';
          font-style: normal;
          font-weight: 800;
          font-size: 32px;
          line-height: 48px;
          margin-top: 4px;
          color: var(--font_deep_color);

          text-align: center;
          display: flex;
          align-items: center;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          order: 2;
        }
        .bottom_desc {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--font_color);
          margin-top: 12px;
          text-align: center;
          order: 1;
          background-position: top left;
          background-repeat: no-repeat;
          background-size: auto 100%;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 0px;
        margin-left: 0px;
    
      }
      .bottom_home {
    
        position: static;
        margin-left: -20px;
        margin-top: 32px;
        width: 78.66vw;
        height: 63.73vw;
        /* 664/528 =1.2342007434944238 */
        /* 0.7866666666666666 */
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }

      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      position: static;
      width: 100%;
      max-width: var(--max_width);
      margin-top: 55px;
      height: auto;
      display: flex;
      flex-direction: column-reverse;
    }
    .start_bottom_mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 86.93vw;
      box-sizing: border-box;
      margin-top: 55px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      .bottom_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bottom_title {
          font-weight: 800;
          font-size: 32px;
          line-height: 48px;
          text-align: center;
          color: var(--font_deep_color);
          font-family: 'Cabinet Grotesk';
        }
        .bottom_desc {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--font_color);
        }
        .bottom_btns {
          margin-top: 36px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          max-width: 255px;
          width: 100%;
        }
      }
      .palce_hold {
        height: 100px;
      }
    }
  }
}

@media screen and (max-width: 824px) {
  .mycursor {
    position: absolute;
    width: 48px !important;
    height: 48px !important;
    right: 0px;
    top: 483px !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .start_bottom_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 85.93vw;
    box-sizing: border-box;
    margin-top: 55px;
    .bottom_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bottom_title {
        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: var(--font_deep_color);
        font-family: 'Cabinet Grotesk';
      }
      .bottom_desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--font_color);
      }
      .bottom_btns {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        max-width: 255px;
        width: 100%;
        gap: 16px;
      }
    }
    .palce_hold {
      height: 100px;
    }
  }
}
@media screen and (max-width: 616px) {
  .mycursor {
    position: absolute;
    width: 32.26px !important;
    height: 32.26px !important;
    right: 0px;
    top: 417px !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .start_bottom_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 158.93vw !important;
    box-sizing: border-box;
    margin-top: 55px;
    .bottom_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bottom_title {
        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: var(--font_deep_color);
        font-family: 'Cabinet Grotesk';
      }
      .bottom_desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--font_color);
      }
      .bottom_btns {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        max-width: 255px;
        width: 100%;
        gap: 16px;
      }
    }
    .palce_hold {
      height: 100px;
    }
  }
}
</style>
