export default {
    back(val = -1) {
        this.$router.go(val)
    },
    jump_page_path(path, query = {}, backTop = true, replace = false) {
        let replace2 = sessionStorage.getItem('noSaveHistory')
        // console.log('不保留历史2',replace2)
        if (replace || replace2) {
            // console.log('不保留历史2');
            this.$router.replace({ path: path, query: query })
        } else {
            this.$router.push({ path: path, query: query })
        }
        if (backTop) {
            document.getElementById('view_main').scrollTop = 0
        }
    },
    jump_article_detail(query) {
        this.jump_page_path('/startup/article/detail', query)
    },
    jump_root() {
        this.jump_page_path('/')
    },
    jump_market_explore() {
        this.jump_page_path('/market/explore')
    },
    jump_myster_explore() {
        this.jump_page_path('/myster/explore')
    },
    jump_auction_explore() {
        this.jump_page_path('/auction/explore')
    },
    jump_staking_explore() {
        this.jump_page_path('/staking/explore')
    },
    jump_market_detail(id) {
        this.jump_page_path(`/market/detail/${id}`)
    },
    jump_myster_detail(id) {
        this.jump_page_path(`/myster/detail/${id}`)
    },
    jump_auction_detail(id) {
        this.jump_page_path(`/auction/detail/${id}`)
    },
    jump_staking_detail(id) {
        this.jump_page_path(`/staking/detail/${id}`)
    },
    jump_startup() {
        this.jump_page_path('/startup/index')
    },
    jump_unfind() {
        this.jump_page_path('/404')
    },
    jump_mint() {
        this.jump_page_path('/mint/index')
    },
    jump_brand_login() {
        this.jump_page_path('/brand/login')
    },
    jump_participant_login() {
        this.jump_page_path('/participant/login')
    },
    jump_brand_signup() {
        this.jump_page_path('/brand/signup')
    },
    jump_participant_signup() {
        this.jump_page_path('/participant/signup')
    },

    jump_brand_resetpsd() {
        this.jump_page_path('/brand/resetpsd')
    },
    jump_brand_company() {
        this.jump_page_path('/brand/company')
    },
    jump_brand_verify() {
        this.jump_page_path('/brand/verify')
    },
    jump_brand_create_compaign() {
        this.jump_page_path('/brand/createCampaign/createOverview')
    },
    jump_brand_compaign() {
        this.jump_page_path('/brand/campaign/index')
    },
    jump_brand_compaign_preview(query) {
        this.jump_page_path('/brand/campaign/preview', query)
    },
    jump_brand_compaign_submissions(query) {
        this.jump_page_path('/brand/campaign/submissions', query)
    },
    jump_brand_compaign_participants_detail(query) {
        this.jump_page_path('/brand/campaign/participants/detail', query)
    },
    jump_brand_compaign_participants_profile(query) {
        this.jump_page_path('/brand/campaign/participants/profile', query)
    },
    jump_brand_compaign_artwork_detail(query) {
        this.jump_page_path('/brand/campaign/artwork/detail', query)
    },
    jump_brand_compaign_overview(query) {
        this.jump_page_path(`/brand/campaign/overview/`, query)
    },
    jump_brand_compaign_edit(query) {
        this.jump_page_path('/brand/campaign/edit/editPage', query)
    },
    jump_brand_compaign_plan() {
        this.jump_page_path('/brand/campaign/edit/plan')
    },
    jump_brand_compaign_artwork_mintNfts(query) {
        this.jump_page_path('/brand/campaign/artwork/mintNfts', query)
    },
    jump_brand_profile_myprofile(query, backTop) {
        this.jump_page_path('/brand/profile/myprofile', query, backTop)
    },
    jump_brand_profile_ntfdetail(query) {
        this.jump_page_path('/brand/profile/ntfDetail', query)
    },
    jump_brand_profile_editProfile(query) {
        this.jump_page_path('/brand/profile/editProfile', query)
    },
    jump_collection_belong() {
        this.jump_page_path('/collection/belong')
    },
    jump_collection_update(id) {
        this.jump_page_path(`/collection/update/${id}`)
    },
    jump_collection_new() {
        this.jump_page_path('/collection/new')
    },
    jump_collection(id, tab = '') {
        tab += ''
        if (/^\d+$/.test(tab) && tab) {
            this.jump_page_path(`/collection/index/${id}/${tab}`, false)
        } else {
            this.jump_page_path(`/collection/index/${id}`)
        }
    },
    jump_personal(address, tab = '') {
        tab += ''
        if (/^\d+$/.test(tab) && tab) {
            this.jump_page_path(`/personal/index/${address}/${tab}`, false)
        } else {
            this.jump_page_path(`/personal/index/${address}`)
        }
    },
    jump_setting(tab = '') {
        tab += ''
        if (/^\d+$/.test(tab) && tab) {
            this.jump_page_path(`/personal/setting/${tab}`)
        } else {
            this.jump_page_path('/personal/setting')
        }
    },

    jump_participant_campaign() {
        this.jump_page_path('/participant/campaign/index')
    },
    jump_participant_campaign_artwork_submit() {
        this.jump_page_path('/participant/campaign/artwork/submit')
    },
    jump_participant_mint() {
        this.jump_page_path('/participant/mint/index')
    },
}
