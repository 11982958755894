import Web3 from 'web3'
import Connect from 'h-connect-wallet'
import { Store } from 'h-nftworks'
import supportConnect from 'h-connect-wallet/src/walletSupport'
export default {
    async supportWallets() {
        return supportConnect
    },
    async buildConnect(walletPlugin) {
        if (!walletPlugin) {
            walletPlugin = this.walletDetail && this.walletDetail.plugin
        }
        let connect

        if (walletPlugin) {
            connect = new Connect(walletPlugin)
        } else {
            await this.$store.dispatch('logout')
            // window.location.reload()
        }

        return connect
    },
    async supportProvider(walletPlugin) {
        let connect = await this.buildConnect(walletPlugin)

        let chainMapping = {}
        this.chainMapping.forEach((item) => {
            chainMapping[item.chainId] = item.chainRpc
        })
        let inited = await connect.init(chainMapping)
        let accounts = []
        if (inited) {
            accounts = await connect.enable()
        }
        return { provider: connect.provider, accounts, connect }
    },
    // 监听主链改变
    listenChain() {
        window.provider.on('chainChanged', (chainId) => {
            !!chainId && this.linkUpdate({ chainId })
        })
        window.provider.on('networkChanged', (chainId) => {
            !!chainId && this.linkUpdate({ chainId })
        })
    },
    // 监听钱包改变
    listenWallet() {
        window.provider.on('accountsChanged', (accounts) => {
            if (!!accounts.length) {
                let { address } = this.walletDetail
                !this.addressCompare(address, accounts[0]) && this.$store.dispatch('logout')
                this.linkUpdate({ address: accounts[0] })
            }
        })
    },
    async walletSign(params, plugin = '') {
        plugin = plugin ? plugin : this.walletDetail.plugin
        let { address } = await this.connect(plugin)
        if (params instanceof Array) {
            params = web3.utils.soliditySha3(...params)
        }
        try {
            let result = await window.provider.request({
                method: 'personal_sign',
                params: [params, address],
            })
            return result
        } catch (err) {
            console.log(err)
        }
    },
    async isProvider(walletPlugin) {
        let { provider, accounts } = await this.supportProvider(walletPlugin)
        return { provider, accounts }
    },
    async connect(walletPlugin = 'Metamask') {
        let { provider, accounts } = await this.isProvider(walletPlugin)
        if (!accounts) {
            return false
        }
        if (!provider) {
            return false
        }

        window.provider = provider
        window.web3 = new Web3(provider)
        let chainId = await web3.eth.getChainId()
        let support = this.chainMapping.some((item) => {
            return item.chainId == chainId
        })
        if (!support) {
            // await this.switchNetwork(this.chainMapping[0])
            this.pushMsg("error",this.langJson("The current work network does not support working"))
        }

        // 监听钱包
        this.listenChain()
        this.listenWallet()

        let address = accounts[0]
        let walletDetail = {
            address,
            chainId,
            plugin: walletPlugin,
        }
        // 保存钱包和主链
        let walletObj = await this.linkUpdate(walletDetail)

        return walletObj
    },
    async linkUpdate(obj) {
        let chainDetail
        if ('chainId' in obj) {
            chainDetail = this.findChain('chainId', obj.chainId)
            obj.chainDetail = chainDetail
        }
        let { plugin } = this.walletDetail

        if (plugin || 'plugin' in obj) {
            this.$store.commit('walletDetail', {
                ...this.walletDetail,
                ...obj,
            })
            // obj.chainDetail && (await this.storeState())
        } 
        
        // else {
        //     await this.$store.dispatch('logout')
        //     window.location.reload()
        // }

        return this.walletDetail
    },

    async storeState(id = "") {
        try {
            await this.connect()
            let { chainDetail, plugin, address } = this.walletDetail
            if (!!plugin && !!address && (chainDetail || id)) {
                let platformContract = this.findContractObj(id ? id : chainDetail.id)
                let { provider, accounts } = await this.isProvider(plugin)
                if (!platformContract.Store && address) {
                    return {}
                }
                let store = new Store({
                    provider,
                    account: accounts[0],
                    contract: platformContract.Store,
                })
                return { store }
            } else {
                return {}
            }
        } catch (err) {
            console.log(err)
            return {}
        }
    },
    async useChainId() {
        try {
            let { provider, accounts } = await this.isProvider()
            if (!provider) {
                return false
            } else {
                window.web3 = new Web3(provider)
                let chainID = await web3.eth.getChainId()
                return chainID
            }
        } catch (err) {
            console.log(err)
        }
    },
    async switchNetwork(chain) {
        let chainId = await this.useChainId()

        if (!chainId || !chain) {
            this.pushMsg('error', this.langJson('Main chain exception', 'new_fi.txt.v1.3'))
            return false
        }
        try {
            if (chain.chainId != chainId) {
                const data = [{ chainId: `0x${chain.chainId.toString(16)}` }]
                await window.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: data,
                })
            }
        } catch (err) {
            if (err && err.code == 4902) {
                const data = [
                    {
                        chainId: `0x${chain.chainId.toString(16)}`,
                        chainName: chain.networkName,
                        rpcUrls: [chain.chainRpc],
                    },
                ]
                await window.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: data,
                })
            }
        }

        chainId = await this.useChainId()
        if (chainId == chain.chainId) {
            return true
        } else {
            return false
        }

        // 在wallet connent下 这里由于是监听chainChanged 和 await 同时返回所以比的是 两个接口的快慢问题
        // if (chain.chainId != chainId) {
        //     let res = await this.wait(chain)
        //     return res
        // } else {
        //     return true
        // }
        // chainId = await this.useChainId()
        // console.log('useChainId', chainId) //  当前的链
        // console.log('chain.chainId', chain.chainId) // 要切的链

        // if (chainId == chain.chainId) {
        //     await this.connect(this.walletDetail.plugin)
        //     return true
        // } else {
        //     return false
        // }
    },
    //wait数据加载
    async wait(chain) {
        // console.log('开始监听')
        return new Promise((resolve) => {
            let res

            window.provider.on('chainChanged', async (chainId) => {
                // console.log('开始监听 chainChanged')
                res = await this.checkChangeChainId(chain)
                resolve(res)
            })
            window.provider.on('networkChanged', async (chainId) => {
                // console.log('开始监听 networkChanged')
                res = await this.checkChangeChainId(chain)
                resolve(res)
            })
            let time
            clearTimeout(time)
            time = setTimeout(() => {
                clearTimeout(time)
                resolve(false) // 5秒后算做超时失败
            }, 5000)
        })
    },
    async checkChangeChainId(chain) {
        let chainId
        chainId = await this.useChainId()
        // console.log('useChainId', chainId) //  当前的链
        // console.log('chain.chainId', chain.chainId) // 要切的链

        if (chainId == chain.chainId) {
            await this.connect(this.walletDetail.plugin)
            return true
        } else {
            return false
        }
    },
}
