var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",style:({ padding: _vm.padding })},[_c('div',{staticClass:"image_out",style:(_vm.imageRadius
                ? {
                      height: `${_vm.hs}px`,
                      borderRadius: `${_vm.imageRadius}px`,
                  }
                : { height: `${_vm.hs}px` }),on:{"click":function($event){return _vm.jump_participant_compaign_preview({ id: _vm.item.id })}}},[(_vm.item.isPrivate == 1)?_c('div',{staticClass:"item_privacy"},[_c('GI',{attrs:{"name":"brand_privacy_white","ws":"20","hs":"20"}})],1):_vm._e(),_c('div',{staticClass:"item_image",style:({
                backgroundImage: `url(${_vm.item.banner})`,
            })}),_vm._t("image")],2),_c('div',{class:_vm.titType},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_vm._t("countDownText"),_c('div',{staticClass:"item_belong",on:{"click":function($event){_vm.isParticipant ? _vm.jump_participant_campaign_participants_profileColl({ idUser: _vm.item.brandUserId }) : () => {}}}},[_c('div',{staticClass:"belong_image",style:({
                backgroundImage: `url(${_vm.brandUserVo.imageUrl})`,
            })}),_c('div',{staticClass:"belong_name"},[_vm._v(_vm._s(_vm.brandUserVo.nickName))])]),_c('div',{class:_vm.descType},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]),(!_vm.isPhone)?_c('VI',{attrs:{"participants":_vm.item.joinNum,"voters":_vm.item.voteNum,"days":_vm.item.endTime}}):_vm._e(),((_vm.isPhone && _vm.showBtn) || _vm.showBtnSure)?_c('div',{staticClass:"item_mobile_btn"},[_c('BTN',{attrs:{"type":"detail","bh":"48","fontSize":"16"},on:{"handle":_vm.submitArtwork}},[_vm._v(_vm._s(_vm.langJson('Submit Artwork')))])],1):_vm._e(),_c('Model',{attrs:{"padding":'24px'},model:{value:(_vm.showSubmitArtwork),callback:function ($$v) {_vm.showSubmitArtwork=$$v},expression:"showSubmitArtwork"}},[_c('SubmitArtTip',{attrs:{"slot":"main","id":_vm.item.id,"titleActive":_vm.item.name},on:{"cancel":_vm.cancel,"computed":_vm.handSubArtTip},slot:"main",model:{value:(_vm.showSubmitArtwork),callback:function ($$v) {_vm.showSubmitArtwork=$$v},expression:"showSubmitArtwork"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }