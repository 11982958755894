<template>
  <div class="main">
    <GI class="icon_unfind" name="global_404"></GI>
    <div class="tip">
      {{ langJson('Page not found') }}
    </div>
    <div class="back_index" @click="jump_root">
      {{ langJson('Back to home page') }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .back_index {
    font-family: 'Cabinet Grotesk';
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--main_color);
    font-size: 16px;
    cursor: pointer;
  }
  .tip {
    font-family: 'Cabinet Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 64px;
    color: var(--font_deep_color);
  }
  .icon_unfind {
    width: 40% !important;
    max-width: 240px;
  }
}
</style>
