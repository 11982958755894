import axios from "@/assets/js/axios";
import upload from "@/assets/js/ajax"
import { getCookie } from "@/assets/js/cookie";
let http = {
  get(url, params = {}, cancelToken, hostType) {
    return axios(
      {
        url,
        params,
        method: "GET"
      },
      cancelToken,
      hostType
    );
  },
  post(url, params = {}) {
    return axios({
      url,
      params,
      method: "POST"
    });
  },
  put(url, params = {}) {
    return axios({
      url,
      params,
      method: "PUT"
    });
  },
  delete(url, params = {}) {
    return axios({
      url,
      params,
      method: "DELETE"
    });
  },
  postJson(url, params = {}) {
    return axios({
      url,
      params,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      isJson: true
    });
  },
  upload(url, file, key = "file") {
    return new Promise((resolve,reject)=>{
      upload({
        action:"/api"+url,
        filename:key,
        file,
        onProgress(){
  
        },
        onError(err){
          reject(err)
        },
        onSuccess(result){
          resolve(result)
        },
        headers: {
          "Authorization":getCookie("token") ? `Bearer ${getCookie("token")}` : ""
        }
      });
    })
  }
};
export default http;
