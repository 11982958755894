<template>
    <div :class="commonStyle ? 'table_main_common' : 'table_main'">
        <div class="selectAll">
            <CheckBox @click.native="selectAll" :active="isAllChecked"></CheckBox>
            <span @click="selectAll">{{ langJson('Select all') }}</span>
        </div>
        <div class="table">
            <div class="table_head">
                <div class="head_item" v-for="(item, index) in listKey" :key="index">
                    {{ title[item].title }}
                </div>
                <div v-if="play.length" class="head_item play">
                    {{ langJson('Action') }}
                </div>
            </div>

            <div class="table_body">
                <!-- 行 -->
                <div class="table_row" v-for="(item, index) in list" :key="index">
                    <!-- 列 -->
                    <div class="table_column" v-for="(subItem, subIndex) in listKey" :key="subIndex">
                        <div class="text">
                            <CheckBox @click.native="selectOneItem(item)" :active="selected(item)" v-if="subIndex == 0"></CheckBox>
                            {{ list[index][subItem] }}
                        </div>
                    </div>

                    <!-- 按钮，根据play数组和 -->
                    <div class="table_column play">
                        <!-- <GI ws="24" hs="24" class="pointer"  name="brand_more"></GI> -->
                        <BtnPop>
                            <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                            <GI slot="target" name="brand_more" ws="24" hs="24" class="pointer icon_"></GI>
                            <div slot="main" class="type_main">
                                <div v-for="(tag, i) in tagList" :key="i" class="type_content">
                                    <div @click="triggleEvent(tag.method, item)" class="type_line">
                                        <GI ws="20" hs="20" :name="globalTheme == 'light' ? tag.icon_dark : tag.icon_light"></GI>
                                        <span class="title">{{ tag.val }}</span>
                                    </div>
                                </div>
                            </div>
                        </BtnPop>
                    </div>
                </div>
            </div>
            <editShare v-model="showShare" @changeShow="changeShow" :url="'2143223'" image="https://w.wallhaven.cc/full/1p/wallhaven-1p8dxg.jpg"></editShare>
            <div class="table_foot">
                <slot name="foot"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        play: {
            type: Array,
            default: () => {
                return []
            },
        },
        title: {
            type: Object,
            default: () => {
                return {}
            },
        },
        loadItem: {
            type: [String, Number],
            default: '',
        },
        commonStyle: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        Object.keys(this.$slots).forEach((item) => {
            // console.log(item)
        })
    },
    computed: {
        tagList() {
            return [
                { 
                    icon_dark: 'edit_invite', 
                    icon_light: 'edit_invite_light', 
                    val: this.langJson('Invite'), 
                    method: 'Invite', 
                    isChecked: false, 
                    number: 1 
                },
                {
                    icon_dark: 'brand_trash',
                    icon_light: 'brand_trash',
                    val: this.langJson('Delete'), 
                    method: 'Delete',
                    isChecked: false,
                    number: 4,
                },
            ]
        },
        haveData() {
            return this.list && this.list.length
        },
        // title数组中的key值组成的表头数组
        listKey() {
            return Object.keys(this.title)
        },
        selected() {
            return (item) => {
                try {
                    let result = this.seletelist.some((select) => {
                        return select == item.id
                    })
                    return result
                } catch (err) {
                    console.log(err)
                }
                return false
            }
        },
    },
    data() {
        return {
            isAllChecked: false,
            notAllChecked: false,
            showShare: false,
            seletelist: [],
        }
    },
    watch: {
        seletelist(n) {
            this.$emit('change', n)
        },
    },
    methods: {
        triggleEvent(event, item) {
            console.log(event, item)
            this.$emit(event, item)
        },
        selectOneItem(item) {
            let exit = this.seletelist.some((select) => {
                return select == item.id
            })
            if (exit) {
                this.seletelist = this.seletelist.filter((select) => {
                    return select != item.id
                })
            } else {
                this.seletelist.push(item.id)
            }
        },
        selectAll() {
            this.isAllChecked = !this.isAllChecked
            if (this.isAllChecked) {
                this.seletelist = this.list.map((item) => {
                    return item.id
                })
            } else {
                this.seletelist = []
            }
        },
        changeShow(val) {
            this.showShare = val
        },
    },
}
</script>
<style lang="scss" scoped>
.table_main_common {
    background-color: var(--color_white);
    color: var(--font_modif_color);
    letter-spacing: 1px;
    gap: 32px;
    display: flex;
    flex-direction: column;
    .selectAll {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 32px;
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
            cursor: pointer;
        }
    }
    .table {
        box-sizing: border-box;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        .table_head {
            display: flex;
            background: var(--bg_color);
            padding: 4px 32px;
            overflow: auto;
            border-radius: 32px;
            gap: 36px;
            .head_item {
                font-size: 18px;
                line-height: 24px;
                color: var(--font_deep_color);
                box-sizing: border-box;
            }
            .head_item:nth-child(1) {
                width: 60px;
            }
            .head_item:nth-child(2) {
                width: 153px;
            }
            .head_item:nth-child(3) {
                width: 144px;
            }
            .head_item:nth-child(4) {
                width: 172px;
            }
            .head_item:nth-child(5) {
                width: 55px;
            }
        }
        .table_body {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .table_row {
                display: flex;
                gap: 36px;
                padding: 0px 32px;
            }

            .table_column:nth-child(1) {
                width: 60px;
                .text {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                }
            }
            .table_column:nth-child(2) {
                width: 153px;
                &>.text{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .table_column:nth-child(3) {
                width: 144px;
                &>.text{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .table_column:nth-child(4) {
                width: 172px;
            }
            .table_column:nth-child(5) {
                width: 55px;
            }
        }
    }
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    box-sizing: border-box;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
</style>
