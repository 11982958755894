import http from "./base";

/**
 *@param pageSize
 *@param pageNum
 *
 * ino列表 */
let ino_list = (params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/list`, params);
};
// ino 详情
let inoDetail = (id, params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/detail/${id}`, params);
};
/** 
 *购买记录

*/
let inoRecord = (params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/tradeListBy`, params);
};


/**
 * @param id
 * @param pageSize
 * @param pageNum
 * 盲盒项目 资产列表
 */
let inoAssets = (params = {}) => {
  return http.get(`/mysteryBoxAccount/account`, params);
};

/**
 * @param mysteryBoxId
 * 盲盒下系列列表
 */
 let inoSeries = (params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/seriesList`, params);
};

export default { inoDetail, ino_list, inoRecord, inoAssets,inoSeries };
