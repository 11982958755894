<template>
    <div class="progress">
        <div class="progress_num">
            <div class="progress_sold progress_item">
                <div class="item_tit">{{langJson("Sold")}}</div>
                <div class="item_num">{{sold}}</div>
            </div>
            <div class="progress_total progress_item">
                <div class="item_tit">{{langJson("Total")}}</div>
                <div class="item_num">{{totalAll}}</div>
            </div>
        </div>
        <Progress :progress="parseFloat(sold / totalAll * 100)"></Progress>
    </div>
</template>
<script>
export default {
    props:{
        sold:{
            type:[Number,String],
            default:0
        },
        totalAll:{
            type:[Number,String],
            default:0
        }
    }
}
</script>
<style lang="scss" scoped>
.progress{
    .progress_num{
        .progress_item{
            .item_tit{
                color:var(--font_modif_color);
                font-size: 12px;
                line-height: 12px;
            }
            .item_num{
                margin-top:6px;
                letter-spacing: 1px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;
        color:var(--font_deep_color);
    }
    border-radius: 16px;
    margin-top:32px;
    padding:20px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:106px;
    // background:var(--bg_color);
    background-image: linear-gradient(rgba($color: #ccc, $alpha: 0.2) , #E7F8E8);
}
                
</style>