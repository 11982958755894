<template>
    <div
        :class="[type == 'btn' ? 'tab_btn' : 'tab_line']"
        :style="{
            justifyContent: align,
        }"
    >
        <div :class="['tab_item', value === item.value && 'tab_active']" v-for="(item, index) in list" :key="index" @click="selectItem(item.value)">
            <GI v-if="item.icon && item.iconActive" class="tab_icon" :name="value === item.value ? item.iconActive : item.icon" ws="24" hs="24"></GI>
            <span v-if="!isPhone" class="tab_text">{{ item.lable }}</span>
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'update',
    },
    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        type: {
            type: String,
            default: 'btn',
        },
        align: {
            type: String,
            default: 'flex-start',
        },
    },
    computed: {
        current() {
            let result = this.list.find((item) => {
                return item.value === this.value
            })
            return result
                ? result
                : {
                      lable: '',
                      value: '',
                  }
        },
    },
    methods: {
        selectItem(val) {
            this.$emit('update', val)
            this.$emit('change', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.tab_line {
    .tab_item {
        .tab_text {
            margin-left: 16px;
            user-select: none;
        }
        margin: 0 16px;
        cursor: pointer;
        height: calc(100% - 2px);
        transform: translateY(1px);
        padding: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_modif_color);
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer !important;
    }
    .tab_active {
        border-bottom: 2px solid var(--nft_select_primary) !important;
        font-weight: 700;
        color: #9766ff;
        /* color: var(--nft_select_primary); */
        position: relative;
        z-index: 2;
    }
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: visible;
    border-bottom: 1px solid var(--border_color);
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: auto;
}
.tab_btn {
    .tab_item {
        color: var(--font_deep_color);
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 28px;
        cursor: pointer;
        letter-spacing: 2px;
        margin: 12px;
        margin-left: 0px;
        padding: 8px 16px;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        border-radius: 6px;
    }
    .tab_active {
        background-color: var(--main_color);
        color: #fff;
    }
    padding: 0 12px;
    background-color: var(--color_white);
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
</style>
