<template>
    <div class="page_main" v-if="all != 0">
        <div class="page_prev" @click="prev">
            <GI name="page_left" ws="24" hs="24" @click="prev"></GI>
        </div>

        <div v-show="isBtn" v-if="current != 1 && current > 2" :class="['page_item']" @click="goJump(1)">1</div>

        <div v-show="isBtn" v-if="current > 3" :class="['page_item']">
            {{ langJson('...') }}
        </div>

        <!-- <div v-if="current - 2 > 0" :class="['page_item']" @click="goJump(current - 2)">
            {{current - 2}}
        </div> -->

        <div v-show="isBtn" v-if="current - 1 > 0" :class="['page_item']" @click="goJump(current - 1)">
            {{ current - 1 }}
        </div>
        <div v-show="isBtn" :class="['page_item', 'page_active']" @click="goJump(current)">
            {{ current }}
        </div>
        <div v-show="isBtn" v-if="current < page - 1" :class="['page_item']" @click="goJump(current + 1)">
            {{ current + 1 }}
        </div>
        <!-- <div v-if="current < page - 2" :class="['page_item']" @click="goJump(current + 2)">
            {{current + 2}}
        </div> -->

        <div v-show="isBtn" v-if="current <= page - 3" :class="['page_item']">
            {{ langJson('...') }}
        </div>

        <div v-show="isBtn" v-if="current != page" :class="['page_item']" @click="goJump(page)">
            {{ page }}
        </div>
        <div v-show="!isBtn" class="page_num">{{ current }} / {{ page }}</div>

        <div class="page_next" @click="next">
            <GI name="page_right" ws="24" hs="24" @click="next"></GI>
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'current',
        event: 'update',
    },
    props: {
        all: {
            type: [Number, String],
            default: 0,
        },
        size: {
            type: [Number, String],
            default: 24,
        },
        current: {
            type: [Number, String],
            default: 1,
        },
        isBtn: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        page() {
            try {
                return Math.ceil(this.all / this.size)
            } catch (err) {
                console.log(err)
                return 1
            }
        },
    },
    methods: {
        prev() {
            if (this.current > 1) {
                this.goJump(this.current - 1)
            }
        },
        next() {
            if (this.current < this.page) {
                this.goJump(this.current + 1)
            }
        },
        goJump(val) {
            this.$emit('update', val)
            this.$emit('change', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.page_main {
    .page_next {
        margin-left: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .page_prev {
        margin-right: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .page_item {
        width: 36px;
        height: 36px;
        margin: 0 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px;
        border-radius: 8px;
        box-sizing: border-box;
        font-family: 'Lato';
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        background: var(--color_white);
        color: var(--font_modif_color);
        cursor: pointer;
    }
    .page_active {
        background: var(--bg5);
        color: #fff;
    }
    .page_num {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;
        color: var(--font_modif_color);
        height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
