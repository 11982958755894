import { render, staticRenderFns } from "./MintTip.vue?vue&type=template&id=217c0e77&scoped=true"
import script from "./MintTip.vue?vue&type=script&lang=js"
export * from "./MintTip.vue?vue&type=script&lang=js"
import style0 from "./MintTip.vue?vue&type=style&index=0&id=217c0e77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "217c0e77",
  null
  
)

export default component.exports