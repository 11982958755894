<template>
    <div class="belong">
        <Go :prev="langJson('Back')" @prev="back">
            <BTN class="ml20" type="main" @handle="jump_collection_new">
                {{langJson("Create your collection")}}
            </BTN>
        </Go>
        <div class="belong_center">
            <div class="belong_title">{{langJson("My collections")}}</div>
            <div class="belong_main">
                <Table :list="list" :title="title" :play="play" @Edit="jump_collection_update(1)" @View="jump_collection(1)">
                    <!-- <div class="more" slot="foot">
                        <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="false"></Page>
                    </div> -->
                </Table>
            </div>
        </div>
        
        
    </div>
</template>
<script>
export default {
    data(){
        return {
            play:[
                {
                    event:"Edit",
                    title:"Edit",
                    type:"active"
                },
                {
                    event:"View",
                    title:"View",
                    type:"main"
                }
            ],
            list:[
                {
                    image:"https://w.wallhaven.cc/full/jx/wallhaven-jx96ry.jpg",
                    name:"Miss Wan",
                    price:"200",
                    time:"2022-09-20 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    image:"https://w.wallhaven.cc/full/jx/wallhaven-jx96ry.jpg",
                    name:"Miss Zhou",
                    price:"140",
                    time:"2022-09-12 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    image:"https://w.wallhaven.cc/full/jx/wallhaven-jx96ry.jpg",
                    name:"Miss Li",
                    price:"32",
                    time:"2022-09-10 20:00",
                    address:"0xDe126...e1762e1Dc"
                }
            ].concat(new Array(6).fill({
                image:"https://w.wallhaven.cc/full/jx/wallhaven-jx96ry.jpg",
                name:"Miss Li",
                price:"100",
                time:"2022-09-10 20:00",
                address:"0xDe126...e1762e1Dc"
            })),
            title:{
                image:{
                    title:"Priview",
                    type:"image"
                },
                name:{
                    title:"Name",
                    type:"text"
                },
                price:{
                    title:"Amount",
                    type:"text"
                },
                time:{
                    title:"Create Time",
                    type:"text"
                }
            }
        }
    },
    computed:{
        haveData() {
            return this.list && this.list.length
        },
        listKey() {
            return Object.keys(this.title)
        }
    }
}
</script>
<style lang="scss" scoped>
.belong{
    .belong_center{
        .belong_title{
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            margin-top:32px;
            color:var(--font_deep_color);
        }
        .belong_main{
            margin-top:32px;
        }
        width:100%;
        max-width:var(--max_width);
        margin: auto;
    }
}
.more{
    height:50px;
    background:var(--color_white);
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px -5px 10px var(--bg_color);
    padding: 0 20px;

}
.more .page_main{
    justify-content: space-between!important;
    width:100%;
}
.step_item{

    height:400px;
    margin-top:20px;
    
}
</style>