<template>
    <Model v-model="show">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    {{ langJson('Tip') }}
                </div>
                <div class="top_right cursor" @click="close">
                    <GI name="model_close" ws="24" hs="24"></GI>
                </div>
            </div>

            <div class="nft_preview" :style="{ backgroundImage: `url(${image})` }"></div>
            <div class="tip">
                {{ langJson('Are you sure to mint nft with current info ?') }}
            </div>

            <div class="model_btns">
                <div class="model_btn">
                    <BTN class="btn_item" @handle="close">{{ langJson('Cancel') }}</BTN>
                </div>
                <div class="btn_grap"></div>
                <div class="model_btn">
                    <BTN class="btn_item" type="active" @handle="jump_personal">{{ langJson('Confirm') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>
<script>
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        image: {
            type: String,
            default: '',
        },
    },
    methods: {
        close() {
            this.$emit('change', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .nft_preview {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 16px;
        background-color: var(--bg_color);
        margin: 20px 0;
    }
    .tip {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_color);
    }
    .information_item {
        margin-top: 32px;
    }
    .model_btns {
        .btn_grap {
            width: 20px;
        }
        .model_btn {
            flex: 1;
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    padding: 24px;
    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
}
</style>
