<template>
  <div>
1111
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
  beforeDestroy() {

  },
}
</script>
<style lang='scss' scoped>

</style>