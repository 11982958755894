<template>
    <div class="filter">
        <div class="filter_view">
            <div class="view_left">
                <BTN icon_left="global_filter" :load="load_btn" @handle="openMain">{{langJson("Filters")}}</BTN>
            </div>
            <div class="view_right">
                <div class="search" v-show="filter.keyword">
                    <IN v-model="source.keyword" :placeholder="langJson('Please enter keywords')"></IN>
                </div>
                <div class="sort" v-show="filter.sort">
                    <Sel v-model="source.sort" :list="sortlist"></Sel>
                </div>
            </div>
        </div>

        <div class="filter_main">
            <div class="filter_space"></div>
            <div class="filter_item" v-show="filter.saleType">
                <div class="item_lable">{{langJson("Sale Types")}}</div>
                <div class="item_list">
                    <SelBtn v-model="source.saleType" :list="saleTypes"></SelBtn>
                </div>
            </div>
            <div class="filter_item" v-show="filter.chain">
                <div class="item_lable">{{langJson("MainChain")}}</div>
                <div class="item_list">
                    <SelBtn v-model="source.chain" :list="chainlist"></SelBtn>
                </div>
            </div>
            <div class="filter_item" v-show="filter.symbol">
                <div class="item_lable">{{langJson("Currencies")}}</div>
                <div class="item_list">
                    <SelBtn v-model="source.symbol" :list="symbols"></SelBtn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import move from "move-js"
export default {
    data() {
        return {
            source:{
                keyword:"",
                sort:"",
                saleType:"",
                chain:"",
                symbol:""
            },
            sortlist:[
                {
                    lable:this.langJson("Sort By"),
                    value:""
                },
                {
                    lable:this.langJson("Recently created"),
                    value:"create"
                },
                {
                    lable:this.langJson("Most Favorited"),
                    value:"favorite"
                },{
                    lable:this.langJson("Price:  low to high"),
                    value:"lowPrice"
                },
                {
                    lable:this.langJson("Price:  high to low"),
                    value:"highPrice"
                },
                {
                    lable:this.langJson("Recently launched"),
                    value:"new"
                },
                {
                    lable:this.langJson("Most recommended"),
                    value:"way"
                },
                {
                    lable:this.langJson("Recent transactions"),
                    value:"trade"
                },
                {
                    lable:this.langJson("Ending soon"),
                    value:"expire"
                }
            ],
            saleTypes:[
                {
                    lable:this.langJson("All"),
                    value:""
                },
                {
                    lable:this.langJson("Fixed Price"),
                    value:1
                },
                {
                    lable:this.langJson("Live Auction"),
                    value:2
                }
            ],
            timelen:1000
        }
    },
    props:{
        filter:{
            type:Object,
            default:() => {
                return {
                    keyword:true,
                    sort:true,
                    saleType:true,
                    chain:true,
                    symbol:true
                }
            }
        }
    },
    computed: {
        symbols() {
            try{
                let result = this.globalToken.map(item => {
                    return {
                        lable:item.symbol,
                        value:item.id
                    }
                })
                result.unshift({
                    lable:this.langJson("All"),
                    value:""
                })
                return result
            }catch(err){
                console.log(err)
                return []
            }
        },
        chainlist() {
            try{
                let result = this.chainMapping.map(item => {
                    return {
                        lable:item.chainName,
                        value:item.id
                    }
                })
                result.unshift({
                    lable:this.langJson("All"),
                    value:""
                })
                return result
            }catch(err){
                console.log(err)
                return []
            }
        }
    },
    mounted() {
        move.defaults = {
            duration: this.timelen
        };
        this.openMain()
    },
    methods:{
        openMain() {
            this.load_btn = true
            let node = document.getElementsByClassName("filter_main")[0]
            let result = this.SubTH(node)

            let { height } = node.getBoundingClientRect()
            if(height > 0) {
                move(`.filter .filter_main`)
                .set("height",`0px`)
                .end();
            }else {
                move(`.filter .filter_main`)
                .set("height",`${result}px`)
                .end();
            }
            setTimeout(() => {
                this.load_btn = false
            },this.timelen)
        },
    }
}
</script>
<style lang="scss" scoped>
.filter{
    padding: 20px;
    background-color: var(--color_white);
    border-radius: 16px;
    
    
    .filter_main{
        height:0px;
        box-sizing: border-box;
        overflow: hidden;
        .filter_item{
            .item_list{
                flex:1;
                
            }
            .item_lable{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color:var(--font_modif_color);
                margin-right:20px;
                width:100px;
            }
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            min-height:50px;
        }
        .filter_space{
            height:20px;
        }
    }
    .filter_view{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .view_right{
            display: flex;
            align-items: center;
            
            flex-wrap: wrap;
            .sort{
                margin-left:16px;
            }
            .search{
                background-color: var(--bg_color);
                border-radius: 6px;
                
                width:300px;
                
            }
        }
        .view_left{
           
        }
    }
}
</style>