<template>
    <div class="prize_container">
        <div class="WhiteBg"></div>
        <div class="left_box">
            <div class="link_item" v-for="(item, index) in navlist" :key="index">
                <div :class="['link_inner cursor', item.value == tab && 'link_active']" @click="selectItem(item.value)">
                    {{ item.title }}
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <StepRun ref="step" class="step">
            <div class="right_box1" slot="step1">
                <div class="text_box" v-for="(item, index) in list" :key="index">
                    <div class="border">
                        <div class="up_box" :style="{ 'background-image': `url(${item.rewardBrandDescription})` }"></div>
                    </div>
                    <div class="down_box">
                        <h2>{{ item.rewardTitle }}</h2>
                        <span>{{ item.rewardDescription }}</span>
                    </div>
                </div>
            </div>
            <div class="right_box1 right_box2" slot="step2">
                <div class="text123 v-html">{{ detail.rules }}</div>
            </div>
        </StepRun>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            tab: 1,
            navlist: [
                { title: 'Prizes', value: 1 },
                { title: 'Rules', value: 2 },
            ],
        }
    },
    computed: {
        bannerImage() {
            try {
                let isMobile = this.browser().mobile
                let { banner, appBanner } = this.detail
                return isMobile ? (appBanner ? appBanner : banner) : banner
            } catch (err) {
                console.log(err)
                return ''
            }
        },
        list() {
            try {
                return this.detail.campaignAwards
            } catch (error) {
                return []
            }
        },
    },
    mounted() {},
    methods: {
        next() {
            this.$refs.step.next()
        },
        prev() {
            this.$refs.step.prev()
        },
        backPrev() {
            if (this.$refs.step.step > 0) {
                this.prev()
            } else {
                this.back()
            }
        },
        selectItem(val) {
            this.tab = val
            if (val == 1) {
                this.prev()
            }
            if (val == 2) {
                this.next()
            }
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.step {
    position: relative;
    z-index: 999;
}
.text123 {
    margin: 0 auto;
    padding: 48px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--font_color);
    background-color: var(--bg1);
    border-radius: 12px;
    overflow: hidden;

    @media screen and (max-width: 1080px) {
        box-sizing: border-box;
        padding: 40px;
        width: calc(100% - 50px);
    }
}
.prize_container {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: var(--max_width);
    /* background-color: #000000; */
    border-radius: 12px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    padding-bottom: 102px;
    border-radius: 12px;
    /* overflow: hidden; */

    .WhiteBg {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: #ffffffef;
        background: var(--bg10);
    }

    .left_box {
        margin: 0 auto;
        margin-top: 57px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-direction: row;
        height: fit-content;
        position: relative;
        z-index: 999;

        .link_item {
            font-family: 'Inter';
            font-style: normal;
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            background-clip: text;
            cursor: pointer;
            margin-right: 28px;
            margin-top: 49px;
            &:last-child {
                margin-right: 0;
            }
            @media screen and (max-width: 1080px) {
                cursor: inherit;
                font-size: 16px;
            }
            .link_inner {
                font-size: 20px;
                color: var(--font_color);
                padding: 12px 0;
            }
            .link_active {
                color: transparent !important;
                -webkit-background-clip: text;
                background-image: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%);

                .line {
                    transition: all 0s;
                    margin-top: 12px;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(90deg, #ffc5ac, #ba99ff);
                }
            }
        }
    }
    .right_box1 {
        margin: 0 auto;
        margin-top: 74px;
        max-width: 964px;
        padding: 0 100px;
        display: flex;
        gap: 32px;
        flex-wrap: wrap;

        @media screen and (max-width: 1080px) {
            padding: 0;
            margin-left: 0px !important;
            margin-top: 44px !important;
            max-width: none;
            overflow: auto;
        }
        .down_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 300px;
            word-break: break-all;
            @media screen and (max-width: 1080px) {
                align-items: center;
                justify-content: center;
            }
            h2 {
                font-family: 'Inter';
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                color: var(--font_color);
                width: 100%;
                text-align: center;
                @media screen and (max-width: 1080px) {
                    font-size: 18.67px;
                    line-height: 23.15px;
                    text-align: center;
                }
            }
            span {
                font-family: 'Lato';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
                @media screen and (max-width: 1080px) {
                    text-align: center;
                }
            }
        }

        &.right_box2 {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
        }
        .text_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 21px;
            width: 300px;

            @media screen and (max-width: 1080px) {
                width: 100% !important;
                align-items: center;
                justify-content: center;
            }
            .border {
                position: relative;
                width: 315px;
                height: 315px;
                background-image: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%);
                clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);

                @media screen and (max-width: 1080px) {
                    width: 208px !important;
                    height: 208px !important;
                }
            }
            .up_box {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                // width: 100%;
                width: 300px;
                @media screen and (max-width: 1080px) {
                    width: 200px !important;
                    height: 200px !important;
                    // width: calc(100% - 96px) !important;
                    align-items: center;
                    justify-content: center;
                }

                aspect-ratio: 1.15;
                /* 保持图片原有比例, 会有剪切*/
                clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
                // clip-path: polygon(48% 3%, 90% 25%, 90% 75%, 50% 97%, 10% 75%, 10% 25%);

                height: 300px;
                /* background-image: url('@/assets/image/brand/brand_box.png'); */
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;

                // border: 5px solid #ffffff;
                border-top-color: #ffc5ac;
                border-right-color: #dbaed7;
                border-bottom-color: #ba99ff;
                border-left-color: #dbaed7;
            }
            .down_box {
                display: flex;
                flex-direction: column;
                // align-items: flex-start;
                align-items: center;
                gap: 8px;
                width: 300px;
                word-break: break-all;
                @media screen and (max-width: 1080px) {
                    align-items: center;
                    justify-content: center;
                }
                h2 {
                    font-family: 'Inter';
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 34px;
                    /* color: var(--font_deep_color); */
                    width: 100%;
                    text-align: center;
                    color: var(--font_color);
                    @media screen and (max-width: 1080px) {
                        font-size: 18.67px;
                        line-height: 23.15px;
                        text-align: center;
                    }
                }
                span {
                    font-family: 'Lato';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                    @media screen and (max-width: 1080px) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
