<template>
    <div :class="['mask', show ? 'maskShow' : 'maskHide']" :style="{ 
        display: waitrun ? `flex` : `none`,
        zIndex: waitrun ? 20231028 : -1,
    }" @click.stop="$emit('mask')">
        <div v-if="waitrun" :class="['inner', show ? 'innerShow' : 'innerHide']" :style="{ borderRadius: `${isPhone ? 20 : borderRadius}px`, padding: `${padding}`, gap: `${gap}px` }">
            <div v-show="showHeader" class="model_hdeader">
                <div class="model_title">
                    <div :style="{ fontSize: titleSize, lineHeight: titleHeight }" class="title_content">{{ title }}</div>
                    <div :style="{ fontSize: titleSize, lineHeight: titleHeight }" class="title_content">{{ title2 }}</div>
                    <div class="sub_title">{{ subtitle }}</div>
                </div>
                <div style="height: 24px" @click="close" class="cursor">
                    <GI name="model_close" ws="24" hs="24"></GI>
                </div>
            </div>
            <div class="inner_main">
                <div class="inner_content">
                    <slot name="main"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    data() {
        return {
            loading: false,
            width: '0%',
            waitrun: false,
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        borderRadius: {
            type: [Number, String],
            default: '32',
        },
        title: {
            type: [Number, String],
            default: '',
        },
        title2: {
            type: [Number, String],
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        padding: {
            type: String,
            default: '32px',
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        titleSize: {
            type: String,
            default: '20px',
        },
        headerGap: {
            type: String,
            default: '24px',
        },
        titleHeight: {
            type: String,
            default: '32px',
        },
        gap: {
            type: [Number, String],
            default: '',
        },
    },
    watch: {
        show: function (n) {
            if (n) {
                this.waitrun = n
            } else {
                setTimeout(() => {
                    this.waitrun = n
                }, 500)
            }
        },
    },
    mounted() {},

    methods: {
        /* open(){
      this.width = "100%"
    },
    close(){
    //   this.isShow = false
      this.$emit('change',false)
      setTimeout(()=>{
        this.width = "0%"
      },500)
    }, */
        close() {
            this.$emit('close')
            this.$emit('change', false)
        },
        confirm() {
            this.loading = true
            this.close()
            this.$emit('Confirm')
            this.loading = false
        },
        cancle() {
            this.loading = true
            this.close()
            this.$emit('Cancle')
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    // z-index: 20231028;
    transition: all 0s;
    .inner {
        .model_hdeader {
            display: flex;
            justify-content: space-between;
            /* align-items: center; */
            align-items: flex-start;
            .model_title {
                display: flex;
                flex-direction: column;
                .title_content {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;

                    color: var(--font_deep_color);
                }
                .sub_title {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: left;
                    color: var(--font_modif_color);
                }
            }
        }
        .inner_main {
            min-height: 10vh;
            max-height: 100vh;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .inner_content {
                width: 100%;
                height: auto;
            }
        }
        position: relative;

        box-sizing: border-box;
        border-radius: 20px;
        background-color: var(--nft_dialog_bg);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 100vh;
    }
}
@media screen and (max-width: 500px) {
    .mask {
        /* align-items: flex-end; */
        .inner {
            .model_hdeader {
                /* width: 74.4vw; */
            }
        }
    }
}
@media screen and (max-width: 1080px) {
    .inner {
    }
}
.maskShow {
    animation: maskShow 0.5s ease-in;
}
.maskHide {
    animation: maskHide 0.5s ease-in !important;
}
@keyframes maskShow {
    0% {
        opacity: 0;
        display: none;
    }
}
@keyframes maskHide {
    100% {
        opacity: 0;
        display: flex;
    }
}

.innerShow {
    animation: innerShow 0.5s ease;
}
.innerHide {
    animation: innerHide 0.5s ease forwards;
}
@keyframes innerShow {
    0% {
        transform: translateY(100vh);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes innerHide {
    0% {
        transform: translateY(0px);
        opacity: 0;
    }
    100% {
        transform: translateY(100vh);
    }
}
@media screen and (max-width: 750px) {
    .innerShow {
        margin: 0 10px;
    }
}
</style>
