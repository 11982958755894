<template>
    <div>
        <Go :prev="langJson('Back')" @prev="backPrev"></Go>
        <div id="participants"></div>
        <div class="ParticipantsNFTsBar">
            <div v-if="isPhone" class="ParticipantsNFTsDesc">
                <span class="ParticipantsNFTsDesc1">{{ langJson('hosted by') }}</span>
                &nbsp;
                <span class="ParticipantsNFTsDesc2">{{ brandUserVoNow.nickName }}</span>
            </div>
            <div class="ParticipantsNFTsRow1">
                <div @click="backPrev" class="ParticipantsNFTsTitle">
                    {{ globalMarket.campaignDetailUser.name }}
                </div>

                <div class="ParticipantsNFTsTag">
                    <div class="tagTitle">{{ langJson('Participated in') }}</div>
                    <GI style="margin-left: 8px" name="mint_check" ws="16" hs="16"></GI>
                </div>
            </div>
            <div v-if="!isPhone" class="ParticipantsNFTsDesc">
                <span class="ParticipantsNFTsDesc1">{{ langJson('hosted by ') }}</span>
                <span class="ParticipantsNFTsDesc2">{{ brandUserVoNow.nickName }}</span>
            </div>
        </div>

        <div class="floor_top">
            <div class="title">{{ langJson('Participants ') }}{{ `(${totalUser})` }}</div>
            <div class="top_list" :style="viewMore ? 'max-height: 999px' : 'max-height: 276px'">
                <div class="top_item" v-for="(item, index) of globalMarket.campaignParticipantsUser" :key="index + 'U'">
                    <ParticipantBrand :item="item" @click.native="jump_participant_campaign_participants_profile({ userId: item.userId })" :index="index"></ParticipantBrand>
                </div>
                <NuData height="300" v-if="!lenArray(globalMarket.campaignParticipantsUser)" />
            </div>
            <div class="viewMore" v-if="globalMarket.campaignParticipantsUser && globalMarket.campaignParticipantsUser.length > 12" @click="viewMore = !viewMore">
                <span class="viewMoreTitle">{{ langJson('View more') }}</span>
                <GI style="margin-left: 8px" :style="viewMore ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'" name="arrow-down-blue" ws="16" hs="16"></GI>
            </div>
        </div>

        <div class="submissions_center">
            <div id="artwork"></div>
            <div class="title">
                <h2 @click="backPrev" v-if="!isPhone">{{ globalMarket.campaignDetailUser.name }}</h2>
                <div v-else>{{ langJson('Submitted artworks ') }} {{ `(${totalArt})` }}</div>
            </div>
            <div class="status">
                <div class="status_scroll">
                    <SelBtn class="status_item" @change="change" v-model="status" type="main" :list="statuslist" size="unselected"></SelBtn>
                </div>
            </div>

            <StepRun ref="step">
                <div slot="step1" v-loading="loading">
                    <div class="artworks_box">
                        <div class="artworks_item" v-for="(item, index) of globalMarket.campaignWorksSelect" :key="index + 'P'">
                            <Artworks :item="item" :isParticipant="true" :showBottom="true" :hs="isPhone ? 136 : 204" :showHead="false" :top="isTopSort(index)"></Artworks>
                        </div>
                    </div>
                    <NuData height="300" v-if="!lenArray(globalMarket.campaignWorksSelect)" />
                    <div class="art_more">
                        <Page v-model="pageNumArt"
                        @change="init3" :all="totalArt" :size="pageSizeArt" :isBtn="true"></Page>
                    </div>
                </div>
                <div slot="step2" v-loading="loading">
                    <div class="artworks_box">
                        <div class="artworks_item" v-for="(item, index) of globalMarket.campaignWorksSelect" :key="index + 'K'">
                            <Artworks :item="item" :isParticipant="true" :showBottom="true" :hs="isPhone ? 136 : 204" :showHead="false" :top="isTopSort(index)"></Artworks>
                        </div>
                    </div>
                    <NuData height="300" v-if="!lenArray(globalMarket.campaignWorksSelect)" />
                    <div class="art_more">
                        <Page @change="init3" v-model="pageNumArt" :all="totalArt" :size="pageSizeArt" :isBtn="true"></Page>
                    </div>
                </div>
            </StepRun>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            totalUser: 0,
            pageSizeUser: 24,
            pageNumUser: 1,

            totalArt: 0,
            pageSizeArt: 24,
            pageNumArt: 1,

            type: 2, // 1-用户自己的作品 2-其他用户的作品

            viewMore: false,
            status: 1,
            statuslist: [
                {
                    lable: "Other participants' artworks",
                    value: 1,
                },
                {
                    lable: 'Your submitted artworks',
                    value: 2,
                },
            ],
            id: '',
            loading:false
        }
    },
    computed: {
        isTopSort() {
            return (index) => {
                try {
                    if (this.pageNumArt == 1 && index < 3) {
                        return index + 1
                    } else {
                        return ''
                    }
                } catch (err) {
                    return ''
                }
            }
        },
        brandUserVoNow() {
            try {
                let { brandUserVo } = this.globalMarket.campaignDetailUser
                return brandUserVo ? brandUserVo : {}
            } catch (error) {
                return {}
            }
        },
    },
    mounted() {
        let { id } = this.$route.query
        if (id) {
            this.id = id
        }
    },
    watch: {
        // 锚点
        id(val) {
            this.handleScroll(val)
        },
    },
    created() {
        this.init()
        this.init2()
        this.init3()
        window.voteComplete = () => {
            this.init3()
        }
    },
    methods: {
        // 活动里的艺术品
        async init3() {
            this.loading = true
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                let res = await dispatch('campaignWorksSelect', { pageSize: this.pageSizeArt, pageNum: this.pageNumArt, campaignId: idActive, type: this.type, orderByColumn: 'voteNum', isAsc: 'desc' })
                this.totalArt = res.total
            } catch (error) {
                console.log(error)
            }
            this.loading = false
        },
        // 活动里的参与者
        async init2() {
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                let res = await dispatch('campaignParticipantsUser', { pageSize: this.pageSizeUser, pageNum: this.pageNumUser, campaignId: idActive })
                this.totalUser = res.total
            } catch (error) {
                console.log(error)
            }
        },
        // 活动详情
        async init() {
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                await dispatch('campaignDetailUser', idActive)
            } catch (error) {
                console.log(error)
            }
        },
        next() {
            this.$refs.step.next()
        },
        prev() {
            this.$refs.step.prev()
        },
        backPrev() {
            this.back()
            // let { idActive } = this.$route.query
            // this.jump_participant_compaign_preview({ id: idActive })
        },
        change(val) {
            this.status = val
            this.globalMarket.campaignWorksSelect = []
            if (val == 1) {
                this.type = 2
                this.prev()
            }
            if (val == 2) {
                this.type = 1
                this.next()
            }

            this.pageNumArt = 1
            this.init3()
        },
        handleScroll(id) {
            var element = document.getElementById(id)
            if (element) {
                // console.log(element)
                this.$nextTick(() => {
                    setTimeout(() => {
                        element.scrollIntoView({
                            behavior: 'smooth',
                        })
                    }, 300)
                })
            }
        },
        beforeDestroy() {},
    },
}
</script>
<style lang="scss" scoped>
.submissions_center {
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    padding-top: 53px;
    padding-bottom: 73px;
    .title {
        h2 {
            color: var(--font_deep_color);
            
        }
        font-family: 'Cabinet Grotesk';
        cursor: pointer;
    }
    .status {
        margin-top: 16px;
    }
    .artworks_box {
        margin-top: 32px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;
        .artworks_item {
            max-width: 296px;
            min-width: 296px;
            background: var(--color_white);
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
        }
    }
    .art_more {
        margin-top: 36px;
        margin-bottom: 82px;
        width: 100%;
    }
}
.floor_top {
    display: flex;
    flex-direction: column;
    .top_list {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        column-gap: 32px;
        .top_item {
            margin-top: 24px;
        }
    }
    .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: var(--font_deep_color);
    }
    .viewMore {
        display: flex;
        align-items: center;
        justify-items: center;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 54px;
        user-select: none;
        .viewMoreTitle {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: var(--primary_color);
        }
    }
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 48px;
    padding-bottom: 36px;
    border-bottom: 1px solid var(--border_color);
}
.ParticipantsNFTsBar {
    margin: auto;
    width: 100%;
    max-width: var(--max_width);
    padding-bottom: 53px;
    border-bottom: 1px solid var(--border_color);
    .ParticipantsNFTsRow1 {
        display: flex;
        align-items: center;

        .ParticipantsNFTsTitle {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
            cursor: pointer;
        }
        .ParticipantsNFTsTag {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            background: var(--bg4);
            border-radius: 32px;
            margin-left: 12px;
            .tagTitle {
                font-family: 'Lato';
                font-style: normal;
                /* font-weight: 600; */
                font-size: 12px;
                line-height: 16px;
                color: var(--primary_color);
            }
        }
    }
    .ParticipantsNFTsDesc {
        margin-top: 10px;
        .ParticipantsNFTsDesc1 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_color);
        }
        .ParticipantsNFTsDesc2 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
}
@media screen and (max-width: 1080px) {
    .submissions_center {
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        padding-top: 53px;
        padding-bottom: 73px;
        .title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
            cursor: pointer;
        }
        .status {
            margin-top: 16px;
            overflow: auto;
            .status_scroll {
            }
        }
        .artworks_box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 32px;
            .artworks_item {
                width: 100% !important;
                max-width: 100% !important;
                background: var(--color_white);
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 16px;
            }
        }
        .art_more {
            margin-top: 36px;
            margin-bottom: 82px;
            width: 100%;
        }
    }
    .floor_top {
        display: flex;
        flex-direction: column;
        .top_list {
            margin-top: 24px;
            .top_item {
                margin-top: 0px;
                margin-right: 0px;
                width: 100%;
            }
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            overflow: hidden;
        }
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: var(--font_deep_color);
        }
        .viewMore {
            display: flex;
            align-items: center;
            justify-items: center;
            margin: 0 auto;
            cursor: pointer;
            margin-top: 54px;
            user-select: none;
            .viewMoreTitle {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: var(--primary_color);
            }
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--border_color);
    }
    .ParticipantsNFTsBar {
        margin: auto;
        width: 100%;
        max-width: var(--max_width);
        padding-bottom: 40px;
        border-bottom: 1px solid var(--border_color);
        box-sizing: border-box;
        .ParticipantsNFTsRow1 {
            margin-top: 8px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 8px;
            .ParticipantsNFTsTitle {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
            }
            .ParticipantsNFTsTag {
                display: flex;
                align-items: center;
                padding: 8px 16px;
                background: #eae0ff;
                border-radius: 32px;
                margin-left: 0px;
                .tagTitle {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--primary_color);
                }
            }
        }
        .ParticipantsNFTsDesc {
            margin-top: 0px;
            .ParticipantsNFTsDesc1 {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
            }
            .ParticipantsNFTsDesc2 {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
            }
        }
    }
}
</style>
