<template>
  <div :class="['tag', type, size,globalThemeColor]" @click="$emit('click')">
    <GI v-if="icon" :name="icon" ws="16" hs="16" @click="$emit('click')"></GI>
    <div v-if="icon" class="tag_space"></div>
    <div class="tag_txt">
      <div v-if="title" class="hide">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'mini',
    },
  },
}
</script>
<style lang="scss" scoped>
.tag {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 16px;
  cursor: pointer;
  .tag_space {
    width: 4px;
  }
  .tag_txt {
    .hide {
      color: var(--font_modif_color);
      margin-bottom: 2px;
    }
    font-family: 'Lato';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
    line-height: 16px;
  }
}
.mini {
  padding: 8px 16px;
}
.medium {
  padding: 10px 22px;
  .tag_txt {
    .hide {
      margin-bottom: 4px;
    }
    font-size: 14px;
    line-height: 18px;
  }
}
.big {
  padding: 12px 28px;
  .tag_txt {
    .hide {
      margin-bottom: 6px;
    }
    font-size: 16px;
    line-height: 20px;
  }
}
.warning {
  background: #ffeae0;
  .tag_txt {
    color: var(--warning_color);
  }
}
.primary {
  background: #eae0ff;
  .tag_txt {
    .hide {
      color: var(--primary_color) !important;
    }
    color: var(--primary_color);
  }
}

.danger {
  background: #ffe0e0;
  .tag_txt {
    color: var(--primary_color);
  }
}

.default {
  background-color: rgba($color: #ccc, $alpha: 0.2);
  .tag_txt {
    color: var(--font_deep_color);
  }
}

.success {
  background: #e0f0ff;
  .tag_txt {
    color: var(--success_color);
  }
}
.time {
  background: #e7f8e8;
  .tag_txt {
    color: var(--time_color);
  }
}
.vote {
  background: #e0f0ff;
  .tag_txt {
    color: #66b6ff;
  }
}

.more {
  background: var(--color_white);
  .tag {
    color: var(--success_color);
  }
}
.theme_dark {
  background: transparent;
  .tag_txt{
    color: #fff;
  }
}
@media only screen and (max-width: 1080px) {
  .tag {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 16px;
    cursor: pointer;
    .tag_space {
      width: 4px;
    }
    .tag_txt {
      .hide {
        color: var(--font_modif_color);
        margin-bottom: 2px;
      }
      font-family: 'Lato';
      font-style: normal;
      /* font-weight: 600; */
      font-size: 12px;
      line-height: 16px;
    }
  }
  .mini {
    padding: 4px 16px;
  }
}
</style>
