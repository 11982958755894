<template>
    <Model :title="langJson('Choose payment method')" :subtitle="isPhone ? '' : langJson('For gas fee')" v-model="modelShow" @change="close">
        <div class="model_main" slot="main">
            <StepRun ref="step">
                <div class="buy_detail" slot="step1">
                    <div class="mint_fee">
                        <div class="fee_title">{{ langJson('NFTWerks Mint Fee') }}</div>
                        <div class="fee_right">
                            <div class="estimate isPc">{{ langJson('Estimate') }}</div>
                            <div class="amount">
                                {{ amount | numberGrap }}

                                {{ symbolDetail.name }}
                            </div>
                        </div>
                    </div>

                    <div class="methods">
                        <div class="method_item" :class="[globalThemeColor]">
                            <div class="item_left">
                                <GI name="metamask_small" ws="24" hs="24"></GI>
                                <div class="method_title">{{ langJson('Via Metamask') }}</div>
                            </div>
                            <div class="item_right">
                                <GI name="method_check" ws="24" hs="24"></GI>
                            </div>
                        </div>
                    </div>

                    <div class="model_btns">
                        <div class="model_btn">
                            <BTN class="btn_item" type="detail" bh="52" @handle="close">{{ langJson('Cancel') }}</BTN>
                        </div>
                        <div class="btn_grap"></div>
                        <div class="model_btn">
                            <BTN class="btn_item" type="active" @handle="confirm">{{ langJson('Confirm') }}</BTN>
                        </div>
                    </div>
                </div>
            </StepRun>
        </div>
    </Model>
</template>
<script>
let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    data() {
        return {
            modelShow: false,
            hash: '',
            symbolDetail: {},
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        amount: {
            type: [Number, String],
            default: '',
        },

        form: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    computed: {
        usdtPrice() {
            return (num) => {
                return saveNum(num)
            }
        },
        tokens() {
            try {
                let result = []
                let { chainDetail } = this.walletDetail
                if (chainDetail && chainDetail.id) {
                    result = this.globalToken.filter((item) => {
                        return item.chainId == chainDetail.id
                    })
                }
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
    },
    watch: {
        modelShow(n) {
            this.$emit('change', n)
        },
        show(n) {
            this.modelShow = n
        },
        'form.chainId'(n) {
            this.getSymbolDetail()
        },
    },
    created() {
        this.modelShow = this.show
    },
    mounted() {
        this.getSymbolDetail()
    },
    methods: {
        getSymbolDetail() {
            try {
                if (this.form.chainId) {
                    let chainSymbol = this.globalToken.filter((item) => {
                        return item.chainId == this.form.chainId
                    })
                    let symbolDetail = chainSymbol.find((item) => {
                        return item.type - 0 === 0
                    })
                    if (symbolDetail) {
                        this.symbolDetail = symbolDetail
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        confirm() {
            this.close()
            this.$emit('finash', {
                symbol: this.symbolDetail,
                amount: this.amount,
            })
        },

        failed() {
            this.close()
            this.pushMsg('error', this.langJson('Extract failed'))
        },
        close() {
            this.$emit('change', false)
            // 完成后刷新列表
            this.$emit('completeExtract')
        },
        next() {
            this.$refs.step.next()
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .buy_detail {
        .methods {
            .method_item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 24px;
                box-sizing: border-box;
                height: 56px;
                background: var(--bg4);
                border: 1px solid #9766ff;
                border-radius: 24px;
                margin-top: 32px;
                .item_left {
                    .method_title {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        margin-left: 24px;
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                /* &.theme_dark {
                    background: #eae0ff;
                    border: 1px solid #9766ff;
                } */
            }
            width: 100%;
        }
        .mint_fee {
            .fee_right {
                .estimate {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 8px 16px;
                    background: #ffeae0;
                    color: #ff9466;
                    border-radius: 32px;
                }
                .amount {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--font_deep_color);
                    margin-left: 16px;
                }
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            .fee_title {
                color: var(--font_deep_color);
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                flex: 1;
                white-space: nowrap;
            }
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            height: 40px;
            margin-top: 32px;
            width: 100%;
        }
        .model_btns {
            .btn_grap {
                width: 20px;
            }
            .model_btn {
                flex: 1;
            }
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .account_balance {
            .balance {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #1b1b2c;
            }
            .down {
                color: var(--danger_color);
            }
            .up {
                color: var(--success_color);
            }
            .balance_title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #1b1b2c;
            }
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .info_item {
            .price_item {
                .price_amount {
                    display: flex;
                    align-items: flex-end;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 24px;
                    color: #1b1b2c;
                    margin-top: 12px;
                    word-break: break-all;
                    .symbol_icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }
                    .price_usdt {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                        color: #1b1b2c;
                        margin-left: 10px;
                    }
                }
                .price_title {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: #1b1b2c;
                }
                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .price_left {
                flex: 1;
            }
            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 0px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    box-sizing: border-box;
    width: 558px;
    max-width: 558px;
    min-width: 280px;
}
@media screen and (max-width: 1081px) {
    .model_main {
        width: auto !important;
    }
}
@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
    .mint_fee {
        align-items: flex-start;
        .fee_right {
            flex-wrap: wrap;
        }
    }
}
</style>
