<template>
    <div>
        <Go :prev="langJson('Back')" @prev="back">
            <BTN class="isPc" icon_left="brand_share" @handle="share" type="detail" bh="52" bw="169">{{ langJson('Share') }}</BTN>
        </Go>
        <div class="detail_box">
            <div class="inner_box">
                <div class="left_box">
                    <div class="submitter">
                        <h2 class="title">{{ detail.artworkTitle }}</h2>
                        <div class="submitter_by">
                            {{ langJson('Submitted by') }} &nbsp;&nbsp;
                            <img :src="userVo.imageUrl" class="userIcon cursor" alt="" @click="jump_participant_campaign_participants_profile({ userId: detail.userId })" />
                            <span class="cursor" @click="jump_participant_campaign_participants_profile({ userId: detail.userId })">{{ userVo.nickName }}</span>
                        </div>
                    </div>
                    <div v-if="isPhone" :style="{ backgroundImage: `url(${detail.artworkImg})` }" class="mobile_box"></div>
                    <div class="vote_btn">
                        <BTN :class="[detail.voteFlag && 'voted']" type="active" bh="52" bw="150" @handle="VoteMethod(detail)" borderWidth="1" :load="loading">{{ langJson('Vote') }}</BTN>
                        <div class="number">{{ detail.voteNum | numberGrap }} {{ langJson('votes') }}</div>
                    </div>
                    <div class="description">
                        <div>
                            <h2>{{ langJson('Description') }}</h2>
                            <p v-html="detail.description" class="v-html"></p>
                        </div>
                        <div>
                            <h2>{{ langJson('Artist') }}</h2>
                            <p v-html="detail.artworkCredit" class="v-html"></p>
                        </div>
                    </div>
                </div>
                <div v-if="!isPhone" :style="{ backgroundImage: `url(${detail.artworkImg})` }" class="right_box"></div>
            </div>
        </div>
        <div class="other_artworks">
            <div class="card_container">
                <Votegroup :itemPadding="'12px'" :itemWidth="isPhone ? 252 : 276" :title="`${langJson('Other artworks of')}<span class='cursor' style='color:#9766ff;' onclick='window.jumeUser()'>&nbsp;${userVo.nickName}&nbsp;</span>${langJson('in this campaign')}`" :list="globalMarket.userOtherCampaignWorks">
                    <template v-slot:item="data">
                        <Votecard :isParticipant="true" :item="data.item" :hs="isPhone ? 136 : 204"></Votecard>
                    </template>
                </Votegroup>
            </div>
        </div>

        <ProGroup :isParticipant="true" :title="langJson('Phifer Hall also joined')" :list="globalMarket.joinCampaignListUser"></ProGroup>
        <div style="height: 50px; width: 50px"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading:false
        }
    },
    computed: {
        detail() {
            try {
                return this.globalMarket.campaignWorks ? this.globalMarket.campaignWorks : {}
            } catch (error) {
                return {}
            }
        },
        userVo() {
            try {
                return this.detail.userVo ? this.detail.userVo : {}
            } catch (error) {
                return {}
            }
        },
    },
    created() {
        this.init()
        window.jumeUser = () => {
            this.jump_participant_campaign_participants_profile({ userId: this.detail.userId })
        }
        this.init2()
        this.init3()
        window.voteComplete = () => {
            this.init()
            this.init3()
        }
    },
    watch: {
        $route() {
            this.init()
            this.init3()
        },
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        async VoteMethod(detail){
            this.loading = true
            try{
                await this.vote(detail)
            }catch(err){
            }
            this.loading = false
        },
        // 用户其他参与活动的作品
        async init3() {
            this.load_btn = true
            try {
                let { idActive, idArt, idUser } = this.$route.query
                let { dispatch } = this.$store
                await dispatch('userOtherCampaignWorks', {
                    campaignId: idActive,
                    workId: idArt,
                    userId: idUser,
                })
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
        // 用户 参与的活动
        async init2() {
            try {
                let { dispatch } = this.$store
                let { idUser } = this.$route.query
                await dispatch('joinCampaignListUser', { pageNum: 1, pageSize: 24, userId: idUser })
            } catch (err) {
                console.log(err)
            }
        },
        // 获取艺术品详情
        async init() {
            try {
                let { idArt } = this.$route.query
                // 用户id
                let { dispatch } = this.$store
                await dispatch('campaignWorks', {
                    id: idArt,
                })
            } catch (err) {
                console.log(err)
            }
        },
        share() {
            this.$store.commit('changeShowShare', true)
            this.$store.commit('shareObj', { img: this.detail.artworkImg, title: this.langJson('Share your ArtWork'), url: ``, linkName: this.langJson('Artwork link') })
        },
    },
}
</script>
<style lang="scss" scoped>
.voted {
    border: 1px solid !important;
    border-color: var(--font3) !important;
    color: var(--font3) !important;
    background: transparent !important;
}

.detail_box {
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    padding: 32px;
    box-sizing: border-box;
    min-height: 624px;
    background: var(--color_white);
    border-radius: 32px;

    display: flex;

    .inner_box {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 47px;
        .left_box {
            word-break: break-all;
            width: 496px;
            min-height: 444px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            .submitter {
                display: flex;
                flex-direction: column;
                .title {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px;
                    color: var(--font_deep_color);
                }
                .submitter_by {
                    flex-wrap: wrap;
                    font-family: 'Lato';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    .userIcon {
                        width: 32px;
                        height: 32px;
                        border-radius: 40px;
                        margin-right: 8px;
                    }
                    span {
                        max-width: 496px;
                        font-family: 'Inter';
                        font-weight: 800;
                        font-size: 18px;
                        word-wrap:break-word;
                        line-height: 28px;
                        color: var(--font_deep_color);
                    }
                }
            }

            .vote_btn {
                display: flex;
                align-items: center;
                gap: 22px;
                .number {
                    width: 99px;
                    height: 28px;

                    font-family: 'Inter';

                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;

                    display: flex;
                    align-items: center;

                    color: var(--font_deep_color);
                }
            }
            .description {
                padding-top: 20px;
                border-top: 1px solid var(--border_color);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                width: 496px;
                min-height: 236px;
                h2 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--font_deep_color);
                }
                p {
                    margin-top: 10px;
                    font-family: 'lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                }
            }
        }
        .right_box {
            width: 673px;
            height: 560px;

            border-radius: 24px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}
.other_artworks {
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    box-sizing: border-box;
    margin-top: 40px;
    .other_artworks_title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_deep_color);
    }
    .card_container {
        display: flex;
        gap: 32px;
        .card_item {
            width: 296px;
            margin-top: 32px;
            background: var(--color_white);
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
        }
    }
}
.share_icons {
}
@media screen and (max-width: 1080px) {
    /deep/ {
        .floor_new .new_top .title {
            font-family: 'Lato' !important ;
            font-style: normal !important;
            font-weight: 700 !important;
            font-size: 16px !important;
            line-height: 24px !important;
        }
    }
    .inner_box {
        gap: 0px !important;
    }
    .detail_box {
        height: auto;
        background: transparent;
        padding: 0px;
    }
    .left_box {
        height: auto !important;
        width: 100% !important;
        gap: 24px !important;
    }
    .description {
        width: 100% !important;
        height: auto !important;
    }
    .mobile_box {
        width: 100%;
        height: 335px;
        border-radius: 20px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .other_artworks {
        margin-top: 24px;
        margin-bottom: 19px;
        .other_artworks_title {
            font-size: 18px;
            line-height: 28px;
        }
        .card_container {
            margin-top: 16px !important;
            gap: 8px;
        }
    }
    .submitter_by{
        span{
            max-width: var(--max_width) !important;
        }
    }
    .vote_btn {
        margin-top: 8px;
    }
}
</style>
