<template>
    <div class="personal">
        <Go v-if="isPhone" :prev="langJson('Back')" @prev="back"></Go>
        <div class="personal_center">
            <div class="personal_banner">
                <div
                    class="banner"
                    :style="{
                        backgroundImage: `url(${userInfo.bannerUrl ? userInfo.bannerUrl : 'https://w.wallhaven.cc/full/j3/wallhaven-j3m8y5.png'})`,
                    }"
                ></div>
            </div>
            <div class="personal_portrait">
                <div
                    class="portrait"
                    :style="{
                        backgroundImage: `url(${userInfo.imageUrl ? userInfo.imageUrl : 'https://w.wallhaven.cc/full/kx/wallhaven-kx98xd.jpg'})`,
                    }"
                ></div>
            </div>

            <div class="personal_name">{{ userInfo.nickName }}</div>
            <div class="personal_detail">
                <div class="personal_address">
                    <!-- abc1344...cde
            <GI class="copy" name="personal_copy" ws="24" hs="24"></GI> -->
                </div>
                <div class="personal_email">{{ userInfo.email }}</div>
            </div>
            <div class="personal_desc">{{ userInfo.bio }}</div>
            <div class="personal_edit">
                <BTN @handle="jump_brand_profile_editProfile" bh="48" bw="127" type="detail">{{ langJson('Edit profile') }}</BTN>
            </div>
            <div class="personal_main">
                <Tab type="line" align="center" v-model="tab" :list="tablist" @change="switchTab"></Tab>

                <StepRun ref="tab">
                    <!-- <myCreatedNtfs :isParticipant="false" slot="tab1"></myCreatedNtfs> -->
                    <template slot="tab1">
                        <CreatedNFTs :list="globalMarket.nftProductList" @change="changeNft"></CreatedNFTs>
                        <!-- <div class="link_wallet" v-if="!walletDetail.address">
                            <BTN type="active" bh="52" bw="150" @handle="linkWallet()">{{ langJson('Link Wallet') }}</BTN>
                        </div> -->
                        <NuData height="300" v-if="!lenArray(globalMarket.nftProductList)" />
                        <div class="art_more">
                            <Page v-model="pageNumNft" :all="totalNft" :size="pageSizeNft" :isBtn="true" @change="init2"></Page>
                        </div>
                    </template>
                    <template slot="tab2">
                        <JoinedCompaigns :list="myCompaign" @change="changeActive"></JoinedCompaigns>
                        <NuData height="300" v-if="!lenArray(myCompaign)" />
                        <div class="art_more">
                            <Page v-model="pageNumActive" :all="totalActive" :size="pageSizeActive" :isBtn="true" @change="init3"></Page>
                        </div>
                    </template>
                </StepRun>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageSizeNft: 24,
            pageNumNft: 1,
            totalNft: 0,

            pageSizeActive: 24,
            pageNumActive: 1,
            totalActive: 0,

            tab: 1,
            tablist: [
                {
                    value: 1,
                    lable: this.langJson('Created NFTs'),
                    icon: 'profile_nft_deactive',
                    iconActive: 'profile_nft_active',
                },
                {
                    value: 2,
                    lable: this.langJson('My campaigns'),
                    icon: 'profile_folder_deactive',
                    iconActive: 'profile_folder_active',
                },
            ],
            source: {},
        }
    },
    watch: {
        $route() {
            if (this.$route.query.tab) {
                this.tab = parseInt(this.$route.query.tab)
                this.switchTab(this.tab)
            }
        },
    },
    async created() {
        await this.init()
        await this.init2()
        await this.init3()
    },
    mounted() {
        if (this.$route.query.tab) {
            this.tab = parseInt(this.$route.query.tab)
            this.switchTab(this.tab)
        }
    },
    methods: {
        async linkWallet() {
            await this.connect()
            await this.init2()
        },
        changeNft(source) {
            this.pageNumNft = 1
            this.source = source
            this.init2()
        },
        changeActive(source) {
            this.pageNumArt = 1
            this.source = source
            this.init3()
        },
        // 商户 活动
        async init3() {
            try {
                let { dispatch } = this.$store
                // let { idUser } = this.$route.query
                // brandUserId: idUser 
                let res = await dispatch('myCompaign', Object.assign({ pageNum: this.pageNumActive, pageSize: this.pageSizeActive }, this.source))
                this.totalActive = res.data.total
            } catch (error) {
                console.log(error)
            }
        },
        // 商户 NFT
        async init2() {
            try {
                let { dispatch, commit } = this.$store
                let { idUser } = this.$route.query
                let res = await dispatch('nftProductList', Object.assign({ pageNum: this.pageNumNft, pageSize: this.pageSizeNft, userId: idUser }, this.source))
                this.totalNft = res.total

                // if (this.walletDetail.address) {
                //     let res = await dispatch('nftProductList', Object.assign({ pageNum: this.pageNumNft, pageSize: this.pageSizeNft, creator: this.walletDetail.address }, source))

                //     this.totalNft = res.total
                //     console.log('this.totalNft', this.totalNft)
                // } else {
                //     commit('nftProductList', [])
                // }
            } catch (error) {
                console.log(error)
            }
        },
        // 商户 信息
        async init() {
            try {
                let { dispatch } = this.$store
                let { idUser } = this.$route.query
                await dispatch('infoByUserId', { userId: idUser })
            } catch (error) {
                console.log(error)
            }
        },
        switchTab(val) {
            // console.log(val)
            this.tablist.find((item, index) => {
                if (item.value == val) {
                    let { idUser } = this.$route.query
                    this.jump_brand_profile_myprofile({ idUser, tab: val }, false)
                    this.$refs.tab.jump(index)
                    this.source = {}
                    return true
                } else {
                    return false
                }
            })
            //   this.jump_personal('0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.art_more {
    margin-top: 36px;
    margin-bottom: 82px;
    width: 100%;
}
.link_wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}
.personal {
    .personal_center {
        .personal_main {
            // margin-top: 32px;

            width: 100%;
            padding-bottom: 50px;
        }

        .personal_desc {
            margin-top: 16px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--font_color);
            width: 100%;
            max-width: 624px;
            padding: 0 24px;
            box-sizing: border-box;
        }
        .personal_edit {
            margin-top: 16px;
            margin-bottom: 32px;
        }
        .personal_detail {
            display: flex;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
            color: var(--font_color);
        }
        .personal_name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            margin-top: 16px;
        }
        .personal_portrait {
            .portrait {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-color: var(--color_white);
                position: absolute;
                bottom: 0;
                left: -60px;
            }
            height: 60px;
            position: relative;
        }
        .personal_banner {
            .banner {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                width: 100%;
                height: 100%;
                background-color: var(--color_white);
            }
            border-radius: 24px;
            overflow: hidden;
            height: 280px;
            width: 100%;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
