export default {
	Abbre(value) {
		return value.slice(2, 8).toUpperCase();
	},
    hideStr(val,len = 6) {
		if (!val) {
			return val
		}
		let reg = `^(.{${len}}).*(.{${len}})$`
		let patt = new RegExp(reg)
		return val.replace(patt, '$1...$2')
	},
    numberGrap(val){
		if(val){
			val = val + ''
		}else{
			return 0
		}
		
		let float = val.split('.')[1]
		if(float && float.length > 4){
			float = float.slice(0,4)
		}
		val = val.split('.')[0]
		val = val.split("")
		val.reverse()
		val = val.join("")
		if(val.length > 3){
			let last = ''
			if(val.length % 3 != 0){
				last = val.slice(val.length - val.length % 3)
				last = last.split("")
				last.reverse()
				last = last.join("")
			}
			let temp = val.match(/\w{3}/g).map(item=>{
				item = item.split("")
				item.reverse()
				item = item.join("")
				return item
			}).reverse().join(',')
			if(last){
				return float ? `${last + ',' + temp}.${float}` : last + ',' + temp
			}else{
				return float ? `${temp}.${float}` : temp
			}
		}else{
			val = val.split("")
			val.reverse()
			return float ? `${val.join("")}.${float}` : val.join("")
		}
	},
}