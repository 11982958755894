<template>
    <img
        v-if="!noClickStop"
        :style="{
            width: ws ? `${ws}px` : '100%',
            height: hs ? `${hs}px` : 'auto',
        }"
        :class="['icon', rotate ? 'rotate' : '']"
        :src="src ? src : `/source/${type}/${name + (!isActive && type == 'svg' ? '_dark' : '')}.${type}`"
        @click.stop="clickHandle"
    />
    <img
        v-else
        :style="{
            width: ws ? `${ws}px` : '100%',
            height: hs ? `${hs}px` : 'auto',
        }"
        :class="['icon', rotate ? 'rotate' : '']"
        :src="src ? src : `/source/${type}/${name + (!isActive && type == 'svg' ? '_dark' : '')}.${type}`"
        @click="clickHandle"
    />
</template>

<script>
export default {
    name: 'GlobalSvg',
    data() {
        return {}
    },
    props: {
        src: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'svg',
        },
        name: {
            type: String,
            default: '',
        },
        ws: {
            type: [Number, String],
            default: 0,
        },
        hs: {
            type: [Number, String],
            default: 0,
        },
        rotate: {
            type: Boolean,
            default: false,
        },
        noClickStop: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isActive() {
            return this?.globalTheme == 'light'
        },
    },
    watch: {
        isActive() {
            setTimeout(() => {
                this.$forceUpdate()
            }, 200)
        },
    },
    created() {},
    methods: {
        clickHandle() {
            this.$emit('click')
        },
    },
}
</script>

<style lang="scss" scoped>
.icon {
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: inline-block;
}

@keyframes rotateHandle {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotate {
    animation: rotateHandle 1s linear forwards infinite;
}
</style>
