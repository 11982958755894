<template>
  <div class="foot_list">
    <div class="foot_class" v-for="(item, index) in datas" :key="index">
      <div class="class_name">{{ item.className }}</div>
      <div class="item" v-for="(subItem, subIndex) in item.list" :key="subIndex" >
        {{ subItem.title }}
      </div>
    </div>
  </div>
</template>
<script>
import helpApi from "@/api/help";
export default {
  data() {
    return {
      Links: [],
      dataList: [],
      datas: [
        {
          className: "MARKET",
          typeId: 2,
          list: [
            {
              id: 14,
              articleId: 3,
              title: "NEW IN",
              typeId: 2,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "https://www.google.com/",
              externalLinkFlag: 1,
              status: 1,
              sort: 1,
              createTime: "2022-09-03 15:12:28",
              updateTime: "2022-09-03 15:12:28",
            },
            {
              id: 18,
              articleId: 4,
              title: "TRENDING",
              typeId: 2,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "https://www.atverse.space/nftStaking",
              externalLinkFlag: 1,
              status: 1,
              sort: 2,
              createTime: "2022-09-03 15:13:13",
              updateTime: "2022-09-17 11:02:44",
            },
            {
              id: 22,
              articleId: 5,
              title: "GAMING",
              typeId: 2,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "https://www.atverse.space/market",
              externalLinkFlag: 1,
              status: 1,
              sort: 3,
              createTime: "2022-09-03 15:14:39",
              updateTime: "2022-09-17 11:01:51",
            },
          ],
        },
        {
          className: "RESOURCES",
          typeId: 3,
          list: [
            {
              id: 26,
              articleId: 6,
              title: "HELP CENTER",
              typeId: 3,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: null,
              externalLinkFlag: 0,
              status: 1,
              sort: 1,
              createTime: "2022-09-03 15:15:36",
              updateTime: "2022-09-03 15:15:36",
            },
            {
              id: 30,
              articleId: 7,
              title: "PRIVACY AGREEMENT",
              typeId: 3,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "https://www.google.com",
              externalLinkFlag: 1,
              status: 1,
              sort: 2,
              createTime: "2022-09-03 15:17:53",
              updateTime: "2022-09-03 15:17:53",
            },
            {
              id: 34,
              articleId: 8,
              title: "SERVICE TERMS",
              typeId: 3,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "SERVICE TERMS",
              externalLinkFlag: 1,
              status: 1,
              sort: 4,
              createTime: "2022-09-03 15:18:20",
              updateTime: "2022-09-03 15:18:20",
            },
          ],
        },
        {
          className: "ACOUNT",
          typeId: 4,
          list: [
            {
              id: 38,
              articleId: 9,
              title: "PROFILE",
              typeId: 4,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "PROFILE",
              externalLinkFlag: 1,
              status: 1,
              sort: 1,
              createTime: "2022-09-03 15:19:01",
              updateTime: "2022-09-03 15:19:01",
            },
            {
              id: 42,
              articleId: 10,
              title: "ASSET DISPLAY",
              typeId: 4,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "ASSET DISPLAY",
              externalLinkFlag: 1,
              status: 1,
              sort: 2,
              createTime: "2022-09-03 15:19:38",
              updateTime: "2022-09-03 15:19:38",
            },
            {
              id: 46,
              articleId: 11,
              title: "KYC CERTIFICATION",
              typeId: 4,
              lang: "en_US",
              content: "",
              description: null,
              imgUrl: null,
              externalLink: "KYC CERTIFICATION",
              externalLinkFlag: 1,
              status: 1,
              sort: 3,
              createTime: "2022-09-03 15:20:02",
              updateTime: "2022-09-03 15:20:02",
            },
          ],
        }
      ],
    };
  },
  created() {
    // this.articleType()
  },
  methods: {
    async articleType() {
      try {
        this.datas = [];
        let result = await helpApi.getTypes();
        let success = result && result.code == 200;
        if (success) {
          result.data.forEach((item, index) => {
            this.datas.push({});
            this.$set(this.datas[index], "className", item.title);
            this.$set(this.datas[index], "typeId", item.typeId);
          });
          await this.batchDo();
        } else {
          this.$tip.error(result.msg);
        }
      } catch (err) {
        console.log(err);
        this.$tip.error(err);
      }
    },
    async batchDo() {
      for (let i = 0; i < this.datas.length; i++) {
        let list = await this.articleTypelist(this.datas[i].typeId);
        this.$set(this.datas[i], "list", list);
      }
    },
    async articleTypelist(typeId) {
      try {
        let result = await helpApi.getArticles({
          typeId,
        });
        let success = result && result.code == 200;
        if (success) {
          return result.data;
        } else {
          this.$tip.error(result.msg);
        }
      } catch (err) {
        console.log(err);
        this.$tip.error(err);
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.foot_list {
  display: flex;
  // align-items: flex-end;
  // justify-content: flex-end;
  // justify-content: center;
  flex-wrap: wrap;
  // padding: 0 50px;
  box-sizing: border-box;
  .foot_class {
    min-width:240px;
    margin-bottom:20px;
    .class_name {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color:var(--font_deep_color);
      text-align: left;
      margin-bottom: 10px;
    }
    .item {

      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color:var(--font_modif_color);
      font-size: 12px;

      margin-top: 6px;
      font-weight: 100 !important;
      text-align: left;
      cursor: pointer;
    }
  }
}
</style>
