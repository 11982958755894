<template>
    <div class="reset_form">
        <div class="form_title">
            <Logo></Logo>
            <div class="title_info">
                <h2>{{ langJson('Change and bind your email?') }}</h2>
                <p>{{ langJson('Enter your new email address here') }}</p>
            </div>
        </div>

        <div class="form_input">
            <FormItem v-model="form.email" class="information_item" type="text" :matchs="['unull', 'email']" :title="langJson('Email')"></FormItem>
        </div>
        <div class="form_btn">
            <BTN @handle="changeEmail" :disable="!pass" type="active" :bh="isPhone ? 48 : 52">{{ langJson('Confirm') }}</BTN>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        verifyCode: {
            type: [String, Number],
            default: () => {
                return ''
            },
        },
    },
    data() {
        return {
            form: {
                email: '',
                phone: '',
            },
        }
    },
    computed: {
        pass() {
            try {
                let { email } = this.form
                let result = this.checkPass(email, ['unull', 'email'], false)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    methods: {
        async changeEmail() {
            try {
                console.log('this.verifyCode', this.verifyCode)
                let { dispatch } = this.$store
                let result = await dispatch('updateEmailConfirm', {
                    email: this.form.email,
                    verifyCode: this.verifyCode,
                })
                if (result) {
                    this.pushMsg('success', this.langJson('Change bind success'))
                    this.close()
                }
            } catch (error) {}
        },

        close() {
            this.$emit('close', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.reset_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
    gap: 32px;
    width: 406px;
    box-sizing: border-box;
    .form_title {
        box-sizing: border-box;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
        .title_info {
            p {
                color: var(--font_modif_color);
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-top: 4px;
            }
            h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 52px;
                text-align: center;
                color: var(--font_deep_color);
            }
        }
    }
    .form_btn {
        width: 100%;
    }
    .form_input {
        width: 100%;
    }
}
@media screen and (max-width: 1081px) {
    .reset_form {
        width: 287px;
        gap: 20px !important;
        padding-top: 20px !important;
    }
    .form_main {
        gap: 20px !important;
    }
    .form_title {
        gap: 12px !important;
    }
    .form_btn {
        margin-top: 8px;
    }
    .title_info {
        h2 {
            font-size: 20px !important;
            line-height: 32px !important;
        }
    }
}
</style>
