<template>
    <div>
        <div class="header">
            <h2>{{ langJson('Add your stickers') }}</h2>
            <span>{{ langJson('Enhance your communication with personalized flair by adding your own vibrant stickers.') }}</span>
        </div>
        <div>
            <div class="form">
                <div class="form_box_upload">
                    <div class="title">{{ langJson('Add stickers') }}</div>
                    <UploadBatch ref="UploadBatch" v-model="files" @change="fileGet"></UploadBatch>
                    <!-- <FormItem :title="langJson('Add stickers')" class="information_item" type="image" :image="bannerConfig" :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')"></FormItem> -->
                </div>
            </div>
            <div v-if="!!selectList.length" class="form">
                <div class="form_box">
                    <div class="upper_btns">
                        <div class="title">{{ langJson('Sticker library') }}</div>
                        <div class="select_btns">
                            <BTN @handle="delSticker" icon_left="brand_trash" type="warn" bh="52" bw="127">{{ langJson('Delete ') }}</BTN>
                            <BTN @handle="selectAll" type="detail" bh="52" bw="127">{{ langJson('Select all') }}</BTN>
                        </div>
                    </div>
                    <div class="select_box">
                        <div v-for="(item, index) in selectList" @click="selectItem(item)" :style="{ backgroundImage: `url(${item.path})` }" :key="index" :class="['select_item', 'bgCover', item.isSelected && 'selected_item']">
                            <GI v-if="item.isSelected" class="check" name="brand_checked" ws="18" hs="18"></GI>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns">
                <div class="next_step">
                    <span>{{ langJson('Your changes have been saved') }}</span>
                    <div>
                        <BTN @handle="goLink" type="active" bh="52" bw="152" class="BtnLeft">{{ langJson('Last: Link') }}</BTN>
                        <BTN @handle="goTreaty" type="active" bh="52" bw="195">{{ langJson('Next: Smart Contract Deployer') }}</BTN>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bannerConfig: {
                type: 'big',
            },
            bannerConfig2: {
                type: 'small',
            },
            selectList: [],
            files: [],
        }
    },
    watch: {
        selectList: {
            handler(newVal) {
                let arr = newVal.map((item) => {
                    return item.path
                })
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    stickers: arr.join(','),
                })
            },
            deep: true,
        },
    },
    inject: ['switchTab'],
    created() {
        this.initData()
    },
    methods: {
        initData() {
            let { stickers } = this.compaignDetail
            if (stickers) {
                this.files = stickers.split(',').map((item) => {
                    return {
                        source: '',
                        url: item,
                        name: '',
                        type: 'image/png',
                    }
                })
                setTimeout(() => {
                    this.selectList = this.files.map((item) => {
                        return {
                            path: item.url,
                            isSelected: true,
                        }
                    })
                }, 200)
                
            }
        },
        delSticker() {
            let arr = []
            this.selectList.forEach((item, index) => {
                if (item.isSelected) {
                    arr.push(index)
                }
            })

            this.$refs.UploadBatch.deleteMul(arr)
        },
        fileGet(files) {
            this.selectList = files.map((item) => {
                return {
                    path: item.url,
                    isSelected: false,
                }
            })
        },
        selectItem(item) {
            item.isSelected = !item.isSelected
        },
        selectAll() {
            this.selectList.forEach((item) => {
                if (item.isSelected) {
                    return
                } else {
                    item.isSelected = !item.isSelected
                }
            })
        },
        goLink() {
            this.switchTab(3)
        },
        goTreaty() {
            this.switchTab(5)
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }
    span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_modif_color);
    }
}
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;

    .form_box_upload {
        width: 100%;
        border-radius: 32px;
        background: var(--color_white);
        display: flex;
        flex-direction: column;
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            color: var(--font_deep_color);
        }
        .information_item {
            width: 100%;
            .textarea {
                margin-top: 42px;
            }
        }
        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
        }
    }
    .form_box {
        width: 100%;
        background: var(--color_white);
        display: flex;
        flex-direction: column;
        gap: 16px;
        .upper_btns {
            gap: 16px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
            .select_btns {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }
    .select_box {
        width: 100%;
        height: auto;
        padding-right: 52px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
        .select_item {
            .check {
                position: absolute;
                top: 10px;
                right: 10px;
            }
            width: 148px;
            height: 162px;
            border: 1px solid transparent;
            box-sizing: border-box;
            border-radius: 16px;
            position: relative;
        }
        .selected_item {
            border: 1px solid var(--primary_color);
            border-radius: 16px;
        }
    }
}
.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin: 32px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
        div{
            display: flex;
            .BtnLeft{
                margin-right: 15px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .form {
        padding: 20px;
    }
    .btns {
        flex-direction: column;
        gap: 24px;
    }
    .header {
        h2 {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        span {
            font-size: 14px;
        }
    }
    .next_step {
        flex-direction: column;
        align-items: flex-start !important;
    }
}

@media screen and (max-width: 500px) {
    .btns {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 35px;
        .next_step {
            flex-direction: column;
            align-items: flex-start !important;
            div{
                flex-direction: column;
                .BtnLeft{
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
