import api from '@/api/person'
const personModule = {
    state: {
        // nftlist: [],
    },
    getters: {},
    mutations: {
        // nftlist(state, nftlist) {
        //     state.nftlist = nftlist
        // },
    },
    actions: {
        // async nftGet(context, params = {}) {
        //     let result = await api.nftlist(params)
        //     result = this.apiResult(result)
        //     if (result && result.rows) {
        //         context.commit('nftlist', result.rows)
        //     }
        //     return result
        // },
    },
}
export default personModule
