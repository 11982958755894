<template>
    <div class="collected">
        <SearchKey></SearchKey>
        <div class="collect_item" v-for="(item,index) of 24" :key="index">
            <ProItem :viewEle="viewEle"></ProItem>
        </div>

        <div class="page">
            <Page v-model="pageNum" :all="total" :size="pageSize"></Page>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            viewEle:{
                price:true,
                status:false,
                play:false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.collected{
    .page{
        margin-top:10px;
        display: flex;
        justify-content: center;
        width:100%;
    }
    .collect_item{
        min-width: 240px;
        width:24%;
        background:var(--color_white);
        padding: 16px;
        box-sizing: border-box;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 16px;
        white-space: wrap!important;
        margin-bottom:24px;
        &:hover{
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
        }
    }
    width:100%;
    margin-top:20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>