<template>
    <div
        :class="['input_main', type]"
        :style="
            imgSrc && {
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: `cover`,
                width: `100%`,
            }
        "
    >
        <div class="upload_icon" v-if="!imgSrc && view" @click="upload">
            <GI name="global_upload" ws="24" hs="24" class="pointer"></GI>
            <div class="main_title">{{ langJson('Upload file') }}</div>
            <div class="main_tip">{{ tip ? tip : langJson('(Acceptable file format: JPEG, JPG, PNG.)') }}</div>
            <div v-if="msg" class="main_tip">{{ msg }}</div>
        </div>
        <div class="custom" @click="upload" v-show="!imgSrc">
            <slot></slot>
        </div>
        <div class="mask" v-show="loading">
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
        </div>
        <div class="mask replay cursor" v-show="imgSrc">
            <div class="icon_item replace" @click="reUpload">
                <GI name="global_upload" ws="24" hs="24"></GI>
            </div>
            <div class="icon_item edit" @click="editImage">
                <GI name="global_edit" ws="24" hs="24"></GI>
            </div>
        </div>
        <input @change="uploadFile" :id="components_id" class="file_real" type="file" name="myfile" />

        <Editor v-if="imgSrc" v-model="showEditor" @update="updateImage" :url="imgSrc"></Editor>
    </div>
</template>
<script>
import api from '@/api/config'
export default {
    data() {
        return {
            showEditor: false,
            loading: false,
            components_id: '',
            imgSrc: '',
            support: ['png', 'jpeg', 'jpg', 'gif', 'svg'],
        }
    },
    inject: ['regCode'],
    props: {
        fileUrl: {
            type: String,
            default: '',
        },
        tip: {
            type: String,
            default: '',
        },
        msg: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'small',
        },
        view: {
            type: Boolean,
            default: true,
        },
        size: {
            type: [Number, String],
            default: 5,
        },
    },
    model: {
        prop: 'fileUrl',
        event: 'change',
    },
    computed: {
        isImage() {
            let tempArr = this.typeNow.split('/')
            let last = tempArr.length - 1
            return this.support_image_extensions.some((item) => {
                return item.toLowerCase() == tempArr[last].toLowerCase()
            })
        },
    },
    watch: {
        fileUrl() {
            this.imgSrc = this.fileUrl
        },
    },
    created() {
        let code = this.regCode()
        this.components_id = 'upload_' + code
        this.imgSrc = this.fileUrl
    },
    methods: {
        editImage() {
            this.showEditor = !this.showEditor
        },
        updateImage(url) {
            this.imgSrc = url
            this.$emit('change', url)
        },
        reUpload() {
            this.imgSrc = ''
            this.upload()
        },
        upload() {
            document.getElementById(this.components_id).click()
        },
        async uploadFile() {
            this.$store.commit('cleanTips', [])
            this.loading = true
            try {
                let { dispatch } = this.$store
                let fileSource = document.getElementById(this.components_id).files[0]

                var typelimit = this.support.some((item) => {
                    return fileSource.type.indexOf(item) != -1
                })

                if (!typelimit) {
                    this.pushMsg('error', this.langJson('Uploading this file is not supported ') + `(only support ${this.support.join(',')})`)
                    this.loading = false
                    return
                }
                const sizelimit = fileSource.size / 1024 / 1024 <= this.size

                // 限制上传大小
                if (!sizelimit) {
                    this.pushMsg('error', this.langJson(`${this.langJson('The size of the uploaded file cannot exceed')} ${this.size}M!`))
                    document.getElementById(this.components_id).value = ''
                    this.loading = false
                    return
                }

                // if (fileSource.size >= 10485760) {
                //     this.pushMsg('error', this.langJson('Upload pictures cannot be exceeded 10MB'))
                //     document.getElementById(this.components_id).value = ''
                //     this.loading = false
                //     return
                // }

                this.$emit('source', fileSource)
                let result = await dispatch('uploadFile', fileSource)
                let success = result && result.code == 200
                if (success) {
                    document.getElementById(this.components_id).value = ''
                    this.imgSrc = result.data.url
                    this.$emit('change', result.data.url)
                } else {
                    if (result.code == 403) {
                    }
                }
            } catch (err) {
                console.log(err)
            }
            this.loading = false
        },
    },
}
</script>
<style lang="scss" scoped>
.input_main {
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-radius: 32px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    position: relative;
    transition: all 1s;
    height: 100%;
    border-radius: 19px;
    .mask {
        .icon_item {
            margin: 0 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--color_white);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 19px;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .replay {
        opacity: 0;
    }
    .replay:hover {
        opacity: 1;
    }
    .upload_icon {
        .main_tip {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font_modif_color);
            margin-top: 4px;
        }
        .main_title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
            margin-top: 8px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .custom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.small {
    background-image: url(@/assets/image/global_upload_border.png);
    height: 220px;
    max-width: 403px;
    width: 100%;
}
.big {
    /* border:1px dashed #ffffff; */
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 220px;
    width: 100%;
}

.nft_mint {
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 240px;
    width: 500px;
}
.file_real {
    display: none;
}
@media screen and (max-width: 1080px) and (min-width: 485px) {
    .small {
        max-width: none;
    }
}
</style>
