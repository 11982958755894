var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"image_out",style:({
            height: `${_vm.hs}px`,
            width: `${_vm.ws}px`,
        })},[(_vm.item.isPrivate == 1)?_c('div',{staticClass:"item_privacy"},[_c('GI',{attrs:{"name":"brand_privacy_white","ws":"20","hs":"20"}})],1):_vm._e(),_c('div',{staticClass:"item_image",style:({
                backgroundImage: `url(${_vm.item.banner})`,
            }),on:{"click":function($event){return _vm.jump_participant_compaign_preview({ id: _vm.item.id })}}})]),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"item_title"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"item_belong",on:{"click":function($event){return _vm.jump_participant_campaign_participants_profileColl({ idUser: _vm.item.brandUserId })}}},[_c('div',{staticClass:"belong_image",style:({
                    backgroundImage: `url(${_vm.brandUserVo.imageUrl})`,
                })}),_c('div',{staticClass:"belong_name"},[_vm._v(_vm._s(_vm.brandUserVo.nickName))])]),_c('div',{staticClass:"item_desc"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]),(!_vm.isPhone)?_c('VI',{attrs:{"participants":_vm.item.joinNum,"voters":_vm.item.voteNum,"days":_vm.item.endTime}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }