<template>
    <Model v-model="modelShow" @change="close" :showHeader="false">
        <div class="model_main" slot="main">
            <div class="row">{{ langJson('Would you like to load the saved draft?') }}</div>

            <div class="model_btns">
                <div class="model_btn">
                    <BTN class="btn_item" type="detail" @handle="close">{{ langJson('Cancel') }}</BTN>
                </div>
                <div class="btn_grap"></div>
                <div class="model_btn">
                    <BTN class="btn_item" type="active" @handle="confirm">{{ langJson('Confirm') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>
<script>
let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    data() {
        return {
            modelShow: false,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    created() {
        this.modelShow = this.show
    },
    watch: {
        modelShow(n) {
            this.$emit('change', n)
        },
        show(n) {
            this.modelShow = n
        },
    },
    computed: {},
    methods: {
        confirm() {
            // detail
            this.$emit('confirm')
            this.close()
        },
        homepage() {
            this.jump_participant_compaign()
            this.close()
        },
        failed() {
            this.close()
            this.pushMsg('error', this.langJson('Extract failed'))
        },
        close() {
            this.$emit('change', false)

            // 完成后刷新列表
            this.$emit('completeExtract')
        },
        next() {
            this.$refs.step.next()
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .model_btns {
        .btn_grap {
            width: 20px;
        }
        .model_btn {
            flex: 1;
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .load_icon {
        margin-bottom: 20px;
    }
    .row {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_deep_color);
        width: 100%;
        text-align: center;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // height: 367px;
    padding-top:30px;

    box-sizing: border-box;
    width: 558px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
}
</style>
