import api from '@/api/help'
import tools from '@/mixin/tools'
const userModule = {
    state: {
        // globalHelp
        articleType: [],
        getArticleDetail: [],
    },
    getters: {},
    mutations: {
        articleType(state, articleType) {
            state.articleType = articleType
        },
        getArticleDetail(state, getArticleDetail) {
            state.getArticleDetail = getArticleDetail
        },
    },
    actions: {
        // 文章列表
        async articleType(context, params = {}) {
            let result = await api.articleType(params)
            result = this.apiResult(result)
            context.commit('articleType', result.data)
            return result
        },
        // 文章详情
        async getArticleDetail(context, params = {}) {
            let result = await api.getArticleDetail(params)
            result = this.apiResult(result)
            context.commit('getArticleDetail', result.data)
            return result
        },
    },
}
export default userModule
