import api from '@/api/user'
import tools from '@/mixin/tools'
const userModule = {
    state: {
        // 用户令牌
        token: '',
        userInfo: {},
        infoByUserId: {},
        showLoginStatus:false
    },
    getters: {},
    mutations: {
        token(state, token) {
            tools.setCookie('token', token)
            state.token = token
        },
        userInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        infoByUserId(state, infoByUserId) {
            state.infoByUserId = infoByUserId
        }
    },
    actions: {
        async infoByUserId(context, params = {}) {
            let result = await api.infoByUserId(params)
            result = this.apiResult(result)
            if (result && result.data) {
                context.commit('infoByUserId', result.data)
            }
            return result
        },
        // 获取支持的币种
        async userGet(context) {
            let result = await api.info()
            result = this.apiResult(result)
            if (result && result.data) {
                context.commit('userInfo', result.data)
            }
            return result
        },

        // 注册
        async signup(context, params = {}) {
            let result = await api.signup(params)
            result = this.apiResult(result)
            return result
        },

        // 发送邮箱
        async sendEmailCode(context, params = {}) {
            let result = await api.sendEmailCode(params)
            result = this.apiResult(result)
            return result
        },
        // 验证邮箱验证码
        async verifyCode(context, params = {}) {
            let result = await api.verifyCode(params)
            result = this.apiResult(result)
            return result
        },
        // 重置密码
        async resetPwd(context, params = {}) {
            let result = await api.pwdReset(params)
            result = this.apiResult(result)
            return result
        },
        // 登录
        async login(context, params = {}) {
            let result = await api.login(params)
            result = this.apiResult(result)
            if (result && result.data && result.data.token) {
                context.commit('token', result.data.token)
            }
            return result
        },

        // 修改用户信息
        async updateInfo(context, params = {}) {
            let result = await api.updateInfo(params)
            result = this.apiResult(result)
            return result
        },

        // 修改用户密码
        async updatePwd(context, params = {}) {
            let result = await api.updatePwd(params)
            result = this.apiResult(result)
            return result
        },
        // 修改用户密码
        async userCheckSignInfo(context, params = {}) {
            let result = await api.userCheckSignInfo(params)
            result = this.apiResult(result)
            return result
        },
        setShowLoginStatus(context, params = false){
            context.state.showLoginStatus = params
        }
    },
}
export default userModule
