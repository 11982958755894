<template>
    <div class=" ">
        <div class="navHeight"></div>
        <div class="prewNav">
            <div class="start_nav">
                <div class="nav_left">
                    <div v-if="isPhone" class="menu" @click="openMenu">
                        <GI name="index_menu" :ws="24"></GI>
                    </div>
                    <div class="logo cursor" @click="jump_root()" :style="logoStyle"></div>
                </div>
                <div class="nav_right">

                    <div class="nav_list">
                        <div v-for="(item,index) in navlist" :key="index" @click="item.path" class="nav_item">
                            {{ item.title }}
                        </div>
                    </div>
                    <BTN v-if="isCampaign" class="btn_item" @handle="selectLogin" type="active" bw="164" bh="52">
                        {{ langJson('Log in') }}
                    </BTN>
                </div>
            </div>
        </div>
        <Model v-model="monitorShowSign" :borderRadius="isPhone ? 40 : 24" :padding="isPhone ? '24px 32px' : '32px'" :showHeader="false" @mask="closeSign(false)">
            <Signup slot="main" @closeSign="closeSign"></Signup>
        </Model>
        <Model v-model="showLog" @mask="closeLogin(false)" :borderRadius="isPhone ? 40 : 24" :padding="isPhone ? '24px 32px' : '32px'" :showHeader="false">
            <Login slot="main" @closeLogin="closeLogin"></Login>
        </Model>
    </div>
</template>
<script>
import { setCookie, getCookie } from '@/assets/js/cookie'
export default {
    data() {
        return {
            // showSign: false,
            showLog: false,
            path: ""
        }
    },
    inject: ['showMenu'],
    computed: {
        navlist() {
            let result = [
                {
                    icon: '/source/svg/menu_1.svg',
                    title: this.langJson('What are NFTs'),
                    path: () => {
                        this.jump_page_path('/startup/article/whatNft')
                        // this.jump_page_path('/startup/index')
                    },
                },
                {
                    icon: '/source/svg/menu_2.svg',
                    title: this.langJson('Use cases of NFT'),
                    path: () => {
                        this.jump_page_path('/startup/article/unleash')
                    },
                },
                {
                    icon: '/source/svg/menu_3.svg',
                    title: this.langJson('About Us'),
                    path: () => {
                        this.jump_page_path('/startup/article/graphic')
                    },
                },
                // {
                //     icon: '/source/svg/menu_4.svg',
                //     title: this.langJson('Term & Conditions'),
                //     path: () => {
                //         this.jump_article_detail({ articleId: 5 })
                //     },
                // },
                {
                    icon: '/source/svg/menu_5.svg',
                    title: this.langJson('FAQs'),
                    path: () => {
                        this.jump_page_path('/startup/article/faq')
                    },
                }
            ]

            if(this.isCampaign) {
                result.push({
                    icon: '/source/svg/menu_5.svg',
                    title: this.langJson('Sign Up'),
                    path: () => {
                        this.selectRegister()
                    },
                })
            }
            return result
        },
        logoStyle() {
            try {
                let { webLogo, webDarknessLogo } = this.globalImage
                if (this.globalTheme != 'light') {
                    webLogo = webDarknessLogo
                }
                return { backgroundImage: `url(${webLogo})`, width: this.isPhone ? `44px` : `96px`, borderRadius: `50%`, height: this.isPhone ? `44px` : `96px` }
            } catch (error) {
                return {}
            }
        },
        monitorShowSign() {
            return this.$store.state.user.showLoginStatus
        },
        isCampaign() {
            return this.path != "/participant/campaign/preview"
        }
    },
    watch: {
        "$route": {
            handler(val) {
                this.path = location.pathname
            },
            deep:true
        }
    },
    created() {
        this.path = location.pathname

    },
    mounted() {
        
    },
    methods: {
        openMenu(open = true) {
            this.showMenu(open)
        },
        async selectRegister() {
            // this.showSign = true
            let { dispatch } = this.$store
            await dispatch('setShowLoginStatus', true)
        },
        selectLogin() {
            this.showLog = true
        },
        async closeLogin(val) {
            this.showLog = val
        },
        async closeSign(val) {
            // this.showSign = val
            let { dispatch } = this.$store
            await dispatch('setShowLoginStatus', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.type_main {
    .btns {
        .type_btn {
            margin-top: 16px;
        }

        margin-top: 16px;
    }

    .type_title {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_deep_color);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    padding: 32px 60px;
    width: 360px;
    height: auto;
    box-sizing: border-box;
    background: var(--nft_dialog_bg);
    border-radius: 24px;
}

.navHeight {
    height: 128px;
}

.prewNav {
    width: 100vw;
    background-color: var(--bg_color);
    /* background-color: rgba(250, 250, 250, 0.9); */
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20221027;

    .start_nav {
        .nav_right {
            .nav_list{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                column-gap: 24px;
                .nav_item {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    // font-family: Cabinet Grotesk;
                    font-size: 18px;
                    // font-style: normal;
                    // font-weight: bold;
                    line-height: 28px;
                    padding:12px;
                    cursor: pointer;
                    color:var(--font_deep_color);
                }
            }
            .btn_item {
                margin-left: 24px;
            }

            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .nav_left {
            .logo {
                height: 64px;
                width: 64px;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
            }

            .menu {
                cursor: pointer;
                margin-right: 40px;
            }

            display: flex;
            align-items: center;
        }

        margin: 0 auto;
        max-width: var(--max_width);
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 128px;
    }
}

@media screen and (max-width: 1081px) {
    .menu {
        img {
            margin-right: -4px;
        }
    }
    .navHeight {
        height: 52px;
    }

    .prewNav {
        background-color: var(--bg_color2);
        .start_nav {
            min-height: 56px;

            .nav_left {
                flex-direction: row-reverse;
                flex: 1;
                justify-content: space-between;
                padding-left: 0px;
                padding-right: 0px;

                .logo {
                    width: 44px;
                    height: 44px;
                }

                .menu {
                    margin-right: 0px;
                }
            }

            .nav_right {
                display: none !important;
            }
        }
    }
}
</style>
