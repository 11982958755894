import { render, staticRenderFns } from "./PushProperties.vue?vue&type=template&id=0a6270bc&scoped=true"
import script from "./PushProperties.vue?vue&type=script&lang=js"
export * from "./PushProperties.vue?vue&type=script&lang=js"
import style0 from "./PushProperties.vue?vue&type=style&index=0&id=0a6270bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6270bc",
  null
  
)

export default component.exports