<template>
    <div class="start_up">
        <!-- <StartHead ref="StartHead"></StartHead> -->
        <div v-if="!isPhone" class="start_banner" :style="{ backgroundImage: `url(/source/png/index_banner_light.png)` }">
            <div class="banner_center">
                <div class="banner_text">
                    <div class="banner_title">{{ langJson('Design, mint, and share NFTs') }}</div>
                    <div class="banner_title_small">{{ langJson('In 5 easy steps:') }}</div>

                    <div class="step_main">
                        <div v-for="(item, index) in steplist" :key="index" class="banner_step">
                            <GI :name="`index_step_${index}`" ws="32" hs="32"></GI>
                            <div class="step_item">
                                <div class="item_top">{{ item.title }}</div>
                                <div v-if="item.desc" class="item_bottom">{{ item.desc }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="star" :style="{ backgroundImage: `url(/source/png/startup_star_1.png)` }"></div>
                    <div class="cycle" :style="{ backgroundImage: `url(/source/png/startup_cycle_1.png)` }"></div>
                    <BTN class="btn_item" type="active" bw="224" :bh="isPhone ? 52 : 60" @handle="jumpTo('join_us')">
                        {{ langJson('Get started') }}
                    </BTN>
                </div>
                <div class="banner_image" :style="{ backgroundImage: `url(/source/png/startup_banner_right.png)` }"></div>
            </div>
        </div>

        <div v-if="isPhone" class="mobile">
            <div class="banner_text">
                <div class="banner_title">{{ langJson('Design, mint, and share NFTs') }}</div>
                <div class="banner_title_small">{{ langJson('In 5 easy steps:') }}</div>

                <div class="step_main">
                    <div v-for="(item, index) in steplist" :key="index" class="banner_step">
                        <GI :name="`index_step_${index}`" ws="32" hs="32"></GI>
                        <div class="step_item">
                            <div class="item_top">{{ item.title }}</div>
                            <div v-if="item.desc" class="item_bottom">{{ item.desc }}</div>
                        </div>
                    </div>
                </div>

                <div class="star" :style="{ backgroundImage: `url(/source/png/startup_star_1.png)` }"></div>
                <div class="cycle" :style="{ backgroundImage: `url(/source/png/startup_cycle_1.png)` }"></div>
            </div>
            <BTN class="btn_item" type="active" bw="255" :bh="isPhone ? 52 : 60" @handle="jumpTo('join_us')">
                {{ langJson('Get started') }}
            </BTN>
        </div>

        <div v-if="isPhone" class="mobile mt35">
            <img class="mobile_image" src="/source/png/startup_banner_two.png" alt="" srcset="" />
        </div>
        <div v-if="isPhone" class="mobile mt35">
            <div class="title_one">
                {{ langJson("Why NFTWerks?") }}
                <div class="star" :style="{ backgroundImage: `url(/source/png/startup_star_one.png)` }"></div>
                <div class="cycle" :style="{ backgroundImage: `url(/source/png/startup_cycle_one.png)` }"></div>
            </div>
            <img class="mobile_image" src="/source/png/startup_banner_three.png" alt="" srcset="" />
        </div>

        <div v-if="!isPhone" class="start_article">
            <div class="article_why" :style="{ backgroundImage: `url(/source/png/index_article_title.png)` }">
                {{ langJson('Why NFTWerks?') }}
            </div>
            <div class="article_list">
                <div class="article_item" v-for="(item, index) of articlelist" :key="index" :style="{ backgroundImage: `url(${item.border})` }">
                    <div class="article_preview" :style="{ backgroundImage: `url(${item.image})` }"></div>
                    <div class="article_title">{{ item.title }}</div>
                    <div class="article_desc">{{ item.desc }}</div>
                </div>
            </div>
        </div>

        <div id="join_us" class="start_join">
            <div v-if="!isPhone" class="article_why" :style="{ backgroundImage: `url(/source/png/index_article_title.png)` }">
                {{ langJson('Get started with NFTWerks') }}
            </div>
            <div v-if="isPhone" class="article_why" :style="{ backgroundImage: `url(/source/png/startup_cycle_mobile.png)` }">
                {{ langJson('Get started with NFTWerks') }}
            </div>
            <div class="join_list">
                <div class="join_item" v-for="(item, index) of joinlist" :key="index">
                    <div class="join_preview" :style="{ backgroundImage: `url(${item.image})` }"></div>
                    <div class="join_title">{{ item.title }}</div>
                    <div class="join_desc">{{ item.desc }}</div>
                    <BTN class="btn_item" type="active" bw="224" :bh="isPhone ? 52 : 60" @handle="item.handle">
                        {{ item.title }}
                    </BTN>
                </div>
            </div>
        </div>

        <div class="start_grap"></div>

        <div class="start_bottom">
            <div
                class="bottom_home"
                :style="{
                    backgroundImage: `url(/source/png/index_home.png)`,
                }"
            ></div>
            <div v-if="!isPhone" class="shadow"></div>
            <div class="bottom_content">
                <div class="bottom_title" :style="{ backgroundImage: `url(/source/png/bottom_title_bgc.png)` }">
                    {{ langJson('Create campaign') }}
                </div>
                <div class="bottom_desc">{{ langJson('In 7 simple steps you can design a campaign for your brand or to capture fun moments from your private party or event.') }}</div>
                <div class="bottom_desc">{{ langJson('Turn every phone and person into a creative artist for you! Capture the spontaneity, creativity, and fun for posterity.') }}</div>
                <!-- <div class="bottom_desc">{{ langJson('Participation in campaigns is simple, and free for your audience.') }}</div> -->
                <BTN class="btn_item" type="active" bw="286" :bh="isPhone ? 52 : 60" @handle="goToRegister">{{ langJson('Let’s get started !') }}</BTN>
            </div>
        </div>
    </div>
</template>
<script>
import { getCookie } from '@/assets/js/cookie'
export default {
    computed: {
        steplist() {
            return [
                {
                    title: this.langJson('Create an account or log in'),
                    desc: '',
                },
                {
                    title: this.langJson('Choose what to do'),
                    desc: this.langJson('Participate in a campaign or mint your own NFT'),
                },
                {
                    title: this.langJson('Design your NFT'),
                    desc: this.langJson('Import an image, take a picture, or edit'),
                },
                {
                    title: this.langJson('Connect your Web3 wallet'),
                    desc: '',
                },
                {
                    title: this.langJson('Mint NFT to your wallet'),
                    desc: '',
                },
            ]
        },
        joinlist() {
            return [
                {
                    image: '/source/png/startup_join_1.png',
                    title: this.langJson('Join as an Individual'),
                    desc: this.langJson("Get in on the action! Dive into a variety of unique NFT campaigns or mint your own NFTs by joining us as an individual. Campaigns are free and simple to enter. Don't miss out on the excitement—join us today!"),
                    handle: () => {
                        this.goParticipant()
                    },
                },
                {
                    image: '/source/png/startup_join_2.png',
                    title: this.langJson('Join as a Brand'),
                    desc: this.langJson('Unlock the power of NFTs for your brand! Launch your very own campaign to engage your audience & strengthen your reach within your industry. You can share your campaign with your audience where participation is simple and free!'),
                    handle: () => {
                        this.goBrandSignup()
                    },
                },
            ]
        },
        articlelist() {
            return [
                {
                    image: '/source/png/nft1.png',
                    border: '/source/png/index_border_1.png',
                    title: this.langJson('Express'),
                    desc: this.langJson('your creativity'),
                },
                {
                    image: '/source/png/nft2.png',
                    border: '/source/png/index_border_2.png',
                    title: this.langJson('5 easy steps'),
                    desc: this.langJson('to your NFT'),
                },
                {
                    image: '/source/png/nft3.png',
                    border: '/source/png/index_border_3.png',
                    title: this.langJson('Free-to-use'),
                    desc: this.langJson('platform'),
                },
            ]
        },
    },
    methods: {
        goParticipant() {
            this.jump_participant_signup()
        },
        goBrandSignup() {
            this.jump_brand_signup()
        },
        async goToRegister() {
            if (getCookie('token')) {
                let type = localStorage.getItem('userType')
                if (type == 1) {
                    // 商户
                    // 跳转到活动页面
                    this.jump_page_path('/brand/campaign/index')
                } else {
                    // 用户
                    this.jump_page_path('/participant/campaign/index')
                }

                return
            }
            let { dispatch } = this.$store
            await dispatch('setShowLoginStatus', true)
        },
    },
}
</script>
<style lang="scss" scoped>
.start_up {
    .mobile {
        .title_one{
            .star {
                position: absolute;
                top: -30px;
                left: 85px;
                width: 36px;
                height: 36px;
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .cycle {
                position: absolute;
                top: 0px;
                left: 20px;
                width: 100%;
                height: 48px;
                background-position: left top;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            position: relative;
            font-family: Cabinet Grotesk;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 48px;
            text-align: center;
            color:var(--font_deep_color);
            margin-bottom:24px;
        }
        .banner_text {
                .step_main {
                    .banner_step {
                        .step_item {
                            .item_top {
                                font-family: Lato;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px;
                                color: var(--font_deep_color);
                            }
                            .item_bottom {
                                font-family: Lato;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                color: var(--font_color);
                            }
                        }

                        display: flex;
                        align-items: center;

                        column-gap: 16px;
                    }
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    row-gap: 16px;
                }
                .banner_title_small {
                    font-family: Cabinet Grotesk;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    color: var(--font_color);
                    margin-top: 22px;
                    margin-bottom: 22px;
                }
                .banner_title {
                    text-align: center;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Cabinet Grotesk;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 38px;
                    color: var(--font_deep_color);
                }
                width: 100%;
                padding: 20px;
                margin-top:30px;
                box-sizing: border-box;
                // height: 444px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: relative;
                .star {
                    position: absolute;
                    top: 0px;
                    left: 243px;
                    width: 44px;
                    height: 44px;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .cycle {
                    position: absolute;
                    top: 0px;
                    left: 20px;
                    width: 312.085px;
                    height: 96.366px;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .btn_item {
                    position: absolute;
                    bottom: -96px;
                    left: 0px;
                }
        }
        
        .mobile_image {
            width: 100%;
        }
        .btn_item {
            width: 255px;
            margin: auto;
        }
        width: 100%;
    }
    .mt35 {
        margin-top: 35px;
    }
    .start_bottom {
        .bottom_content {
            .btn_item {
                margin-top: 32px;
            }
            .bottom_title {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 56px;
                line-height: 64px;
                color: var(--font_deep_color);
                background-position: 120px 0px;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            .bottom_desc {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
                margin-top: 8px;
            }

            width: 515px;
            padding-top: 175px;
            margin-left: 845px;
        }
        .bottom_home {
            position: absolute;
            top: 0px;
            left: -180px;
            width: 960px;
            height: 520px;
            background-position: top right;
            background-repeat: no-repeat;
            background-size: auto 100%;
        }
        .shadow {
            width: 153.67px;
            height: 153.67px;
            background: linear-gradient(0deg, var(--main_color), var(--main_color));
            filter: blur(150px);
            transform: matrix(-0.55, -0.83, -0.83, 0.55, 0, 0);
            position: absolute;
            top: 350px;
            left: 750px;
            pointer-events: none;
        }

        position: relative;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-bottom: 88px;
        height: 576px;
    }
    .start_grap {
        height: 76px;
        width: 100%;
    }
    .start_join {
        .join_list {
            .join_item {
                .join_desc {
                    font-family: Lato;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    color: var(--font_color);
                    // text-align: center;
                }
                .join_title {
                    font-family: Cabinet Grotesk;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    color: var(--font_deep_color);
                    // margin-top: 20px;
                    // text-align: center;
                }
                .join_preview {
                    max-width: 400px;
                    width: 100%;
                    height: 200px;
                    border-radius: 20px;
                    background: var(--main_color);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    text-align: center;
                }
                max-width: 448px;
                width: 100%;
                // height: 450px;
                // background: rgba(255, 255, 255, 0.13);
                // backdrop-filter: blur(2.5px);
                // border-radius: 32px;
                box-sizing: border-box;
                // padding: 17px;
                // background-position: center center;
                // background-repeat: no-repeat;
                // background-size: 100% 100%;

                display: flex;
                padding: 24px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 24px;
                border-radius: 24px;
                background: var(--white_color);
                box-shadow: 9px 7px 47px 0px rgba(0, 0, 0, 0.05);
                backdrop-filter: blur(5.997468948364258px);
            }
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            column-gap: 64px;
            row-gap: 48px;
        }
        .article_why {
            // width: 469.24px;
            height: 141.42px;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 32px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 64px;
            color: var(--font_deep_color);
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 200px;
    }
    .start_article {
        .article_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 48px;
            margin-bottom: 56px;
        }
        .article_list {
            .article_item {
                .article_desc {
                    font-family: Cabinet Grotesk;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 36px;
                    color: var(--font_color);
                    text-align: center;
                }
                .article_title {
                    font-family: 'Cabinet Grotesk';
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 36px;
                    color: var(--font_deep_color);
                    margin-top: 20px;
                    text-align: center;
                }
                .article_preview {
                    height: 313px;
                    width: 100%;
                    border-radius: 20px;
                    background: var(--main_color);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    text-align: center;
                }
                width: 347px;
                height: 450px;
                background: rgba(255, 255, 255, 0.13);
                backdrop-filter: blur(2.5px);
                border-radius: 32px;
                box-sizing: border-box;
                padding: 17px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .article_why {
            width: 469.24px;
            height: 141.42px;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 32px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 64px;
            color: var(--font_deep_color);
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
    }
    .start_banner {
        .banner_center {
            .banner_text {
                .step_main {
                    .banner_step {
                        .step_item {
                            .item_top {
                                font-family: Lato;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px;
                                color: var(--font_deep_color);
                            }
                            .item_bottom {
                                font-family: Lato;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                color: var(--font_color);
                            }
                        }

                        display: flex;
                        align-items: center;

                        column-gap: 16px;
                    }
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    row-gap: 16px;
                }
                .banner_title_small {
                    font-family: Cabinet Grotesk;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    color: var(--font_color);
                    margin-top: 22px;
                    margin-bottom: 22px;
                }
                .banner_title {
                    width: 420px;
                    font-family: Cabinet Grotesk;
                    font-size: 56px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 60px;
                    color: var(--font_deep_color);
                }
                width: 592px;
                height: 444px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: relative;
                .star {
                    position: absolute;
                    top: 0px;
                    left: 343px;
                    width: 44px;
                    height: 44px;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .cycle {
                    position: absolute;
                    top: 0px;
                    left: 138px;
                    width: 312.085px;
                    height: 96.366px;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .btn_item {
                    position: absolute;
                    bottom: -96px;
                    left: 0px;
                }
            }
            .banner_image {
                width: 665px;
                height: 645px;

                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            margin: 0 auto;
            max-width: var(--max_width);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        height: 800px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: relative;
    }
}
/* 1.249  宽高比*/
@media only screen and (max-width: 1080px) {
    .start_up {
        .start_join {
            .article_why {
                text-align: center;
                font-family: Cabinet Grotesk;
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 38px;
                height: 76px;
                background-position: 200px 0px;
                background-size: auto 76px;
            }
            .join_list {
                .join_item {
                    .btn_item {
                        width: 100% !important;
                    }
                }
            }
            margin-top: 0;
        }
    }
    .start_banner {
        position: static;
        height: auto !important;
        background-position: center 70% !important;
        .banner_content {
            position: static !important;
            margin: auto;
            width: 100% !important;
            .banner_title {
                font-size: 32px !important;
                text-align: center;
                height: 64px !important;
                background-position: center center !important;
                justify-content: center;
            }
            .banner_desc {
                /* text-align: center; */
                padding-left: 20px;
                padding-right: 20px;
            }
            .btn_item {
                margin-top: 32px;
                margin-left: 50%;
                transform: translateX(-50%);
            }
        }
        .banner_image {
            position: static !important;
            width: 82vw !important;
            height: calc(82vw / 1.249) !important;
            height: calc(82vw / 1.249) !important;
            margin: auto;
            margin-top: 28px;
        }
    }
    .start_article_mobile {
        height: 82vw;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        .article_why {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
    }
    .start_bottom {
        position: static !important;
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
        height: auto !important;
        .bottom_home {
            position: static !important;
            height: 63.52vw !important;
            width: 89.33vw !important;
            background-position: center center !important;
        }
        .bottom_content {
            margin-left: 50% !important;
            transform: translateX(-50%);
            width: 100% !important;
            padding-top: 0px !important;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .bottom_title {
                font-size: 32px !important;
                line-height: 48px !important;
                text-align: center;
                width: 260px;
            }
            .bottom_desc {
                text-align: center;
            }
            .btn_item {
            }
        }
    }
}
</style>
