<template>
    <div class="item" @click="$emit('click')">
        <div class="brand_detail">
            <CheckBox @click.native="$emit('select')" :active="selected"></CheckBox>
        </div>

        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image cursor" :style="{ backgroundImage: `url(${item.artworkImg})` }" @click="jump_brand_compaign_artwork_detail({ idArt: item.id, idActive: $route.query.idActive, idUser: item.userId })"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: [Object],
            default: () => {
                return {}
            },
        },
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        selectlist: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            active: false,
        }
    },
    computed: {
        selected() {
            return this.selectlist.some((item) => {
                return item.id == this.item.id
            })
        },
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    gap: 16px;

    .image_out {
        border-radius: 12px;
        overflow: hidden;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
</style>
