<template>
    <div class="personal">
        <Go :prev="langJson('Back')" @prev="back"></Go>
        <div class="personal_center">
            <div class="personal_banner">
                <div
                    class="banner"
                    :style="{
                        backgroundImage: `url(${infoByUserId.bannerUrl})`,
                    }"
                ></div>
            </div>
            <div class="personal_portrait">
                <div
                    class="portrait"
                    :style="{
                        backgroundImage: `url(${infoByUserId.imageUrl})`,
                    }"
                ></div>
            </div>

            <div class="personal_name">{{ infoByUserId.nickName }}</div>
            <div class="personal_detail">
                <div class="personal_address" v-if="infoByUserId?.address" @click="copy(infoByUserId.address)">
                    {{ infoByUserId.address }}
                    <GI class="copy" name="personal_copy" ws="24" hs="24"></GI>
                </div>
                <div class="personal_email">{{ infoByUserId.email }}</div>
            </div>

            <div class="personal_desc">{{ infoByUserId.bio }}</div>

            <div class="personal_main">
                <Tab type="line" align="center" v-model="tab" :list="tablist" @change="switchTab"></Tab>
                <StepRun ref="tab">
                    <template slot="tab1">
                        <CreatedNFTs :list="listDataNft" @change="changeNft"></CreatedNFTs>
                        <NuData height="300" v-if="!lenArray(listDataNft)" />
                        <div class="art_more">
                            <Page v-model="pageNumNft" :all="totalNft" :size="pageSizeNft" :isBtn="true" @change="init2"></Page>
                        </div>
                    </template>

                    <template slot="tab2">
                        <Arts slot="tab2" :list="listDataArt" @change="changeArt"></Arts>
                        <NuData height="300" v-if="!lenArray(listDataArt)" />
                        <div class="art_more">
                            <Page v-model="pageNumArt" :all="totalArt" :size="pageSizeArt" :isBtn="true" @change="init3"></Page>
                        </div>
                    </template>

                    <template slot="tab3">
                        <JoinedCompaigns slot="tab3" :list="listDataActive" @change="changeActive"></JoinedCompaigns>
                        <NuData height="300" v-if="!lenArray(listDataActive)" />
                        <div class="art_more">
                            <Page v-model="pageNumActive" :all="totalActive" :size="pageSizeActive" :isBtn="true" @change="init4"></Page>
                        </div>
                    </template>
                </StepRun>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab: 1,
            tablist: [
                {
                    value: 1,
                    lable: this.langJson('Created NFTs'),
                    icon: 'profile_nft_deactive',
                    iconActive: 'profile_nft_active',
                },
                {
                    value: 2,
                    lable: this.langJson('Artworks'),
                    icon: 'profile_gallery_deactive',
                    iconActive: 'profile_gallery_active',
                },
                {
                    value: 3,
                    lable: this.langJson('Joined campaigns'),
                    icon: 'profile_folder_deactive',
                    iconActive: 'profile_folder_active',
                },
            ],
            listDataNft: [],
            listDataArt: [],
            listDataActive: [],

            pageSizeNft: 24,
            pageNumNft: 1,
            totalNft: 0,

            pageSizeArt: 24,
            pageNumArt: 1,
            totalArt: 0,

            pageSizeActive: 24,
            pageNumActive: 1,
            totalActive: 0,
            source: {},
        }
    },
    async created() {
        await this.init()
        await this.init2()
        await this.init3()
        await this.init4()
    },
    mounted() {
        if (this.$route.query.tab) {
            this.tab = parseInt(this.$route.query.tab)
            this.switchTab(this.tab)
        }
    },
    methods: {
        // 用户 信息
        async init() {
            let { dispatch } = this.$store
            let { userId } = this.$route.query
            await dispatch('infoByUserId', { userId })
        },
        switchTab(val) {
            this.tablist.find((item, index) => {
                if (item.value == val) {
                    this.$refs.tab.jump(index)
                    this.source = {}
                    return true
                } else {
                    return false
                }
            })
        },
        // 用户 nft
        async init2() {
            try {
                let { dispatch, commit } = this.$store
                let { userId } = this.$route.query
                // creator : 当前链接的钱包地址
                // userId,
                let res = await dispatch('nftProductList', Object.assign({ pageNum: this.pageNumNft, pageSize: this.pageSizeNft, userId }, this.source))
                this.totalNft = res.total
                let Arr = JSON.parse(JSON.stringify(res.rows))
                Arr.forEach((item) => {
                    item.operateStop = true
                })
                this.listDataNft = Arr
            } catch (error) {
                console.log(error)
            }
        },
        // 用户 艺术品
        async init3() {
            try {
                let { dispatch } = this.$store
                let { userId } = this.$route.query
                let res = await dispatch('userArtWorkList', Object.assign({ pageNum: this.pageNumArt, pageSize: this.pageSizeArt, userId }, this.source))
                this.totalArt = res.total
                let Arr = JSON.parse(JSON.stringify(res.rows))
                Arr.forEach((item) => {
                    item.operateStop = true
                })
                this.listDataArt = Arr
            } catch (error) {
                console.log(error)
            }
        },
        // 用户 参与的活动
        async init4() {
            try {
                let { dispatch } = this.$store
                let { userId } = this.$route.query
                let res = await dispatch('joinCampaignListUser', Object.assign({ pageNum: this.pageNumActive, pageSize: this.pageSizeActive, userId: userId * 1 }, this.source))
                this.totalActive = res.total
                let Arr = JSON.parse(JSON.stringify(res.rows))
                Arr.forEach((item) => {
                    item.operateStop = true
                })
                this.listDataActive = Arr
            } catch (error) {
                console.log(error)
            }
        },
        changeActive(source) {
            this.pageNumArt = 1
            this.source = source
            this.init4()
        },
        changeArt(source) {
            this.pageNumArt = 1
            this.source = source
            this.init3()
        },
        changeNft(source) {
            this.pageNumNft = 1
            this.source = source
            this.init2()
        },
    },
}
</script>
<style lang="scss" scoped>
.personal {
    .personal_center {
        .personal_main {
            margin-top: 32px;
            width: 100%;
            .art_more {
                margin-top: 36px;
                margin-bottom: 82px;
                width: 100%;
            }
        }

        .personal_desc {
            margin-top: 16px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--font_color);
            width: 100%;
            max-width: 624px;
            padding: 0 24px;
            box-sizing: border-box;
        }
        .personal_detail {
            .personal_email {
                margin-left: 40px;
            }
            .personal_address {
                margin-right: 40px;
                display: flex;
                align-items: center;
                .copy {
                    margin-left: 4px;
                    cursor: pointer;
                }
            }
            display: flex;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
            color: var(--font_color);
        }
        .personal_name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            margin-top: 16px;
        }
        .personal_portrait {
            .portrait {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-color: var(--color_white);
                position: absolute;
                bottom: 0;
                left: -60px;
            }
            height: 60px;
            position: relative;
        }
        .personal_banner {
            .banner {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                width: 100%;
                height: 100%;
                background-color: var(--color_white);
            }
            border-radius: 24px;
            overflow: hidden;
            height: 280px;
            width: 100%;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
