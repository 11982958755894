<template>
    <div class="main">
        <div class="info">
            <div class="theme_box">
                <Theme></Theme>
            </div>
            <div class="company_form">
                <div class="title">
                    <Logo></Logo>
                    <div>
                        <h2 style="text-align: center" class="company_form_title">{{ langJson('Company Information') }}</h2>
                        <p class="title_text">
                            {{ langJson('Finish creating the account for your company by providing the following information') }}
                        </p>
                    </div>
                </div>

                <div class="form">
                    <div class="form_box">
                        <h2 class="form_box_title">{{ langJson('Company information') }}</h2>
                        <div class="formItem">
                            <div class="form_input">
                                <FormItem v-model="form.name" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Company name')"></FormItem>
                                <FormItem v-model="form.address" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Company Address')"></FormItem>
                            </div>
                            <div class="form_input">
                                <FormItem v-model="form.country" class="information_item" type="select" :matchs="['unull']" :title="langJson('Country')" :list="countryList" :placeholder="langJson('Select Country')"></FormItem>

                                <FormItem v-model="form.zipcode" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Zip Code')"></FormItem>
                            </div>
                        </div>
                    </div>
                    <div class="form_box">
                        <h2 class="form_box_title">{{ langJson('Contact Information') }}</h2>
                        <div class="formItem">
                            <div class="form_input">
                                <FormItem v-model="contact1.name" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('First user name')"></FormItem>
                                <FormItem v-model="contact1.email" class="information_item" type="text" :matchs="['unull', 'email', 'maxLength']" :title="langJson('Email')"></FormItem>
                            </div>
                            <div class="form_input">
                                <FormItem v-model="contact1.phone" class="information_item" type="text" :title="langJson('Contact No')"></FormItem>
                                <FormItem v-model="contact1.desc" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Designation')"></FormItem>
                            </div>
                        </div>
                        <div class="bottom_checkbox">
                            <CheckBox v-model="contact1.isAgreed"></CheckBox>

                            <div class="checkbox_content" @click="handleChange1">{{ langJson('The main account manager') }}</div>
                        </div>
                    </div>
                    <div class="form_box">
                        <h2 class="form_box_title">{{ langJson('Contact Infomation') }}</h2>
                        <div class="formItem">
                            <div class="form_input">
                                <FormItem v-model="contact2.name" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Second user name')"></FormItem>
                                <FormItem v-model="contact2.email" class="information_item" type="text" :matchs="['unull', 'email', 'maxLength']" :title="langJson('Email')"></FormItem>
                            </div>
                            <div class="form_input">
                                <FormItem v-model="contact2.phone" class="information_item" type="text" :title="langJson('Contact No')"></FormItem>
                                <FormItem v-model="contact2.desc" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Designation')"></FormItem>
                            </div>
                        </div>
                        <div class="bottom_checkbox">
                            <CheckBox v-model="contact2.isAgreed"></CheckBox>
                            <div class="checkbox_content" @click="handleChange2">{{ langJson('The main account manager') }}</div>
                        </div>
                    </div>

                    <div class="submit">
                        <BTN class="btn_item" @handle="signup" :load="load_btn" :disable="!pass" type="active" bh="52">{{ langJson('Submit') }}</BTN>

                        <div v-if="isPhone" class="learn_more">
                            <div class="learm_more_box">
                                <span class="learn_text_1">{{ langJson('Powered by NFTWerks') }}</span>
                                <div></div>
                                <span class="learn_text_2 cursor" @click="jump_page_path('/startup/index')">{{ langJson('Learn more') }}</span>
                            </div>
                        </div>
                        <span v-if="!isPhone" class="submit_info">
                            {{ langJson('Account will only be activated after NFTwerks verification.') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <Model :borderRadius="16" :padding="isPhone ? '24px 24px' : '24px 74px'" :showHeader="false" v-model="RegistrationSuccessModel">
            <RegistrationSuccessModel slot="main" v-model="RegistrationSuccessModel" />
        </Model>
    </div>
</template>
<script>
export default {
    data() {
        return {
            countryList: [],
            RegistrationSuccessModel: false,
            form: {
                name: '',
                address: '',
                country: '',
                zipcode: '',
            },
            contact1: {
                name: '',
                email: '',
                phone: '',
                desc: '',
                isAgreed: false,
            },
            contact2: {
                name: '',
                email: '',
                phone: '',
                desc: '',
                isAgreed: false,
            },
        }
    },
    computed: {
        pass() {
            try {
                let { address, name, country, zipcode } = this.form

                let result = this.checkPass(name, ['unull', 'maxLength'], false) && this.checkPass(address, ['unull', 'maxLength'], false) && this.checkPass(country, ['unull'], false) && this.checkPass(zipcode, ['unull', 'maxLength'], false) && this.checkPass(this.contact1.name, ['unull', 'maxLength'], false) && this.checkPass(this.contact1.email, ['unull', 'email', 'maxLength'], false) && this.checkPass(this.contact1.desc, ['unull', 'maxLength'], false) && this.checkPass(this.contact2.name, ['unull', 'maxLength'], false) && this.checkPass(this.contact2.email, ['unull', 'email', 'maxLength'], false) && this.checkPass(this.contact2.desc, ['unull', 'maxLength'], false)
                // && this.contact2.isAgreed && this.contact1.isAgreed

                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    created() {
        this.init()
    },
    methods: {
        // 获取平台国家配置
        async init() {
            let { dispatch } = this.$store
            let res = await dispatch('configCountryConfig')
            if (res && res.code == 200) {
                this.countryList = res.data.map((item) => {
                    return {
                        lable: item.name,
                        value: item.name,
                    }
                })
            }
        },
        async signup() {
            this.load_btn = true
            try {
                let { email, name, password } = this.tempVal
                let lang = localStorage.getItem('Language')
                let parmas = {
                    userName: name,
                    email,
                    password,
                    userType: 1,
                    lang,
                    theme: this?.globalTheme == 'light' ? 0 : 1,
                    brandUserInfo: {
                        companyName: this.form.name,
                        country: this.form.country,
                        companyAddress: this.form.address,
                        zipCode: this.form.zipcode,
                        contactInfo: [
                            {
                                contactUserName: this.contact1.name,
                                contactNo: this.contact1.phone,
                                email: this.contact1.email,
                                designation: this.contact1.desc,
                                isMainAccount: this.contact1.isAgreed ? 1 : 0,
                            },
                            {
                                contactUserName: this.contact2.name,
                                contactNo: this.contact2.phone,
                                email: this.contact2.email,
                                designation: this.contact2.desc,
                                isMainAccount: this.contact2.isAgreed ? 1 : 0,
                            },
                        ],
                    },
                }
                let { dispatch } = this.$store
                let result = await dispatch('signup', parmas)
                if (result && result.code == 200) {
                    this.RegistrationSuccessModel = true
                } else {
                    this.pushMsg('error', this.langJson('fail to register'))
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('error', this.langJson('fail to register'))
            }
            this.load_btn = false
        },

        handleChange1() {
            this.$set(this.contact1, 'isAgreed', !this.contact1.isAgreed)
            // this.form1.isChecked = !this.form1.isChecked
        },
        handleChange2() {
            this.$set(this.contact2, 'isAgreed', !this.contact2.isAgreed)
            // this.form2.isChecked = !this.form2.isChecked
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    /* height: 100%; */
    width: 100%;
    display: flex;
    justify-content: center;
    .info {
        /* height: 100%; */
        width: 100%;
        /* box-sizing: border-box;
    width: 1440px;
    max-width: 100%;
    height: 1484px; */
        margin-top: 40px;
        position: relative;
        .company_form {
            /* position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 906px;
      height: 1396px; */
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            border-radius: 32px;
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                width: 100%;
                max-width: 366px;
                min-height: 136px;
                .circle {
                    width: 80px;
                    height: 80px;
                    background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    border-radius: 50%;
                }
                .company_form_title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 52px;
                    color: var(--font_deep_color);
                    font-family: 'Lato';
                }
                .title_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: var(--font_modif_color);
                    font-family: 'Lato';
                }
            }
        }
        .form {
            box-sizing: border-box;
            max-width: 906px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 32px;

            .form_box {
                box-sizing: border-box;
                width: 100%;
                padding: 32px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                border-radius: 32px;
                background: var(--color_white);
                .formItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                }
                .information_item {
                    width: 100%;
                }
                .form_box_title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--font_deep_color);
                    font-family: 'Inter';
                }
                .form_input {
                    width: 100%;
                    gap: 16px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }
                .bottom_checkbox {
                    .checkbox_content {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--font_modif_color);
                        cursor: pointer;
                    }
                    display: flex;
                    align-items: center;
                    color: var(--font_modif_color);
                    gap: 12px;
                }
            }
            .submit {
                width: 100%;
                min-height: 108px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 24px;
                margin-bottom: 48px;
                .btn_item {
                    width: 100% !important;
                }
                .submit_info {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: var(--font_deep_color);
                    margin-top: 8px;
                }
                .learn_more {
                    width: 100%;
                    height: 20px;
                    font-size: 14px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    margin-top: 28px;

                    .learm_more_box {
                        width: 233px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 6px;
                        gap: 10px;
                        align-items: center;
                        .learn_text_1 {
                            color: var(--font_modif_color);
                        }
                        div {
                            height: 8px;
                            background: var(--color_white);
                            border: 1px solid var(--border_color);
                        }
                        .learn_text_2 {
                            line-height: 20px;
                            text-align: right;
                            color: var(--primary_color);
                        }
                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .theme_box {
            position: absolute;
            top: 0px;
            right: 80px;
            /deep/ {
                .switch_theme {
                    background-color: var(--bg7) !important;
                }
            }
        }
    }
}

@media screen and (max-width: 906px) {
    .info {
        margin-top: 0px !important;
    }
    .company_form_title {
        font-weight: 700;
        font-size: 24px !important;
        line-height: 40px !important;
        color: var(--font_deep_color);
        padding-left: 43px;
        padding-right: 43px;
        font-family: 'Inter' !important;
    }
    .title_text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--font_modif_color);
        padding: 0px 43px;
    }
    .form_box_title {
        font-family: 'Lato' !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .theme_box {
        top: 20px !important;
        right: 20px !important;
    }
    .company_form {
        margin-top: 84px !important;
        .title {
            gap: 12px !important;
            height: 136px;
            justify-content: flex-start !important;
        }
    }
    .formItem {
        flex-wrap: wrap;
    }
    .form {
        padding: 0px 20px !important;
        margin-top: 4px;
        .form_box {
            padding: 16px !important;
        }
    }
}
</style>
