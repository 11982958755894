<template>
    <div class="information">
        <div class="title">
            {{langJson("Account support")}}
            <div class="submited" v-if="switchStep === 0" @click="switchPage(1)">
                {{langJson("Your submitted requests")}}
            </div>
            <div class="submited" v-else @click="switchPage(0)">
                {{langJson("Submit your message")}}
            </div>
        </div>
        <div class="tip">
            {{langJson("Can't find the answer you want in Help Center? You can make a request ")}}&nbsp;
            <!-- class="here" -->
            <span >{{langJson("Here")}}</span>
        </div>

        <StepRun ref="switch">
            <div slot="switch1">
                <div class="information_main">
                    <div class="information_left">
                        <FormItem v-model="form.name" :list="typelist"
                        type="select" :title="langJson('What can we help')" :placeholder="langJson('Select type')"></FormItem>
                        
                        <FormItem v-model="form.bio" class="information_item" 
                        type="text" :title="langJson('Email')" :placeholder="langJson('Please enter a Email')"></FormItem>
                        
                        <FormItem v-model="form.email" class="information_item" 
                        type="text" :title="langJson('Company name')" :placeholder="langJson('Please enter a Email')"></FormItem>
                        
                        <FormItem v-model="form.phone" class="information_item" 
                        type="textarea" :title="langJson('Your message')" :placeholder="langJson('Please enter your message')"></FormItem>
                    </div>
                    <div class="information_right">
                        <FormItem v-model="form.image"
                        type="image" :image="imageConfig" :title="langJson('Supporting information (Optional)')"></FormItem>
                    </div>
                </div>
                <div class="btns">
                    <BTN class="btn_item" type="active">{{langJson("Submit")}}</BTN>
                </div>
            </div>
            
            <div class="table_requested" slot="switch2">
                <Table :list="list" :title="title" :play="play">
                    <div class="more" slot="foot">
                        <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="false"></Page>
                    </div>
                </Table>
            </div>
        </StepRun>

        
        
        
        
        

        
    </div>
</template>
<script>
export default {
    data() {
        return {
            switchStep:0,
            form:{
                image:"",
                banner:"",
                name:"",
                bio:"",
                email:"",
                phone:""
            },
            imageConfig:{
                type:"small",
                titleStyle:"title_small"
            },
            typelist:[
                {
                    lable:this.langJson("Selling / purchasing NFT"),
                    value:1
                },
                {
                    lable:this.langJson("Account settings"),
                    value:2
                },
                {
                    lable:this.langJson("Cast NFT"),
                    value:3
                },
                {
                    lable:this.langJson("Other matters"),
                    value:4
                }
            ],
            play:[
                {
                    event:"Cancel",
                    title:"Cancel",
                    type:"main"
                }
            ],
            list:[
                {
                    name:"Miss Wan",
                    price:"200 ETH",
                    time:"2022-09-20 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    name:"Miss Zhou",
                    price:"140 ETH",
                    time:"2022-09-12 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    name:"Miss Li",
                    price:"100 ETH",
                    time:"2022-09-10 20:00",
                    address:"0xDe126...e1762e1Dc"
                }
            ].concat(new Array(6).fill({
                name:"Miss Li",
                price:"100 ETH",
                time:"2022-09-10 20:00",
                address:"0xDe126...e1762e1Dc"
            })),
            title:{
                name:{
                    title:"name",
                    type:"text"
                },
                price:{
                    title:"price",
                    type:"text"
                },
                time:{
                    title:"time",
                    type:"text"
                }
            }
        }
    },
    created() {
        this.$watch("form",(n) => {
            console.log(n)
        },{
            deep:true
        })
    },
    methods:{
        switchPage(val) {
            this.switchStep = val
            this.$refs.switch.jump(val)
        }
    }
}
</script>
<style lang="scss" scoped>
.more{
    height:50px;
    background:var(--color_white);
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px -5px 10px var(--bg_color);
    padding: 0 20px;

}
.more .page_main{
    justify-content: space-between!important;
    width:100%;
}
.information_item{
    margin-top:32px;
}
.information{
    .tip{
        
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color:var(--font_color);
        margin-top:8px;
        margin-bottom:24px;
        .here{
            color:var(--main_color);
            cursor: pointer;
        }
    }
    .information_main{
        .information_left{
            flex:1;
            margin-right:20px;
            
        }
        .information_right{
            flex:1;
            margin-left:20px;
        }
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        margin-top:32px;
    }
    .table_requested{
        box-shadow: 4px 4px 10px #ccc;
        border-radius: 12px;
    }
    .btns{
        display: flex;
        align-items: center;
        margin-top:30px;
        .btn_item{
            margin-right:20px;
        }
    }
    
    .title{
        .submited{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color:var(--main_color);
            cursor: pointer;
        }
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color:var(--font_deep_color);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>