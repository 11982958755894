<template>
    <div>
        <Go :prev="langJson('Back')" @prev="back"></Go>
        <div class="title">
            <div v-if="!isPhone" class="right_popup">
                <BtnPop>
                    <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                    <div slot="target" class="btn_popup">
                        <GI name="brand_more" ws="24" hs="24" class="pointer icon_"></GI>
                    </div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                                <span class="title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
            </div>
        </div>
        <div class="detail_box">
            <div class="content">
                <div class="content_left">
                    <div class="left_title">
                        <h2>{{ detail.name }}</h2>
                        <!-- 暂时没有集合名字 -->
                        <!-- <span>{{ langJson('Collection name') }}</span> -->
                    </div>
                    <div class="top_master">
                        <div class="person">
                            <!-- 缺少拥有者 缺少头像 -->
                            <span class="person_type">{{ langJson('Creator') }}</span>

                            <div class="header_name cursor" @click="jump_participant_campaign_participants_profile({ userId: detail.userId })">
                                <GI v-show="userVo.imageUrl" class="userIcon" :src="userVo.imageUrl" ws="32" hs="32"></GI>
                                <span class="name">{{ userVo.nickName }}</span>
                            </div>
                        </div>
                        <div class="line" v-if="detail.owner"></div>
                        <div class="person" v-if="detail.owner">
                            <span class="person_type">{{ langJson('Owner') }}</span>
                            <div class="header_name">
                                <span class="name cursor" @click="openAddress(detail.owner, detail.chainId)">{{ detail.owner | hideStr }}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="isPhone"
                        :style="{
                            backgroundImage: `url(${detail.data})`,
                        }"
                        class="mobile_content_right"
                    ></div>
                    <div class="info_item">
                        <span>{{ langJson('Contract Address') }}</span>
                        <h2 class="cursor" @click="openAddress(detail.contractAddr, detail.chainId)">{{ reAddress(detail.contractAddr) }}</h2>
                    </div>
                    <div class="info_item">
                        <span>{{ langJson('Token ID') }}</span>
                        <h2>{{ detail.tokenId }}</h2>
                    </div>
                    <div class="info_item">
                        <span>{{ langJson('Token standard') }}</span>
                        <h2>{{ langJson('ERC') }}{{ detail.contractType }}</h2>
                    </div>
                    <div class="info_item">
                        <span>{{ langJson('Description') }}</span>
                        <h2 v-html="detail.description" class="v-html"></h2>
                    </div>
                </div>
                <div
                    v-if="!isPhone"
                    :style="{
                        backgroundImage: `url(${detail.data})`,
                    }"
                    class="content_right"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detail: {},
            tagList: [
                // { icon_dark: 'profile_hide', icon_light: 'profile_hide_light', val: 'Hide', number: 1 },

                // { icon_dark: 'brand_edit', icon_light: 'brand_edit_light', val: 'Edit', number: 2 },

                { icon_dark: 'profile_share', icon_light: 'profile_share_light', val: 'Share', number: 3 },

                // { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', number: 4 },
            ],
        }
    },
    computed: {
        userVo() {
            try {
                return this.detail.userVo ? this.detail.userVo : {}
            } catch (error) {
                return {}
            }
        },
    },
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        goRoute(item) {
            switch (item.number) {
                case 1:
                    // 判断 viewObj.create 为true 则是 participant

                    break
                case 2:
                    break
                case 3:
                    // 分享
                    this.$store.commit('changeShowShare', true)
                    this.$store.commit('shareObj', {
                        ...this.globalConfig.shareObj,
                        img: this.detail.data,
                        title: this.langJson('Share your NFTs'),
                        url: ``,
                        linkName: this.langJson('NFTs link'),
                    })
                    break

                default:
                    break
            }
        },
        async init() {
            this.load_btn = true
            try {
                let { idNft } = this.$route.query
                let { dispatch } = this.$store
                let res = await dispatch('nftProduct', {
                    id: idNft,
                })
                if (res && res.code == 200) {
                    this.detail = res.data
                }
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: var(--max_width);
    width: 100%;
    margin: auto;
    height: 0 !important;
    .right_popup {
        position: relative;
        top: -46px;
        .btn_popup {
            width: 64px;
            height: 64px;
            background: var(--color_white);
            border: 1px solid var(--border_color);
            box-shadow: 4px 8px 40px rgba(162, 162, 162, 0.07);
            border-radius: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.detail_box {
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    background: var(--color_white);
    border-radius: 32px;
    .left_title {
        h2 {
            word-break: break-all;
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
    .content {
        margin-top: 32px;
        display: flex;
        gap: 32px;
        .content_left {
            width: 624px;
            padding: 32px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 32px;

            min-height: 546px;
            .top_master {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 40px;
                .person {
                    .person_type {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_color);
                    }
                    .header_name {
                        margin-top: 11px;
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        .userIcon {
                            border-radius: 100px;
                        }
                    }
                    .name {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        &.cursor {
                            color: #3993e6;
                            text-decoration-line: underline;
                        }
                    }
                }
                .line {
                    height: 41px;
                    border-right: 1px solid var(--border_color);
                }
            }
            .info_item {
                span {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                }
                h2 {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                    &.cursor {
                        color: #3993e6;
                        text-decoration-line: underline;
                        word-break: break-all;
                    }
                }
            }
        }
        .content_right {
            margin: 32px;
            margin-left: 0;
            width: 560px;
            height: 560px;
            border-radius: 32px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}

@media only screen and (max-width: 1080px) {
    .top_master {
        gap: 30px !important;
    }
    .content {
        margin-top: 0px !important;
    }
    .detail_box {
        margin-bottom: 63px;
        background: var(--bg_color3);
    }
    .content_left {
        background: transparent !important;
        flex: 1;
        height: auto !important;
        padding: 0px !important;
        .mobile_content_right {
            height: 335px;
            border-radius: 32px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }
}
</style>
