<template>
    <Model v-model="modelShow" @change="close" :showHeader="false">
        <div class="model_main" slot="main">
            <GI class="load_icon" name="run_loading" ws="123" hs="120"></GI>
            <div class="row">{{ langJson('Processing...') }}</div>
            <div class="row">{{ langJson('Please wait a few seconds') }}</div>
        </div>
    </Model>
</template>
<script>
let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    data() {
        return {
            modelShow: false,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.modelShow = this.show
    },
    watch: {
        modelShow(n) {
            this.$emit('change', n)
        },
        show(n) {
            this.modelShow = n
        },
    },
    computed: {},
    methods: {
        failed() {
            this.close()
            this.pushMsg('error', this.langJson('Extract failed'))
        },
        close() {
            this.$emit('change', false)

            // 完成后刷新列表
            this.$emit('completeExtract')
        },
        next() {
            this.$refs.step.next()
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .load_icon{
        margin-bottom:20px;
    }
    .row {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_deep_color);
        width: 100%;
        text-align: center;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 367px;

    box-sizing: border-box;
    width: 558px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
}
</style>


