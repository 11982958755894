<template>
    <div>
        <div class="header">
            <div class="left_title">
                <h2 class="title_name">
                    {{ langJson('Name of campaign') }}
                    <!-- <BtnPop v-if="isPhone" :left="-180">
                        <div slot="target" class="more_icon"><GI ws="24" hs="24" name="brand_more"></GI></div>
                        <div slot="main" class="type_main">
                            <div v-for="(item, index) in tagList" :key="index" class="type_content">
                                <div @click="goRoute(item)" class="type_line">
                                    <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                                    <span :class="['title',!showBtn?'disabledTitle':'']">{{ item.val }}</span>
                                </div>
                            </div>
                        </div>
                    </BtnPop> -->
                </h2>
                <!-- <span class="title_creator">{{ langJson('By Moon agency') }}</span> -->
            </div>
            <div v-if="!isPhone" class="right_go">
                <BTN icon_left="brand_trash" v-if="showBtn" type="warn" bh="52" bw="198">{{ langJson('Delete campaign') }}</BTN>
                <BTN icon_left="eye" v-if="showBtn" type="detail" @handle="jump_brand_compaign_preview()" bh="52" bw="131">{{ langJson('Preview') }}</BTN>
                <!-- <BTN @handle="saveChanges" :disable="!showBtn" type="active" bh="52" bw="209">{{ langJson('Submit for review') }}</BTN> -->
            </div>
        </div>
        <div class="line"></div>

        <div class="edit_preview">
            <h2 class="overview">{{ langJson('CAMPAIGN OVERVIEW') }}</h2>

            <div class="overview_box">
                <div class="overview_item">
                    <GI hs="56" ws="56" name="edit_basics"></GI>
                    <div @click="jump_brand_compaign_edit({ tab: 1, from: 'create' })" class="item_detail">
                        <h2>{{ langJson('Basics') }}</h2>
                        <span>{{ langJson('Name your project, upload cover, and establish your campaign details') }}</span>
                    </div>
                </div>
                <div class="overview_item">
                    <GI hs="56" ws="56" name="edit_rewards"></GI>
                    <div @click="jump_brand_compaign_edit({ tab: 2, from: 'create' })" class="item_detail">
                        <h2>{{ langJson('Rewards') }}</h2>
                        <span>{{ langJson('Set your rewards') }}</span>
                    </div>
                </div>
                <div class="overview_item">
                    <GI hs="56" ws="56" name="edit_links"></GI>
                    <div @click="jump_brand_compaign_edit({ tab: 3, from: 'create' })" class="item_detail">
                        <h2>{{ langJson('Links') }}</h2>
                        <span>{{ langJson('Add your website links and connect to your community') }}</span>
                    </div>
                </div>
                <div class="overview_item">
                    <GI hs="56" ws="56" name="edit_sticker"></GI>
                    <div @click="jump_brand_compaign_edit({ tab: 4 , from: 'create'})" class="item_detail">
                        <h2>{{ langJson('Sticker library') }}</h2>
                        <span>{{ langJson('Add your brand’s stickers') }}</span>
                    </div>
                </div>
                <div class="overview_item">
                    <GI hs="56" ws="56" name="edit_basics"></GI>
                    <div @click="jump_brand_compaign_edit({ tab: 5, from: 'create' })" class="item_detail">
                        <h2>{{ langJson('Smart Contract Deployer') }}</h2>
                        <span>{{ langJson('Add your Smart Contract Deployer') }}</span>
                    </div>
                </div>
            </div>
            <div class="submitted_box">
                <span>{{ langJson('Submit your campaign for review') }}</span>
            </div>
            <div class="review_box">
                <div class="review_item">
                    <div class="review_clock">
                        <GI hs="56" ws="56" name="project_review"></GI>
                    </div>

                    <div class="item_detail">
                        <h2>{{ langJson('Project review') }}</h2>
                        <span>{{ langJson('We’ll check to make sure it follows our rules and guidelines.') }}</span>
                        <span>{{ langJson('Please allow 1 business days for a response.') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="line"></div>
        <div class="edit_preview">
            <h2 class="overview">{{ langJson(screenFont) }}</h2>

            <div class="overview_box">
                <div @click="judgeShow" class="overview_item">
                    <GI hs="60" ws="60" name="edit_promotion_"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Promotion') }}</h2>
                        <span>{{ langJson('Generate your campaign URL and invite participants') }}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <Model :title="isPhone ? langJson('Enter your password') : langJson('Enter your password to submit your campaign')" :title2="isPhone ? langJson('to submit your campaign') : ''" padding="24px 28px" v-model="saveModel">
            <div class="notify_main" slot="main">
                <FormItem type="password" :matchs="['haveword']" :title="langJson('Password')"></FormItem>
                <div class="tips">
                    <span>
                        {{ langJson('We’ll check to make sure it follows our Terms & Privacy. ') }}
                        <br />
                        {{ langJson(' Please allow 24h for a response.') }}
                    </span>
                </div>
                <div class="notify_btns notify_common">
                    <BTN type="detail" @handle="cancelNotify" bw="270">{{ langJson('Cancel') }}</BTN>
                    <BTN type="active" @handle="jump_brand_compaign_plan" bw="270">{{ langJson('Send') }}</BTN>
                </div>
            </div>
        </Model>
    </div>
</template>

<script>
export default {
    data() {
        return {
            status: this.$route.query.status,
            saveModel: false,
            tagList: [
                // { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'Preview', number: 1 },
                { icon_dark: 'clients_upload', icon_light: 'clients_upload', val: 'Submit for Preview', number: 2 },
                // { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete Compaign', number: 3 },
            ],
            showBtn: true,
            screenValue:null,
        }
    },
    mounted(){
        window.addEventListener('resize', ()=>{
            // 窗口大小发生变化时的处理逻辑
            this.screenValue = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        });
    },
    computed:{
        screenFont(){
            let str = 'AFTER APPROVAL'
            if (this.screenValue < 1081) {
                str = 'CAMPAIGN OVERVIEW'
            }
            return str
        }
    },
    created() {
        this.$store.commit('compaignDetail', {})
        this.showBtn = true
        if (!this.$route.query.from) {
            this.showBtn = false
        }
    },
    methods: {
        saveChanges() {
            this.saveModel = true
        },
        closeNotify() {
            this.saveModel = false
        },
        cancelNotify() {
            this.saveModel = false
        },
        goRoute(item) {
            switch (item.number) {
                case 1:
                    break
                case 2:
                    if (!this.showBtn) {
                        this.pushMsg('warning', this.langJson('This page cannot submit data'))
                       return 
                    }
                    this.saveChanges()
                    break
                case 3:
                    break

                default:
                    break
            }
        },
        judgeShow(){
            if (!this.$route.query.from) {
                return
            }
            this.jump_brand_compaign_edit({ tab: 6, from: 'create' })
        }
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.header {
    max-width: var(--max_width);
    width: 100%;
    height: 144px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left_title {
        .title_name {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        .title_creator {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
    .right_go {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;
    }
}

.line {
    width: 100%;
    /* Neutral/5 */
    border-bottom: 1px solid var(--border_color);
}

.edit_preview {
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
    box-sizing: border-box;
    max-width: var(--max_width);
    .overview {
        margin-top: 24px;
        margin-bottom: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_deep_color);
    }
    .overview_box {
        width: 100%;
        border-radius: 32px;
        background: var(--color_white);
        .overview_item {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-left: 24px;
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--bg_color);
            box-sizing: border-box;
            cursor: pointer;
            .item_detail {
                h2 {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--font_color_black);
                }
                span {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--font_color);
                }
            }
        }
        .overview_item:last-child {
            border-bottom: none;
        }
    }

    .submitted_box {
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: var(--font_modif_color);
        }
    }
    .review_box {
        width: 100%;
        border-radius: 32px;
        border: 1px solid var(--border_color);
        .review_item {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-left: 24px;
            padding-top: 16px;
            padding-bottom: 16px;
            /* border-bottom: 1px solid var(--bg_color); */
            box-sizing: border-box;
            .review_clock {
                width: 56px;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .item_detail {
                display: flex;
                flex-direction: column;
                h2 {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--font_color_black);
                }
                span {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--font_color);
                }
                span:last-child {
                    color: var(--primary_color);
                }
            }
        }
        .overview_item:last-child {
            border-bottom: none;
        }
    }
}
.notify_main {
    width: 557px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    .tips {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color);
    }
    .notify_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .notify_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
    }
    .notify_btns {
        display: flex;
        gap: 16px;
        justify-content: space-between;
    }
}
@media screen and (max-width: 1080px) {
    .left_title {
        flex: 1;
    }
    .title_name {
        font-size: 32px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 335px;
    }
    .overview_item {
        padding: 16px 24px;
    }
    .review_item {
        padding: 16px 24px;
    }
    .notify_title {
        font-size: 18px !important;
    }
    .more_icon {
        box-sizing: border-box;
        width: 36px;
        height: 36px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .type_main {
        padding: 24px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 24px;
        cursor: pointer;
        box-sizing: border-box;
        .type_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:last-child {
                color: var(--danger_color);
            }
            &:not(:last-child) {
                color: var(--font_deep_color);
            }
            .type_line {
                display: flex;
                align-items: center;
                gap: 8px;

                .title {
                    white-space: nowrap;
                    font-size: 14px;
                    line-height: 20px;
                }
                .disabledTitle{
                    color: var(--font_modif_color) !important; 
                }
            }
        }
    }
    .edit_preview{
        .overview{
            margin-bottom: 12px; 
        }
        .review_box{
            background: var(--color_white);
            border:none;
        }
    }
}
@media screen and (max-width: 500px) {
    .notify_main {
        width: 74.4vw;
    }
    .notify_btns {
        flex-direction: column-reverse;
    }
}
</style>
