<template>
    <div class="nftSource">
        <!-- 图片 img-->
        <SourceImg @click.native="viewPreview()" v-if="isImage" :isDetail="isDetail" :Preview="Preview" :Data="Data" :fit="fit" :isUploadFile="isUploadFile"></SourceImg>
        <!-- 音乐 mp3-->
        <!-- <SourceMp3 v-if="isAudio" :Autoplay="Autoplay" :Preview="Preview" :Data="Data" :fit="fit" :isUploadFile="isUploadFile"></SourceMp3> -->
        <!-- 视频 mp4-->
        <SourceMp4 v-if="isVideo" :Autoplay="Autoplay" :Preview="Preview2" :Data="Data2" :fit="fit" :isUploadFile="isUploadFile"></SourceMp4>
        <!-- 3D模型 3dModel -->
        <!-- <Source3dModel v-if="isModel" :Preview="Preview" :Data="Data" :isDetail="isDetail" :fit="fit" :isUploadFile="isUploadFile"></Source3dModel> -->
        <!-- 3D模型 Html -->
        <!-- <Source3dHtml v-if="isHtmlModel" :Preview="Preview" :Data="Data" :isDetail="isDetail" :fit="fit" :isUploadFile="isUploadFile"></Source3dHtml> -->
    </div>
</template>
<script>
export default {
    props: {
        // 是否是上传 此时无预览图
        isUploadFile: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: String,
        },
        isDetail: {
            type: Boolean,
            default: Boolean,
        },
        Preview: {
            type: String,
            default: String,
        },
        Data: {
            type: String,
            default: String,
        },
        Autoplay: {
            type: Boolean,
            default: false,
        },
        fit: {
            type: String,
            default: 'cover',
        },
    },
    watch: {
        type(val) {
            this.typeNow = this.type
        },
        Preview: {
            handler(val) {
                this.Preview2 = val
            },
            immediate: true,
            deep: true,
        },
        Data: {
            handler(val) {
                this.Data2 = val
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            Preview2: '',
            Data2: '',
            typeNow: '',
            support_video_extensions: ['mp4', 'ogg'],
            support_image_extensions: ['jpeg', 'jpg', 'png', 'gif', 'svg', 'svg+xml', 'x-icon', 'webp', 'avif'],
            support_audio_extensions: ['mp3', 'mpeg'],
            support_model_extensions: ['glb'],
            support_html_extensions: ['html'],
        }
    },
    computed: {
        isVideo() {
            let tempArr = this.typeNow.split('/')
            let last = tempArr.length - 1
            return this.support_video_extensions.some((item) => {
                return item.toLowerCase() == tempArr[last].toLowerCase()
            })
        },
        isImage() {
            let tempArr = this.typeNow.split('/')
            let last = tempArr.length - 1
            return this.support_image_extensions.some((item) => {
                return item.toLowerCase() == tempArr[last].toLowerCase()
            })
        },
        isAudio() {
            let tempArr = this.typeNow.split('/')
            let last = tempArr.length - 1
            return this.support_audio_extensions.some((item) => {
                return item.toLowerCase() == tempArr[last].toLowerCase()
            })
        },
        isModel() {
            let tempArr = this.typeNow.split('/')
            let last = tempArr.length - 1
            return this.support_model_extensions.some((item) => {
                var is3D = item.toLowerCase() == tempArr[last].toLowerCase()
                return is3D
            })
        },
        isHtmlModel() {
            let tempArr = this.typeNow.split('/')
            let last = tempArr.length - 1
            return this.support_html_extensions.some((item) => {
                return item.toLowerCase() == tempArr[last].toLowerCase()
            })
        },
    },

    created() {
        this.typeNow = this.type
    },
    mounted() {},

    methods: {
        viewPreview() {
            if (this.isImage) {
                this.$emit('viewPreview')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.nftSource {
    height: 100%;
    width: 100%;
    max-height: 75vh;
}
</style>
