import contract from "./contract"
export default {
    ...contract,
    // danger success warning
    pushMsg(type, message) {
        let timelen = 5000
        let result = this.globalTip.concat([
            {
                type,
                message,
                expire: new Date().getTime() + timelen,
            },
        ])
        this.$store.commit('tips', result)
    },
    moveMsg() {
        let result = this.globalTip.slice(1)
        this.$store.commit('tips', result)
    }
}
