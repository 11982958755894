<template>
    <div class="submit_main">
        <div class="title">{{langJson("Submit NFT Project")}}</div>

        <StepRun ref="switch">
            <div slot="switch1">
                <div class="submit_btn">
                    <BTN class="btn_item" type="active" @handle="switchPage(1)">{{langJson("Submit Project")}}</BTN>
                </div>
                <div class="table_submited">
                    <Table :list="list" :title="title" :play="play">
                        <div class="more" slot="foot">
                            <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="false"></Page>
                        </div>
                    </Table>
                </div>
            </div>
            <div slot="switch2">
                <FormItem v-model="form.image" class="information_item" 
                type="image" :title="langJson('Project picture')"></FormItem>
                
                <FormItem v-model="form.banner" class="information_item" 
                type="image" :image="imageConfig" :title="langJson('Project banner')"></FormItem>
                
                <FormItem v-model="form.name" class="information_item" 
                type="text" :title="langJson('Project name')" :placeholder="langJson('Please enter project name')"></FormItem>
                
                <FormItem v-model="form.bio" class="information_item" 
                type="textarea" :title="langJson('Bio')" :placeholder="langJson('Please enter Bio')"></FormItem>
                
                <FormItem v-model="form.email" class="information_item" 
                type="text" :title="langJson('Contrarct address')" :placeholder="langJson('Please enter contrarct address')"></FormItem>
                
                <FormItem v-model="form.type" class="information_item"  :list="typelist"
                type="select" :title="langJson('Token Standard')" :placeholder="langJson('Select type')"></FormItem>

                <FormItem v-model="form.phone" class="information_item" 
                type="text" :title="langJson('Block height')" :placeholder="langJson('Please enter block height')"></FormItem>

                <div class="btns">
                    <BTN class="btn_item" type="active">{{langJson("Save change")}}</BTN>
                    <BTN class="btn_item" type="main" @handle="switchPage(0)">{{langJson("Back")}}</BTN>
                </div>
            </div>
        </StepRun>
        
        
    </div>
</template>
<script>
export default {
    data(){
        return {
            switchStep:0,
            form:{
                image:"",
                banner:"",
                name:"",
                bio:"",
                email:"",
                phone:"",
                type:721,
            },
            imageConfig:{
                type:"big"
            },
            typelist:[
                {
                    lable:this.langJson("ERC721"),
                    value:721
                },
                {
                    lable:this.langJson("ERC1155"),
                    value:1155
                }
            ],
            play:[
                {
                    event:"Cancel",
                    title:"Cancel",
                    type:"main"
                }
            ],
            list:[
                {
                    name:"Miss Wan",
                    price:"200 ETH",
                    time:"2022-09-20 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    name:"Miss Zhou",
                    price:"140 ETH",
                    time:"2022-09-12 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    name:"Miss Li",
                    price:"100 ETH",
                    time:"2022-09-10 20:00",
                    address:"0xDe126...e1762e1Dc"
                }
            ].concat(new Array(6).fill({
                name:"Miss Li",
                price:"100 ETH",
                time:"2022-09-10 20:00",
                address:"0xDe126...e1762e1Dc"
            })),
            title:{
                name:{
                    title:"name",
                    type:"text"
                },
                price:{
                    title:"price",
                    type:"text"
                },
                time:{
                    title:"time",
                    type:"text"
                }
            }
        }
    },
    computed:{
        haveData() {
            return this.list && this.list.length
        },
        listKey() {
            return Object.keys(this.title)
        }
    },
    methods:{
        switchPage(val) {
            this.switchStep = val
            this.$refs.switch.jump(val)
        }
    }
}
</script>
<style lang="scss" scoped>
.information_item{
    margin-top:32px;
}
.btns{
    display: flex;
    align-items: center;
    margin-top:30px;
    .btn_item{
        margin-right:20px;
    }
}
.more{
    height:50px;
    background:var(--color_white);
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px -5px 10px var(--bg_color);
    padding: 0 20px;

}
.more .page_main{
    justify-content: space-between!important;
    width:100%;
}
.submit_main{
    .submit_btn{
        display: flex;
        margin-top: 32px;
    }
    .title{
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color:var(--font_deep_color);
    }
    .table_submited{
        box-shadow: 4px 4px 10px #ccc;
        margin-top: 32px;
        border-radius: 12px;
    }
    
}
</style>