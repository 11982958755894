<template>
    <div :style="{ padding: padding }" class="item">
        <div
            class="image_out"
            :style="
                imageRadius
                    ? {
                          height: `${hs}px`,
                          borderRadius: `${imageRadius}px`,
                      }
                    : { height: `${hs}px` }
            "
            @click="jump_participant_compaign_preview({ id: item.id })"
        >
            <div v-if="item.isPrivate == 1" class="item_privacy">
                <GI name="brand_privacy_white" ws="20" hs="20"></GI>
            </div>
            <div
                class="item_image"
                :style="{
                    backgroundImage: `url(${item.banner})`,
                }"
            ></div>
            <slot name="image"></slot>
        </div>
        <div :class="titType">
            {{ item.name }}
        </div>
        <slot name="countDownText"></slot>
        <div @click="isParticipant ? jump_participant_campaign_participants_profileColl({ idUser: item.brandUserId }) : () => {}" class="item_belong">
            <div
                class="belong_image"
                :style="{
                    backgroundImage: `url(${brandUserVo.imageUrl})`,
                }"
            ></div>
            <div class="belong_name">{{ brandUserVo.nickName }}</div>
        </div>
        <div :class="descType">
            {{ item.description }}
        </div>
        <VI v-if="!isPhone" :participants="item.joinNum" :voters="item.voteNum" :days="item.endTime"></VI>
        <div v-if="(isPhone && showBtn) || showBtnSure" class="item_mobile_btn">
            <BTN type="detail" bh="48" fontSize="16" @handle="submitArtwork">{{ langJson('Submit Artwork') }}</BTN>
        </div>
        <Model :padding="'24px'" v-model="showSubmitArtwork">
            <SubmitArtTip slot="main" :id="item.id" @cancel="cancel" v-model="showSubmitArtwork" :titleActive="item.name" @computed="handSubArtTip"></SubmitArtTip>
        </Model>
    </div>
</template>
<script>
export default {
    props: {
        isParticipant: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        titType: {
            type: String,
            default: 'item_title',
        },
        descType: {
            type: String,
            default: 'item_desc',
        },
        imageRadius: {
            type: String,
            default: '',
        },
        showBtn: {
            type: Boolean,
            default: false,
        },
        showBtnSure: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: String,
            default: '16px 24px',
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            showSubmitArtwork: false,
        }
    },
    computed: {
        // 商户信息
        brandUserVo() {
            try {
                return this.item.brandUserVo ? this.item.brandUserVo : {}
            } catch (error) {
                return {}
            }
        },
    },
    methods: {
        handSubArtTip() {
            localStorage.setItem('loginBgUrl', this.item.banner)
        },
        submitArtwork() {
            this.showSubmitArtwork = true
        },
        cancel(val) {
            this.showSubmitArtwork = val
        },
    },
}
</script>
<style lang="scss" scoped>
.item {
    .item_tags {
        .tag_item {
            margin-right: 12px;
            &:last-child {
                margin-right: 0px;
            }
        }
        margin-top: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .item_desc_small {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 16px;
        color: var(--font_color);
        word-break: break-all;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        min-height: 40px;
    }
    .item_desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
        color: var(--font_deep_color);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .item_belong {
        .belong_name {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
            margin-left: 8px;
        }
        .belong_image {
            height: 28px;
            width: 28px;
            border-radius: 50%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
        }
        margin-top: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .item_title {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
        margin-top: 16px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .item_title_small {
        color: var(--font_deep_color);
        margin-top: 16px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .image_out {
        border-radius: 32px;
        overflow: hidden;
        width: 100%;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        position: relative;
        cursor: pointer;

        .item_privacy {
            position: absolute;
            top:15px;
            left:15px;
            width: 40px;
            height: 40px;
            background-color: var(--danger_color);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 200;
        }
        
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
    padding: 24px;
}
@media only screen and (max-width: 1080px) {
    .item {
        background-color: var(--color_white);
        padding: 16px;
        border-radius: 16px;
        box-sizing: border-box;
        .item_mobile_btn {
            margin-top: 16px;
        }
        .item_tags {
            .tag_item {
                margin-right: 12px;
                &:last-child {
                    margin-right: 0px;
                }
            }
            margin-top: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .item_desc_small {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: 16px;
            color: var(--font_color);

            white-space: pre-wrap;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .item_desc {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 16px;
            color: var(--font_color);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .item_belong {
            .belong_name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-left: 8px;
            }
            .belong_image {
                height: 28px;
                width: 28px;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
            }
            margin-top: 8px;
            display: flex;
            align-items: center;
        }
        .item_title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 48px;
            color: var(--font_deep_color);
            margin-top: 16px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .item_title_small {
            color: var(--font_deep_color);
            margin-top: 16px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .image_out {
            border-radius: 12px;
            overflow: hidden;
            width: 100%;
            background-color: rgba($color: #ccc, $alpha: 0.2);
            position: relative;
            cursor: pointer;
            .item_image {
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;

                transition: all 2s;
            }
            .item_image:hover {
                transform: scale(2);
            }
        }
    }
}
</style>
