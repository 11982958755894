<template>
    <div class="step_item">

    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.listings{
    display: flex;
    flex-direction: column;
}
</style>