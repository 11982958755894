<template>
  <div>
    <div class="form">
      <div class="header">
        <div class="header_title">
          <h2>{{ langJson('Subscriptions') }}</h2>
          <span>{{ langJson('Your next monthly charge of $199 will be applied to your chosen payment method on April 21, 2022.') }} </span>
        </div>
        <div class="btn">
          <BTN :bw="isPhone ? 136 : 187" type="active">{{ langJson('Change plan') }}</BTN>
          <span class="cancel_subscription">{{ langJson('Cancel subscription') }}</span>
        </div>
      </div>
      <div class="table_box">
        <h2 class="table_title">{{ langJson('Billing history') }}</h2>
        
        <div class="table_scroll">
          <Table :commonStyle="true" :list="list" :title="title" :play="play"> </Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          No: '1',
          Plan: 'Phifer Hall',
          Amount: '$199',
          Date: 'Nov 11, 2022',
          Status: 'Successful',
        },
      ],
      play: [
        {
          event: 'viewDetail',
          title: 'View detail',
          type: 'detail',
          act: 'action',
        },
      ],
      title: {
        No: {
          title: 'No.',
          type: 'text',
        },
        Plan: {
          title: 'Plan',
          type: 'text',
        },
        Amount: {
          title: 'Amount',
          type: 'text',
        },
        Date: {
          title: 'Date',
          type: 'text',
        },
        Status: {
          title: 'Status',
          type: 'text',
        },
      },
    }
  },
  mounted() {},
  methods: {},
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.form {
  box-sizing: border-box;
  max-width: var(--max_form_width);
  margin: auto;
  width: 100%;
  background: var(--color_white);
  border-radius: 32px;
  padding: 32px;
  margin-bottom: 156px;
  .table_box {
    .table_title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: var(--font_deep_color);
    }
    .table_scroll {
      overflow: auto;
    }
   
  }
  .header {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .header_title {
      font-family: 'Lato';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      h2 {
        font-family: 'Cabinet Grotesk';
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
      }
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color_black);
      }
    }
    .btn {
      display: flex;
      align-items: center;
      gap: 32px;
      .cancel_subscription {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--danger_color);
        white-space: nowrap;
      }
    }
  }
}
@media only screen and (max-width: 1080px) {
  .form {
    box-sizing: border-box;
    max-width: 100%;
    margin: auto;
    width: 100%;
    background: var(--color_white);
    border-radius: 32px;
  padding:20px !important;
    margin-bottom: 156px;
    .header {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      .header_title {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        h2 {
          font-family: 'Cabinet Grotesk';
          font-weight: 800;
          line-height: 48px;
          font-size: 32px;
          color: var(--font_deep_color);
        }
      }
      .btn {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          font-family: 'Cabinet Grotesk';
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 24px;
          color: var(--danger_color);
        }
      }
    }
  }

  .header_title {
    h2 {
    }
  }
}
</style>
