<template>
    <div class="item_tags">
        <div v-if="!isPhone" class="tag_item">
            <Tag type="warning" icon="brand_participant">{{ zan }} {{ langJson('participants') }}</Tag>
        </div>
        <div class="tag_item">
            <Tag type="vote" icon="brand_like">{{ coll }} {{ langJson('Votes') }}</Tag>
        </div>
        <div class="tag_item">
            <Tag type="time" v-if="status == 'live'" icon="brand_clock">{{ view }} {{ langJson('days to go') }}</Tag>
        </div>
        <div class="tag_item">
            <Tag type="time" v-if="status == 'archive'" icon="gallery">{{ arts }} {{ langJson('artworks') }}</Tag>
        </div>
        <div class="tag_item">
            <Tag type="primary" v-if="isParticipant" icon="parniticipant_clock">{{ view }} {{ langJson('days to go') }}</Tag>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        zan: {
            type: [Number, String],
            default: 0,
        },
        coll: {
            type: [Number, String],
            default: 0,
        },
        view: {
            type: [Number, String],
            default: 0,
        },
        arts: {
            type: [Number, String],
            default: 0,
        },
        status: {
            type: String,
            default: '',
        },
        isParticipant: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.item_tags {
    .tag_item {
        &:last-child {
            margin-right: 0px;
        }
    }
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    // grid-gap:12px 8px;
    row-gap: 12px;
    column-gap: 8px;
}
@media only screen and (max-width: 1080px) {
    .item_tags {
    }
}
</style>
