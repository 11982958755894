<template>
    <div v-if="globalMarket.campaignDetailUser && globalMarket.campaignDetailUser.name" class="step">
        <div class="left">
            <div class="bar">{{ langJson('HOW TO') }}</div>
            <div class="title" v-if="globalMarket.campaignDetailUser && globalMarket.campaignDetailUser.name">
                {{ `Acquire your own ${globalMarket.campaignDetailUser.name} NFT` }}
            </div>
            <div class="desc">
                {{ langJson('Participation is straightforward, simple and open to all festival attendees who wish to be part of this groundbreaking initiative. ') }}
            </div>
            <div v-if="globalMarket.campaignDetailUser && globalMarket.campaignDetailUser.name" class="t">
                {{ `To join the ${globalMarket.campaignDetailUser.name} NFT campaign, follow these steps:` }}
            </div>
            <div class="list">
                <div class="tiem">
                    <div class="key">1</div>
                    <div class="val">
                        <div class="valT">{{ langJson('Create a personal account') }}</div>
                        <div class="valDesc">{{ langJson('Get started on the platform') }}</div>
                    </div>
                </div>
                <div class="tiem">
                    <div class="key">2</div>
                    <div class="val">
                        <div class="valT">{{ `During ${globalMarket.campaignDetailUser.name}, capture your favourite moments on your phone.` }}</div>
                        <div class="valDesc">{{ langJson('It could be of a incredible performance or stunning artwork displayed at the event') }}</div>
                    </div>
                </div>
                <div class="tiem">
                    <div class="key">3</div>
                    <div class="val">
                        <div class="valT">{{ langJson('Submit these moments.') }}</div>
                        <div class="valDesc">{{ langJson('Your images will be considered for the campaign') }}</div>
                    </div>
                </div>
            </div>

            <!-- 商户和没有参加的用户 -->
            <div v-if="!detail_user.isJoin" class="view_more">
                <!-- 这里应该直接提交 -->

                <!-- 商户自己不能提交艺术品 -->
                <BTN class="btn_item" v-if="isParticipant && detail_user.isInvite" type="active" @handle="JoinCampaign" bh="52" :bw="isPhone ? '249' : '180'">
                    {{ langJson('Join campaign') }}
                </BTN>

                <BTN class="btn_item" v-if="isParticipant && !detail_user.isInvite" type="active" @handle="logIn" bh="52" :bw="isPhone ? '249' : '180'">
                    {{ langJson('Log In') }}
                </BTN>
            </div>
            <div v-if="isParticipant && detail_user.isJoin" class="view_more_joined">
                <!-- 参加了会显示这个按钮 -->
                <BTN class="btn_item" v-if="isParticipant && detail_user.isJoin && detail_user.isInvite" @handle="submitArtWork()" type="active" bh="52" :bw="isPhone ? '339' : '193'">
                    {{ langJson('Submit artwork') }}
                </BTN>
                <!-- 参加了就没有这个按钮  -->
                <!-- 布尔值joined表示是否参加了，已参加就是true，未参加就是false -->
                <!-- 参加才会显示 -->
            </div>
        </div>
        <div class="right">
            <div class="nft" :style="{ backgroundImage: `url(/source/png/step_bg.png)` }">
                <div class="title">
                    <div class="t">{{ langJson('@DJSNDWN') }}</div>
                    <div class="desc">{{ detail_user.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
        detail_user: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {}
    },
    computed: {
        // bannerImage() {
        //     try {
        //         let isMobile = this.browser().mobile
        //         let { banner, appBanner } = this.detail
        //         return isMobile ? (appBanner ? appBanner : banner) : banner
        //     } catch (err) {
        //         console.log(err)
        //         return ''
        //     }
        // },
        // 商户信息
        // brandUserVo() {
        //     try {
        //         return this.detail.brandUserVo ? this.detail.brandUserVo : {}
        //     } catch (error) {
        //         return {}
        //     }
        // },
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        JoinCampaign() {
            this.$bus.$emit('JoinCampaign')
        },
        logIn() {
            this.$bus.$emit('logIn')
        },
        submitArtWork() {
            this.$bus.$emit('submitArtWork')
        },
    },
}
</script>
<style lang="scss" scoped>
.step {
    margin: 0 auto;
    max-width: var(--max_width);
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 1080px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .left {
        width: calc(100% - 608px - 200px);
        margin-bottom: 83px;

        @media screen and (max-width: 1080px) {
            width: calc(100% - 24px);
            margin-bottom: 34px;
        }
        .bar {
            margin-top: 90px;
            @media screen and (max-width: 500px) {
                margin-top: 87px;
            }
            font-family: Lato;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            background-image: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%);
            color: transparent;
            -webkit-background-clip: text;
        }
        .title {
            font-family: Cabinet Grotesk;
            font-size: 56px;
            font-weight: 700;
            line-height: 64px;
            margin-top: 24px;
            color: var(--font_color);

            @media screen and (max-width: 1080px) {
                font-size: 32px;
                line-height: 40px;
            }
        }
        .desc {
            margin-top: 24px;
            font-family: Lato;
            font-size: 16px;
            /* font-weight: 600; */
            line-height: 24px;

            color: var(--font_color);
        }
        .t {
            margin-top: 44px;
            font-family: Lato;
            font-size: 16px;
            /* font-weight: 600; */
            line-height: 24px;
            color: var(--font_color);
        }
        .list {
            .tiem {
                margin-top: 24px;
                display: flex;
                align-items: center;
                .key {
                    width: 32px;
                    height: 32px;
                    border-radius: 32px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%);
                    color: var(--font_color);

                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32px;
                }
                .val {
                    margin-left: 16px;
                    .valT {
                        font-family: Lato;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        @media screen and (max-width: 1080px) {
                            font-size: 18px;
                        }
                    }
                    .valDesc {
                        font-family: Lato;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: var(--font_color);
                        @media screen and (max-width: 1080px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .view_more {
            /* margin: 0 auto; */
            margin-top: 25px;
            width: 224px;
            .btn_item {
                min-width: 100% !important;
                width: 100%;
            }
        }
        .view_more_joined {
            /* margin: 0 auto; */
            margin-top: 25px;
            width: 224px;
            .btn_item {
                min-width: 100% !important;
                width: 100%;
            }
        }
    }
    .right {
        @media screen and (max-width: 1080px) {
            width: 100vw;
            height: 130vw;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }
        .nft {
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            height: 641px;
            width: 608px;
            @media screen and (max-width: 1080px) {
                width: 130vw;
                height: 130vw;
            }
            .title {
                position: absolute;
                top: 70.8268330733%;
                left: 36.8421052632%;
                transform: rotateZ(-11.3deg);
                /* transform: rotate3d(x, y, z, deg) */
                transform-style: preserve-3d; /* 保持元素内部的3D空间 */
                .t {
                    font-family: Lato;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    width: 280px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    @media screen and (max-width: 1080px) {
                        width: 61vw;
                        font-size: 4vw;
                        line-height: 6vw;
                    }

                    @media screen and (max-width: 400px) {
                        font-size: 5vw;
                        line-height: 6.6vw;
                    }
                }
                .desc {
                    margin-top: -2px;
                    font-family: Inter;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 44px;
                    width: 280px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    @media screen and (max-width: 1080px) {
                        width: 61vw;
                        font-size: 6vw;
                        line-height: 9.8vw;
                    }
                }
            }
        }
    }
}
</style>
