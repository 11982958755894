<template>
    <div class="faq">
        <!-- <StartHead></StartHead> -->
        <div class="faq_main">
            <div v-if="!isPhone" class="faq_title">
                {{ langJson('Frequently Asked Questions') }}
                <div class="cycle" :style="{ backgroundImage:`url(/source/png/faqs_cycle.png)` }"></div>
                <div class="star" :style="{ backgroundImage:`url(/source/png/faqs_star.png)` }"></div>
            </div>
            <div v-if="isPhone" class="faq_moblie_title">
                {{ langJson('Frequently Asked Questions') }}
                <div class="cycle" :style="{ backgroundImage:`url(/source/png/faqs_moblie_cycle.png)` }"></div>
                <div class="star" :style="{ backgroundImage:`url(/source/png/faqs_moblie_star.png)` }"></div>
            </div>
            <div class="content">
                <div class="faq_item" v-for="(item, index) of globalHelp.articleType" :key="index">
                    <div class="item_top">
                        <div class="item_title">{{ item.title }}</div>
                        <div class="item_icon cursor" @click="!status[index] ? openItem(index) : closeItem(index)">
                            <GI class="icon_target" v-if="!status[index]" name="faq_close" ws="24" hs="24" :noClickStop="true"></GI>
                            <GI class="icon_target" v-else name="faq_open" ws="24" hs="24" :noClickStop="true"></GI>
                        </div>
                    </div>
                    <div class="item_bottom v-html" v-html="item.content"></div>
                    <!-- {{ item.content }} -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            status: {},
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            try {
                let { dispatch } = this.$store
                await dispatch('articleType', { typeId: 1 })
            } catch (err) {
                console.log(err)
            }
        },
        openItem(index) {
            this.$set(this.status, index, true)
            let faqGroup = document.getElementsByClassName('faq_item')

            let target = faqGroup[index].getElementsByClassName('item_bottom')[0]
            target.style.cssText = 'display:block;opacity: 1;'
        },
        closeItem(index) {
            this.$set(this.status, index, false)
            let faqGroup = document.getElementsByClassName('faq_item')
            let target = faqGroup[index].getElementsByClassName('item_bottom')[0]
            target.style.cssText = ''
        },
    },
}
</script>
<style lang="scss" scoped>
.icon_target {
    pointer-events: stroke;
}
.faq {
    .faq_main {
        .content {
            min-height: calc(100vh - 500px);
            .faq_item {
                .item_bottom {
                    width: 100%;
                    height: auto;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_modif_color) !important;
                    display: none;
                    opacity: 0;
                    transition: all 1s;
                    margin-top:24px;
                }
                .item_top {
                    .item_title {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 40px;
                        color: var(--font_deep_color);
                    }
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                }
                margin-bottom: 24px;

                padding: 24px;
                justify-content: space-between;
                align-items: center;

                border-radius: 24px;
                background: var(--white_color);
                box-shadow: 9px 7px 47px 0px rgba(0, 0, 0, 0.07);
            }
            width: 677px;
        }
        .faq_title {
            .star {
                position: absolute;
                top:-64px;
                left:100px;
                width:30px;
                height:44px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            .cycle {
                position: absolute;
                top:-50px;
                left:0px;
                width:100%;
                height:120px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            position: relative;
            font-family: Cabinet Grotesk;
            font-size: 56px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            color: var(--font_deep_color);
            width: 464px;
        }

        .faq_moblie_title {
            .star {
                position: absolute;
                bottom:0px;
                left:36px;
                width:29px;
                height:29px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            .cycle {
                position: absolute;
                bottom:-10px;
                right:0px;
                width:100%;
                height:70px;
                background-position: left center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            position: relative;
            font-family: Cabinet Grotesk;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 38px;
            color: var(--font_deep_color);
            text-align: center;
            width: 100%;
            height: 76px;
            margin-top:38px;
            margin-bottom:40px;
        }
        display: flex;
        justify-content: space-between;
        max-width: var(--max_width);
        width: 100%;
        margin: auto;
        margin-top: 80px;
    }
}
@media only screen and (max-width: 1080px) {
    .faq_main {
        margin-top: 20px !important;
        padding-bottom: 24px;
    }
    .faq_item {
        margin-bottom: 16px !important;
        .item_bottom {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
    .item_title {
        font-family: 'Lato' !important;

        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }
}
@media screen and (max-width: 824px) {
    .faq {
        .faq_main {
            flex-wrap: wrap;

            .faq_title {
                font-weight: 800;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: var(--font_deep_color);
                font-family: 'Cabinet Grotesk';
                margin-bottom: 15px;
            }
        }
    }
}
</style>
