<template>
    <div class="search_item">
        <div class="search_main">
            <div class="search">
                <IN v-model="source.keyword" :placeholder="langJson('Please enter keywords')"></IN>
            </div>
            <div class="sort">
                <Sel v-model="source.sort" :list="sortlist"></Sel>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            source:{
                keyword: "",
                sort: ""
            },
            sortlist:[
                {
                    lable:this.langJson("Sort By"),
                    value:""
                },
                {
                    lable:this.langJson("Recently created"),
                    value:"create"
                },
                {
                    lable:this.langJson("Most Favorited"),
                    value:"favorite"
                },{
                    lable:this.langJson("Price:  low to high"),
                    value:"lowPrice"
                },
                {
                    lable:this.langJson("Price:  high to low"),
                    value:"highPrice"
                },
                {
                    lable:this.langJson("Recently launched"),
                    value:"new"
                },
                {
                    lable:this.langJson("Most recommended"),
                    value:"way"
                },
                {
                    lable:this.langJson("Recent transactions"),
                    value:"trade"
                },
                {
                    lable:this.langJson("Ending soon"),
                    value:"expire"
                }
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
.search_item{
    .search_main{
        .sort{
            
        }
        .search{
            background-color: var(--bg_color);
            border-radius: 6px;
            
            width:300px;
            
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    background:var(--color_white);
    padding:16px;
    width:100%;
    margin-bottom:20px;
    border-radius: 12px;
}
</style>