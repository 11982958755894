<template>
    <div class="main">
        <div class="left_img" :style="loginBgUrl ? { backgroundImage: `url(${loginBgUrl})` } : {}"></div>

        <div class="right_box">
            <Go class="top_play" :prev="langJson('Back')" @prev="jump_participant_compaign">
                <div class="theme_box">
                    <Theme></Theme>
                </div>
            </Go>

            <div class="login_form">
                <div class="title">
                    <Logo></Logo>
                    <div class="title_content">
                        <h2>{{ langJson('Welcome') }}</h2>
                        <p>{{ langJson('Please enter your username and password') }}</p>
                    </div>
                </div>

                <div class="form_input">
                    <FormItem v-model="form.name" class="information_item" type="text" :title="langJson('Username ')" :matchs="['unull', 'maxLength']" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>

                    <FormItem v-model="form.password" class="information_item" type="password" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']" :title="langJson('Password ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>

                    <div class="fgt-password">
                        <div @click="showReset = true" style="cursor: pointer">
                            {{ langJson('Forgot password?') }}
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <BTN class="btn_item" @handle="login" type="active" :disable="!pass" :load="load_btn" :bh="isPhone ? 48 : 52" :bw="isPhone ? 335 : 406">
                        {{ langJson('Log In') }}
                    </BTN>

                    <p class="border">
                        <span>{{ langJson('Or') }}</span>
                    </p>
                    <div :class="['text_item', loading ? 'active_text' : '']" @click="selectLogin">
                        <div class="img">
                            <GI class="icon_google" type="png" name="google" ws="24" hs="24" :noClickStop="true"></GI>
                        </div>

                        <div class="text">{{ langJson('Continue with Google') }}</div>
                    </div>
                    <div class="notice">
                        <span class="notice_info">{{ langJson('Don’t have account? ') }}</span>
                        <span class="notice_info" @click="jump_participant_signup">{{ langJson('Sign up') }}</span>
                    </div>
                </div>
            </div>
            <div class="learn_more">
                <div class="learm_more_box">
                    <span class="learn_text_1">{{ langJson('Powered by NFTWerks') }}</span>
                    <!-- <div></div>
                    <span class="learn_text_2 cursor" @click="jump_page_path('/startup/index')">{{ langJson('Learn more') }}</span> -->
                </div>
            </div>
        </div>

        <Model :padding="isPhone ? '28px 24px' : '32px'" v-model="showReset">
            <div slot="main">
                <StepRun ref="step">
                    <ParticipantReset slot="step1" @next="next" @close="showReset = false"></ParticipantReset>
                    <ParticipantVerify slot="step2" :email="form.email" @close="showReset = false"></ParticipantVerify>
                </StepRun>
            </div>
        </Model>
    </div>
</template>
<script>
import { getCookie } from '@/assets/js/cookie'
export default {
    data() {
        return {
            loginBgUrl: '',
            showReset: false,
            form: {
                name: '',
                password: '',
                email: '',
                code: '',
            },
            loading: false,
        }
    },
    computed: {
        pass() {
            try {
                let { name, password } = this.form
                let result = this.checkPass(name, ['unull', 'maxLength'], false) && this.checkPass(password, ['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop'], false)

                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    created() {
        // this.loginBgUrl = localStorage.getItem('loginBgUrl')
        // let token = getCookie('token')
        // 未登录 且 有回调函数 开始记录需要回退的页面
        // if (!token && window.loginComple) {
        //     localStorage.setItem('noSaveHistory', 'true')
        // } else {
        //     localStorage.setItem('noSaveHistory', '')
        // }
    },
    async mounted() {
        console.log('参与者')

        // 重置 isGoogle
        localStorage.setItem('isGoogle', false)

        // 获取token
        let { dispatch, commit } = this.$store
        if (!!this.$route.query.code && !!this.$route.query.state) {
            await dispatch('authLogin', {
                source: 'google',
                socialCode: this.$route.query.code,
                socialState: this.$route.query.state,
                userType: 0,
            }).then(async (el) => {
                console.log('参与者---', el)
                if (el.code == 1009 || !el) {
                    this.jump_participant_signup()
                } else if (el.code == 200 && !!el.data) {
                    await commit('token', el.data.token)
                    this.jump_participant_compaign()
                }
            })
        }
    },
    methods: {
        async selectLogin() {
            if (this.loading) true
            this.loading = true

            try {
                let { dispatch } = this.$store
                await dispatch('authBinding', {
                    userType: 0,
                }).then(async (el) => {
                    if (el.code == 200) {
                        this.loading = false
                        localStorage.setItem('isGoogle', true)
                        window.open(el.data, '_self') //_blank _self
                    }
                })
            } catch (error) {}

            this.loading = false
        },
        async login() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let { name, password } = this.form
                let parmas = {
                    userType: 0, // 0-参与者 1-品牌
                    userName: name,
                    password,
                }
                let result = await dispatch('login', parmas)
                if (result && result.code == 200) {
                    localStorage.setItem('userType', 0)
                    await dispatch('userGet')
                    this.$store.commit('saveHeadView', { create: true })
                    if (window.loginComple) {
                        window.loginComple()
                        window.loginComple = ''
                        this.timer = setTimeout(() => {
                            localStorage.setItem('loginBgUrl', '')
                            clearTimeout(this.timer)
                        }, 1000)
                    } else {
                        this.jump_participant_compaign()
                    }
                } else {
                    this.$set(this.form, 'name', '')
                    this.$set(this.form, 'password', '')
                }
                // else {
                //     this.pushMsg('error', this.langJson('登录失败'))
                // }
            } catch (err) {
                console.log(err)
                this.$set(this.form, 'name', '')
                this.$set(this.form, 'password', '')
                // this.pushMsg('error', this.langJson('登录失败'))
            }
            this.load_btn = false
        },
        next(email) {
            console.log(email)
            this.$set(this.form, 'email', email)
            this.$refs.step.next()
            this.$nextTick(() => {
                this.$refs.step.$children[1].sendCode()
            })
        },
        prev() {
            this.$refs.step.prev()
        },
        backPrev() {
            if (this.$refs.step.step > 0) {
                this.prev()
            } else {
                this.back()
            }
        },
        close() {
            this.$emit('change', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    width: 100%;
    display: flex;
    .left_img {
        height: 100vh;
        width: 50%;
        background-image: url('../../assets/image/login/bgc.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .right_box {
        flex: 1;
        // padding-top: 40px;
        box-sizing: border-box;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: auto;
        .top_play {
            margin: 0 auto;
            width: 100%;
            max-width: 470px;
        }

        .theme_box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            // padding-right: 80px;
            box-sizing: border-box;
            /deep/ {
                .switch_theme {
                    background-color: var(--bg7) !important;
                }
            }
        }
        .login_form {
            box-sizing: border-box;
            margin: 44px auto;
            margin-top: 0px;
            width: 100%;
            max-width: 470px;
            padding: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            background: var(--color_white);
            border-radius: 32px;
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 100%;
                padding: 0 32px;
                text-align: center;
                .title_content {
                    p {
                        color: var(--font_modif_color);
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        margin-top: 4px;
                    }
                    h2 {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 52px;
                        text-align: center;
                        color: var(--font_deep_color);
                    }
                }
            }

            .form_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 16px;
                .information_item {
                    width: 100%;
                }
            }
            .fgt-password {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .ico {
                    height: 24px;
                    margin-left: 15px;
                }
                .icon_google {
                    cursor: pointer;
                }

                div {
                    font-family: 'Lato';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: right;
                    color: var(--primary_color);
                }
                margin-top: 12px;
            }
            .btn {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .notice {
                    margin-top: 12px;
                    .notice_info {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        /* identical to box height, or 150% */
                        margin: 0 5px;
                        text-align: center;

                        /* Neutral/4 */

                        color: var(--font_modif_color);
                    }
                    span:nth-child(2) {
                        color: var(--primary_color);
                        font-weight: 700;
                        cursor: pointer;
                    }
                }
                .border {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    &::before {
                        content: '';
                        box-sizing: border-box;
                        border-width: 0;
                        border-style: solid;
                        border-color: rgb(245, 246, 247);
                        border-top-width: 1px;
                        border-bottom-width: 1px;
                        width: 100%;
                        margin-right: 16px;
                    }
                    &::after {
                        content: '';
                        box-sizing: border-box;
                        border-width: 0;
                        border-style: solid;
                        border-color: rgb(245, 246, 247);
                        border-top-width: 1px;
                        border-bottom-width: 1px;
                        width: 100%;
                        margin-left: 16px;
                    }
                    span {
                        color: var(--font_modif_color);
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .text_item {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background-color: #1a73e8;
                    border-radius: 40px;
                    padding: 2px;
                    margin-top: 20px;
                    .img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 12px;
                        width: 44px;
                        height: 44px;
                        border-radius: 50%;
                        background-color: #fff;
                    }
                    .text {
                        flex: 1;
                        color: #fff;
                        text-align: center;
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .active_text {
                    cursor: no-drop;
                    background-color: #c9ccdd;
                }
            }
        }

        .learn_more {
            width: 100%;
            height: 20px;
            font-size: 14px;
            padding-right: 80px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            .learm_more_box {
                /* width: 233px; */
                height: 100%;
                display: flex;

                gap: 10px;
                align-items: center;
                .learn_text_1 {
                    color: var(--font_modif_color);
                }
                div {
                    height: 8px;
                    background: var(--color_white);
                    border: 1px solid var(--border_color);
                }
                .learn_text_2 {
                    line-height: 20px;
                    text-align: right;
                    color: var(--primary_color);
                }
                span {
                    white-space: nowrap;
                }
            }
        }
    }
    .notice {
        .notice_info {
            font-size: 14px !important;
        }
        span:nth-child(2) {
            color: var(--primary_color);
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 1081px) {
    .title {
        gap: 12px !important;
        padding: 0px !important;
        .title_content {
            h2 {
                /* 标题 */
                font-size: 24px !important;
                line-height: 40px !important;
            }
        }
    }
    .main {
        .right_box {
            flex: 1;
            .theme_box {
                // padding-right: 20px;
            }
            .login_form {
                margin: 8px auto !important;
                padding: 0px 20px;
                background: var(--bg_color) !important;
            }
        }
        .left_img {
            display: none;
        }
        .fgt-password {
            div {
                font-family: 'Lato';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: var(--primary_color);
            }
            /* margin-top: 0px !important; */
        }
        .btn {
            margin-top: 8px !important;
        }
        .learn_more {
            margin-top: 46px !important;
            font-size: 14px !important;
        }
    }
}
@media screen and (max-width: 400px) {
    .title {
        .title_content {
            h2 {
                /* 标题 */
                font-size: 24px !important;
                line-height: 40px !important;
            }
        }
    }
    .right_box {
    }
    .login_form {
        max-width: 375px !important;
        margin: 8px auto !important;
        background: var(--bg_color) !important;
    }
    .learn_more {
        padding-right: 0px !important;
        justify-content: center !important;
    }
}
</style>
