var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['btn cursor', _vm.type, _vm.disable || _vm.load ? 'disable' : '', _vm.size, _vm.themeColor],style:(Object.assign(_vm.styleCustom, {
            'min-width': _vm.bw ? `${_vm.bw}px` : `auto`,
            'min-height': _vm.bh ? `${_vm.bh}px` : `auto`,
            'max-height': _vm.bh ? `${_vm.bh}px` : `auto`,
            fontSize: _vm.fontSize ? `${_vm.fontSize}px` : ``,
            borderWidth: _vm.borderWidth ? `${_vm.borderWidth}px` : ``,
        })),on:{"click":_vm.emitEvent}},[(_vm.icon_left)?_c('GI',{staticClass:"icon_left",attrs:{"name":_vm.icon_left,"ws":_vm.iconSize ? _vm.iconSize : _vm.ws,"hs":_vm.iconSize ? _vm.iconSize : _vm.hs},on:{"click":_vm.emitEvent}}):_vm._e(),_vm._t("default"),(_vm.icon_right)?_c('GI',{staticClass:"icon_right",attrs:{"name":_vm.icon_right,"ws":_vm.iconSize ? _vm.iconSize : _vm.ws,"hs":_vm.iconSize ? _vm.iconSize : _vm.hs},on:{"click":_vm.emitEvent}}):_vm._e(),(_vm.load)?_c('div',{staticClass:"mask"},[_c('GI',{staticClass:"loading",attrs:{"rotate":true,"type":"png","name":"backup_loading","ws":"30","hs":"30"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }