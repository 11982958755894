<template>
    <div class="main">
        <div class="submited_box">
            <h2>{{ langJson('Successfully submitted') }}</h2>
            <p>
                {{ langJson('Your company registration has successfully been recorded and is being processed by NFTWerks') }}
            </p>
            <BTN class="btn_item" @handle="jump_root" type="active" bh="52">{{ langJson('OK') }}</BTN>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .submited_box {
        box-sizing: border-box;
        background: var(--nft_dialog_bg);
        border-radius: 16px;
        width: 422px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        .btn_item {
            width: 100% !important;
        }
        h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: var(--font_deep_color);
        }
        p {
            text-align: center;
            font-family: 'Lato';
            font-weight: 400;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
}
@media screen and (max-width: 906px) {
    .submited_box {
        width: 76.534vw !important;
        padding: 0px 0px !important;
        h2 {
            font-size: 20px;
            line-height: 32px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
            color: var(--font_deep_color);
        }
    }
}
</style>
