import { render, staticRenderFns } from "./TableMobile.vue?vue&type=template&id=f56f9fb8&scoped=true"
import script from "./TableMobile.vue?vue&type=script&lang=js"
export * from "./TableMobile.vue?vue&type=script&lang=js"
import style0 from "./TableMobile.vue?vue&type=style&index=0&id=f56f9fb8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f56f9fb8",
  null
  
)

export default component.exports