<template>
    <div class="frame">
        <div class="frame_head">
            <slot name="head"></slot>
        </div>
        <div class="frame_main">
            <slot name="main"></slot>
        </div>
        <div class="frame_foot">
            <slot name="foot"></slot>
        </div>
        <slot name="expand"></slot>
        <GT></GT>
        <Assets></Assets>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.frame{
    .frame_foot{
        width:100%;
    }
    .frame_main{
        flex:1;
        width:100%;
        overflow: hidden;
    }
    .frame_head{
        width:100%;
    }
    height:100vh;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
</style>