var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item_image",style:({
      height: `${_vm.hs}px`,
      width: `${_vm.ws}px`,
      backgroundImage: `url(/source/png/image.jpeg)`,
    })}),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"item_title"},[_vm._v(" "+_vm._s(_vm.langJson('Skechers Sundown Festival'))+" ")]),_c('div',{staticClass:"item_belong"},[_c('div',{staticClass:"belong_image",style:({
          backgroundImage: `url(/source/png/profile.jpeg)`,
        })}),_c('div',{staticClass:"belong_name"},[_vm._v(_vm._s(_vm.langJson('Asians For Asia')))])]),_c('div',{staticClass:"item_desc"},[_vm._v(" "+_vm._s(_vm.langJson('Art Meets Tech at Fast-Growing Festival Highlighting Innovative Storytellers from Around the World'))+" ")]),_c('VI',{attrs:{"coll":"334","zan":"13","view":"3343"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }