<template>
    <Model v-model="show">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    {{langJson("Propertie")}}
                </div>
                <div class="top_right cursor" @click="close">
                    <GI name="model_close" ws="24" hs="24"></GI>
                </div>
            </div>
            <FormItem v-model="form.key" class="information_item" 
            type="text" :title="langJson('Propertie name')" :placeholder="langJson('Please enter propertie name')"></FormItem>

            <FormItem v-model="form.value" class="information_item" 
            type="text" :title="langJson('Propertie value')" :placeholder="langJson('Please enter propertie value')"></FormItem>

            <div class="model_btns">
                <div class="model_btn">
                    <BTN class="btn_item" @handle="close">{{langJson("Cancel")}}</BTN>
                </div>
                <div class="btn_grap"></div>
                <div class="model_btn">
                    <BTN class="btn_item" type="active" @handle="close">{{langJson("Confirm")}}</BTN>    
                </div>
            </div>
        </div>
    </Model>
</template>
<script>

export default {
    data() {
        return {
            form:{
                key:"",
                value:""
            }
        }
    },
    model:{
        prop:"show",
        event:"change"
    },  
    props:{
        show:{
            type:Boolean,
            default:false
        },
        url:{
            type:String,
            default:""
        },
        image:{
            type:String,
            default:""
        }
    },
    methods:{
        close() {
            this.$emit("change",false)
        }
    }
}
</script>
<style lang="scss" scoped>
.model_main{
    .information_item{
        margin-top:32px;
    }
    .model_btns{
        .btn_grap{
            width:20px;
        }
        .model_btn{
            flex:1;
        }
        margin-top:32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .model_top{
        .top_right{
            cursor: pointer;
        }
        .top_left{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:100%;
    }
    padding:24px;
    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px){
    .model_main{
        
        width: 100%;
    }
}
</style>