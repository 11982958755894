<template>
    <div class="go">
        <div class="go_center">
            <div class="go_prev" @click="trigglePrev">
                <GI v-show="!!prev" name="global_previous" :ws="isPhone ? '30' : '32'" :hs="isPhone ? '14' : '16'" @click="trigglePrev"></GI>
                <div v-show="!!prev" class="go_title">{{ prev }}</div>
            </div>
            <div class="go_next" @click="triggleNext">
                <div v-show="!!next" class="go_title">
                    {{ next }}
                </div>
                <GI v-show="!!next" :name="globalTheme == 'light' ? 'global_next' : 'global_next_light'" ws="32" hs="16" @click="triggleNext"></GI>
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        prev: {
            type: String,
            default: '',
        },
        next: {
            type: String,
            default: '',
        },
    },
    methods: {
        trigglePrev() {
            this.$emit('prev')
        },
        triggleNext() {
            this.$emit('next')
        },
    },
}
</script>
<style lang="scss" scoped>
.go {
    .go_center {
        .go_next {
            display: flex;
            align-items: center;
            cursor: pointer;
            .go_title {
                margin-right: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: var(--font_deep_color);
            }
        }
        .go_prev {
            display: flex;
            align-items: center;
            cursor: pointer;
            .go_title {
                margin-left: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: var(--font_deep_color);
            }
        }
        height: 104px;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 32px;
        box-sizing: border-box;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    width: 100%;
}
@media only screen and (max-width: 750px) {
    .go_center {
        height: 56px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    .go_title {
        font-weight: 700;
        font-size: 16px !important;
        line-height: 24px !important;
        margin-left: 13px !important;
    }
}
</style>
