import http from './base'

/**
 * 获取语种
 */
let getLangType = () => {
    return http.get('/config/getLangList')
}

/**
 * 获取语言
 */
let getLanguage = () => {
    return http.get('/content/webLanguage')
}

/**
 * 获取所有币种列表
 */
let allCoins = (params) => {
    return http.get('/config/coinList', params)
}

let langVersion = () => {
    return http.get(`/config/language_version`)
}

/**
 * 获取主链配置
 */
let getChainMapping = () => {
    return http.get('/config/chainMapping')
}

/**
 * 获取网站默认配置
 */
let getWebConfig = () => {
    return http.get('/config/website')
}

/**
 * 获取nft类型
 */
let nftCategory = () => {
    return http.get('/content/nft/category')
}

/**
 * 获取平台合约
 */
let platformContract = () => {
    return http.get('/config/contractAddressList')
}

let uploadFile = (file) => {
    return http.upload('/file/upload', file)
}
/**
 * 获取网站平台合约
 */
let getPlatformContractList = (params) => {
    return http.get('/config/platformContractList', params)
}

// 获取 kyc 开关

let getKycConfigGuide = (parmas = {}) => {
    return http.get('/config/getKycConfigGuide', parmas)
}

// 获取 平台部署的合约地址
/**
 *
 * @param {*} parmas chainId 主链ID
 * @returns
 */
let platformSupport = (parmas = {}) => {
    return http.get('/product/custom/contract', parmas)
}

/**
 *获取ipfs主域名 */
let getIpfsDomain = () => {
    return http.get(`/config/ipfsDomain`)
}

// 获取 当前商户是否开启铸造
/**
 *
 */
let switchSupport = (parmas = {}) => {
    return http.get('/config/operation/switch', parmas)
}

let socialMediaConfig = (parmas = {}) => {
    return http.get('/config/socialMediaConfig', parmas)
}

// 获取平台国家配置
let configCountryConfig = (parmas = {}) => {
    return http.get('/config/countryConfig', parmas)
}

// 
let authBinding = (parmas = {}) => {
    return http.get(`/auth/binding/${parmas.userType}`)
}

// 
let authLogin = (parmas = {}) => {
    return http.postJson('/auth/login', parmas)
}

export default {
    configCountryConfig,
    getIpfsDomain,
    platformSupport,
    getLangType,
    getLanguage,
    getChainMapping,
    getWebConfig,
    langVersion,
    allCoins,
    uploadFile,
    getPlatformContractList,
    getKycConfigGuide,
    platformContract,
    nftCategory,
    switchSupport,
    socialMediaConfig,
    authBinding,
    authLogin,
}
