<template>
    <div class="second">
        <div class="second_main">
            <div class="main_left">
                <div class="step_box">
                    <div class="step_item step_active"></div>
                    <div class="step_grap"></div>
                    <div class="step_item step_active"></div>
                    <div class="step_grap"></div>
                    <div class="step_item"></div>
                </div>
                <div class="main_title">{{ langJson('Upload your artwork') }}</div>
                <div v-show="isPhone && form.artworkImg == ''" class="main_right" :style="{ backgroundImage: `url(/source/png/global_upload_border_artwork.png)` }">
                    <UploadFile v-model="form.artworkImg" type="" :view="false">
                        <GI name="artwork_upload" ws="40" hs="40"></GI>
                        <div class="upload_tip">{{ langJson('(Acceptable file format: JPEG, JPG, PNG.)') }}</div>
                        <BTN type="active" bw="213" bh="52" class="upload_btn">
                            {{ langJson('Upload your image') }}
                        </BTN>
                    </UploadFile>
                </div>
                <!-- h5 图片上传完毕 -->
                <div class="image_box_mobile" v-show="isPhone && form.artworkImg">
                    <div class="image_box" :style="{ backgroundImage: `url(${form.artworkImg})` }"></div>
                    <div class="btns">
                        <!-- 不需要单独写 本来就可以选 -->
                        <!-- <BTN type="detail" class="btn_item_1" icon_left="replace_image_mobile" @handle="cameraOpen">{{ langJson('Camera') }}</BTN> -->
                        <BTN type="detail" class="btn_item_1" icon_left="replace_image_mobile" @handle="replacePicture()">{{ langJson('Replace image') }}</BTN>
                        <BTN type="detail" class="btn_item_2" icon_left="brand_edit_mobile" @handle="go_editor()">{{ langJson('Edit') }}</BTN>
                    </div>
                </div>

                <FormItem v-model="form.artworkTitle" class="input_item" type="text" :title="langJson('Artwork title')" :matchs="['unull','maxLength']"></FormItem>

                <FormItem v-model="form.description" class="input_item" type="textarea" :title="langJson('Description')" :matchs="['unull']" height="368px"></FormItem>

                <!-- 不需要单独写 本来就可以选 -->
                <!-- <FormItem v-model="form.description" class="input_item" type="camera" :title="langJson('Description')" :matchs="['unull']" height="368px"></FormItem> -->

                <div class="left_bottom">
                    <div class="left_back" @click="$emit('prev')">
                        <GI name="artwork_back" ws="24" hs="24"></GI>
                        <div class="back_text">{{ langJson('Previous step') }}</div>
                    </div>
                    <BTN type="active" :disable="!pass" @handle="$emit('next')" :bw="isPhone ? 0 : 180" bh="52">
                        {{ langJson('Next 2/3') }}
                    </BTN>
                </div>
            </div>
            <div
                v-show="!isPhone && form.artworkImg == ''"
                class="main_right"
                :style="{
                    backgroundImage: `url(/source/png/global_upload_border_artwork.png)`,
                }"
            >
                <UploadFile ref="upImg" v-model="form.artworkImg" type="" :view="false">
                    <GI name="artwork_upload" ws="40" hs="40"></GI>
                    <div class="upload_tip">{{ langJson('(Acceptable file format: JPEG, JPG, PNG.)') }}</div>
                    <div class="ratio">{{ langJson('Recommended ratio: 1 × 1') }}</div>
                    
                    <BTN type="active" bw="213" bh="52" class="upload_btn">
                        {{ langJson('Upload your image') }}
                    </BTN>
                </UploadFile>
            </div>
            <!-- 图片上传完毕 -->
            <div
                class="image_box"
                :style="{
                    backgroundImage: `url(${form.artworkImg})`,
                }"
                v-if="!isPhone && form.artworkImg"
            >
                <div class="btns">
                    <BTN type="blank" icon_left="replace_image" @handle="replacePicture()">{{ langJson('Replace image') }}</BTN>
                    <BTN type="blank" @handle="go_editor()" icon_left="brand_edit">{{ langJson('Edit') }}</BTN>
                </div>
            </div>
        </div>

        <Editor v-model="showEditor" @update="updateImage" :url="form.artworkImg" :id="$route.params.id"></Editor>
    </div>
</template>
<script>
export default {
    data() {
        return {
            bannerConfig: {
                type: 'small',
            },
            form: {
                artworkTitle: '', // 艺术品标题
                description: '', // 描述
                artworkImg: '', // 艺术品
            },
            showEditor: false,
        }
    },
    computed: {
        pass() {
            let result = this.checkPass(this.form.artworkTitle, ['unull','maxLength'], false)
            result = result && this.checkPass(this.form.description, ['unull'], false)
            result = result && this.checkPass(this.form.artworkImg, ['unull'], false)
            return result
        },
    },
    watch: {
        form: {
            handler(newVal) {
                let { artworkTitle, description, artworkImg } = newVal
                this.$store.commit('campaignWorksSave', {
                    ...this.globalMarket.campaignWorksSave,
                    artworkTitle,
                    description,
                    artworkImg,
                })
            },
            deep: true,
        },
    },
    methods: {
        updateImage(url) {
            this.$set(this.form, 'artworkImg', url)
        },
        go_editor() {
            // let { id } = this.$route.params
            // this.jump_editor({
            //     url:this.form.artworkImg,
            //     id
            // })
            console.log(this.showEditor)

            this.showEditor = !this.showEditor
        },
        cameraOpen() {
            document.querySelector('#cameraOpen') && document.querySelector('#cameraOpen').click()
        },
        replacePicture() {
            this.$refs['upImg'] && this.$refs['upImg'].reUpload()
        },
        goEdit() {
            window.open('https://nft.chainup.com/editor/')
        },
    },
}
</script>
<style lang="scss" scoped>
.second {
    .second_main {
        .image_box {
            .btns {
                display: flex;
                justify-content: flex-end;
                gap: 16px;
                margin-right: 24px;
                margin-top: 24px;
            }

            width: 600px;
            height: 600px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 20px;
        }

        .main_right {
            .upload_btn {
                margin-top: 24px;
            }

            .upload_tip {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
                margin-top: 24px;
            }
            .ratio {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
                margin-top: 10px;
            }

            width: 600px;
            height: 572px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        .main_left {
            .left_bottom {
                .left_back {
                    .back_text {
                        font-family: 'Cabinet Grotesk';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 28px;
                        color: var(--font_deep_color);
                        margin-left: 16px;
                    }

                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 32px;
            }

            .main_title {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
                margin-top: 32px;
                text-align: center;
            }

            .input_item {
                margin-top: 32px;
            }

            .step_box {
                .step_item {
                    flex: 1;
                    height: 6px;
                    background-color: var(--border_color);
                    opacity: 0.4;
                    border-radius: 4px;
                }

                .step_grap {
                    width: 16px;
                }

                .step_active {
                    background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                }

                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            width: 100%;
            max-width: 600px;
            padding: 32px;
            box-sizing: border-box;
            background: var(--color_white);
            border-radius: 32px;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 80px;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-bottom: 138px;
    }
}

@media screen and (max-width: 1081px) {
    .second {
        .second_main {
            .image_box_mobile {
                .btns {
                    display: flex;
                    gap: 16px;
                    margin-top: 16px;

                    .btn_item_1 {
                        flex-grow: 6;
                        height: 40px !important;
                        font-size: 16px;
                    }

                    .btn_item_2 {
                        flex-grow: 4;
                        height: 40px !important;
                        font-size: 16px;
                    }
                }

                .image_box {
                    width: auto;
                    height: 295px;
                    border-radius: 20px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin: auto;
                    margin-top: 32px;
                }
            }

            .main_right {
                .upload_btn {
                    margin-top: 24px;
                }

                .upload_tip {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_modif_color);
                    margin-top: 24px;
                    padding: 0 16px;
                    text-align: center;
                }

                max-width: 600px;
                width: 100%;
                height: 295px;
                margin-top: 32px;
                margin-bottom: 32px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .main_left {
                .left_bottom {
                    .left_back {
                        .back_text {
                            font-family: 'Cabinet Grotesk';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 28px;
                            color: var(--font_deep_color);
                            margin-left: 16px;
                        }

                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 32px;
                }

                .main_title {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px;
                    color: var(--font_deep_color);
                    margin-top: 32px;
                    text-align: center;
                }

                .input_item {
                    margin-top: 32px;
                }

                .step_box {
                    .step_item {
                        flex: 1;
                        height: 6px;
                        background-color: var(--border_color);
                        opacity: 0.4;
                        border-radius: 4px;
                    }

                    .step_grap {
                        width: 16px;
                    }

                    .step_active {
                        background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    }

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                width: 100%;
                max-width: 600px;
                padding: 32px 20px !important;
                box-sizing: border-box;
                background: var(--color_white);
                border-radius: 32px;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            margin-bottom: 138px;
        }
    }
}
</style>
