<template>
    <div>
        <div class="banner" :style="{ 'background-image': `url(${bannerImage})` }">
            <!-- 暗色渐变 -->
            <img class="cover" v-if="!isPhone" src="../../../../../public/source/png/brand/RectangleBgcMobile.png" alt="" />
            <img class="cover" v-if="isPhone" src="../../../../../public/source/png/brand/RectangleBgcMobile.png" alt="" />

            <div class="down_box">
                <div class="image">
                    <img class="image_13" src="../../../../../public/source/png/brand/image_13.png" alt="" />
                    <img class="image_29" src="../../../../../public/source/png/brand/image_29.png" alt="" />
                </div>
                <!-- detail -->
                <div class="title_box">
                    <h2 class="title">{{ detail.name }}</h2>
                    <div v-if="detail.isJoin" class="participated_in">
                        <span class="participated_title">{{ langJson('Participated in') }}</span>
                        <GI class="loading" name="participated_in" ws="16" hs="16"></GI>
                    </div>
                </div>

                <span class="subtext cursor" @click="isParticipant ? jump_participant_campaign_participants_profileColl({ idActive: $route.query.id, idUser: detail.brandUserId }) : jump_brand_profile_myprofile({ idUser: detail.brandUserId })">
                    <span class="by">{{ langJson('hosted by') }}</span>
                    &nbsp;
                    <span class="byName">{{ brandUserVo.nickName }}</span>
                </span>

                <div class="compaign_detail">
                    <!-- 剩余几天 -->
                    <div class="detail_box">
                        <h2>{{ days }}</h2>
                        <div>
                            <span class="detail_item">{{ langJson('days to go') }}</span>
                        </div>
                    </div>

                    <!-- 参与人数 -->
                    <div @click="$route.query.operateStop ? '' : jumpParticiPant({ tab: 1, status: status, id: 'participants', idActive: $route.query.id })" :class="['detail_box', $route.query.operateStop ? 'notClick' : '']">
                        <h2>{{ detail.joinNum }}</h2>
                        <div class="detail_content">
                            <span class="detail_item">{{ langJson('participants') }}</span>
                            <span class="view">{{ langJson('View') }}</span>
                        </div>
                    </div>

                    <div @click="$route.query.operateStop ? '' : jumpArtWort({ tab: 2, status: status, id: 'artwork', idActive: $route.query.id })" :class="['detail_box', $route.query.operateStop ? 'notClick' : '']">
                        <h2>{{ detail.workNum }}</h2>
                        <div class="detail_content">
                            <span class="detail_item">{{ langJson('artworks') }}</span>
                            <span class="view">{{ langJson('View') }}</span>
                        </div>
                    </div>
                </div>
                <!-- 商户和没有参加的用户 -->
                <div v-if="!detail.isJoin" class="view_more">
                    <!-- 这里应该直接提交 -->

                    <!-- 商户自己不能提交艺术品 -->
                    <BTN class="btn_item" v-if="isParticipant && detail.isInvite" type="active" @handle="showSubmitArtwork = true" bh="52" :bw="isPhone ? '249' : '180'">
                        {{ langJson('Join campaign') }}
                    </BTN>

                    <BTN class="btn_item" v-if="isParticipant && !detail.isInvite" type="active" @handle="submitArtwork" bh="52" :bw="isPhone ? '249' : '180'">
                        {{ langJson('Log In') }}
                    </BTN>

                    <BTN @handle="share" v-if="detail.isInvite && !detail.isPrivate" class="btn_item" type="empty" bh="52" :bw="isPhone ? '249' : '180'">{{ langJson('Share campaign') }}</BTN>
                </div>
                <div v-if="isParticipant && detail.isJoin" class="view_more_joined">
                    <!-- 参加了会显示这个按钮 -->
                    <BTN class="btn_item" v-if="isParticipant && detail.isJoin && detail.isInvite" @handle="showSubmitArtwork = true" type="active" bh="52" :bw="isPhone ? '339' : '193'">
                        {{ langJson('Submit artwork') }}
                    </BTN>
                    <!-- 参加了就没有这个按钮  -->
                    <!-- 布尔值joined表示是否参加了，已参加就是true，未参加就是false -->
                    <!-- 参加才会显示 -->
                    <div class="down_btns">
                        <BTN @handle="unjoinHand()" v-if="detail.isJoin && detail.isInvite" class="btn_item" type="empty" bh="52" :bw="isPhone ? '160' : '193'">{{ langJson('No longer joining') }}</BTN>
                        <BTN @handle="share" v-if="detail.isInvite && !detail.isPrivate" class="btn_item" type="empty" bh="52" :bw="isPhone ? '160' : '193'">{{ langJson('Share campaign') }}</BTN>
                    </div>
                </div>
            </div>
        </div>
        <!-- 对话框使用模板 -->
        <Model :padding="'24px'" v-model="showSubmitArtwork">
            <SubmitArtTip ref="SubmitArtTip" slot="main" @cancel="cancel" :detail="detail" :titleActive="detail.name" @computed="handSubArtTip"></SubmitArtTip>
        </Model>

        <Model :padding="'24px'" v-model="showUnjoinTip">
            <UnjoinTip :id="id" :titleActive="titleActive" @cancel="cancel2" slot="main" @complete="$emit('change')" />
        </Model>
    </div>
</template>

<script>
import { getCookie } from '@/assets/js/cookie'
export default {
    props: {
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            refer: 1,
            showSubmitArtwork: false,
            isBrand: false,
            status: '',

            showUnjoinTip: false,
            id: '',
            titleActive: '',
            loginStatus: false,
        }
    },
    computed: {
        bannerImage() {
            try {
                let isMobile = this.browser().mobile
                let { banner, appBanner } = this.detail
                return isMobile ? (appBanner ? appBanner : banner) : banner
            } catch (err) {
                console.log(err)
            }
        },
        brandUserVo() {
            try {
                let { brandUserVo } = this.detail
                if (brandUserVo) {
                    return brandUserVo
                } else {
                    return {}
                }
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        days() {
            try {
                let obj = this.refer && this.remainTime(this.detail.endTime)
                return obj.days ? obj.days - 0 : ''
            } catch (error) {
                return ''
            }
        },
    },
    mounted() {
        this.$bus.$off('JoinCampaign')
        this.$bus.$on('JoinCampaign', () => {
            this.showSubmitArtwork = true
        })
        this.$bus.$off('logIn')
        this.$bus.$on('logIn', () => {
            this.submitArtwork()
        })
        this.$bus.$off('submitArtWork')
        this.$bus.$on('submitArtWork', () => {
            this.showSubmitArtwork = true
        })

        // 商户进来的
        this.status = this.$route.query.status

        this.loginStatus = false
        if (getCookie('token')) {
            this.loginStatus = true
        }
        if (this.$route.query.submit) {
            this.showSubmitArtwork = true
        }
    },
    methods: {
        submitArtwork() {
            let id = this.$route.query.id
            let isLogin = this.checkLogin((result) => {
                if (!result) {
                    this.jump_participant_compaign_preview({
                        id,
                        submit: 1,
                    })
                } else {
                    this.jump_participant_campaign_artwork_submit(id)
                }
                sessionStorage.setItem('noSaveHistory', '')
            }, true)
            if (!isLogin) {
                localStorage.setItem('userType', 0)
                this.$emit('computed')
                localStorage.setItem('backActive', 'true')
            } else {
                localStorage.setItem('loginBgUrl', '')
            }
        },
        tiplogin() {
            console.log(this.$refs)
            this.$store.commit('loginShow', true)
        },
        handSubArtTip() {
            localStorage.setItem('loginBgUrl', this.detail.banner)
        },
        unjoinHand() {
            this.id = this.detail.id
            this.titleActive = this.detail.name
            this.showUnjoinTip = true
        },
        share() {
            this.$store.commit('changeShowShare', true)
            this.$store.commit('shareObj', {
                ...this.globalConfig.shareObj,
                img: this.detail.banner,
                title: this.isParticipant ? this.langJson('Share campaign') : this.langJson('Share your Campaign'),
                url: this.isParticipant ? `` : `/participant/campaign/preview?id=${this.detail.id}`,
                linkName: this.langJson('Campaign link'),
            })
        },
        jumpParticiPant(tab) {
            this.isParticipant ? this.jump_participant_compaign_ParticipantsNFTs(tab) : this.jump_brand_compaign_submissions(tab)
        },
        jumpArtWort(tab) {
            this.isParticipant ? this.jump_participant_compaign_ParticipantsNFTs(tab) : this.jump_brand_compaign_submissions(tab)
        },
        cancel(val) {
            this.showSubmitArtwork = val
        },
        cancel2(close) {
            this.showUnjoinTip = close
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.banner {
    height: 785px;
    /* background-image: url('../../../../../public/source/png/brand/Rectangle.png'); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .down_box {
        position: absolute;
        // top: 349px;
        top: 72px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        .image {
            // position: relative;
            width: 100%;
            height: 292.21px;
            flex-shrink: 0;
            box-sizing: border-box;

            .image_13 {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: 583.68px;
                height: 169.92px;
                top: 0px;
            }
            .image_29 {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: 600px;
                height: 207px;
                top: 85.21px;
            }

            @media screen and (max-width: 1080px) {
                height: 184.46px;
                .image_13 {
                    width: 291.84px;
                    height: 84.96px;
                    top: -15px;
                }
                .image_29 {
                    width: 300px;
                    height: 103.5px;
                    top: 30px;
                }
            }
        }

        .title_box {
            display: flex;
            align-items: center;
            gap: 28px;
            .title {
                font-family: 'Cabinet Grotesk';
                color: #ffffff;
                font-weight: 700;
                font-size: 56px;
                line-height: 64px;
                // width:80%;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
            }
            .participated_in {
                background: var(--bg4);
                padding: 8px 16px;
                border-radius: 32px;
                display: flex;
                align-items: center;
                gap: 8px;
                /* width: 133px; */
                height: 32px;
                box-sizing: border-box;
                .participated_title {
                    font-family: 'Lato';
                    font-style: normal;
                    /* font-weight: 600; */
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: var(--primary_color);
                    white-space: nowrap;
                }
            }
        }

        .subtext {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            color: #ffffff;
            display: block;
            margin-top: 16px;
            .by {
                font-family: 'Lato';
                font-style: normal;
                /* font-weight: 400; */
                font-size: 20px;
                line-height: 24px;
                color: #d4d3d2;
            }
            .byName {
                font-family: 'Lato';
                font-style: normal;
                /* font-weight: 700; */
                font-size: 20px;
                line-height: 24px;
                color: #d4d3d2;
            }
        }

        .compaign_detail {
            min-height: 116px;
            max-width: 1080px;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: space-evenly;
            background: var(--color_white);
            backdrop-filter: blur(8.5px);
            border-radius: 16px;
            margin-top: 26px;
            padding-top: 20px;
            padding-bottom: 20px;
            box-sizing: border-box;
            .detail_box {
                .detail_item {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                border-right: 1px solid var(--border_color);
                cursor: pointer;
                .detail_content {
                    display: flex;
                    align-items: flex-end;
                    gap: 8px;
                }
                h2 {
                    font-family: 'Cabinet Grotesk';
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px;
                    /* identical to box height, or 120% */

                    /* Neutral/1 */

                    color: var(--font_deep_color);
                }

                p {
                    margin-top: 4px;
                }
                .view {
                    background-image: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    -webkit-background-clip: text;
                    color: transparent;
                    font-size: 14px;
                    line-height: 20px;
                }
                &:last-child {
                    border-right: none;
                }
            }
            .notClick {
                cursor: not-allowed !important;
            }
        }

        .view_more {
            /* width: 397px; */
            margin-top: 33px;
            display: flex;
            gap: 23px;
            width: 100%;
            justify-content: center;
        }
        .view_more_joined {
            display: flex;
            margin-top: 33px;
            gap: 23px;
            .down_btns {
                display: flex;
                gap: 23px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .banner {
        height: 751px;
    }

    .down_box {
        text-align: center;
        // top: 112px !important;
        // top: auto !important;
        /* bottom: 28px !important; */
        padding-left: 20px;
        padding-right: 20px;
        bottom: 103px !important;
        .title_box {
            display: flex;
            align-items: center;
            gap: 12px !important;
            flex-direction: column;
            .title {
                font-family: 'Cabinet Grotesk';
                color: #ffffff;
                font-weight: 700;
                font-size: 40px !important;
                line-height: 48px !important;

                // width:80%!important;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
            }
            .participated_in {
                background: var(--bg4);
                padding: 8px 16px;
                border-radius: 32px;
                display: flex;
                align-items: center;
                gap: 8px;
                /* width: 133px; */
                height: 32px;
                box-sizing: border-box;
                .participated_title {
                    font-family: 'Lato';
                    font-style: normal;
                    /* font-weight: 600; */
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: var(--primary_color);
                }
            }
        }
        .subtext {
            margin-top: 8px !important;
        }

        .compaign_detail {
            min-height: 116px;
            border-radius: 12px !important;
            margin-top: 42px !important;
            .line {
                width: 100%;
            }

            .detail_box {
                h2 {
                    font-size: 20px !important;
                    line-height: 32px !important;
                }
                .detail_content {
                    gap: 0px !important;
                    align-items: center !important;
                }
                div {
                    height: 44px;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 16px;
                    }
                }
            }
        }

        .view_more {
            flex-direction: column !important;
            align-items: center;
            margin-top: 32px !important;
            gap: 15px !important;
            .upper_btn {
                display: flex;
                gap: 23px;
            }
            .down_btn {
                display: flex;
                gap: 23px;
            }
        }
        .view_more_joined {
            display: flex;
            flex-direction: column;
            margin-top: 24px !important;
            gap: 15px;
            .down_btns {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 16px !important;
            }
        }
    }
}
</style>
