<template>
    <div class="personal">
        <Go :prev="langJson('Back')" @prev="back"></Go>
        <div class="personal_center">
            <div class="personal_banner">
                <div class="banner" :style="{
                    backgroundImage:`url(https://w.wallhaven.cc/full/j3/wallhaven-j3m8y5.png)`
                }"></div>

            </div>
            <div class="personal_portrait">
                <div class="portrait" :style="{
                    backgroundImage:`url(https://w.wallhaven.cc/full/kx/wallhaven-kx98xd.jpg)`
                }"></div>
            </div>

            <div class="personal_name">Participant name</div>
            <div class="personal_detail">
                <div class="personal_address">
                    abc1344...cde
                    <GI class="copy" name="personal_copy" ws="24" hs="24"></GI>
                </div>
                <div class="personal_email">abc@gmail.com</div>
            </div>

            <div class="personal_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl sit ullamcorper ipsum, mattis sodales faucibus. Varius sapien enim ipsum 
            </div>

            
            <div class="personal_btns"> 
                <BTN class="personal_set" type="main" @handle="jump_collection_belong">
                    {{langJson("My collections")}}
                </BTN>
                <BTN class="personal_set" type="main" @handle="jump_setting">
                    {{langJson("Edit profile")}}
                </BTN>
            </div>
            

            <div class="personal_main">
                <Tab type="line" align="center" v-model="tab" :list="tablist" @change="switchTab"></Tab>

                <StepRun ref="tab">
                    <Collected slot="tab1"></Collected>
                    <Created slot="tab2"></Created>
                    <Favorited slot="tab3"></Favorited>
                    <Recommend slot="tab4"></Recommend>
                    <Winning slot="tab5"></Winning>
                    <Activity slot="tab6"></Activity>
                    <Offers slot="tab7"></Offers>
                </StepRun>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab: 1,
            tablist: [
                {
                    value: 1,
                    lable: this.langJson("Collected"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                },
                {
                    value: 2,
                    lable: this.langJson("Created"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                },
                {
                    value: 3,
                    lable: this.langJson("Favorited"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                },
                {
                    value: 4,
                    lable: this.langJson("Recommend"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                },
                {
                    value: 5,
                    lable: this.langJson("Winning"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                },
                {
                    value: 6,
                    lable: this.langJson("Activity"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                },
                {
                    value: 7,
                    lable: this.langJson("Offers"),
                    icon: "personal_collect_list",
                    iconActive: "personal_collect_list_active"
                }
            ]
        };
    },
    mounted() {
        if(this.$route.params.tab) {
            this.tab = parseInt(this.$route.params.tab)
            this.switchTab(this.tab)
        }
    },
    methods:{
        switchTab(val) {
            this.tablist.find((item,index) => {
                if(item.value == val){
                    this.$refs.tab.jump(index)
                    return true
                }else{
                    return false
                }
            })
            this.jump_personal("0x55959f0D5e1b7DC57fe4079e596b8BBafFF123B1",val)
        }
    }
}
</script>
<style lang="scss" scoped>
.personal{
    .personal_center{
        .personal_main{
            margin-top:32px;
            width:100%;
        }
        .personal_btns{
            margin-top:16px;
            display: flex;
            .personal_set{
                margin:0 10px;
            }
        }
        .personal_desc{
            margin-top:16px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color:var(--font_color);
            width:100%;
            max-width:624px;
            padding:0 24px;
            box-sizing: border-box;
        }
        .personal_detail{
            .personal_email{
                margin-left:40px;
            }
            .personal_address{
                margin-right:40px;
                display: flex;
                align-items: center;
                .copy{
                    margin-left:4px;
                    cursor: pointer;
                }
            }
            display: flex;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top:4px;
            color:var(--font_color);
        }
        .personal_name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            margin-top:16px;
        }
        .personal_portrait{
            .portrait{
                width:120px;
                height:120px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-color:var(--color_white);
                position: absolute;
                bottom:0;
                left:-60px;
            }
            height:60px;
            position: relative;
        }
        .personal_banner{
            .banner{
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                width:100%;
                height:100%;
                background-color:var(--color_white);
            }
            border-radius: 24px;
            overflow: hidden;
            height:280px;
            width:100%;
        }
        width:100%;
        max-width:var(--max_width);
        margin: auto;
        margin-top:32px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
}
</style>