<template>
    <div>
        <div>
            <div class="form">
                <div class="header">
                    <div class="header_left">
                        <h2>{{ langJson('Verification') }}</h2>
                        <!-- <GI name="profile_unverified" ws="117"></GI> -->
                        <BTN :icon_right="status == 1 ? 'profile_verification_success' : 'profile_noverification'" :type="status != 1 ? 'disable' : 'success'" bh="45" :bw="status == 0 ? 170 : 150">{{ status == 1 ? langJson('Verified') : status == 2 ? langJson('Unverified') : langJson('Pending Review') }}</BTN>
                    </div>
                    <div class="header_right">
                        <BTN v-if="!isPhone" type="detail" bw="96" @handle="showStatus">{{ langJson('Edit') }}</BTN>
                        <BTN v-if="isPhone" type="detail" bw="77" bh="48" @handle="showStatus">{{ langJson('Edit') }}</BTN>
                    </div>
                </div>
                <div class="form_box">
                    <div class="form_input_box">
                        <div class="form_input">
                            <FormItem v-model="form.name" :disabled="editShow" class="information_item" type="text" :title="langJson('Your name')"></FormItem>
                            <FormItem v-model="form.contactNo" :disabled="editShow" class="information_item" type="text" :title="langJson('Contact no.')"></FormItem>
                        </div>
                        <div class="form_input">
                            <FormItem v-model="form.companyName" :disabled="editShow" class="information_item" type="text" :title="langJson('Company name')"></FormItem>
                            <FormItem v-model="form.designation" :disabled="editShow" class="information_item" type="text" :title="langJson('Designation')"></FormItem>
                        </div>
                        <div class="line"></div>
                    </div>

                    <div>
                        <h2 class="additiional_contract">{{ langJson('Additional contact') }}</h2>
                        <div class="form_input_box">
                            <div class="form_input">
                                <FormItem v-model="form.contactName" :disabled="editShow" class="information_item" type="text" :title="langJson('Full name')"></FormItem>
                                <FormItem v-model="form.contactContactNo" :disabled="editShow" class="information_item" type="text" :title="langJson('Contact no.')"></FormItem>
                            </div>
                            <div class="form_input">
                                <FormItem v-model="form.contactCompanyName" :disabled="editShow" class="information_item" type="text" :title="langJson('Company name')"></FormItem>
                                <FormItem v-model="form.contactDesignation" :disabled="editShow" class="information_item" type="text" :title="langJson('Designation')"></FormItem>
                            </div>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="form_btns">
                        <BTN :type="editShow ? 'disable' : 'active'" bh="52" bw="308" @handle="BtnSubmit" :load="submit_load">{{ editShow ? langJson('Under Review') : langJson('Submit') }}</BTN>
                        <span>{{ langJson('We’ll check to make sure it follows our Terms & Privacy.Please allow 1-3 business days for a response.') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bannerConfig: {
                type: 'big',
            },
            list: [
                {
                    lable: this.langJson('USD'),
                    value: '',
                },
            ],
            form: {
                name: '',
                contactNo: '',
                companyName: '',
                designation: '',
                contactName: '',
                contactContactNo: '',
                contactCompanyName: '',
                contactDesignation: '',
            },
            editShow: true,
            status: 0,
            submit_load:false
        }
    },
    created() {
        this.status = 0
        this.editShow = true
    },
    mounted() {
        this.init()
    },
    methods: {
        // 初始化 通知类型列表
        async init() {
            try {
                let { dispatch } = this.$store
                let res = await dispatch('contactInformation')
                if (res && res.code == 200) {
                    if (res.data && res.data instanceof Object) {
                        let obj = this.form
                        for (let key in obj) {
                            this.$set(this.form, key, res.data[key])
                        }
                        if (res.data.id || res.data.id == 0) {
                            this.$set(this.form, 'id', res.data.id)
                        }
                        if (res.data.status) {
                            this.status = res.data.status
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },
        showStatus() {
            if (this.submit_load) {
                this.pushMsg('warning', this.langJson('Being committed, not clickable'))
                return
            }
            this.editShow = !this.editShow
        },
        async BtnSubmit() {
            if (this.editShow) {
                this.pushMsg('warning', this.langJson('It is not in an editing state and cannot be modified'))
                return
            }
            this.submit_load = true
            try {
                let { dispatch } = this.$store
                let res = await dispatch('updateInformation', { ...this.form })
                if (res && res.code == 200) {
                    this.pushMsg('success', this.langJson('Modify successfully'))
                }
            } catch {}
            this.editShow = true
            this.submit_load = false
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 156px;
    .header {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header_left {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            h2 {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
            }
            /deep/ .icon_right {
                margin-left: 0 !important;
            }
        }
    }
    .form_box {
        gap: 54px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .form_input_box {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .form_input {
                display: flex;
                width: 100%;
                gap: 32px;
                .information_item {
                    width: 100%;
                }
            }
            .line {
                width: 100%;
                border-bottom: 1px solid var(--border_color);
            }
        }

        .additiional_contract {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 16px;
            font-family: 'Inter';
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
        }
        .form_btns {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            margin: auto;
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: var(--font_deep_color);
            }
        }
    }
}

@media only screen and (max-width: 1080px) {
    .form {
        margin: 0px;
        max-width: 100%;
        padding: 20px !important;
    }
    .header {
        justify-content: flex-start !important;
        flex-direction: column;
        align-items: flex-start !important;
        gap: 16px;
        .header_left {
            h2 {
                font-size: 32px !important;
            }
        }
    }
    .form_input {
        flex-direction: column;
    }
}
</style>
