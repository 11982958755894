<template>
    <div class="select_btns" :class="[globalThemeColor]">
        <div :class="['btn_item', !disabled && current.value === item.value && type, size]" v-for="(item, index) in list" :key="index" @click="selectItem(item.value)" v-show="disabled ? current.value === item.value : true">
            {{ item.lable }}
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        type: {
            type: String,
            default: 'default',
        },
        value: {
            type: [Number, String],
            default: '',
        },
        size: {
            type: String,
            default: 'mini',
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        current() {
            let result = this.list.find((item) => {
                return item.value === this.value
            })
            return result
                ? result
                : {
                      lable: this.langJson('All'),
                      value: '',
                  }
        },
    },
    methods: {
        selectItem(val = '') {
            if (this.disabled) return
            this.$emit('change', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.select_btns {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    &.theme_dark {
        .btn_item {
            color: #ffffff;
        }
        .contract {
            color: var(--primary_color);
        }
    }
    .btn_item {
        margin-right: 20px;
        background: var(--bg_color);
        border-radius: 24px;
        color: var(--font_modif_color);
        border: 1px solid transparent;
        cursor: pointer;
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .mini {
        padding: 6px 12px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        border-radius: 4px;
    }
    .medium {
        padding: 10px 20px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        border-radius: 6px;
    }
    .big {
        padding: 12px 24px;
        font-family: 'Lato';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        line-height: 16px;
        border-radius: 24px;
    }
    .default {
        background: #e0f0ff;
        color: var(--primary_color);
    }

    .main {
        background: var(--main_color);
        color: #fff;
    }
    .contract {
        background: #eae0ff;
        border: 1px solid var(--primary_color);
        border-radius: 24px;
    }
}
</style>
