var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"unleash"},[_c('div',{staticClass:"unleash_title",style:({ backgroundImage: `url(/source/png/unleash_title.png)` })},[_vm._v(_vm._s(_vm.langJson('Earn with talent and creativity')))]),_c('div',{staticClass:"unleash_desc"},[_vm._v(_vm._s(_vm.langJson('You are only limited by your imagination')))]),_c('div',{staticClass:"unleash_btns"},[_c('div',{staticClass:"unleash_btnitem"},[_c('BTN',{staticClass:"btn_item",attrs:{"fontSize":_vm.isPhone?'16':'18',"type":"active"},on:{"handle":function($event){return _vm.jumpOther('/brand/createCampaign/createOverview')}}},[_vm._v(_vm._s(_vm.langJson('Unleash your creativity')))])],1),_c('div',{staticClass:"unleash_btnitem",on:{"click":function($event){return _vm.jumpOther('/brand/campaign/index')}}},[_c('div',{staticClass:"border_tab"},[_vm._v(" "+_vm._s(_vm.langJson('Explore campaigns'))+" ")])])]),_c('div',{staticClass:"unleash_bgs"},[_c('div',{staticClass:"bg_item bg_line_1",style:({ backgroundImage: `url(/source/png/unleash_bg/line_1.png)` })}),_c('div',{staticClass:"bg_item bg_line_2",style:({ backgroundImage: `url(/source/png/unleash_bg/line_2.png)` })}),_vm._m(0)]),_c('div',{staticClass:"start_bottom"},[_c('div',{staticClass:"bottom_home",style:({
        backgroundImage: `url(/source/png/unleash_custom/unleash_customer.png)`,
      })}),(!_vm.isPhone)?_c('div',{staticClass:"shadow"}):_vm._e(),_c('div',{staticClass:"title_bg",style:({
        backgroundImage: `url(/source/png/unleash_custom/unleash_title.png)`,
      })}),(!_vm.isPhone)?_c('div',{staticClass:"icon_bg",style:({
        backgroundImage: `url(/source/png/unleash_custom/unleash_icon.png)`,
      })}):_vm._e(),_c('div',{staticClass:"bottom_content"},[(!_vm.isPhone)?_c('div',{staticClass:"bottom_title"},[_vm._v(_vm._s(_vm.langJson('Engage your customers')))]):_vm._e(),(_vm.isPhone)?_c('div',{staticClass:"bottom_title"},[_vm._v(" "+_vm._s(_vm.langJson('Engage your'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.langJson('customers'))+" ")]):_vm._e(),_c('div',{staticClass:"bottom_desc"},[_vm._v(" "+_vm._s(_vm.langJson('Create campaigns for your brand or event to captivate your customers and communities'))+" ")]),_c('div',{staticClass:"bottom_btns"},[_c('BTN',{staticClass:"btn_item",attrs:{"type":"active","bw":_vm.isPhone ? 255 : 204,"bh":_vm.isPhone ? 48 : 52},on:{"handle":function($event){return _vm.jumpOther('/brand/createCampaign/createOverview')}}},[_vm._v(_vm._s(_vm.langJson('Create Campaign')))]),_c('BTN',{staticClass:"btn_item",attrs:{"type":"main","bw":_vm.isPhone ? 255 : 200,"bh":_vm.isPhone ? 48 : 52},on:{"handle":function($event){return _vm.jumpOther('/brand/campaign/index')}}},[_vm._v(_vm._s(_vm.langJson('Explore Artwork')))])],1)])]),_c('div',{staticClass:"unleash_bottom"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.langJson('The cheapest & easiest way possible'))+" ")]),_c('div',{staticClass:"title_bg",style:({ backgroundImage: `url(/source/png/index_article_title.png)` })}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.langJson("It's as simple as taking a picture. NFTWerks makes it as seamless as possible for you.")))]),_c('BTN',{staticClass:"btn_item",attrs:{"type":"active","bw":"286","bh":"52"},on:{"handle":function($event){return _vm.jumpOther('/brand/campaign/index')}}},[_vm._v(_vm._s(_vm.langJson('Let’s get started !')))]),_c('div',{staticClass:"home_bg",style:({
        backgroundImage: `url(/source/png/index_home.png)`,
      })}),(_vm.isPhone)?_c('div',{staticClass:"shadow"}):_vm._e(),_c('div',{staticClass:"mycursor",style:({
        backgroundImage: `url(/source/png/unleash_bg/cursor_1.png)`,
      })})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg_center"},[_c('img',{staticClass:"bg_star_1",attrs:{"src":"/source/png/unleash_bg/star_1.png"}}),_c('img',{staticClass:"bg_star_2",attrs:{"src":"/source/png/unleash_bg/star_2.png"}}),_c('img',{staticClass:"bg_image_1",attrs:{"src":"/source/png/unleash_bg/image_1.png"}}),_c('img',{staticClass:"bg_image_2",attrs:{"src":"/source/png/unleash_bg/image_2.png"}}),_c('img',{staticClass:"bg_image_3",attrs:{"src":"/source/png/unleash_bg/image_3.png"}})])
}]

export { render, staticRenderFns }