<template>
    <div>
        <div class="form">
            <div class="form_box">
                <div class="title">
                    <h2>{{ langJson('Smart Contract Deployer') }}</h2>
                    <BTN v-if="!form.contractAddress" :disable="!isEdit" @handle="isEdit = false" type="active">{{ langJson('Edit') }}</BTN>
                </div>

                <div class="form_input">
                    <FormItem v-model="form.name" :disabled="!namePass" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Full name of contract ')"></FormItem>
                </div>

                <div class="form_input">
                    <FormItem v-model="form.abbreviation" :disabled="!namePass" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Contract abbreviation ')"></FormItem>
                </div>

                <div v-if="!form.contractAddress" class="form_switch">
                    <MintSel class="status_item" :disabled="!!form.contractAddress || deploy_load" 
                    v-model="switchval" type="contract" :list="switchlist" size="big"></MintSel>
                </div>

                <div class="form_input">
                    <FormItem v-model="form.author" class="information_item" type="text" 
                    :disabled="switchval == 2"
                    :matchs="['unull']" :title="langJson('Royalty payment address')"></FormItem>
                </div>
                <div class="form_input">
                    <FormItem v-model="form.royalty" class="information_item" type="text" 
                    :disabled="switchval == 2"
                    :matchs="['unull', 'float']" :title="langJson('Royalty ratio (keep two decimal places)')">
                        <span slot="text_append">%</span>
                    </FormItem>
                </div>

                <FormItem v-model="form.chainId" :disabled="showBtn" class="form_item" :list="chainlist" type="select" :matchs="['unull']" :title="langJson('Network')" :placeholder="langJson('Select Network')"></FormItem>

                <div class="form_input">
                    <FormItem v-model="form.contractAddress" placeholder="Please deploy a contract first,this field will auto-populate once complete" :disabled="true" class="information_item" type="text" :matchs="['unull']" :title="langJson('Contract Address ')"></FormItem>
                </div>

                <div class="select_contract">
                    <span>{{ langJson('Collective contract type') }}</span>
                    <div>
                        <MintSel class="status_item" :disabled="!!form.contractAddress || deploy_load" v-model="form.contractType" type="contract" :list="statuslist" size="big"></MintSel>
                    </div>
                </div>
                <hr />
                <div class="contract" v-if="!form.contractAddress">
                    <div>
                        <BTN :disable="pass || !setRule" :load="deploy_load" @handle="isPhone ? deploy_handle() : chainChange(form.chainId, getDeploy)" type="active" bh="52" bw="209">{{ langJson('Deploy Contract') }}</BTN>
                    </div>
                </div>

                <div class="contract" v-else>
                    <div>
                        <BTN :disable="!setRule" :load="deploy_load" @handle="isPhone ? royalty_handle() : chainChange(form.chainId, setRoyalty)" type="active" bh="52" bw="209">{{ langJson('Update Royalty') }}</BTN>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns">
            <div class="next_step">
                <span>{{ langJson('Your changes have been saved') }}</span>
                <div>
                    <BTN @handle="goSticker" type="active" bh="52" bw="152" class="BtnLeft">{{ langJson('Last: Sticker library') }}</BTN>
                    <BTN @handle="goPromotion" type="active" bh="52" bw="195" v-if="$route.query.from === 'edit'">{{ langJson('Next: Promotion') }}</BTN>
                </div>
            </div>
        </div>


        <DeployTip v-model="deploy_tip" @confirm="chainChange(form.chainId, getDeploy)"></DeployTip>
        <DeployTip v-model="royalty_tip" @confirm="chainChange(form.chainId, setRoyalty)"></DeployTip>
    </div>
</template>

<script>
let { saveNum } = require('h-token-staking/src/utils')
export default {
    data() {
        return {
            deploy_tip:false,
            royalty_tip:false,
            form: {
                name: '',
                abbreviation: '',
                contractType: '721',
                contractAddress: '',
                blockHeight: '',
                author: '',
                royalty: '',
            },
            statuslist: [
                {
                    lable: 'ERC721',
                    value: '721',
                },
                {
                    lable: 'ERC1155',
                    value: '1155',
                },
                // {
                //     lable: 'ERC2891',
                //     value: 'ERC2891',
                // }
            ],
            datalist: [
                {
                    lable: 'Deploy Contract',
                    value: 'Deploy Contract',
                },
            ],
            btnShow: false,
            deploy_load: false,
            isEdit: true,
            switchval:1
        }
    },
    watch: {
        switchval(val) {
            if(val == 1) {
                this.$set(this.form,"author","")
                this.$set(this.form,"royalty","")
            }else if(val == 2) {
                this.$set(this.form,"author","0xcEF4660E0C8d4dE394393fce306137f3973eEA46")
                this.$set(this.form,"royalty","0")
            }
        },
        form: {
            handler(newVal) {
                let { name, abbreviation, contractType, chainId, contractAddress, blockHeight, author, royalty } = newVal
                royalty = saveNum(royalty, 2)
                royalty = isNaN(royalty) ? '' : royalty
                let obj = {
                    name,
                    abbreviation,
                    contractType,
                    chainId,
                    contractAddress,
                    blockHeight,
                    royaltyAddress: author,
                    royaltyProportion: royalty,
                }
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    campaignContract: obj,
                })
            },
            deep: true,
        }
    },
    inject: ['switchTab'],
    created() {
        this.btnShow = false
        this.initData()
    },
    computed: {
        switchlist() {
            return [
                {
                    lable: this.langJson("Custom Royalty"),
                    value: 1,
                },
                {
                    lable: this.langJson("Default Royalty"),
                    value: 2,
                }
            ]
        },
        namePass() {
            return !this.form.contractAddress && !this.isEdit
        },
        showDisable() {
            if (this.$route.query.from === 'edit') {
                return true
            }
            return false
        },
        chainlist() {
            try {
                let result = this.chainMapping.map((item) => {
                    return {
                        lable: item.chainName,
                        value: item.id,
                    }
                })
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
        showBtn() {
            if (this.$route.query.from === 'edit' || this.btnShow) {
                return true
            }
            return false
        },
        pass() {
            if (!this.form.name || !this.form.abbreviation || !this.form.royalty || !this.form.author || this.$route.query.from === 'edit') {
                return true
            }
            return false
        },
        setRule() {
            try {
                let { name, abbreviation, royalty, author } = this.form
                let result = this.checkPass(name, ['unull', 'maxLength'], false) && this.checkPass(abbreviation, ['unull', 'maxLength'], false) && this.checkPass(author, ['unull'], false) && this.checkPass(royalty, ['unull', 'float'], false)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    methods: {
        deploy_handle() {
            this.$emit("saveDraft")
            this.deploy_tip = true
        },
        royalty_handle() {
            this.$emit("saveDraft")
            this.royalty_tip = true
        },

        async setRoyalty() {
            this.$store.commit('cleanTips', [])
            this.deploy_load = true
            try {
                let { store } = await this.storeState()
                let pass = await store.isOwner(this.form.contractAddress)
                if (!pass) {
                    this.deploy_load = false
                    this.pushMsg('error', this.langJson('No permission'))
                    return
                }

                let result = await store.setDefaultRoyalty({
                    nft: this.form.contractAddress,
                    author: this.form.author,
                    royalty: saveNum(this.form.royalty, 2),
                })
                if (result) {
                    this.pushMsg('success', this.langJson('Update success'))
                } else {
                    this.pushMsg('error', this.langJson('Update fail'))
                }
            } catch (err) {
                this.pushMsg('error', this.langJson('Update fail'))
            }
            this.deploy_load = false
        },
        async getDeploy() {
            this.$store.commit('cleanTips', [])
            this.deploy_load = true
            try {
                let { store } = await this.storeState()
                this.form.blockHeight = await store.web3.eth.getBlockNumber()
                let result = await store.nftDeploy({
                    contractType: this.form.contractType,
                    name: this.form.name,
                    symbol: this.form.abbreviation,
                    author: this.form.author,
                    royalty: saveNum(this.form.royalty, 2),
                })
                console.log(result)
                if (result) {
                    this.$set(this.form, 'contractAddress', result.nft)
                    this.pushMsg('success', this.langJson('successful deployment'))
                    this.btnShow = true
                } else {
                    this.pushMsg('error', this.langJson('error deployment'))
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('warning', this.langJson('Please try again'))
            }
            this.deploy_load = false
        },
        async chainChange(val, fn = () => {}) {
            try {
                let result = await this.connect()
                if (!result) {
                    this.pushMsg('warning', this.langJson('Please connect wallet'))
                    return
                }
                let chain = this.chainMapping.find((item) => {
                    return item.id == val
                })
                await this.targetChain(chain.id, fn)
            } catch (err) {
                console.log(err)
            }
        },
        initData() {
            if (this.compaignDetail && this.compaignDetail.campaignContract) {
                let { name, abbreviation, contractType, contractAddress, chainId, blockHeight, royaltyAddress, royaltyProportion } = this.compaignDetail.campaignContract
                royaltyProportion = isNaN(royaltyProportion) ? '' : royaltyProportion
                if (name || abbreviation) {
                    this.$set(this.form, 'name', name)
                    this.$set(this.form, 'abbreviation', abbreviation)
                    this.$set(this.form, 'chainId', chainId)
                    this.$set(this.form, 'contractAddress', contractAddress)
                    this.$set(this.form, 'contractType', contractType)
                    this.$set(this.form, 'blockHeight', blockHeight)
                    this.$set(this.form, 'author', royaltyAddress)
                    this.$set(this.form, 'royalty', royaltyProportion)
                }
            }

            // 如果没有选择主链 设置默认主链
            if (!!this.chainlist.length && !this.form.chainId) {
                this.$set(this.form, 'chainId', this.chainlist[0].value)
            }
        },
        updateName(name) {
            if (this.compaignDetail && this.compaignDetail.campaignContract) {
                let { contractAddress } = this.compaignDetail.campaignContract
                if (contractAddress) {
                    return
                }
            }

            let abbreviation = ''
            if (name) {
                let tmp = name.split(' ')
                if (tmp.length > 1) {
                    abbreviation = tmp
                        .map((item) => {
                            if (item) {
                                return item[0]
                            } else {
                                return ''
                            }
                        })
                        .join('')
                } else {
                    abbreviation = name.slice(0, 6)
                }
            }
            this.$set(this.form, 'name', name)
            this.$set(this.form, 'abbreviation', abbreviation)
        },
        goSticker() {
            this.switchTab(4)
        },
        goPromotion() {
            this.switchTab(6)
        },
    },
}
</script>

<style lang="scss" scoped>
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    /* margin-top: 32px; */
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    .form_box {
        /* width: 483px; */
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .title {
            h2 {
                font-weight: 700;
                font-size: 24px;
                font-family: 'Inter';
                line-height: 40px;
                color: var(--font_deep_color);
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .information_item {
            width: 100%;
        }

        .form_input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
        }
        hr {
            background: #c9ccdd;
            opacity: 0.2;
        }
        .contract {
            display: flex;
        }
    }
    .select_contract {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .form_ERC1155 {
            max-width: 544px;
        }
        .form_ERC2891 {
            max-width: 732px;
            .title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-bottom: 24px;
            }
            .input_item {
                display: flex;
                gap: 16px;
            }
        }
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font_color);
        }
    }
}
.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin: 32px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
        div {
            display: flex;
            .BtnLeft {
                margin-right: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1080px) {
    .form {
        padding: 20px;
    }

    .form_box {
        width: auto !important;
    }
    .btns {
        flex-direction: column;
        gap: 24px;
    }
    .next_step {
        flex-direction: column;
        align-items: flex-start !important;
    }
}
@media screen and (max-width: 500px) {
    .btns {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 35px;
        .next_step {
            flex-direction: column;
            align-items: flex-start !important;
            div {
                flex-direction: column;
                .BtnLeft {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
