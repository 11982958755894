import connect from "./connect";
export default {
    ...connect,


    // 请在调用connect方法之后调用
    findContract() {
        let { chainDetail } = this.walletDetail
        let result = this.platformContract.filter(item => {
            return item.chainId == chainDetail.id
        })
        let obj = {}
        result.forEach(item => {
            obj[item.name] = item.address
        })
        return obj
    },

    async supportContract() {
        await this.connect(this.walletDetail.plugin)
        let result = this.findContract()
        return result
    },
    
    initContract(contract = "") {
      return new Promise((resolve, reject) => {
        try{
            let ERC20;
            if (contract) {
                ERC20 = new web3.eth.Contract(abiJson.ERC20, contract);
            }else{
                ERC20 = new web3.eth.Contract(abiJson.ERC20);
            }
            resolve({ ERC20 });

        }catch(err){
            console.log(err)
            reject(err)
        }
      });
    },

    async getGasPrice(){
      await this.connect(this.walletDetail.plugin)
      let result = await web3.eth.getGasPrice()
      result = '0x' + parseInt(result *  1.25).toString(16)
      return result
    },

    async getEthBalance(address) {
      await this.connect(this.walletDetail.plugin)
      let result = await web3.eth.getBalance(address);
      return result;
    },

    async checkBalance(address,limit,price,value = 0) {
        let balance = await this.getEthBalance(address)
        let need = this.multiplication(limit,price)
        need = this.plus(value,need)
        if(parseInt(balance) > parseFloat(need)) {
            return true
        }else {
            this.pushMsg("warning",this.langJson("Sorry, your credit is running low",'new_fi.txt.v1.4'))
            return false
        }
    },

    async Deploy(type = "ERC721",params = []) {
        let result;
        try{
            let { address } = await this.connect(this.walletDetail.plugin)
            let gasPrice = await this.getGasPrice()
            const ContractObj = await this.initContract()
            if(ContractObj[type]){
                
                result = await ContractObj[type].deploy({
                    data: codeJson[type],
                    arguments:params
                }).send({ from: address,gasPrice });
            }else{
                this.pushMsg("warning",this.langJson("I won't support it",'new_fi.txt.v1.5'))
            }
        }catch(err){
            console.log(err)
            // this.pushMsg("error",err)
        }
        return result
    },
    async SendCode(method, params = [], type = "MARKET", contract = ""){
        let result;
        try{
            const ContractObj = await this.initContract(contract)
            if(ContractObj[type]){
                result = ContractObj[type].methods[method](...params).encodeABI()
            }
        }catch(err){
            console.log(err)
            // this.pushMsg("error",err)
        }
        return result
    },
    async Send(method, params = [], type = "MARKET", value = 0, contract = ""){
        let result;
        try{
            let { address } = await this.connect(this.walletDetail.plugin)
            let gasPrice = await this.getGasPrice()
            const ContractObj = await this.initContract(contract)
            if(ContractObj[type]){
                let limit = await ContractObj[type].methods[method](...params).estimateGas({ from: address,value })
                let enough = await this.checkBalance(address,limit,gasPrice,value)
                if(!enough) return
                console.log(`gaslimit:${limit}`)
                let data = await this.SendCode(method,params,type,contract)
                console.log(data)
                result = await ContractObj[type].methods[method](...params).send({
                    from: address,
                    value,
                    gasPrice
                });
            }else{
                let txObj = { from: address,value,gasPrice,to:contract }
                let gas = await window.web3.eth.estimateGas(txObj)
                let enough = await this.checkBalance(address,gas,gasPrice,value)
                if(!enough) return

                console.log(`gaslimit:${gas}`)
                result = await window.web3.eth.sendTransaction({
                    ...txObj,
                    gas
                })
                console.log(result)
            }
        }catch(err){
            console.log(err)
            // this.pushMsg("error",err)
        }
        return result
    },

    async Call(method, params = [], type = "MARKET", contract = ""){
        let result;
        try{
            await this.connect(this.walletDetail.plugin)
            const ContractObj = await this.initContract(contract)
            if(ContractObj[type]){
                result = await ContractObj[type].methods[method](...params).call();
            }else{
                this.pushMsg("error",this.langJson("Contract initialization failed, no contract is deployed",'new_fi.txt.v1.6'))
            }
        }catch(err){
            console.log(err)
 
            this.pushMsg("error",err)
        }
        return result
    }
}











