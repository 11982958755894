<template>
    <div class="detail_info">
        <div class="info_item">
            <div class="role">
                <div class="role_title">{{langJson("Contract Address")}}</div>
                <div class="role_name">0x495f9...045cb7b5e</div>
            </div>
            <div class="item_line"></div>
            <div class="role">
                <div class="role_title">{{langJson("Token Standard")}}</div>
                <div class="role_name">ERC721</div>
            </div>

        </div>

        <div class="info_item">
            <div class="item_tag">
                <Tag title="Blockchain">Ethereum</Tag>
            </div>
            <div class="item_tag">
                <Tag title="Token ID">123</Tag>
            </div>
            <div class="item_tag">
                <Tag title="Royalty">25%</Tag>
            </div>
        </div>
        
        <div class="info_item">
            <div class="price_left price_item">
                <div class="price_title">{{langJson("Price")}}</div>
                <div class="price_amount">
                    <img src="https://etherscan.io/token/images/tether_32.png" alt="" class="symbol_icon">
                    0.1 USDT
                    <div class="price_usdt">≈ $99.91</div>
                </div>
            </div>
            <div class="price_right price_item">
                <div class="price_title">{{langJson("Ends in")}}</div>
                <div class="price_time">
                    <Time time="2022-12-12" type="cancel"></Time>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.detail_info{
                    
    .info_item{
        .item_tag{
            margin-right:10px;
        }
        .item_right{
            flex:1;
            display: flex;
            justify-content: flex-end;
        }
        .item_left{
            .item_royalty{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: var(--main_color);
                margin-left:20px;
            }
            .item_rate{
                margin-left:15px;
            }
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: var(--font_modif_color);
            display: flex;
            align-items: center;
        }
        .price_item{
            .price_time{
                // height:30px;
            }
            .price_amount{
                display: flex;
                align-items: flex-end;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 30px;
                color: var(--font_deep_color);
                margin-top:12px;
                .symbol_icon{
                    width:30px;
                    height:30px;
                    border-radius: 50%;
                    margin-right:6px;
                }
                .price_usdt{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: var(--font_modif_color);
                    margin-left:10px;
                }
            }
            .price_title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: var(--font_deep_color);
            }
            
        }
        .price_right{
            flex:1;
            .price_time{
                margin-top:12px;
                display: flex;
                justify-content: flex-end;
            }
            .price_title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: var(--font_deep_color);
                text-align: right;
            }
        }
        .price_left{
            flex:1;
        }
        .role{
            .role_title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: var(--font_modif_color);
            }
            .role_name{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: var(--font_deep_color);
                margin-top:6px;
            }
        }
        .item_line{
            height:20px;
            width:1px;
            background-color: var(--font_modif_color);
            margin:0 30px;
        }
        align-items: center;
        display: flex;
        width:100%;
    }
    background:var(--bg_color);
    min-height:240px;
    border-radius: 16px;
    margin-top:32px;
    padding:16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>