<template>
    <div class="personal">
        <Go :prev="langJson('Back')" @prev="back"></Go>
        <div class="personal_center">
            <div class="personal_banner">
                <div
                    class="banner"
                    :style="{
                        backgroundImage: `url(${infoByUserId.bannerUrl})`,
                    }"
                ></div>
            </div>
            <div class="personal_portrait">
                <div
                    class="portrait"
                    :style="{
                        backgroundImage: `url(${infoByUserId.imageUrl})`,
                    }"
                ></div>
            </div>

            <div class="personal_name">{{ infoByUserId.nickName }}</div>
            <div class="personal_detail">
                <div class="personal_address" v-show="infoByUserId.address">
                    {{ infoByUserId.address | hideStr }}
                    <GI class="copy" name="personal_copy" ws="24" hs="24"></GI>
                </div>
                <div class="personal_email">{{ infoByUserId.email }}</div>
            </div>

            <div class="personal_desc v-html" v-html="infoByUserId.bio"></div>

            <div class="personal_main">
                <Tab type="line" align="center" v-model="tab" :list="tablist" @change="switchTab"></Tab>

                <StepRun ref="tab">
                    <template slot="tab1">
                        <CreatedNFTs :list="globalMarket.nftProductList" :isParticipant="true" @change="changeNft"></CreatedNFTs>

                        <!-- 不在传钱包地址 -->
                        <!-- <div class="link_wallet" v-if="!walletDetail.address">
                            <BTN type="active" bh="52" bw="150" @handle="linkWallet()">{{ langJson('Link Wallet') }}</BTN>
                        </div> -->

                        <NuData height="300" v-if="!lenArray(globalMarket.nftProductList)" />
                        <div class="art_more">
                            <Page v-model="pageNumNft" :all="totalNft" :size="pageSizeNft" :isBtn="true" @change="init2"></Page>
                        </div>
                    </template>
                    <template slot="tab2">
                        <Arts :list="globalMarket.userArtWorkList" :isParticipant="true" @change="changeArt"></Arts>
                        <NuData height="300" v-if="!lenArray(globalMarket.userArtWorkList)" />
                        <div class="art_more">
                            <Page v-model="pageNumArt" :all="totalArt" :size="pageSizeArt" :isBtn="true" @change="init3"></Page>
                        </div>
                    </template>
                    <template slot="tab3">
                        <JoinedCompaigns :list="globalMarket.joinCampaignListUser" :isParticipant="true" @change="changeActive"></JoinedCompaigns>
                        <NuData height="300" v-if="!lenArray(globalMarket.joinCampaignListUser)" />
                        <div class="art_more">
                            <Page v-model="pageNumActive" :all="totalActive" :size="pageSizeActive" :isBtn="true" @change="init4"></Page>
                        </div>
                    </template>
                </StepRun>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageSize: 24,
            pageNum: 1,
            total: 0,

            pageSizeNft: 24,
            pageNumNft: 1,
            totalNft: 0,

            pageSizeArt: 24,
            pageNumArt: 1,
            totalArt: 0,

            pageSizeActive: 24,
            pageNumActive: 1,
            totalActive: 0,

            tab: 1,
            tablist: [
                {
                    value: 1,
                    lable: this.langJson('Created NFTs'),
                    icon: 'profile_nft_deactive',
                    iconActive: 'profile_nft_active',
                },
                {
                    value: 2,
                    lable: this.langJson('Artworks'),
                    icon: 'profile_gallery_deactive',
                    iconActive: 'profile_gallery_active',
                },
                {
                    value: 3,
                    lable: this.langJson('Joined campaigns'),
                    icon: 'profile_folder_deactive',
                    iconActive: 'profile_folder_active',
                },
            ],
            source: {},
            userId:''
        }
    },
    watch: {
        watch: {
            $route() {
                if (this.$route.query.tab) {
                    this.tab = parseInt(this.$route.query.tab)
                    this.switchTab(this.tab)
                }
            },
        },
    },
    async created() {
        this.userId = this.$route.query.userId
        await this.init()
        await this.init2()
        await this.init3()
        await this.init4()
        window.voteComplete = () => {
            this.init3()
        }
    },
    mounted() {
        if (this.$route.query.tab) {
            this.tab = parseInt(this.$route.query.tab)
            this.switchTab(this.tab)
        }
    },
    methods: {
        async linkWallet() {
            await this.connect()
            await this.init2()
        },
        changeActive(source) {
            this.pageNumArt = 1
            this.source = source
            this.init4()
        },
        changeArt(source) {
            this.pageNumArt = 1
            this.source = source
            this.init3()
        },
        changeNft(source) {
            this.pageNumNft = 1
            this.source = source
            this.init2()
        },
        // 用户 参与的活动
        async init4() {
            try {
                let { dispatch } = this.$store
                // let { userId } = this.$route.query
                let res = await dispatch('joinCampaignListUser', Object.assign({ pageNum: this.pageNumActive, pageSize: this.pageSizeActive, userId:this.userId }, this.source))
                this.totalActive = res.total
            } catch (error) {
                console.log(error)
            }
        },
        // 用户 艺术品
        async init3() {
            try {
                let { dispatch } = this.$store
                // let { userId } = this.$route.query
                let res = await dispatch('userArtWorkList', Object.assign({ pageNum: this.pageNumArt, pageSize: this.pageSizeArt, userId:this.userId }, this.source))
                this.totalArt = res.total
            } catch (error) {
                console.log(error)
            }
        },
        // 用户 nft
        async init2() {
            try {
                let { dispatch, commit } = this.$store
                // let { userId } = this.$route.query
                let res = await dispatch('nftProductList', Object.assign({ pageNum: this.pageNumNft, pageSize: this.pageSizeNft, userId:this.userId }, this.source))
                this.totalNft = res.total
                // userId,
                // creator
                // if (this.walletDetail.address) {
                //     let res = await dispatch('nftProductList', Object.assign({ pageNum: this.pageNumNft, pageSize: this.pageSizeNft, creator: this.walletDetail.address }, source))
                //     this.totalNft = res.total
                //     console.log('this.totalNft', this.totalNft)
                // } else {
                //     commit('nftProductList', [])
                // }
            } catch (error) {
                console.log(error)
            }
        },
        // 用户 信息
        async init() {
            try {
                let { dispatch } = this.$store
                // let { userId } = this.$route.query
                await dispatch('infoByUserId', { userId:this.userId })
            } catch (error) {
                console.log(error)
            }
        },
        switchTab(val) {
            // console.log(val)
            this.tablist.find((item, index) => {
                if (item.value == val) {
                    // let { userId } = this.$route.query
                    this.jump_participant_campaign_participants_profile({ userId:this.userId, tab: val }, false)
                    this.$refs.tab.jump(index)
                    this.source = {}
                    return true
                } else {
                    return false
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.link_wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.art_more {
    margin-top: 36px;
    margin-bottom: 82px;
    width: 100%;
}

.personal {
    .personal_center {
        .personal_main {
            margin-top: 32px;
            width: 100%;
            padding-bottom: 50px;
        }

        .personal_desc {
            margin-top: 16px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--font_color);
            width: 100%;
            max-width: 624px;
            padding: 0 24px;
            box-sizing: border-box;
        }
        .personal_detail {
            .personal_email {
                /* margin-left: 40px; */
            }
            .personal_address {
                margin-right: 80px;
                display: flex;
                align-items: center;
                .copy {
                    margin-left: 4px;
                    cursor: pointer;
                }
            }
            display: flex;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
            color: var(--font_color);
        }
        .personal_name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            margin-top: 16px;
        }
        .personal_portrait {
            .portrait {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-color: var(--color_white);
                position: absolute;
                bottom: 0;
                left: -60px;
            }
            height: 60px;
            position: relative;
        }
        .personal_banner {
            .banner {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                width: 100%;
                height: 100%;
                background-color: var(--color_white);
            }
            border-radius: 24px;
            overflow: hidden;
            height: 280px;
            width: 100%;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media only screen and (max-width: 1080px) {
    .personal_banner {
        margin-top: 4px;
        height: 200px !important;
        border-radius: 32px !important;
    }
}
</style>
