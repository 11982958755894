<template>
    <div class="step">
        <div class="point_list" v-if="showCode">
            <div class="point_item" v-for="(item,index) in slotlist" :key="index">
                <div :class="['point',step == index ? 'point_active' : '']">{{index + 1}}</div>
            </div>
        </div>
        <div :class="[
            'step_item',
            step == index ? 'show' : '',
            step != index ? 'hidden' : ''
        ]" v-for="(item,index) in slotlist" :key="index">
            <slot :name="item"></slot>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            slotlist:[],
            step:0
        }
    },
    props:{
        showCode:{
            type:Boolean,
            default:false
        }
    },
    mounted(){
        Object.keys(this.$slots).forEach(item=>{
            this.slotlist.push(item)
        })
    },
    methods:{
        // 重置
        reset(){
            this.step = 0
        },
        // 中止
        stop(){
            this.$emit('stop')
            this.reset()
        },
        jump(index){
            this.step = index
        },
        // 下一步
        next(){
            if(this.step < this.slotlist.length - 1){
                this.step += 1
            }else{
                this.$emit('finash')
            }
        },
        // 上一步
        prev(){
            if(this.step >= 1){
                this.step -= 1
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.step{
    .point_list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding:30px 0; */
        .point_item{
            flex:1;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .point{
                width:24px;
                height:24px;
                border-radius: 50%;
                border:1px solid #00f261;
                color:#00f261;
                font-size:12px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-family: Russo One,sans-serif;
                box-sizing: border-box;
                padding-top:2px;
            }
            .point:hover{
                color:#fff!important;
                background-color: rgba($color: #00f261, $alpha: 0.5);
            }
            .point_active{
                color:#fff!important;
                background-color: rgba($color: #a3ff12, $alpha: 0.5);
            }
        }
    }
    
}


.show {
  animation: innerShow 2s ease;
}
.hidden {
  height: 0;
  overflow: hidden;
}
@keyframes innerShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes innerHide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>





