<template>
    <div class="floor_new">
        <div class="new_top">
            <div class="title">{{ title }}</div>
            <div class="new_do">
                <GI class="icon_do" v-show="!groupStatus.prev" name="market_left" ws="32" hs="32"></GI>
                <GI class="icon_do" v-show="groupStatus.prev" name="market_left_active" ws="32" hs="32" @click="$refs.group.prev()"></GI>
                <GI class="icon_do" v-show="!groupStatus.next" name="market_right" ws="32" hs="32"></GI>
                <GI class="icon_do" v-show="groupStatus.next" name="market_right_active" ws="32" hs="32" @click="$refs.group.next()"></GI>
            </div>
        </div>
        <Group v-if="!load_btn" ref="group" @change="changeGroup">
            <div class="group_item" v-for="(item, index) in listData" :key="index">
                <div class="item_main" :style="{ width: `${itemWidth}px`, padding: `${itemPadding}` }">
                    <slot :item="item" name="item"></slot>
                </div>
            </div>
        </Group>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        // 每一项的宽度
        itemWidth: {
            type: Number,
            default: 375,
        },
        // 内边距
        itemPadding: {
            type: String,
            default: '16px 24px 24px 24px',
        }
    },
    data() {
        return {
            groupStatus: {
                prev: false,
                next: false,
            },
            listData:[]
        }
    },
	created() {
        this.listData = []
		this.init()
	},
    methods: {
		async init() {
            this.load_btn = true
			try{
				let { user } = this.$route.query
				let { dispatch } = this.$store
				await dispatch('joinCampaignList',{
					userId:user
				})
                this.listData = JSON.parse(JSON.stringify(this.joinCampaignList))
                this.listData.forEach(item =>{
                    item.operateStop = true
                })
			}catch(err){
				console.log(err)
			}
            this.load_btn = false
		},
        changeGroup(obj) {
            this.groupStatus = Object.assign(this.groupStatus, obj)
        },
    },
}
</script>
<style lang="scss" scoped>
.floor_new {
    .group_item {
        .item_main {
            background: var(--color_white);
            margin-right: 32px;
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
            white-space: wrap !important;
            box-sizing: border-box;
            &:hover {
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
            }
        }
    }
    .new_top {
        .new_do {
            display: flex;
            align-items: center;
            .icon_do {
                cursor: pointer;
                margin-left: 20px;
                pointer-events: stroke;
            }
        }
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 48px;
}
@media screen and (max-width: 1080px) {
    .floor_new {
        .group_item {
            .item_main {
                background: var(--color_white);
                margin-right: 8px;
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 16px;
                white-space: wrap !important;
                &:hover {
                    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
                }
            }
        }
        .new_top {
            .new_do {
                display: flex;
                align-items: center;
                .icon_do {
                    cursor: pointer;
                    margin-left: 20px;
                    pointer-events: stroke;
                }
            }
            .title {
                color: var(--font_deep_color);
                font-family: 'Lato' !important;
                font-weight: 700 !important;
                font-size: 16px !important;
                line-height: 24px !important;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px !important;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 24px !important;
    }
}
</style>
