<template>
    <div>
        <div class="maskBox" v-show="show" @click="$emit('change', false)"></div>

        <div
            class="start_menu"
            :style="{
                width: `${width}px`,
                left: show ? `0` : `${-width}px`,
            }"
        >
            <div @click="$emit('change', false)" class="close cursor">
                <GI name="model_close" ws="24" hs="24"></GI>
            </div>
            <!-- <div class="line"></div> -->
            <div class="link_menu">
                <BtnPop v-if="viewObj.portrait">
                    <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                    <div slot="target" class="portrait_box">
                        <div class="portrait" :style="{ backgroundImage: `url(${userInfo.imageUrl})` }"></div>
                        <span>{{ userInfo.nickName }}</span>
                    </div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                                <span class="title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
                <div class="line"></div>
                <div v-show="viewObj.link" class="link_item" v-for="(item, index) in navlist" :key="index">
                    <div :class="['link_inner', item.matching.test(routePath) && 'link_active']" @click="jumpOther(item.path)">
                        {{ item.title }}
                    </div>
                </div>
                <div v-show="viewObj.link" class="link_item">
                    <BtnPop>
                        <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                        <div class="link_inner" slot="target">
                            {{ langJson('Resources') }}
                        </div>
                        <div slot="main" class="type_main_resources">
                            <div v-for="(item, index) in tagListResources" :key="index" class="type_content">
                                <div @click="goRouteResources(item)" class="type_line">
                                    <span class="title">{{ item.val }}</span>
                                </div>
                            </div>
                        </div>
                    </BtnPop>
                </div>
                <div v-show="viewObj.link" class="link_item">
                    <div @click="jumpProfile" class="link_inner">
                        {{ langJson('Profile') }}
                    </div>
                </div>
                <div class="line"></div>
                <div class="wallet_box">
                    <BtnPop @handle="walletClick">
                        <div slot="target" v-show="viewObj.wallet" class="wallet">
                            <GI name="global_wallet" ws="30" hs="30"></GI>
                            <span class="wallet_title">{{ langJson('My wallet') }}</span>
                        </div>
                        <div slot="main" class="type_main_wallet_success" v-if="lenArray(tokens)">
                            <div class="upper">
                                <h2 class="title_wallet">{{ langJson('Connect with Metamask') }}</h2>
                                <div class="wallet_address" @click="copy(walletDetail.address)">
                                    <span>{{ walletDetail.address | hideStr }}</span>
                                    <GI ws="24" hs="24" name="edit_copy_1"></GI>
                                </div>

                                <div class="line"></div>
                                <div class="total_balance">
                                    <span>{{ langJson('Total balance') }}</span>
                                    <h2>$&nbsp;{{ totalUsd | numberGrap }}</h2>
                                </div>
                            </div>
                            <div>
                                <BTN type="warn" bw="248" @handle="disconnect()">{{ langJson('Disconnect') }}</BTN>
                            </div>
                        </div>
                    </BtnPop>

                    <BtnPop :left="-80" v-if="!walletDetail.address">
                        <BTN slot="target" type="detail" bh="40">{{ langJson('Connect') }}</BTN>
                        <div slot="main" class="connect_wallet">
                            <h2 class="title_wallet">{{ langJson('Connect wallet') }}</h2>
                            <div>
                                <BTN bh="48" @handle="modelShow = true" icon_left="metamask_left">{{ langJson('Metamask') }}</BTN>
                            </div>
                        </div>
                    </BtnPop>
                    <!-- 准备链接钱包 -->
                    <!-- <BtnPop>
        
          <div slot="target" v-show="viewObj.wallet" class="wallet">
                <BTN slot="target" type="detail" bh="40">Connect </BTN>
          </div>
          <div slot="main" class="type_main_wallet">
            <h2 class="title_wallet">{{ langJson('Connect wallet') }}</h2>
            <div>
              <BTN icon_left="metamask">{{ langJson('Metamask') }}</BTN>
            </div>
          </div>
        </BtnPop> -->
                </div>
                <div v-show="viewObj.theme" class="theme">
                    <span>{{ langJson('Theme') }}</span>
                    <Theme></Theme>
                </div>
                <div v-show="viewObj.create" class="btn_item">
                    <BTN type="active" @handle="mint_nft_handle">{{ langJson('Create NFTs') }}</BTN>
                </div>
                <div v-show="!viewObj.create" class="btn_item">
                    <BTN type="active" @handle="create_handle">{{ langJson('Create new campaign') }}</BTN>
                </div>
            </div>
        </div>

        <Model :title="langJson('Connect wallet')" :subtitle="langJson('For storing your final NFTs')" :padding="'24px'" v-model="modelShow">
            <template v-slot:main>
                <div class="connect_dialog_main">
                    <div class="connect_content">
                        <div class="metamask">
                            <GI name="metamask_left" hs="48" ws="48"></GI>
                            <span class="metamask_title">{{ langJson('Metamask') }}</span>
                        </div>
                    </div>
                    <div class="connect_btns">
                        <BTN class="btn" type="active" :load="load_btn" @handle="connectWallet">{{ langJson('Connect') }}</BTN>
                        <BTN @handle="closeModel" class="btn" type="detail">{{ langJson('Cancel') }}</BTN>
                    </div>
                </div>
            </template>
        </Model>

        <MintTip v-model="head_tip" @confirm="showSubmit"></MintTip>
        <WalletTip v-model="create_tip" @confirm="jumpOther('/brand/createCampaign/createOverview')"></WalletTip>
    </div>
</template>
<script>
import { HERC20 } from 'h-token-staking'
let { saveNum, multiplication, division, charCompare } = require('h-token-staking/src/utils')
import { setCookie } from '@/assets/js/cookie'
export default {
    props: {
        viewObj: {
            type: Object,
            default: () => {
                return {
                    menu: true,
                    logo: true,
                    search: true,
                    link: true,
                    create: false,
                    newCompagin: false,
                    portrait: true,
                    wallet: true,
                    theme: true,
                }
            },
        },
        show: {
            type: [Boolean, PointerEvent],
            default: false,
        },
        navlist: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            head_tip:false,
            create_tip:false,
            modelShow: false,
            width: 360,
            tagList: [
                { icon_dark: 'profile_my', icon_light: 'profile_my_light', val: 'My profile', number: 1 },
                { icon_dark: 'profile_setting', icon_light: 'profile_setting_light', val: 'Settings', number: 2 },
                { icon_dark: 'brand_exit', icon_light: 'brand_exit', val: 'Log out', number: 3 },
            ],
            // 判断 viewObj.create 为true 则是 participant
            tagListResources: [
                { val: this.langJson('Account Support'), number: 1 },
                { val: this.viewObj.create ? this.langJson('Terms & Condition') : this.langJson('Terms of Service'), number: 2 },
                { val: this.langJson('About NFTWerks'), number: 3 },
            ],
            totalUsd: 0,
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    mounted() {
        window.addEventListener('resize', this.screenAdapter)
        this.screenAdapter()
    },

    computed: {
        routePath() {
            return this.$route.path
        },
        wallet() {
            return this.walletDetail.address
        },
        tokens() {
            try {
                let result = []
                let { chainDetail } = this.walletDetail
                if (chainDetail && chainDetail.id) {
                    result = this.globalToken.filter((item) => {
                        return item.chainId == chainDetail.id
                    })
                }
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
    },
    watch: {
        wallet(address) {
            this.balanceGet()
            let { dispatch } = this.$store
            // if (address) {
            //     dispatch('userGet', { address })
            // }
        },
    },
    created() {
        window.closeH5Menu = () => {
            this.close()
        }
    },
    methods: {
        create_handle() {
            this.create_tip = true
        },
        mint_nft_handle() {
            this.head_tip = true
        },
        logout() {
            setCookie('token', '')
            this.$emit('handleOut', 'quit')
            this.jump_startup()
            this.close()
        },
        walletClick() {
            if (!this.lenArray(this.tokens)) {
                this.pushMsg('warning', this.langJson('Please connect wallet'))
                return
            }
            this.balanceGet()
        },
        disconnect() {
            this.$store.commit('walletDetail', {})
            if (window.provider && window.provider.close) {
                window.provider.close()
            }
            this.close()
        },
        async balanceGet() {
            try {
                let { provider, accounts } = await this.isProvider('Metamask')
                this.totalUsd = 0
                // this.tokenlist = []
                for (let i = 0; i < this.tokens.length; i++) {
                    let { contractAddr, id } = this.tokens[i]
                    let { address } = this.walletDetail
                    // let { balance } = await this.tokenBalance(contractAddr)
                    let ERC20 = new HERC20({
                        provider,
                        accounts,
                        contract: contractAddr,
                    })
                    let balance = await ERC20.balanceOf(address)
                    balance = this.toMain(balance, id)
                    let usdBalance = saveNum(this.usdRate(balance, id))
                    // let tokenObj = {
                    //     ...this.tokens[i],
                    //     balance: saveNum(balance),
                    //     usdBalance,
                    // }
                    this.totalUsd = this.plus(this.totalUsd, usdBalance)

                    // this.pushToken(tokenObj)
                }
            } catch (err) {
                console.log(err)
            }
        },
        async connectWallet() {
            this.load_btn = true
            try {
                let result = await this.connect()
                if (result) {
                    this.close()
                }
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
        jumpOther(path) {
            this.$emit('change', false)
            this.jump_page_path(path)
        },
        close() {
            this.modelShow = false
            this.$emit('change', false)
        },
        goRoute(item) {
            this.$emit('change', false)
            switch (item.number) {
                case 1:
                    // 判断 viewObj.create 为true 则是 participant

                    this.jumpProfile()
                    break
                case 2:
                    if (this.viewObj.create) {
                        this.jump_participant_profile_editProfile()
                    } else {
                        this.jump_brand_profile_editProfile()
                    }

                    break
                case 3:
                    this.logout()
                    break

                default:
                    break
            }
        },
        jumpProfile() {
            this.$emit('change', false)
            if (this.viewObj.create) {
                this.jump_participant_profile_myprofile({ userId: this.userInfo.id })
            } else {
                this.jump_brand_profile_myprofile({ idUser: this.userInfo.id })
            }
        },
        goRouteResources(item) {
            this.$emit('change', false)
            switch (item.number) {
                case 1:
                    if (this.viewObj.create) {
                        this.jump_participant_profile_editProfile({ tab: 3 })
                    } else {
                        this.jump_brand_profile_editProfile({ tab: 4 })
                    }
                    break
                case 2:
                    if (!this.viewObj.create) {
                        this.jump_article_detail({ articleId: 4 })
                    } else {
                        this.jump_article_detail({ articleId: 5 })
                    }
                    break
                case 3:
                    this.jump_page_path('/startup/index')
                    break

                default:
                    break
            }
        },
        closeModel() {
            this.modelShow = false
        },
        screenAdapter() {
            if (document.body.clientWidth < 616) {
                this.width = document.body.clientWidth
            } else {
                this.width = 360
            }
        },
        showSubmit() {
            this.$store.commit('changeshowMintOrJoin', true)
        },
    },
    destroyed() {
        window.removeEventListener('resize', this.screenAdapter)
    },
}
</script>
<style lang="scss" scoped>
/deep/ {
    .switch_theme {
        background-color: var(--bg_color) !important;
    }
}

.maskBox {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.5s;
    z-index: 20221028;
}

.start_menu {
    overflow: auto;
    .close {
        height: 52px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--border_color);
    }
    .link_menu {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-top: 72px;
        /* padding-top: 84px; */
        padding-bottom: 53px;
        padding-left: 20px;
        padding-right: 20px;
        .portrait_box {
            display: flex;
            gap: 8px;
            width: fit-content;
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: var(--font_deep_color);
            }
        }
        .portrait {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            cursor: pointer;
        }
        .link_item {
            .link_inner {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
                padding: 12px 0;
                border-bottom: 2px solid transparent;
            }
            .link_active {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--primary_color);
                border-bottom: 2px solid var(--primary_color);
            }
            cursor: pointer;
            margin-right: 32px;
            width: fit-content;
        }
    }

    position: fixed;
    top: 0;
    bottom: 0;
    background: var(--color_white);

    box-sizing: border-box;
    transition: all 0.5s;
    z-index: 20221029;
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child {
            color: var(--danger_color);
        }
        &:not(:last-child) {
            color: var(--font_deep_color);
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                font-family: 'Lato';
            }
        }
    }
}
/* resources下拉框 */
.type_main_resources {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                font-family: 'Lato';
                color: var(--font_deep_color);
            }
        }
    }
}
/* 多个地方用到了，抽离出来 */
.line {
    width: 100%;
    border-bottom: 1px solid var(--border_color);
}
/* 底部钱包 */
.wallet_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wallet {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        .wallet_title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_color);
        }
    }
}
.type_main_wallet_success {
    padding: 24px;
    background: var(--nft_dialog_bg);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    .upper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .title_wallet {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        .wallet_address {
            display: flex;
            gap: 8px;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            /* color: var(--font_modif_color); */
            color: var(--font_deep_color);
        }
        .line {
            width: 248px;
            border-bottom: 1px solid var(--border_color);
        }
        .total_balance {
            h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
            }
        }
    }
}
.connect_wallet {
    padding: 24px;
    background: var(--nft_dialog_bg);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    .title_wallet {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        /* identical to box height, or 156% */

        /* Neutral/1 */

        color: var(--font_deep_color);
    }
}
/* 主题 */
.theme {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--font_color);
}
/* 链接钱包对话框 */
.connect_dialog_main {
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 76.534vw;
    .connect_content {
        display: flex;
        justify-content: center;
        .metamask {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            .metamask_title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
        }
    }
    .connect_btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .btn {
            width: 100% !important;
        }
    }
}

.start_menu {
    .close {
        position: fixed;
        width: 360px;
        background: var(--color_white);
        z-index: 999;
        box-sizing: border-box;
        /* transition: all 0s !important; */
    }
}
@media screen and (max-width: 616px) {
    .start_menu {
        .close {
            width: 100%;
        }
    }
}
</style>
