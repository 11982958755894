<template>
    <div class="item">
        <div class="brand_detail">
            <CheckBox @click.native="selectItem" :active="selected"></CheckBox>
            <div class="rank_vote">{{ timed(item.deleteTime) }} {{ langJson('days') }}</div>
        </div>

        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image cursor" :style="{ backgroundImage: `url(${item.artworkImg})` }" @click="jump_brand_compaign_artwork_detail({ idArt: item.id, idActive: $route.query.idActive, idUser: item.userId })"></div>
        </div>

        <div class="item_info">
            <div class="item_title">
                {{ item.artworkTitle }}
                <br />
                <span class="item_subtitle">@{{ item.artworkCredit }}</span>
            </div>
            <div class="item_desc">
                <span>{{ item.voteNum }} {{ langJson('votes') }}</span>
                <BTN v-show="false" type="selectAll" bw="88" bh="36" borderWidth='1'>{{ langJson('Vote') }}</BTN>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
		selectlist: {
			type: Array,
            default: () => {
                return []
            },
		}
    },
    computed: {
		selected() {
			return this.selectlist.some(item => {
				return item == this.item.id
			})
		},
        timed() {
            return (time) => {
                try {
                    time = time.replace(/-/g, '/')
                    let now = new Date().getTime()
                    let deleteTime = new Date(time).getTime()
                    let result = parseInt(now - deleteTime)
                    let daylen = 1000 * 60 * 60 * 24
                    let day = Math.ceil(result / daylen)
                    day = isNaN(day) ? 0 : day
                    return day
                } catch (err) {
                    console.log(err)
                    return 0
                }
            }
        },
    },
    data() {
        return {
            active: false,
        }
    },
    methods: {
		selectItem() {
            // console.log(this.item)
			this.$emit("select",this.item)
		}
	},
}
</script>
<style lang="scss" scoped>
.item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    gap: 16px;

    .item_info {
        .item_desc {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .item_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .item_subtitle {
            /* Body/1/Regular */
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Neutral/3 */

            color: var(--font_color);
        }
        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: space-between;
        height: 32px;
        .rank_vote {
            width: 61px;

            height: 32px;
            text-align: center;
            line-height: 32px;
            color: var(--danger_color);
        }
    }

    .image_out {
        border-radius: 12px;
        overflow: hidden;
        background-color: rgba($color: #ccc, $alpha: 0.2);

        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
</style>
