<template>
    <div class="second">
        <div class="second_main">
            <div class="main_left">
                <div class="step_box">
                    <div class="step_item step_active"></div>
                    <div class="step_grap"></div>
                    <div class="step_item step_active"></div>
                    <div class="step_grap"></div>
                    <div class="step_item step_active"></div>
                </div>
                <div class="main_title">{{ langJson('Preview of final artwork') }}</div>
                <div
                    v-if="isPhone"
                    class="main_right"
                    :style="{
                        backgroundImage: `url(${form.artworkImg})`,
                    }"
                ></div>

                <FormItem :disabled="true" v-model="form.artworkTitle" class="input_item" type="text" :title="langJson('Title')" :matchs="['unull']"></FormItem>

                <FormItem :disabled="true" v-model="form.description" class="input_item" type="textarea" :title="langJson('Description')" :matchs="['unull']" height="368px"></FormItem>

                <div class="left_bottom">
                    <div class="left_back" @click="$emit('prev')">
                        <GI name="artwork_back" ws="24" hs="24"></GI>
                        <div class="back_text">{{ langJson('Previous step') }}</div>
                    </div>
                    <BTN type="active" @handle="goRoute" :bw="isPhone ? 0 : 180" bh="52">
                        {{ langJson('Submit') }}
                    </BTN>
                </div>
            </div>
            <div
                class="main_right"
                v-if="!isPhone"
                :style="{
                    backgroundImage: `url(${form.artworkImg})`,
                }"
            ></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                artworkTitle: '', // 艺术品标题
                description: '', // 描述
                artworkImg: '', // 艺术品
            },
        }
    },
    created() {},
    watch: {
        'globalMarket.campaignWorksSave': {
            handler() {
                this.initData()
            },
            deep: true,
        },
    },
    methods: {
        initData() {
            let { artworkTitle, description, artworkImg } = this.globalMarket.campaignWorksSave
            artworkTitle && this.$set(this.form, 'artworkTitle', artworkTitle)
            description && this.$set(this.form, 'description', description)
            artworkImg && this.$set(this.form, 'artworkImg', artworkImg)
        },
        async goRoute() {
            try {
                let { dispatch } = this.$store
                let { id } = this.$route.params
                let res = await dispatch('campaignWorksSave', { campaignId: id })
                // console.log('res', res)
                if (res && res.code == '200') {
                    this.pushMsg('success', this.langJson('Join Success'))
                    this.jump_participant_compaign_ParticipantsNFTs({ tab: 1, id: 'participants', idActive: id })
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.second {
    .second_main {
        .main_right {
            border-radius: 20px;
            width: 600px;
            height: 572px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .main_left {
            .left_bottom {
                .left_back {
                    .back_text {
                        font-family: 'Cabinet Grotesk';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 28px;
                        color: var(--font_deep_color);
                        margin-left: 16px;
                    }

                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 32px;
            }

            .main_title {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
                margin-top: 32px;
                text-align: center;
            }

            .input_item {
                margin-top: 32px;
            }

            .step_box {
                .step_item {
                    flex: 1;
                    height: 6px;
                    background-color: var(--border_color);
                    opacity: 0.4;
                    border-radius: 4px;
                }

                .step_grap {
                    width: 16px;
                }

                .step_active {
                    background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                }

                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            width: 100%;
            max-width: 600px;
            padding: 32px;
            box-sizing: border-box;
            background: var(--color_white);
            border-radius: 32px;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 80px;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-bottom: 138px;
    }
}

@media screen and (max-width: 1081px) {
    .second {
        .second_main {
            .main_right {
                margin-top: 32px;
                border-radius: 20px;
                width: auto;
                height: 295px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .main_left {
                .left_bottom {
                    .left_back {
                        .back_text {
                            font-family: 'Cabinet Grotesk';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 28px;
                            color: var(--font_deep_color);
                            margin-left: 16px;
                        }

                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 32px;
                }

                .main_title {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px;
                    color: var(--font_deep_color);
                    margin-top: 32px;
                    text-align: center;
                }

                .input_item {
                    margin-top: 32px;
                }

                .step_box {
                    .step_item {
                        flex: 1;
                        height: 6px;
                        background-color: var(--border_color);
                        opacity: 0.4;
                        border-radius: 4px;
                    }

                    .step_grap {
                        width: 16px;
                    }

                    .step_active {
                        background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    }

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                width: 100%;
                max-width: 600px;
                padding: 32px 20px !important;
                box-sizing: border-box;
                background: var(--color_white);
                border-radius: 32px;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            margin-bottom: 138px;
        }
    }
}
</style>
