<template>
    <div :class="['input_style', globalThemeColor, noBorder?'noBorder':'']">
        <GI class="input_icon" v-if="icon" :name="icon" ws="24" hs="24"></GI>

        <!-- :id="type=='camera'?'cameraOpen':''" -->
        <input v-if="!openPwd" v-model="value" class="input_main" :type="type" 
        :placeholder="placeholder" @input="compare" :accept="accept" :capture="capture" :disabled="disabled">
        <input v-else v-model="value" class="input_main" type="text" 
        :placeholder="placeholder" @input="compare" :disabled="disabled">

        <div class="input_append">
            <img v-if="type == 'password' && !openPwd" @click="review(true)" src="/source/svg/global_eye_on.svg" class="icon cursor"></img>
            <img v-else-if="type == 'password'" @click="review(false)" src="/source/svg/global_eye_close.svg" class="icon cursor"></img>
            <slot name="append"></slot>
        </div>

        <div class="input_tip" v-if="tip">{{tip}}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: '',
            openPwd: false,

            isInit: false,
            time:null
        }
    },
    model: {
        prop: 'keywork',
        event: 'change',
    },
    props: {
        accept: {
            type: String,
            default: '',
        },
        capture: {
            type: String,
            default: '',
        },
        keywork: {
             type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: 'nav_search',
        },
        matchs: {
            type: Array,
            default: () => {
                return []
            },
        },
        max: {
            type: [Number, String],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        }, 
         numberInput: {
            type: Boolean,
            default: false,
        },
        disabled:{
            type:Boolean,
            default:false
        },
        noBorder:{
            type:Boolean,
            default:false
        },
        inputNumber:{
            type:String,
            default:'0'
        }
    },
    computed: {
        tip() {
            // 是否有输入
            if (!this.isInit) {
                return ''
            }
            try {
                let result = this.matchs.find((item) => {
                    return !this.rules[item].match.test(this.value)
                })
                if (result) {
                    return this.rules[result].tip
                } else {
                    if (this.type == 'text' && this.value && this.inputNumber === '255') {
                        if (this.value.length >= 255) {
                            return 'The maximum length is 255'
                        }
                    }
                    return ''
                }
            } catch (err) {
                console.log(err)
            }
            return ''
        },
    },
    created() {
        this.value = this.keywork
    },
    watch: {
         keywork(n) {
            this.value = n
        },
        // this.trimSpace()
        value(n) {
            
            if (this.numberInput) {
                this.$emit('change', Number(n))
            } else {
                this.$emit('change', n)
            }
        },
    },
    methods: {
        compare(event) {
            this.time && clearTimeout(this.time)
            this.isInit = true
            try {
                if (this.max !== '') {
                    let current = parseFloat(event.target.value)
                    let max = parseFloat(this.max)
                    if (current > max) {
                        this.value = this.max
                    }
                }
            } catch (err) {
                console.log(err)
                this.value = ''
            }

            this.time = setTimeout(() => {
                if(!(this.value instanceof Number)) {
                    this.value = this.trimSpace(this.value)
                }
            },1000)
        },
        review(val) {
            this.openPwd = val
        },
    },
}
</script>
<style lang="scss" scoped> 
.input_style {
    .input_tip {
        position: absolute;
        bottom: -28px;
        right: 0px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--danger_color);
    }
    .input_append {
        .icon {
            width: 24px;
            height: 24px;
        }
        margin-left: 22px;
        display: flex;
        align-items: center;
    }
    .input_icon {
        margin-right: 22px;
    }
    .input_main {
        border: none;
        outline: none;
        background: transparent;
        height: 32px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* color: var(--font_modif_color); */
        color: var(--font_deep_color);
        flex: 1;

        min-width: 0px;
        overflow: hidden;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 24px;
    border-radius: var(--borderRadius); 
    box-sizing: border-box;
    position: relative;
    border: 1px solid var(--borderColor1);
    box-sizing: border-box;
}
.theme_dark{
    /* border: 1px solid #FFFFFF; */
    /* border-radius: 16px; */
    border-radius: var(--nft_select_border_radius);
    color: #fff;
}
.noBorder{
    border: none;
}
</style>