<template>
  <div class="item">
    <div class="image_out" :style="{ height: `${hs}px` }" @click="jump_myster_detail(1)">
      <div
        class="item_image"
        :style="{
          backgroundImage: `url(https://w.wallhaven.cc/full/dp/wallhaven-dp9d2m.jpg)`,
        }"
      ></div>
      <slot name="image"></slot>
    </div>
    <div class="item_title_small">
      <div class="title_text">
        {{ langJson('Skechers Sundown Festival') }}
      </div>
      <Tag>
        {{ langJson('Ethereum') }}
      </Tag>
    </div>
    <!-- <div class="item_belong">
            <div class="belong_image" :style="{
                backgroundImage:`url(/source/png/profile.jpeg)`
            }"></div>
            <div class="belong_name">{{langJson("Asians For Asia")}}</div>
        </div> -->
    <div class="item_price">
      <div class="item_lable">
        {{ langJson('Price') }}
      </div>
      <div class="item_value">
        <div class="amount">1 ETH</div>
        <div class="usdtAmount">≈ $1284.45</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ws: {
      type: [String, Number],
      default: '100%',
    },
    // 图片高度
    hs: {
      type: [String, Number],
      default: '200',
    },
    titType: {
      type: String,
      default: 'item_title',
    },
    descType: {
      type: String,
      default: 'item_desc',
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  .item_price {
    .item_lable {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 14px;
      color: var(--font_color);
    }
    .item_value {
      .amount {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        color: var(--font_deep_color);
      }
      .usdtAmount {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 14px;
        color: var(--font_modif_color);
        margin-top: 4px;
      }
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
  }
  .item_tags {
    .tag_item {
      margin-right: 12px;
      &:last-child {
        margin-right: 0px;
      }
    }
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .item_desc_small {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    color: var(--font_color);

    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .item_desc {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    color: var(--font_deep_color);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .item_belong {
    .belong_name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--font_deep_color);
      margin-left: 8px;
    }
    .belong_image {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
    }
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  .item_title {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item_chain {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      padding: 8px 14px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.3);
      color: var(--font_deep_color);
    }
    .title_text {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: var(--font_deep_color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .item_title_small {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item_chain {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      padding: 8px 14px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.3);
      color: var(--font_deep_color);
    }
    .title_text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: var(--font_deep_color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .image_out {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: rgba($color: #ccc, $alpha: 0.2);
    .item_image {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      transition: all 2s;
    }
    .item_image:hover {
      transform: scale(2);
    }
  }

  cursor: pointer;
}
</style>
