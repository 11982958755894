<template>
    <div>
        <div class="header">
            <h2>{{ langJson('Add links to website and community') }}</h2>
            <span>{{ langJson('Connect your audience and expand your online presence with clickable links to your website and vibrant community.') }}</span>
        </div>
        <div>
            <div class="form">
                <div class="form_box" style="gap: 4px;">
                    <h2 class="form_box_title">{{ langJson('Your company website') }}</h2>
                    <div class="form_input">
                        <FormItem class="information_item" type="text" v-model="form.companyWebsite" 
                        :placeholder="langJson('example.com')"></FormItem>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="form_box">
                    <h2 class="form_box_title">{{ langJson('Social media') }}</h2>
                    <div v-for="(item, index) in socialMedia" :key="index" class="form_input">
                        <FormItem v-model="form.urls[index]" class="information_item" type="text" 
                        :placeholder="item.url" :title="item.title"></FormItem>
                    </div>

                    <!-- <div class="form_input">
                        <BTN icon_left="add-rewards" type="detail" bh="52" bw="170">{{ langJson('Add new link') }}</BTN>
                    </div> -->
                </div>
            </div>
            <div class="btns">
                <div class="next_step">
                    <span>{{ langJson('Your changes have been saved') }}</span>
                    <div>
                        <BTN @handle="goRewards" type="active" bh="52" bw="152" class="BtnLeft">{{ langJson('Last: Rewards') }}</BTN>
                        <BTN @handle="goSticker" type="active" bh="52" bw="227">{{ langJson('Next: Sticker library') }}</BTN>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                companyWebsite: '',
                urls: [],
            },
            bannerConfig: {
                type: 'big',
            },
            list: [
                {
                    lable: this.langJson('USD'),
                    value: '',
                },
            ],
        }
    },

    watch: {
        form: {
            handler(newVal) {
                let { companyWebsite,urls } = newVal
                let campaignSocialMedias = this.socialMedia.map((item,index) => {
                    return {
                        socialConfigId: item.id,
                        socialMedia: item.title,
                        website: urls[index]
                    }
                })
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    companyWebsite,
                    campaignSocialMedias
                })
            },
            deep: true,
        },
    },
    created() {
        this.initData()
    },
    inject: ['switchTab'],
    methods: {
        initData() {
            let { companyWebsite, campaignSocialMedias } = this.compaignDetail
            if (companyWebsite) {
                this.$set(this.form, 'companyWebsite', companyWebsite)
            }

            if (campaignSocialMedias) {
                this.socialMedia.forEach((item, index) => {
                    let detail = campaignSocialMedias.find((subItem) => {
                        return subItem.socialConfigId == item.id
                    })
                    if (detail) {
                        this.$set(this.form.urls, index, detail.website)
                    } else {
                        this.$set(this.form.urls, index, '')
                    }
                })
            }
        },
        goRewards() {
            this.switchTab(2)
        },
        goSticker() {
            this.switchTab(4)
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }
    span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_modif_color);
    }
}
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    .form_box {
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .information_item {
            width: 100%;
        }
        .form_box_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        .btns {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
        }
        .form_input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
        }
    }
}
.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
        div{
            display: flex;
            .BtnLeft{
                margin-right: 15px;
            }
        }
    }
}
@media screen and (max-width: 1080px) {
    .form {
        padding: 20px;
    }
    .btns {
        flex-direction: column;
        gap: 24px;
    }
    .header {
        h2 {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        span {
            font-size: 14px;
        }
    }
    .next_step {
        flex-direction: column;
        align-items: flex-start !important;
    }
}

@media screen and (max-width: 500px) {
    .btns {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 35px;
        .next_step {
            flex-direction: column;
            align-items: flex-start !important;
            div{
                flex-direction: column;
                .BtnLeft{
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
