<template>
    <div @click="check" class="check_container">
        <GI :class="[disabled && 'disabled']" :name="active === true ? 'brand_checked' : 'brand_unchecked'" ws="24" hs="24" class="pointer"></GI>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'active',
        event: 'change',
    },
    data() {
        return {}
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
        check() {
            if (this.disabled) {
                return
            }
            this.active ? this.$emit('change', false) : this.$emit('change', true)
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.disabled {
    opacity: 0.6;
    background-color: rgba($color: #ccc, $alpha: 0.2);
    border-radius: 8px;
}
.check_container {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}
</style>
