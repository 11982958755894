import axios from 'axios'
import qs from 'qs'
import { createLogger } from 'vuex'
import { getCookie, removeCookie, setCookie } from './cookie'
const lans = 'en_US'||navigator.language
const cookLan = localStorage.getItem('Language')
let langs
let Lan
if (lans.indexOf('zh') !== -1) {
    Lan = 'zh_CN'
} else if (lans.indexOf('en') !== -1) {
    Lan = 'en_US'
} else if (lans.indexOf('ja') !== -1) {
    Lan = 'ja_JP'
} else if (lans.indexOf('ko') !== -1) {
    Lan = 'ko_KR'
} else {
    Lan = 'en_US'
}

if (cookLan === 'zh_CN' || cookLan === 'en_US' || cookLan === 'ja_JP' || cookLan === 'ko_KR') {
    langs = cookLan
} else {
    langs = Lan || 'en_US'
}

langs = 'en_US'
localStorage.setItem('Language', langs)

let getParams = () => {
    let temp = window.location.href
    let obj = {}
    if (temp.indexOf('?') != -1) {
        let str = temp.split('?')[temp.split('?').length - 1]
        if (!str) {
            return
        }
        str = str.split('&')

        str.forEach((item) => {
            let tempStr = item.split('=')
            obj[tempStr[0]] = tempStr[1]
        })
    }
    return obj
}
// 请求头
const formatHeaders = (acHeaders) => {
    let headers = {}
    headers.Language = getParams().lang ? getParams().lang : langs
    headers['Authorization'] = getCookie('token') ? `Bearer ${getCookie('token')}` : ''

    if (acHeaders) {
        headers = { ...headers, ...acHeaders }
    }
    return headers
}

let lock = {
    url: '',
    time: 0,
}
const http = ({ url, headers, params, method, hostType, isJson }, cancelToken) => {
    let prefix = ''
    switch (hostType) {
        case 'otc':
            prefix = '/fe-otc-api'
            break
        case 'co':
            prefix = '/fe-co-api'
            break
        case 'upload':
            prefix = '/fe-upload-api'
            break
        case 'def':
            prefix = ''
            break
        case 'exchange':
            prefix = '/web'
            break
        default:
            prefix = '/api' // 默认
    }
    const qsParams = method.toUpperCase() === 'POST' ? (isJson ? JSON.stringify(params) : qs.stringify(params)) : params
    // console.log(cancelToken,'01');
    return new Promise((resolve, reject) => {
        axios(
            method.toUpperCase() === 'POST'
                ? {
                      url: `${prefix}${url}`,
                      headers: formatHeaders(headers),
                      data: qsParams,

                      method: method || 'post',
                      cancelToken: cancelToken,
                  }
                : method.toUpperCase() === 'BLOB'
                ? {
                      url: `${prefix}${url}`,
                      headers: formatHeaders(headers),

                      params: qsParams,
                      method: method || 'post',
                      cancelToken: cancelToken,
                  }
                : {
                      url: `${prefix}${url}`,
                      headers: formatHeaders(headers),

                      params: qsParams,
                      method: method || 'post',
                      cancelToken: cancelToken,
                  }
        )
            .then((data) => {
                resolve(data.data)
                if (data.data.code == 403) {
                    var userInfo = {}
                    window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
                    removeCookie('token')
                    // 清除所有cookie
                    var keys = document.cookie.match(/[^ =;]+(?==)/g)
                    if (keys) {
                        for (var i = keys.length; i--; ) {
                            document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString()
                            document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString()
                            document.cookie = keys[i] + '=0;path=/;domain=.xfnh.com;expires=' + new Date(0).toUTCString()
                        }
                    }
                    window.location.href = '/'
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    // 处理错误
                    reject(err)
                    throw new Error(`Error:${err}`)
                }
            })
    })
}
export default http
