<template>
  <div class="floor_new">
    <div class="new_top">
      <div class="title">{{ title }}</div>
      <div class="new_do">
        <GI class="icon_do" v-show="!groupStatus.prev" name="market_left" ws="32" hs="32"></GI>
        <GI class="icon_do" v-show="groupStatus.prev" name="market_left_active" ws="32" hs="32" @click="$refs.group.prev()"></GI>
        <GI class="icon_do" v-show="!groupStatus.next" name="market_right" ws="32" hs="32"></GI>
        <GI class="icon_do" v-show="groupStatus.next" name="market_right_active" ws="32" hs="32" @click="$refs.group.next()"></GI>
      </div>
    </div>
    <Group ref="group" @change="changeGroup">
      <div class="group_item" v-for="(item, index) of 20" :key="index">
        <div class="item_main">
          <BoxItem hs="200" titType="item_title_small" descType="item_desc_small">
            <div class="project_time" slot="image">
              <Time time="2022-12-12"></Time>
            </div>
          </BoxItem>
        </div>
      </div>
    </Group>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      groupStatus: {
        prev: false,
        next: false,
      },
    }
  },
  mounted() {},
  methods: {
    changeGroup(obj) {
      this.groupStatus = Object.assign(this.groupStatus, obj)
    },
  },
}
</script>
<style lang="scss" scoped>
.project_time {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.floor_new {
  .group_item {
    width: 305px;
    .item_main {
      width: 275px;
      background: var(--color_white);
      margin-right: 20px;
      padding: 16px;
      box-sizing: border-box;
      box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
      border-radius: 16px;
      white-space: wrap !important;
      &:hover {
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
      }
    }
  }
  .new_top {
    .new_do {
      display: flex;
      align-items: center;
      .icon_do {
        cursor: pointer;
        margin-left: 20px;
      }
    }
    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: var(--font_deep_color);
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  width: 100%;
  max-width: var(--max_width);
  margin: auto;
  margin-top: 48px;
}
</style>
