<template>
    <div class="setting">
        <div class="setting_center">
            <div class="tab_box">
                <div class="tabs">
                    <div :class="['tab_item',tab === item.value && 'tab_active']" v-for="(item,index) in tablist" 
                    :key="index" @click="switchTab(item.value)">
                        <GI :name="tab === item.value ? item.iconActive :item.icon" ws="24" hs="24"></GI>
                        <div class="tab_title">{{item.label}}</div>
                    </div>
                    <div class="tab_bottom"></div>
                </div>
            </div>
            <div class="main">
                <StepRun ref="tab">
                    <Information slot="tab1"></Information>
                    <Notice slot="tab2"></Notice>
                    <Support slot="tab3"></Support>
                    <Submit slot="tab4"></Submit>
                </StepRun>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab:1,
            tablist:[
                {
                    label:this.langJson("Profile"),
                    value:1,
                    icon:"personal_information",
                    iconActive:"personal_information_active"
                },
                {
                    label:this.langJson("Notice"),
                    value:2,
                    icon:"personal_notice",
                    iconActive:"personal_notice_active"
                },
                {
                    label:this.langJson("Account Support"),
                    value:3,
                    icon:"personal_support",
                    iconActive:"personal_support_active"
                },
                {
                    label:this.langJson("Submit NFT Project"),
                    value:4,
                    icon:"personal_support",
                    iconActive:"personal_support_active"
                }
            ]
        }
    },
    mounted() {
        if(this.$route.query.tab) {
            this.tab = parseInt(this.$route.query.tab)
            this.switchTab(this.tab)
        }
    },
    methods:{
        switchTab(val) {
            this.tab = val
            this.tablist.find((item,index) => {
                if(item.value == val){
                    this.$refs.tab.jump(index)
                    return true
                }else{
                    return false
                }
            })
            this.jump_setting(val)
        }
    }
}
</script>
<style lang="scss" scoped>
.setting{
    display: flex;
    .setting_center{
        .tab_box{
            .tabs{
                .tab_bottom{
                    height:1px;
                }
                .tab_item{
                    .tab_title{
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color:var(--font_modif_color);
                        margin-left:8px;
                    }
                    display: flex;
                    height:52px;
                    display: flex;
                    align-items: center;
                    margin-bottom:24px;
                    padding:0 24px;
                    box-sizing: border-box;
                    border-radius: 26px;
                    border: 2px solid transparent;
                    cursor: pointer;
                }
                .tab_active{
                    border: 2px solid var(--main_color);
                    .tab_title{
                        // background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                        // background-clip: text;
                        // text-fill-color: transparent;
                        color:var(--main_color);
                        font-weight: 700;
                    }
                }
                width: 100%;
                background:var(--color_white);
                padding: 24px;
                padding-bottom:0;
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 32px;
                box-sizing: border-box;
            }
            min-width: 296px;
        }
        
        .main{
            background:var(--color_white);
            border-radius: 32px;
            flex:1;
            margin-left:32px;
            padding: 32px;
            min-height:500px;
            box-sizing: border-box;
        }
        display: flex;
        align-content: flex-start;
        width:100%;
        max-width:var(--max_width);
        margin: auto;
        margin-top:32px;
    }
    
}
@media screen and (max-width: 1080px) {
    .setting{
        flex-direction: column;
    }
}
</style>