<template>
    <div
        :class="['mask', show ? 'maskShow' : 'maskHide']"
        :style="{
            display: waitrun ? `flex` : `none`,
            zIndex: show ? 2000 : -1,
        }"
        @click.stop="$emit('mask')"
    >
        <div v-if="waitrun" :class="['inner', show ? 'innerShow' : 'innerHide']">
            <div class="inner_main">
                <div class="inner_content">
                    <slot name="main"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Model',
    data() {
        return {
            loading: false,
            width: '0%',
            waitrun: false,
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show: function (n) {
            if (n) {
                this.waitrun = n
            } else {
                setTimeout(() => {
                    this.waitrun = n
                }, 500)
            }
        },
    },
    created() {},
    methods: {
        close() {
            this.$emit('change', false)
        },
        confirm() {
            this.loading = true
            this.close()
            this.$emit('Confirm')
            this.loading = false
        },
        cancle() {
            this.loading = true
            this.close()
            this.$emit('Cancle')
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.mask {
    position: fixed!important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: rgba($color: #fff, $alpha: 1);
    display: flex;
    align-items: center;
    justify-content: center;
     // 时间选择弹窗层级为 2001 tabbar为1999

    .inner {
        .inner_main {
            min-height: 10vh;
            height: 100vh;
            overflow: auto;
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            justify-content: center;
            .inner_content {
                width: 100%;
                height: auto;
            }
        }
        position: relative;
        min-width: 300px;
        border-radius: 8px;
        background-color: rgba($color: #fff, $alpha: 1);
        /* backdrop-filter: blur(10px); */
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}
@media screen and (max-width: 500px) {
    .mask {
        align-items: flex-end;
        .inner {
            width: 100%;
            border-radius: 12px 12px 0 0;
        }
    }
}
.maskShow {
    animation: maskShow 0.5s ease-in;
}
.maskHide {
    animation: maskHide 0.5s ease-in !important;
}
@keyframes maskShow {
    0% {
        opacity: 0;
        display: none;
    }
}
@keyframes maskHide {
    100% {
        opacity: 0;
        display: flex;
    }
}

.innerShow {
    animation: innerShow 0.5s ease;
}
.innerHide {
    animation: innerHide 0.5s ease forwards;
}
@keyframes innerShow {
    0% {
        transform: translateY(100vh);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes innerHide {
    0% {
        transform: translateY(0px);
        opacity: 0;
    }
    100% {
        transform: translateY(100vh);
    }
}
</style>


