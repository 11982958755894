<template>
    <div class="setting">
        <div class="setting_center">
            <div ref="tab_box" class="tab_box">
                <div class="tabs">
                    <div :class="[tab === item.value && 'border_tab']" v-for="(item, index) in tablist" :key="index" @click="switchTab(item.value)" class="tab_inner">
                        <div class="tab_item">
                            <GI :name="tab === item.value ? item.iconActive : item.icon" ws="24" hs="24"></GI>
                            <div class="tab_title">{{ item.label }}</div>
                        </div>
                    </div>
                    <div class="tab_bottom"></div>
                </div>
            </div>
            <div class="main">
                <StepRun ref="tab">
                    <BrandInfomation slot="tab1"></BrandInfomation>
                    <Verification slot="tab2"></Verification>
                    <Notification slot="tab3"></Notification>
                    <!-- <SubscriptionPlan slot="tab4"></SubscriptionPlan> -->
                    <AccountSupport slot="tab4"></AccountSupport>
                </StepRun>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab: 1,
            from: this.$route.query.from,
            saveModel: false,
            tablist: [
                {
                    label: this.langJson('Brand information'),
                    value: 1,
                    icon: 'profile_brandinformation',
                    iconActive: 'profile_brandinformation_active',
                },
                {
                    label: this.langJson('Verification'),
                    value: 2,
                    icon: 'profile_verification',
                    iconActive: 'profile_verification_active',
                },
                {
                    label: this.langJson('Notification'),
                    value: 3,
                    icon: 'profile_notification',
                    iconActive: 'profile_notification_active',
                },
                // {
                //   label: this.langJson('Subscription plan'),
                //   value: 4,
                //   icon: 'profile_scriptionplan',
                //   iconActive: 'profile_scriptionplan_active',
                // },
                {
                    label: this.langJson('Account support'),
                    value: 4,
                    icon: 'profile_brandinformation',
                    iconActive: 'profile_brandinformation_active',
                },
            ],
            isMouseDown: false,
            startX: 0,
        }
    },
    mounted() {
        if (this.$route.query.tab) {
            this.tab = parseInt(this.$route.query.tab)
            setTimeout(() => {
                this.switchTab(1)
            }, 200);
        }
    },
    methods: {
        switchTab(val) {
            this.tab = val
            this.tablist.find((item, index) => {
                if (item.value == val) {
                    this.$refs.tab.jump(index)
                    return true
                } else {
                    return false
                }
            })
            // this.jump_setting(val)
        },
        saveChanges() {
            this.saveModel = true
        },
        closeNotify() {
            this.saveModel = false
        },
        cancelNotify() {
            this.saveModel = false
        },
    },
}
</script>
<style lang="scss" scoped>
.setting {
    .setting_center {
        .tab_box {
            .tabs {
                display: flex;
                flex-direction: column;
                gap: 24px;
                .tab_bottom {
                    height: 1px;
                }
                .tab_item {
                    .tab_title {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_modif_color);
                        margin-left: 8px;
                    }
                    display: flex;
                    height: 52px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    position: relative;
                    cursor: pointer;
                }
                .tab_inner {
                    padding-left: 24px;
                    border: 2px solid transparent;
                    border-radius: 32px;
                }
                .border_tab {
                    border: 2px solid transparent;
                    border-radius: 32px;
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    background-image: linear-gradient(to right, var(--color_white), var(--color_white)), linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    padding-left: 24px;
                    .tab_title {
                        background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        // color: var(--main_color);
                        font-weight: 700;
                    }
                }
                background: var(--color_white);
                padding: 24px;
                padding-bottom: 0px;
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 32px;
                box-sizing: border-box;
            }
            min-width: 296px;
        }

        .main {
            background: var(--bg_color);
            border-radius: 32px;
            min-height: 500px;
            width: 100%;
            box-sizing: border-box;
        }
        display: flex;
        gap: 32px;
        align-content: flex-start;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 32px;
    }
}
.right_go {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}

@media only screen and (max-width: 1081px) {
    .setting_center {
        flex-direction: column;
        max-width: 100% !important;
        margin: 0px !important;
        gap: 0px !important;
        .main {
            width: 100%;
            margin-right: 0px;
        }
    }
    .tab_box {
        height: 102px;
        display: flex;
        align-items: center;
        overflow: auto;
    }
    .tabs {
        flex-direction: row !important;
        padding: 0px !important;
        background: transparent !important;
        .tab_item {
            min-width: 194px;
        }
    }
}
</style>
