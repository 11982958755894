<template>
    <div class="model_main">
        <div class="tip">
            {{ langJson('Do you want to delete your campaign') }}
        </div>

        <div class="model_btns">
            <div class="model_btn">
                <BTN class="btn_item" @handle="close">{{ langJson('Cancel') }}</BTN>
            </div>

            <div class="model_btn">
                <BTN class="btn_item" type="active" @handle="submitArtwork">{{ langJson('Delete campaign') }}</BTN>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
        }
    },

    props: {
        id: {
            type: [Number, String],
            default: '',
        },
        titleActive: {
            type: String,
            default: '',
        },
    },
    methods: {
        async submitArtwork() {
            try {
                let { dispatch } = this.$store
                let res = await dispatch('campaignDelete', {
                    id: this.id,
                })
                if (res && res.code) {
                    this.$emit('complete')
                    this.close()
                    this.pushMsg('success', this.langJson('Delete success'))
                }
            } catch (err) {
                console.log(err)
            }
        },
        close() {
            this.$emit('cancel', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .nft_preview {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 16px;
        background-color: var(--bg_color);
        margin: 20px 0;
    }
    .tip {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--font_deep_color);
        margin-top: 32px;
        text-align: center;
    }
    .information_item {
        margin-top: 32px;
    }
    .model_btns {
        .model_btn {
            flex: 1;
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 77vw;
    }
}
</style>
