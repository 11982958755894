<template>
    <div class="item">
        <div v-show="showHead" class="brand_detail">
            <CheckBox @click.native="selectItem" :active="selected"></CheckBox>
            <div class="rank_vote" v-show="!isParticipant" v-if="top != ''">
                {{ langJson('No.') }}{{ top }}

                <!-- {{ item.sort }} -->
            </div>
        </div>
        <div class="rank_vote2" v-show="item.contractType == 1155">x {{ item.amount }}</div>

        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image cursor" :style="{ backgroundImage: `url(${item.preview})` }" @click="isParticipant ? jump_participant_profile_ntfdetail({ idNft: item.id, idUser: item.userId }) : jump_brand_profile_ntfdetail({ idNft: item.id, idUser: item.userId })"></div>
        </div>

        <div class="item_info">
            <div class="item_title">
                <div class="title">{{ item.name }}</div>
                <!-- <span class="item_subtitle">@{{ item.artworkCredit }}</span> -->
                <!-- 活动名 和活动的链接 -->
                <!-- <span v-show="showCompaign" @click="jump_participant_compaign_preview({ id: item.campaignId })" class="artworks_for_compaign">
                    {{ item.campaignName }}
                </span> -->
            </div>
            <!-- <div v-show="showBottom" class="item_desc">
                <span>{{ item.voteNum }} {{ langJson('votes') }}</span>
                <BTN v-show="isParticipant" @handle="vote(item)" type="detail" bw="88" bh="36" :class="[item.voteFlag && 'voted']">{{ langJson('Vote') }}</BTN>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        // 前三
        top: {
            type: [String, Number],
            default: '',
        },
        // 展示头部
        showHead: {
            type: Boolean,
            default: false,
        },
        // 展示底部按钮
        showBottom: {
            type: Boolean,
            default: false,
        },
        // 蓝字下划线
        showCompaign: {
            type: Boolean,
            default: false,
        },
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
        selectlist: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    computed: {
        selected() {
            return this.selectlist.some((item) => {
                return item == this.item.id
            })
        },
    },
    data() {
        return {
            tagList: [
                { icon: 'brand_eye', val: 'Hide', isChecked: false, number: 1 },
                { icon: 'brand_edit', val: 'Edit', isChecked: false, number: 2 },
                { icon: 'brand_candle', val: 'Share', isChecked: false, number: 3 },
                { icon: 'brand_trash', val: 'Delete', isChecked: false, number: 4 },
            ],
            active: false,
            voteShow: false,
        }
    },

    methods: {
        selectItem() {
            this.$emit('select', this.item)
        },
        goRoute(item) {
            let that = this
            switch (item.number) {
                case 1:
                    break
                case 2:
                    break
                case 3:
                    that.$store.commit('changeShowShare', true)
                    this.$store.commit('shareObj', {
                        ...this.globalConfig.shareObj,
                        img: this.item.preview,
                        title: this.langJson('Share your ArtWork'),
                        url: ``,
                        linkName: this.langJson('Artwork link'),
                    })
                    break

                default:
                    break
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.voted {
    border-color: var(--font3) !important;
    color: var(--font3) !important;
}

.item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    gap: 16px;

    .item_info {
        .item_desc {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .item_title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
                margin-bottom: 4px;
            }
            .item_subtitle {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
            }
        }
        .item_subtitle {
            /* Body/1/Regular */
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Neutral/3 */

            color: var(--font_color);
        }
        .artworks_for_compaign {
            display: block;
            font-family: 'Inter';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-decoration-line: underline;
            margin-bottom: 16px;
            color: #3993e6;
            cursor: pointer;

            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: space-between;
        height: 32px;
        .rank_vote {
            width: 61px;
            background: #ffeae0;
            border-radius: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            color: #ff9466;
        }
    }
    .rank_vote2 {
        position: absolute;
        top: 24px;
        right: 24px;
        font-family: 'Lato';
        font-size: 12px;
        width: 61px;
        background: #ffeae0;
        border-radius: 32px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #ff9466;
        z-index: 10;
    }

    .image_out {
        border-radius: 20px;
        overflow: hidden;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
            cursor: pointer;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
.dialog_main {
    width: 422px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    h1 {
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: var(--font_deep_color);
    }
    span {
        text-align: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--font_deep_color);
    }
}

.brand_detailThreePoints {
    display: flex;
    justify-content: flex-end;
    height: 40px;
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
@media only screen and (max-width: 1080px){
    .item{
        padding: 16px
    }
}
@media only screen and (max-width: 500px) {
    .item{
        padding: 16px
    }
    .dialog_main {
        width: 74.4vw !important;
    }
    .image_out {
        border-radius: 20px !important;
    }
}
</style>
