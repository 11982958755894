<template>
    <div class="go">
        <div class="go_center">
            <div class="go_right">
                <div class="go_prev" @click="trigglePrev">
                    <GI v-show="!!prev" name="global_previous" ws="32" hs="16" @click="trigglePrev"></GI>
                    <div v-show="!!prev" class="go_title">{{ prev }}</div>
                    <div v-if="status == 'live'" class="live" :style="{ backgroundImage: `url(/source/png/brand/live.png)` }"></div>
                    <div v-if="status == 'archive'" class="archive" :style="{ backgroundImage: `url(/source/png/brand/archive.png)` }"></div>
                </div>
                <div class="hoster">
                    <h2>{{ descText }}</h2>
                </div>
            </div>

            <div class="go_next" @click="triggleNext">
                <div v-show="!!next" class="go_title">
                    {{ next }}
                </div>
                <GI v-show="!!next" :name="globalTheme == 'light' ? 'global_next' : 'global_next_light'" ws="32" hs="16" @click="triggleNext"></GI>
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        prev: {
            type: String,
            default: '',
        },
        descText: {
            type: String,
            default: '',
        },
        next: {
            type: String,
            default: '',
        },
        status: {
            type: String,
            default: '',
        },
    },
    methods: {
        trigglePrev() {
            this.$emit('prev')
        },
        triggleNext() {
            this.$emit('next')
        },
    },
}
</script>
<style lang="scss" scoped>
.go {
    .go_center {
        .go_next {
            display: flex;
            align-items: center;
            cursor: pointer;
            .go_title {
                margin-right: 16px;
                font-size: 20px;
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
            }
        }
        .go_prev {
            display: flex;
            align-items: center;
            cursor: pointer;
            .go_title {
                margin-left: 16px;
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
            }
            .live {
                width: 88px;
                height: 36px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-left: 16px;
            }
            .archive {
                width: 88px;
                height: 36px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-left: 16px;
            }
        }
        width: 100%;
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    padding-top: 40px;
    padding-bottom: 32px;
    height: 144px;
    width: 100%;
    margin: auto;
    max-width: var(--max_width);
    box-sizing: border-box;
    .hoster {
        h2 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
}
</style>
