<template>
    <Model :padding="'24px'" v-model="modelShow" @change="close">
        <div class="model_main" slot="main">
            <div class="tip">
                {{ langJson('For a seamless experience, please use a laptop/desktop. To create a campaign on mobile, you will be redirected to Metamask. Please ensure you have installed Metamask on your mobile. Once you have logged into Metamask, please log back into the account and click on "Create new Campaign". You will be prompted to load your current draft. After loading it, you can deploy your contract in Metamask.') }}
            </div>

            <div class="model_btns">
                <div class="model_btn">
                    <BTN class="btn_item" @handle="close" type="detail" bh="52">{{ langJson('Cancel') }}</BTN>
                </div>

                <div class="model_btn">
                    <BTN class="btn_item" type="active" @handle="loginJump">{{ langJson('Confirm') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>

<script>
export default {
    computed: {},
    model:{
        prop:"show",
        event:"change"
    },
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            modelShow: false,
        }
    },
    created() {
        this.modelShow = this.show
    },
    watch: {
        modelShow(n) {
            this.$emit('change', n)
        },
        show(n) {
            this.modelShow = n
        },
    },
    methods: {
        close() {
            this.$emit("change",false)
        },
        loginJump() {
            this.close()
            this.$emit("confirm")
        },
    },
}
</script>

<style lang="scss" scoped>
.model_main {
    .nft_preview {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 16px;
        background-color: var(--bg_color);
        margin: 20px 0;
    }
    .tip {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--font_deep_color);
        margin-top: 32px;
        text-align: center;
    }
    .information_item {
        margin-top: 32px;
    }
    .model_btns {
        .model_btn {
            flex: 1;
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 77vw;
    }
}
</style>
