<template>
    <div class="nftSourceMp4">
        <div class="imgSource">
            <!-- 视频 -->
            <!-- @loadeddata="loadingEnd = true" -->
            <video ref="paly" :autoplay="Autoplay" class="cover mp4" @ended="isPlay = false" @click="zanTing()">
                <source :src="Data2" type="video/ogg" />
                <source :src="Data2" type="video/webm" />
                <source :src="Data2" type="video/mp4" />
                <source :src="Data2" type="video/wmv" />
                <source :src="Data2" type="video/rm" />
                <source :src="Data2" type="video/rmvb" />
                <source :src="Data2" type="video/asf" />
                <source :src="Data2" type="video/mov" />
                <source :src="Data2" type="video/avi" />
                <source :src="Data2" type="video/navi" />
                <embed height="50" width="100" :src="Data2" />
            </video>
            
            <!-- 预览 -->
            <!--  v-show="!isPlay"  -->
            <img v-show="false" class="cover" :src="Preview2" @load="load()" @error="error()" />
            <!-- <canvas id="canvas" width="640" height="360"></canvas> -->
            <!-- loading -->
            <div class="img_loading" v-show="!loadingEnd">
                <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
                <div class="loading_text">{{ langJson('loading') }}</div>
            </div>
            <!-- <div class="maskLoad" v-show="loadingEnd">
                <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
            </div> -->
            <!-- 播放 -->
            <div class="icon_play" v-show="!isUploadFile" v-if="!isPlay && loadingEnd" @click="play()">
                <GI v-if="!isPhone" class="auction" name="play_" ws="100" hs="67.2"></GI>
                <GI v-if="isPhone" class="auction" name="play_" ws="58.06" hs="39.02"></GI>
            </div>

            <div class="mask" v-show="!isUploadFile" v-if="isPlay && loadingEnd" :style="{ opacity: 1 }" :class="[isPhone ? (pauseShow ? 'pauseShow' : '') : 'isPc']" @click="pause()">
                <GI v-if="!isPhone" class="auction" name="pause" ws="100" hs="67.2"></GI>
                <GI v-if="isPhone" class="auction" name="pause" ws="58.06" hs="39.02"></GI>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        Data: {
            type: String,
            default: String,
        },
        isDetail: {
            type: Boolean,
            default: Boolean,
        },
        Autoplay: {
            type: Boolean,
            default: false,
        },
        Preview: {
            type: String,
            default: String,
        },
        fit: {
            type: String,
            default: 'scale-down',
        },
        isUploadFile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return { Preview2: '', Data2: '', loadingEnd: false, isPlay: false, pauseShow: false }
    },
    watch: {
        Preview: {
            handler(val) {
                this.Preview2 = val
            },
            immediate: true,
            deep: true,
        },
        Data: {
            handler(val) {
                this.Data2 = val
            },
            immediate: true,
            deep: true,
        },
    },
    created() {},
    mounted() {
        var audio = this.$refs.paly
        audio.loop = false
        audio.addEventListener(
            'ended',
            () => {
                console.log('endedendedendedendedended')
                this.isPlay = false // 按钮恢复
            },
            false
        )
        // audio.addEventListener(
        //     'loadeddata',
        //     () => {
        //         console.log('loadeddataloadeddataloadeddataloadeddata')
        //         const canvas = document.getElementById('canvas')
        //         const ctx = canvas.getContext('2d')
        //         ctx.drawImage(audio, 0, 0, canvas.width, canvas.height)
        //     },
        //     false
        // )
        // audio.addEventListener(
        //     'canplay',
        //     () => {
        //         let t = setTimeout(() => {
        //             console.log('canplaycanplaycanplaycanplaycanplay')
        //             const canvas = document.getElementById('canvas')
        //             const ctx = canvas.getContext('2d')
        //             ctx.drawImage(audio, 0, 0, canvas.width, canvas.height)
        //             clearTimeout(t)
        //         }, 5000)
        //     },
        //     false
        // )
    },
    computed: {},
    methods: {
        zanTing() {
            this.pauseShow = true
            if (this.isPhone) {
                setTimeout(() => {
                    this.pauseShow = false
                }, 1000)
            }
        },
        load() {
            this.loadingEnd = true
        },
        error() {
            this.loadingEnd = true
        },
        pause() {
            this.$refs.paly.pause()
            this.isPlay = !this.isPlay
        },
        play() {
            this.isPlay = !this.isPlay
            this.$nextTick(() => {
                this.$refs.paly.play()
                this.pauseShow = true
                if (this.isPhone) {
                    setTimeout(() => {
                        this.pauseShow = false
                    }, 1000)
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
#canvas {
    height: 100%;
    width: 100%;
}
.nftSourceMp4 {
    height: 100%;
    width: 100%;
}
.auction {
    cursor: pointer;
}
.none {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgSource {
    width: 100%;
    height: 100%;
    position: relative;
    .maskLoad {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 19px;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mask {
        z-index: -2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: none;
        transition: all 0;
        &.pauseShow {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 2;
            opacity: 0;
            transition: all 0;
        }
    }
    &:hover {
        .isPc {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 2;
            opacity: 0;
            transition: all 0;
        }
    }

    .icon_play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .img_loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        .loading_text {
            margin-top: 6px;
            color: #fff;
            font-size: 16px;
            font-family: 'Cabinet Grotesk';
        }
    }
    .cover {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.mp4 {
            object-fit: cover;
            /* object-fit: contain; */
        }
    }
}
</style>
