<template>
    <div class="brand">
        <img class="brand_icon" :src="item.imageUrl" alt="" />
        <div class="brand_info">
            <div>
                <div class="name">{{ item.nickName }}</div>
                <div class="number">
                    <span>{{ item.workNum }} {{ langJson('artworks') }}</span>
                    |
                    <span>{{ item.totalVote }} {{ langJson('votes') }}</span>
                </div>
            </div>
            <div class="rightTopNumber" v-if="index < 3">{{ 'Top' }}{{ index + 1 }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        index: {
            type: [String, Number],
            default: () => {
                return ''
            },
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.brand {
    display: flex;
    .brand_info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
            font-family: 'Lato';
            font-style: normal;
            /* font-weight: 700; */
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
        .number {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font1);
        }
        .rightTopNumber {
            padding: 8px 12px;
            background: var(--bg2);
            border-radius: 32px;
            color: var(--font2);
            font-family: 'Lato';
            font-style: normal;
            /* font-weight: 600; */
            font-size: 12px;
            line-height: 16px;
        }
    }
    .brand_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
    }
    &:hover {
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
    }
    /* width: 405px; */
    width: 296px;
    height: 68px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: var(--borderRadius);
    padding: 14px 12px;
    box-sizing: border-box;
    background: var(--bg1);
    display: flex;
    align-items: center;
    cursor: pointer;
}
@media screen and (max-width: 1080px) {
    .brand {
        display: flex;
        .brand_info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
                font-family: 'Lato';
                font-style: normal;
                /* font-weight: 700; */
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
            }
            .number {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font1);
            }
            .rightTopNumber {
                padding: 8px 12px;
                background: var(--bg2);
                border-radius: 32px;
                color: var(--font2);
                font-family: 'Lato';
                font-style: normal;
                /* font-weight: 600; */
                font-size: 12px;
                line-height: 16px;
            }
        }
        .brand_icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 8px;
        }
        &:hover {
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
        }
        /* width: 405px; */
        width: 100%;
        height: 68px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: var(--borderRadius);
        padding: 14px 12px;
        box-sizing: border-box;
        background: var(--bg1);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
</style>
