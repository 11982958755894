<template>
    <div class="graphic">
        <!-- 登录就隐藏，没登录就显示 -->
        <!-- <StartHead v-if="showHead"></StartHead> -->
        <div class="graphic_main">
            <div class="main_content">
                <div class="title">{{ langJson('What are NFTs') }}</div>
                <div class="desc">{{ langJson('An NFT (non-fungible token) is a unique token. It cannot be copied, substituted, or subdivided.') }}</div>
                <div class="desc">{{ langJson('NFTs are used to certify ownership and authenticity. ') }}</div>
                <div class="desc">{{ langJson('The ownership of an NFT is recorded in the blockchain and can be transferred by the owner, allowing NFTs to be shared, sold and traded.') }}</div>
                <div class="bg" :style="{ backgroundImage: `url(/source/png/index_article_title.png)` }"></div>
            </div>
            <img src="/source/png/graphic_banner.png" alt="" class="main_image" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showHead: true,
        }
    },
    mounted() {
        if (eval(this.$route.query.showHead) == false) {
            this.showHead = eval(this.$route.query.showHead)
        }
    },
}
</script>
<style lang="scss" scoped>
.graphic {
    .graphic_main {
        .main_content {
            .bg {
                position: absolute;
                top: -40px;
                right: 0px;
                width: 410px;
                height: 150px;
                background-position: top left;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }

            .desc {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
                margin-top: 8px;
            }

            .title {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 56px;
                line-height: 64px;
                color: var(--font_deep_color);
            }

            width: 515px;
            position: relative;
        }

        .main_image {
            width: 600px;
            // height: 641px;
        }

        height: 769px;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1080px) {
    .graphic_main {
        flex-direction: column;
        justify-content: flex-start !important;
        height: fit-content !important;
        margin-top: 40px !important;
        margin-bottom: 24px !important;

        .title {
            text-align: center;
            font-size: 32px !important;
        }

        .bg {
            background-position: center center !important;
            max-width: var(--max_width);
        }

        .main_content {
            max-width: var(--max_width);
            .desc {
                text-align: center;
                font-size: 14px !important;
                line-height: 20px !important;
                margin-top: 0px !important;
            }
        }

        .main_image {
            max-width: var(--max_width);
            width: 100%;
            // height: 641px;
        }
    }
}
</style>
