<template>
    <div class="time">
        <!-- <div class="title">{{title}}</div> -->
        <div class="items">
            <div class="item">
                <div :class="['item_btn',type]">{{timeObj.days}} {{langJson("D")}}</div>
                <!-- <div class="item_title">{{langJson("DAYS")}}</div> -->
            </div>
            <div class="item">
                <div :class="['item_btn',type]">{{timeObj.hours}} {{langJson("H")}}</div>
                <!-- <div class="item_title">{{langJson("HOURS")}}</div> -->
            </div>
            <div class="item">
                <div :class="['item_btn',type]">{{timeObj.minutes}} {{langJson("M")}}</div>
                <!-- <div class="item_title">{{langJson("MINUTES")}}</div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            refer:1,
            target:null
        }
    },
    computed:{
        timeObj() {
            return this.refer && this.remainTime(this.time)
        }
    },
    props:{
        title:{
            type:String,
            default:""
        },
        time:{
            type:[String,Number],
            default:0
        },
        type:{
            type:String,
            default:"active"
        }
    },
    created() {
        this.target = setInterval(() => {
            this.refer = new Date().getTime()
        },1000)
    },
    beforeDestroy() {
        clearInterval(this.target)
    }
}
</script>
<style lang="scss" scoped>
.time{
    .items{
        .item{
            .item_title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                color:var(--font_color);
                margin-top:4px;
            }
            .item_btn{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 2px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // width: 56px;
                white-space: nowrap;
                height: 30px;
                padding: 6px 10px;
                
                border-radius: 4px;
                box-sizing: border-box;

            }
            .active{
                background: var(--main_color);
                color:var(--color_white);
            }

            .cancel{
                background: var(--color_white);
                color:var(--font_modif_color);
            }
            // height: 60px;
            width: 40px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title{
        text-align: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color:var(--font_deep_color);
    }
    padding:0 10px;
    gap: 16px;
    box-sizing: border-box;
    // position: absolute;
    width: 200px;
    // height: 86px;
    // left: 20px;
    // bottom: 20px;
    // background: rgba(255, 255, 255, 0.2);
    // backdrop-filter: blur(10px);
    border-radius: 6px;
}
</style>