<template>
    <div class="notice">
        <div class="title">{{langJson("Event types")}}</div>
        <div class="tip">{{langJson("Select the notifications you would like to receive")}}</div>

        <div class="notice_item" v-for="(item,index) in list" :key="index">
            
            <div class="item_detail">
                <GI name="personal_notice" ws="24" hs="24"></GI>
                <div class="item_information">
                    <div class="item_title">{{item.title}}</div>
                    <div class="item_desc">{{item.description}}</div>
                </div>
            </div>
            <SW v-model="item.status"></SW>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            list:[
                {
                    id:1,
                    title:this.langJson("Goods sold"),
                    description:this.langJson("When someone buys one of your goods"),
                    status:true
                },
                {
                    id:2,
                    title:this.langJson("Auction goods"),
                    description:this.langJson("When someone bids for an item of yours"),
                    status:true
                },
                {
                    id:3,
                    title:this.langJson("Auction expiration"),
                    description:this.langJson("When the auction you created ends"),
                    status:false
                },
                {
                    id:4,
                    title:this.langJson("Bid exceeded"),
                    description:this.langJson("When bidding for other users exceeds your quotation"),
                    status:true
                },
                {
                    id:5,
                    title:this.langJson("Purchase successful"),
                    description:this.langJson("When you successfully purchase the product"),
                    status:false
                },
                {
                    id:6,
                    title:this.langJson("Successful bidding"),
                    description:this.langJson("When you win the bid"),
                    status:true
                },
                {
                    id:7,
                    title:this.langJson("At the end of the sale"),
                    description:this.langJson("When your goods are sold"),
                    status:false
                }
            ],
            isOn:true
        }
    },
    created() {
        this.$watch("form",(n) => {
            console.log(n)
        },{
            deep:true
        })
    }
}
</script>
<style lang="scss" scoped>
.notice{
    .notice_item{
        .item_detail{
            display: flex;
            align-items: center;
            .item_information{
                .item_title{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color:var(--font_deep_color);
                }
                .item_desc{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color:var(--font_modif_color);
                }
                margin-left:24px
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:16px;
    }
    .tip{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color:var(--font_color);
        margin-top:8px;
        margin-bottom:24px;
    }
    .btns{
        display: flex;
        align-items: center;
        margin-top:30px;
        .btn_item{
            margin-right:20px;
        }
    }
    .information_item{
        margin-top:32px;
    }
    .title{
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color:var(--font_deep_color);
    }
}
</style>