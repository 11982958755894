import http from './base'

/**
 *@param pageSize
 *@param pageNum
 *
 *我的活动列表
 */
let myCampaigns = (params = {}) => {
    return http.get(`/campaign/brandCampaignList`, params)
}
/**
 *
 *
 * 新增活动
 */
let newCampaign = (params = {}) => {
    return http.postJson(`/campaign/save`, params)
}

/**
 *
 *
 * 修改活动
 */
let updateCampaign = (params = {}) => {
    return http.postJson(`/campaign/update`, params)
}

/**
 *
 *
 * 活动详情
 */
let campaignDetail = (params = {}) => {
    return http.get(`/campaign/${params.id}`, params)
}

/**
 *
 * @param campaignId
 * 活动作品
 */
let campaignArtwork = (params = {}) => {
    return http.get(`/campaign/works/list`, params)
}

let importData = (file) => {
    return http.upload('/brand/client/importData', file)
}

let clientData = (params = {}) => {
    return http.get('/brand/client/list', params)
}

let clientSave = (params = {}) => {
    return http.postJson('/brand/client/save', params)
}

let clientInvite = (params = {}) => {
    return http.postJson('/brand/client/invite', params)
}

let clientDelete = (params = {}) => {
    return http.postJson('/brand/client/delete', params)
}

let campaignDelete = (params = {}) => {
    return http.post('/campaign/delete', params)
}

let artworkDelete = (params = {}) => {
    return http.postJson('/campaign/works/delete', params)
}

let removedArtwork = (params = {}) => {
    return http.get('/campaign/works/selectDeleted', params)
}

let completelyDelete = (params = {}) => {
    return http.postJson('/campaign/works/completelyDelete', params)
}

let campaignParticipants = (params = {}) => {
    return http.get('/campaign/works/selectParticipants', params)
}

let recoverArtwork = (params = {}) => {
    return http.postJson('/campaign/works/recover', params)
}

let notifyWinners = (params = {}) => {
    return http.postJson('/campaign/works/notifyWinners', params)
}

let selectCampaignWorks = (params = {}) => {
    return http.get('/campaign/works/selectCampaignWorks', params)
}
let joinCampaignList = (params = {}) => {
    return http.get('/campaign/joinCampaignList', params)
}


let draftSave = (params = {}) => {
    return http.postJson('/user/drafts/save', params)
}
let draftClear = (params = {}) => {
    return http.post('/user/drafts/delete', params)
}
let getDraft = (params = {}) => {
    return http.get('/user/drafts/getDrafts', params)
}

let updateApply = (params = {}) => {
    return http.postJson('/campaign/updateApply', params)
}



export default {
    draftSave,
    draftClear,
    getDraft,


    myCampaigns,
    newCampaign,
    campaignDetail,
    campaignArtwork,
    updateCampaign,
    importData,
    clientData,
    clientSave,
    clientInvite,
    clientDelete,
    campaignDelete,
    removedArtwork,
    completelyDelete,
    artworkDelete,
    campaignParticipants,
    recoverArtwork,
    selectCampaignWorks,
    joinCampaignList,
    notifyWinners,
    updateApply
}
