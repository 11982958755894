<template>
    <div class="main_parent">
        <div class="main_box" :style="{ transform: `rotateX(-0deg) rotateY(${boxRotate}deg)` }">
            <div class="main_item"  v-for="(item,index) in list" :key="index" :style="{
                transform: `rotateY(${currentRotate(index)}deg) translateZ(48vw) scale(${scale(index)})`,
                width: `${width * 1}px`,
                height: `${height * 1}px`,
                opacity: `${scale(index) >= 0.7 ? 1 : 0}`,
                left:`calc(50% - ${width / 2}px)`,
            }"  @click="jump_staking_detail(1)">
                <div class="item_image" :style="{ backgroundImage:`url(${item.image})` }">

                </div>
                <div class="item_position">
                    <div class="title">Why is it all so abstract?</div>
                    <div class="desc">Official is a NFT collection of 20,001 PF Avatars that will give you membership access to The Yard. It starts with exclusive Giveaways among other top collections, regular NFT drops, contests and Treasury Hunt even, and much more that will be revealed over time. </div>
                    <div class="item_price">
                        <div class="item_lable">
                            {{langJson("Profit")}}
                        </div>
                        <div class="item_value">
                            <div class="amount">1 ETH</div>
                            <div class="usdtAmount">≈ $1284.45</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="main_left" @click="prev">
            <div class="icon">
                <GI name="msyter_left_white" ws="18" hs="18" @click="prev"></GI>
            </div>
        </div>
        <div class="main_right" @click="next">
            <div class="icon">
                <GI name="msyter_right_white" ws="18" hs="18" @click="next"></GI>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            current:0,
            rotate:0,

            list:[
                {
                    image:"https://w.wallhaven.cc/full/57/wallhaven-57o9j5.png"
                },
                {
                    image:"https://w.wallhaven.cc/full/1p/wallhaven-1p8dxg.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/6o/wallhaven-6o93p6.png"
                },
                {
                    image:"https://w.wallhaven.cc/full/d6/wallhaven-d6xxol.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/28/wallhaven-28pl29.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/85/wallhaven-857g11.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/1p/wallhaven-1p8dxg.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/6o/wallhaven-6o93p6.png"
                },
                {
                    image:"https://w.wallhaven.cc/full/d6/wallhaven-d6xxol.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/l3/wallhaven-l3xk6q.jpg"
                },
                {
                    image:"https://w.wallhaven.cc/full/28/wallhaven-28pl29.jpg"
                }
            ]
        }
    },
    props:{
        width:{
            type:Number,
            default:300
        },
        height:{
            type:Number,
            default:500
        }
    },
    computed:{
        medium() {
            return parseInt(this.list.length / 2)
        },
        boxRotate() {
            return 360 / this.list.length * this.rotate
        },
        currentRotate() {
            return (index) => {
                return this.unitAngle * index
            }
        },
        unitAngle() {
            return 360 / this.list.length
        },
        angle() {
            return (itemAngle) => {
                if(itemAngle > 180) {
                    itemAngle = itemAngle - 360
                }
                return itemAngle
            }
        },
        AngleDiff() {
            return (index) => {
                let indexAngle = this.angle(this.currentRotate(index))
                let currentAngle = this.angle(this.currentRotate(this.current))
                let difference = Math.abs(indexAngle - currentAngle)
                if(difference > 180) {
                    difference = 360 - difference
                }
                return difference
            }
        },
        scale() {
            return (index) => {
                let result = 1 - this.AngleDiff(index) / this.unitAngle * 0.1
                return result < 0.5 ? 0.5 : result
            }
        }

    },
    methods:{
        next() {
            if(this.current < this.list.length - 1) {
                this.current += 1
            }else{
                this.current = 0
            }
            this.rotate -= 1
        },
        prev(){
           if(this.current > 0) {
                this.current -= 1
            }else{
                this.current = this.list.length - 1
            } 
            this.rotate += 1
        }
    }
}
</script>

<style lang="scss" scoped>
.main_parent{
    .main_box{

        .main_item{
            border-radius: 16px;
            background:rgba($color: #000000, $alpha: 0.1);
            position: absolute;
            top:0;
            overflow: hidden;
            box-shadow: 5px 5px 20px #888;
            cursor: pointer;
            -webkit-box-reflect: below 0 -webkit-linear-gradient(transparent,transparent 50%,rgba(255,255,255,.3));
            .item_position{
                .item_price{
                    .item_lable{
                        font-family: 'Cabinet Grotesk';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 14px;
                    }
                    .item_value{
                        .amount{
                            font-family: 'Cabinet Grotesk';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 24px;
                            line-height: 24px;
                        }
                        .usdtAmount{
                            font-family: 'Cabinet Grotesk';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 14px;
                            margin-top:4px;
                        }
                        display: flex;
                        align-items: flex-end;
                        flex-direction: column;
                    }
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-top:16px;
                }
                .title{
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 36px;
                    color:#fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .desc{
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 20px;
                    color:#fff;
                    margin-top:10px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    overflow: hidden;
                }
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background: rgba(0,0,0,0.2);
                transform: scale(0);
                border-radius: 16px;
                color:#fff;
                padding:20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                cursor: pointer;
            }
            .item_image{
                width:100%;
                height:100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                // opacity: 0.9;
                color:#fff;
                
            }
        }
        .main_item:hover {
            .item_position{
                transform: scale(1);
            }
            .item_image {
                transform: scale(1.5);
            }
        }
        transform-style: preserve-3d;
        position: relative;
        width: 100%;
        height: 100%;
        transform: rotateX(-0deg);
        transition: all 1s!important;
    }
    .main_left{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        width: 80px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            background-color: #aaa;
            width:28px;
            height:28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 10;
        }
    }
    .main_right{
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        width: 80px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            background-color: #aaa;
            width:28px;
            height:28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 10;
        }
    }
    display: flex;
    justify-content: center;
    height:100%;
    width:100%;
    perspective: 10000;
    position: relative;
    
    
}


</style>