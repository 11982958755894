<template>
    <div>
        <div class="header">
            <h2>{{ langJson('Start with the basics') }}</h2>
            <span>{{ langJson('Make it easy for people to learn about your campaign') }}</span>
        </div>
        <div>
            <div class="form">
                <div class="form_box">
                    <h2>{{ langJson('Campaign title') }}</h2>
                    <div class="form_input">
                        <FormItem v-model="form.name" class="information_item" type="text"
                            :matchs="['unull', 'maxLength']" :title="langJson('Campaign Name')"></FormItem>
                    </div>
                    <div class="form_input">
                        <FormItem v-model="form.subtitle" class="information_item" type="text"
                            :matchs="['unull', 'maxLength']" :title="langJson('Subtitle ')"></FormItem>
                    </div>
                    <div class="form_switch">
                        <span>{{ langJson('Campaign type') }}</span>
                        <MintSel class="status_item" v-model="form.isPrivate" type="contract" :list="switchlist"
                            size="big"></MintSel>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="form_box_upload">
                    <FormItem v-model="form.banner" :title="langJson('Campaign Banner')" class="information_item"
                        type="image" :image="bannerConfig" :uploadMsg="langJson('Recommended ratio: 16 × 9')"
                        :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')"></FormItem>
                </div>

                <div class="form_box_upload mobile_upload">
                    <FormItem v-model="form.appBanner" :title="langJson('Mobile Banner')" class="information_item"
                        type="image" :image="bannerConfig" :uploadMsg="langJson('Recommended ratio: 9 × 16')"
                        :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')"></FormItem>
                </div>
            </div>
            <div class="form">
                <div class="form_switch">
                    <span>{{ langJson('Video Type') }} </span>
                    <div class="tip">{{ langJson("If the video size exceeds 8M, please choose to use YouTube") }}</div>
                    <MintSel class="status_item" v-model="form.imgType" type="contract" :list="videoType" size="big">
                    </MintSel>
                </div>
                <div v-if="form.imgType == 1" class="form_box_upload mt24">
                    <FormItem :size="300" v-model="form.campaignBanner" :title="langJson('Campaign Description')"
                        class="information_item" type="mp4" :image="bannerConfig2"
                        :uploadMsg="langJson('Recommended ratio: 4 × 3')"
                        :uploadTip="langJson('(Acceptable file format: mp4, ogg.)')"></FormItem>
                    <div class="information_item">
                        <FormItem :title="langJson('Write about your campaign')" :matchs="['unull']" class="textarea"
                            v-model="form.campaignDesc" height="188px" type="textarea"></FormItem>
                    </div>
                </div>
                <div v-if="form.imgType == 2" class="form_box_upload">
                    <div class="information_item">
                        <FormItem :title="langJson('YouTube Embed Code')" :matchs="['unull']" class="textarea"
                            @change="updateSize" v-model="form.campaignBanner" height="188px" type="textarea">
                        </FormItem>
                    </div>
                    <div class="information_item">
                        <FormItem :title="langJson('Write about your campaign')" :matchs="['unull']" class="textarea"
                            v-model="form.campaignDesc" height="188px" type="textarea"></FormItem>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="form_box_upload">
                    <FormItem v-model="form.brandBanner" :title="langJson('Brand Description')"
                        :uploadMsg="langJson('Recommended ratio: 16 × 9')" class="information_item" type="image"
                        :image="bannerConfig2" :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')">
                    </FormItem>
                    <div class="information_item">
                        <FormItem :title="langJson('Write about your brand')" :matchs="['unull']" class="textarea"
                            v-model="form.brandDesc" height="188px" type="textarea"></FormItem>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="form_box_textarea">
                    <h2>{{ langJson('Rules') }}</h2>
                    <FormItem :title="langJson('Add rules')" :matchs="['unull']" class="information_item rules"
                        v-model="form.rules" height="188px" type="textarea"></FormItem>
                </div>
            </div>

            <div class="form">
                <div class="timerpick_form">
                    <h2>{{ langJson('Campaign duration') }}</h2>
                    <div class="timepicker_box">
                        <!-- 开始 -->
                        <div class="timer_box">
                            <span class="timer_title">{{ langJson('START DATE & TIME') }}</span>
                            <div class="timer_up">
                                <div class="timer_picker">
                                    <div class="timer_item">
                                        <span>{{ langJson('Day') }}</span>
                                        <div class="timer_content">{{ startTime.day }}</div>
                                    </div>
                                </div>
                                <div class="timer_picker">
                                    <div class="timer_item">
                                        <span>{{ langJson('Month') }}</span>
                                        <div class="timer_content">{{ startTime.month }}</div>
                                    </div>
                                </div>
                                <div class="timer_picker">
                                    <div class="timer_item">
                                        <span>{{ langJson('Year') }}</span>
                                        <div class="timer_content">{{ startTime.year }}</div>
                                    </div>
                                </div>
                                <!--开始时间 -->
                                <dateTimePicker @getStartTime="getStartTime" ref="starttime">
                                    <div slot="start" class="timer cursor" @click="openStartTime">
                                        <GI name="timer" ws="48" hs="48"></GI>
                                    </div>
                                </dateTimePicker>
                            </div>
                            <div class="timer_bottom">
                                <div class="timer_left">
                                    <div class="timer_picker">
                                        <div class="timer_item">
                                            <span>{{ langJson('Time') }}</span>
                                            <div class="timer_content">{{ startTime.hour }}</div>
                                        </div>
                                    </div>
                                    <div class="time_seperate"><span>:</span></div>
                                    <div class="timer_picker">
                                        <div class="timer_item">
                                            <div class="timer_content">{{ startTime.minute }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="est">
                                    <span>EST</span>
                                    <GI ws="24" hs="24" :name="globalTheme == 'light' ? 'arrow_down' : 'arrow_down_light'"></GI>
                                </div> -->
                            </div>
                        </div>
                        <!-- 结束 -->
                        <div class="timer_box">
                            <span class="timer_title">{{ langJson('END DATE & TIME') }}</span>
                            <div class="timer_up">
                                <div class="timer_picker">
                                    <div class="timer_item">
                                        <span>{{ langJson('Day') }}</span>
                                        <div class="timer_content">{{ endTime.day }}</div>
                                    </div>
                                </div>
                                <div class="timer_picker">
                                    <div class="timer_item">
                                        <span>{{ langJson('Month') }}</span>
                                        <div class="timer_content">{{ endTime.month }}</div>
                                    </div>
                                </div>
                                <div class="timer_picker">
                                    <div class="timer_item">
                                        <span>{{ langJson('Year') }}</span>
                                        <div class="timer_content">{{ endTime.year }}</div>
                                    </div>
                                </div>
                                <!-- 结束时间 -->
                                <dateTimePicker2 @getEndTime="getEndTime" ref="endtime">
                                    <div slot="end" class="timer cursor" @click="openEndTime">
                                        <GI name="timer" ws="48" hs="48"></GI>
                                    </div>
                                </dateTimePicker2>
                            </div>
                            <div class="timer_bottom">
                                <div class="timer_left">
                                    <div class="timer_picker">
                                        <div class="timer_item">
                                            <span>{{ langJson('Time') }}</span>
                                            <div class="timer_content">{{ endTime.hour }}</div>
                                        </div>
                                    </div>
                                    <div class="time_seperate"><span>:</span></div>
                                    <div class="timer_picker">
                                        <div class="timer_item">
                                            <div class="timer_content">{{ endTime.minute }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="est">
                                    <span>EST</span>
                                    <GI ws="24" hs="24" :name="globalTheme == 'light' ? 'arrow_down' : 'arrow_down_light'"></GI>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns">
                <div class="next_step">
                    <span>{{ langJson('Please ensure your changes are saved') }}</span>
                    <BTN @handle="goRewards" type="active" bh="52" bw="152">{{ langJson('Next: Rewards') }}</BTN>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                name: '',
                subtitle: '',
                banner: '',
                campaignBanner: '',
                brandBanner: '',
                campaignDesc: '',
                brandDesc: '',
                rules: '',
                isPrivate: 0,
                appBanner: '',
                imgType: 1
            },
            bannerConfig: {
                type: 'big',
            },
            bannerConfig2: {
                type: 'small',
            },
            startTime: {
                year: '0000',
                month: '00',
                day: '00',
                hour: '00',
                minute: '00',
                second: '00',
            },
            endTime: {
                year: '0000',
                month: '00',
                day: '00',
                hour: '00',
                minute: '00',
                second: '00',
            },
            showPicker: false,
        }
    },
    computed: {
        switchlist() {
            return [
                {
                    lable: this.langJson('Public'),
                    value: 0,
                },
                {
                    lable: this.langJson('Private'),
                    value: 1,
                }
            ]
        },
        videoType() {
            return [

                {
                    lable: this.langJson('Upload'),
                    value: 1,
                },
                {
                    lable: this.langJson('Youtube code'),
                    value: 2,
                },
            ]
        },
    },
    props: {
        screenValue: {
            type: [String, Number],
            default: '1081',
        },
    },
    watch: {
        startTime: {
            handler(newVal) {
                let { year, month, day, hour, minute, second } = newVal
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    startTime: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
                })
            },
            deep: true,
        },
        endTime: {
            handler(newVal) {
                let { year, month, day, hour, minute, second } = newVal
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    endTime: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
                })
            },
            deep: true,
        },
        form: {
            handler(newVal) {
                let { name, subtitle, banner, campaignBanner, brandBanner, campaignDesc, brandDesc, rules, appBanner, isPrivate, imgType } = newVal
                this.$store.commit('compaignDetail', {
                    ...this.compaignDetail,
                    name,
                    subtitle,
                    banner,
                    descriptionImg: campaignBanner,
                    description: campaignDesc,
                    brandDescriptionImg: brandBanner,
                    brandDescription: brandDesc,
                    rules,
                    appBanner,
                    isPrivate,
                    imgType
                })
                this.$emit('updateName', name)
            },
            deep: true,
        },
        screenValue: {
            handler(newVal) {
                if (newVal >= 1081) {
                    this.bannerConfig.type = 'big'
                    this.bannerConfig2.type = 'small'
                } else if (newVal < 1081 && newVal > 500) {
                    this.bannerConfig.type = 'big'
                    this.bannerConfig2.type = 'big'
                } else {
                    this.bannerConfig.type = 'small'
                    this.bannerConfig2.type = 'small'
                }
            },
            immediate: true,
        },
    },
    inject: ['switchTab'],
    created() {
        this.initData()
    },
    methods: {
        updateSize() {

            let tmp = this.form.campaignBanner.replace(/width=(.*?)\s/, `width="100%" `)
            tmp = tmp.replace(/height=(.*?)\s/, `height="100%" `)
            this.$set(this.form, "campaignBanner", tmp)
        },
        initData() {
            let { name, subtitle, banner, descriptionImg, description, brandDescriptionImg, brandDescription, rules, endTime, startTime, appBanner, isPrivate, imgType } = this.compaignDetail
            name && this.$set(this.form, 'name', name)
            subtitle && this.$set(this.form, 'subtitle', subtitle)
            banner && this.$set(this.form, 'banner', banner)
            descriptionImg && this.$set(this.form, 'campaignBanner', descriptionImg)
            appBanner && this.$set(this.form, 'appBanner', appBanner)
            this.$set(this.form, 'isPrivate', isPrivate == 1 ? 1 : 0)
            brandDescriptionImg && this.$set(this.form, 'brandBanner', brandDescriptionImg)
            description && this.$set(this.form, 'campaignDesc', description)
            brandDescription && this.$set(this.form, 'brandDesc', brandDescription)
            rules && this.$set(this.form, 'rules', rules)
            imgType && this.$set(this.form, 'imgType', imgType)

            endTime && this.getEndTime(endTime)
            startTime && this.getStartTime(startTime)
        },
        showDatePicker() {
            this.showPicker = true
        },
        openStartTime() {
            this.$refs.starttime.uploadTime()
        },
        openEndTime() {
            this.$refs.endtime.uploadTime()
        },
        getStartTime(val) {
            this.startTime = this.timeJson(val)
        },
        getEndTime(val) {
            this.endTime = this.timeJson(val)
        },
        goRewards() {
            this.switchTab(2)
        },
    },
    beforeDestroy() { },
}
</script>
<style lang="scss" scoped>
.mt24 {
    margin-top: 24px;
}

.tip {
    font-size: 12px;
    color: var(--danger_color) !important;
    margin-top:10px;
    margin-bottom:10px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }

    span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_modif_color);
    }
}

.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;

    .mobile_upload {
        margin-top: 32px;
    }

    .form_box {
        width: 483px;
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);

        .form_switch {
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_color_black);

            }



            .status_item {
                margin-top: 6px;
            }
        }

        .information_item {
            width: 100%;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            font-family: 'Inter';
            line-height: 40px;
            color: var(--font_deep_color);
        }

        .form_input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
        }
    }

    .form_box_upload {
        width: 100%;
        border-radius: 32px;
        background: var(--color_white);
        display: flex;
        gap: 32px;

        .information_item {
            width: 100%;

            .textarea {
                margin-top: 42px;
            }
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
        }
    }

    .form_box_textarea {
        .rules {
            margin-top: 24px;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            font-family: 'Inter';
            line-height: 40px;
            color: var(--font_deep_color);
        }
    }
}

.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 35px;

        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
}

.timerpick_form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h2 {
        font-weight: 700;
        font-size: 24px;
        font-family: 'Inter';
        line-height: 40px;
        color: var(--font_deep_color);
    }

    .timepicker_box {
        display: flex;
        justify-content: space-between;

        .timer_box {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .timer_title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_deep_color);
            }

            .timer_picker {
                .timer_item {
                    span {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--font_modif_color);
                    }

                    .timer_content {
                        margin-top: 4px;
                        background: var(--bg_color);
                        border: 1px solid var(--border_color);
                        border-radius: 16px;
                        padding: 15px 30px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        font-family: 'Lato';
                    }
                }
            }

            .timer_up {
                display: flex;
                gap: 24px;
                align-items: flex-end;
            }

            .timer_bottom {
                display: flex;
                gap: 24px;
                align-items: flex-end;

                .timer_left {
                    display: flex;
                    gap: 12px;
                    align-items: flex-end;

                    .time_seperate {
                        padding: 16px 4px;
                    }
                }

                .est {
                    background: var(--bg_color);
                    border: 1px solid var(--border_color);
                    border-radius: 16px;
                    padding: 15px 30px;
                    display: flex;
                    line-height: 24px;
                    align-items: flex-end;
                    gap: 8px;
                    color: var(--font_deep_color);
                }
            }
        }
    }
}

.timePicker {
    position: absolute;
    top: 0px;
    left: 0px;

    :deep(.el-input__inner) {
        width: 0;
        padding: 0;
        border: 0;
    }

    :deep(.el-input__prefix) {
        display: none;
    }
}

.block {
    position: relative;
}

@media screen and (max-width: 1080px) {
    .form {
        padding: 20px;
    }

    .header {
        h2 {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            color: var(--font_deep_color);
        }

        span {
            font-size: 14px;
        }
    }

    .form_box {
        width: auto !important;
    }

    .form_box_upload {
        flex-direction: column;
    }

    .timepicker_box {
        flex-direction: column;
        gap: 24px;
    }

    .timer_content {
        padding: 12px !important;
    }

    .est {
        padding: 12px 24px !important;
    }

    .btns {
        justify-content: flex-start;
    }

    .next_step {
        flex-direction: column;
        align-items: flex-start !important;
    }
}
</style>
