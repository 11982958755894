<template>
    <div class="brand" @click="$emit('click')">
        <img class="brand_icon" :src="item.imageUrl" alt="" />
        <div class="brand_info">
            <div class="name">{{ item.nickName }}</div>
            <div class="number">{{ item.campaignNum }} {{ langJson('Items') }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.brand {
    .brand_info {
        .name {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
        .number {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font1);
        }
    }
    .brand_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
    }
    &:hover {
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
    }
    width: 405px;
    /* height: 72px; */
    height: var(--height1);
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: var(--borderRadius);
    padding: 12px 24px;
    box-sizing: border-box;
    background: var(--bg1);
    display: flex;
    align-items: center;
    cursor: pointer;
}
@media only screen and (max-width: 1080px) {
    .brand {
        .brand_info {
            .name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
            }
            .number {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font1);
            }
        }
        .brand_icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 8px;
        }
        &:hover {
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
        }
        width: 100%;
        height: 64px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: var(--borderRadius);
        padding: 12px 24px;
        padding-left: 12px;
        box-sizing: border-box;
        background: var(--bg1);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
</style>
