var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[(_vm.showHeader)?_c('div',{staticClass:"brand_detail"},[_c('BtnPop',{attrs:{"left":_vm.isPhone ? -190 : 0}},[_c('div',{staticClass:"gi",staticStyle:{"height":"24px","width":"24px"},attrs:{"slot":"target"},slot:"target"},[_c('GI',{staticClass:"pointer icon_",attrs:{"name":"brand_more","ws":"24","hs":"24"}})],1),_c('div',{class:['type_main', _vm.globalThemeColor],attrs:{"slot":"main"},slot:"main"},_vm._l((_vm.tagList),function(item,index){return _c('div',{key:index,staticClass:"type_content"},[_c('div',{staticClass:"type_line",on:{"click":function($event){return _vm.goRoute(item)}}},[_c('GI',{attrs:{"ws":"20","hs":"20","name":item.icon}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.val))])],1)])}),0)])],1):_vm._e(),_c('div',{staticClass:"image_out cursor",style:({ height: `${_vm.hs}px`, width: `${_vm.ws}px` })},[(_vm.detail.isPrivate == 1)?_c('div',{staticClass:"item_privacy"},[_c('GI',{attrs:{"name":"brand_privacy_white","ws":"20","hs":"20"}})],1):_vm._e(),_c('div',{staticClass:"item_image",style:({ 
            backgroundImage: `url(${_vm.detail.banner})` 
        }),on:{"click":function($event){_vm.isParticipant ? 
        _vm.jump_participant_compaign_preview({ id: _vm.detail.id }) : 
        _vm.jump_brand_compaign_preview({ status: _vm.status, id: _vm.detail.id, operateStop: _vm.detail.operateStop })}}})]),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"item_title"},[_vm._v(" "+_vm._s(_vm.detail.name)+" ")]),_c('div',{staticClass:"item_belong",on:{"click":function($event){_vm.isParticipant ? _vm.jump_participant_campaign_participants_profile({userId:_vm.detail.brandUserId}) : () => {}}}},[_c('div',{staticClass:"belong_image",style:({
                    backgroundImage: `url(${_vm.brandUserVo.imageUrl})`,
                })}),_c('div',{staticClass:"belong_name"},[_vm._v(_vm._s(_vm.brandUserVo.nickName))])]),_c('div',{staticClass:"item_desc"},[_vm._v(" "+_vm._s(_vm.detail.description)+" ")]),_c('CompaignVI',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVI),expression:"showVI"}],attrs:{"status":_vm.status,"isParticipant":_vm.isParticipant,"coll":_vm.detail.voteNum,"zan":_vm.detail.joinNum,"view":_vm.remainTime(_vm.detail.endTime).days,"arts":_vm.detail.workNum}}),(_vm.showBotomBtn)?_c('BTN',{staticClass:"cardItem",on:{"handle":function($event){return _vm.$emit('openModel')}}},[_vm._v(_vm._s(_vm.langJson('Submit Artwork')))]):_vm._e(),(_vm.showBotomBtnUnjoin)?_c('BTN',{directives:[{name:"show",rawName:"v-show",value:(_vm.isParticipant),expression:"isParticipant"}],staticClass:"cardItem Unjoin",attrs:{"fontSize":"16"},on:{"handle":function($event){return _vm.$emit('unjoinHand')}}},[_vm._v(_vm._s(_vm.langJson('Unjoin')))]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }