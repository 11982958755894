<template>
    <div class="model_main">
        <div class="tip">
            {{ langJson('Do you want to submit your artwork to') }}
            {{ titleActive }}
        </div>

        <div class="model_btns">
            <div class="model_btn">
                <BTN class="btn_item" bh="52" type="detail" @handle="close">{{ langJson('Cancel') }}</BTN>
            </div>

            <div class="model_btn">
                <BTN class="btn_item" type="active" @handle="submitArtwork">{{ langJson('Submit artwork') }}</BTN>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
        }
    },

    props: {
        id: {
            type: [Number, String],
            default: '',
        },
        titleActive: {
            type: String,
            default: '',
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        }
    },
    methods: {
        submitArtwork() {
            let id
            if (this.id) {
                id = this.id
            } else {
                id = this.$route.query.id
            }
            let isLogin = this.checkLogin((result) => {
                if(!result) {
                    this.jump_participant_compaign_preview({
                        id,
                        submit:1
                    })
                }else {
                    this.jump_participant_campaign_artwork_submit(id)
                }
                sessionStorage.setItem('noSaveHistory', '')
            }, true)
            if (!isLogin) {
                localStorage.setItem('userType', 0)
                this.$emit('computed')
                localStorage.setItem('backActive', 'true')
            } else {
                localStorage.setItem('loginBgUrl', '')
            }
        },
        close() {
            this.$emit('cancel', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .nft_preview {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 16px;
        background-color: var(--bg_color);
        margin: 20px 0;
    }
    .tip {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--font_deep_color);
        margin-top: 32px;
        text-align: center;
    }
    .information_item {
        margin-top: 32px;
    }
    .model_btns {
        .model_btn {
            flex: 1;
            /deep/ .medium {
                padding: 12px 14px !important;
            }
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 77vw;
    }
}
</style>
