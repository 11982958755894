<template>
    <div class="explore">
        <div class="compaign_title">
            <h2 class="my_campaign">{{ langJson('My Campaigns') }}</h2>
        </div>
        <div class="explore_center">
            <div class="status">
                <SelBtn class="status_item" v-model="status" type="main" @change="listGet" :list="statuslist" size="unselected"></SelBtn>
                <div class="sel_box">
                    <div class="sel">
                        <Sel :size="isPhone ? 111 : 200" :left="isPhone ? -111 : 0" :showBorder="true" :showIcon="true" @change="handleChange" type="select" :list="selectList" :searchHeight="true"></Sel>
                    </div>
                </div>
            </div>

            <div v-show="status == 1" class="explore_main">
                <div class="explore_item" v-for="(item, index) in myCompaign" :key="index">
                    <CompaignItem :hs="isPhone ? 124 : 200" status="live" :detail="item" :tagList="liveMenu" :showHeader="true" @delCompaign="delCompaign(item)"></CompaignItem>
                </div>
                <div class="explore_grap"></div>
                <div class="explore_grap"></div>
                <div class="explore_grap"></div>
                <template v-if="!lenArray(myCompaign)">
                    <div class="position_center">
                        <nu-data></nu-data>
                    </div>
                </template>
            </div>
            <div v-show="status == 2" class="explore_main" style="gap: 24px">
                <div class="explore_item" v-for="(item, index) in myCompaign" :key="index">
                    <CompaignItem :hs="isPhone ? 124 : 200" status="archive" :detail="item" :tagList="archiveMenu" :showHeader="true" @delCompaign="delCompaign(item)"></CompaignItem>
                </div>
                <div class="explore_grap"></div>
                <div class="explore_grap"></div>
                <div class="explore_grap"></div>
                <template v-if="!lenArray(myCompaign)">
                    <div class="position_center">
                        <nu-data></nu-data>
                    </div>
                </template>
            </div>
            <div v-show="status == 0" class="explore_main">
                <div class="explore_item" v-for="(item, index) in myCompaign" :key="index">
                    <CompaignItem :hs="isPhone ? 124 : 200" status="Unpublished" :detail="item" :showVI="false" :tagList="unpublishedMenu" :showHeader="true" @delCompaign="delCompaign(item)"></CompaignItem>
                </div>
                <div class="explore_grap"></div>
                <div class="explore_grap"></div>
                <div class="explore_grap"></div>
                <template v-if="!lenArray(myCompaign)">
                    <div class="position_center">
                        <nu-data></nu-data>
                    </div>
                </template>
            </div>
        </div>
        <div style="height: 50px; width: 100%"></div>
        <Page v-model="pageNum" :all="total" :size="pageSize" @change="listGet" :isBtn="true"></Page>
        <div style="height: 50px; width: 100%"></div>

        <!-- 对话框使用模板 -->
        <Model :padding="'24px'" v-model="showDelCompaignTip">
            <DelCompaignTip slot="main" @cancel="cancel" :id="id" @complete="listGet"></DelCompaignTip>
        </Model>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showDelCompaignTip: false,
            titleActive: '',
            id: '',

            pageNum: 1,
            pageSize: 8,
            total: 0,
            isAsc: '',
            status: 1,
            live: 0,
            archive: 0,
            unpublished: 0,

            selectList: [
                { lable: 'All', value: '' },
                { lable: 'New created', value: 'desc' },
                { lable: 'Oldest submitted', value: 'asc' },
            ],
            placeholder: 'All',
            // tagListLive: [
            //     { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'View as a participant', isChecked: false, number: 1, status: 'live' },
            //     { icon_dark: 'brand_edit', icon_light: 'brand_edit_light', val: 'Edit campaign', isChecked: false, number: 2, status: 'live' },
            //     // { icon_dark: 'brand_candle', icon_light: 'brand_candle_light', val: 'Manage submissions', isChecked: false, number: 3, status: 'live' },
            //     { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', isChecked: false, number: 4, status: 'live' },
            // ],
            // tagListArchive: [
            //     { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'Preview', isChecked: false, number: 1, status: 'archive' },
            //     { icon_dark: 'brand_detail', icon_light: 'brand_detail_light', val: 'Campaign details', isChecked: false, number: 2, status: 'archive' },
            //     { icon_dark: 'brand_candle', icon_light: 'brand_candle_light', val: 'Manage submissions', isChecked: false, number: 3, status: 'archive' },
            //     { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', isChecked: false, number: 4, status: 'archive' },
            // ],
            // tagListUnpublished: [
            //     { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'Preview', isChecked: false, number: 1, status: 'published' },
            //     { icon_dark: 'brand_detail', icon_light: 'brand_detail_light', val: 'Campaign details', isChecked: false, number: 2, status: 'published' },
            //     { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', isChecked: false, number: 4, status: 'published' },
            // ],
        }
    },
    async created() {
        let { dispatch } = this.$store
        let isGoogle = localStorage.getItem('isGoogle') || false
        if (isGoogle) {
            sessionStorage.setItem('noSaveHistory', '')
            localStorage.setItem('userType', 1)
            await dispatch('userGet')
            this.$store.commit('saveHeadView', { create: false, newCompagin: true })

            console.log('谷歌登录 品牌')
        }
    },
    computed: {
        statuslist() {
            return [
                {
                    lable: this.langJson('Live') + ` (${this.live})`,
                    value: 1,
                },
                {
                    lable: this.langJson('Completed') + ` (${this.archive})`,
                    value: 2,
                },
                {
                    lable: this.langJson('Unpublished') + ` (${this.unpublished})`,
                    value: 0,
                },
            ]
        },
        unpublishedMenu() {
            return [
                {
                    icon: 'brand_eye',
                    val: this.langJson('Campaign details'),
                    isChecked: false,
                    number: 1,
                    status: 'live',
                },
                {
                    icon: 'brand_edit',
                    val: this.langJson('Edit campaign'),
                    isChecked: false,
                    number: 2,
                    status: 'live',
                },
                {
                    icon: 'brand_trash',
                    val: this.langJson('Delete'),
                    isChecked: false,
                    number: 4,
                    status: 'live',
                },
            ]
        },
        liveMenu() {
            return [
                {
                    icon: 'brand_eye',
                    val: this.langJson('Preview'),
                    isChecked: false,
                    number: 1,
                    status: 'live',
                },
                {
                    icon: 'brand_edit',
                    val: this.langJson('Edit campaign'),
                    isChecked: false,
                    number: 2,
                    status: 'live',
                },
                {
                    icon: 'brand_candle',
                    val: this.langJson('Manage submissions'),
                    isChecked: false,
                    number: 3,
                    status: 'live',
                },
                {
                    icon: 'brand_trash',
                    val: this.langJson('Delete'),
                    isChecked: false,
                    number: 4,
                    status: 'live',
                },
            ]
        },
        archiveMenu() {
            return [
                {
                    icon: 'brand_eye',
                    val: this.langJson('Preview'),
                    isChecked: false,
                    number: 1,
                    status: 'archive',
                },
                {
                    icon: 'brand_candle',
                    val: this.langJson('Manage submissions'),
                    isChecked: false,
                    number: 3,
                    status: 'archive',
                },
                {
                    icon: 'brand_trash',
                    val: this.langJson('Delete'),
                    isChecked: false,
                    number: 4,
                    status: 'archive',
                },
            ]
        },
    },
    mounted() {
        if (this.$route.query.status) {
            this.status = this.$route.query.status
        }
        this.listGet()
    },

    watch: {
        'globalBrand.myCompaignName'() {
            this.pageNum = 1
            this.listGet()
        },
    },
    methods: {
        cancel(val) {
            this.showDelCompaignTip = val
        },
        delCompaign(item) {
            this.showDelCompaignTip = true
            this.id = item.id
            this.titleActive = item.name
        },
        async listGet() {
            try {
                let { dispatch } = this.$store
                let result = await dispatch('myCompaign', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    isAsc: this.isAsc,
                    orderByColumn: 'createTime',
                    status: this.status,
                })

                this.live = result.data.liveNum
                this.archive = result.data.archiveNum
                this.unpublished = result.data.unpublishedNum
                this.total = result.data.total
            } catch (err) {
                console.log(err)
            }
        },
        handleChange(val) {
            this.isAsc = val
            this.placeholder = this.selectList.find((item) => item.value == val).lable
            this.listGet()
        },
    },
}
</script>
<style lang="scss" scoped>
.explore {
    .explore_center {
        max-width: var(--max_width);

        .status {
            margin-top: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .total {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
            }

            .status_item {
                flex: 1;
            }
        }

        .explore_main {
            .explore_item {
                min-width: 240px;
                max-width: 405px;

                background: var(--color_white);
                padding: 24px;
                padding-top: 16px;
                box-sizing: border-box;
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 24px;
                white-space: wrap !important;

                &:hover {
                    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
                }
            }
            .explore_grap {
                min-width: 240px;
                max-width: 405px;
                height: 0;
            }

            margin-top: 26px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
            gap: 32px;

            .position_center {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .explore_filter {
            // margin-top: 48px;
        }

        width: 100%;
        max-width: var(--max_width);
        margin: auto;
    }
}

.compaign_title {
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 32px;

    .my_campaign {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }
}

@media only screen and (max-width: 1080px) {
    .compaign_title {
        margin-top: 24px;

        .my_campaign {
            font-size: 32px;
        }
    }

    .explore {
        .explore_center {
            .status {
                flex-direction: column;
                align-items: flex-end;
                gap: 12px;

                .status_item {
                    overflow: auto;
                }

                .sel_box {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 5px;
                    .sel {
                        float: right;
                    }
                }
            }

            .explore_main {
                .explore_item {
                    padding: 12px;
                    max-width: 100%;
                    border-radius: 16px;
                }

                margin-top: 8px;
                gap: 20px;
            }
        }
    }

    .compaign_title {
        height: 50px;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        align-items: center;
        margin-top: 24px;

        h2 {
            line-height: 48px;
        }
    }
}

@media only screen and (max-width: 750px) {
}

@media only screen and (max-width: 375px) {
    .explore_main {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    }
}
</style>
