import tools from '@/mixin/tools'
import computed from '@/mixin/computed'
import jumpPageBrand from '@/mixin/jumpPageBrand'
import jumpPageParticipant from '@/mixin/jumpPageParticipant'
import filters from '@/mixin/filters'
import methods from '@/mixin/methods'
import { getCookie } from '@/assets/js/cookie'
export default {
    data() {
        return {
            lock_btn: false,
            load_btn: false,
            total: 500,
            pageSize: 24,
            pageNum: 1,
            components_id: 0,
            mainToken: '0x0000000000000000000000000000000000000000',
            maxVal: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
            rules: {
                // 整数
                int: {
                    match: /^\d+$/,
                    tip: this.langJson('Must be an integer', 'saas_nft.txt.v1.12'),
                },
                NumberInt: {
                    match: /^[1-9]\d*$/,
                    tip: this.langJson('Must be an integer', 'saas_nft.txt.v1.12'),
                },
                // 整数或小数
                float: {
                    match: /^\d+(\.\d*)?$/,
                    tip: this.langJson('Must be an integer or decimal', 'saas_nft.txt.v1.13'),
                },
                // 不为空
                unull: {
                    match: /.+/,
                    tip: "",
                },
                // 限制字符长度
                overflow: {
                    match: /^.{8,20}$/,
                    tip: this.langJson('Character length is 8 to 20 characters'),
                },
                // 含字母
                haveword: {
                    match: /[a-zA-Z]+/,
                    tip: this.langJson('Must contain letters', 'saas_nft.txt.v1.16'),
                },
                // 含大写字母
                haveupword: {
                    match: /[A-Z]+/,
                    tip: this.langJson('Must contain capital letters', 'saas_nft.txt.v1.17'),
                },
                // 含小写字母
                havelowword: {
                    match: /[a-z]+/,
                    tip: this.langJson('Must contain lowercase letters', 'saas_nft.txt.v1.18'),
                },
                // 含特殊字符
                haveldrop: {
                    match: /[\W_]+/,
                    tip: this.langJson('Must contain a special character'),
                },
                // 含数字
                havenum: {
                    match: /[0-9]+/,
                    tip: this.langJson('Must contain numbers', 'saas_nft.txt.v1.19'),
                },
                // 邮箱
                email: {
                    match: /^.+@.+$/,
                    tip: this.langJson('Mailbox format error', 'saas_nft.txt.v1.19001'),
                },
                // 长度限制
                threeNumber: {
                    match: /^.{1,8}$/,
                    tip: this.langJson('Character length is 1 to 8 characters'),
                },
                // 最大字符长度
                maxLength: {
                    match: /^.{1,255}$/,
                    tip: this.langJson('Character length is 1 to 255 characters'),
                },
                maxName: {
                    match: /^.{1,25}$/,
                    tip: this.langJson('Character length is 1 to 25 characters'),
                },
            },
        }
    },
    filters,
    computed,
    methods: {
        // 邀请机制
        // 检查是否 用户 登录
        checkLogin(fn = () => {}, execute = false) {
            if (getCookie('token')) {
                execute && fn(true)
            } else {
                window.loginComple = () => {
                    fn(false)
                }
                this.$store.commit('loginShow', true)
            }
            return getCookie('token')
        },
        // 前提 登录 以及 链接钱包
        async loginOf(fn = () => {}, isConnect = true) {
            if (this.walletDetail && this.walletDetail.address && getCookie('token')) {
                isConnect && (await this.connect(this.walletDetail.plugin))
                fn()
            } else {
                this.$store.commit('linkShow', true)
            }
        },

        // 登录并切换至对应主链
        async targetChain(chainId, fn = () => {}, error = () => {}) {
            // 登录
            return await this.loginOf(async () => {
                // 切换至对应主链
                let chainDetail = this.findChain('id', chainId)
                let result = await this.switchNetwork(chainDetail)
                // 判断切换主链是否成功
                // console.log('判断切换主链是否成功', result)
                if (result) {
                    fn()
                } else {
                    error()
                    this.pushMsg('error', this.langJson('Please switch the working network ') + `(${chainDetail.networkName})`)
                }
            })
        },
        // 如果之前登录过自动连接
        async autoConnect() {
            this.walletDetail && this.walletDetail.address && (await this.connect(this.walletDetail.plugin))
        },
        async voteHand(item) {
            try {
                if (!item.voteFlag) {
                    let { dispatch } = this.$store
                    let res = await dispatch('campaignWorksVote', { worksId: item.id })
                    if (res && res.code == 200) {
                        this.pushMsg('success', this.langJson('Vote Success'))
                        window.voteComplete && window.voteComplete()
                    }
                } else {
                    this.$store.commit('showVote', true)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async vote(item) {
            try {
                let { path, query } = this.$route
                let isLogin = this.checkLogin(() => {
                    this.$router.replace({ path, query })
                    this.$router.go(-1)
                    sessionStorage.setItem('noSaveHistory', '')
                }, false)
                if (!isLogin) {
                    localStorage.setItem('userType', 0)
                } else {
                    await this.voteHand(item)
                }
            } catch (error) {
                console.log(error)
            }
        },
        ...tools,
        ...jumpPageBrand,
        ...jumpPageParticipant,
        ...methods,
    },
}
