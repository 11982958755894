<template>
    <div class="switch_theme">
        <div :class="['theme_item', isActive && 'theme_active']">
            <GI class="icon_target" v-if="isActive" name="theme_light" ws="20" hs="20"></GI>
            <GI class="icon_target cursor" v-else name="theme_light" ws="20" hs="20" @click="setTheme('light')"></GI>
        </div>
        <div :class="['theme_item', !isActive && 'theme_active']">
            <GI class="icon_target" v-if="!isActive" name="theme_night" ws="20" hs="20"></GI>
            <GI class="icon_target cursor" v-else name="theme_night" ws="20" hs="20" @click="setTheme('night')"></GI>
        </div>
        <!-- theme -->
    </div>
</template>
<script>
export default {
    computed: {
        isActive() {
            return this?.globalTheme == 'light'
        },
    },
    created() {
        this.setTheme()
    },
    methods: {
        setTheme(type) {
            let theme = localStorage.getItem('themeColor')
            if (!type) {
                // 刷新
                if (!theme) {
                    // 查找内存 - 无
                    localStorage.setItem('themeColor', 'light')
                    document.documentElement.className = 'light'
                } else {
                    document.documentElement.className = theme
                }
            } else {
                localStorage.setItem('themeColor', type)
                document.documentElement.className = type
            }
            this.$store.commit('theme', localStorage.getItem('themeColor'))

            if (localStorage.getItem('themeColor') == 'night') {
                document.documentElement.style.setProperty('--color_white', '#1B1C20')
                document.documentElement.style.setProperty('--font_deep_color', '#ffffff')
                document.documentElement.style.setProperty('--font_modif_color', '#ffffff')
                document.documentElement.style.setProperty('--font_color', '#ffffff')
                document.documentElement.style.setProperty('--bg_color', '#23252b')
                document.documentElement.style.setProperty('--bg_color2', '#23252b')
                document.documentElement.style.setProperty('--bg_color3', '#4c4f5d')

                document.documentElement.style.setProperty('--font_color_black', '#ffffff')
                document.documentElement.style.setProperty('--font_article', '#ffffff')

                // start
                document.documentElement.style.setProperty('--header_bg', '#23252b')
                document.documentElement.style.setProperty('--header_border', '#72768B')

                document.documentElement.style.setProperty('--nft_dialog_bg', '#4D4F5E')
                document.documentElement.style.setProperty('--nft_btn_border', '#ffffff')
                document.documentElement.style.setProperty('--nft_btn_bg_active', '#ffffff')

                document.documentElement.style.setProperty('--nft_btn_font_active', '#0B0C0E')
                document.documentElement.style.setProperty('--nft_btnPop_border', '#c9ccdd')
                document.documentElement.style.setProperty('--nft_select_primary', '#c9ccdd')
                document.documentElement.style.setProperty('--nft_select_border_radius', '12px') //10px
                document.documentElement.style.setProperty('--nft_select_font', '#23252Bd')

                // end

                // new color dark
                // start
                document.documentElement.style.setProperty('--font1', '#c9ccdd')
                document.documentElement.style.setProperty('--font2', '#ffffff')
                document.documentElement.style.setProperty('--font3', '#ffffff44')
                document.documentElement.style.setProperty('--bg1', '#4f526e')
                document.documentElement.style.setProperty('--bg2', '#0b0c0e')
                document.documentElement.style.setProperty('--bg3', '#4f526e')
                document.documentElement.style.setProperty('--bg4', '#4f526e')
                document.documentElement.style.setProperty('--bg5', '#4f526e')
                document.documentElement.style.setProperty('--bg6', '#00000000')
                document.documentElement.style.setProperty('--bg7', '#4f526e')
                document.documentElement.style.setProperty('--bg8', '#4f526e')
                document.documentElement.style.setProperty('--bg9', '#00000000')

                document.documentElement.style.setProperty('--bg10', 'linear-gradient(#23252b, #00023252b000, #23252b)')
                document.documentElement.style.setProperty('--borderColor1', '#72768B')
                document.documentElement.style.setProperty('--borderRadius', '12px')
                document.documentElement.style.setProperty('--height1', '68px')

                //  end
            } else if (localStorage.getItem('themeColor') == 'light') {
                // new color
                //  start
                document.documentElement.style.setProperty('--font1', '#72768b')
                document.documentElement.style.setProperty('--font2', '#ff9466')
                document.documentElement.style.setProperty('--font3', '#00000044')
                document.documentElement.style.setProperty('--bg1', '#ffffff')
                document.documentElement.style.setProperty('--bg2', '#ffeae0')
                document.documentElement.style.setProperty('--bg3', '#ffeae0')
                document.documentElement.style.setProperty('--bg4', '#eae0ff')
                document.documentElement.style.setProperty('--bg5', '#9766ff')
                document.documentElement.style.setProperty('--bg6', '#F8F8FA')
                document.documentElement.style.setProperty('--bg7', '#ffffff')
                document.documentElement.style.setProperty('--bg8', '#4f526e')
                document.documentElement.style.setProperty('--bg9', '#ffffff')
                document.documentElement.style.setProperty('--bg10', 'linear-gradient(#eeedf4, #f2e6f9, #eeedf4)')
                document.documentElement.style.setProperty('--borderColor1', '#00000000')
                document.documentElement.style.setProperty('--borderRadius', '32px') // 36px
                document.documentElement.style.setProperty('--height1', '72px')
                // end

                document.documentElement.style.setProperty('--color_white', '#ffffff')
                document.documentElement.style.setProperty('--font_deep_color', '#0B0C0E')
                document.documentElement.style.setProperty('--font_modif_color', '#72768B')
                document.documentElement.style.setProperty('--font_color', '#4D4F5E')
                document.documentElement.style.setProperty('--bg_color', '#F8F8FA')
                document.documentElement.style.setProperty('--bg_color2', '#FFFFFF')
                document.documentElement.style.setProperty('--bg_color3', '#F8F8FA')
                document.documentElement.style.setProperty('--font_color_black', '#23252b')
                document.documentElement.style.setProperty('--font_article', '#1B1C20')

                // start
                document.documentElement.style.setProperty('--header_bg', '#ffffff')
                document.documentElement.style.setProperty('--header_border', '#ffffff')

                document.documentElement.style.setProperty('--nft_dialog_bg', '#ffffff')
                document.documentElement.style.setProperty('--nft_btn_border', '#c9ccdd')
                document.documentElement.style.setProperty('--nft_select_primary', '#9766ff')
                document.documentElement.style.setProperty('--nft_select_border_radius', '32px')
                document.documentElement.style.setProperty('--nft_select_font', '#ffffff')

                // end
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.icon_target {
    pointer-events: stroke;
}
.switch_theme {
    height: 32px;
    width: 68px;
    padding: 2px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg_color2);
    cursor: pointer;
    .theme_item {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .theme_active {
        background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
    }
}
</style>
