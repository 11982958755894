<template>
  <div>
    <Go :prev="langJson('Create multiple NFTs')" @prev="back"> </Go>
    <div class="line"></div>

    <div class="mint_container">
      <div class="mint_top">
        <h2>{{ langJson('Selected Artworks (8)') }}</h2>
        <BTN type="warn" icon_left="brand_trash" bw="138">{{ langJson('Remove') }} </BTN>
      </div>
      <div class="mint_box">
        <div class="artworks_item" v-for="(item, index) of 5" :key="index">
          <MintCard hs="204" ws="272"></MintCard>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="form">
      <div class="form_box">
        <FormItem :list="typelist" type="select" :matchs="['haveword']" :title="langJson('Network')" :placeholder="langJson('Network')"></FormItem>
        <div class="select_contract">
          <span>{{ langJson('Collective contract type') }} </span>
          <div><MintSel class="status_item" v-model="status" type="contract" :list="statuslist" size="big"></MintSel></div>
          <div class="form_ERC1155" v-if="status == 'ERC1155'">
            <FormItem :title="langJson('Number of tokens')" class="information_item" height="188px" type="text"></FormItem>
          </div>
          <div class="form_ERC2891" v-if="status == 'ERC2891'">
            <h2 class="title">{{ langJson('Royalty payments') }}</h2>
            <div class="input_item">
              <FormItem :title="langJson('Public address')" class="information_item" height="188px" type="text"></FormItem>
              <FormItem :title="langJson('Royalty proportion')" class="information_item" height="188px" type="text"></FormItem>
            </div>
            <div class="input_item">
              <FormItem :title="langJson('Public address')" class="information_item" height="188px" type="text"></FormItem>
              <FormItem :title="langJson('Royalty proportion')" class="information_item" height="188px" type="text"></FormItem>
            </div>
            <div class="input_item">
              <FormItem :title="langJson('Public address')" class="information_item" height="188px" type="text"></FormItem>
              <FormItem :title="langJson('Royalty proportion')" class="information_item" height="188px" type="text"></FormItem>
            </div>
          </div>
        </div>
        <div class="estimate">
          <span>{{ langJson('NFTWerks Mint Fee') }} </span>
          <div class="estimate_btn">
            <BTN bw="95" bh="40" type="estimate">{{ langJson('Estimate') }} </BTN>
            <h2>{{ langJson('12 USDT') }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_box">
      <BTN type="active" @handle="showMint" bw="328">{{ langJson('Create multiple NFTs') }} </BTN>
      <!-- Processing... -->
      <Model v-model="payShow">
        <template v-slot:main>
          <div class="pay_dialog_main">
            <div class="process_icon">
              <GI name="pay_processing" ws="123" hs=" 123"></GI>
              <div class="processing_words">
                <p>{{ langJson('Processing...') }}</p>
                <p>{{ langJson('Please wait a few seconds') }}</p>
              </div>
            </div>
          </div>
        </template>
      </Model>
      <!-- Minting Completed -->
      <Model v-model="mintShow">
        <template v-slot:main>
          <div class="mint_dialog_main">
            <div class="mint_icon">
              <GI name="mint_completed" ws="200" hs=" 233"></GI>
              <div class="mint_completed">
                <p>{{ langJson('Minting Completed') }}</p>
                <div class="btns">
                  <BTN bw="210" type="detail"> {{ langJson('Back to My Campaign') }} </BTN>
                  <BTN bw="174" @handle="shareShow" type="active"> {{ langJson('Share') }} </BTN>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Model>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typelist: [12, 3, 4],
      status: 'ERC721',
      statuslist: [
        {
          lable: 'ERC721',
          value: 'ERC721',
        },
        {
          lable: 'ERC1155',
          value: 'ERC1155',
        },
        {
          lable: 'ERC2891',
          value: 'ERC2891',
        },
      ],
      payShow: false,
      mintShow: false,
    }
  },
  mounted() {},
  methods: {
    pay() {
      this.payShow = true
    },
    showMint() {
      this.mintShow = true
    },
    shareShow() {
      // 此页无效
      // this.$store.commit('changeShowShare', true)
    },
    getlink() {
      let protocol = window.location.protocol
      let host = window.location.host
      let port = window.location.port
      return `${protocol}//${host}/myster/detail/${this.$route.params.id}`
    },
  },
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.line {
  width: var(--max_width);
  margin: auto;
  border-bottom: 1px solid var(--border_color);
}
.mint_container {
  width: var(--max_width);
  margin: auto;
  margin-top: 32px;
  .mint_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mint_box {
    margin-top: 24px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    .artworks_item {
      width: 296px;
          background: var(--color_white);
      box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
      border-radius: 16px;
    }
  }
}
.form {
  box-sizing: border-box;
  max-width: var(--max_width);
  margin: auto;
  width: 100%;
  margin-top: 32px;
     background: var(--color_white);
  border-radius: 32px;
  padding: 32px;
  .form_box {
    width: 733px;
    gap: 42px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 32px;
       background: var(--color_white);
    .information_item {
      width: 100%;
    }
    .select_contract {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .form_ERC1155 {
        max-width: 544px;
      }
      .form_ERC2891 {
        max-width: 732px;
        .title {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--font_deep_color);
          margin-bottom: 24px;
        }
        .input_item {
          display: flex;
          gap: 16px;
        }
      }
    }

    .estimate {
      display: flex;
      justify-content: space-between;
      .estimate_btn {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }
}
.btn_box {
  max-width: var(--max_width);
  margin: auto;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 64px;
}
.pay_dialog_main {
  width: 570px;
  height: 431px;
  display: flex;
  justify-content: center;
  align-items: center;

  .process_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    p {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 38px;
      color: var(--font_deep_color);
    }
    .processing_words {
      display: flex;
      flex-direction: column;
      gap: 13px;
      justify-content: center;
      align-items: center;
    }
  }
}
.mint_dialog_main {
  width: 570px;
  height: 431px;
  display: flex;
  justify-content: center;
  align-items: center;
  .mint_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    .mint_completed {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
    }
    p {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      color: var(--font_deep_color);
    }
    .btns {
      display: flex;
      gap: 16px;
    }
  }
}
</style>
