import { render, staticRenderFns } from "./editGoMobile.vue?vue&type=template&id=327e7455&scoped=true"
import script from "./editGoMobile.vue?vue&type=script&lang=js"
export * from "./editGoMobile.vue?vue&type=script&lang=js"
import style0 from "./editGoMobile.vue?vue&type=style&index=0&id=327e7455&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327e7455",
  null
  
)

export default component.exports