<template>
    <div
        :id="components_id"
        :class="['switch', 'cursor']"
        :style="{
            width: `${size}px`,
            height: `${24}px`,
            borderRadius: `${24}px`,
        }"
        @click="onOff"
    >
        <div
            class="switch_inner cursor"
            :style="{
                width: `${val ? 40 : 23.2}px`,
                height: `${24}px`,
                borderRadius: `${24}px`,
                background: val ? `linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%)` : '',
                'padding-right': val ? '0.8px' : '0px',
            }"
        >
            <!-- 伸缩的长度 -->
            <div
                class="switch_point cursor"
                :style="{
                    width: `${22.4}px`,
                    height: `${22.4}px`,
                    borderRadius: `${size}px`,
                }"
            ></div>
        </div>
    </div>
</template>
<script>
import move from 'move-js'
export default {
    model: {
        prop: 'val',
        event: 'change',
    },
    data() {
        return {
            components_id: '',
        }
    },
    props: {
        val: {
            type: Boolean,
            default: false,
        },
        size: {
            type: [Number],
            default: 40,
        },
    },
    inject: ['regCode'],
    created() {
        let code = this.regCode()
        this.components_id = 'switch_' + code
    },
    mounted() {
        move.defaults = {
            duration: 500,
        }
    },
    methods: {
        onOff() {
            if (document.getElementById(this.components_id)) {
                if (this.val) {
                    move(`#${this.components_id} .switch_inner`).set('width', `${23.2}px`).set('background', `${'#72768b'}`).end()
                    this.$emit('change', false)
                } else {
                    move(`#${this.components_id} .switch_inner`).set('width', `${40}px`).set('background', `${'linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%)'}`).end()
                    this.$emit('change', true)
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.switch {
    .switch_inner {
        .switch_point {
            background-color: #ffffff;
            /* background-color: var(--color_white); */
        }
        /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
    }
    background-color: rgba($color: #72768b, $alpha: 1);
    overflow: hidden;
}
</style>
