<template>
    <div class="market">
        <div class="floor_banner">
            <!-- 推荐列表 第一个 -->
            <div class="banner_left banner_item">
                <div class="title">{{ langJson('FEATURED CAMPAIGN') }}</div>
                <div class="main">
                    <CPRO v-if="lenArray(globalMarket.campaignRecommend)" :item="globalMarket.campaignRecommend[0]" :showBtn="true" :padding="isPhone ? '16px' : '0px'" :hs="isPhone ? 200 : 390" :isParticipant="true"></CPRO>
                    <NuData height="300" v-if="!lenArray(globalMarket.campaignRecommend)" />
                </div>
            </div>
            <div class="banner_space"></div>
            <!--  推荐列表除去第一个 -->
            <div class="banner_right banner_item">
                <div class="title">{{ langJson('RECOMMENDED FOR YOU') }}</div>
                <NuData height="300" v-if="!lenArray(recommendList)" />
                <div class="main" v-if="lenArray(recommendList)">
                    <div class="maim_item" v-for="(item, index) in recommendList" :key="index">
                        <RproV1 :item="item" :hs="isPhone ? 136 : 180" :ws="isPhone ? 132 : 226"></RproV1>
                    </div>
                </div>
            </div>
        </div>

        <!-- 铸造Nft -->
        <div
            class="floor_mint"
            :class="[globalThemeColor]"
            :style="{
                backgroundImage: `url(/source/png/create.png)`,
            }"
        >
            <div class="mask"></div>
            <div class="title">{{ langJson('Mint NFTs for yourself') }}</div>
            <div class="desc">{{ langJson('Create your very own NFTs and empower your creativity by creating unique digital assets on the blockchain.') }}</div>
            <BTN class="createNFTs" type="active" bw="240" bh="52" @handle="showSubmit">{{ langJson('Create NFTs') }}</BTN>
        </div>

        <!-- 最新列表 -->
        <ProGroup :list="globalMarket.campaignNew" :title="langJson('NEW IN')" :isParticipant="true"></ProGroup>

        <!-- 排行列表 -->
        <div class="floor_top">
            <div class="title">{{ langJson('TOP BRANDS') }}</div>
            <div class="top_list">
                <div class="top_item" v-for="(item, index) of globalMarket.userTopBrands" :key="index">
                    <Brand :item="item" @click="jump_participant_campaign_artwork_joinCampaign({ id: item.id })"></Brand>
                </div>
                <NuData height="300" v-if="!lenArray(globalMarket.userTopBrands)" />
            </div>
        </div>

        <!-- 即将结束 -->
        <div class="floor_end">
            <div class="title">{{ langJson('ENDS SOON') }}</div>
            <div class="end_list">
                <div class="end_item" v-for="(item, index) of globalMarket.campaignEnd" :key="index">
                    <CPRO :item="item" :hs="isPhone ? 200 : 390" :imageRadius="isPhone ? '12' : '24'" :padding="isPhone ? '16px' : '0px'" titType="item_title_small" descType="item_desc_small" :showBtn="true" :isParticipant="true">
                        <div v-if="!isPhone" class="project_time" slot="image">
                            <Countdown :time="item.endTime"></Countdown>
                        </div>
                        <div class="count_text" v-if="isPhone" slot="countDownText">
                            {{ langJson('Ends in') }}&nbsp;&nbsp;
                            <!-- 5d : 12 h : 19m -->
                            <TimeStr :time="item.endTime" />
                        </div>
                    </CPRO>
                </div>
                <NuData height="300" v-if="!lenArray(globalMarket.campaignEnd)" />
            </div>
        </div>

        <div style="height: 50px; width: 50px"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // groupStatus:{
            //     prev:false,
            //     next:false
            // }
        }
    },
    computed: {
        recommendList() {
            try {
                return this.globalMarket.campaignRecommend.filter((item, index) => {
                    return index != 0
                })
            } catch (error) {
                return []
            }
        },
    },
    async created() {
        let isGoogle = localStorage.getItem('isGoogle') || false
        if (isGoogle) {
            let { dispatch } = this.$store

            localStorage.setItem('userType', 0)
            await dispatch('userGet')
            this.$store.commit('saveHeadView', { create: true })

            localStorage.setItem('isGoogle', false)
            console.log('谷歌登录 参与者')
        }

        this.init()
        this.init2()
        this.init3()
        this.init4()
    },
    mounted() {},
    methods: {
        // 已上架的活动列表 campaignEnd
        async init4() {
            try {
                let { dispatch } = this.$store
                await dispatch('campaignEnd')
            } catch (error) {
                console.log(error)
            }
        },
        // 已上架的活动列表 isRecommend
        async init3() {
            try {
                let { dispatch } = this.$store
                await dispatch('campaignRecommend')
            } catch (error) {
                console.log(error)
            }
        },
        // 顶级品牌列表 newIn
        async init2() {
            try {
                let { dispatch } = this.$store
                await dispatch('userTopBrands')
            } catch (error) {
                console.log(error)
            }
        },
        // 已上架的活动列表 newIn
        async init() {
            try {
                let { dispatch } = this.$store
                await dispatch('campaignNew')
            } catch (error) {
                console.log(error)
            }
        },
        // changeGroup(obj){
        //     this.groupStatus = Object.assign(this.groupStatus,obj)
        // }
        showSubmit() {
            this.$store.commit('changeshowMintOrJoin', true)
        },
    },
}
</script>
<style lang="scss" scoped>
.market {
    .floor_end {
        .end_list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .end_item {
                .project_time {
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                }
                margin-top: 24px;
                background-color: var(--color_white);
                padding: 16px;
                box-sizing: border-box;
                border-radius: 16px;
                width: calc(50% - 16px);
                min-width: 400px;
                &:hover {
                    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
                }
            }
        }
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 48px;
    }
    .floor_top {
        .top_list {
            .top_item {
                margin-top: 24px;
            }
            display: flex;
            column-gap: 30px;

            flex-wrap: wrap;
        }
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 48px;
    }

    .floor_mint {
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 268px;
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.theme_dark {
            position: relative;
            /* background-color: var(--bg_item3); */
            border-radius: 30px;
            overflow: hidden;
            .mask {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: var(--max_width);
                min-height: 268px;
                height: 100%;
                background-color: rgba($color: #2a2f3b, $alpha: 0.95);
                z-index: 2;
                transition: all 0s !important;
            }
        }
        .title {
            width: 100%;
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
            text-align: center;
            position: relative;
            z-index: 3;
        }
        .desc {
            width: 700px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_color);
            text-align: center;
            margin-top: 8px;
            margin-bottom: 32px;
            position: relative;
            z-index: 3;
        }

        .createNFTs {
            position: relative;
            z-index: 3;
        }
    }

    .floor_banner {
        .banner_space {
            width: 32px;
        }
        .banner_item {
            .main {
                .maim_item {
                    padding-bottom: 12px;
                    margin-bottom: 24px;
                    border-bottom: 1px solid var(--border_color);
                    &:last-child {
                        border-bottom: 1px solid rgba(0, 0, 0, 0);
                    }
                }
            }
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
                margin-bottom: 24px;
            }
            flex: 1;
            min-width: 500px;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 32px;
    }
    width: 100%;
    // height:100%;
    // overflow: auto;
}
@media only screen and (max-width: 1080px) {
    .market {
        .floor_end {
            .end_list {
                display: flex;
                gap: 20px;
                margin-top: 4px;
                justify-content: space-between;
                flex-wrap: wrap;
                .end_item {
                    .project_time {
                        position: absolute;
                        left: 10px;
                        bottom: 10px;
                    }
                    margin-top: 0px;
                    background-color: var(--color_white);
                    padding: 0px;
                    box-sizing: border-box;
                    border-radius: 16px;
                    min-width: var(--max_width);
                    &:hover {
                        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
                    }
                }
            }
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
            /deep/ .count_text {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: var(--primary_color);
            }
            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            margin-top: 36px;
        }
        .floor_top {
            .top_list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 4px;
                gap: 16px;
                .top_item {
                    margin-top: 0px;
                    max-width: var(--max_width);
                    width: 100%;
                }
            }
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            margin-top: 40px;
        }

        .floor_mint {
            .desc {
                margin: 0 auto;
                width: calc(100% - 60px);
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                margin-top: 8px;
                margin-bottom: 32px;
                color: var(--font_color);
            }
            .title {
                margin: 0 auto;
                width: calc(100% - 60px);
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 48px;
                text-align: center;
            }
            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 292px;
            margin-top: 40px;
            border-radius: 32px;
            display: flex;
            box-sizing: content-box;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            /* padding-top: 10px;
            padding-bottom: 10px; */
        }
        .floor_banner {
            .banner_space {
                width: 32px;
            }
            .banner_right {
                margin-top: 40px;
            }
            .banner_item {
                .main {
                    .maim_item {
                        padding-bottom: 16px;
                        margin-bottom: 20px;
                        border-bottom: 1px solid var(--border_color);
                        &:last-child {
                            border-bottom: 1px solid rgba(0, 0, 0, 0);
                        }
                    }
                }
                .title {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 28px;
                    color: var(--font_deep_color);
                    margin-bottom: 8px;
                }
                flex: 1;
                min-width: var(--max_width);
            }
            width: 100%;
            max-width: var(--max_width);
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 24px;
            flex-direction: column;
        }
        width: 100%;
        // height:100%;
        // overflow: auto;
    }
}
@media only screen and (max-width: 500px) {
    .floor_banner {
        display: block !important;
    }
}
</style>
