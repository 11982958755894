<template>
    <div :class="commonStyle ? 'table_main_common' : 'table_main'">
        <div class="selectAll"></div>
        <div class="table">
            <div class="table_head">
                <div class="head_item" v-for="(item, index) in listKey" :key="index">
                    {{ title[item].title }}
                </div>
                <div v-if="action.length" class="head_item">
                    {{ langJson('') }}
                </div>
            </div>
            <NuData height="300" v-if="!lenArray(list)" />
            <div class="table_body" v-if="lenArray(list)">
                <!-- 行 -->
                <div class="table_row" v-for="(item, index) in list" :key="index">
                    <!-- 列 -->
                    <div class="table_column" v-for="(subItem, subIndex) in listKey" :key="subIndex">
                        <div class="text" v-if="title[subItem].type == 'text'" :title="list[index][subItem]">
                            <span v-if="!isNaN(list[index][subItem] - 0)">{{ list[index][subItem] | numberGrap }}</span>
                            <span v-else>{{ list[index][subItem] }}</span>
                        </div>
                        <div v-else>
                            <slot :name="subItem" :data="item"></slot>
                        </div>
                    </div>

                    <!-- 按钮，根据play数组和 -->
                    <div v-if="action.length" class="table_column play">
                        <div v-for="(subItem, subIndex) in action" @handle="triggleEvent(subItem.event, list[index])" :key="subIndex" :type="subItem.type" size="mini" height="32px" :load="!!loadItem">{{ subItem.title }}</div>
                    </div>
                </div>
            </div>

            <div class="table_foot" v-if="lenArray(list)">
                <slot name="foot"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        action: {
            type: Array,
            default: () => {
                return []
            },
        },
        title: {
            type: Object,
            default: () => {
                return {}
            },
        },
        loadItem: {
            type: [String, Number],
            default: '',
        },
        commonStyle: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        Object.keys(this.$slots).forEach((item) => {
            // console.log(item)
        })
    },
    computed: {
        haveData() {
            return this.list && this.list.length
        },
        // title数组中的key值组成的表头数组
        listKey() {
            return Object.keys(this.title)
        },
    },
    data() {
        return {
            allSelected: false,
            tagList: [
                { icon_light: 'edit_invite', icon_light: 'edit_invite_light', val: 'Invite', method: 'invite', isChecked: false, number: 1 },
                { icon_light: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', method: 'delete', isChecked: false, number: 4 },
            ],
        }
    },
    methods: {
        triggleEvent(event, item) {
            this.$emit(event, item)
        },
    },
}
</script>
<style lang="scss" scoped>
.table_main_common {
    background-color: var(--color_white);
    color: var(--font_modif_color);
    letter-spacing: 1px;
    gap: 32px;
    display: flex;
    flex-direction: column;
    .selectAll {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
    .table {
        box-sizing: border-box;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-direction: column;
        gap: 23px;
        .table_head {
            display: flex;
            background: var(--bg_color);
            padding: 8px 16px;
            overflow: auto;
            border-radius: 32px;
            gap: 24px;
            .head_item {
                font-size: 18px;
                line-height: 24px;
                color: var(--font_deep_color);
                box-sizing: border-box;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_color);
            }
            .head_item:nth-child(1) {
                width: 160px;
            }
            .head_item:nth-child(2) {
                width: 200px;
            }
            .head_item:nth-child(3) {
                width: 160px;
            }
            .head_item:nth-child(4) {
                width: 160px;
            }
            .head_item:nth-child(5) {
                width: 160px;
            }
            .head_item:nth-child(6) {
                width: 110px;
            }
        }
        .table_body {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .table_row {
                display: flex;
                gap: 24px;
                padding: 10px 16px;
            }

            .table_column:nth-child(1) {
                width: 160px;
            }
            .table_column:nth-child(2) {
                width: 200px;
            }
            .table_column:nth-child(3) {
                width: 160px;
            }
            .table_column:nth-child(4) {
                width: 160px;

                .text {
                    width: 88.89px;
                    height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #ffeae0;
                    border-radius: 32px;
                    color: #ff9466;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
            .table_column:nth-child(5) {
                width: 160px;
            }
            .table_column:nth-child(6) {
                width: 110px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                text-decoration-line: underline;
                color: var(--primary_color);
                cursor: pointer;
            }
        }
    }
}
</style>
