<template>
    <div :id="components_id" class="select" @mouseleave="leave">
        <div class="select_btn" @click="openPopup" @mouseenter="openPopup">
            <slot name="target"></slot>
        </div>
        <div
            class="select_popup"
            :id="popup_id"
            :style="position"
            @mouseenter="clearLeave"
        >
            <slot name="top"></slot>
            <slot name="main"></slot>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
<script>
import move from 'move-js'
export default {
    data() {
        return {
            top: 0,
            offset: 10,
            timeNum: null,
            popup_id: '',
        }
    },
    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        size: {
            type: [Number, String],
            default: 200,
        },
        ws: {
            type: [Number, String],
            default: 0,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        left: {
            type: Number,
            default: 0,
        },
        right: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    inject: ['regCode'],
    created() {
        let code = this.regCode()
        this.components_id = 'select_' + code
        code = this.regCode()
        this.popup_id = 'select_popup_' + code
    },
    mounted() {
        let { height } = document.getElementById(this.components_id).getBoundingClientRect()
        this.top = this.offset + height
        move.defaults = {
            duration: 500,
        }
        // this.openPopup()
    },
    computed: {
        position() {
            if (this.right === '') {
                return {
                    top: `${this.top}px`,
                    left: `${this.left}px`,
                    width: `auto`,
                }
            } else {
                return {
                    top: `${this.top}px`,
                    right: `${this.right}px`,
                    width: `auto`,
                }
            }
        },
        getStyle() {
            console.log(this.top)
            return this.ws && this.top
                ? {
                      top: `${this.top}px`,
                      width: `${this.ws}px`,
                  }
                : {
                      top: `${this.top}px`,
                      right: `0px`,
                  }
        },
        current() {
            let result = this.list.find((item) => {
                return item.value === this.value
            })
            return result
                ? result
                : {
                      lable: this.placeholder ? this.placeholder : this.langJson('All'),
                      value: '',
                  }
        },
    },
    methods: {
        selectItem(val = '') {
            this.$emit('change', val)
            this.closePopup()
        },
        openPopup() {
            let node = document.getElementById(this.popup_id)
            let result = this.SubTH(node)
            result = result > 300 ? 300 : result + 12

            if (document.getElementById(this.components_id)) {
                // 打开选项
                move(`#${this.components_id} .select_popup`).set('height', `${result}px`).set('opacity', 1).end()
            }
            this.$emit('handle')
        },
        leave() {
            if (!this.timeNum) {
                this.timeNum = setTimeout(() => {
                    this.closePopup()
                }, 200)
            }
        },
        clearLeave() {
            clearTimeout(this.timeNum)
            this.timeNum = null
        },
        closePopup() {
            this.clearLeave()

            if (document.getElementById(this.components_id)) {
                // 收起选项
                move(`#${this.components_id} .select_popup`).set('height', `0px`).set('opacity', 0).end()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.select {
    position: relative;
    .select_popup {
        .popup_list {
            padding: 12px;
            padding-top: 0px;
            .popup_item {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
                margin-top: 12px;
                cursor: pointer;
                padding: 8px 12px;
                box-sizing: border-box;
                transition: all 0.5s;
            }
            .popup_item:hover {
                background: var(--color_white);
                border-radius: 6px;
            }
        }
        height: 0px;
        overflow: auto;
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: 2000000;
    }
    .select_btn {
        cursor: pointer;
        // min-height: 44px;
    }
}
</style>
