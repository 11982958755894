<template>
    <div class="item">
        <div v-if="showManage" class="showManage">
            <BtnPop :left="isPhone ? -190 : 0">
                <div slot="target" class="gi" style="height: 24px; width: 24px">
                    <GI name="brand_more" ws="24" hs="24" class="pointer icon_"></GI>
                </div>
                <div slot="main" class="type_main">
                    <div v-for="(item, index) in tagList" :key="index" class="type_content">
                        <div @click="goRoute(item)" class="type_line">
                            <GI ws="20" hs="20" :name="item.icon"></GI>
                            <span class="title" :class="[item.isChecked && 'red']">{{ item.val }}</span>
                        </div>
                    </div>
                </div>
            </BtnPop>
        </div>
        <div v-show="showHead" class="brand_detail">
            <CheckBox @click.native="selectItem" :active="selected" :disabled="!!item.status" :class="!!item.status ? 'unPointer' : ''"></CheckBox>
            <!-- status 0-未铸造 1-已铸造 -->
            <div class="rank_vote" v-show="!isParticipant" v-if="top != ''">
                {{ langJson('No.') }}{{ top }}

                <!-- {{ item.sort }} -->
            </div>
        </div>
        <div class="rank_vote2" v-show="isParticipant" v-if="top != ''">{{ langJson('Top ') }} {{ top }}</div>

        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image cursor lazyload" :style="{ backgroundImage: `url(${item.artworkImg})` }" :data-bg="item.artworkImg" @click="isParticipant ? jump_participant_campaign_artwork_detail({ idArt: item.id, idActive: item.campaignId, idUser: item.userId }) : jump_brand_compaign_artwork_detail({ idArt: item.id, idActive: item.campaignId, idUser: item.userId })"></div>
        </div>

        <div class="item_info">
            <div class="item_title">
                <div class="title">{{ item.artworkTitle }}</div>
                <span class="item_subtitle">@{{ item.artworkCredit }}</span>
                <!-- 活动名 和活动的链接 -->
                <!-- <span v-show="showCompaign" @click="jump_participant_compaign_preview({ id: item.campaignId })" class="artworks_for_compaign">
                    {{ item.campaignName }}
                </span> -->
                <span v-show="showCompaign" @click="isParticipant ? jump_participant_compaign_preview({ id: item.campaignId }) : jump_brand_compaign_preview({ status: status, id: item.campaignId, operateStop: item.operateStop })" class="artworks_for_compaign">
                    {{ item.campaignName }}
                </span>
            </div>
            <div v-show="showBottom" class="item_desc">
                {{ item.voteNum }} {{ langJson('votes') }}
                <div class="btns">
                    <BTN size="mini" v-show="isParticipant" :load="loading" @handle="VoteMethod(item)" type="detail" bw="88" bh="36" :class="[item.voteFlag && 'voted']" borderWidth="1">{{ langJson('Vote') }}</BTN>
                    <BTN size="mini" v-show="isParticipant" @handle="goRoute({ number: 3 })" type="detail" bw="88" bh="36" borderWidth="1">{{ langJson('Share') }}</BTN>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        // 前三
        top: {
            type: [String, Number],
            default: '',
        },
        // 展示头部
        showHead: {
            type: Boolean,
            default: false,
        },
        showManage: {
            type: Boolean,
            default: false,
        },
        // 展示底部按钮
        showBottom: {
            type: Boolean,
            default: false,
        },
        // 蓝字下划线
        showCompaign: {
            type: Boolean,
            default: false,
        },
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
        selectlist: {
            type: Array,
            default: () => {
                return []
            },
        },
        status: {
            type: String,
            default: '',
        },
    },
    computed: {
        selected() {
            return this.selectlist.some((item) => {
                return item == this.item.id
            })
        },
    },
    data() {
        return {
            tagList: [
                // { icon: 'brand_eye', val: 'Hide', isChecked: false, number: 1 },
                // { icon: 'brand_edit', val: 'Edit', isChecked: false, number: 2 },
                { icon: 'brand_candle', val: this.langJson('Share'), isChecked: false, number: 3 },
                { icon: 'brand_trash', val: this.langJson('Delete'), isChecked: true, number: 4 },
            ],
            active: false,
            voteShow: false,
            loading: false,
        }
    },

    methods: {
        async VoteMethod(detail) {
            this.loading = true
            try {
                await this.vote(detail)
            } catch (err) {}
            this.loading = false
        },
        selectItem() {
            if (!!this.item.status) {
                return
            }
            this.$emit('select', this.item)
        },
        goRoute(item) {
            let that = this
            switch (item.number) {
                case 1:
                    break
                case 2:
                    break
                case 3:
                    // http://192.168.1.111:8120/participant/campaign/artwork/detail?idArt=6&idActive=6&idUser=9
                    that.$store.commit('changeShowShare', true)
                    this.$store.commit('shareObj', {
                        ...this.globalConfig.shareObj,
                        img: this.item.artworkImg,
                        title: this.langJson('Share your ArtWork'),
                        url: `/participant/campaign/artwork/detail?idArt=${this.item.id}&idActive=${this.item.campaignId}&idUser=${this.item.userId}`,
                        linkName: this.langJson('Artwork link'),
                    })
                    break
                case 4:
                    this.delArt()
                    break
                default:
                    break
            }

            // console.log('this.$store.state.config.showShare', this.$store.state.config.showShare)
            // console.log(item)
        },
        async delArt() {
            this.$emit('delArt')
        },
    },
}
</script>
<style lang="scss" scoped>
.red {
    color: var(--danger_color) !important;
}

.showManage {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.voted {
    border-color: var(--font3) !important;
    color: var(--font3) !important;
}

.item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    gap: 16px;

    .item_info {
        .item_desc {
            .number {
                flex: 1;
                .text {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .btns {
                display: flex;
                align-items: center;
                gap: 10px;
            }
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .item_title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
                margin-bottom: 4px;
            }
            .item_subtitle {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color);
            }
        }
        .item_subtitle {
            /* Body/1/Regular */
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Neutral/3 */

            color: var(--font_color);
        }
        .artworks_for_compaign {
            display: block;
            font-family: 'Inter';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-decoration-line: underline;
            margin-bottom: 16px;
            color: #3993e6;
            cursor: pointer;

            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: space-between;
        height: 32px;
        .rank_vote {
            width: 61px;
            background: #ffeae0;
            border-radius: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            color: #ff9466;
        }
    }
    .rank_vote2 {
        position: absolute;
        top: 24px;
        right: 24px;
        font-family: 'Lato';
        font-size: 12px;
        width: 61px;
        background: var(--bg3);
        border-radius: 32px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: var(--font2);
        z-index: 10;
    }

    .image_out {
        border-radius: 12px;
        overflow: hidden;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
            cursor: pointer;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}
.dialog_main {
    width: 422px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    h1 {
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: var(--font_deep_color);
    }
    span {
        text-align: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--font_deep_color);
    }
}

.brand_detailThreePoints {
    display: flex;
    justify-content: flex-end;
    height: 40px;
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .dialog_main {
        width: 74.4vw !important;
    }
    .image_out {
        border-radius: 20px !important;
    }
}
</style>
