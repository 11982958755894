<template>
    <div class="step_item">
        <div class="serie_item" v-for="(item,index) in series" :key="index">
            <img class="serie_img" :src="item.previewImg" />
            <div class="serie_detail">
                <div class="detail_top">
                    <div class="name">{{item.name}}</div>
                    <div class="level">
                        <img :src="item.levelIcon" alt="" class="level_img" :alt="item.levelName">
                    </div>
                </div>
                <div class="detail_main">
                    <div class="main_item">
                        <div class="key">{{langJson("Circulation")}}</div>
                        <div class="val">{{item.amount}}</div>
                    </div>
                    <div class="main_item">
                        <div class="key">{{langJson("Probability")}}</div>
                        <div class="val">{{rate(item.probability)}}%</div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import inoApi from "@/api/myster";
export default {
    data() {
      return {
        series:[]
      };
    },
    created() {
        this.getSeries()
    },
    computed:{
        rate() {
            return (val) => {
                return this.multiplication(val,100)
            }
        }
    },
    methods:{
        async getSeries() {
            try{
                let result = await inoApi.inoSeries({
                    mysteryBoxId:1
                })
                let success = result && result.code == 200
                if(success) {
                    this.series = result.rows
                }else {
                    // 提示
                }
            }catch(err){
                console.log(err)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.step_item{
    .serie_item{
      display: flex;
      height:100px!important;
      padding:10px;
      background-color: rgba($color: #ccc, $alpha: 0.2);
      border-radius: 8px;
      margin: 0 20px 20px 0;
      box-sizing: border-box;
      .serie_img{
          width:80px;
          height:80px;
          border-radius: 6px;
          margin-right:15px;
      }
      .serie_detail{
          .detail_main{
              min-width:150px;
              
              .main_item{
                  display:flex;
                  align-items:center;
                  justify-content: space-between;
                  margin-top:12px;
                  .key{
                      font-weight:600;
                      margin-right:20px;
                  }
              }
          }
          .detail_top{
              .name{
                  font-size:18px;
                  font-weight:bold;
                  margin-right:20px;
                  color:var(--font_deep_color);
              }
              .level{
                  .level_img{
                      height:20px;
                  }
              }
              display:flex;
              align-items:center;
              justify-content: space-between;
          }
          flex:1;
          color:var(--font_modif_color);
      }
    }
    background:var(--color_white);
    height:400px;
    margin-top:20px;
    border-radius: 12px;
    padding:20px;
    box-sizing: border-box;
    overflow: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
    display: flex;
    align-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
</style>