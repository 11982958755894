<template>
    <div class="nftSourceImg">
        <div class="imgSource">
            <img class="cover" :src="isDetail ? Preview : Data" @load="load()" @error="error()" :fit="fit" />

            <div class="img_loading" v-show="!loadingEnd">
                <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
                <div class="loading_text">{{ langJson('loading') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isDetail: {
            type: Boolean,
            default: Boolean,
        },
        Preview: {
            type: String,
            default: String,
        },
        Data: {
            type: String,
            default: String,
        },
        fit: {
            type: String,
            default: 'scale-down',
        },
        isUploadFile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingEnd: false,
        }
    },
    created() {},
    mounted() {},
    computed: {},
    methods: {
        load() {
            this.loadingEnd = true
        },
        error() {
            this.loadingEnd = true
        },
    },
}
</script>
<style lang="scss" scoped>
.nftSourceImg {
    height: 100%;
    width: 100%;
}
.none {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgSource {
    width: 100%;
    height: 100%;
    position: relative;
    .img_loading {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        .loading_text {
            margin-top: 6px;
            color: #fff;
            font-size: 16px;
            font-family: 'Cabinet Grotesk';
        }
    }
    .cover {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
    }
}
</style>
