var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['switch', 'cursor'],style:({
        width: `${_vm.size}px`,
        height: `${24}px`,
        borderRadius: `${24}px`,
    }),attrs:{"id":_vm.components_id},on:{"click":_vm.onOff}},[_c('div',{staticClass:"switch_inner cursor",style:({
            width: `${_vm.val ? 40 : 23.2}px`,
            height: `${24}px`,
            borderRadius: `${24}px`,
            background: _vm.val ? `linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%)` : '',
            'padding-right': _vm.val ? '0.8px' : '0px',
        })},[_c('div',{staticClass:"switch_point cursor",style:({
                width: `${22.4}px`,
                height: `${22.4}px`,
                borderRadius: `${_vm.size}px`,
            })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }