<template>
    <div class="">
        {{ timeObj.days - 0 }}{{ langJson('d') }} : 
        {{ timeObj.hours - 0 }} {{ langJson('h') }} : 
        {{ timeObj.minutes - 0 }} {{ langJson('m') }}
        </div>
</template>
<script>
export default {
    data() {
        return {
            refer: 1,
            target: null,
        }
    },
    computed: {
        timeObj() {
            return this.refer && this.remainTime(this.time)
        },
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        time: {
            type: [String, Number],
            default: 0,
        },
        type: {
            type: String,
            default: 'active',
        },
    },
    created() {
        this.target = setInterval(() => {
            this.refer = new Date().getTime()
        }, 1000)
    },
    beforeDestroy() {
        clearInterval(this.target)
    },
}
</script>
<style lang="scss" scoped></style>
