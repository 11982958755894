<template>
    <div>
        <!-- 选择艺术品后提交 -->
        <Go :prev="langJson('Back')" @prev="back"></Go>
        <div class="pageTitle" @click="showSubmitArtwork = true">{{ langJson('Let’s choose a campaign to submit your artwork') }}</div>
        <div class="line"></div>
        <div class="explore_main">
            <div class="explore_item" v-for="(item, index) of globalMarket.campaignBrand" :key="index">
                <!-- <CompaignItem :detail="item" hs="200" :status="status" :tagList="tagListLive" :isParticipant="true" :showBotomBtn="true" :showHeader="false" @openModel="openModel(item)"></CompaignItem> -->
                <CPRO :showBtnSure="true" :isParticipant="true" :item="item" :padding="isPhone ? '12px' : '16px 24px 24px 24px'" :hs="isPhone ? '132' : '200'" imageRadius="20" titType="item_title_small" descType="item_desc_small" @openModel="openModel(item)"></CPRO>
            </div>
            <div class="explore_item gap" v-for="item of 3" :key="item + 'gap'"></div>

            <NuData height="300" v-if="!lenArray(globalMarket.campaignBrand)" />
            <div class="art_more">
                <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="true"></Page>
            </div>
        </div>
        <Model :padding="'24px'" v-model="showSubmitArtwork">
            <SubmitArtTip :id="id" @cancel="cancel" slot="main" :titleActive="titleActive" @computed="handSubArtTip" />
        </Model>
    </div>
</template>

<script>
export default {
    data() {
        return {
            banner: '',
            titleActive: '',
            pageNum: 1,
            pageSize: 24,
            total: 0,

            id: '',
            status: 'Live',
            showSubmitArtwork: false,
            tagListLive: [
                { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'View as a participant', isChecked: false, number: 1, status: 'live' },
                { icon_dark: 'brand_edit', icon_light: 'brand_edit_light', val: 'Edit campaign', isChecked: false, number: 2, status: 'live' },
                { icon_dark: 'brand_candle', icon_light: 'brand_candle_light', val: 'Manage submissions', isChecked: false, number: 3, status: 'live' },
                { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', isChecked: false, number: 4, status: 'live' },
            ],
        }
    },
    watch: {
        'globalMarket.campaignBrandName'() {
            this.pageNum = 1
            this.init()
        },
    },
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        handSubArtTip() {
            localStorage.setItem('loginBgUrl', this.banner)
        },
        openModel(item) {
            console.log('开始提交')
            this.id = item.id
            this.banner = item.banner
            this.titleActive = item.name
            this.showSubmitArtwork = true
        },
        // 已上架的活动列表 campaignEnd
        async init() {
            try {
                let { dispatch } = this.$store
                let { id } = this.$route.query

                let res = await dispatch('campaignBrand', { pageNum: this.pageNum, pageSize: this.pageSize, brandUserId: id ? id : '' })
                this.total = res.total
            } catch (error) {
                console.log(error)
            }
        },
        cancel(val) {
            this.showSubmitArtwork = val
        },
    },
}
</script>
<style lang="scss" scoped>
.art_more {
    margin-top: 36px;
    margin-bottom: 82px;
    width: 100%;
}

.pageTitle {
    margin: auto;
    width: 100%;
    max-width: var(--max_width);
    font-family: 'Cabinet Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: var(--font_deep_color);
}

.explore_main {
    margin: auto;
    width: 100%;
    max-width: var(--max_width);
    padding-bottom: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;

    .explore_item {
        /* 335px */
        min-width: 335px;
        max-width: 335px;
        width: 100%;
        background: var(--color_white);
        padding: 24px;
        padding-top: 16px;
        box-sizing: border-box;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 16px;
        white-space: wrap !important;
        /deep/ .item {
            padding: 0 !important;
        }
        &.gap {
            height: 0;
            padding: 0;
            overflow: hidden;
        }
        &:hover {
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
        }
    }
}
@media only screen and (max-width: 1080px) {
    .pageTitle {
        margin: auto;
        width: 100%;
        max-width: var(--max_width);
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }
    .line {
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid var(--border_color);
    }
    .explore_main {
        margin: auto;
        width: 100%;
        max-width: var(--max_width);
        padding-bottom: 50px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;
        .explore_item {
            max-width: 45%;
            min-width: 335px;
            width: 100%;
            flex-shrink: 0;
            background: var(--color_white);
            padding: 16px;
            box-sizing: border-box;
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
            white-space: wrap !important;
            &:hover {
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
            }
        }
    }
}

@media only screen and (max-width: 742px) {
    .explore_item {
        max-width: 100% !important;
        min-width: 335px;
        width: 100%;
        flex-shrink: 0;
        background: var(--color_white);
        padding: 16px;
        box-sizing: border-box;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 16px;
        white-space: wrap !important;
        &:hover {
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
        }
    }
}
</style>
