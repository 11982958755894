<template>
    <div class="collected">
        <div class="search_item">
            <div class="search_main">
                <div class="search">
                    <IN v-if="!isPhone" v-model="source.name" :placeholder="langJson('Search NFT')"></IN>
                    <Search ws="80px" v-if="isPhone" v-model="source.name"></Search>
                </div>
                <div class="sort">
                    <Sel :showBorder="true" v-model="source.orderByColumn" :list="sortlist"></Sel>
                </div>
            </div>
        </div>
        <!-- drafts and Submitted for campaign -->
        <!-- <div v-if="!isParticipant" class="kind"><SelBtn class="status_item" size="big" v-model="status" type="main" :list="statuslist" ></SelBtn></div> -->
        <div class="artworks_box">
            <div class="artworks_item" v-for="(item, index) in list" :key="index">
                <NtfItem :item="item" :showBottom="!isParticipant ? true : false" :showHead="true" :showCompaign="!isParticipant ? true : false" hs="204" :isParticipant="isParticipant"></NtfItem>
                
            </div>
            <div class="artworks_grap"></div>
            <div class="artworks_grap"></div>
            <div class="artworks_grap"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            viewEle: {
                price: false,
                status: true,
                play: false,
            },
            source: {
                name: '',
                orderByColumn: '',
            },

            sortlist: [
                {
                    lable: this.langJson('Sort By'),
                    value: '',
                },
                {
                    lable: this.langJson('Recently created'),
                    value: 'createTime',
                },
                // {
                //     lable: this.langJson('Number of votes'),
                //     value: 'voteNum',
                // },
            ],
            status: '1',
            statuslist: [
                {
                    lable: 'Submitted for campaign',
                    value: '1',
                },
                {
                    lable: 'Drafts',
                    value: '2',
                },
            ],
            total: 0,
            pageNum: 1,
            pageSize: 10,
        }
    },
    created() {},
    watch: {
        source: {
            handler(n) {
                this.$emit('change', n)
            },
            deep: true,
        },
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.collected {
}
.search_item {
    .search_main {
        .sort {
        }
        .search {
            background-color: var(--color_white);
            border-radius: var(--nft_select_border_radius);
            /* border: 1px solid var(--border_color); */
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    padding-top: 32px;
    width: 100%;
    border-radius: 12px;
}
.kind {
    margin-top: 32px;
    margin-bottom: 32px;
}
.artworks_box {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    .artworks_item {
        max-width: 296px;
        width: 100%;
        background: var(--color_white);
        box-sizing: border-box;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 16px;
    }
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child:hover {
            color: var(--danger_color);
            transition: all 1s;
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
@media only screen and (max-width: 1080px) {
    .kind {
        margin-top: 20px;
        margin-bottom: 24px;
    }
    .artworks_item {
        max-width: 100% !important;
        /* flex: 1 !important; */
    }
    .artworks_box {
        gap: 16px !important;
    }
}
</style>
