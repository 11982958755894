<template>
    <div class="select_btns">
        <div :class="['btn_item', current.value === item.value && type, size]" v-for="(item, index) in list" :key="index" @click="selectItem(item.value)">
            {{ item.lable }}
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        type: {
            type: String,
            default: 'default',
        },
        value: {
            type: [Number, String],
            default: '',
        },
        size: {
            type: String,
            default: 'mini',
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    computed: {
        current() {
            let result = this.list.find((item) => {
                return item.value === this.value
            })
            return result
                ? result
                : {
                      lable: this.langJson('All'),
                      value: '',
                  }
        },
    },
    methods: {
        selectItem(val = '') {
            this.$emit('change', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.select_btns {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .btn_item {
        color: var(--font_modif_color);
        border-radius: 32px;
        cursor: pointer !important;
        box-sizing: border-box;
    }

    .mini {
        padding: 6px 12px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        border-radius: 4px;
    }

    .medium {
        padding: 10px 20px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        border-radius: 6px;
    }

    .big {
        padding: 12px 24px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        border-radius: 8px;
    }

    .default {
        background: #e0f0ff;
        color: var(--primary_color);
    }

    .unselected {
        padding: 12px 24px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_color);
        cursor: pointer;
    }

    .main {
        background: var(--nft_select_primary);
        border-radius: var(--nft_select_border_radius);
        color: var(--nft_select_font);
    }
}

@media only screen and (max-width: 1080px) {
    .select_btns {
        flex-wrap: nowrap;

        .btn_item {
            white-space: nowrap;
            font-size: 14px !important;
            padding: 8px 24px;
            line-height: 20px !important;
        }
    }
}
</style>
