<template>
    <div>
        <div class="circle" :style="logoStyle"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        logoStyle() {
            try {
                let { webLogo,webDarknessLogo } = this.globalImage
                if(this.globalTheme != 'light') {
                    webLogo = webDarknessLogo
                }
                return { backgroundImage: `url(${webLogo})`, width: this.isPhone ? `40px` : `80px`, borderRadius: `50%`, height: this.isPhone ? `40px` : `80px` }
            } catch (error) {
                return {}
            }
        },
    },
    mounted() {},
    methods: {},
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.circle {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);*/
    border-radius: 50%;
}
@media screen and (max-width: 1081px) {
    .circle {
        width: 40px;
        height: 40px;
    }
}
</style>
