<template>
    <div class="container">
        <div class="main">
            <!-- <div v-if="!isPhone" class="theme_box">
				<Theme></Theme>
			</div> -->

            <div class="title">
                <Logo></Logo>
                <div>
                    <h2 style="text-align: center">{{ langJson('Choose a plan that works for you') }}</h2>
                    <p class="title_text">
                        {{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada vulputate elementum iaculis sed') }}
                        <br />
                        {{ langJson('amet hendrerit. Pulvinar senectus vitae at amet mattis sed dictum gravida enim.') }}
                    </p>
                </div>
            </div>
            <div class="price">
                <div class="price_card">
                    <div class="card_content">
                        <div class="upper">
                            <div class="level_border">
                                <div class="level">{{ langJson('STARTER') }}</div>
                            </div>
                            <h2 class="count">$00.00</h2>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                        </div>
                        <div class="down">
                            <BTN type="active">{{ langJson('Choose Plan') }}</BTN>
                        </div>
                    </div>
                </div>
                <div class="price_card">
                    <div class="card_content">
                        <div class="upper">
                            <div class="level_border">
                                <div class="level">{{ langJson('PREMIUM') }}</div>
                            </div>
                            <h2 class="count">$99.00</h2>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                        </div>
                        <div class="down">
                            <BTN type="active">{{ langJson('Choose Plan') }}</BTN>
                        </div>
                    </div>
                </div>
                <div class="price_card">
                    <div class="card_content">
                        <div class="upper">
                            <div class="level_border">
                                <div class="level">{{ langJson('ENTERPRISE') }}</div>
                            </div>
                            <h2 class="count">$399.00</h2>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                            <div class="content">
                                <GI name="content_tick" hs="24" ws="24"></GI>
                                <span>{{ langJson('Lorem ipsum dolor sit amet, consectetur adipiscing elit.') }}</span>
                            </div>
                        </div>
                        <div class="down">
                            <BTN @handle="paySuccess" type="active">{{ langJson('Choose Plan') }}</BTN>
                            <!-- paySuccess-->
                            <!-- <Model v-model="modelShow">
                <template v-slot:main>
                  <div class="pay_dialog_main">
                    <div class="pay_icon">
                      <GI name="mint_completed" ws="200" hs=" 233"></GI>
                      <div class="pay_completed">
                        <p>{{ langJson('You’ve made payment successfully') }}</p>
                        <span>{{langJson('Payment will be charged upon verification of company details')}} </span>
                        <div class="btns">
                          <BTN bw="406" type="active"> {{ langJson('OK') }} </BTN>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Model> -->
                            <!-- Connect Wallet Metamask -->
                            <Model v-model="modelShow">
                                <template v-slot:main>
                                    <div class="connect_dialog_main">
                                        <div class="connect_top">
                                            <div class="top_left">
                                                <h2>{{ langJson('Connect wallet') }}</h2>
                                                <span>{{ langJson('For storing your final NFTs') }}</span>
                                            </div>
                                            <div class="top_right cursor" @click="closeModel">
                                                <GI name="model_close" ws="24" hs="24"></GI>
                                            </div>
                                        </div>
                                        <div class="connect_content">
                                            <div class="metamask">
                                                <GI name="metamask" hs="48" ws="48"></GI>
                                                <span>{{ langJson('Metamask') }}</span>
                                            </div>
                                        </div>
                                        <div class="connect_btns">
                                            <BTN @handle="closeModel" bw="210" type="detail">{{ langJson('Cancel') }}</BTN>
                                            <BTN bw="210" type="active">{{ langJson('Connect') }}</BTN>
                                        </div>
                                    </div>
                                </template>
                            </Model>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            modelShow: false,
        }
    },

    methods: {
        paySuccess() {
            this.modelShow = true
        },
        closeModel() {
            this.modelShow = false
        },
    },
}
</script>
<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .main {
        max-width: var(--max_width);
        margin: auto;
        width: 100%;
		padding:80px 0;
        .title {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            .circle {
                width: 80px;
                height: 80px;
                background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                border-radius: 50%;
            }
            h2 {
                font-weight: 700;
                font-size: 32px;
                line-height: 52px;
            }
            .title_text {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                white-space: wrap;
                color: var(--font_modif_color);
            }
        }
        .price {
            margin: auto;
            margin-top: 64px;
            width: 100%;
            display: flex;
            gap: 32px;
            .price_card {
                max-width: 406px;
                width: 100%;
                height: 540px;
                flex-shrink: 0;
                background: #23252b;
                border-radius: 32px;
                padding: 32px 24px;
                box-sizing: border-box;
                .upper {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .level_border {
                        width: fit-content;
                        display: inline-block;
                        border: 2px solid transparent;
                        border-radius: 32px;
                        background-clip: padding-box, border-box;
                        background-origin: padding-box, border-box;
                        background-image: linear-gradient(to right, #23252b, #23252b), linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                        box-sizing: border-box;
                        .level {
                            width: fit-content;
                            padding: 8px 16px;
                            display: inline-block;
                            font-weight: 700;
                            box-sizing: border-box;
                            background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                        }
                    }
                    .count {
                        font-family: 'Cabinet Grotesk';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 56px;
                        color: #ffffff;
                    }
                    .content {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        span {
                            font-weight: 400;
                            font-size: 16px;
                            color: #ffffff;
                        }
                    }
                }
                .down {
                    margin-top: 80px;
                }
            }
        }
    }
}
.pay_dialog_main {
    width: 570px;
    height: 431px;
    display: flex;
    justify-content: center;
    align-items: center;
    .pay_icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        .pay_completed {
            display: flex;
            flex-direction: column;

            gap: 16px;
            justify-content: center;
            align-items: center;
        }
        p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        .btns {
            display: flex;
            gap: 16px;
        }
    }
}
.connect_dialog_main {
    box-sizing: border-box;
    width: 492px;
    height: 264px;
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .connect_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            font-family: 'Inter';
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
        }
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font_modif_color);
        }
    }
    .connect_content {
        display: flex;
        justify-content: center;
        .metamask {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
        }
    }
    .connect_btns {
        display: flex;
        gap: 16px;
    }
}
@media only screen and (max-width: 1080px) {
    .price {
        flex-direction: column;
        align-items: center;
        .price_card {
        }
    }
}
</style>
