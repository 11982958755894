<template>
    <div class="step_item">
        <Table :list="list" :title="title" :play="play">
            <div class="more" slot="foot">
                <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="false"></Page>
            </div>
        </Table>
    </div>
</template>
<script>
export default {
    data(){
        return {
            play:[],
            list:[
                {
                    name:"Miss Wan",
                    price:"200 ETH",
                    time:"2022-09-20 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    name:"Miss Zhou",
                    price:"140 ETH",
                    time:"2022-09-12 20:00",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    name:"Miss Li",
                    price:"100 ETH",
                    time:"2022-09-10 20:00",
                    address:"0xDe126...e1762e1Dc"
                }
            ].concat(new Array(6).fill({
                name:"Miss Li",
                price:"100 ETH",
                time:"2022-09-10 20:00",
                address:"0xDe126...e1762e1Dc"
            })),
            title:{
                name:{
                    title:"name",
                    type:"text"
                },
                price:{
                    title:"price",
                    type:"text"
                },
                time:{
                    title:"time",
                    type:"text"
                }
            }
        }
    },
    computed:{
        haveData() {
            return this.list && this.list.length
        },
        listKey() {
            return Object.keys(this.title)
        }
    }
}
</script>
<style lang="scss" scoped>
.more{
    height:50px;
    background:var(--color_white);
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px -5px 10px var(--bg_color);
    padding: 0 20px;

}
.more .page_main{
    justify-content: space-between!important;
    width:100%;
}
.step_item{

    height:400px;
    margin-top:20px;
    
}
</style>