<template>
  <div class="item">
    <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
      <div class="item_image" :style="{ backgroundImage: `url(/source/png/image.jpeg)` }" @click="jump_brand_compaign_preview"></div>
    </div>

    <div class="item_info">
      <div class="item_title">
        {{ langJson('Skechers Sundown Festival') }}
      </div>
      <div class="header">
        <GI></GI> <span>{{ langJson('LUMA Events') }} </span>
      </div>

      <div class="item_desc">
        {{ langJson('Art Meets Tech at Fast-Growing Festival Highlighting Innovative Storytellers from Around the World') }}
      </div>
      <JoinedCompaignVI coll="334" zan="13" view="3343"></JoinedCompaignVI>
    </div>
  </div>
</template>
<script>
import GI from '../../../base/GI'
export default {
  components: { GI },
  props: {
    ws: {
      type: [String, Number],
      default: '100%',
    },
    // 图片高度
    hs: {
      type: [String, Number],
      default: 'auto',
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: column;
  // margin-right: 24px;
  // margin-left: 24px;
  .item_info {
    margin-top: 16px;
    .item_desc {
      margin-top: 16px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--font_color);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      white-space: pre-wrap;
    }

    .item_title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: var(--font_deep_color);
    }
    .header {
      margin-top: 4px;
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_deep_color);
      }
    }
    flex: 1;
  }
  .brand_detail {
    display: flex;
    justify-content: flex-end;

    height: 40px;
  }

  .image_out {
    border-radius: 12px;
    overflow: hidden;

    background-color: rgba($color: #ccc, $alpha: 0.2);
    .item_image {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      transition: all 2s;
    }
    .item_image:hover {
      transform: scale(2);
    }
  }
}
</style>
