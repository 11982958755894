<template>
    <Model :padding="'24px'" :title="langJson('Share')" v-model="show">
        <div class="share_main" slot="main">
            <div class="share_image" :style="{
                backgroundImage:`url(${image})`
            }"></div>
            <div id="share_copy" class="share_link" :data-clipboard-text="url" @click="copy('#share_copy')">
                <div class="link_left">
                    <div class="link_title">{{langJson("Share link")}}</div>
                    <div class="link_text">{{url}}</div>
                </div>
                <div class="link_right">
                    <!-- <Tag id="share_copy" type="success" :data-clipboard-text="getlink" 
                    @click="copy('#share_copy')" size="medium">{{langJson("Copy")}}</Tag> -->
                </div>
            </div>
        </div>
    </Model>
</template>
<script>

export default {
    model:{
        prop:"show",
        event:"change"
    },  
    props:{
        show:{
            type:Boolean,
            default:false
        },
        url:{
            type:String,
            default:""
        },
        image:{
            type:String,
            default:""
        }
    },
    methods:{
        close() {
            this.$emit("change",false)
        }
    }
}
</script>
<style lang="scss" scoped>
.share_main{
    .share_link{
        .link_right{
            margin-left:20px;
        }
        .link_left{
            .link_text{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color:var(--font_deep_color);
                margin-top:4px;
                width:100%;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .link_title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color:var(--font_modif_color);
            }
            flex:1;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--bg_color);
        border-radius: 32px;
        padding:12px 24px;
        flex:1;
        cursor: pointer;
    }
    .share_image{
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width:100%;
        min-height:500px;
        border-radius: 16px;
        margin:20px auto;
    }
    .share_top{
        .top_right{
            cursor: pointer;
        }
        .top_left{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:100%;
    }

    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px){
    .share_main{
        
        width: 100%;
    }
}
</style>