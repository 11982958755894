<template>
    <div class="step_item">
        <Table :list="list" :title="title" :play="play"></Table>
    </div>
</template>
<script>
export default {
    data(){
        return {
            play:[],
            list:[
                {
                    image:"https://w.wallhaven.cc/full/2y/wallhaven-2yglqx.png",
                    name:"Test Box 1",
                    price:"200 ETH",
                    count:"2323",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    image:"https://w.wallhaven.cc/full/vq/wallhaven-vqxl6l.png",
                    name:"Test Box 2",
                    price:"140 ETH",
                    count:"1233",
                    address:"0xDe126...e1762e1Dc"
                },
                {
                    image:"https://w.wallhaven.cc/full/45/wallhaven-45jdd3.jpg",
                    name:"Test Box 13",
                    price:"100 ETH",
                    count:"1555",
                    address:"0xDe126...e1762e1Dc"
                }
            ].concat(new Array(6).fill({
                image:"https://w.wallhaven.cc/full/x8/wallhaven-x8xgyl.jpg",
                name:"Test Box ...",
                price:"100 ETH",
                count:"333",
                address:"0xDe126...e1762e1Dc"
            })),
            title:{
                image:{
                    title:"Preview",
                    type:"image"
                },
                name:{
                    title:"Name",
                    type:"text"
                },
                price:{
                    title:"Price",
                    type:"text"
                },
                count:{
                    title:"Number of participants",
                    type:"text"
                }
            }
        }
    },
    computed:{
        haveData() {
            return this.list && this.list.length
        },
        listKey() {
            return Object.keys(this.title)
        }
    }
}
</script>
<style lang="scss" scoped>

.step_item{

    // height:400px;
    margin-top:20px;
    
}
</style>