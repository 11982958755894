<template>
    <div class="artwork_first">
        <div class="first_center">
            <div class="step_box">
                <div class="step_item step_active"></div>
                <div class="step_grap"></div>
                <div class="step_item"></div>
                <div class="step_grap"></div>
                <div class="step_item"></div>
            </div>
            <!-- 还可以参加3次 这功能先不管 -->
            <!-- <div class="step_tip">{{ langJson('Remaining NFTs:') }} 3</div> -->
            <div class="step_title">
                {{ langJson('First,') }}
                <br v-if="isPhone" />
                {{ langJson('let’s fill your') }}
                <br v-if="isPhone" />
                {{ langJson('information') }}
            </div>

            <!-- <FormItem v-model="form.artworkCredit" class="input_item" type="text" :title="langJson('Artwork credit to')" :matchs="['unull','maxLength']"></FormItem>

            <FormItem v-model="form.email" class="input_item" type="text" :title="langJson('Email')" :matchs="['unull', 'email']"></FormItem> -->

            <FormItem v-model="form.artistCodename" class="input_item" type="text" :title="langJson('Artist Street Name')" :optional="true"></FormItem>

            <div class="step_line"></div>

            <div class="btn_box">
                <BTN type="active" :disable="!pass" @handle="$emit('next')" :bw="isPhone ? 0 : 180" bh="52">
                    {{ langJson('Next 1/3') }}
                </BTN>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                artworkCredit: '', //艺术品归属
                email: '', // 123456@163.com
                artistCodename: '', // 美工代号
            },
        }
    },
    computed: {
        pass() {
            // let result = this.checkPass(this.form.artworkCredit, ['unull','maxLength'], false)
            // result = result && this.checkPass(this.form.email, ['unull', 'email'], false)
            return true
        },
    },
    watch: {
        form: {
            handler(newVal) {
                let { artworkCredit, email, artistCodename } = newVal
                this.$store.commit('campaignWorksSave', {
                    ...this.globalMarket.campaignWorksSave,
                    artworkCredit,
                    email,
                    artistCodename,
                })
            },
            deep: true,
        },
    },
}
</script>
<style lang="scss" scoped>
.artwork_first {
    .first_center {
        .btn_box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .step_line {
            width: 100%;
            // border-bottom: 1px solid var(--border_color);
            margin: 32px 0;
        }
        .input_item {
            margin-top: 32px;
        }
        .step_title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
            margin-top: 8px;
        }
        .step_tip {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--primary_color);
            margin-top: 32px;
        }
        .step_box {
            .step_item {
                flex: 1;
                height: 6px;
                background-color: var(--border_color);
                opacity: 0.4;
                border-radius: 4px;
            }
            .step_grap {
                width: 16px;
            }
            .step_active {
                background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                /* background: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%); */
                opacity: 1;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
        }
        max-width: 600px;
        width: 100%;
        min-height: 642px;
        margin: auto;
        background: var(--color_white);
        border-radius: 32px;
        margin-bottom: 182px;
        padding: 32px;
        box-sizing: border-box;
    }
}
@media screen and (max-width: 1080px) {
    .step_title {
        text-align: center;
    }
    .first_center {
        padding: 32px 20px !important;
        height: 738px !important;
    }
}
</style>
