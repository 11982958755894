<template>
    <div>
        <div class="maskBox" v-show="show" @click="$emit('change', false)"></div>
        <div
            class="start_menu"
            :style="{
                width: `${width}px`,
                left: show ? `0` : `${-width}px`,
            }"
        >
            <div class="logo_box">
                <div class="logo cursor" :style="logoStyle" @click="jump_page_path('/startup/index');close()"></div>
                <div v-if="isPhone" class="menu cursor" @click="openMenu">
                    <GI name="index_menu" :ws="isPhone ? 24 : 40"></GI>
                </div>
            </div>
            <div class="menu_list">
                <div class="menu_item" v-for="(item, index) in list" :key="index" @click="jumpOther(item.path)">
                    <img :src="item.icon" class="menu_icon" />
                    <div class="menu_title">{{ item.title }}</div>
                </div>
            </div>
            <div class="login_box">
                <BTN type="active" fontSize='16' @handle="selectLogin" bh="48">{{ langJson('Log in') }}</BTN>
                <BTN type="main" fontSize='16' @handle="selectRegister" bh="48">{{ langJson('Sign Up') }}</BTN>
            </div>
            <Model v-model="showSign" @mask="closeSign" :borderRadius="isPhone ? 40 : 24" :padding="isPhone ? '24px 32px' : '32px'" :showHeader="false">
                <Signup slot="main" @closeSign="closeSign"></Signup>
            </Model>
            <Model v-model="showLog" @mask="closeLogin" :borderRadius="isPhone ? 40 : 24" :padding="isPhone ? '24px 32px' : '32px'" :showHeader="false">
                <Login slot="main" @closeLogin="closeLogin"></Login>
            </Model>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [
                {
                    icon: '/source/svg/menu_1.svg',
                    title: this.langJson('What are NFTs'),
                    path: () => {
                        this.jump_page_path('/startup/article/whatNft')
                        // this.jump_page_path('/startup/index')
                    },
                },
                {
                    icon: '/source/svg/menu_2.svg',
                    title: this.langJson('Use cases of NFT'),
                    path: () => {
                        this.jump_page_path('/startup/article/unleash')
                    },
                },
                {
                    icon: '/source/svg/menu_3.svg',
                    title: this.langJson('About NFTWerks'),
                    path: () => {
                        this.jump_page_path('/startup/article/graphic')
                    },
                },
                {
                    icon: '/source/svg/menu_4.svg',
                    title: this.langJson('Term & Conditions'),
                    path: () => {
                        this.jump_article_detail({ articleId: 5 })
                    },
                },
                {
                    icon: '/source/svg/menu_5.svg',
                    title: this.langJson('FAQs'),
                    path: () => {
                        this.jump_page_path('/startup/article/faq')
                    },
                },
            ],
            showSign: false,
            showLog: false,
            width: 360,
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    watch: {},
    mounted() {
        window.addEventListener('resize', this.screenAdapter)
        this.screenAdapter()
    },
    props: {
        show: {
            type: [Boolean, PointerEvent],
            default: false,
        },
    },
    computed: {
        logoStyle() {
            try {
                let { webLogo,webDarknessLogo } = this.globalImage
                if(this.globalTheme != 'light') {
                    webLogo = webDarknessLogo
                }
                return { backgroundImage: `url(${webLogo})`, width: this.isPhone ? `44px` : `80px`, borderRadius: `50%`, height: this.isPhone ? `44px` : `80px` }
            } catch (error) {
                return {}
            }
        },
    },
    methods: {
        openMenu() {
            this.$emit('change', false)
        },
        jumpOther(path) {
            this.$emit('change', false)
            path()
        },
        close() {
            this.$emit('change', false)
        },
        selectRegister() {
            this.showSign = true
        },
        selectLogin() {
            this.showLog = true
        },
        screenAdapter() {
            if (document.body.clientWidth < 616) {
                this.width = document.body.clientWidth
            } else {
                this.width = 360
            }
        },
        closeLogin(val) {
            this.showLog = val
            this.close()
        },
        closeSign(val) {
            this.showSign = val
            this.close()
        },
    },
    destroyed() {
        window.removeEventListener('resize', this.screenAdapter)
    },
}
</script>
<style lang="scss" scoped>
.maskBox {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.5s;
    z-index: 20221028;
}

.start_menu {
    .menu_list {
        .menu_item {
            .menu_title {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }

            .menu_icon {
                width: 28px;
                height: 28px;
                margin-right: 12px;
            }

            display: flex;
            height: 44px;
            border-bottom: 1px solid var(--border_color);
            margin-bottom: 40px;
            cursor: pointer;
        }
        padding: 0px 32px;
        margin-top: 10px;
    }
    .logo_box {
        .logo {
            height: 64px;
            width: 64px;
            border-radius: 50%;
            /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 32px;
    }

    position: fixed;
    top: 0;
    bottom: 0;
    background: var(--color_white);
    border-radius: 0px 32px 32px 0px;

    box-sizing: border-box;
    transition: all 0.5s;
    z-index: 20221029;
    overflow: auto;
}

.login_box {
    display: none;
}

@media only screen and (max-width: 1081px) {
    .start_menu {
        .menu_list {
            .menu_item {
                .menu_title {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                }

                .menu_icon {
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                }

                display: flex;
                height: 52px;
                border-bottom: 1px solid var(--border_color);
                margin-bottom: 40px;
                cursor: pointer;
            }
            padding: 0px 20px;
            margin-top: 20px;
        }
        .logo_box {
            .logo {
                height: 44px;
                width: 44px;
                border-radius: 50%;
                /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 20px;
            background: var(--color_white);
            position: fixed;
            width: 360px;
            top: 0;
            box-sizing: border-box;
        }

        padding-top: 56px;

        position: fixed;
        top: 0;
        bottom: 0;
        background: var(--bg_color);
        border-radius: 0px;
        box-sizing: border-box;
        transition: all 0.5s;
        z-index: 20221029;
        overflow: auto;
    }

    .login_box {
        margin-top: 164px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        padding: 0px 20px;
    }
}
@media only screen and (max-width: 824px) {
    .start_menu {
        .menu_list {
            .menu_item {
                .menu_title {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                }

                .menu_icon {
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                }

                display: flex;
                height: 52px;
                border-bottom: 1px solid var(--border_color);
                margin-bottom: 40px;
                cursor: pointer;
            }
            padding: 0px 20px;
            margin-top: 20px;
        }
        .logo_box {
            .logo {
                height: 44px;
                width: 44px;
                border-radius: 50%;
                /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 20px;
            background: var(--color_white);
            position: fixed;
            width: 360px;
            box-sizing: border-box;
        }

        padding-top: 56px;

        position: fixed;
        top: 0;
        bottom: 0;

        border-radius: 0px;

        box-sizing: border-box;
        transition: all 0.5s;
        z-index: 20221029;
        overflow: auto;
    }
    .login_box {
        margin-top: 164px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        padding: 0px 20px;
    }
}
@media only screen and (max-width: 616px) {
    .menu {
        margin-right: -4px;
    }
    .start_menu {
        .menu_list {
            .menu_item {
                .menu_title {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                }

                .menu_icon {
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                }

                display: flex;
                height: 52px;
                border-bottom: 1px solid var(--border_color);
                margin-bottom: 28px;
                cursor: pointer;
            }
            padding: 0px 20px;
            margin-top: 20px;
        }
        .logo_box {
            .logo {
                height: 44px;
                width: 44px;
                border-radius: 50%;
                /* background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%); */
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 20px;
            position: fixed;
            top: 0;
            width: 100%;
            box-sizing: border-box;
        }

        padding-top: 56px;
        position: fixed;
        top: 0;
        bottom: 0;
        border-radius: 0px;
        box-sizing: border-box;
        transition: all 0.5s;
        z-index: 20221029;
        overflow: auto;
    }

    .login_box {
        margin-top: 164px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        padding: 0px 20px;
        margin-bottom: 44px;
    }
}
</style>
