<template>
    <div :class="commonStyle ? 'table_main_common' : 'table_main'">
        <div class="table_head">
            <div class="head_item" v-for="(item, index) in listKey" :key="index">
                {{ title[item].title }}
            </div>
            <div v-if="play.length" class="head_item play">
                {{ langJson('Action') }}
            </div>
        </div>

        <div class="table_body">
            <div :class="['table_row', index % 2 != 0 ? 'row_active' : '']" v-for="(item, index) in list" :key="index">
                <!-- 表头 -->
                <div class="table_column" v-for="(subItem, subIndex) in listKey" :key="subIndex">
                    <div class="text" v-if="title[subItem].type == 'text'">
                        <CheckBox v-if="subIndex == 0 && CheckBoxShow" style="margin-right: 5px;" @click.native="selectOneItem(item)" :active="selected(item)"></CheckBox>
                        {{ list[index][subItem] }}
                    </div>

                    <div
                        class="image"
                        v-else-if="title[subItem].type == 'image'"
                        :style="{
                            backgroundImage: `url(${list[index][subItem]})`,
                        }"
                    ></div>

                    <div v-else>
                        <slot :name="subItem" :data="item"></slot>
                    </div>
                </div>

                <!-- 按钮，根据play数组和 -->
                <div v-if="play.length" class="table_column play">
                    <BTN class="btn_item" v-for="(subItem, subIndex) in play" @handle="triggleEvent(subItem.event, list[index])" :key="subIndex" :type="subItem.type" size="mini" height="32px" :load="!!loadItem">{{ subItem.title }}</BTN>
                </div>
            </div>
            <nu-data v-if="!lenArray(list)"></nu-data>
        </div>

        <div class="table_foot">
            <slot name="foot"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        play: {
            type: Array,
            default: () => {
                return []
            },
        },
        title: {
            type: Object,
            default: () => {
                return {}
            },
        },
        loadItem: {
            type: [String, Number],
            default: '',
        },
        commonStyle: {
            type: Boolean,
            default: false,
        },
        CheckBoxShow:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            seletelist: [],
        }
    },
    mounted() {
        this.seletelist = []
        Object.keys(this.$slots).forEach((item) => {
            // console.log(item)
        })
    },
    watch: {
        seletelist(n) {
            this.$emit('change', n)
        },
    },
    computed: {
        haveData() {
            return this.list && this.list.length
        },
        // title数组中的key值组成的表头数组
        listKey() {
            return Object.keys(this.title)
        },
        selected() {
            return (item) => {
                try {
                    let result = this.seletelist.some((select) => {
                        return select == item.userId
                    })
                    return result
                } catch (err) {
                    console.log(err)
                }
                return false
            }
        },
    },
    methods: {
        triggleEvent(event, item) {
            this.$emit(event, item)
        },
        selectOneItem(item) {
            let exit = this.seletelist.some((select) => {
                return select == item.userId
            })
            if (exit) {
                this.seletelist = this.seletelist.filter((select) => {
                    return select != item.userId
                })
            } else {
                this.seletelist.push(item.userId)
            }
        },
        selectAll() {
            this.isAllChecked = !this.isAllChecked
            if (this.isAllChecked) {
                this.seletelist = this.list.map((item) => {
                    return item.userId
                })
            } else {
                this.seletelist = []
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.table_main {
	
    .table_body {
        .table_row {
            display: flex;
            padding: 0 20px;
            transition: all 1s;
            .table_column {
                .link {
                    color: var(--success_color);
                    cursor: pointer;
                }
                .address {
                    color: var(--success_color);
                    cursor: pointer;
                }
                .image {
                    width: 70px;
                    height: 70px;
                    border-radius: 6px;
                    margin: 10px 0;
                    overflow: hidden;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                }
                .text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-family: 'Lato';
                }
                flex: 1;
                min-height: 50px;
                display: flex;
                align-items: center;
                min-width: 120px;
                transition: all 1s;
                padding: 0 10px;
                box-sizing: border-box;
            }
            .play {
                min-width: 200px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .btn_item {
                    margin-left: 10px;
                }
            }
        }
        .table_row:nth-child(odd) {
            background: var(--color_white) !important;
            transition: all 1s;
        }

        flex: 1;
        overflow: auto;
    }
    .table_head {
        display: flex;
        

        .head_item {
            flex: 1;
            min-width: 120px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            font-size: 18px;
            line-height: 24px;
            color: var(--font_deep_color);
            display: flex;
            align-items: center;
            padding: 16px 10px;
            box-sizing: border-box;
        }
        .play {
            min-width: 200px;
            display: flex;

            justify-content: flex-end;
        }
        background: var(--color_white);
        // padding: 0 20px;
        overflow: auto;
    }
    background-color: rgba($color: #ccc, $alpha: 0.2);
    border-radius: 12px;
    box-sizing: border-box;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
    overflow: hidden;

    height: 100%;
    display: flex;
    flex-direction: column;
}
.table_main_common {
    background-color: var(--color_white);
    border-radius: 12px;
    box-sizing: border-box;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
    overflow: hidden;

    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
	.table_foot{
		padding-bottom:32px;
	}
    .table_body {
        display: flex;
        flex-direction: column;
        gap: 16px;
		margin:0 32px;
        .table_row {
            display: flex;
            .table_column {
                .text {
                    display: flex;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-family: 'Lato';
                }
                flex: 1;
                min-height: 50px;
                display: flex;
                align-items: center;
                min-width: 120px;
                transition: all 1s;
                box-sizing: border-box;
            }
            .play {
                min-width: 200px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .btn_item {
                    margin-left: 10px;
                }
            }
            padding: 8px 32px;
            transition: all 1s;
        }
        .row_active {
            background: var(--color_white) !important;
            transition: all 1s;
        }
        .table_row:hover {
            // .table_column{
            //     font-family: 'Lato';
            //     font-style: normal;
            //     font-weight: 400;
            //     font-size: 18px;
            //     line-height: 24px;
            //     color: var(--font_modif_color);
            // }
        }
        flex: 1;
        overflow: auto;
    }
    .table_head {
        display: flex;
        align-items: center;
        background: var(--bg_color);
        padding: 4px 32px;
        overflow: auto;
        border-radius: 32px;
		margin: 32px 32px 0 32px;
        .head_item {
            flex: 1;
            min-width: 120px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            display: flex;
            box-sizing: border-box;
        }
        .play {
            min-width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
</style>
