<template>
    <div class="setting">
      <Go :prev="langJson('Back to overview')"  @prev="back" >
        <div class="right_go">
          <BTN icon_left="brand_trash" type="warn" bh="52" bw="198">{{ langJson('Delete campaign') }}</BTN>
          <BTN icon_left="eye" type="detail" bh="52" bw="131">{{ langJson('Preview') }}</BTN>
          <BTN type="active" bh="52" bw="138">{{ langJson('Save changes') }}</BTN>
        </div>
      </Go>
      <div class="setting_center">
        <div class="tab_box">
          <div class="tabs">
            <div :class="[tab === item.value && 'border_tab']" v-for="(item, index) in tablist" :key="index" @click="switchTab(item.value)" class="tab_inner">
              <div class="tab_item">
                <GI :name="tab === item.value ? item.iconActive : item.icon" ws="24" hs="24"></GI>
                <div class="tab_title">{{ item.label }}</div>
              </div>
            </div>
            <div class="tab_bottom"></div>
          </div>
        </div>
        <div class="main">
  
          <StepRun ref="tab">
            <Basics slot="tab1"></Basics>
            <Rewards slot="tab2"></Rewards>
            <Link slot="tab3"></Link>
            <Sticker slot="tab4"></Sticker>
            <Promotion slot="tab5"></Promotion>
          </StepRun>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        tab: 1,
        tablist: [
          {
            label: this.langJson('Basics'),
            value: 1,
            icon: 'edit_basics_active',
            iconActive: 'edit_basics_active',
          },
          {
            label: this.langJson('Rewards'),
            value: 2,
            icon: 'edit_rewards_active',
            iconActive: 'edit_rewards_active',
          },
          {
            label: this.langJson('Link'),
            value: 3,
            icon: 'edit_link_active',
            iconActive: 'edit_link_active',
          },
          {
            label: this.langJson('Sticker library'),
            value: 4,
            icon: 'edit_sticker_active',
            iconActive: 'edit_sticker_active',
          },
          {
            label: this.langJson('Promotion'),
            value: 5,
            icon: 'edit_promotion_active',
            iconActive: 'edit_promotion_active',
          },
        ],
      }
    },
    mounted() {
      if (this.$route.params.tab) {
        this.tab = parseInt(this.$route.params.tab)
        this.switchTab(this.tab)
      }
    },
    methods: {
      switchTab(val) {
        this.tab = val
        this.tablist.find((item, index) => {
          if (item.value == val) {
            this.$refs.tab.jump(index)
            return true
          } else {
            return false
          }
        })
          // this.jump_setting(val)
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  .setting {
  
    .setting_center {
      .tab_box {
        .tabs {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .tab_bottom {
            height: 1px;
          }
          .tab_item {
            .tab_title {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: var(--font_modif_color);
              margin-left: 8px;
            }
            display: flex;
            height: 52px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
          }
          .tab_inner{
              padding-left: 24px;   
              border: 2px solid transparent;
              border-radius: 32px;
          }
          .border_tab {
            border: 2px solid transparent;
            border-radius: 32px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(to right, var(--color_white), var(--color_white)), linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
            padding-left: 24px;
            .tab_title {
              background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              // color: var(--main_color);
              font-weight: 700;
            }
          }
          width: 100%;
          background: var(--color_white);
          padding: 24px;
          padding-bottom: 0px;
          box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
          border-radius: 32px;
          box-sizing: border-box;
        }
        min-width: 296px;
      }
  
      .main {
        background: var(--bg_color);
        border-radius: 32px;
        flex: 1;
        margin-left: 32px;
  
        min-height: 500px;
        box-sizing: border-box;
      }
      display: flex;
      align-content: flex-start;
      width: 100%;
      max-width: var(--max_width);
      margin: auto;
      margin-top: 32px;
    }
  }
  .right_go {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }
  </style>
  