import Clipboard from 'clipboard'
import { BigNumber } from 'bignumber.js'
import createKeccakHash from 'keccak'
export default {
    jumpTo(anchor_id) {
        var url = location.href;
        location.href = "#" + anchor_id;
        history.replaceState(null, null, url);
    },
    reAddress(address) {
        try {
            address = address.toLowerCase().replace('0x', '')
            const hash = createKeccakHash('keccak256').update(address).digest('hex')
            let ret = '0x'

            for (let i = 0; i < address.length; i++) {
                if (parseInt(hash[i], 16) >= 8) {
                    ret += address[i].toUpperCase()
                } else {
                    ret += address[i]
                }
            }

            return ret
        } catch (err) {
            return ""
        }
    },
    trimSpace(str) {
        try {
            return str.trim()
        } catch (err) {
            return str
        }
    },
    /* 时间格式化 */
    timerIo(datetimeTo) {
        if (datetimeTo) {
            if (!(typeof datetimeTo == 'number')) {
                datetimeTo = new Date(datetimeTo.replace(/-/g, '/'))
            } else {
                datetimeTo = new Date(datetimeTo)
            }
            let date = new Date(datetimeTo)

            let year = date.getFullYear() // 获取年份
            let month = date.getMonth() + 1 // 获取月份，记得+1
            let day = date.getDate() // 获取日期

            month < 10 && (month = '0' + month)
            day < 10 && (day = '0' + day)

            return `${day}/${month}/${year}` // 返回格式化后的日期字符串
        } else {
            return ''
        }
    },
    pushMsg(type, message) {
        this.$store.commit(type, message)
    },
    // 检查数据是否合规
    checkPass(source, rules = [], isTip = true) {
        return !rules.some((item) => {
            let pass = true
            if (item in this.rules) {
                pass = this.rules[item].match.test(source)
            } else {
                console.log('Not Support')
            }
            if (pass) {
                return false
            } else {
                isTip && this.pushMsg('warning', this.rules[item].tip)
                return true
            }
        })
    },
    /* 设备宽度 */
    driveWidth() {
        let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        return browserWidth
    },
    /* 获取元素高度 */
    SubTH(parentNode) {
        let nodes = parentNode.childNodes
        if (nodes.length) {
            let { top } = nodes[0].getBoundingClientRect()
            let { bottom } = nodes[nodes.length - 1].getBoundingClientRect()
            return bottom - top
        } else {
            return 0
        }
    },
    /* 时间格式化 */
    remainTime(datetimeTo) {
        let result
        if (datetimeTo) {
            if (!(typeof datetimeTo == 'number')) {
                datetimeTo = new Date(datetimeTo.replace(/-/g, '/'))
            } else {
                datetimeTo = new Date(datetimeTo)
            }
            let time1 = new Date(datetimeTo).getTime()
            let time2 = new Date().getTime()
            let mss = time1 - time2
            // 倒计时结束
            if (mss < 0) {
                result = {
                    days: '00',
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                }
                return result
            }
            // 将时间差（毫秒）格式为：天时分秒
            let days = parseInt(mss / (1000 * 60 * 60 * 24))
            let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = parseInt((mss % (1000 * 60)) / 1000)
            result = {
                days: days < 10 ? '0' + days : '' + days,
                hours: hours < 10 ? '0' + hours : '' + hours,
                minutes: minutes < 10 ? '0' + minutes : '' + minutes,
                seconds: seconds < 10 ? '0' + seconds : '' + seconds,
            }
        } else {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            }
        }

        return result
    },
    langJson(str, key) {
        if (key in this.langSource) {
            return this.langSource[key]
        } else {
            return str
        }
    },
    setCookie(cname, cvalue, exdays) {
        var d = new Date()
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
        var expires = 'expires=' + d.toUTCString()
        document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/'
    },
    /* 科学计数法转字符串 */
    goodNumber(n) {
        n = n + ''
        let r = n.split('e')[0]
        let l = n.split('e')[1] ? n.split('e')[1] : ''
        if (l) {
            let zl
            let zs = ''
            let rf = 0
            if (/^\+\d+$/.test(l)) {
                zl = l.split('+')[1]
                for (let i = 0; i < zl; i++) {
                    zs += '0'
                }
                if (r.split('.')[1]) {
                    rf = r.split('.')[1].length
                    r = r.split('.')[0] + r.split('.')[1]
                }
                zs = zs.slice(0, zs.length - rf)
                return r + zs
            } else if (/^\-\d+$/.test(l)) {
                zl = l.split('-')[1]
                for (let i = 0; i < zl; i++) {
                    zs += '0'
                }
                rf = r.split('.')[0].length
                if (r.split('.')[1]) {
                    r = r.split('.')[0] + r.split('.')[1]
                }
                zs = zs.slice(0, zs.length - rf)
                return '0.' + zs + r
            }
        } else {
            return r
        }
    },
    /* 精确位 */
    longHandle(n, l = 18) {
        if (n) {
            n = '' + n
        } else {
            return 0
        }
        l = parseInt(l)

        if (n.indexOf('.') == -1) {
            n = n + new Array(l).fill(0).join('')
        } else {
            let h = n.split('.')[0]
            let e = n.split('.')[1]
            if (e.length >= l) {
                e = e.substr(0, l)
            } else {
                e = e + new Array(l - e.length).fill(0).join('')
            }
            if (parseInt(h)) {
                n = h + e
            } else {
                n = e
            }
        }
        return n
    },
    /* 精确位 */
    smallHandle(n, l = 18) {
        try {
            if (n) {
                n = '' + n
            } else {
                return 0
            }
            l = parseInt(l)

            let e = n.length - l
            if (n.length >= l + 1) {
                let f = n.slice(0, e)
                let s = n.slice(e)
                n = `${f}.${s}`
            } else {
                let f
                f = new Array(Math.abs(e)).fill(0).join('')
                n = `0.${f}${n}`
            }
            return parseFloat(n)
        } catch (err) {
            console.log(err)
        }
    },
    plus(x, y) {
        let base = BigNumber(x)
        return base.plus(y).toString()
    },
    reduce(x, y) {
        let base = BigNumber(x)
        return base.minus(y).toString()
    },
    multiplication(x, y) {
        let base = BigNumber(x)
        return base.multipliedBy(y).toString()
    },
    division(x, y) {
        let base = BigNumber(x)
        let quote = BigNumber(y)
        return base.dividedBy(quote).toString()
    },
    // timeFormat(timeStr) {
    //   timeStr = timeStr.replace(/-/g, "/");

    //   let timeNum = new Date(timeStr).getTime();
    //   return parseInt(timeNum / 1000);
    // },
    copy(text) {
        let clipboard = new HClipboard()
        let result = clipboard.copy(text)
        if (result) {
            this.pushMsg('success', this.langJson('Copy success'))
        } else {
            this.pushMsg('error', this.langJson('Copy error'))
        }
    },
    openAddress(address, chain = '') {
        chain = chain ? chain : this.walletDetail.chain
        console.log('chain', chain)
        let baseUrl = this.findChain('id', chain).browserAddress
        console.log('baseUrl', baseUrl)
        console.log('this.findChain', this.findChain)
        window.open(baseUrl + address, '_blank')
    },
    openUrl(url) {
        window.open(url, '_blank')
    },
    openTx(hash, chain = '') {
        chain = chain ? chain : this.walletDetail.chain
        let baseUrl = this.findChain(chain).browserTx
        window.open(baseUrl + hash, '_blank')
    },
    timeStr(timestamp) {
        if (typeof timestamp == 'string') {
            if (/^\d+$/.test(timestamp)) {
                timestamp = parseInt(timestamp)
            }
        }

        let dateObj = new Date(timestamp)
        let y = dateObj.getFullYear()
        let m = dateObj.getMonth() + 1
        let d = dateObj.getDate()
        let H = dateObj.getHours()
        let mm = dateObj.getMinutes()
        let ss = dateObj.getSeconds()
        m = m < 10 ? '0' + m : m
        d = d < 10 ? '0' + d : d
        H = H < 10 ? '0' + H : H

        mm = mm < 10 ? '0' + mm : mm
        ss = ss < 10 ? '0' + ss : ss
        return y + '/' + m + '/' + d + ' ' + H + ':' + mm + ':' + ss
    },
    timeJson(timestamp) {
        if (typeof timestamp == 'string') {
            if (/^\d+$/.test(timestamp)) {
                timestamp = parseInt(timestamp)
            }
        }

        let dateObj = new Date(timestamp)
        let y = dateObj.getFullYear()
        let m = dateObj.getMonth() + 1
        let d = dateObj.getDate()
        let H = dateObj.getHours()
        let mm = dateObj.getMinutes()
        let ss = dateObj.getSeconds()
        m = m < 10 ? '0' + m : m
        d = d < 10 ? '0' + d : d
        H = H < 10 ? '0' + H : H

        mm = mm < 10 ? '0' + mm : mm
        ss = ss < 10 ? '0' + ss : ss
        return {
            year: y,
            month: m,
            day: d,
            hour: H,
            minute: mm,
            second: ss,
        }
    },


    browser() {
        var u = navigator.userAgent;
        return {
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1, //android终端
            iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf("MicroMessenger") > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq" //是否QQ
        };
    }
}
