<template>
    <div class="step_item">
        <div class="item_info">
            <Info class="info_detail" icon="global_next" :title="langJson('Contract Address')" size="big">
                0x495f9...045cb7b5e
            </Info>
            <Info class="info_detail" :title="langJson('Token Standard')" size="big">
                ERC721
            </Info>
            <Info class="info_detail" :title="langJson('Blockchain')" size="big">
                Ethereum
            </Info>
            <Info class="info_detail" :title="langJson('Circulation')" size="big">
                10000
            </Info>
            
            <Info class="info_detail" :title="langJson('Unpacking time')" size="big">
                2022-09-23 19:00:00
            </Info>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.step_item{
    .item_info{
        height:100%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-content:flex-start;
        .info_detail{
            margin-bottom:20px;
            margin-right:20px;
            width: 280px;
        }
    }
    .item_tag{
        margin-right:20px;
        height:70px;
        float: left;
        margin-bottom:20px;
    }
    background:var(--color_white);
    height:400px;
    margin-top:20px;
    border-radius: 12px;
    padding:20px;
    box-sizing: border-box;
    overflow: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
}
</style>