<template>
    <div>
        <div class="header">
            <h2>{{ langJson('Promotion') }}</h2>
            <span>{{ langJson('Get ready to promote your campaign.') }}</span>
        </div>
        <div>
            <div class="form_upper">
                <div class="form_box_upper">
                    <h2>{{ langJson('Campaign URL') }}</h2>
                    <span>{{ langJson("We'll use your current campaign title to generate a URL. Even if you change the title later, the URL will stay the same. Your campaign page won't go live until you launch.") }}</span>
                    <div class="form_input">
                        <FormItem height="52px" class="information_item" type="text" v-model="urlShare" :placeholder="langJson('nftworks.com/mycampaign')" icon="edit_unlock"></FormItem>
                    </div>
                    <BTN icon_left="edit_copy" @handle="copy(urlShare)" type="detail" bh="48" bw="147">{{ langJson('Copy link') }}</BTN>
                </div>
            </div>

            <div class="form">
                <div class="form_box">
                    <div class="upper_btns">
                        <div class="title">
                            <h2>{{ langJson('Invite participants') }}</h2>
                            <span>{{ langJson('Key in the details of your guests and invite them to join your campaign.') }}</span>
                        </div>

                        <div class="select_btns">
                            <BTN type="detail" @handle="inviteAll" bh="48" bw="107">{{ langJson('Invite all ') }}</BTN>
                            <editShare v-model="showShare" :list="invitelist" :url="urlShare" @handleSuccess="closeMessage"></editShare>
                            <!-- <BTN icon_left="edit_upload" v-if="from == 'edit'" @handle="selectAll" type="detail" bh="48" bw="155">{{ langJson('Select all') }} </BTN> -->
                            <BTN icon_left="edit_upload" @handle="upload" type="detail" bh="48" bw="155">{{ langJson('Upload file ') }}</BTN>
                        </div>
                    </div>
                    <div class="down_table">
                        <editTable v-if="!isPhone" @change="inviteChange" @Delete="delClient" @Invite="aloneShow" :commonStyle="true" :list="brandClient" :title="title" :play="play">
                            <div class="more" slot="foot">
                                <Page v-model="pageNum" @change="clientGet" :all="total" :size="pageSize" :isBtn="true"></Page>
                            </div>
                        </editTable>
                        <editTableMobile v-if="isPhone" @change="inviteChange" @Delete="delClient" @Invite="aloneShow" :commonStyle="true" :list="brandClient" :title="title" :play="play" :fullName="'name'">
                            <div class="more" slot="foot">
                                <Page v-model="pageNum" @change="clientGet" :all="total" :size="pageSize" :isBtn="true"></Page>
                            </div>
                        </editTableMobile>
                    </div>
                </div>
                <editShare v-model="aloneShare" :list="[selectId]" :url="urlShare"></editShare>
                <div class="add_new">
                    <BTN bw="142" bh="52" @handle="pushModel = true" icon_left="add-rewards" type="detail">{{ langJson('Add new') }}</BTN>
                </div>
            </div>

            <div class="btns">
                <div class="next_step">
                    <span>{{ langJson('Your changes have been saved') }}</span>
                    <BTN @handle="goTreaty" type="active" bh="52" bw="152" class="BtnLeft">{{ langJson('Last: Smart Contract Deployer') }}</BTN>
                </div>
            </div>
        </div>
        <PushClient v-model="pushModel" @change="clientGet"></PushClient>
        <input @change="uploadFile" id="dataFile" class="file_real" type="file" name="dataFile" />
    </div>
</template>

<script>
export default {
    props: {
        from: {
            type: String,
            default: '',
        },
    },
    computed: {
        title() {
            return {
                no: {
                    title: this.langJson('No.'),
                    type: 'text',
                },
                firstName: {
                    title: this.langJson('First name'),
                    type: 'text',
                },
                lastName: {
                    title: this.langJson('Last name'),
                    type: 'text',
                },
                contactNo: {
                    title: this.langJson('Contact No.'),
                    type: 'text',
                },
                email: {
                    title: this.langJson('Email'),
                    type: 'text',
                },
            }
        },
    },
    data() {
        return {
            pageNum: 1,
            pageSize: 100,
            total: 0,
            pushModel: false,
            list: [],

            bannerConfig: {
                type: 'big',
            },
            bannerConfig2: {
                type: 'small',
            },
            play: [{ type: 'detail', event: 'Delete', title: 'Delete contact' }],
            showShare: false,
            aloneShare: false,
            urlShare: '',
            invitelist: [],
            selectId: '',
            
        }
    },

    created() {
        this.initShare()
        this.clientGet()
    },
    inject: ['switchTab'],
    methods: {
        async delClient(item) {
            try {
                let { dispatch } = this.$store
                let result = await dispatch('clientDelete', [item.id])
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Delete success'))
                    this.invitelist = []
                    await this.clientGet()
                }
                
            } catch (err) {
                console.log(err)
                this.pushMsg('error', this.langJson('delete fail'))
            }
        },
        upload() {
            document.getElementById('dataFile').click()
        },
        async uploadFile() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let fileSource = document.getElementById('dataFile').files[0]
                let result = await dispatch('importData', fileSource)
                let success = result && result.code == 200
                if (success) {
                    await this.clientGet()
                }
                document.getElementById('dataFile').value = ''
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
        async clientGet() {
            try {
                let { dispatch } = this.$store
                let result = await dispatch('clientData', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                })
                this.total = result.total
                this.list = result.rows
            } catch (err) {
                console.log(err)
            }
        },
        initShare() {
            let protocol = window.location.protocol
            let host = window.location.host
            this.urlShare = `${protocol}//${host}/brand/campaign/preview?status=live&id=${this.$route.query.id}`
        },
        inviteChange(list) {
            this.invitelist = list
        },
        selectAll() {},
        inviteAll() {
            this.showShare = true
        },
        changeShow(val) {
            this.showShare = val
        },
        aloneShow(item) {
            this.selectId = item.id
            this.aloneShare = true
        },
        goTreaty(){
            this.switchTab(5)
        },
        closeMessage(){
            this.showShare = false
        }
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.file_real {
    display: none;
}
.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: var(--font_deep_color);
    }
    span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_modif_color);
    }
}
.form_upper {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    .form_box_upper {
        width: 100%;
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        h2 {
            font-family: 'Inter';
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        span {
            color: var(--font_modif_color);
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    margin-top: 32px;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    .form_box {
        width: 100%;
        gap: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        .upper_btns {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 16px;
            .title {
                span {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--font_modif_color);
                }
            }
            .select_btns {
                display: flex;
                gap: 16px;
            }
        }
        .information_item {
            width: 100%;
        }
        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            font-family: 'Inter';
            color: var(--font_deep_color);
        }
        .form_input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
        }
    }
    .add_new {
        margin-top: 24px;
    }
}
.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 35px;
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
}
.down_table {
    border: 1px solid var(--border_color);
    border-radius: 32px;
    padding: 32px;
    .more {
        margin-top: 32px;
    }
}
@media screen and (max-width: 1080px) {
    .form_upper {
        padding: 20px;
    }
    .header {
        h2 {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        span {
            font-size: 14px;
        }
    }
    .form {
        background: transparent;
        padding: 0px;
        .upper_btns {
            background: var(--color_white);
            box-sizing: border-box;
            padding: 20px 29px;
            border-radius: 32px;
            flex-direction: column;
        }
    }
    .down_table {
        border: 0px;
        padding: 0px;
        .more {
            margin-top: 40px !important;
        }
    }
    .btns {
        justify-content: flex-start;
        gap: 24px;
        span {
        }
    }
    .next_step {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}
</style>
