<template>
    <div class="nftSourceMp3">
        <div class="imgSource">
            <!-- 单纯传声音有无背景 -->
            <!-- isUploadFile -->
            <div class="audioBg" v-if="isUploadFile" :style="`background-color:${getRandomColor()}`"></div>
            <!-- 声音 -->
            <!-- @loadeddata="loadingEnd = true" -->
            <audio ref="paly" v-show="false" :autoplay="Autoplay" volume controls class="cover" @ended="isPlay = false">
                <source :src="Data" type="audio/ogg" />
                <source :src="Data" type="audio/mpeg" />
                <embed height="50" width="100" :src="Data" />
            </audio>
            <!-- 预览 -->
            <el-image class="cover" :src="Preview" @load="load()" @error="error()" :fit="fit">
                <div slot="error" class="image-slot none">
                    <GI class="icon_unfind" name="global_404"></GI>
                </div>
            </el-image>

            <!-- loading -->
            <div class="img_loading" v-show="!loadingEnd">
                <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
                <div class="loading_text">{{ langJson('loading','saas_nft.txt.v1.265') }}</div>
            </div>

            <!-- 播放 -->
            <div class="icon_play" v-show="!isUploadFile" v-if="!isPlay && loadingEnd">
                <GI @click="play()" class="auction" name="play" ws="24" hs="24"></GI>
            </div>
            <div class="mask" v-show="!isUploadFile" v-if="isPlay && loadingEnd" :style="{ opacity: 1 }">
                <GI @click="pause()" class="auction" name="pause" ws="24" hs="24"></GI>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        Data: {
            type: String,
            default: String,
        },
        Autoplay: {
            type: Boolean,
            default: false,
        },
        Preview: {
            type: String,
            default: String,
        },
        fit: {
            type: String,
            default: 'scale-down',
        },
        isDetail: {
            type: Boolean,
            default: Boolean,
        },
        isUploadFile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingEnd: false,
            isPlay: false,
        }
    },
    created() { 
    },
    mounted() {},
    computed: {},
    methods: {
        load() { 
            this.loadingEnd = true
        },
        error() { 
            this.loadingEnd = true
        },
        pause() {
            this.$refs.paly.pause()
            this.isPlay = !this.isPlay
        },
        play() {
            this.isPlay = !this.isPlay
            this.$nextTick(() => {
                this.$refs.paly.play()
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.audioBg {
    height: 100%;
    width: 100%;
}

.nftSourceMp3 {
    height: 100%;
    width: 100%;
}
.auction {
    cursor: pointer;
}
.none {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgSource {
    width: 100%;
    height: 100%;
    position: relative;
    .mask {
        z-index: -2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: none;
    }
    &:hover .mask {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 2;
        opacity: 0;
        transition: all 0;
    }

    .icon_play {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .img_loading {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        .loading_text {
            margin-top: 6px;
            color: #fff;
            font-size: 16px;
            font-family: 'Cabinet Grotesk';
        }
    }
    .cover {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>


