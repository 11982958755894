<template>
    <div class="main">
        <div class="left_img"></div>

        <div class="right_box">
            <div class="theme_box">
                <Theme></Theme>
            </div>
            <div class="login_form">
                <div class="title">
                    <Logo></Logo>
                    <div class="title_info">
                        <h2>{{ langJson('Set your New Password') }}</h2>
                        <p>
                            {{ langJson('Your password must be at least 8 characters including an uppercase letter, a special character and a number') }}
                        </p>
                    </div>
                </div>
                <div class="form_input">
                    <FormItem v-model="form.password" class="information_item" type="password" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']" :title="langJson('New password ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>

                    <FormItem v-model="form.passwordAgin" class="information_item" type="password" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']" :title="langJson('Confirm new password ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>
                </div>
                <div class="btn">
                    <BTN class="btn_item" :disable="!pass" :load="load_btn" @handle="resetPwd" type="active" bh="52">{{ langJson('Confirm') }}</BTN>
                </div>
            </div>
            <div class="learn_more">
                <div class="learm_more_box">
                    <span class="learn_text_1">{{ langJson('Powered by NFTWerks ') }}</span>
                    <div></div>
                    <span class="learn_text_2" style="cursor: pointer" @click="jump_page_path('/startup/index')">{{ langJson('Learn more ') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                password: '',
                passwordAgin: '',
            },
        }
    },
    computed: {
        pass() {
            try {
                let { password, passwordAgin } = this.form
                let result = this.checkPass(password, ['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop'], false) && password == passwordAgin

                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    methods: {
        async resetPwd() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let { email, verifyCode } = this.tempVal
                let result = await dispatch('resetPwd', {
                    email,
                    verifyCode,
                    newPassword: this.form.password,
                })

                if (result && result.code == 200) {
                    this.jump_participant_login()
                }
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    width: 100%;
    display: flex;
    .left_img {
        height: 100vh;
        width: 50%;
        background-image: url('../../assets/image/login/bgc.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .right_box {
        flex: 1;
        padding-top: 40px;

        box-sizing: border-box;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: auto;
        .theme_box {
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            width: 100%;
            padding-right: 80px;
            /deep/ {
                .switch_theme {
                    background-color: var(--bg7) !important;
                }
            }
        }
        .login_form {
            box-sizing: border-box;
            width: 100%;
            max-width: 470px;
            /* height: 544px; */
            padding: 32px;
            display: flex;
            margin: 44px auto;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            background: var(--color_white);
            border-radius: 32px;
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                p {
                    color:red!important;
                }

                .title_info {
                    h2 {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 52px;
                        text-align: center;
                        color: var(--font_deep_color);
                    }
                    p {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        text-align: center;
                        line-height: 20px;
                        color: var(--font_modif_color);
                        font-size: 14px;
                        margin-top: 4px;
                    }
                }
            }

            .form_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .information_item {
                    width: 100%;
                }
            }

            .btn {
                width: 100%;
            }
        }

        .learn_more {
            width: 100%;
            height: 20px;
            font-size: 14px;
            padding-right: 80px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            .learm_more_box {
                width: 233px;
                height: 100%;
                display: flex;
                gap: 10px;
                align-items: center;
                .learn_text_1 {
                    color: var(--font_modif_color);
                }
                div {
                    height: 8px;
                    background: var(--color_white);
                    border: 1px solid var(--border_color);
                }
                .learn_text_2 {
                    line-height: 20px;
                    text-align: right;
                    color: var(--primary_color);
                }
                span {
                    white-space: nowrap;
                }
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .main {
        .right_box {
            flex: 1;
            padding-top: 20px;
            justify-content: flex-start;
            .theme_box {
                padding-right: 20px;
            }
            .login_form {
                /* max-width: 406px !important; */
                margin: 8px auto !important;
                background: var(--bg_color) !important;
                padding: 20px !important;

                .title_info {
                    width: 277px;
                    h2 {
                        font-size: 24px !important;
                        line-height: 40px !important;
                    }
                    p {
                        line-height: 20px;
                        font-size: 14px;
                    }
                }
            }
        }
        .left_img {
            display: none;
        }
    }
}
@media screen and (max-width: 400px) {
    .right_box {
    }
    .login_form {
        max-width: 375px !important;
        margin: 8px auto !important;
        background: var(--bg_color) !important;
        /* padding: 0px !important; */
    }
    .learn_more {
        padding-right: 0px !important;
        justify-content: center !important;
    }
}
</style>
