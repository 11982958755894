var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.noClickStop)?_c('img',{class:['icon', _vm.rotate ? 'rotate' : ''],style:({
        width: _vm.ws ? `${_vm.ws}px` : '100%',
        height: _vm.hs ? `${_vm.hs}px` : 'auto',
    }),attrs:{"src":_vm.src ? _vm.src : `/source/${_vm.type}/${_vm.name + (!_vm.isActive && _vm.type == 'svg' ? '_dark' : '')}.${_vm.type}`},on:{"click":function($event){$event.stopPropagation();return _vm.clickHandle.apply(null, arguments)}}}):_c('img',{class:['icon', _vm.rotate ? 'rotate' : ''],style:({
        width: _vm.ws ? `${_vm.ws}px` : '100%',
        height: _vm.hs ? `${_vm.hs}px` : 'auto',
    }),attrs:{"src":_vm.src ? _vm.src : `/source/${_vm.type}/${_vm.name + (!_vm.isActive && _vm.type == 'svg' ? '_dark' : '')}.${_vm.type}`},on:{"click":_vm.clickHandle}})
}
var staticRenderFns = []

export { render, staticRenderFns }