<template>
    <Model :title="langJson('Connect wallet')" :subtitle="langJson('For storing your final NFTs')" :padding="'24px'" v-model="linkShow" @change="close" :borderRadius="globalTheme == 'night' ? '20' : '32'" :gap="globalTheme == 'night' ? '10' : ''">
        <div class="model_main" slot="main">
            <div class="link_main">
                <GI name="metamask" type="svg" ws="153" hs="48"></GI>
            </div>
            <div class="model_btns">
                <div class="model_btn">
                    <BTN class="btn_item" @handle="close">{{ langJson('Cancel') }}</BTN>
                </div>
                <div class="model_btn">
                    <BTN class="btn_item" :load="load_btn" @handle="connectWallet" type="active">{{ langJson('Connect') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>

<script>
export default {
    computed: {},
    methods: {
        close() {
            this.$store.commit('linkShow', false)
        },
        async connectWallet() {
            this.load_btn = true
            try {

                let result = await this.connect()
                if (result) {
                    this.close()
                }
            } catch (err) {
                console.log(err)
            }

            this.load_btn = false
        },
    },
}
</script>

<style lang="scss" scoped>
.model_main {
    .link_main {
        margin: 32px auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nft_preview {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 16px;
        background-color: var(--bg_color);
        margin: 20px 0;
    }
    .tip {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--font_deep_color);
        margin-top: 32px;
        text-align: center;
    }
    .information_item {
        margin-top: 32px;
    }
    .model_btns {
        .model_btn {
            flex: 1;
        }
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    box-sizing: border-box;
    width: 448px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 77vw;
    }
}
</style>
