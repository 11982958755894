<template>
    <div class="detail">
        <!-- <StartHead v-if="showHead"></StartHead> -->
        <div class="detail_center">
            <div class="detail_title">
                <!-- {{ langJson('Terms & Condition') }} -->
                {{ globalHelp.getArticleDetail.title }}
            </div>
            <div class="detail_main v-html" v-html="globalHelp.getArticleDetail.content">
               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showHead: true,
        }
    },
    watch: {
        $route() {
            this.init()
        },
    },
    created() {
        this.init()
    },
    mounted() {
        if (eval(this.$route.query.showHead) == false) {
            this.showHead = eval(this.$route.query.showHead)
        }
    },
    methods: {
        async init() {
            try {
                let { dispatch } = this.$store
                let { articleId } = this.$route.query
                await dispatch('getArticleDetail', articleId)
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.detail {
    .detail_center {
        .detail_main {
            min-height: calc(100vh - 400px);
            font-family: 'Lato';
            line-height: 25px;
            color: var(--font_deep_color);
            .main_content {
                .main_row {
                    margin-bottom: 30px;
                }
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_article);
                margin-top: 16px;
            }
            .main_title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 40px;
                color: var(--primary_color);
            }
            height: auto;
            margin-top: 36px;
            padding-bottom: 40px;
        }
        .detail_name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--primary_color);
            margin-top: 16px;
        }
        .detail_title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 64px;
            color: var(--primary_color);
        }

        max-width: var(--max_width);
        width: 100%;
        margin: auto;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (max-width: 1080px) {
    .detail_center {
        align-items: flex-start !important;
        margin-top: 20px !important;
        .detail_title {
            font-family: 'Cabinet Grotesk' !important;
            font-weight: 800 !important;
            font-size: 32px !important;
            line-height: 48px !important;
        }
        .detail_name {
            margin-top: 4px !important;
            color: var(--font_deep_color) !important ;
        }
        .detail_main {
            margin-top: 24px !important;
            padding-bottom: 24px;
            .main_title {
                font-family: 'Lato' !important;
                font-size: 16px !important;
                line-height: 24px !important;
            }
        }
    }
}
</style>
