<template>
    <div class="custom_upload">
        <div :style="{ backgroundImage: `url(${border})` }" class="custom_content">
            <UploadFile class="upload" v-model="url" type="" :view="false" :size="size">
                <slot></slot>
            </UploadFile>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            url: '',
        }
    },
    model: {
        prop: 'val',
        event: 'change',
    },
    props: {
        border: {
            type: String,
            default: '',
        },
        val: {
            type: String,
            default: '',
        },
        size: {
            type: [Number, String],
            default: 5,
        },
    },
    created() {
        this.url = this.val
    },
    watch: {
        url(n) {
            this.$emit('change', n)
        },
    },
}
</script>
<style lang="scss" scoped>
.custom_upload {
    width: 100%;
    height: auto;
    height: 292px;
    .custom_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 292px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        .custom_content {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
