<template>
    <div class="submissions">
        <Go :prev="langJson('Manage submissions')" @prev="back">
            <div v-show="tab == 1 && showStatus == 'archive' && !isPhone" class="right_go">
                <BTN @handle="selectTablePC" type="active" bw="199">{{ langJson('Select All') }}</BTN>
                <BTN @handle="notifyWinner" type="active" bw="199" :disable="!lenArray(selectListData)">{{ langJson('Notify winner') }}</BTN>
            </div>
            <!-- status 三个按钮切换 是否是移动端 archive状态 -->
            <div v-if="tab == 1 && isPhone && showStatus == 'archive'" @click="showBtns" class="more_icon">
                <GI ws="24" hs="24" name="brand_more"></GI>
            </div>

            <!-- status 三个按钮切换 是否是移动端 archive状态 -->
            <div v-if="tab == 2 && isPhone && showStatus == 'archive'" @click="showBtns" class="more_icon">
                <GI ws="24" hs="24" name="brand_more"></GI>
            </div>
            <div v-if="tab == 3 && isPhone && showStatus == 'archive'" @click="showBtns" class="more_icon">
                <GI ws="24" hs="24" name="brand_more"></GI>
            </div>

            <!-- 按钮组 -->
            <div v-show="tab == 2 && !isPhone" class="right_go">
                <BTN @handle="removeArtwork" :disable="selectBtn" v-if="showStatus == 'live'" icon_left="brand_trash" type="warn" bw="199">{{ langJson('Delete artworks') }}</BTN>
                <!-- 已经全选了 -->
                <!-- <BTN v-if="showStatus == 'live' && allSelected" @handle="cancelAllSelected" type="selectAll" bw="142">{{ langJson('Select All') }}</BTN> -->
                <!-- 没有全选 -->
                <BTN v-if="showStatus == 'live'" @handle="selectAllArtworks" type="detail" bw="142">{{ langJson('Select All') }}</BTN>
                <BTN @handle="removeArtwork" :disable="selectBtn" v-if="showStatus == 'archive'" type="warn" bw="215">{{ langJson('Delete all artworks') }}</BTN>
                <BTN v-if="showStatus == 'archive'" :disable="selectBtnAll" type="detail" bw="142" @handle="selectAllArtworks">{{ langJson('Select All') }}</BTN>
                <BTN v-if="showStatus == 'archive'" @handle="isPhone ? mint_handle() : goToMintNft()" type="active" bw="213" :disable="selectBtn">{{ langJson('Mint multiple NFTs') }}</BTN>
            </div>

            <!-- tab artwork 三个按钮切换 是否是移动端 live状态 -->
            <BtnPop v-if="tab == 2 && isPhone && showStatus == 'live'" :left="-100">
                <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                <div slot="target" class="more_icon">
                    <GI ws="24" hs="24" name="brand_more"></GI>
                </div>
                <div slot="main" class="type_main">
                    <div v-for="(item, index) in tagList" :key="index" class="type_content">
                        <div @click="goRouteArtwork(item)" class="type_line">
                            <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                            <span class="title">{{ item.val }}</span>
                        </div>
                    </div>
                </div>
            </BtnPop>

            <div v-show="tab == 3 && !isPhone" class="right_go">
                <BTN icon_left="brand_trash" :disable="selectBtn" @handle="deleteOneWork" type="warn" bw="127">{{ langJson('Delete') }}</BTN>
                <BTN type="detail" :disable="selectBtn" @handle="recoverWork" bw="131">{{ langJson('Recover') }}</BTN>
                <!-- 已经全选 -->
                <!-- <BTN type="selectAll" v-if="deleteSelected" @handle="cancelAlldeleteworks" bw="138">{{ langJson('Select all') }}</BTN> -->
                <!-- 没有全选 -->
                <BTN type="detail" @handle="selectAlldeleteworks" bw="138">{{ langJson('Select all') }}</BTN>
            </div>
            <!-- status deleteartworks 三个按钮切换 是否是移动端 live状态 -->
            <BtnPop v-if="tab == 3 && isPhone && showStatus == 'live'" :left="-100">
                <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                <div slot="target" class="more_icon">
                    <GI ws="24" hs="24" name="brand_more"></GI>
                </div>
                <div slot="main" class="type_main">
                    <div v-for="(item, index) in tagListDelete" :key="index" class="type_content">
                        <div @click="goRouteDeleteWorks(item)" class="type_line">
                            <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                            <span class="title">{{ item.val }}</span>
                        </div>
                    </div>
                </div>
            </BtnPop>
        </Go>

        <div v-if="!isPhone" class="line"></div>
        <div class="submissions_center">
            <div class="title">
                <!-- title 在archive  -->
                <h2 v-if="showStatus == 'archive'" class="title_archive title_info">{{ compaignDetail.name }}</h2>
                <h2 v-if="showStatus == 'live'" class="title_live title_info">{{ compaignDetail.name }}</h2>
                <div class="status_box" v-if="showStatus == 'live'">
                    <span class="status_title" v-if="isPhone && tab == 1">{{ langJson('By LUMA Events') }}</span>
                    <div class="live_status" v-if="!isPhone" :style="{ backgroundImage: `url(/source/png/brand/live.png)` }"></div>
                    <div class="live_status" v-if="isPhone" :style="{ backgroundImage: `url(/source/png/brand/live_mobile.png)` }"></div>
                </div>
                <div class="status_box" v-if="showStatus == 'archive'">
                    <span class="status_title" v-if="isPhone">{{ langJson('By LUMA Events') }}</span>
                    <div class="archive_status" v-if="!isPhone" :style="{ backgroundImage: `url(/source/png/brand/archive.png)` }"></div>
                    <div class="archive_status" v-if="isPhone" :style="{ backgroundImage: `url(/source/png/brand/archive_mobile.png)` }"></div>
                </div>
            </div>
            <div class="status">
                <SelBtn class="status_item" @change="change" v-model="tab" type="main" :list="statuslist" size="unselected"></SelBtn>
                <div v-if="showStatus == 'archive' && tab == 1 && isPhone" class="line"></div>
                <!-- H5 deleteArtworks不显示这些 -->
                <div v-if="tab != 3" class="right_search">
                    <!-- H5不显示搜索 -->
                    <Search v-model="keyword" v-if="!isPhone">
                        <template v-slot:text_append><GI name="search"></GI></template>
                    </Search>
                    <!-- deleteArtworks 没有下拉 -->
                    <Sel v-model="sortType" @change="init" :showBorder="true" :size="isPhone ? 146 : 264" :left="isPhone ? -111 : 0" :placeholder="placeholder" v-show="tab != 3" :showIcon="true" type="select" :list="selectList" :searchHeight="true"></Sel>
                </div>
            </div>

            <div v-show="tab == 1 && !isPhone" class="table_box">
                <TableCopy ref="tableTableCopy" :CheckBoxShow="showStatus == 'archive' ? true : false" :commonStyle="true" :list="campaignParticipants" :title="title" :play="play" @viewDetail="viewDetail" @change="tableChange">
                    <div class="more" slot="foot">
                        <Page v-model="pageNum" @change="initParticipant" :all="totalParticipant" :size="pageSize" :isBtn="true"></Page>
                    </div>
                </TableCopy>
            </div>
            <div v-show="tab == 1 && isPhone" class="table_box">
                <TableMobile ref="tableMobiles" :CheckBoxShow="showStatus == 'archive' ? true : false" :showStatus="showStatus" :list="campaignParticipants" :title="title" :play="play" @viewDetail="viewDetail" @goAction="goAction" @change="tableChange">
                    <div class="more" slot="foot">
                        <Page v-model="pageNum" @change="initParticipant" :all="totalParticipant" :size="pageSize" :isBtn="true"></Page>
                    </div>
                </TableMobile>
            </div>

            <div v-show="tab == 2">
                <div class="artworks_box">
                    <div class="artworks_item" v-for="(item, index) in campaignArtwork" :key="index">
                        <Artworks :selectlist="selete_list" @select="selectItem" :showHead="true" :item="item" :showBottom="true" hs="204" :top="isTopSort(index)"></Artworks>
                    </div>
                    <div class="artworks_grap"></div>
                    <div class="artworks_grap"></div>
                    <div class="artworks_grap"></div>
                </div>
                <template v-if="compaignDetail.workNum == 0">
                    <nu-data></nu-data>
                </template>
                <div class="art_more">
                    <Page v-model="pageNum" @change="artworkInit" :all="totalArtwork" :size="pageSize" :isBtn="true"></Page>
                </div>
            </div>
            <div v-show="tab == 3">
                <div class="artworks_box">
                    <div class="artworks_item" v-for="(item, index) in removedArtwork" :key="index">
                        <Deleteworks :selectlist="selete_list" @select="selectItem" :item="item" hs="204"></Deleteworks>
                    </div>
                    <div class="artworks_grap"></div>
                    <div class="artworks_grap"></div>
                    <div class="artworks_grap"></div>
                </div>
                <template v-if="compaignDetail.deleteWorkNum == 0">
                    <nu-data></nu-data>
                </template>
                <div class="warning">
                    <span>{{ langJson('Artwork show the days remaining before deletion.After that time, items will be permanently deleted') }}</span>
                </div>
                <div class="art_more">
                    <Page v-model="pageNum" @change="removedArtworkInit" :all="totalDeleted" :size="pageSize" :isBtn="true"></Page>
                </div>
                <!-- Delete -->
                <Model :showHeader="false" :borderRadius="16" :padding="'24px 28px'" v-model="show">
                    <template v-slot:main>
                        <div class="dialog_main">
                            <h1>{{ langJson('Delete Artwork') }}</h1>
                            <span>{{ langJson(' Items will be permanently deleted') }}</span>
                            <BTN @handle="completelyDelete" :load="delete_load" type="active">{{ langJson('OK') }}</BTN>
                        </div>
                    </template>
                </Model>
                <!-- Recover -->
                <Model :showHeader="false" :borderRadius="16" :padding="'24px 28px'" v-model="recoverShow">
                    <template v-slot:main>
                        <div class="dialog_main">
                            <h1>{{ langJson('Recover Artwork') }}</h1>
                            <span>{{ langJson('Are you sure to recover these items') }}</span>
                            <BTN @handle="recoverArtwork" :load="recover_load" type="active">{{ langJson('OK') }}</BTN>
                        </div>
                    </template>
                </Model>
            </div>
        </div>
        <Model :title="langJson('Notify winners')" :padding="'28px 28px 40px 28px'" v-model="showWinner">
            <div class="notify_winners" slot="main">
                <div class="notify_middle notify_common">
                    <FormItem v-model="message" type="textarea" :title="langJson('Message')"></FormItem>
                </div>
                <div class="notify_btns notify_common">
                    <BTN type="detail" @handle="cancelNotify">{{ langJson('Cancel') }}</BTN>
                    <BTN type="active" @handle="sendNotify" :disable="!message">{{ langJson('Send') }}</BTN>
                </div>
            </div>
        </Model>
        <!-- 对话框按钮 -->
        <Model :title="langJson('Action')" :padding="'28px 28px 40px 28px'" v-model="showBtnsModel">
            <div class="notify_main" slot="main">
                <div v-if="tab == 1" class="notify_btns">
                    <BTN :disable="!lenArray(selectListData)" type="active" bh="48" @handle="notifyWinner">{{ langJson('Notify Winner') }}</BTN>
                    <BTN type="detail" bh="48" @handle="selectTable">{{ langJson('Select all') }}</BTN>
                </div>
                <div v-if="tab == 2" class="notify_btns">
                    <BTN type="active" bh="48" @handle="isPhone ? mint_handle() : goToMintNft()" :disable="selectBtn">{{ langJson('Mint multiple NFTs') }}</BTN>
                    <BTN type="detail" bh="48" @handle="selectAllArtworks" :disable="selectBtnAll">{{ langJson('Select all') }}</BTN>
                    <BTN icon_left="brand_trash" type="warn" :disable="selectBtn" @handle="removeArtwork">{{ langJson('Delete all artworks') }}</BTN>
                </div>
                <div v-if="tab == 3" class="notify_btns">
                    <BTN icon_left="brand_trash" :disable="selectBtn" @handle="deleteOneWork" type="warn" bw="127">{{ langJson('Delete') }}</BTN>
                    <BTN type="detail" :disable="selectBtn" @handle="recoverWork" bw="131">{{ langJson('Recover') }}</BTN>
                    <BTN type="detail" @handle="selectAlldeleteworks" bw="138">{{ langJson('Select all') }}</BTN>
                </div>
            </div>
        </Model>


        <MintTip v-model="mint_tip" @confirm="goToMintNft()"></MintTip>
    </div>
</template>
<script>
export default {
    computed: {
        isTopSort() {
            return (index) => {
                try {
                    if (this.pageNum == 1 && index < 3) {
                        return index + 1
                    } else {
                        return ''
                    }
                } catch (err) {
                    return ''
                }
            }
        },
        statuslist() {
            let { joinNum, workNum, deleteWorkNum } = this.compaignDetail
            return [
                {
                    lable: `${this.langJson('Participants')} (${joinNum})`,
                    value: 1,
                },
                {
                    lable: `${this.langJson('Artwork')} (${workNum})`,
                    value: 2,
                },
                {
                    lable: `${this.langJson('Deleted Artworks')} (${deleteWorkNum})`,
                    value: 3,
                },
            ]
        },
        selectList() {
            let result = [
                {
                    lable: this.langJson('Most votes'),
                    value: 'totalVoteNum',
                },
                { lable: this.langJson('Joined date'), value: 'joinDate' },
                { lable: this.langJson('Artwork count'), value: 'totalWorkNum' },
                { lable: this.langJson('Total artwork'), value: 'allTotalWorkNum' },
            ]
            if (this.tab == 2) {
                result = [
                    {
                        lable: this.langJson('Most votes'),
                        value: 'voteNum',
                    },
                    { lable: this.langJson('Joined date'), value: 'createTime' },
                ]
            }
            return result
        },
        play() {
            return [
                {
                    event: 'viewDetail',
                    title: this.langJson('View detail'),
                    type: 'detail',
                    act: 'action',
                },
            ]
        },
        title() {
            let result = {
                nickName: {
                    title: this.langJson('Name'),
                    type: 'text',
                },
                joinDate: {
                    title: this.langJson('Joined date'),
                    type: 'text',
                },
                // no: {
                //     title: this.langJson('No.'),
                //     type: 'text',
                // },
                totalWorkNum: {
                    title: this.langJson('Total artworks for this campaign'),
                    type: 'text',
                },
                totalVoteNum: {
                    title: this.langJson('Total Votes'),
                    type: 'text',
                },
                allTotalWorkNum: {
                    title: this.langJson('Total artworks for all campaigns '),
                    type: 'text',
                },
            }

            if (this.tab == 2) {
                result = {
                    nickName: {
                        title: this.langJson('Name'),
                        type: 'text',
                    },
                    date: {
                        title: this.langJson('Joined date'),
                        type: 'text',
                    },
                    no: {
                        title: this.langJson('No.'),
                        type: 'text',
                    },
                    brandRanking: {
                        title: this.langJson('Brand ranking'),
                        type: 'text',
                    },
                    totalvote: {
                        title: this.langJson('Total vote'),
                        type: 'text',
                    },
                    SubmittedArtworks: {
                        title: this.langJson('Submitted Artworks'),
                        type: 'text',
                    },
                }
            }

            return result
        },
        selectBtn() {
            if (this.lenArray(this.selete_list)) {
                return false
            }
            return true
        },
        selectBtnAll() {
            let arr = []
            this.campaignArtwork.forEach((item) => {
                // 0-未铸造 1-已铸造
                if (!item.status) {
                    arr.push(item)
                }
            })
            if (!this.lenArray(arr)) {
                return true
            }
            return false
        },
    },
    data() {
        return {
            mint_tip: false,
            placeholder: this.langJson('Most votes'),
            allSelected: false,
            deleteSelected: false,
            tagList: [
                { icon_dark: 'select_all', icon_light: 'select_all_light', val: 'Select All', number: 1 },
                { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', number: 2 },
            ],
            tagListDelete: [
                { icon_dark: 'select_all', icon_light: 'select_all_light', val: 'Select All', number: 1 },
                { icon_dark: 'overview_clock', icon_light: 'overview_clock_light', val: 'Recover', number: 2 },
                { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', number: 3 },
            ],
            tab: 1,
            showStatus: this.$route.query.status,

            list: [
                {
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    no: '1 Wan',

                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                    isCkecked: false,
                },
                {
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    no: '1 Wan',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    no: '1 Wan',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    no: '1 Wan',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    no: '1 Wan',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    no: '1 Wan',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    no: '1 Wan',
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    no: '1 Wan',
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    no: '1 Wan',
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    isCkecked: false,
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                },
                {
                    no: '1 Wan',
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                    isCkecked: false,
                },
                {
                    no: '1 Wan',
                    Name: 'Phifer Hall',
                    date: '2022-09-20 20:00',
                    thiscompaign: '123',
                    totalvotes: '123',
                    allcampaigns: '123',
                    isCkecked: false,
                },
            ],

            show: false,
            recoverShow: false,
            showWinner: false,
            showBtnsModel: false,

            selete_list: [],
            pageNum: 1,
            pageSize: 10,
            totalParticipant: 0,
            totalArtwork: 0,
            totalDeleted: 0,
            sortType: 'totalVoteNum',
            keyword: '',

            delete_load: false,
            recover_load: false,
            selectListData: [],
            message: '',
        }
    },

    watch: {
        tab(val) {
            this.pageNum = 1
            this.keyword = ''
            this.selete_list = []
            if (val == 1) {
                this.sortType = 'totalVoteNum'
            } else if (val == 2) {
                this.sortType = 'voteNum'
            }
            this.init()
        },
        keyword() {
            this.init()
        },
    },
    created() {
        let { commit } = this.$store
        commit('mintNftList', [])
        this.selectListData = []
        this.message = ''
        this.init()
    },
    methods: {
        mint_handle() {
            this.mint_tip = true
        },
        async goToMintNft() {
            try {
                let result = await this.connect()
                if (!result) {
                    this.pushMsg('warning', this.langJson('Please connect wallet'))
                    return
                }
                let chainId = this.compaignDetail.campaignContract.chainId
                await this.targetChain(chainId, () => {
                    this.jump_brand_compaign_artwork_mintNfts({ idActive: this.$route.query.idActive })
                })
            } catch (err) {
                console.log(err)
            }
        },
        async recoverArtwork() {
            this.recover_load = true
            try {
                let { dispatch } = this.$store
                let result = await dispatch('recoverArtwork', this.selete_list)
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Recover success'))
                    this.selete_list = []
                    this.recoverShow = false
                    await this.init()
                }
            } catch (err) {
                console.log(err)
            }
            this.recover_load = false
        },

        selectItem(obj) {
            let { commit } = this.$store
            let exit = this.selete_list.some((item) => {
                return item == obj.id
            })
            if (exit) {
                this.selete_list = this.selete_list.filter((item) => {
                    return item != obj.id
                })

                let arr = JSON.parse(JSON.stringify(this.globalBrand.mintNftList))

                arr = arr.filter((item) => {
                    return item.id != obj.id
                })
                commit('mintNftList', arr)
            } else {
                this.selete_list.push(obj.id)
                commit('mintNftList', [...this.globalBrand.mintNftList, obj])
            }
        },
        async removeArtwork() {
            this.showBtnsModel = false
            this.delete_load = true
            try {
                let { dispatch } = this.$store
                let result = await dispatch('artworkDelete', this.selete_list)
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Delete success'))
                    this.selete_list = []
                    this.show = false
                    await this.init()
                }
            } catch (err) {
                console.log(err)
            }
            this.delete_load = false
        },
        async completelyDelete() {
            try {
                this.delete_load = true
                let { dispatch } = this.$store
                let result = await dispatch('completelyDelete', this.selete_list)
                this.delete_load = false
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Delete success'))
                    this.selete_list = []
                    this.show = false
                    await this.init()
                }
            } catch (err) {
                this.delete_load = false
                console.log(err)
            }
        },
        async init() {
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                await dispatch('compaignDetail', { id: idActive })
                await this.initParticipant()
                await this.artworkInit()
                await this.removedArtworkInit()
            } catch (err) {
                console.log(err)
            }
        },

        async removedArtworkInit() {
            try {
                if (this.tab != 3) {
                    return
                }
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                let result = await dispatch('removedArtwork', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    campaignId: idActive,
                })
                if (result && result.total) {
                    this.totalDeleted = result.total
                }
            } catch (err) {
                console.log(err)
            }
        },

        async artworkInit() {
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                let result = await dispatch('campaignArtwork', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    campaignId: idActive,
                    artworkTitle: this.keyword,
                    isAsc: 'desc',
                    orderByColumn: this.tab == 2 && this.sortType,
                })
                if (result && result.total) {
                    this.totalArtwork = result.total
                }
            } catch (err) {
                console.log(err)
            }
        },

        async initParticipant() {
            try {
                let { dispatch } = this.$store
                let { idActive } = this.$route.query
                let result = await dispatch('campaignParticipants', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    campaignId: idActive,
                    isAsc: 'desc',
                    orderByColumn: this.tab == 1 && this.sortType,
                    nickName: this.keyword,
                })
                if (result && result.total) {
                    this.totalParticipant = result.total
                }
            } catch (err) {
                console.log(err)
            }
        },

        handleChange(val) {
            this.placeholder = this.selectList.find((item) => item.value == val).lable
        },
        change(val) {
            this.tab = val
        },
        deleteOneWork() {
            this.showBtnsModel = false
            this.show = true
        },
        deleteConfirm() {
            this.show = false
        },
        recoverWork() {
            this.showBtnsModel = false
            this.recoverShow = true
        },
        recoverConfirm() {
            this.recoverShow = false
        },
        viewDetail(item) {
            let { idActive } = this.$route.query
            this.jump_brand_compaign_participants_detail({
                user: item.userId,
                idActive: idActive,
                actName: this.compaignDetail.name,
            })
        },
        notifyWinner() {
            this.message = ''
            this.showBtnsModel = false
            this.showWinner = true
        },
        cancelNotify() {
            this.showWinner = false
            this.message = ''
        },
        async sendNotify() {
            try {
                let { dispatch } = this.$store
                let result = await dispatch('notifyWinners', {
                    userId: this.selectListData,
                    message: this.message,
                })
                if (result && result.code == 200) {
                    this.showWinner = false
                    this.pushMsg('success', this.langJson('submit successfully'))
                    this.message = ''
                }
            } catch (err) {
                console.log(err)
            }
        },
        closeNotify() {
            this.showWinner = false
        },
        goAction() {
            this.notifyWinner()
        },
        showBtns() {
            this.showBtnsModel = true
        },
        closeShowBtnsModel() {
            this.showBtnsModel = false
        },
        selectAllArtworks() {
            let { commit } = this.$store
            if (this.selete_list.length == this.campaignArtwork.length) {
                this.selete_list = []
                commit('mintNftList', this.selete_list)
            } else {
                let arr = []
                this.campaignArtwork.forEach((item) => {
                    // 0-未铸造 1-已铸造
                    if (!item.status) {
                        this.selete_list.push(item.id)
                        arr.push(item)
                    }
                })
                commit('mintNftList', arr)
                // this.selete_list = this.campaignArtwork.map((item) => {
                //     return item.id
                // })
            }
        },
        cancelAllSelected() {
            this.artwork_list.forEach((item) => {
                item.isChecked = false
            })
            this.allSelected = false
        },
        goRouteArtwork(item) {
            switch (item.number) {
                case 1:
                    this.selectAllArtworks()
                    break
                case 2:
                    if (!!this.lenArray(this.selete_list)) {
                        this.removeArtwork()
                        return
                    }
                    this.pushMsg('warning', this.langJson('Please select operation data'))
                    break

                default:
                    break
            }
        },
        selectAlldeleteworks() {
            if (this.selete_list.length == this.removedArtwork.length) {
                this.selete_list = []
            } else {
                this.selete_list = this.removedArtwork.map((item) => {
                    return item.id
                })
            }
        },
        cancelAlldeleteworks() {
            this.delete_list.forEach((item) => {
                item.isChecked = false
            })
        },
        goRouteDeleteWorks(item) {
            switch (item.number) {
                case 1:
                    this.selectAlldeleteworks()
                    break
                case 2:
                    if (!!this.lenArray(this.selete_list)) {
                        this.recoverWork()
                        return
                    }
                    this.pushMsg('warning', this.langJson('Please select operation data'))
                    break
                case 3:
                    if (!!this.lenArray(this.selete_list)) {
                        this.deleteOneWork()
                        return
                    }
                    this.pushMsg('warning', this.langJson('Please select operation data'))
                    break
                default:
                    break
            }
        },
        selectTable() {
            this.$refs.tableMobiles.selectAll()
        },

        selectTablePC() {
            this.$refs.tableTableCopy.selectAll()
        },
        tableChange(val) {
            this.selectListData = val
        },
    },
    mounted() {
        if (this.$route.query.tab) {
            this.tab = this.$route.query.tab
        } else {
            this.tab = 1
        }
    },
}
</script>
<style lang="scss" scoped>
.submissions {
    overflow: hidden;
}
.right_go {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}
.line {
    width: 100%;
    /* Neutral/5 */
    border-bottom: 1px solid var(--border_color);
}
.submissions_center {
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    padding-top: 24px;
    .title {
        display: flex;
        align-items: center;
        gap: 16px;
        .title_info {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
        .live_status {
            width: 88px;
            height: 36px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .archive_status {
            width: 88px;
            height: 36px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .status {
        margin-top: 20px;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 16px;
        box-sizing: border-box;
        .total {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
        }
        .status_item {
            flex: 1;
            overflow: auto;
        }
        .right_search {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    .table_box {
        max-width: var(--max_width);
        box-sizing: border-box;
        width: 100%;

        /* height: 774px; */
        margin-top: 32px;
        /*     background: var(--color_white); */
        margin-bottom: 56px;

        .more {
            margin-top: 32px;
            box-sizing: border-box;
        }

        @at-root .position_center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .artworks_box {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(296px, 1fr));
        column-gap: 32px;
        row-gap: 36px;
        .artworks_item {
            background-color: var(--color_white);
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
            /* max-width: 296px;
      width: 100% !important; */
        }
    }
    .art_more {
        margin-top: 36px;
        margin-bottom: 82px;
        width: 100%;
    }
    .warning {
        width: 100%;
        text-align: center;
        margin-top: 156px;
        margin-bottom: 40px;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color);
    }
    /* .delete_box {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 32px;
    .artworks_item {
      max-width: 296px;
      width:100% !important;

          background: var(--color_white);
      box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
      border-radius: 16px;
    }

  } */
}
.dialog_main {
    display: grid;
    grid-template-rows: max-content;
    grid-auto-columns: minmax(279px, 422px);
    justify-content: center;
    align-content: center;
    gap: 24px;
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: var(--font_deep_color);
        place-self: center;
    }
    span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        place-self: center;
        color: var(--font_deep_color);
    }
}
.notify_winners {
    margin-top: 32px;
    .notify_btns {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, minmax(204px, 1fr));
        gap: 16px;
    }
}
.notify_main {
    box-sizing: border-box;
    margin-top: 24px;
    .notify_btns {
        grid-template-columns: repeat(1, minmax(279px, 1fr));
        display: grid;
        row-gap: 20px;
    }
}
@media only screen and (max-width: 1080px) {
    .submissions_center {
        padding-top: 0px;
    }
    .go_center {
        height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 0px;
    }
    .title {
        width: auto;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start;
        gap: 4px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        .title_live {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 335px;
            font-weight: 700 !important;
            font-size: 24px !important;
            line-height: 40px;
        }
        .title_archive {
            font-family: 'Inter' !important;
            font-weight: 700 !important;
            font-size: 24px !important;
        }
    }
    .table_box {
        height: fit-content !important;
        margin-top: 8px !important;
    }
    .right_search {
        margin-left: auto;
    }
    .status {
        flex-direction: column;
        margin-top: 16px !important;
        gap: 12px !important;
    }
    .status_box {
        display: flex;
        gap: 13px;
        align-items: center;
        .status_title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
    .live_status {
        width: 72px !important;
        height: 22px !important;
    }
    .archive_status {
        width: 90px !important;
        height: 20px !important;
    }
    /* 移动端单个item大小 */
    .artworks_item {
        flex: 1;
        max-width: 100% !important;
    }
    .more_icon {
        box-sizing: border-box;
        width: 36px;
        height: 36px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .type_main {
        padding: 24px;
        background: var(--color_white);
        border: 1px solid var(--border_color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 24px;
        cursor: pointer;

        .type_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:last-child {
                color: var(--danger_color);
            }
            &:not(:last-child) {
                color: var(--font_deep_color);
            }
            .type_line {
                display: flex;
                align-items: center;
                gap: 8px;

                .title {
                    white-space: nowrap;
                    font-size: 14px;
                    font-family: 'Lato';
                }
            }
        }
    }
    .notify_winners {
        margin-top: 32px;
        .notify_btns {
            margin-top: 32px;
            display: grid;
            grid-template-columns: repeat(1, minmax(279px, 1fr)) !important;
            gap: 16px;
        }
    }
    .artworks_box {
        gap: 24px !important;
        margin-top: 20px !important;
        .artworks_item {
            margin-top: 0px !important;
        }
        .art_more {
            margin-top: 0px !important;
        }
        .warning {
            /* gap 24 + margin-top 84 =104px  */
            /* gap 和 margin 不会重叠，而是叠加 */
            margin-top: 84px !important;
        }
    }
    .more {
        margin-top: 20px !important;
    }
}
@media only screen and (max-width: 500px) {
    .dialog_main {
        width: 74.4vw !important;
    }
}
</style>
