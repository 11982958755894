<template>
    <div>
        <StepRun ref="step">
            <div slot="tab1">
                <div class="form">
                    <div class="header">
                        <div class="header_top">
                            <h2>{{ langJson('Account support') }}</h2>
                            <span @click="switchPage(1)" v-if="!isPhone">{{ langJson('Your submitted requests') }}</span>
                        </div>
                        <div class="header_bottom">
                            <span class="span">
                                <span>{{ langJson(`Can't find the answer you want in`) }}&nbsp;</span>
                                <span class="click_underline" @click="jump_page_path('/startup/article/faq')">{{ langJson('Help Center') }}</span>
                                ?
                                <span>{{ langJson('You can make a request') }}&nbsp;</span>
                                <!-- class="click_underline" -->
                                <span>{{ langJson('Here') }}</span>
                                <div class="submitted_box" v-if="isPhone">
                                    <span @click="switchPage(1)" class="submitted">{{ langJson('Your submitted requests') }}</span>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="form_box">
                        <div class="form_left">
                            <FormItem class="information_item" :placeholder="langJson('Select type')" type="select" :matchs="['unull']" :title="langJson('What can we help')" :list="helpList" v-model="form.helpTypeId"></FormItem>
                            <FormItem class="information_item" type="text" :matchs="['unull', 'email']" :title="langJson('Email')" v-model="form.email"></FormItem>
                            <FormItem class="information_item" type="text" :matchs="['unull','maxLength']" :title="langJson('Company')" v-model="form.companyName"></FormItem>
                            <FormItem class="information_item" type="textarea" :matchs="['unull']" :title="langJson('Your message')" v-model="form.description"></FormItem>
                        </div>
                        <div class="form_right">
                            <FormItem :title="langJson('Supporting information (Optional)')" class="information_item" type="image" :image="bannerConfig" :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')" v-model="form.file"></FormItem>
                        </div>
                    </div>
                    <div class="btn">
                        <BTN type="active" :disable="!pass" @handle="saveHand()" :load="load_btn">{{ langJson('Submit') }}</BTN>
                    </div>
                </div>
            </div>
            <requestHistory ref="requestHistory" slot="tab2" @back="prev()"></requestHistory>
        </StepRun>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                helpTypeId: '',
                email: '',
                companyName: '',
                description: '',
                file: '',
            },
            bannerConfig: {
                type: 'big',
            },
            list: [
                {
                    lable: this.langJson('USD'),
                    value: '',
                },
            ],
            helpList: [],
        }
    },
    computed: {
        pass() {
            let result = this.checkPass(this.form.helpTypeId, ['unull'], false)
            result = result && this.checkPass(this.form.email, ['unull', 'email'], false) && this.checkPass(this.form.companyName, ['unull','maxLength'], false) && this.checkPass(this.form.description, ['unull'], false)
            return result
        },
    },
    created() {
        this.init()
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        // 提交问题
        async saveHand() {
            try {
                let { dispatch } = this.$store
                this.load_btn = true
                let res = await dispatch('contentHelpAdd', this.form)
                if (res && res.code == 200) {
                    this.pushMsg('success', this.langJson('Submit Success'))
                    this.$refs['requestHistory'] && this.$refs['requestHistory'].init()
                    this.load_btn = false

                    // Object.assign(this.form, {
                    //     helpTypeId: '',
                    //     email: '',
                    //     companyName: '',
                    //     description: '',
                    //     file: '',
                    // })
                }
            } catch (error) {
                this.load_btn = false
            }
        },

        // 获取帮助类型
        async init() {
            try {
                let { dispatch } = this.$store
                let res = await dispatch('contentHelpList')
                if (res && res.code == 200) {
                    this.helpList = res.data.map((item) => {
                        return {
                            lable: item.name,
                            value: item.id,
                        }
                    })
                }
            } catch (err) {
                console.log(err)
            }
        },
        next() {
            this.$refs.step.next()
        },
        prev() {
            this.$refs.step.prev()
        },
        backPrev() {
            if (this.$refs.step.step > 0) {
                this.prev()
            } else {
                this.back()
            }
        },
        switchPage(val) {
            this.switchStep = val
            this.$refs.step.jump(val)
        },
    },
}
</script>
<style lang="scss" scoped>
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 156px;
    .header {
        margin-bottom: 32px;

        .header_top {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            h2 {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
            }
            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--primary_color);
                cursor: pointer;
            }
        }
        .header_bottom {
            .span {
                margin-top: 16px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_color_black);
                .click_underline {
                    text-decoration-line: underline;
                    color: var(--primary_color);
                    cursor: pointer;
                }
            }
            .submitted_box {
                margin-top: 24px;
                .submitted {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--primary_color);
                    cursor: pointer;
                }
            }
        }
    }
    .form_box {
        gap: 40px;
        display: flex;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        .form_left {
            display: flex;
            flex-direction: column;
            gap: 24px;
            max-width: 405px;
            width: 100%;
        }
        .form_right {
            max-width: 443px;
            width: 100%;
        }
    }
    .btn {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1080px) {
    .header_bottom {
        margin-top: 24px !important;
    }
    .form {
        max-width: 100%;
        padding: 20px !important;
    }
    .header_top {
        h2 {
            font-size: 32px !important;
        }
    }
    .form_box {
        flex-direction: column;
    }
    .form_left {
        max-width: 100% !important;
        width: 100% !important;
    }
    .form_right {
        max-width: 100% !important;
        width: 100% !important;
    }
}
</style>
