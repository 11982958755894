<template>
    <Model :padding="'24px'" :title="globalConfig.shareObj.title" v-model="show">
        <div class="share_main" slot="main">
            <!-- 活动封面 -->
            <div
                class="share_image"
                :style="{
                    backgroundImage: `url(${globalConfig.shareObj.img})`,
                }"
            >
                <img :src="qrcode" alt="" class="qr_code" />
            </div>

            <!-- 支持什么社交媒体将它传入 -->
            <!-- 分享到我的飞书 -->
            <div class="share_icons">
                <div @click="facebook()" class="cursor">
                    <GI name="facebook" ws="48" hs="48"></GI>
                    <span>{{ langJson('Facebook') }}</span>
                </div>
                <div @click="gmail()" class="cursor">
                    <GI name="gmail" ws="48" hs="48"></GI>
                    <span>{{ langJson('Gmail') }}</span>
                </div>
                <div @click="discord()" class="cursor">
                    <GI name="discord" ws="48" hs="48"></GI>
                    <span>{{ langJson('Discord') }}</span>
                </div>
                <div @click="twitter()" class="cursor">
                    <GI name="twitter" ws="48" hs="48"></GI>
                    <span>{{ langJson('Twitter') }}</span>
                </div>
                <!-- <div @click="tiktok()" class="cursor">
                    <GI name="tiktok" ws="48" hs="48"></GI>
                    <span>{{ langJson('Tiktok') }}</span>
                </div>
                <div @click="instagram()" class="cursor">
                    <GI name="instagram" ws="48" hs="48"></GI>
                    <span>{{ langJson('Instagram') }}</span>
                </div> -->
                
            </div>

            <div id="share_copy" class="share_link" @click="copy(url)">
                <div class="link_left">
                    <div class="link_title">{{ globalConfig.shareObj.linkName }}</div>
                    <div class="link_text">{{ url }}</div>
                </div>
                <div class="link_right">
                    <BTN :fontSize="isPhone ? '16' : ''" :borderWidth="isPhone ? '1' : ''" type="detail" bw="70" bh="40">{{ langJson('Copy') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>
<script>
import { mapState } from 'vuex'
import QRCode from 'qrcode'
export default {
    props: {},

    data() {
        return {
            show: false,
            url: '',
            qrcode: '',
        }
    },
    mounted() {},

    computed: {
        ...mapState({ showShare: (state) => state.config.showShare }),
        // ...mapState('config', ['showShare']),
    },
    watch: {
        showShare(val) {
            this.show = val
            this.getlink()
            this.qrlink()
        },
        show(val) {
            this.$store.commit('changeShowShare', val)
        },
    },
    methods: {
        async qrlink() {
            let result = await QRCode.toDataURL(this.url, {
                width: 600,
                height: 600,
            })
            this.qrcode = result
        },
        twitter() {
            window.open(`https://twitter.com/intent/tweet?text=${this.url}&url=${this.url}`)
        },
        discord() {
            window.open(`https://discord.com/channels/@me`)
        },
        gmail() {
            window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body=${this.url}&ui=2&tf=1&pli=1`)
        },
        facebook() {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.url}`)
        },
        instagram() {
            window.open(`https://www.instagram.com/`)
        },
        tiktok() {
            window.open(`https://www.tiktok.com/`)
        },
        getlink() {
            if (this.globalConfig.shareObj.url) {
                let protocol = window.location.protocol
                let host = window.location.host
                let port = window.location.port
                this.url = `${protocol}//${host}${this.globalConfig.shareObj.url}`
            } else {
                this.url = window.location.href
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.share_main {
    .share_link {
        .link_right {
            margin-left: 20px;
        }
        .link_left {
            .link_text {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-top: 4px;
                width: 263px;
                /* width: calc(100% - 200px); */
                /* max-width: calc(100% - 200px); */

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .link_title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_modif_color);
            }
            flex: 1;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--bg_color);
        border-radius: 32px;
        padding: 12px 24px;
        flex: 1;
        cursor: pointer;
        margin-top: 20px;
    }
    .share_image {
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 72%;
        margin: auto;
        min-height: 360px;
        .qr_code {
            position: absolute;
            right: 12px;
            top: 12px;
            width: 100px;
            height: 100px;
            margin: auto;
        }
    }
    .share_icons {
        margin: 0 auto;
        width: 90%;
        height: 72px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: var(--font_color_black);
            }
        }
    }
    .share_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    margin-top: 32px;
    box-sizing: border-box;
    width: 400px;
}
.link_text {
    width: 239px !important;
}

@media screen and (max-width: 1080px) {
    .link_title {
        font-size: 12px !important;
        line-height: 16px !important;
    }
    .link_text {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

@media screen and (max-width: 500px) {
    .share_main {
        width: 100%;
    }
    .share_image {
        width: 100% !important;
    }
    .share_icons {
        width: 100% !important;
    }
    .link_text {
        width: 160px !important;
    }
}
@media screen and (max-width: 400px) {
    .link_text {
        width: 120px !important;
    }
}
</style>
