<template>
    <div :id="components_id" class="search_pop" @mouseleave="leave" @mouseenter="enter">
        <div :id="target_id" class="pop_target">
            <slot name="target"></slot>
        </div>
        <div :id="main_id" :class="['pop_main', initPop && runClass]">
            <slot name="main"></slot>
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'show',
        event: 'update',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show(n) {
            this.initPop = true
            if (n) {
                this.open()
            } else {
                this.close()
            }
        },
    },
    computed: {
        runClass() {
            return this.show ? 'openPop' : 'closePop'
        },
    },
    data() {
        return {
            target_id: '',
            main_id: '',
            initPop: false,

            time: null,
        }
    },
    inject: ['regCode'],
    created() {
        let code = this.regCode()
        this.components_id = 'search_pop_' + code
        this.target_id = 'search_target_' + code
        this.main_id = 'search_main_' + code

        this.show && this.$emit('update', false)
    },
    mounted() {},

    methods: {
        leave() {
            this.time = setTimeout(() => {
                this.close()
            }, 500)
        },
        enter() {
            this.time && clearTimeout(this.time)
        },
        topGet() {
            let target = document.getElementById(this.target_id)
            let main_top = this.SubTH(target) + 10
            return main_top
        },
        sizeGet() {
            let main = document.getElementById(this.main_id)
            let main_size = this.SubTH(main)
            return main_size
        },

        open() {
            let main_top = this.topGet()
            let main_size = this.sizeGet()
            let main = document.getElementById(this.main_id)
            if(main) {
                main.style.cssText = `height:${main_size}px;overflow: auto;top:${main_top}px`
            }
        },
        close() {
            let main_top = this.topGet()
            let main = document.getElementById(this.main_id)
            if(main) {
                main.style.cssText = `height:0px;overflow: auto;top:${main_top}px`
            }
            this.$emit('update', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.search_pop {
    position: relative;

    .pop_target {
    }
    .pop_main {
        position: absolute;
        overflow: hidden;
        background-color: var(--bg_color);
        border-radius: 6px;
        width: 100%;
        z-index: 10000;
        height: 0px;
        transition: all 1s;
       
    }
}
</style>


