<template>
    <div class="time">
        <div class="title">{{langJson("Ending in")}}</div>
        <div class="items">
            <div class="item">
                <div :class="['item_btn']">{{timeObj.days}}</div>
                <div class="item_title">{{langJson("DAYS")}}</div>
            </div>
            <div class="item">
                <div :class="['item_btn']">{{timeObj.hours}}</div>
                <div class="item_title">{{langJson("HOURS")}}</div>
            </div>
            <div class="item">
                <div :class="['item_btn']">{{timeObj.minutes}}</div>
                <div class="item_title">{{langJson("MINUTES")}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            refer:1,
            target:null
        }
    },
    computed:{
        timeObj() {
            return this.refer && this.remainTime(this.time)
        }
    },
    props:{
        title:{
            type:String,
            default:""
        },
        time:{
            type:[String,Number],
            default:0
        },
        type:{
            type:String,
            default:"active"
        }
    },
    created() {
        this.target = setInterval(() => {
            this.refer = new Date().getTime()
        },1000)
    },
    beforeDestroy() {
        clearInterval(this.target)
    }
}
</script>
<style lang="scss" scoped>
.time{
    .items{
        .item{
            .item_title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                color:var(--font_color);
                margin-top:8px;
            }
            .item_btn{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color:var(--font_deep_color);

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                padding: 12px;
                background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                border-radius: 8px;
                box-sizing: border-box;

            }
            .active{
                background: var(--main_color);
                color:var(--color_white);
            }

            .cancel{
                background: var(--color_white);
                color:var(--font_modif_color);
            }
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top:16px;
    }
    .title{
        text-align: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color:var(--font_deep_color);
    }
    padding:16px;
    gap: 16px;
    box-sizing: border-box;
    position: absolute;
    width: 234px;
    left: 16px;
    bottom: 16px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 24px;
}
</style>