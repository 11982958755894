<template>
    <!--移动端圆角40 pc24 -->

    <div class="model_main" slot="main">
        <div class="type_main">
            <div class="type_title">
                {{ langJson('Who are you ?') }}
                <div class="title">{{ langJson("Log in as") }}</div>
                <div class="cycle" :style="{ backgroundImage:`url(/source/png/login_cycle.png)` }"></div>
                <div class="star" :style="{ backgroundImage:`url(/source/png/login_star.png)` }"></div>
            </div>
            <div class="btns">
                <div class="type_btn">
                    <BTN @handle="goParTicipant" type="active" :bh="isPhone ? '56' : '60'">{{ langJson('an Individual') }}</BTN>
                </div>
                <div class="type_btn">
                    <BTN @handle="goBrand" type="active" :bh="isPhone ? '56' : '60'">{{ langJson('a Brand') }}</BTN>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
        }
    },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        image: {
            type: String,
            default: '',
        },
    },
    methods: {
        goParTicipant() {
            this.close()
            this.jump_participant_login()
        },
        goBrand() {
            this.close()
            this.jump_brand_login()
        },
        close() {
            this.$emit('closeLogin', false)
        },
        goRoute(path) {
            this.$router.push({ path: path })
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .type_main {
        .btns {
            .type_btn {
                .title{
                    text-align: center;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22px;
                    color: var(--font_deep_color);
                    
                }
                .desc {
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--font_deep_color);
                    padding:16px;
                    font-family: 'Inter';
                    background-color: var(--bg_color);
                    margin:30px 0;
                    border-radius: 6px;
                }
                margin-top: 16px;
                flex:1;
                display: flex;
                flex-direction: column;

            }
            margin-top: 32px;
            display: flex;
            gap: 30px;
        }

        .type_title {
            .star {
                position: absolute;
                top: 5px;
                left: 125px;
                width: 36px;
                height: 36px;
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .cycle {
                position: absolute;
                top: 20px;
                right: 0px;
                width: 100%;
                height: 100px;
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            .title {
                text-align: center;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Cabinet Grotesk;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 64px;
                color:var(--font_deep_color);
            }
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            padding-top:20px;
            box-sizing: border-box;
        }
        height: auto;
        margin-left: 28px;
        margin-right: 28px;
        width: 560px;
        box-sizing: border-box;
        background: var(--nft_dialog_bg);
        border-radius: 24px;
    }
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    box-sizing: border-box;
}

@media screen and (max-width: 1080px) {
    .model_main {
        .type_main {
            .btns {
                .type_btn {
                    margin-top: 16px;
                }
                margin-top: 24px;
                padding-left: 0px;
                padding-right: 0px;
                flex-direction: column;
                gap: 0px!important;
            }

            .type_title {
                .star {
                    position: absolute;
                    top: 8px;
                    left: 25px;
                    width: 36px;
                    height: 36px;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .cycle {
                    position: absolute;
                    top: 20px;
                    right: 0px;
                    width: 100%;
                    height: 100px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                font-size: 16px;
                line-height: 24px;
            }
            height: auto;
            width: 271px;
            margin-left: 0px;
            margin-right: 0px;
            box-sizing: border-box;
            background: var(--nft_dialog_bg);
            border-radius: 40px;
        }
        .model_top {
            .top_right {
                cursor: pointer;
            }
            .top_left {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--font_deep_color);
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        box-sizing: border-box;
    }
}
</style>
