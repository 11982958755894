<template>
    <div class="step_item">
                        Official is a NFT collection of 20,001 PF Avatars that will give you membership access to The Yard. It starts with exclusive Giveaways among other top collections, regular NFT drops, contests and Treasury Hunt even, and much more that will be revealed over time. Every part of the Roadmap will be activated as soon as sales goals are accomplished. An Azuka Ape is your pass in the metaverse... we build together.

The Yard is a community driven place where the physical and digital worlds are fading away and the rules are being changed.
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.step_item{
    .item_info{
        height:100%;
        overflow: auto;
        .info_detail{
            margin-bottom:20px;
            margin-right:20px;
            float: left;
        }
    }
    .item_tag{
        margin-right:20px;
        height:70px;
        float: left;
        margin-bottom:20px;
    }
    background:var(--color_white);
    height:400px;
    margin-top:20px;
    border-radius: 12px;
    padding:20px;
    box-sizing: border-box;
    overflow: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
}
</style>