<template>
    <div :class="['global_assets', classUpdate]">
        <div class="model_top">
            <div class="top_left">
                {{ langJson('My Assets') }}
            </div>
            <div class="top_right" @click="close">
                <GI name="model_close" ws="24" hs="24" @click="close"></GI>
            </div>
        </div>

        <div class="asset_main">
            <div class="person_info">
                <div class="info_left">
                    <div
                        class="info_image bgCover"
                        :style="{
                            backgroundImage: `url(${portrait})`,
                        }"
                    ></div>
                    <div class="info_name">{{ detail.userName }}</div>
                </div>
                <div class="info_right">
                    <div class="info_address" @click="copy(reAddress(walletDetail.address))">
                        {{ reAddress(walletDetail.address) | hideStr }}
                    </div>
                </div>
            </div>

            <div class="person_total">
                <div class="total_title">{{ langJson('Total balance') }}</div>
                <div class="total_amount">${{ totalUsd }}</div>
            </div>

            <div class="item_title">{{ langJson('BlockChain') }}</div>

            <div class="person_chains" v-if="lenArray(chainMapping)">
                <div :class="['chain_item', item.chainId == walletDetail.chainId && 'chain_active']" v-for="(item, index) in chainMapping" :key="index" @click="switchNetwork(item)">
                    <GI :src="item.chainIcon" ws="16" hs="16"></GI>
                    <div class="chain_name">{{ item.networkName }}</div>
                </div>
            </div>

            <NuData v-else height="200"></NuData>

            <div class="item_title">{{ langJson('Assets') }}</div>

            <div class="person_symbols" v-if="lenArray(tokenlist)">
                <div class="symbol_item" v-for="(item, index) in tokenlist" @click="copy(item.contractAddr)" :key="index" :style="index == tokenlist.length - 1 ? { border: 'none' } : {}">
                    <div class="symbol_left">
                        <div class="symbol_icon bgCover" :style="{ backgroundImage: `url(${item.icon})` }"></div>
                        <div class="symbol">
                            <div class="symbol_name">{{ item.name }}</div>
                            <div class="symbol_full">{{ item.contractAddr | hideStr }}</div>
                        </div>
                    </div>
                    <div class="symbol_right">
                        <div class="symbol_balance">{{ item.balance }}</div>
                        <div class="usd_balance">${{ item.usdBalance }}</div>
                    </div>
                </div>
            </div>
            <NuData v-else height="200"></NuData>
        </div>
        <div class="assets_bottom">
            <BTN class="disconnect" type="active" @handle="logout">{{ langJson('Disconnect') }}</BTN>
        </div>
    </div>
</template>
<script>
import { HERC20 } from 'h-token-staking'
let { saveNum, multiplication, division, charCompare } = require('h-token-staking/src/utils')
export default {
    data() {
        return {
            inited: false,
            tokenlist: [],
            totalUsd: 0,
        }
    },
    mounted() {},
    watch: {
        AssetStatus(n) {
            this.inited = true
            if (n) {
                this.tokenlist = this.tokens.map((item) => {
                    item.balance = 0
                    item.usdBalance = 0
                    return item
                })
                this.balanceGet()
            }
        },
        chainId() {
            this.balanceGet()
        },
        wallet(address) {
            this.balanceGet()
            let { dispatch } = this.$store
            if (address) {
                dispatch('userGet', { address })
            }
        },
    },
    computed: {
        chainId() {
            return this.walletDetail.chainId
        },
        wallet() {
            return this.walletDetail.address
        },
        tokens() {
            try {
                let result = []
                let { chainDetail } = this.walletDetail
                if (chainDetail && chainDetail.id) {
                    result = this.globalToken.filter((item) => {
                        return item.chainId == chainDetail.id
                    })
                }
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
        portrait() {
            return this.detail.imageUrl ? this.detail.imageUrl : this.globalImage.userProfilePhoto
        },
        detail() {
            try {
                if (this.userInfo) {
                    return this.userInfo
                } else {
                    return {}
                }
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        classUpdate() {
            if (this.AssetStatus && this.inited) {
                return 'asset_open'
            } else if (this.inited) {
                return 'asset_close'
            }
        },
    },
    created() {},
    methods: {
        goBuy() {
            this.close()
            this.jump_currency_trading()
        },
        logout() {
            this.$store.commit('walletDetail', {})
            if (window.provider && window.provider.close) {
                window.provider.close()
            }
            this.close()
        },
        async balanceGet() {
            try {
                let { provider, accounts } = await this.isProvider('Metamask')
                this.totalUsd = 0
                this.tokenlist = []
                for (let i = 0; i < this.tokens.length; i++) {
                    let { contractAddr, id } = this.tokens[i]
                    let { address } = this.walletDetail
                    // let { balance } = await this.tokenBalance(contractAddr)
                    let ERC20 = new HERC20({
                        provider,
                        accounts,
                        contract: contractAddr,
                    })
                    let balance = await ERC20.balanceOf(address)
                    balance = this.toMain(balance, id)
                    let usdBalance = saveNum(this.usdRate(balance, id))
                    let tokenObj = {
                        ...this.tokens[i],
                        balance: saveNum(balance),
                        usdBalance,
                    }
                    this.totalUsd = this.plus(this.totalUsd, usdBalance)
                    this.pushToken(tokenObj)
                }
            } catch (err) {
                console.log(err)
            }
        },
        pushToken(token) {
            let exit = this.tokenlist.some((item) => {
                return item.id == token.id
            })
            if (!exit) {
                this.tokenlist.push(token)
            } else {
                this.tokenlist = this.tokenlist.map((item) => {
                    if (item.id == token.id) {
                        return token
                    } else {
                        return item
                    }
                })
            }
        },
        close() {
            this.$store.commit('AssetStatus', false)
        },
        async init() {
            try {
                let { dispatch } = this.$store
                await dispatch('userGet', { address: this.walletDetail.address })
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.global_assets {
    .assets_bottom {
        .disconnect {
            // margin-top:24px;
        }
        padding: 16px;
        padding-bottom: 0px;
    }
    .asset_main {
        .person_chains {
            .chain_item {
                padding: 8px 14px;
                // background:#E0F0FF;
                border: 1px solid var(--border_color);
                display: flex;
                align-items: center;
                border-radius: 6px;
                cursor: pointer;
                margin-top: 12px;
                margin-right: 12px;
                max-width: calc(33% - 8px);
                box-sizing: border-box;

                .chain_name {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--font3);
                    margin-left: 6px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .chain_active {
                border: 1px solid var(--main_color);
                .chain_name {
                    color: var(--main_color);
                    font-weight: 800;
                }
            }
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .item_title {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 16px;
            color: var(--font_deep_color);
            margin-top: 24px;
        }
        .person_symbols {
            .symbol_item {
                .symbol_right {
                    .symbol_balance {
                        font-family: 'Cabinet Grotesk';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 14px;
                        color: var(--font_deep_color);
                    }
                    .usd_balance {
                        font-family: 'Cabinet Grotesk';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: var(--font_modif_color);
                        margin-top: 4px;
                    }
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                .symbol_left {
                    .symbol {
                        .symbol_full {
                            font-family: 'Cabinet Grotesk';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: var(--font_modif_color);
                            margin-top: 4px;
                        }
                        .symbol_name {
                            font-family: 'Cabinet Grotesk';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 14px;
                            color: var(--font_deep_color);
                        }
                        margin-left: 10px;
                    }
                    .symbol_icon {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                    display: flex;
                    align-items: center;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                box-sizing: border-box;
                border-bottom: 1px solid var(--border_color);
            }
            margin-top: 12px;
            border: 1px solid var(--border_color);
            border-radius: 10px;
            width: 100%;
        }
        .person_total {
            .buy_btn {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: #fff;
                background: var(--bg11);
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                position: relative;
                transform: translateY(16px);
                cursor: pointer;
            }
            .total_title {
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: var(--font_modif_color);
                /* margin-top: 16px; */
            }
            .total_amount {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-top: 12px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;
            }
            margin-top: 24px;
            border: 1px solid var(--border_color);
            border-radius: 10px;
            min-height: 90px; // 50px 136px
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            width: 100%;
            padding: 16px 0;
            box-sizing: border-box;
        }
        .person_info {
            .info_right {
                .info_address {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    color: var(--font_modif_color);
                    cursor: pointer;
                }
            }
            .info_left {
                .info_name {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    /* line-height: 16px; */
                    color: var(--font_deep_color);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 120px;
                }
                .info_image {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 4px;
                }
                display: flex;
                align-items: center;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        padding: 16px;
        box-sizing: border-box;
        // display: flex;
        // flex-direction:column;
        flex: 1;
        overflow-y: auto;
    }
    .model_top {
        .top_right {
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid var(--border_color);
        padding: 0 16px;
        padding-bottom: 16px;
        box-sizing: border-box;
    }
    background: var(--color_white);
    width: 360px;
    border-radius: 12px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    top: 100px;
    /* z-index: 1000000000; */
    z-index: 30000199;
    box-shadow: 0px 0px 0px transparent;
    transform: translateX(100vw) scale(1);
    opacity: 0;
    padding: 16px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.asset_open {
    animation: openModel 1s forwards;
}
.asset_close {
    animation: closeModel 1s forwards;
}
@keyframes openModel {
    0% {
        opacity: 0;
        box-shadow: 0px 0px 0px transparent;
        transform: translateX(100vw) scale(1);
    }
    100% {
        opacity: 1;
        box-shadow: 2px 2px 20px #888;
        transform: translateX(0px) scale(1);
    }
}
@keyframes closeModel {
    0% {
        opacity: 1;
        box-shadow: 2px 2px 20px #888;
        transform: translateX(0px) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateX(100vw) scale(1);
        box-shadow: 0px 0px 0px transparent;
    }
}

@media screen and (max-width: 1081px) {
    .global_assets {
        width: 100%;
        max-width: 100%;
        top: 0px;
        left: 0;
        right: 0px;
        bottom: 0;
        border-radius: 0;
    }
}
</style>
