<template>
    <div :id="components_id" :class="[showBorder ? 'select_border select' : 'select', globalThemeColor]" @mouseleave="leave">
        <div
            :class="['select_btn',searchHeight?'select_height':'']"
            @click="openPopup"
            :style="{
                width: size ? `${size}px` : `100%`,
            }"
        >
            <!--  height: hs ? `${hs}px` : `100%`, -->
            <!-- 移动端只出现sortBy，不出现currentlabel -->
            <div v-if="isSort" class="btn_text">
                <!-- {{ langJson('Sort by :') }}  -->
                {{ current.lable }}
            </div>
            <div v-else class="btn_text">{{ current.lable }}</div>
            <GI class="icon" name="select_down" ws="24" hs="24" @click="openPopup"></GI>
        </div>
        <div
            class="select_popup"
            :id="popup_id"
            :style="{
                top: `${top}px`,
                width: ws ? `${ws}px` : `auto`,
                right: ws ? `` : `0px`,
                left: `${left}px`,
            }"
            @mouseenter="clearLeave"
        >
            <slot name="top"></slot>
            <div class="popup_list">
                <div :class="tab === item.value ? 'checked_item' : ' popup_item'" v-for="(item, index) in list" :key="index" @click="selectItem(item.value)">
                    <GI v-if="showIcon" :name="tab === item.value ? 'brand_tick_active' : 'brand_tick_deactive'" ws="24" hs="24"></GI>
                    {{ item.lable }}
                </div>
            </div>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
<script>
import move from 'move-js'
export default {
    data() {
        return {
            tab: '',
            top: 0,
            offset: 10,
            timeNum: null,
            popup_id: '',
        }
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        size: {
            type: [Number, String],
            default: 200,
        },
        ws: {
            type: [Number, String],
            default: 0,
        },
        hs: {
            type: [Number, String],
            default: 0,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        showBorder: {
            type: Boolean,
            default: false,
        },
        left: {
            type: [String, Number],
            default: 0,
        },
        isSort: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        searchHeight:{
            type:Boolean,
            default:false
        }
    },
    inject: ['regCode'],
    created() {
        let code = this.regCode()
        this.components_id = 'select_' + code
        code = this.regCode()
        this.popup_id = 'select_popup_' + code
        this.tab = this.value
    },
    watch: {
        value(val) {
            this.tab = val
        },
    },
    mounted() {
        let { height } = document.getElementById(this.components_id).getBoundingClientRect()
        this.top = this.offset + height

        move.defaults = {
            duration: 500,
        }
    },
    computed: {
        getStyle() {
            console.log(this.top)
            return this.ws && this.top
                ? {
                      top: `${this.top}px`,
                      width: `${this.ws}px`,
                  }
                : {
                      top: `${this.top}px`,
                      right: `0px`,
                  }
        },
        current() {
            let result = this.list.find((item) => {
                return item.value === this.tab
            })

            return result ? result : { lable: this.placeholder ? this.placeholder : this.langJson('All'), value: '' }
        },
    },
    methods: {
        selectItem(val = '') {
            this.tab = val
            this.$emit('change', val)
            this.closePopup()
        },
        openPopup() {
            if (this.disabled) return
            let node = document.getElementById(this.popup_id)
            let result = this.SubTH(node)
            result = result > 300 ? 300 : result + 12

            if (document.getElementById(this.components_id)) {
                // 图标旋转
                move(`#${this.components_id} .icon`).rotate(180).end()

                // 打开选项
                move(`#${this.components_id} .select_popup`).set('height', `${result}px`).set('opacity', 1).end()
            }
        },
        leave() {
            if (!this.timeNum) {
                this.timeNum = setTimeout(() => {
                    this.closePopup()
                }, 2000)
            }
        },
        clearLeave() {
            clearTimeout(this.timeNum)
            this.timeNum = null
        },
        closePopup() {
            this.clearLeave()

            if (document.getElementById(this.components_id)) {
                // 图标旋转
                move(`#${this.components_id} .icon`).rotate(0).end()

                // 收起选项
                move(`#${this.components_id} .select_popup`).set('height', `0px`).set('opacity', 0).end()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.select {
    position: relative;

    .select_popup {
        .popup_list {
            padding: 12px;
            padding-top: 0px;
            .popup_item {
                display: flex;
                gap: 8px;
                white-space: nowrap;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_modif_color);
                margin-top: 12px;
                cursor: pointer;
                padding: 8px 12px;
                box-sizing: border-box;
                transition: all 0.5s;
            }
            .checked_item {
                display: flex;
                gap: 8px;
                white-space: nowrap;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--primary_color);
                margin-top: 12px;
                cursor: pointer;
                padding: 8px 12px;
                box-sizing: border-box;
                transition: all 0.5s;
            }
            .popup_item:hover {
                background: var(--color_white);
                border-radius: 32px;
            }
        }
        height: 0px;
        overflow: auto;
        opacity: 0;
        position: absolute;
        background-color: var(--bg_color);
        left: 0;
        z-index: 2000000;
        border-radius: 32px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    }
    .select_btn {
        padding: 0 22px;
        height: 52px;
        /* height: 44px; */
        box-sizing: border-box;
        // border: 1px solid var(--border_color);
        background: var(--bg_color);

        border-radius: 32px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .btn_text {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            color: var(--font_modif_color) !important;
            margin-right: 10px;
            flex: 1;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.select_border {
    box-sizing: border-box;

    /* Auto layout */
    /* Neutral/5 */

    border: 1px solid var(--border_color);
    border-radius: 32px;
    .select_btn {
        background: var(--color_white);
    }
    .select_popup {
        border: 1px solid var(--border_color);
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        background: var(--color_white);
        border-radius: 12px;
    }
}
.theme_dark {
  border: 1px solid #72768B;
  border-radius: var(--nft_select_border_radius);
  .select_btn{
    background: transparent;
  }
  .select_popup{
    background: var(--nft_dialog_bg);
    .popup_item:hover {
        border-radius: var(--nft_select_border_radius) !important;
    }
  }
}
@media only screen and (max-width: 1080px) {
    .select_btn {
        padding: 6px 16px !important;
        height: 52px !important;
        /* height: 36px !important; */
        .btn_text {
            margin-right: 4px !important;
        }
    }
    .select_height{
        height: 36px !important;
    }
}

</style>
