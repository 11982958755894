<template>
  <div class="main">
    <div class="header">
      <h2>{{ langJson('My Contacts') }}</h2>
      <div v-if="!isPhone" class="btns">
        <BTN bw="127" icon_left="brand_trash" type="warn">{{ langJson('Delete') }}</BTN>
        <BTN bw="88" @handle="shareShow" type="detail">{{ langJson('Invite') }}</BTN>
        <BTN bw="180" type="detail" icon_left="clients_upload">{{ langJson('Upload Contacts') }}</BTN>
      </div>
    </div>
    <div v-if="isPhone" class="mobile_btns">
      <BTN bw="136" bh="44" type="detail_mobile ">{{ langJson('Upload Contacts') }}</BTN>
      <div class="btns">
        <div class="more_icon"><GI ws="24" hs="24" name="brand_trash_mobile"></GI></div>
        <BtnPop :left="isPhone ? -90 : 0">
          <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
          <div slot="target" class="more_icon"><GI ws="24" hs="24" name="brand_more"></GI></div>
          <div slot="main" class="type_main">
            <div v-for="(item, index) in tagList" :key="index" class="type_content">
              <div @click="goRoute(item)" class="type_line">
                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                <span class="title">{{ item.val }}</span>
              </div>
            </div>
          </div>
        </BtnPop>
      </div>
    </div>
    <div v-if="!isPhone" class="client_table">
      <TableforClients :commonStyle="true" :selectList="selectList" :list="list" :title="title" :play="play">
        <div class="more" slot="foot">
          <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="true"></Page>
        </div>
      </TableforClients>
    </div>
    <div v-if="isPhone" class="client_table_mobile">
      <TableforClientsMobile :selectList="selectList" :checkName="checkName" :checkNameVal="checkNameVal" :list="list" :title="title" :play="play">
        <div class="more" slot="foot">
          <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="true"></Page>
        </div>
      </TableforClientsMobile>
    </div>

    <editShare v-model="showShare" @changeShow="changeShow" :url="getlink" image="https://w.wallhaven.cc/full/1p/wallhaven-1p8dxg.jpg"></editShare>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getlink: '',
      showShare: false,
      tagList: [
        { icon_dark: 'edit_invite', icon_light: 'edit_invite_light', val: 'Invite', method: 'invite', isChecked: false, number: 1 },
        { icon_dark: 'brand_trash', icon_light: 'brand_trash_light', val: 'Delete', method: 'delete', isChecked: false, number: 4 },
      ],
      checkName: 'Fullname',
      checkNameVal: 'ContactNo',
      list: [
        {
          No: '1 ',
          Fullname: 'Phifer Hall',
          ContactNo: '1233344567',
          Email: 'example@gmail.com',
          Invitationssent: '123',
          Votes: '12',
          show: false,
          active: false,
        },
        {
          No: '2 ',
          Fullname: 'Phifer Hall',
          ContactNo: '1233344567',
          Email: 'example@gmail.com',
          Invitationssent: '123',
          Votes: '12',
          show: false,
          active: false,
        },
        {
          No: '3 ',
          Fullname: 'Phifer Hall',
          ContactNo: '1233344567',
          Email: 'example@gmail.com',
          Invitationssent: '123',
          Votes: '12',
          show: false,
          active: false,
        },
      ],
      title: {
        No: {
          title: 'No.',
          type: 'text',
        },
        Fullname: {
          title: 'Full name',
          type: 'text',
        },
        ContactNo: {
          title: 'Contact No.',
          type: 'text',
        },
        Email: {
          title: 'Email',
          type: 'text',
        },
        Invitationssent: {
          title: 'Invitations sent',
          type: 'text',
        },
        Votes: {
          title: 'Votes',
          type: 'text',
        },
      },
      play: [{ type: 'detail', event: 'detail', title: 'Delete contact' }],
      selectList: [
        {
          lable: this.langJson('All'),
          value: 1,
        },
        {
          lable: this.langJson('Recently created'),
          value: 2,
        },
        {
          lable: this.langJson('Most Favorited'),
          value: 3,
        },
        {
          lable: this.langJson('Price:  low to high'),
          value: 4,
        },
        {
          lable: this.langJson('Price:  high to low'),
          value: 5,
        },
        {
          lable: this.langJson('Recently launched'),
          value: 6,
        },
        {
          lable: this.langJson('Most recommended'),
          value: 7,
        },
        {
          lable: this.langJson('Recent transactions'),
          value: 8,
        },
        {
          lable: this.langJson('Ending soon'),
          value: 9,
        },
      ],
    }
  },
  mounted() {},
  methods: {
    shareShow() {
      this.showShare = true
    },
    goRoute(item){
      // console.log(item);
      switch (item.number) {
        case 1:
           this.shareShow()
          break;
        case 4:
          
          break;
      
        default:
          break;
      }
    },
    changeShow(val) {
      this.showShare = val
    },
  },
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.header {
  max-width: var(--max_width);
  width: 100%;
  height: 106px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-family: 'Cabinet Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: var(--font_deep_color);
  }
  .btns {
    display: flex;
    gap: 16px;
  }
}
.mobile_btns {
  max-width: var(--max_width);
  width: 100%;
  margin: auto;
  margin-top: 26px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btns {
    display: flex;
    gap: 16px;
    .more_icon {
      box-sizing: border-box;
      width: 44px;
      height: 44px;
      background: var(--color_white);
      border: 1px solid var(--border_color);
      border-radius: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .type_main {
      padding: 24px;
      background: var(--color_white);
      border: 1px solid var(--border_color);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
      border-radius: 24px;
      cursor: pointer;

      .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child:hover {
          color: var(--danger_color);
          transition: all 1s;
        }

        .type_line {
          display: flex;
          align-items: center;
          gap: 8px;

          .title {
            white-space: nowrap;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: var(--font_deep_color);
          }
        }
      }
    }
  }
}
.client_table {
  max-width: var(--max_width);
  width: 100%;
  margin: auto;
  padding: 23px 32px;
  background-color: var(--color_white);
  box-sizing: border-box;
  border-radius: 32px;
  .more {
    margin-top: 24px;
  }
}
.client_table_mobile {
  max-width: var(--max_width);
  width: 100%;
  margin: auto;
  border-radius: 32px;
  .more {
    margin-top: 11px;
  }
}
</style>
