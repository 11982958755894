import { render, staticRenderFns } from "./CompaignItem.vue?vue&type=template&id=041ee30d&scoped=true"
import script from "./CompaignItem.vue?vue&type=script&lang=js"
export * from "./CompaignItem.vue?vue&type=script&lang=js"
import style0 from "./CompaignItem.vue?vue&type=style&index=0&id=041ee30d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041ee30d",
  null
  
)

export default component.exports