<template>
    <div class="descript_banner" :style="{ 'background-image': `url(${detail.brandDescriptionImg})` }">
        <!-- brandDescriptionImg -->
        <div class="script_text">
            <!-- 缺少品牌方 信息 brandUserVo-->
            <!-- <h2 class="script_title">
                {{ langJson('BRAND DESCRIPTION') }}
            </h2> -->
            <h1 v-html="brandUserVo.nickName" class="v-html script_idea"></h1>
            <p v-html="detail.brandDescription" class="v-html"></p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {}
    },
    computed: {
        // 商户信息
        brandUserVo() {
            try {
                return this.detail.brandUserVo ? this.detail.brandUserVo : {}
            } catch (error) {
                return {}
            }
        },
    },
    mounted() {},
    methods: {},
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.descript_banner {
    margin-top: 40px;
    box-sizing: border-box;
    width: 100%;
    height: 602px;
    /* background-image: url('../../../../assets/image/brand/brand_descript.png'); */
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    padding-top: 189px;
    .script_text {
        width: 623px;
        height: 268px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
    .script_title {
        background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .script_idea {
        text-align: center;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #ffffff;

        width: 90vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap !important;
    }
    p {
        font-weight: 400;
        line-height: 24px;
        color: #c9ccdd;
        text-align: center;
    }
}

@media screen and (max-width: 1080px) {
    .descript_banner {
        align-items: flex-start;
        padding-top: 105px;
    }
    .script_text {
        height: 0px !important;
    }
    p {
        width: auto !important;
        padding-right: 20px;
        padding-left: 20px;
    }
}
</style>
