export default {
    back(val = -1) {
        this.$router.go(val)
    },
    jump_page_path(path, query = {}, backTop = true, replace = false) {
        let replace2 = sessionStorage.getItem('noSaveHistory')
        // console.log('不保留历史1', replace2)
        if (replace || replace2) {
            // console.log('不保留历史1')
            this.$router.replace({ path: path, query: query })
        } else {
            this.$router.push({ path: path, query: query })
        }
        if (backTop) {
            document.getElementById('view_main').scrollTop = 0
        }
    },
    jump_root() {
        this.jump_page_path('/')
    },
    jump_editor(query) {
        console.log(query)
        this.jump_page_path('/editor', query)
    },
    jump_market_explore() {
        this.jump_page_path('/market/explore')
    },
    jump_myster_explore() {
        this.jump_page_path('/myster/explore')
    },
    jump_auction_explore() {
        this.jump_page_path('/auction/explore')
    },
    jump_staking_explore() {
        this.jump_page_path('/staking/explore')
    },
    jump_market_detail(id) {
        this.jump_page_path(`/market/detail/${id}`)
    },
    jump_myster_detail(id) {
        this.jump_page_path(`/myster/detail/${id}`)
    },
    jump_auction_detail(id) {
        this.jump_page_path(`/auction/detail/${id}`)
    },
    jump_staking_detail(id) {
        this.jump_page_path(`/staking/detail/${id}`)
    },
    jump_startup() {
        this.jump_page_path('/startup/index')
    },
    jump_unfind() {
        this.jump_page_path('/404')
    },
    jump_mint() {
        this.jump_page_path('/mint/index')
    },
    jump_participant_login() {
        this.jump_page_path('/participant/login')
    },
    jump_participant_signup() {
        this.jump_page_path('/participant/signup')
    },

    jump_participant_resetpsd() {
        this.jump_page_path('/participant/resetpsd')
    },
    jump_participant_compaign() {
        this.jump_page_path('/participant/campaign/index')
    },
    jump_brand_create_compaign() {
        this.jump_page_path('/brand/createCampaign/createOverview')
    },
    jump_brand_compaign() {
        this.jump_page_path('/brand/campaign/index')
    },
    jump_participant_compaign_preview(query) {
        this.jump_page_path(`/participant/campaign/preview`, query)
    },
    jump_participant_compaign_ParticipantsNFTs(query) {
        this.jump_page_path('/participant/campaign/ParticipantsNFTs', query)
    },

    jump_collection_belong() {
        this.jump_page_path('/collection/belong')
    },
    jump_collection_update(id) {
        this.jump_page_path(`/collection/update/${id}`)
    },
    jump_collection_new() {
        this.jump_page_path('/collection/new')
    },
    jump_collection(id, tab = '') {
        tab += ''
        if (/^\d+$/.test(tab) && tab) {
            this.jump_page_path(`/collection/index/${id}/${tab}`, false)
        } else {
            this.jump_page_path(`/collection/index/${id}`)
        }
    },
    jump_personal(address, tab = '') {
        tab += ''
        if (/^\d+$/.test(tab) && tab) {
            this.jump_page_path(`/personal/index/${address}/${tab}`, false)
        } else {
            this.jump_page_path(`/personal/index/${address}`)
        }
    },
    jump_setting(tab = '') {
        tab += ''
        if (/^\d+$/.test(tab) && tab) {
            this.jump_page_path(`/personal/setting/${tab}`)
        } else {
            this.jump_page_path('/personal/setting')
        }
    },

    jump_participant_campaign() {
        this.jump_page_path('/participant/campaign/index')
    },
    jump_participant_campaign_artwork_submit(id = 0) {
        this.jump_page_path(`/participant/campaign/artwork/submit/${id}`)
    },
    jump_participant_mint() {
        this.jump_page_path('/participant/mint/index')
    },

    jump_participant_campaign_participants_profile(query, backTop) {
        this.jump_page_path('/participant/campaign/participants/profile', query, backTop)
    },
    jump_participant_campaign_participants_profileColl(query, backTop) {
        this.jump_page_path('/participant/campaign/participants/profileColl', query, backTop)
    },
    jump_participant_campaign_artwork_detail(query) {
        this.jump_page_path('/participant/campaign/artwork/detail', query)
    },
    jump_participant_campaign_artwork_joinCampaign(query) {
        this.jump_page_path(`/participant/campaign/artwork/joinCampaign`, query)
    },
    jump_participant_profile_myprofile(query, backTop) {
        this.jump_page_path('/participant/profile/myprofile', query, backTop)
    },

    jump_participant_profile_ntfdetail(query) {
        this.jump_page_path('/participant/profile/ntfDetail', query)
    },
    jump_participant_profile_editProfile(query) {
        this.jump_page_path('/participant/profile/editProfile', query)
    },
}
