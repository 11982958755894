<template>
    <Model :showHeader="false" :padding="'24px 28px'" v-model="show">
        <template v-slot:main>
            <div class="dialog_main">
                <h1>{{ langJson('Can not vote this Artwork') }}</h1>
                <span>{{ langJson('You have voted for this Artwork before') }}</span>
                <BTN @handle="voteConfirm" type="active">{{ langJson('OK') }}</BTN>
            </div>
        </template>
    </Model>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {},

    data() {
        return {
            show: false,
            url: '',
        }
    },
    mounted() {},

    computed: {
        ...mapState({ showShare: (state) => state.config.showVote }),
        // ...mapState('config', ['showShare']),
    },
    watch: {
        showShare(val) {
            this.show = val
        },
        show(val) {
            this.$store.commit('showVote', val)
        },
    },
    methods: {
        voteConfirm() {
            this.$store.commit('showVote', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.dialog_main {
    width: 422px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    h1 {
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: var(--font_deep_color);
    }
    span {
        text-align: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--font_deep_color);
    }
}

@media screen and (max-width: 500px) {
    .dialog_main {
        width: 74.4vw !important;
    }
}
</style>
