<template>
    <div>
        <div>
            <StepRun ref="step">
                <div class="form" slot="tab1">
                    <div class="header">
                        <h2>{{ headView.create == true ? langJson('Personal information') : langJson('Brand information') }}</h2>
                    </div>
                    <div class="form_box">
                        <div class="form_box_upload">
                            <FormItem v-model="form.imageUrl" :title="langJson('Profile picture')" 
                            :uploadMsg="langJson('Recommended ratio: 1 × 1')"
                            class="information_item" type="image" :image="bannerConfig" 
                            :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')"></FormItem>
                        </div>
                        <div class="form_input">
                            <FormItem v-model="form.bannerUrl" :title="langJson('Introduction cover')" 
                            :uploadMsg="langJson('Recommended ratio: 4 × 1')"
                            class="information_item" type="image" :image="bannerConfig" 
                            :uploadTip="langJson('(Acceptable file format: JPEG, JPG, PNG.)')"></FormItem>
                        </div>
                        <div class="line"></div>
                        <div class="form_input">
                            <FormItem v-model="form.nickName" class="information_item" :placeholder="langJson('Enter one user name')" type="text" :title="langJson('Name')"></FormItem>
                        </div>
                        <div class="form_input">
                            <FormItem v-model="form.bio" class="information_item" :placeholder="langJson('Tell others about yourself')" type="text" :title="langJson('Bio')"></FormItem>
                        </div>
                        <div class="form_input">
                            <FormItem :matchs="['email']" v-model="form.email" :disabled="true" class="information_item" :placeholder="langJson('Please enter your email address')" type="text" :title="langJson('Email')">
                                <BTN type="active" size="mini" bh="32" @handle="showChangeEmail = true" :disable="!pass" slot="text_append" fontSize="13">{{ langJson('Change email') }}</BTN>
                            </FormItem>
                        </div>
                        <div class="form_input">
                            <BTN type="active" bh="52" @handle="editInfo()" :disable="!pass">{{ langJson('Save change') }}</BTN>
                            <BTN type="detail" bh="52" @handle="next()">{{ langJson('Change password') }}</BTN>
                        </div>
                    </div>
                </div>
                <div class="form_psd" slot="tab2">
                    <div class="header" @click="prev()">
                        <GI name="global_previous" ws="24" hs="15"></GI>
                        <h2>{{ langJson('Change password') }}</h2>
                    </div>
                    <div class="form_box" v-if="rendering">
                        <div class="form_input">
                            <FormItem v-model="form2.oldPassword" class="information_item" type="password" :title="langJson('Current password')" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']"></FormItem>
                        </div>
                        <div class="form_input">
                            <FormItem v-model="form2.newPassword" class="information_item" type="password" :title="langJson('New password')" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']"></FormItem>
                        </div>
                        <div class="form_input">
                            <FormItem v-model="form2.newPasswordSure" class="information_item" type="password" :title="langJson('Confirm new password')" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']"></FormItem>
                        </div>
                        <div class="form_input">
                            <BTN v-if="!isPhone" :disable="!pass2" bw="373" type="active" @handle="editPass()">{{ langJson('Change password') }}</BTN>
                            <BTN v-if="isPhone" :disable="!pass2" type="active" @handle="editPass()">{{ langJson('Change password') }}</BTN>
                        </div>
                    </div>
                </div>
            </StepRun>
        </div>

        <!-- 对话框使用模板 setInterval -->
        <Model :padding="'24px'" v-model="showChangeEmail">
            <div slot="main">
                <StepRun ref="step2">
                    <ChangeEmail slot="step1" @next="next2" />
                    <ChangeEmail2 slot="step2" :verifyCode="verifyCode" @close="showChangeEmail = false" />
                </StepRun>
            </div>
        </Model>
    </div>
</template>

<script>
export default {
    data() {
        return {
            verifyCode: '',
            showChangeEmail: false,
            rendering: true,

            form: {
                imageUrl: '',
                bannerUrl: '',
                nickName: '',
                bio: '',
                email: '',
            },
            form2: {
                oldPassword: '',
                newPassword: '',
                newPasswordSure: '',
            },

            bannerConfig: {
                type: 'big',
            },
            list: [
                {
                    lable: this.langJson('USD'),
                    value: '',
                },
            ],
        }
    },
    computed: {
        pass2() {
            try {
                let { newPasswordSure, newPassword, oldPassword } = this.form2
                return this.checkPass(oldPassword, ['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop'], false) && this.checkPass(newPassword, ['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop'], false) && this.checkPass(newPasswordSure, ['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop'], false)
            } catch (err) {
                console.log(err)
                return false
            }
        },
        pass() {
            try {
                return this.checkPass(this.form.email, ['email'], false)
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    created() {
        this.init()
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        next() {
            this.$refs.step.next()
        },
        next2(verifyCode) {
            this.verifyCode = verifyCode
            this.$refs.step2.next()
        },
        async editPass() {
            if (this.form2.newPassword != this.form2.newPasswordSure) {
                this.pushMsg('error', this.langJson('The new password entered twice does not match'))
                return
            }
            // 修改用户密码
            let { dispatch } = this.$store
            let res = await dispatch('updatePwd', this.form2)
            if (res && res.code == 200) {
                this.pushMsg('success', this.langJson('Edit Success'))
                this.prev()
                this.$set(this.form2, 'oldPassword', '')
                this.$set(this.form2, 'newPassword', '')
                this.$set(this.form2, 'newPasswordSure', '')
                this.rendering = false
                this.$nextTick(() => {
                    this.rendering = true
                })
            }
        },
        async editInfo() {
            // 修改个人中心
            let { dispatch } = this.$store
            let res = await dispatch('updateInfo', this.form)
            if (res && res.code == 200) {
                this.pushMsg('success', this.langJson('Edit success'))
                this.init()
            }
        },
        prev() {
            this.$refs.step.prev()
        },
        backPrev() {
            if (this.$refs.step.step > 0) {
                this.prev()
            } else {
                this.back()
            }
        },
        async init() {
            try {
                let { dispatch } = this.$store
                await dispatch('userGet')
                Object.assign(this.form, this.userInfo)
                this.$forceUpdate()
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.form {
    box-sizing: border-box;
    max-width: var(--max_form_width);
    margin: auto;
    width: 100%;
    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 156px;
    .header {
        margin-bottom: 32px;
        h2 {
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
    }
    .form_box {
        gap: 40px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);

        .information_item {
            width: 100%;
        }
        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
        }
        .form_box_upload {
            max-width: 403px;
            width: 100%;
        }
        .form_input {
            display: flex;
            width: 100%;
            gap: 40px;
        }
        .line {
            width: 100%;
            border-bottom: 1px solid var(--border_color);
        }
    }
}
.form_psd {
    box-sizing: border-box;
    width: 733px;

    background: var(--color_white);
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 32px;
    .header {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 48px;
            color: var(--font_deep_color);
        }
    }

    .form_box {
        gap: 40px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 32px;
        background: var(--color_white);
        margin-top: 32px;
    }
}
.btns {
    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .next_step {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 65px;
        span {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
        }
    }
}
@media only screen and (max-width: 1080px) {
    .form {
        margin: 0px;
        max-width: 100%;
        padding: 20px !important;
        .header {
            h2 {
                font-size: 32px !important;
            }
        }
    }
    .form_input {
        flex-direction: column;
        gap: 16px !important;
    }
    .form_psd {
        width: auto;
        padding: 20px;
    }
}
</style>
