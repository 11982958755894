<template>
    <div class="setting">
        <div id="top"></div>
        <Go :prev="langJson('Back to overview')" @prev="back">
            <div v-if="!isPhone" class="right_go">
                <!-- <BTN icon_left="brand_trash" type="warn" bh="52" bw="198" v-if="showBtn">{{ langJson('Delete campaign') }}</BTN> -->
                <BTN icon_left="eye" type="detail" @handle="jump_brand_compaign_preview($route.query)" v-if="showBtn" bh="52" bw="131">{{ langJson('Preview') }}</BTN>
                <BTN v-if="from == 'edit'" :disable="!pass" @handle="saveChanges" type="active" bh="52" bw="138">{{ langJson('Save changes') }}</BTN>

                <BTN v-if="from == 'create'" :disable="!pass" @handle="saveChanges" type="active" bh="52" bw="209">{{ langJson('Submit for review') }}</BTN>
                <BTN v-if="from == 'create'" :load="save_load" @handle="saveDraft" type="active" bh="52" bw="209">{{ langJson('Save Draft') }}</BTN>
            </div>
            <div v-if="isPhone" class="right_go">
                <BtnPop :left="-180">
                    <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                    <div slot="target" class="more_icon"><GI ws="24" hs="24" name="brand_more"></GI></div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                                <span class="title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
            </div>
        </Go>
        <div class="setting_center">
            <div v-if="!isPhone" class="tab_box">
                <div class="tabs">
                    <div :class="[tab === item.value && 'border_tab']" v-for="(item, index) in tablist" :key="index" @click="switchTab(item.value)" class="tab_inner">
                        <div class="tab_item">
                            <GI :name="tab === item.value ? item.iconActive : item.icon" ws="24" hs="24"></GI>
                            <div class="tab_title">{{ item.label }}</div>
                        </div>
                    </div>
                    <div class="tab_bottom"></div>
                </div>
            </div>
            <div v-if="loadData" class="main">
                <StepRun ref="tab">
                    <Basics slot="tab1" @updateName="syncName" :screenValue="screenValue"></Basics>
                    <Rewards slot="tab2" :screenValue="screenValue"></Rewards>
                    <Link slot="tab3"></Link>
                    <Sticker slot="tab4"></Sticker>
                    <Treaty slot="tab5" @saveDraft="saveDraft"></Treaty>
                    <Promotion :from="from" slot="tab6"></Promotion>
                </StepRun>
            </div>
        </div>
        <Model :title="from == 'edit' ? (isPhone ? langJson('Enter your password') : langJson('Enter your password to update your campaign')) : isPhone ? langJson('Enter your password') : langJson('Enter your password to submit your campaign')" :title2="isPhone ? (from == 'edit' ? langJson('to update your campaign') : langJson('to submit your campaign')) : ''" :padding="'24px 28px'" :headerGap="isPhone ? '36px' : '82px'" v-model="saveModel">
            <div class="notify_main" slot="main">
                <div class="notify_middle notify_common">
                    <FormItem v-model="password" type="password" :matchs="['unull']" :title="langJson('Password')"></FormItem>
                </div>
                <div>
                    <span class="tips">
                        {{ langJson('NFTWerks will process your campaign application and check to ensure it follows our Terms & Conditions. Please allow us up to 48 hours for a response. Please also note that NFTWerks may reach out to you for pricing, where applicable.') }}
                        <!-- <br v-if="!isPhone" />
                        {{ langJson('Please allow 24h for a response.') }} -->
                    </span>
                </div>
                <div class="notify_btns notify_common">
                    <BTN type="detail" class="btn1" :bh="isPhone ? '48' : '52'" @handle="cancelNotify">{{ langJson('Cancel') }}</BTN>
                    <!-- 需要判断 -->
                    <BTN type="active" class="btn2" :disable="!password" :load="load_btn" :bh="isPhone ? '48' : '52'" @handle="handleChange">{{ langJson('Send') }}</BTN>
                </div>
            </div>
        </Model>

        <DraftTip v-model="draftModel" @confirm="getDraft"></DraftTip>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab: 1,
            from: this.$route.query.from,
            saveModel: false,
            password: '',
            loadData: false,
            tagList: [
                { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'Preview', number: 1 },
                { icon_dark: 'clients_upload', icon_light: 'clients_upload', val: 'Submit for Preview', number: 2 },
                { icon_dark: 'clients_upload', icon_light: 'clients_upload', val: 'Save Draft', number: 4 },
                // { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete Compaign', number: 3 },
            ],
            showBtn: true,
            screenValue: null,
            save_load: false,
            draftModel: false,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            // 窗口大小发生变化时的处理逻辑
            this.screenValue = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        })
        this.haveDraft()
    },
    computed: {
        tablist() {
            let result = [
                {
                    label: this.langJson('Basics'),
                    value: 1,
                    icon: 'edit_basics_active',
                    iconActive: 'edit_basics_active',
                },
                {
                    label: this.langJson('Rewards'),
                    value: 2,
                    icon: 'edit_rewards_active',
                    iconActive: 'edit_rewards_active',
                },
                {
                    label: this.langJson('Link'),
                    value: 3,
                    icon: 'edit_link_active',
                    iconActive: 'edit_link_active',
                },
                {
                    label: this.langJson('Sticker library'),
                    value: 4,
                    icon: 'edit_stick_active',
                    iconActive: 'edit_stick_active',
                },
                {
                    label: this.langJson('Smart Contract Deployer'),
                    value: 5,
                    icon: 'edit_treaty_active',
                    iconActive: 'edit_treaty_active',
                },
                {
                    label: this.langJson('Promotion'),
                    value: 6,
                    icon: 'edit_promotion_active',
                    iconActive: 'edit_promotion_active',
                },
            ]

            if (this.from == 'create') {
                let index = result.findIndex((e) => e.label === 'Promotion')
                result.splice(index, 1)
                // result.pop()
            }
            return result
        },
        pass() {
            let { name, subtitle, descriptionImg, description, brandDescriptionImg, brandDescription, rules, campaignAwards, campaignContract, appBanner } = this.compaignDetail
            let result = this.checkPass(name, ['unull', 'maxLength'], false)
            result = result && this.checkPass(subtitle, ['unull', 'maxLength'], false)
            result = result && this.checkPass(descriptionImg, ['unull'], false)
            result = result && this.checkPass(appBanner, ['unull'], false)
            result = result && this.checkPass(description, ['unull'], false)
            result = result && this.checkPass(brandDescriptionImg, ['unull'], false)
            result = result && this.checkPass(brandDescription, ['unull'], false)
            result = result && this.checkPass(rules, ['unull'], false)

            // 整个奖励可不填
            // let reward =
            //     campaignAwards &&
            //     campaignAwards.every((item) => {
            //         let temp = this.checkPass(item.rewardTitle, ['unull', 'maxLength'], false)
            //         temp = temp && this.checkPass(item.rewardDescription, ['unull'], false)
            //         temp = temp && this.checkPass(item.rewardAmount, ['unull', 'float', 'threeNumber'], false)
            //         temp = temp && this.checkPass(item.rewardBrandDescription, ['unull'], false)
            //         return temp
            //     })
            if (this.from == 'create') {
                if (campaignContract) {
                    result = result && this.checkPass(campaignContract.name, ['unull', 'maxLength'], false)
                    result = result && this.checkPass(campaignContract.abbreviation, ['unull', 'maxLength'], false)
                    result = result && this.checkPass(campaignContract.contractAddress, ['unull'], false)
                } else {
                    return false
                }
            }
            return result
            // return result && reward // 整个奖励可不填
        },
    },
    watch: {
        password(pwd) {
            this.$store.commit('compaignDetail', {
                ...this.compaignDetail,
                password: pwd,
            })
        },
    },
    created() {
        if (this.$route.query.id) {
            this.detailGet()
        } else {
            this.loadData = true
        }
        setTimeout(() => {
            if (this.$route.query.tab) {
                this.tab = parseInt(this.$route.query.tab)
                this.switchTab(this.tab)
            }
        }, 100)
        this.showBtn = false
        if (this.$route.query.from != 'create') {
            this.showBtn = true
        } else {
            let index = this.tagList.findIndex((e) => e.val === 'Preview')
            this.tagList.splice(index, 1)
        }
        this.screenValue = document.documentElement.clientWidth || document.body.clientWidth

        // this.getDraft()
    },
    provide() {
        return {
            switchTab: this.switchTab,
        }
    },
    methods: {
        async clearDraft() {
            let { dispatch } = this.$store
            await dispatch('draftClear')
        },
        async haveDraft() {
            try {
                if (!this.$route.query.id) {
                    let { dispatch } = this.$store
                    let result = await dispatch('getDraft')
                    if (result && result.code == 200) {
                        let draft = result.data
                        if (draft) {
                            this.draftModel = true
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },
        async getDraft() {
            this.loadData = false
            try {
                if (!this.$route.query.id) {
                    let { dispatch } = this.$store
                    let result = await dispatch('getDraft')
                    if (result && result.code == 200) {
                        let draft = result.data
                        if (draft) {
                            await this.$store.commit('compaignDetail', draft)
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
            this.loadData = true

            this.$nextTick(() => {
                if (this.$route.query.tab) {
                    this.tab = parseInt(this.$route.query.tab)
                    this.switchTab(this.tab)
                }
            })
        },
        async saveDraft() {
            this.save_load = true
            try {
                let { dispatch } = this.$store
                let result = await dispatch('draftSave', this.compaignDetail)
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Successfully saved'))
                } else {
                    this.pushMsg('warning', result.msg)
                }
            } catch (err) {
                console.log(this.compaignDetail)
                console.log(err)
                this.pushMsg('error', this.langJson('Save failed'))
            }
            this.save_load = false
        },

        syncName(name) {
            this.$nextTick(() => {
                try {
                    this.$refs.tab.$children[4].updateName(name)
                } catch (err) {}
            })
        },
        async handleChange() {
            if (this.$route.query.id) {
                await this.updateCampaign()
            } else {
                await this.pushCampaign()
            }
        },

        async detailGet() {
            try {
                let { dispatch } = this.$store
                await dispatch('compaignDetail', {
                    id: this.$route.query.id,
                })
                console.log(this.compaignDetail)
            } catch (err) {
                console.log(err)
            }
            this.loadData = true
        },
        async pushCampaign() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store

                let result = await dispatch('newCompaign', this.compaignDetail)
                if (result && result.code == 200) {
                    this.$store.commit('compaignDetail', {})
                    this.clearDraft()
                    this.jump_brand_compaign()
                }
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
        async updateCampaign() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let { status } = this.compaignDetail
                let result

                if (status == 1) {
                    result = await dispatch('updateApply', this.compaignDetail)
                } else {
                    result = await dispatch('updateCampaign', this.compaignDetail)
                }
                // updateApply
                if (result && result.code == 200) {
                    this.jump_brand_compaign()
                    this.pushMsg('success', this.langJson('Submitted successfully'))
                }
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },

        tabHandle(val) {
            this.$route.query.from != 'create' && this.switchTab(val)
        },

        switchTab(val) {
            this.tab = val
            this.tablist.find((item, index) => {
                if (item.value == val) {
                    this.$refs.tab?.jump(index)
                    this.handleScroll('top')
                    return true
                } else {
                    return false
                }
            })
            // this.jump_setting(val)
        },
        saveChanges() {
            this.saveModel = true
        },
        closeNotify() {
            this.saveModel = false
        },
        cancelNotify() {
            this.saveModel = false
        },
        goRoute(item) {
            console.log(item)
            switch (item.number) {
                case 1:
                    this.jump_brand_compaign_preview(this.$route.query)
                    break
                case 2:
                    if (!this.pass) {
                        this.pushMsg('warning', this.langJson('The data is incomplete and cannot be submitted'))
                        return
                    }
                    this.saveChanges()
                    break
                case 4:
                    this.saveDraft()
                    break

                default:
                    break
            }
        },
        handleScroll(id) {
            var element = document.getElementById(id)
            element.scrollIntoView() // 参数 false 代表 Bottom
        },
    },
    destroyed() {
        this.$store.commit('compaignDetail', {})
    },
}
</script>
<style lang="scss" scoped>
.setting {
    .setting_center {
        .tab_box {
            .tabs {
                display: flex;
                flex-direction: column;
                gap: 24px;
                .tab_bottom {
                    height: 1px;
                }
                .tab_item {
                    .tab_title {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_modif_color);
                        margin-left: 8px;
                    }
                    display: flex;
                    height: 52px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    position: relative;
                    cursor: pointer;
                }
                .tab_inner {
                    padding-left: 24px;
                    border: 2px solid transparent;
                    border-radius: 32px;
                }
                .border_tab {
                    border: 2px solid transparent;
                    border-radius: 32px;
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    background-image: linear-gradient(to right, var(--color_white), var(--color_white)), linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    padding-left: 24px;
                    .tab_title {
                        background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        // color: var(--main_color);
                        font-weight: 700;
                    }
                }
                width: 100%;
                background: var(--color_white);
                padding: 24px;
                padding-bottom: 0px;
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 32px;
                box-sizing: border-box;
            }
            min-width: 320px;
        }

        .main {
            background: var(--bg_color);
            border-radius: 32px;
            flex: 1;
            min-height: 500px;
            box-sizing: border-box;
        }
        display: flex;
        align-content: flex-start;
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 32px;
        gap: 32px;
    }
}
.right_go {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}
.notify_main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    width: 557px;
    .notify_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 82px;
        h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
        }
    }
    .tips {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--font_color);
    }
    .notify_btns {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
    }
}
.more_icon {
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;

    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child {
            // color: var(--danger_color);
        }
        &:not(:last-child) {
            // color: var(--font_deep_color);
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
@media only screen and (max-width: 1080px) {
    /* 上面padding 25-12 = 13 */
    .setting_center {
        margin-top: 13px !important;
    }
    .setting {
        overflow: hidden;
    }
    .notify_main {
    }
    .notify_btns {
        row-gap: 20px;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-template-areas: 'btn1' 'btn2';
        .btn1 {
            grid-area: btn2;
        }
        .btn2 {
            grid-area: btn1;
        }
    }
    .notify_top {
        gap: 36px !important;
        align-items: center;
        h2 {
            font-weight: 700;
            font-size: 18px !important;

            line-height: 28px;
        }
    }
}
@media screen and (max-width: 500px) {
    .notify_main {
        width: 74.4vw;
    }
}
</style>
