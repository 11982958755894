<template>
    <Model v-model="showShare" :title="isPhone ? langJson('Would you like to send invitation') : isParticipant ? langJson('Would you like to send invitation emails to your contacs') : langJson('Would you like to send invitation emails to your clients')" :title2="isPhone ? (isParticipant ? langJson('emails to your contacs') : langJson('emails to your clients')) : ''" :padding="'24px'" :titleSize="isPhone ? '16px' : '18px'" :titleHeight="'28px'">
        <div class="share_main" slot="main">
            <div class="share_description">
                <div class="total_clients">
                    <span>
                        <template v-if="isParticipant">
                            {{ langJson('Total of contacts') }}
                        </template>
                        <template v-else>
                            {{ langJson('Total of clients') }}
                        </template>
                    </span>
                    <h2>{{ list.length }}</h2>
                </div>
                <div class="choose_campaign">
                    <div class="title">
                        <template v-if="isParticipant">
                            {{ langJson('Choose your joined campaign') }}
                        </template>
                        <template v-else>
                            {{ langJson('Choose campaign') }}
                        </template>
                        <span class="required">*</span>
                    </div>
                    <Sel v-if="this.compaign" :isSort="false" :list="typelist" :placeholder="langJson('Please select')" :size="0" v-model="compaign"></Sel>
                </div>
            </div>
            <div class="share_image" :style="{ backgroundImage: `url(${detail.banner})` }">
                <img :src="qrcode" alt="" class="qr_code" />
                <div class="compaign_detail">
                    <div class="detail_box">
                        <h2>{{ remainTime(detail.endTime).days }}</h2>
                        <div>
                            <span>{{ langJson('days to go') }}</span>
                        </div>
                    </div>
                    <div class="detail_box">
                        <h2>{{ detail.joinNum }}</h2>
                        <div>
                            <span>{{ langJson('participants') }}</span>
                        </div>
                    </div>

                    <div class="detail_box">
                        <h2>{{ detail.workNum }}</h2>
                        <div>
                            <span>{{ langJson('artworks') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="share_copy" class="share_link" @click="copy(urlShare)">
                <div class="link_left">
                    <div class="link_title">{{ langJson('Share link') }}</div>
                    <div class="link_text">{{ urlShare }}</div>
                </div>
                <div class="link_right">
                    <BTN type="detail" bw="70" bh="40" :fontSize="isPhone ? '16' : ''" :borderWidth="isPhone ? '1' : ''">{{ langJson('Copy') }}</BTN>
                </div>
            </div>
            <div class="btns">
                <BTN @handle="close" type="detail" bh="40" bw="250" :fontSize="isPhone ? '16' : ''">{{ langJson('Back to editing') }}</BTN>
                <BTN @handle="sendInvite" :fontSize="isPhone ? '16' : ''" :disable="!list.length" :load="load_btn" type="active" bh="40" bw="250">{{ langJson('Send') }}</BTN>
            </div>
        </div>
    </Model>
</template>
<script>
import QRCode from 'qrcode'
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            typelist: [],
            showShare: false,
            compaign: '',
            detail: {},
            urlShare: '',
            qrcode: '',
        }
    },
    created() {},
    watch: {
        show(val) {
            this.showShare = val
            if (val) {
                if (this.isParticipant) {
                    this.init()
                } else {
                    this.listGet()
                }
            }
        },
        showShare(val) {
            this.$emit('change', val)
        },
        compaign() {
            this.infoGet()
        },
    },
    methods: {
        async qrlink() {
            let result = await QRCode.toDataURL(this.urlShare, {
                width: 600,
                height: 600,
            })
            this.qrcode = result
        },
        // 用户 参与的活动
        async init(source = {}) {
            console.log('用户 参与的活动')
            try {
                let { dispatch } = this.$store
                let { id } = this.userInfo // status 0 待审核 1 上架中 2 已下架
                await dispatch('joinCampaignListUser', Object.assign({ pageNum: 1 || this.pageNum, pageSize: 1000 || this.pageSize, userId: id, status: 1 }, source))
                console.log('this.globalMarket.joinCampaignListUser', this.globalMarket.joinCampaignListUser)
                this.typelist = this.globalMarket.joinCampaignListUser.map((item) => {
                    return {
                        lable: item.name,
                        value: item.id,
                    }
                })
                if (this.$route.query.id) {
                    this.compaign = parseInt(this.$route.query.id)
                } else if (this.typelist.length) {
                    this.compaign = parseInt(this.typelist[0].value)
                }
                console.log('this.compaign', this.compaign)
                if (!this.compaign) {
                    return
                }
                this.infoGet()
            } catch (error) {
                console.log(error)
            }
        },
        initShare() {
            try {
                let protocol = window.location.protocol
                let host = window.location.host
                if (this.isParticipant) {
                    this.urlShare = `${protocol}//${host}/participant/campaign/preview?status=live&id=${this.detail.id}`
                } else {
                    this.urlShare = `${protocol}//${host}/participant/campaign/preview?status=live&id=${this.detail.id}`
                }
            } catch (error) {
                console.log(error)
            }
        },
        async sendInvite() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let result
                if (this.isParticipant) {
                    result = await dispatch('participantContactsInvite', {
                        contactIds: this.list,
                        campaignId: this.compaign,
                        campaignLink: this.urlShare,
                    })
                } else {
                    result = await dispatch('clientInvite', {
                        clientIds: this.list,
                        campaignId: this.compaign,
                        campaignLink: this.urlShare,
                    })
                }
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Send success'))
                    this.$emit('handleSuccess')
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('error', this.langJson('Send fail'))
            }
            this.load_btn = false
        },
        close() {
            this.showShare = false
        },
        async listGet() {
            try {
                let { dispatch } = this.$store
                await dispatch('myCompaign', {
                    pageNum: 1 || this.pageNum,
                    pageSize: 1000 || this.pageSize,
                })
                this.typelist = this.myCompaign.map((item) => {
                    return {
                        lable: item.name,
                        value: item.id,
                    }
                })
                if (this.$route.query.id) {
                    this.compaign = parseInt(this.$route.query.id)
                } else if (this.typelist.length) {
                    this.compaign = parseInt(this.typelist[0].value)
                }
                this.infoGet()
            } catch (err) {
                console.log(err)
            }
        },
        async infoGet() {
            let { dispatch } = this.$store
            let result = await dispatch('compaignInfo', {
                id: this.compaign,
            })

            if (result && result.code == 200) {
                this.detail = result.data
                this.initShare()
                this.qrlink()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.share_main {
    box-sizing: border-box;
    margin-top: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    .share_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font_deep_color);
        }
    }
    .share_description {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .choose_campaign {
            .title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_deep_color);
                margin-bottom: 12px;
                .required {
                    color: var(--danger_color);
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    margin-left: 4px;
                }
            }
        }
        .total_clients {
            display: flex;
            align-items: center;
            gap: 24px;
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_color_black);
            }
            h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: var(--font_deep_color);
            }
        }
    }
    .share_link {
        .link_right {
            margin-left: 20px;
        }
        .link_left {
            .link_text {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-top: 4px;
                width: 100%;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .link_title {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--font_modif_color);
            }
            flex: 1;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--bg_color);
        border-radius: 32px;
        padding: 12px 24px;
        flex: 1;
        cursor: pointer;
    }
    .share_image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 360px;
        padding-left: 11px;
        padding-right: 11px;

        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        padding-bottom: 17px;
        border-radius: 24px;
        position: relative;
        .qr_code {
            border-radius: 24px;
            position: absolute;
            right: 12px;
            top: 12px;
            width: 150px;
            height: 150px;
            margin: auto;
        }
        .compaign_detail {
            min-height: 80px;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            background: var(--color_white);
            backdrop-filter: blur(8.5px);
            border-radius: 12px;

            .detail_box {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                flex: 1;
                border-right: 1px solid rgb(201, 204, 221);
                color: var(--font_deep_color);
                h2 {
                    font-family: 'Cabinet Grotesk';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 32px;
                    color: var(--font_deep_color);
                }
                p {
                    margin-top: 4px;
                }
                color: var(--font_color_black);
            }
            .detail_box:last-child {
                border-right: none;
            }

            .line {
                width: 80px;
                border: 1px solid var(--border_color);
                transform: rotate(90deg);
            }
        }
    }
    .btns {
        width: 100%;
        display: flex;
        gap: 12px;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1080px) {
    .compaign_detail {
        min-height: 62px !important;
    }
    .link_title {
        font-size: 12px !important;
        line-height: 16px !important;
    }
    .link_text {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .share_main {
        gap: 16px !important;
    }
    .share_image {
        min-height: 248px !important;
        .qr_code {
            border-radius: 24px;
            position: absolute;
            right: 12px;
            top: 12px;
            width: 100px !important;
            height: 100px !important;
            margin: auto;
        }
    }
    .detail_box {
        h2 {
            font-size: 18px !important;
            line-height: 22px !important;
        }
        span {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 750px) and (min-width: 500px) {
    .link_text {
        width: 285px !important;
    }
    .btns {
        justify-content: center !important;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .share_main {
        width: 76.534vw !important;
    }
    .link_text {
        width: 120px !important;
    }
    .btns {
        justify-content: center !important;
        flex-wrap: wrap;
    }
}
</style>
