<template>
    <div class="prize_container">
        <div class="left_box">
            <div class="link_item" v-for="(item, index) in navlist" :key="index">
                <div :class="['link_inner cursor', item.value == tab && 'link_active']" @click="selectItem(item.value)">
                    {{ item.title }}
                </div>
            </div>
        </div>
        <StepRun ref="step">
            <div class="right_box1" slot="step1">
                <div class="text_box" v-for="(item, index) in list" :key="index">
                    <div class="up_box" :style="{ 'background-image': `url(${item.rewardBrandDescription})` }"></div>
                    <div class="down_box">
                        <h2>{{ item.rewardTitle }}</h2>
                        <span>{{ item.rewardDescription }}</span>
                    </div>
                </div>
            </div>
            <div class="right_box1 right_box2" slot="step2">
                <div class="text123 v-html">{{ detail.rules }}</div>
            </div>
        </StepRun>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            tab: 1,
            navlist: [
                { title: 'Prizes', value: 1 },
                { title: 'Rules', value: 2 },
            ],
        }
    },
    computed: {
        list() {
            try {
                return this.detail.campaignAwards
            } catch (error) {
                return []
            }
        },
    },
    mounted() {},
    methods: {
        next() {
            this.$refs.step.next()
        },
        prev() {
            this.$refs.step.prev()
        },
        backPrev() {
            if (this.$refs.step.step > 0) {
                this.prev()
            } else {
                this.back()
            }
        },
        selectItem(val) {
            this.tab = val
            if (val == 1) {
                this.prev()
            }
            if (val == 2) {
                this.next()
            }
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.text123 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--font_color);
}
.prize_container {
    width: 100%;

    box-sizing: border-box;
    max-width: var(--max_width);
    margin: auto;
    display: flex;
    padding-bottom: 80px;
    .left_box {
        margin-top: 114px;
        height: 108px;
        width: 61px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 28px;
        .link_item {
            font-family: 'Inter';
            font-style: normal;
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            background-clip: text;
            .link_inner {
                color: #c9ccdd;
                padding: 12px 0;
                border-bottom: 2px solid transparent;
            }
            .link_active {
                background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                border-bottom: 2px solid #bb99fe;
            }
            cursor: pointer;
            margin-right: 32px;
        }
    }
    .right_box1 {
        margin-top: 80px;
        max-width: 964px;
        /* width: 100%; */

        margin-left: 178px;
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        &.right_box2 {
            display: flex;
            flex-direction: column;
        }
        .text_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 21px;
            width: 300px;
            .up_box {
                width: 100%;
                height: 300px;
                /* background-image: url('@/assets/image/brand/brand_box.png'); */
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
            .down_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                width: 300px;
                word-break: break-all;
                h2 {
                    font-family: 'Inter';
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 32px;
                    color: var(--font_deep_color);
                }
                span {
                    font-family: 'Lato';

                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color);
                }
            }
        }
    }
}
@media screen and (max-width: 1080px) {
    .prize_container {
        flex-direction: column;
        padding-bottom: 40px;
        padding-top: 20px;
        gap: 24px;
        .left_box {
            flex-direction: row;
            margin-top: 0px;
            justify-content: flex-start;
            padding-top: 20px;
            height: fit-content;
            padding-top: 0px;
            .link_item {
                font-size: 16px;
            }
        }
    }
    .right_box1 {
        .text_box {
            width: 100% !important;
            
        }
        margin-left: 0px !important;
        margin-top: 0px !important;
        max-width: none;
        overflow: auto;
    }
}
</style>
