import http from './base'

/**
 * 已上架的活动列表
 * @param pageNum
 * @param pageSize
 * @param isAsc desc
 * @param orderByColumn createTime
 * @param isRecommend 是否推荐 0-否 1-是
 * @returns
 */
let campaignList = (params = {}) => {
    return http.get(`/campaign/list`, params)
}

/**
 * 顶级品牌列表
 * @param pageNum
 * @param pageSize
 */
let userTopBrands = (params = {}) => {
    return http.get(`/user/top/brands`, params)
}

/**
 * 活动详情页
 * @param detail
 *
 */
let campaignDetail = (id = '0') => {
    return http.get(`/campaign/${id}`)
}

/**
 * 活动详情 活动作品列表
 * @param campaignId 活动id
 *
 */
let campaignOtherArt = (params) => {
    return http.get(`/campaign/works/list`, params)
}

/**
 * 参加活动
 * @param campaignId 活动id
 * @param artworkCredit 艺术品归属
 * @param email 邮箱
 * @param artistCodename 美工代号
 * @param artworkTitle 艺术品标题
 * @param artworkImg 艺术品
 * @param description 描述
 *
 */
let campaignWorksSave = (params) => {
    return http.postJson(`/campaign/works/save`, params)
}

/**
 * 活动里的参与者
 * @param campaignId 活动id
 * @param pageSize  每页条数
 * @param pageNum 页数
 */
let campaignParticipantsUser = (params) => {
    return http.get(`/campaign/participants`, params)
}

/**
 * 活动里的艺术品
 * @param campaignId 活动id
 * @param type 1-用户自己的作品 2-其他用户的作品
 * @param pageSize  每页条数
 * @param pageNum 页数
 */
let campaignWorksSelect = (params) => {
    return http.get(`/campaign/works/selectCampaignWorks`, params)
}

/**
 * 为艺术品投票
 * @param worksId 艺术品id
 */
let campaignWorksVote = (params) => {
    return http.post(`/campaign/works/vote`, params)
}
/**
 * 个人中心 我铸造的nft列表
 * @param isAsc desc 降序
 * @param orderByColumn createTime 排序字段
 * @param owner 0xe... 拥有者
 * @param pageSize  每页条数
 * @param pageNum 页数
 *
 */
let nftProductList = (params) => {
    return http.get(`/nftProduct/list`, params)
}

/**
 * 个人中心 用户的艺术品
 * @param userId 用户id
 * @param pageSize  每页条数
 * @param pageNum 页数
 *
 */
let userArtWorkList = (params) => {
    return http.get(`/campaign/works/userArtWorkList`, params)
}
/**
 * 个人中心 用户参加过的活动
 * @param userId 用户id
 * @param pageSize  每页条数
 * @param pageNum 页数
 */
let joinCampaignListUser = (params) => {
    return http.get(`/campaign/joinCampaignList`, params)
}

/**
 * 个人中心 用户参加过的活动
 * @param userId 用户id
 * @param pageSize  每页条数
 * @param pageNum 页数
 */
let campaignWorks = ({ id }) => {
    return http.get(`/campaign/works/${id}`)
}

// 用户联系人
/**
 * 获取已有联系人
 * @param pageNum
 * @param pageSize
 *
 */
let participantContactsList = (params) => {
    return http.get(`/participant/contacts/list`, params)
}

/**
 * 新增联系人
 * @param pageNum
 * @param pageSize
 *
 */
// let participantContactsSave = (params) => {
//     return http.post(`/participant/contacts/save`, params)
// }

/**
 * 修改联系人
 * @param pageNum
 * @param pageSize
 *
 */
// let participantContactsUpdate = (params) => {
//     return http.post(`/participant/contacts/update`, params)
// }

/**
 * 导入联系人
 * @param pageNum
 * @param pageSize
 *
 */
let participantContactsImportData = (file) => {
    return http.upload(`/participant/contacts/importData`, file)
}
/**
 * 删除 联系人
 * @param pageNum
 * @param pageSize
 *
 */
let participantContactsDelete = (params) => {
    return http.postJson(`/participant/contacts/delete`, params)
}

/**
 * 邀请联系人
 * @param pageNum
 * @param pageSize
 *
 */
let participantContactsInvite = (params) => {
    return http.postJson(`/participant/contacts/invite`, params)
}

// 设置 获取通知类型
let contentNoticeType = (params) => {
    return http.get(`/content/noticeType`, params)
}
// 设置 提交通知
let contentNoticeSwitch = (params) => {
    return http.postJson(`/content/noticeSwitch`, params)
}

// 设置 获取支持类型
let contentHelpList = (params) => {
    return http.get(`/content/helpList`, params)
}
// 设置 提交问题
let contentHelpAdd = (params) => {
    return http.postJson(`/content/helpAdd`, params)
}
// 设置 支持记录
let contentHelpRecordUser = (params) => {
    return http.get(`/content/helpRecordUser`, params)
}

/**
 * 铸造 NFT
 * @param data nft
 * @param preview  nft
 * @param dataType 图片
 * @param name 名字
 * @param type 分类
 * @param description 描述
 * @param chainId 网络
 * @param contractType 合约类型
 * @param royaltyAddress 版税接收地址
 * @param royaltyRate 版税百分比
 * @param circulation 数量
 */
let nftSave = (params) => {
    return http.postJson(`/nftProduct/save`, params)
}

// 获取 nft详情
let nftProduct = ({ id }) => {
    return http.get(`/nftProduct/${id}`)
}

/**
 * 参与者退出活动【删除活动的作品】
 * @param campaignId  活动id
 */
let deleteByCampaignId = (params) => {
    return http.post(`/campaign/works/participant/deleteByCampaignId`, params)
}

/**
 * 参与者删除活动的作品
 * @param id 作品id
 */
let deleteById = (params) => {
    return http.post(`/campaign/works/participant/deleteById`, params)
}

/**
 * 联系人验证信息
 * @param contactInformation 联系人验证信息
 * @param Information  修改
 */
let contactInformation = (params) => {
    return http.get(`/user/verify/info`, params)
}
let updateInformation = (params = {}) => {
    return http.postJson(`/user/verify/submit`, params)
}

/**
 * 提交交易哈希
 * @param chainId 系统chainId
 * @param txHash  txHash
 */
let nftProductArtworksCast = (params = {}) => {
    return http.post(`/nftProduct/artworks/cast`, params)
}

/**
 * 获取登录用户邮箱验证码
 */
let getLoginUserEmailCode = (params = {}) => {
    return http.get(`/user/getLoginUserEmailCode`, params)
}

/**
 * 修改用户邮箱
 * @param email 新邮箱
 * @param verifyCode 新邮箱
 */
let updateEmailConfirm = (params = {}) => {
    return http.post(`/user/updateEmailConfirm`, params)
}

/**
 * 活动下同用户 其他艺术品
 * @param email 新邮箱
 * @param verifyCode 新邮箱
 */
let userOtherCampaignWorks = (params = {}) => {
    return http.get(`/campaign/works/selectUserOtherCampaignWorks`, params)
}

export default { userOtherCampaignWorks, updateEmailConfirm, getLoginUserEmailCode, nftProductArtworksCast, deleteByCampaignId, deleteById, nftProduct, nftSave, contentHelpAdd, contentHelpRecordUser, contentHelpList, contentNoticeSwitch, contentNoticeType, participantContactsInvite, participantContactsDelete, participantContactsImportData, participantContactsList, campaignWorks, joinCampaignListUser, userArtWorkList, nftProductList, campaignWorksVote, campaignParticipantsUser, campaignWorksSelect, campaignWorksSave, campaignOtherArt, campaignDetail, campaignList, userTopBrands, contactInformation, updateInformation }
