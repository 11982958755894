<template>
    <div class="mint">
        <div class="mint_main">
            <div class="mint_title">{{ langJson('Create an NFT') }}</div>
            <div class="mint_center">
                <div class="item_title">{{ langJson('Image') }}</div>
                <div class="item_upload">
                    <CustomUpload size="5" v-model="form.preview" border="/source/png/mint_upload.png">
                        <GI name="artwork_upload" ws="40" hs="40"></GI>
                        <div class="upload_tip">{{ langJson('Maximum file size: 5MB') }}</div>
                        <div class="ratio">{{ langJson('Recommended ratio: 1 × 1') }}</div>
                        <BTN icon_left="mint_upload_btn" type="active" bw="253" bh="52" class="upload_btn">
                            {{ langJson('Upload your image') }}
                        </BTN>
                    </CustomUpload>
                </div>

                <div class="mint_form">
                    <FormItem v-model="form.name" class="form_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Name')" :placeholder="langJson('Please enter artwork name')"></FormItem>

                    <FormItem v-model="form.type" class="form_item" :list="classlist" type="select" :matchs="['unull']" :title="langJson('Category')" :placeholder="langJson('Select Category')"></FormItem>

                    <FormItem v-model="form.description" class="form_item" type="textarea" :title="langJson('Description')" height="248px"></FormItem>

                    <FormItem v-model="form.chainId" class="form_item" :list="chainlist" type="select" :matchs="['unull']" :title="langJson('Network')" :placeholder="langJson('Select Network')"></FormItem>

                    <div class="form_title">{{ langJson('Collective contract type') }}</div>
                    <div class="types">
                        <div :class="['type_btn', form.contractType == item.value && 'type_active', globalThemeColor]" v-for="(item, index) in typelist" :key="index" @click="selectContractType(item.value)">
                            {{ item.lable }}
                        </div>
                    </div>

                    <!-- <div class="form_title royalty_title">{{ langJson('Royalty payments') }}</div> -->
                    <div class="royalty">
                        <div class="royalty_item royalty_address">
                            <FormItem v-model="form.recipient" :matchs="['unull', 'maxLength']" class="form_item_bottom" type="text" :title="langJson('Receiving address')"></FormItem>
                        </div>
                        <!-- <div class="royalty_item royalty_rate">
                            <FormItem v-model="form.royaltyRate" :matchs="['unull', 'float', 'threeNumber']" class="form_item_bottom" type="text" :title="langJson('Royalty proportion')">
                                <span class="percent" slot="text_append">%</span>
                            </FormItem>
                        </div> -->
                    </div>

                    <FormItem v-show="form.contractType == 1155" :matchs="['unull', 'NumberInt', 'maxLength']" v-model="form.circulation" class="number_item form_item_bottom" type="text" :title="langJson('Number of tokens')"></FormItem>

                    <div class="submit_btn">
                        <BTN type="active" bw="240" bh="52" :disable="!pass" :load="load_btn" class="upload_btn" @handle="isPhone ? mint_handle() : chainChange(form.chainId,mintShow)">
                            {{ langJson('Create NFT') }}
                        </BTN>
                    </div>
                </div>
            </div>
        </div>

        <MintNFT :form="form" :amount="amount" v-model="mintModel" @finash="submitMint"></MintNFT>
        <Runload v-model="runModel"></Runload>
        <MintCompleted v-model="completeModel" :detail="detail"></MintCompleted>

        <MintTip v-model="mint_tip" @confirm="chainChange(form.chainId,mintShow)"></MintTip>
    </div>
</template>
<script>
let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    data() {
        return {
            mint_tip: false,
            amount: 0,
            detail: {},
            mintModel: false,
            runModel: false,
            completeModel: false,
            form: {
                data: '',
                preview: '',
                dataType: 'image', // 图片

                name: '', // 名字
                type: '', // 分类下拉选择
                description: '', // 描述
                chainId: '', // 网络下拉选择
                contractType: 721, // 合约类型 ERC第三种合约类型
                royaltyAddress: '', // 版税接收地址
                royaltyRate: 0, // 版税百分比
                circulation: 1, // 数量
                recipient:""
            },
            typelist: [
                {
                    lable: this.langJson('ERC721'),
                    value: 721,
                },
                {
                    lable: this.langJson('ERC1155'),
                    value: 1155,
                },
            ],
        }
    },
    computed: {
        classlist() {
            try {
                let result = this.nftCategory.map((item) => {
                    return {
                        lable: item.name,
                        value: item.categoryId,
                    }
                })
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },

        symbols() {
            try {
                let result = this.globalToken.map((item) => {
                    return {
                        lable: item.symbol,
                        value: item.id,
                    }
                })
                result.unshift({
                    lable: this.langJson('All'),
                    value: '',
                })
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
        chainlist() {
            try {
                let result = this.chainMapping.map((item) => {
                    return {
                        lable: item.chainName,
                        value: item.id,
                    }
                })
                return result
            } catch (err) {
                console.log(err)
                return []
            }
        },
        pass() {
            try {
                // return true
                let condition = true
                if (this.form.contractType == 1155) {
                    condition = this.checkPass(this.form.circulation, ['unull', 'NumberInt', 'maxLength'], false)
                }
                return this.checkPass(this.form.name, ['unull', 'maxLength'], false) && this.checkPass(this.form.preview, ['unull'], false) && this.checkPass(this.form.recipient, ['unull', 'maxLength'], false) && condition
            } catch (err) {
                return false
            }
        },
    },
    created() {
        this.init()
        // this.loginOf()
    },
    methods: {
        mint_handle() {
            this.mint_tip = true
        },
        async chainChange(val,fn = () => {}) {
            try {
                let result = await this.connect()
                if (!result) {
                    this.pushMsg('warning', this.langJson('Please connect wallet'))
                    return
                }
                let chain = this.chainMapping.find((item) => {
                    return item.id == val
                })
                await this.targetChain(chain.id,fn)
            } catch (err) {
                console.log(err)
            }
        },
        // 开始铸造
        async submitMint() {
            this.runModel = true
            try {
                let balance = await this.getEthBalance(this.walletDetail.address)
                balance = division(balance, Math.pow(10, 18))
                console.log(balance,this.amount)
                if (!(balance * 1 >= this.amount * 1)) {
                    this.pushMsg('warning', this.langJson('Sorry, your credit is running low'))
                    this.runModel = false
                    return
                }
                let { store } = await this.storeState()

                let { dispatch } = this.$store
                let { chainId, preview, dataType, name, description, type, contractType, recipient, royaltyRate,royaltyAddress, circulation } = this.form
                let result = await dispatch('nftSave', {
                    chainId,
                    preview,
                    data: preview,
                    dataType,
                    name,
                    type,
                    description,
                    amount: circulation,
                    contractType: contractType,
                    royaltyAddress: "",
                    royaltyProportion: "",
                })

                if (result && result.code == 200) {
                    this.detail = result.data
                }
                let data = this.detail
                let params = {
                    uid: this.userInfo.id,
                    nft: data.contractAddr,
                    list: [
                        {
                            recipient: recipient,
                            amount: data.circulation,
                            uri: data.tokenUri,
                        },
                    ],
                }
                let transition = await store.mint(params, true)
                if (transition && transition.transactionHash) {
                    this.pushMsg('success', this.langJson('Mint success'))
                    this.runModel = false
                    this.completeModel = true
                    let { dispatch } = this.$store

                    let result = await dispatch('nftProductArtworksCast', {
                        chainId: this.detail.chainId,
                        txHash: transition.transactionHash,
                    })
                    console.log('提交完交易has', result)
                } else {
                    this.runModel = false
                    this.pushMsg('error', this.langJson('Mint fail'))
                }
            } catch (err) {
                console.log(err)
            }
            this.runModel = false
        },
        async mintShow() {
            await this.mintFee()
            this.mintModel = !this.mintModel
        },
        // 确认铸造
        async confirmMint() {
            if (this.pass) {
                if (!this.form.data) {
                    this.$set(this.form, 'data', this.form.preview)
                }
            }
        },
        async init() {
            // 如果没有选择主链 设置默认主链
            if (!!this.chainlist.length && !this.form.chainId) {
                this.$set(this.form, 'chainId', this.chainlist[0].value)
            }
            // 如果没有选择nft类型 设置默认网站支持的nft类型
            if (!!this.classlist.length && !this.form.type) {
                this.$set(this.form, 'type', this.classlist[0].value)
            }
        },
        selectContractType(type) {
            this.$set(this.form, 'contractType', type)
        },
        // 初始化手续费
        async mintFee() {
            try {
                this.load_btn = true

                // 1铸造合约 2 721合约 3 1155合约
                let type = this.form.contractType == 721 ? 'ERC721' : 'ERC1155'
                let contractAddr = this.findContractObj(this.form.chainId)
                let { store } = await this.storeState()
                let params = {
                    uid: this.userInfo.id,
                    nft: contractAddr[type],
                    list: [
                        {
                            recipient: this.form.recipient,
                            amount: 1,
                            uri: 'http://xxx.xxxxxxx.xxx:6666/api/metadata/artworks/tokenUri/1000000',
                        },
                    ],
                }
                let fee = await store.mint(params, false)
                console.log(fee)
                this.amount = fee.total
            } catch (err) {
                this.load_btn = false
                console.log(err)
            }
            this.load_btn = false
        },
    },
}
</script>
<style lang="scss" scoped>
.mint {
    .mint_main {
        .mint_center {
            .mint_form {
                /deep/ .input_content {
                    .input_text,
                    .select_btn {
                        background: var(--bg9);
                        .select_popup {
                            background: var(--bg7);
                        }
                    }
                }

                .submit_btn {
                    margin-top: 64px;
                }
                .royalty {
                    .royalty_item {
                        width: 100%;
                        .percent {
                            color: var(--font_deep_color);
                        }
                    }
                    margin-top: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .types {
                    .type_btn {
                        width: 160px;
                        height: 56px;
                        background: var(--bg9);
                        border: 1px solid var(--bg_color);
                        border-radius: 24px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-right: 32px;
                        box-sizing: border-box;
                        cursor: pointer;
                        &.theme_dark {
                            color: #ffffff;
                        }
                    }
                    .type_active {
                        background: #eae0ff;
                        border: 1px solid var(--primary_color);
                        &.theme_dark {
                            color: var(--primary_color);
                        }
                    }
                    margin-top: 12px;
                    display: flex;
                    align-items: center;
                }
                .form_title {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                    margin-top: 42px;
                }
                .royalty_title {
                    margin-top: 24px;
                }
                .form_item {
                    margin-bottom: 42px;
                }
                .form_item_bottom {
                    margin-bottom: 0;
                }
                .number_item {
                    margin-top: 16px;
                }
                /* background-color: var(--color_white); */
                /* border-radius: 32px; */
                /* padding: 32px; */
                margin-top: 42px;
                margin-bottom: 100px;
            }
            .item_upload {
                .upload_btn {
                    margin-top: 24px;
                }
                .upload_tip {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_modif_color);
                    margin-top: 24px;
                }
                .ratio{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_modif_color);
                    margin-top: 10px;
                }
                margin-top: 16px;
                height: 292px;
            }
            .item_title {
                margin-top: 40px;
                color: var(--font_deep_color);
                margin-top: 40px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
            width: 734px;
            margin: auto;
        }
        .mint_title {
            border-bottom: 1px solid var(--nft_btn_border);
            height: 68px;
            font-family: 'Cabinet Grotesk';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            color: var(--font_deep_color);
            margin-top: 40px;
        }
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
    }
}
@media screen and (max-width: 1080px) {
    .mint {
        .mint_main {
            .mint_center {
                .mint_form {
                    .submit_btn {
                        margin-top: 64px;
                    }
                    .royalty {
                        .royalty_item {
                            width: 100%;
                            .percent {
                                color: var(--font_deep_color);
                            }
                        }
                        margin-top: 42px;
                        gap: 42px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                    .types {
                        .type_btn {
                            width: 160px;
                            height: 56px;
                            background: var(--bg9);
                            border: 1px solid var(--bg_color);
                            border-radius: 24px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            margin-right: 32px;
                            box-sizing: border-box;
                            cursor: pointer;
                        }
                        .type_active {
                            background: #eae0ff;
                            border: 1px solid var(--primary_color);
                        }
                        margin-top: 12px;
                        display: flex;
                        align-items: center;
                    }
                    .form_title {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_deep_color);
                        margin-top: 42px;
                    }
                    .royalty_title {
                        margin-top: 24px;
                    }
                    .form_item {
                        margin-bottom: 42px;
                    }
                    .form_item_bottom {
                        margin-bottom: 0;
                    }
                    .number_item {
                        margin-top: 16px;
                    }
                    /* background-color: var(--color_white);
                    border-radius: 32px;
                    padding: 32px; */
                    margin-top: 42px;
                    margin-bottom: 100px;
                }
                .item_upload {
                    .upload_btn {
                        margin-top: 24px;
                    }
                    .upload_tip {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--font_modif_color);
                        margin-top: 24px;
                    }
                    margin-top: 16px;
                    min-height: 292px;
                }
                .item_title {
                    margin-top: 40px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
                max-width: 734px;
                width: 100%;
                margin: auto;
            }
            .mint_title {
                border-bottom: 1px solid var(--nft_btn_border);
                height: 68px;
                font-family: 'Cabinet Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                color: var(--font_deep_color);
                margin-top: 40px;
            }
            width: 100%;
            max-width: var(--max_width);
            margin: auto;
        }
    }
}
@media screen and (max-width: 500px) {
    .mint {
        /deep/ .model_main {
            width: 279px;
        }
        /deep/ .model_btns {
            .model_btn {
                margin-top: 8px;
            }
            .btn_grap {
                width: 0;
            }
        }
    }
}
</style>
