import api from '@/api/market'
import tools from '@/mixin/tools'
const userModule = {
    state: {
        // globalMarket
        campaignEnd: [], // 快要结束的额
        campaignNew: [], // 最新的
        campaignRecommend: [], // 推荐的
        userTopBrands: [], // 排名
        campaignDetailUser: {}, // 活动详情
        campaignOtherArt: [], // 活动下已参与的艺术品

        campaignWorksSave: {
            campaignId: '',
            artworkCredit: '', // 艺术品归属
            email: '', // 邮箱
            artistCodename: '', // 美工代号
            artworkTitle: '', // 艺术品标题
            description: '', // 描述
            artworkImg: '', // 艺术品图片
        }, // 参加活动

        campaignBrandName: '',
        campaignBrand: [], // 单个品牌下的 活动
        campaignParticipantsUser: [], // 活动里的参与者
        campaignWorksSelect: [], // 活动下的艺术品

        nftProductList: [], // 个人 我铸造的NFT
        userArtWorkList: [], // 个人 我的艺术品
        joinCampaignListUser: [], // 个人 我参加的活动

        campaignWorks: {}, // 艺术品详情
        userOtherCampaignWorks: [], // 用户活动的其他艺术品
    },
    getters: {},
    mutations: {
        logoBg(state, logoBg) {
            state.logoBg = logoBg
        },
        campaignWorks(state, campaignWorks) {
            state.campaignWorks = campaignWorks
        },
        joinCampaignListUser(state, joinCampaignListUser) {
            state.joinCampaignListUser = joinCampaignListUser
        },
        userArtWorkList(state, userArtWorkList) {
            state.userArtWorkList = userArtWorkList
        },
        nftProductList(state, nftProductList) {
            state.nftProductList = nftProductList
        },
        campaignBrand(state, campaignBrand) {
            state.campaignBrand = campaignBrand
        },
        campaignRecommend(state, campaignRecommend) {
            state.campaignRecommend = campaignRecommend
        },
        campaignEnd(state, campaignEnd) {
            state.campaignEnd = campaignEnd
        },
        campaignNew(state, campaignNew) {
            state.campaignNew = campaignNew
        },
        userTopBrands(state, userTopBrands) {
            state.userTopBrands = userTopBrands
        },
        campaignDetailUser(state, campaignDetailUser) {
            state.campaignDetailUser = campaignDetailUser
        },
        campaignOtherArt(state, campaignOtherArt) {
            state.campaignOtherArt = campaignOtherArt
        },
        campaignWorksSave(state, campaignWorksSave) {
            state.campaignWorksSave = campaignWorksSave
        },
        campaignWorksSelect(state, campaignWorksSelect) {
            state.campaignWorksSelect = campaignWorksSelect
        },
        campaignParticipantsUser(state, campaignParticipantsUser) {
            state.campaignParticipantsUser = campaignParticipantsUser
        },
        userOtherCampaignWorks(state, userOtherCampaignWorks) {
            state.userOtherCampaignWorks = userOtherCampaignWorks
        },
    },
    actions: {
        // 已上架的活动列表 newin
        async campaignNew(context, params = {}) {
            let result = await api.campaignList(params)
            result = this.apiResult(result)
            // console.log('已上架的活动列表newin', result)
            context.commit('campaignNew', result.rows)
            return result
        },
        // 已上架的活动列表 isRecommend
        async campaignRecommend(context, params = {}) {
            let obj = {
                pageSize: 4,
                pageNum: 1,
                isRecommend: 1, // 0否 1推荐
            }

            let result = await api.campaignList(Object.assign(params, obj))
            result = this.apiResult(result)
            // console.log('已上架的活动列表isRecommend', result)
            context.commit('campaignRecommend', result.rows)
            return result
        },
        //  活动列表  End
        async campaignEnd(context, params = {}) {
            let obj = {
                isAsc: 'desc',
                orderByColumn: 'endTime',
            }
            let result = await api.campaignList(Object.assign(params, obj))
            result = this.apiResult(result)
            context.commit('campaignEnd', result.rows)
            return result
        },
        // 单个品牌的活动列表
        // 所有活动列表
        async campaignBrand(context, params = {}) {
            let result = await api.campaignList(Object.assign({ name: context.state.campaignBrandName }, params))
            result = this.apiResult(result)
            context.commit('campaignBrand', result.rows)
            return result
        },
        //  品牌排行
        async userTopBrands(context, params = {}) {
            let result = await api.userTopBrands(params)
            result = this.apiResult(result)
            // console.log('品牌排行', result)
            context.commit('userTopBrands', result.data)
            return result
        },
        // 活动详情
        async campaignDetailUser(context, id) {
            let result = await api.campaignDetail(id)
            result = this.apiResult(result)
            // console.log('活动详情页', result)
            context.commit('campaignDetailUser', result.data)
            return result
        },
        // 活动详情 > 活动作品列表
        async campaignOtherArt(context, params) {
            // console.log('其他参赛者params', params)
            let result = await api.campaignOtherArt(params)
            result = this.apiResult(result)
            // console.log('活动作品列表', result)

            context.commit('campaignOtherArt', result.rows)
            return result
        },
        // 参加活动
        async campaignWorksSave(context, params) {
            let result = await api.campaignWorksSave(Object.assign(context.state.campaignWorksSave, params))
            result = this.apiResult(result)
            context.commit('campaignOtherArt', result.rows)
            return result
        },
        // 活动里的参与者
        async campaignParticipantsUser(context, params) {
            let result = await api.campaignParticipantsUser(params)
            result = this.apiResult(result)
            context.commit('campaignParticipantsUser', result.rows)
            return result
        },
        // 活动里所有的艺术评  我的艺术品
        async campaignWorksSelect(context, params) {
            let result = await api.campaignWorksSelect(params)
            result = this.apiResult(result)
            context.commit('campaignWorksSelect', result.rows)
            return result
        },
        // 艺术品 投票
        async campaignWorksVote(context, params) {
            let result = await api.campaignWorksVote(params)
            result = this.apiResult(result)
            return result
        },
        // 用户 NFT
        async nftProductList(context, params) {
            let result = await api.nftProductList(params)
            result = this.apiResult(result)
            context.commit('nftProductList', result.rows)
            return result
        },
        // 用户 艺术品
        async userArtWorkList(context, params) {
            let result = await api.userArtWorkList(params)
            result = this.apiResult(result)
            context.commit('userArtWorkList', result.rows)
            return result
        },
        // 用户 活动
        async joinCampaignListUser(context, params) {
            let result = await api.joinCampaignListUser(params)
            result = this.apiResult(result)
            context.commit('joinCampaignListUser', result.rows)
            return result
        },
        //  艺术品详情
        async campaignWorks(context, params) {
            let result = await api.campaignWorks(params)
            result = this.apiResult(result)
            context.commit('campaignWorks', result.data)
            return result
        },

        // 获取已有联系人
        async participantContactsList(context, params) {
            try {
                let result = await api.participantContactsList(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 上传联系人
        async participantContactsImportData(context, params) {
            try {
                let result = await api.participantContactsImportData(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 删除联系人
        async participantContactsDelete(context, params) {
            try {
                let result = await api.participantContactsDelete(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 铸造 NFT
        async nftSave(context, params) {
            try {
                let result = await api.nftSave(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 邀请联系人
        async participantContactsInvite(context, params) {
            try {
                let result = await api.participantContactsInvite(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 设置 获取通知类型
        async contentNoticeType(context, params) {
            try {
                let result = await api.contentNoticeType(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 设置 提交通知类型
        async contentNoticeSwitch(context, params) {
            try {
                let result = await api.contentNoticeSwitch(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 设置 支持类型
        async contentHelpList(context, params) {
            try {
                let result = await api.contentHelpList(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 设置 提交问题
        async contentHelpAdd(context, params) {
            try {
                let result = await api.contentHelpAdd(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 设置 支持记录
        async contentHelpRecordUser(context, params) {
            try {
                let result = await api.contentHelpRecordUser(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取nft详情
        async nftProduct(context, params) {
            try {
                let result = await api.nftProduct(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        // 参与者删除作品
        async deleteById(context, params) {
            try {
                let result = await api.deleteById(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        // 参与者退出活动 删除所有作品
        async deleteByCampaignId(context, params) {
            try {
                let result = await api.deleteByCampaignId(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 联系人验证信息详情
        async contactInformation(context, params) {
            try {
                let result = await api.contactInformation(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 修改验证信息
        async updateInformation(context, params = {}) {
            let result = await api.updateInformation(params)
            result = this.apiResult(result)
            return result
        },
        // 修改验证信息
        async nftProductArtworksCast(context, params = {}) {
            let result = await api.nftProductArtworksCast(params)
            result = this.apiResult(result)
            return result
        },

        // 获取登录用户邮箱验证码
        async getLoginUserEmailCode(context, params = {}) {
            let result = await api.getLoginUserEmailCode(params)
            result = this.apiResult(result)
            return result
        },
        // 修改用户邮箱
        async updateEmailConfirm(context, params = {}) {
            let result = await api.updateEmailConfirm(params)
            result = this.apiResult(result)
            return result
        },
        // 修改用户邮箱
        async userOtherCampaignWorks(context, params = {}) {
            let result = await api.userOtherCampaignWorks(params)
            result = this.apiResult(result)
            context.commit('userOtherCampaignWorks', result.rows)
            return result
        },
    },
}
export default userModule
