<template>
  <div class="item_tags">
    <div class="tag_item">
      <Tag type="warning" icon="joinedcompaign_participants"> {{ zan }} {{ langJson('participants') }} </Tag>
    </div>
    <div class="tag_item">
      <Tag type="vote" icon="joinedcompaign_voters"> {{ coll }} {{ langJson('Votes') }} </Tag>
    </div>
    <div class="tag_item">
      <Tag type="primary" icon="joinedcompaign_clock"> {{ view }} {{ langJson('days to go') }} </Tag>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zan: {
      type: [Number, String],
      default: 0,
    },
    coll: {
      type: [Number, String],
      default: 0,
    },
    view: {
      type: [Number, String],
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.item_tags {
  .tag_item { 
    margin-right: 12px;
    margin-bottom: 12px;
    &:last-child {
      margin-right: 0px;
    }
  }
  margin-top: 16px;
  display: flex;
  margin-bottom: -12px;
  align-items: center;
  flex-wrap: wrap;
}
</style>
