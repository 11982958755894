<template>
    <div class="main">
        <div class="header">
            <h2>{{ langJson('My Clients') }}</h2>
            <div v-if="!isPhone" class="btns">
                <BTN @handle="downloadTemplate" bw="200" type="detail">{{ langJson('Download Template') }}</BTN>
                <BTN @handle="delClient" bw="127" icon_left="brand_trash" type="warn" :disable="!lenArray(invitelist)">{{ langJson('Delete') }}</BTN>
                <BTN bw="88" @handle="shareShow" type="detail" :disable="!lenArray(invitelist)">{{ langJson('Invite') }}</BTN>
                <BTN @handle="upload" bw="180" type="detail" icon_left="clients_upload">{{ langJson('Upload clients') }}</BTN>
            </div>
        </div>

        <div v-if="isPhone" class="mobile_btns">
            <BTN @handle="upload" bw="136" bh="44" type="detail_mobile">{{ langJson('Upload clients') }}</BTN>
            <div class="btns">
                <div class="more_icon" @click="delClient">
                    <GI ws="24" hs="24" name="brand_trash_mobile"></GI>
                </div>
                <BtnPop :left="isPhone ? -90 : 0">
                    <div slot="target" class="more_icon"><GI ws="24" hs="24" name="brand_more"></GI></div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light" :noClickStop="true"></GI>
                                <span class="title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
            </div>
        </div>
        <div v-if="!isPhone" class="client_table">
            <TableforClients :commonStyle="true" :selectList="selectList" @Sort="updateSort" @Delete="delAloneClient" @Invite="aloneShow" :list="list" :title="title" :play="play" @change="inviteChange">
                <div class="more" slot="foot">
                    <NuData height="300" v-if="!lenArray(list)" />
                    <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="true" @change="clientGet"></Page>
                </div>
            </TableforClients>
        </div>
        <!-- checkName checkNameVal  确定第一行显示的数据 -->
        <div v-if="isPhone" class="client_table_mobile">
            <TableforClientsMobile @Delete="delAloneClient" @Invite="aloneShow" @Sort="updateSort" @change="inviteChange" :selectList="selectList" :checkName="checkName" :checkNameVal="checkNameVal" :list="list" :title="title" :play="play">
                <div class="more" slot="foot">
                    <NuData height="300" v-if="!lenArray(list)" />
                    <Page v-model="pageNum" :all="total" :size="pageSize" :isBtn="true" @change="clientGet"></Page>
                </div>
            </TableforClientsMobile>
        </div>
        <!-- 分享邀请页 -->
        <editShare ref="editShare" v-model="showShare" :list="invitelist" @handleSuccess="closeDialog"></editShare>

        <editShare v-model="aloneShare" :list="[selectId]"></editShare>
        <input @change="uploadFile" id="dataFile" class="file_real" type="file" name="dataExle" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            getlink: '',
            showShare: false,
            tagList: [
                { icon_dark: 'edit_invite', icon_light: 'edit_invite_light', val: 'Invite', method: 'invite', isChecked: false, number: 1 },
                { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', method: 'delete', isChecked: false, number: 4 },
            ],
            checkName: 'Fullname',
            checkNameVal: 'JoinedCampaigns',
            // list: [
            //     {
            //         No: '1 ',
            //         Fullname: 'Phifer Hall',
            //         JoinedCampaigns: '12',
            //         NFTsminted: '20',
            //         ContactNo: '123333333',
            //         Email: '123333333',
            //         active: false,
            //         show: false,
            //     },

            // ],

            play: [{ type: 'detail', event: 'Delete', title: 'Delete contact' }],
            pageNum: 1,
            pageSize: 100,
            total: 0,
            pushModel: false,
            list: [],
            urlShare: '',
            invitelist: [],
            resetfile: true,
            selectId: '',
            aloneShare: false,
            sortType: '',
        }
    },
    computed: {
        selected() {
            return (item) => {
                try {
                    let result = this.seletelist.some((select) => {
                        return select == item.id
                    })
                    return result
                } catch (err) {
                    console.log(err)
                }
                return false
            }
        },
        selectList() {
            return [
                {
                    lable: this.langJson('All'),
                    value: '',
                },
                {
                    lable: this.langJson('Campaigns Joined'),
                    value: 'joinCampaignNum',
                },
                {
                    lable: this.langJson('NFTs Minted'),
                    value: 'mintNftNum',
                },
                // {
                //     lable: this.langJson('createTime'),
                //     value: 'createTime',
                // },
            ]
        },
        title() {
            return {
                no: {
                    title: this.langJson('No.'),
                    type: 'text',
                },
                // name: {
                //     title: this.langJson('Full name'),
                //     type: 'text',
                // },
                firstName: {
                    title: this.langJson('First name'),
                    type: 'text',
                },
                lastName: {
                    title: this.langJson('Last name'),
                    type: 'text',
                },
                joinCampaignNum: {
                    title: this.langJson('Joined Campaigns'),
                    type: 'text',
                },
                mintNftNum: {
                    title: this.langJson('NFTs minted'),
                    type: 'text',
                },
                contactNo: {
                    title: this.langJson('Contact No.'),
                    type: 'text',
                },
                email: {
                    title: this.langJson('Email'),
                    type: 'text',
                },
            }
        },
    },
    created() {
        this.initShare()
        this.clientGet()
    },
    mounted() {},
    methods: {
        // 检查商户是否参加了活动
        async checkShare(fn) {
            await this.$refs['editShare'].listGet()
            if (this.myCompaign && this.myCompaign.length > 0) {
                fn()
            } else {
                this.pushMsg('warning', this.langJson('Please create and list at least one activity first'))
            }
        },
        async downloadTemplate() {
            this.$download.excel('/participant/contacts/importTemplate')
        },
        updateSort(val) {
            // console.log(val)
            this.sortType = val
            this.clientGet()
        },
        upload() {
            document.getElementById('dataFile').click()
        },
        async uploadFile() {
            console.log('上传之后')
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let fileSource = document.getElementById('dataFile').files[0]
                let result = await dispatch('importData', fileSource)
                let success = result && result.code == 200
                if (success) {
                    await this.clientGet()
                }

                document.getElementById('dataFile').value = ''
            } catch (err) {
                console.log(err)
            }
            this.load_btn = false
        },
        aloneShow(item) {
            // console.log(item)
            this.selectId = item.id
            this.checkShare(() => {
                this.aloneShare = true
            })
        },
        async delAloneClient(item) {
            try {
                let { dispatch } = this.$store
                let result = await dispatch('clientDelete', [item.id])
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Delete success'))
                    this.invitelist = []
                    await this.clientGet()
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('error', this.langJson('delete fail'))
            }
        },
        async delClient() {
            if (!this.lenArray(this.invitelist)) {
                this.pushMsg('warning', this.langJson('Please select operation data'))
                return
            }
            try {
                let { dispatch } = this.$store
                let result = await dispatch('clientDelete', this.invitelist)
                if (result && result.code == 200) {
                    this.pushMsg('success', this.langJson('Delete success'))
                    this.invitelist = []
                    await this.clientGet()
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('error', this.langJson('delete fail'))
            }
        },
        inviteChange(list) {
            this.invitelist = list
        },
        initShare() {
            let protocol = window.location.protocol
            let host = window.location.host
            this.urlShare = `${protocol}//${host}/brand/campaign/preview?status=live`
        },
        async clientGet() {
            try {
                let { dispatch } = this.$store
                let result = await dispatch('clientData', {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    isAsc: 'desc',
                    orderByColumn: this.sortType,
                })
                this.total = result.total
                this.list = result.rows.map((item,index) => {
                    return {
                        ...item,
                        no:parseInt(this.pageSize * (this.pageNum - 1) + index + 1)
                    }
                })
            } catch (err) {
                console.log(err)
            }
        },
        shareShow() {
            this.checkShare(() => {
                this.showShare = true
            })
        },
        goRoute(item) {
            switch (item.number) {
                case 1:
                    this.shareShow()
                    break
                case 4:
                    this.delClient()
                    break
                default:
                    break
            }
        },
        changeShow(val) {
            this.checkShare(() => {
                this.showShare = val
            })
        },
        closeDialog() {
            this.showShare = false
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
}
.file_real {
    display: none;
}
.header {
    max-width: var(--max_width);
    width: 100%;
    height: 106px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-family: 'Cabinet Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        display: flex;
        align-items: center;
        color: var(--font_deep_color);
    }
    .btns {
        display: flex;
        gap: 16px;
    }
}
.mobile_btns {
    max-width: var(--max_width);
    width: 100%;
    margin: auto;
    margin-top: 26px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns {
        display: flex;
        gap: 16px;
        .more_icon {
            box-sizing: border-box;
            width: 44px;
            height: 44px;
            background: var(--color_white);
            border: 1px solid var(--border_color);
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .type_main {
            padding: 24px;
            background: var(--color_white);
            border: 1px solid var(--border_color);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 24px;
            cursor: pointer;

            .type_content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &:last-child {
                    color: var(--danger_color);
                }
                &:not(:last-child) {
                    color: var(--font_deep_color);
                }

                .type_line {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .title {
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: 'Lato';
                    }
                }
            }
        }
    }
}
.client_table {
    max-width: var(--max_width);
    width: 100%;
    margin: auto;
    padding: 23px 32px;
    background-color: var(--color_white);
    box-sizing: border-box;
    border-radius: 32px;
    .more {
        margin-top: 24px;
    }
}
.client_table_mobile {
    max-width: var(--max_width);
    width: 100%;
    margin: auto;
    border-radius: 32px;
    .more {
        margin-top: 11px;
    }
}
</style>
