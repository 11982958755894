<template>
    <div class="floor_new">
        <div class="new_top">
            <div class="title">{{ title }}</div>
            <div class="new_do">
                <GI class="icon_do" v-show="!groupStatus.prev" name="market_left" :ws="isPhone ? '24' : '32'" :hs="isPhone ? '24' : '32'"></GI>
                <GI class="icon_do" v-show="groupStatus.prev" name="market_left_active" :ws="isPhone ? '24' : '32'" :hs="isPhone ? '24' : '32'" @click="$refs.group.prev()"></GI>
                <GI class="icon_do" v-show="!groupStatus.next" name="market_right" :ws="isPhone ? '24' : '32'" :hs="isPhone ? '24' : '32'"></GI>
                <GI class="icon_do" v-show="groupStatus.next" name="market_right_active" :ws="isPhone ? '24' : '32'" :hs="isPhone ? '24' : '32'" @click="$refs.group.next()"></GI>
            </div>
        </div>
        <Group ref="group" @change="changeGroup" v-if="lenArray(list)">
            <div class="group_item" v-for="(item, index) of list" :key="index">
                <div class="item_main">
                    <CPRO :isParticipant="isParticipant" :item="item" :padding="isPhone ? '12px' : '16px 24px 24px 24px'" :hs="isPhone ? '132' : '200'" imageRadius="20" titType="item_title_small" descType="item_desc_small"></CPRO>
                    <!-- <CompaignItem :detail="item" :isParticipant="isParticipant" :showBotomBtnUnjoin="false" :tagList="tagList" hs="200"></CompaignItem> -->
                </div>
            </div>
        </Group>
        <NuData height="300" v-if="!lenArray(list)" />
    </div>
</template>
<script>
export default {
    props: {
        isParticipant: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        title: {
            type: String,
            default: '',
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            groupStatus: {
                prev: false,
                next: false,
            },
            tagList: [
                { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'View as a participant', isChecked: false, number: 1, status: 'live' },
                { icon_dark: 'brand_edit', icon_light: 'brand_edit_light', val: 'Edit campaign', isChecked: false, number: 2, status: 'live' },
                { icon_dark: 'brand_candle', icon_light: 'brand_candle_light', val: 'Manage submissions', isChecked: false, number: 3, status: 'live' },
                { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', isChecked: false, number: 4, status: 'live' },
            ],
        }
    },
    mounted() {},
    methods: {
        changeGroup(obj) {
            this.groupStatus = Object.assign(this.groupStatus, obj)
        },
    },
}
</script>
<style lang="scss" scoped>
.floor_new {
    .group_item {
        max-width: 405px;
        width: 100%;
        margin-right: 32px;
        .item_main {
            width: 100%;
            background: var(--color_white);
            padding: 16px 24px 24px 24px;

            box-sizing: border-box;
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
            white-space: wrap !important;
            /deep/ .item {
                padding: 0 !important;
            }
            &:hover {
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
            }
        }
    }
    .new_top {
        .new_do {
            display: flex;
            align-items: center;
            .icon_do {
                cursor: pointer;
                margin-left: 20px;
                pointer-events: stroke;
            }
        }
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: var(--font_deep_color);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    width: 100%;
    max-width: var(--max_width);
    margin: auto;
    margin-top: 48px;
}
@media only screen and (max-width: 1080px) {
    .floor_new {
        width: 100%;
        max-width: var(--max_width);
        margin: auto;
        margin-top: 48px;
        .group_item {
            margin-right: 16px;
            width: 252px;
            .item_main {
                width: 252px;
                background: var(--color_white);
                margin-right: 16px;
                box-sizing: border-box;
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
                border-radius: 20px;
                white-space: wrap !important;
                padding: 12px;
                &:hover {
                    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
                }
            }
        }
        .new_top {
            .new_do {
                display: flex;
                align-items: center;
                .icon_do {
                    cursor: pointer;
                    margin-left: 17.25px;
                    pointer-events: stroke;
                }
            }
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: var(--font_deep_color);
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
        }
    }
}
</style>
