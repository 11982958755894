<template>
    <div
        :class="['input_main','bgCover']"
        :style="{
            width:`100%`,
            minHeight: `${colAmount * (sizeHeight+10) + 70}px`
        }"
    >
        <div class="upload_icon bgCover" :style="{
            backgroundSize: `100% 100%`,
            backgroundImage: !!!files.length && `url(/img/global_upload_border_full.2a4128db.png)`
        }" v-if="!!!files.length" @click="upload">
            <GI name="global_upload" ws="24" hs="24" @click="upload"></GI>
            <div class="main_title">{{ langJson('Upload mutiple file') }}</div>
            <div class="main_tip">{{ tip ? tip : langJson('(Acceptable file format: JPEG, JPG, PNG.)') }}</div>
        </div>
        <div
            class="image_main"
            v-if="!!files.length"
            :id="box_id"
            :style="{
                minHeight: `${colAmount * size + 10 * (colAmount - 1)}px`,
            }"
        >
            <draggable v-model="files">
                <transition-group>
                    <div class="image_item bgCover target_move" v-for="(item, index) in files" :key="`image_${index}}`" :style="computStyle(item, index)">
                        <div class="image_mask">
                            <div class="image_icon" @click="updateItem(index)">
                                <GI :noClickStop="true" name="global_upload_update" ws="18" hs="18"></GI>
                            </div>
                            <div class="image_icon" @click="deleteItem(index)">
                                <GI :noClickStop="true" name="global_upload_delete" ws="22" hs="22"></GI>
                            </div>
                        </div>
                        <div class="load_mask" v-if="playIndex == index && loadItem">
                            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
                        </div>
                    </div>
                </transition-group>
            </draggable>

            <div class="image_item image_push" :style="pushStyle" @click="upload">
                <GI :noClickStop="true" class="play_icon" name="global_upload_add" ws="36" hs="36"></GI>
            </div>
            <div class="image_grap" v-for="(item, index) of 20" :key="`grap_${index}`" :style="{ width: `${size}px` }"></div>
        </div>

        <div class="mask" v-show="loading">
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
        </div>
        <!-- <div class="mask replay" v-show="imgSrc">
            <GI name="global_upload" ws="24" hs="24" @click="reUpload"></GI>
        </div> -->
        <input @change="uploadAlone" :id="`${components_id}_alone`" class="file_real" type="file" name="myfile" />
        <input v-if="uploadType == 2" @change="uploadFile" :id="components_id" class="file_real" multiple type="file" name="myfile" />
        <input v-else-if="uploadType == 3" @change="uploadFile" :id="components_id" class="file_real" webkitdirectory type="file" name="myfile" />
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    data() {
        return {
            loading: false,
            loadItem: false,

            components_id: '',
            box_id: '',
            rowAmount: 2,
            colAmount: 1,

            files: [],
            fileData: [],

            positions: [],

            uploadType: 2,
            playIndex: 0,

            playElement: null,
            mx: 0,
            my: 0,
            sx: 0,
            sy: 0,
            startIndex: null,
            endIndex: null,
            initMove: false,
        }
    },
    computed: {
        computStyle() {
            return (item, index) => {
                let pt
                let pl
                if (this.positions[index]) {
                    pt = this.positions[index].pt
                    pl = this.positions[index].pl
                } else {
                    pt = 0
                    pl = 0
                }
                return {
                    backgroundImage: `url(${item.url})`,
                    width: `${this.sizeHeight}px`,
                    height: `${this.sizeHeight}px`,
                    top: `${pt}px`,
                    left: `${pl}px`,
                }
            }
        },

        pushStyle() {
            let num = this.files.length % this.rowAmount
            return {
                width: `${this.sizeHeight}px`,
                height: `${this.sizeHeight}px`,
                top: `${(this.colAmount - 1) * (this.sizeHeight + 10)}px`,
                left: `${num * (this.sizeHeight + 10)}px`,
            }
        },
        moreIcon() {
            return this.uploadType == 2 ? 'global_upload_more_active' : 'global_upload_more'
        },
        fileIcon() {
            return this.uploadType == 3 ? 'global_upload_files_active' : 'global_upload_files'
        },
    },
    inject: ['regCode'],
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        tip: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'big',
        },
        support: {
            type: Array,
            default: () => {
                return ['image']
            },
        },
        sizeHeight: {
            type: [Number, String],
            default: 194,
        },
        size: {
            type: [Number, String],
            default: 5,
        },
    },
    model: {
        prop: 'list',
        event: 'change',
    },
    watch: {
        list() {
            this.files = this.list
        },
        files: {
            handler(n) {
                this.$emit('change', n)
                this.viewInit()
            },
            deep: true,
        },
    },
    created() {
        let code = this.regCode()
        this.components_id = 'upload_' + code
        code = this.regCode()
        this.box_id = 'image_upload_' + code

        this.files = this.list
        this.viewInit()
    },
    mounted() {},
    methods: {
        initPosition() {
            this.positions = []
            let nodes = document.getElementsByClassName('target_move')
            for (let i = 0; i < nodes.length; i++) {
                let rect = nodes[i].getBoundingClientRect()
                let { x, y, width, height } = rect
                let position = {
                    left: x,
                    top: y,
                    bottom: y + height,
                    right: x + width,
                    width,
                    height,
                }
                this.positions.push(position)
            }

            this.positionGet()
        },

        positionGet() {
            let main = document.getElementById(this.box_id)
            if (main) {
                let rect = main.getBoundingClientRect()
                let { width } = rect
                this.rowAmount = Math.floor(width / this.sizeHeight)
                this.colAmount = Math.ceil((this.files.length + 1) / this.rowAmount)
                this.positions = this.positions.map((item, index) => {
                    let row = Math.floor(index / this.rowAmount)
                    let col = index % this.rowAmount
                    return {
                        ...item,
                        pl: col * (this.sizeHeight + 10),
                        pt: row * (this.sizeHeight + 10),
                        index,
                    }
                })
            }
        },
        startPoint(event, index) {
            this.sx = event.clientX
            this.sy = event.clientY
            this.startIndex = index
            if (!this.initMove) {
                this.viewInit()
            } else {
                this.initMove = true
            }

            document.onmousemove = this.movePoint
            document.onmouseup = this.endPoint
        },
        movePoint(event) {
            this.mx = event.clientX
            this.my = event.clientY
            this.positions.some((item, index) => {
                if (this.mx >= item.left && this.mx <= item.right && this.my >= item.top && this.my <= item.bottom) {
                    this.endIndex = index
                }
            })
        },
        endPoint() {
            if (this.endIndex !== null && this.startIndex !== null) {
                this.positionReplace()
            } else if (this.startIndex !== null) {
                this.startIndex = null
                this.endIndex = null
            }
            document.onmousemove = null
            document.onmouseup = null
        },

        positionReplace() {
            // let targetPosition = this.positions[this.startIndex]
            // this.positions.splice(this.endIndex,0,[targetPosition])
            // this.positions = this.positions.filter((item,index) => {
            //     return index !== this.startIndex
            // })
            let aPosition = this.positions[this.startIndex]
            let bPosition = this.positions[this.endIndex]
            this.$set(this.positions, this.startIndex, bPosition)
            this.$set(this.positions, this.endIndex, aPosition)

            // let aItem = this.files[this.startIndex]
            // let bItem = this.files[this.endIndex]
            // this.$set(this.files,this.startIndex,bItem)
            // this.$set(this.files,this.endIndex,aItem)

            this.startIndex = null
            this.endIndex = null
        },

        viewInit() {
            // 定位初始化
            this.$nextTick(() => {
                this.initPosition()
            })
        },

        deleteItem(index) {
            this.files = this.files.filter((subItem, subIndex) => {
                return index != subIndex
            })
        },
        deleteMul(arr) {
            this.files = this.files.filter((subItem, subIndex) => {
                let exit = arr.some(item => {
                    return item == subIndex
                })
                return !exit
            })
        },
        updateItem(index) {
            this.playIndex = index
            document.getElementById(`${this.components_id}_alone`).click()
        },
        typeChange(val) {
            this.uploadType = val
        },
        clearAll() {
            this.files = []
            document.getElementById(this.components_id).value = ''
            document.getElementById(`${this.components_id}_alone`).value = ''
            this.colAmount = 1
        },
        upload() {
            document.getElementById(this.components_id).click()
        },
        async uploadAlone() {
            let file = document.getElementById(`${this.components_id}_alone`).files[0] 
            var typelimit = this.support.some((item) => {
                return file.type.indexOf(item) != -1
            })
            const sizelimit = file.size / 1024 / 1024 <= this.size
            // 限制上传类型
            if (!typelimit) {
                this.pushMsg('error', this.langJson('Uploading this file is not supported'))
                return
            }
            // 限制上传大小
            if (!sizelimit) {
                this.pushMsg('error', this.langJson('The size of the uploaded file cannot exceed') + this.size + 'M!')
                return
            }
            this.loadItem = true
            try {
                let fileSource = document.getElementById(`${this.components_id}_alone`).files[0]
                let { dispatch } = this.$store
                let result = await dispatch('uploadFile', fileSource)
                let success = result && result.code == 200
                let { name } = this.files[this.playIndex]
                if (success) {
                    this.$set(this.files, this.playIndex, {
                        source: fileSource,
                        url: result.data.url,
                        name,
                        type: fileSource.type,
                    })
                } else {
                    this.$set(this.files, this.playIndex, { source: '', url: '', name, type: '' })
                }
            } catch (err) {
                console.log(err)
            }
            this.loadItem = false
        },
        async uploadFile() {
            this.loading = true
            try {
                let fileSource = document.getElementById(this.components_id).files 
                let { dispatch } = this.$store
                for (let i = 0; i < fileSource.length; i++) {
                    let file = fileSource[i] 
                    var typelimit = this.support.some((item) => {
                        return file.type.indexOf(item) != -1
                    })
                    const sizelimit = file.size / 1024 / 1024 <= this.size
                    // 限制上传类型
                    if (!typelimit) {
                        this.pushMsg('error', this.langJson('Uploading this file is not supported'))
                        continue
                    }
                    // 限制上传大小
                    if (!sizelimit) {
                        this.pushMsg('error', this.langJson('The size of the uploaded file cannot exceed') + this.size + 'M!')
                        continue
                    }
                    let result = await dispatch('uploadFile', fileSource[i])
                    let success = result && result.code == 200
                    if (success) {
                        this.files.push({
                            source: fileSource[i],
                            url: result.data.url,
                            name: '',
                            type: fileSource[i].type,
                        })
                    } else {
                        this.files.push({
                            source: fileSource[i],
                            url: '',
                            name: '',
                            type: fileSource[i].type,
                        })
                    }
                }
            } catch (err) {
                console.log(err)
            }
            this.loading = false
        },
    },
}
</script>
<style lang="scss" scoped>
.input_main {
    .play_nav {
        .play_icon {
            margin: 0 10px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        width: 100%;
    }
    .image_main {
        .image_item {
            .image_mask {
                .image_icon {
                    margin: 0 10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 6px;
                // background-color: rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(0);
            }
            .image_input {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba($color: #fff, $alpha: 0.8);
                border-radius: 6px 6px 0 0;
                padding: 0 10px;
                box-sizing: border-box;
                input {
                    border: none;
                    outline: none;
                    height: 36px;
                    background: transparent;
                    width: 100%;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_modif_color);
                }
            }
            .load_mask {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 6px;
                background-color: rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            width: 200px;
            height: 200px;
            border-radius: 6px;
            overflow: auto;
            background-color: rgba($color: #ccc, $alpha: 0.2);
            position: absolute;
            overflow: hidden;
        }
        .image_push {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .image_item:hover {
            .image_mask {
                transform: scale(1);
            }
        }
        .image_grap {
            width: 200px;
        }
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        flex: 1;
        position: relative;
    }
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 32px;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .replay {
        opacity: 0;
    }
    .replay:hover {
        opacity: 1;
    }
    .upload_icon {
        .main_tip {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font_modif_color);
            margin-top: 4px;
        }
        .main_title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
            margin-top: 8px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex: 1;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: auto;
    position: relative;
    transition: all 1s;
    border-radius: 16px;
    min-height: 300px;
    // padding:10px;
    box-sizing: border-box;
    user-select: none;
}
@media screen and (max-width: 435px) {
    .input_main {
        .image_main {
            .image_item {
                width: 100% !important;
                height: 180px !important;
            }
            .image_grap {
                width: 100% !important;
            }
        }
    }
}
.file_real {
    display: none;
}
</style>


