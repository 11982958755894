import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import config from "@/store/data/config";
import user from "@/store/data/user";
import help from "@/store/data/help";
import market from "@/store/data/market";
import brand from "@/store/data/brand";
import person from "@/store/data/person";
import createPersistedState from 'vuex-persistedstate'
import { removeCookie } from '@/assets/js/cookie'
const store = new Vuex.Store({
    state: {
        tips: [],
        // 提示时长
        timelen: 5000,
        // 接口数据缓存
        apiCache: {},
        // 记录总条数，当页面只有一个记录接口是可以使用
        total: 0,

        // 是否显示登录弹框
        walletShow: false,
        // 是否显示语言弹框
        langShow: false,

        // 登录提示
        loginShow: false,

        // 钱包连接
        linkShow: false,

        walletDetail: {},
        AssetStatus: false,

        // 临时变量
        tempVal: {}

    },
    mutations: {
        walletDetail(state, walletDetail) {
            if (walletDetail) {
                localStorage.setItem('walletDetail', JSON.stringify(walletDetail))
            }
            state.walletDetail = walletDetail
        },
        AssetStatus(state, AssetStatus) {
            state.AssetStatus = AssetStatus
        },
        linkShow(state, linkShow) {
            state.linkShow = linkShow
        },
        loginShow(state, loginShow) {
            state.loginShow = loginShow
        },
        walletShow(state, walletShow) {
            state.walletShow = walletShow
        },
        langShow(state, langShow) {
            state.langShow = langShow
        },
        tips(state, tips) {

            state.tips = tips
        },
        total(state, total) {
            state.total = total
        },
        apiCache(state, apiCache) {
            state.apiCache = apiCache
        },
        warning(state, message) {
            let result = state.tips.concat([
                {
                    type: 'warning',
                    message,
                    expire: new Date().getTime() + state.timelen,
                },
            ])
            store.commit("tips", result)
        },
        success(state, message) {
            let result = state.tips.concat([
                {
                    type: 'success',
                    message,
                    expire: new Date().getTime() + state.timelen,
                },
            ])
            store.commit("tips", result)
        },
        error(state, message) {
            let result = state.tips.concat([
                {
                    type: 'error',
                    message,
                    expire: new Date().getTime() + state.timelen,
                },
            ])
            store.commit("tips", result)
        },

        tempVal(state, tempVal) {
            state.tempVal = tempVal
        }
    },
    modules: {
        config,
        user,
        help,
        market,
        brand,
        person
    },
    plugins: [createPersistedState({
        key: "persistent", //设置的localStorage的存储键值
        storage: window.localStorage,
        // 此处才是我们的重中之重，idnexModule是我们的命名空间
        reducer: (state) => ({
            config: {
                headView: state.config.headView
            }
        })
    })],
})

store.apiResult = (result, defaultValue = false) => {
    try {
        if (result && result.code == 200) {
            return result
        } else if ((result && result.code == 403) || (result && result.code == 401) || (result && result.code == 1004)) {
            store.commit('loginShow', true)
            removeCookie('token')
            return result
        } else if (result.code == 500 && !result.data && !result.msg) {
            return result
        } else {
            console.log(result.msg)
            store.commit('warning', result.msg)
            return defaultValue
        }
    } catch (err) {
        console.log(err)
        return defaultValue
    }
}

store.apiCache = (model, mutation, data) => {
    try {
        let expirelen = 1000 * 600
        store.commit(mutation, data)
        let allCache = store.state.apiCache
        allCache[`${model}.${mutation}`] = {
            expire: new Date().getTime() + expirelen,
            data,
        }
        store.commit('apiCache', allCache)
    } catch (err) {
        console.log(err)
    }
}

store.isGet = (model, mutation) => {
    let allCache = store.state.apiCache
    let cacheData = allCache[`${model}.${mutation}`]
    let nowTime = new Date().getTime()
    if (cacheData && cacheData.expire > nowTime) {
        return false
    } else {
        return true
    }
}

export default store;