<template>
    <div :class="['input_main', nowUrl || file2 ? 'file' : type, disabled2 ? 'handStop' : '']">
        <!-- :style="
            nowUrl && {
                backgroundImage: `url(${nowUrl})`,
                backgroundSize: `cover`,
            }
        " -->
        <Source :isDetail="true" v-if="nowUrl || file2" :Preview="nowUrl || file2" :Data="nowUrl || file2" :type="nowtype" :isUploadFile="true" :fit="fit"></Source>
        <!-- || file.type -->

        <div class="upload_icon" v-if="!(nowUrl || file2)" @click="upload">
            <GI name="global_upload" ws="26" hs="26" @click="upload"></GI>
            <!-- <GI name="global_upload" ws="24" hs="24" @click="upload"></GI> -->
            <div class="main_title">{{ langJson('Upload file') }}</div>
            <div class="main_tip">{{ tip ? tip : langJson(`Acceptable file format: ${support.toString()}`) }}</div>
            <div v-if="msg" class="main_tip">{{ msg }}</div>
        </div>

        <div class="mask" v-show="loading">
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
        </div>
        <div class="mask replay" v-if="!disabled2" v-show="nowUrl || file2" @click="reUpload">
            <!-- <GI name="wallet_swap" ws="24" hs="24" @click="reUpload"></GI> -->
            <GI name="global_upload" ws="24" hs="24"></GI>
        </div>
        <input ref="selectFile" @change="change" :id="components_id" class="file_real" type="file" name="myfile" />
    </div>
</template>
<script>
import api from '@/api/config'
export default {
    data() {
        return {
            disabled2: false,
            loading: false,
            components_id: '',
            // imgSrc: '',

            nowUrl: '', //当前文件
            urlGlb: '', //当前glb文件
            nowtype: '', //当前类型
            ipfsMainUrl: '',
            file2: '',
        }
    },
    inject: ['regCode'],
    props: {
        msg: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        file: {
            type: String, // , Number, Array, Object
            default: () => {
                return ''
                // return {
                //     type: '',
                //     url: '',
                // }
            },
        },
        // fileUrl: {
        //     type: String,
        //     default: '',
        // },
        tip: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'small',
        },
        support: {
            type: Array,
            default: () => {
                return ['video']
                // return ['image', 'video', 'audio', 'glb']
                //'text' 并不支持 text/html\
                // 'image',为了 区分 图片 和 多媒体
            },
        },
        size: {
            type: [Number, String],
            default: 40,
        },
        fit: {
            type: String,
            default: 'cover',
        },
    },
    model: {
        prop: 'file',
        // prop: 'fileUrl',
        event: 'change',
    },
    watch: {
        disabled: {
            handler(val) {
                this.disabled2 = val
            },
            immediate: true,
            deep: true,
        },
        file: {
            handler(val) {
                this.file2 = val
                var resultIndex = val.lastIndexOf('.')
                var srt = (val + '').substring(resultIndex - 0 + 1).toLowerCase()
                this.nowtype = srt
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        // this.init()
        let code = this.regCode()
        this.components_id = 'upload_' + code
        // this.imgSrc = this.fileUrl
        // this.nowUrl = this.fileUrl
    },
    methods: {
        // async init() {
        //     let { dispatch } = this.$store
        //     let res = await dispatch('getIpfsDomain')
        //     if (res) {
        //         this.ipfsMainUrl = res.data
        //     }
        // },
        clearUpload() {
            this.nowUrl = ''
            this.urlGlb = ''
            // this.$emit('change', { type: '', url: '' })
            this.$emit('change', '')
            document.getElementById(this.components_id).value = ''
        },
        reUpload() {
            this.nowUrl = ''
            this.urlGlb = ''
            this.upload()
        },
        upload() {
            // this.$emit('change', { type: '', url: '' })
            this.$emit('change', '')
            document.getElementById(this.components_id).value = ''
            document.getElementById(this.components_id).click()
        },
        async change() {
            let file = document.getElementById(this.components_id).files[0]
            var resultIndex = file.name.indexOf('.')
            var srt = (file.name + '').substring(resultIndex - 0 + 1).toLowerCase()
            if (srt == 'glb') {
                this.nowtype = srt
                var typelimit = this.support.some((item) => {
                    return srt.indexOf(item) != -1
                })
            } else {
                this.nowtype = file.type
                var typelimit = this.support.some((item) => {
                    return file.type.indexOf(item) != -1
                })
            }
            const sizelimit = file.size / 1024 / 1024 <= this.size
            if (!typelimit) {
                this.pushMsg('error', this.langJson('Uploading this file is not supported ') + `(only support ${this.support.join(',')})`)
                return
            }
            // 限制上传大小
            if (!sizelimit) {
                this.pushMsg('error', this.langJson(`${this.langJson('The size of the uploaded file cannot exceed')} ${this.size}M!`))
                return
            }
            this.uploadFile()
        },
        async uploadFile() {
            this.loading = true
            try {
                let fileSource = document.getElementById(this.components_id).files[0]
                // this.$emit('source', fileSource)
                let result = await api.uploadFile(fileSource)
                let success = result && result.code == 200
                if (success) {
                    this.uploadSuccess(result)
                } else {
                    if (result.code == 403) {
                    }
                }
            } catch (err) {
                console.log(err)
            }
            this.loading = false
        },
        uploadSuccess(response) {
            // if (this.nowtype == 'glb') {
            //     if (!this.ipfsMainUrl) {
            //         this.pushMsg('error', this.langJson('The configuration of the ipfs primary domain name is missing', 'saas_nft.txt.v1.228'))
            //         return
            //     }
            //     var reg = new RegExp(this.ipfsMainUrl, '')
            //     this.urlGlb = response.data.replace(reg, '')
            // }
            console.log('response', response)
            this.nowUrl = this.urlGlb || response.data.url

            let params = {
                type: this.nowtype,
                url: this.urlGlb || response.data.url,
            }
            this.$emit('change', params.url)
        },
    },
}
</script>
<style lang="scss" scoped>
.input_main {
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-radius: 32px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    position: relative;
    transition: all 1s;
    height: 100%;
    border-radius: 19px;
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 19px;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .replay {
        opacity: 0;
    }
    .replay:hover {
        opacity: 1;
    }
    .upload_icon {
        .main_tip {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font_modif_color);
            margin-top: 4px;
            text-align: center;
        }
        .main_title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_deep_color);
            margin-top: 8px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.small {
    background-image: url(@/assets/image/global_upload_border.png);
    height: 220px;
    max-width: 403px;
    width: 100%;
}
.big {
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 220px;
    width: 100%;
}
.nft_file {
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 220px;
    width: 100%;
}

.file {
    height: 220px;
    width: 403px;
}
.mp4 {
    height: 220px;
    width: 403px;
}

.nft_mint {
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 220px;
    width: 403px;
}
.file_real {
    display: none;
}
</style>
