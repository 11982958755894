<template>
    <div class="item_tags">
        <div class="tag_item">
            <!-- 参与人数 -->
            <Tag type="warning" icon="global_zan">{{ participants }} {{ langJson('participants') }}</Tag>
        </div>
        <div class="tag_item">
            <!-- 投票人数 -->
            <Tag type="success" icon="global_coll">{{ voters }} {{ langJson('voters') }}</Tag>
        </div>
        <div class="tag_item">
            <!-- 结束 -->
            <Tag type="primary" icon="global_view">{{ timeObj.days - 0 }} {{ langJson('days to go') }}</Tag>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refer: 1,
            target: null,
        }
    },
    props: {
        participants: {
            type: [Number, String],
            default: 0,
        },
        voters: {
            type: [Number, String],
            default: 0,
        },
        days: {
            type: [Number, String],
            default: 0,
        },
    },
    computed: {
        timeObj() {
            return this.refer && this.remainTime(this.days)
        },
    },
}
</script>

<style lang="scss" scoped>
.item_tags {
    .tag_item {
        margin-right: 12px;
        margin-bottom: 12px;
        &:last-child {
            margin-right: 0px;
        }
    }
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
</style>
