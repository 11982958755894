<template>
    <div class="myster_banner">
        <Group ref="group" @change="changeGroup">
            <div class="group_item" v-for="(item,index) in list" :key="index" :style="{ width }">
                <div class="banner_item">
                    <div class="banner_image" :style="{
                        backgroundImage:`url(${item.image})`
                    }"></div> 
                </div>
            </div>
        </Group>
        <div class="mask">
            <div class="title">Moonbirds</div>
            <div class="end_title">{{langJson("Sale end time")}}</div>
            <div class="mask_main">
                <div class="main_left icon_item">
                    <div class="icon" @click="$refs.group.prev()">
                        <GI name="msyter_left_white" ws="18" hs="18" @click="$refs.group.prev()"></GI>
                    </div>
                    <div class="left_line"></div>
                </div>
                <div class="main_time">
                    <div class="time_item">
                        {{timeObj.days}}
                        <div class="unit">{{langJson("D")}}</div>
                    </div>
                    <div class="time_line"></div>
                    <div class="time_item">
                        {{timeObj.hours}}
                        <div class="unit">{{langJson("H")}}</div>
                    </div>
                    <div class="time_line"></div>
                    <div class="time_item">
                        {{timeObj.minutes}}
                        <div class="unit">{{langJson("M")}}</div>
                    </div>
                    <div class="time_line"></div>
                    <div class="time_item">
                        {{timeObj.seconds}}
                        <div class="unit">{{langJson("S")}}</div>
                    </div>
                </div>
                <div class="main_right icon_item">
                    <div class="right_line"></div>
                    <div class="icon" @click="$refs.group.next()">
                        <GI name="msyter_right_white" ws="18" hs="18" @click="$refs.group.next()"></GI>
                    </div>
                </div>
            </div>
            <div class="mask_support">{{langJson("Circulation:")}} 100</div>
            <div class="mask_price">
                {{langJson("Price")}} 
                <img class="symbol_icon" src="https://oss.metabazaar.vip/market/2022/06/09/41d14b42721c48faa7c2d981623f7831.png" alt="">
                2 ETH
            </div>
            <div class="mask_point">
                <div :class="['point_item',groupStatus.current === index && 'point_active']" 
                v-for="(item,index) in list" :key="index" @click="$refs.group.jump(index)"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            width:"100%",
            list:[
                {
                    image:"https://w.wallhaven.cc/full/1k/wallhaven-1k7wl1.png"
                },
                {
                    image:"https://w.wallhaven.cc/full/9m/wallhaven-9m372d.png"
                },
                {
                    image:"https://w.wallhaven.cc/full/ex/wallhaven-exzrmw.png"
                },
                {
                    image:"https://w.wallhaven.cc/full/dp/wallhaven-dp9y1m.jpg"
                }
            ],
            refer:1,
            target:null,
            time:"2022-12-30 20:00",
            groupStatus:{
                prev:false,
                next:false,
                current:0
            }
        }
    },
    computed:{
        timeObj() {
            return this.refer && this.remainTime(this.time)
        }
    },
    mounted(){
        this.width = this.driveWidth()
    },
    methods:{
        changeGroup(obj){
            this.groupStatus = Object.assign(this.groupStatus,obj)
        }
    },
    created() {
        this.target = setInterval(() => {
            this.refer = new Date().getTime()
        },1000)
    },
    beforeDestroy() {
        clearInterval(this.target)
    }
}
</script>
<style lang="scss" scoped>
.myster_banner{
    
    .mask{
        .mask_point{
            .point_item{
                margin:0 5px;
                height:4px;
                background-color: rgba($color: #ccc, $alpha: 0.2);
                border-radius: 2px;
                width:20px;
                cursor: pointer;
            }
            .point_active{
                    background: var(--color_white);
                width:40px;
            }
            display: flex;
            align-items: flex-end;
            flex:1;
            margin-bottom:30px;
        }
        .mask_price{
            .symbol_icon{
                width:24px;
                height:24px;
                border-radius: 50%;
                margin:0 10px;
            }
            margin-top:15px;
            font-family: 'Cabinet Grotesk';
            font-weight: 800;
            font-size:20px;
            color:#fff;
            line-height: 20px;
            display: flex;
            align-items: center;
        }
        .mask_support{
            margin-top:60px;
            font-family: 'Cabinet Grotesk';
            font-weight: 800;
            font-size:18px;
            color:#fff;
            line-height: 24px;
        }
        .mask_main{
            .main_time{
                .time_item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    font-family: 'Inter';
                    font-weight: 100;
                    font-size:40px;
                    color:#fff;
                    line-height: 40px;
                    .unit{
                        font-size:20px;
                        line-height: 20px;
                        color:#fff;
                        margin-top:10px;
                    }
                }
                .time_line{
                    margin:0 4vw;
                    width:1px;
                    background:#fff;
                    height: 28px;
                }
                display: flex;
                align-items: center;
                margin:0 6vw;
            }
            .icon_item{
                .left_line{
                    flex:1;
                    height:4px;
                    background-image: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
                }
                .right_line{
                    flex:1;
                    height:4px;
                    background-image: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
                }
                .icon{
                    background-color: #aaa;
                    width:28px;
                    height:28px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                flex:1;
                display: flex;
                align-items: center;
            }
            .main_right{
                display: flex;
                justify-content: flex-end;
            }
            margin-top:20px;
            height:84px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:0 30px;
            box-sizing: border-box;
            width:100%;
        }
        .end_title{
            margin-top:20px;
            font-family: 'Cabinet Grotesk';
            font-weight: 800;
            font-size:18px;
            color:#fff;
            line-height: 24px;
        }
        .title{
            margin-top:120px;
            font-family: 'Cabinet Grotesk';
            font-weight: 800;
            font-size:40px;
            color:#fff;
            line-height: 72px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
        z-index: 2;
        background-color: rgba(0,0,0,0.2);
    }
    .group_item{
        .banner_item{
            
            .banner_image{
                height:100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                transition: all .5s;
            }
            width:100%;
            height:580px;
            overflow: hidden;
            background:var(--color_white);
        }
        
    }
    position: relative;
}
.myster_banner:hover{
    .banner_image{
        transform: scale(1.5);
    }
}
</style>