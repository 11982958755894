<template>
    <div class="swiper_" :style="{ backgroundImage: `url(${bannerImage})`, height: isPhone ? `calc(803px + ${maxHeight}px)` : `calc(793px + ${maxHeight}px)` }">
        <!--  height: true ? `calc(803px + ${maxHeight}px)` : `calc(793px + ${maxHeight}px)`  -->
        <!-- 暗色渐变 -->

        <!-- // maxHeight pc  793px  h5 803px -->
        <div class="cover"></div>
        <div class="swiper">
            <!-- <img class="cover" v-if="!isPhone" src="../../../../../public/source/png/brand/RectangleBgcMobile.png" alt="" />
        <img class="cover" v-if="isPhone" src="../../../../../public/source/png/brand/RectangleBgcMobile.png" alt="" /> -->
            <div class="title">
                <div class="t">
                    {{ langJson('Join our campaign') }}
                    <div class="swper1" :style="{ backgroundImage: `url(/source/png/swper1.png)` }"></div>
                    <div class="swper2" :style="{ backgroundImage: `url(/source/png/swper2.png)` }"></div>
                </div>
                <div v-if="globalMarket.campaignDetailUser && globalMarket.campaignDetailUser.brandDescription" class="desc">
                    {{ globalMarket.campaignDetailUser.brandDescription }}
                </div>
            </div>

            <!-- 商户和没有参加的用户 -->
            <div v-if="!detail_user.isJoin" class="view_more">
                <!-- 这里应该直接提交 -->

                <!-- 商户自己不能提交艺术品 -->
                <BTN class="btn_item" v-if="isParticipant && detail_user.isInvite" type="active" @handle="JoinCampaign" bh="52" :bw="isPhone ? '249' : '180'">
                    {{ langJson('Join campaign') }}
                </BTN>

                <BTN class="btn_item" v-if="isParticipant && !detail_user.isInvite" type="active" @handle="logIn" bh="52" :bw="isPhone ? '249' : '180'">
                    {{ langJson('Log In') }}
                </BTN>
            </div>
            <div v-if="isParticipant && detail_user.isJoin" class="view_more_joined">
                <!-- 参加了会显示这个按钮 -->
                <BTN class="btn_item" v-if="isParticipant && detail_user.isJoin && detail_user.isInvite" @handle="submitArtWork()" type="active" bh="52" :bw="isPhone ? '339' : '193'">
                    {{ langJson('Submit artwork') }}
                </BTN>
                <!-- 参加了就没有这个按钮  -->
                <!-- 布尔值joined表示是否参加了，已参加就是true，未参加就是false -->
                <!-- 参加才会显示 -->
            </div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div @click="getItem(item, index)" v-for="(item, index) in list" :key="index" class="swiper-slide" :wb-id="JSON.stringify({ ...item, index: index })" :onclick="'loopSwiperClick' + item.id + '(this)'">
                        <img :data-src="item.artworkImg" alt="" class="cursor lazyload" />
                    </div>
                </div>
            </div>
            <!-- <div
                v-show="!isPhone"
                class="swiper-button-prev btns left_btn"
                :style="{
                    backgroundImage: `url(/source/png/left_btn.png)`,
                }"
            ></div> -->
            <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <!-- <div
                v-show="!isPhone"
                class="swiper-button-next btns right_btn"
                :style="{
                    backgroundImage: `url(/source/png/right_btn.png)`,
                }"
            ></div> -->
            <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper' // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
export default {
    name: 'Swiper',
    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        detail_user: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    computed: {
        bannerImage() {
            try {
                let isMobile = this.browser().mobile
                let { banner, appBanner } = this.detail_user
                return isMobile ? (appBanner ? appBanner : banner) : banner
            } catch (err) {
                console.log(err)
                return ''
            }
        },
    },
    data() {
        return {
            maxHeight: 0,
            slidesPerView: '',
            reRender: false,
            listMock: [
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '1' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '2' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '3' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '4' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '5' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '6' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '7' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '8' },
                { artworkImg: 'https://w.wallhaven.cc/full/kx/wallhaven-kx36mq.png', compaign: '9' },
            ],
        }
    },
    watch: {
        list() {
            this.$nextTick(() => {
                this.initSwiper()
            })
        },
    },
    created() {
        window.onresize = () => {}
    },
    mounted() {
        this.$nextTick(() => {
            this.initSwiper()
        })
    },
    beforeDestroy() {},
    methods: {
        JoinCampaign() {
            this.$bus.$emit('JoinCampaign')
        },
        logIn() {
            this.$bus.$emit('logIn')
        },
        submitArtWork() {
            this.$bus.$emit('submitArtWork')
        },
        loopSwiperClick(e) {
            var newObj = JSON.parse(e.getAttribute('wb-id'))
            this.getItem(newObj)
        },
        loopSwiperList() {
            this.list.forEach((item) => {
                window['loopSwiperClick' + item.id] = this.loopSwiperClick
            })
        },
        getItem(item, index) {
            if (this.isParticipant) {
                this.jump_participant_campaign_artwork_detail({ idArt: item.id, idActive: this.$route.query.id, idUser: item.userId })
            } else {
                this.jump_brand_compaign_artwork_detail({ idArt: item.id, idActive: this.$route.query.id, idUser: item.userId })
            }
        },
        initSwiper() {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                // console.log('window.innerWidth', window.innerWidth)

                //  298.67

                let phone = (window.innerWidth - 20) / 298.67
                // let phone = (window.innerWidth - 32) / 260.045489006823
                // 343/1.319 = 260.045489006823
                // 375/1.319 = 284.306292645944
                // this.slidesPerView = this.isPhone ? phone : 4
                // this.slidesPerView = this.isPhone ? 1.319 : 4
                // this.slidesPerView = this.isPhone ? 2.4 : 4

                this.slidesPerView = this.isPhone ? 3.6028 : phone // 最小 1080px
                // this.slidesPerView = 8.6 // 最小 3000px

                new Swiper({
                    el: '.swiper-container',
                    // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
                    autoHeight: true,
                    // roundLengths: true,
                    // 设定初始化时slide的索引
                    initialSlide: 0,
                    // slide之间的距离（单位px）
                    spaceBetween: 32,
                    // 设置slider容器能够同时显示的slides数量(carousel模式)
                    slidesPerView: this.slidesPerView,
                    // 设定为true时，活动块会居中，而不是默认状态下的居左。
                    centeredSlides: this.isPhone ? true : Math.floor(this.list.length / 2) > 3 ? true : false,
                    // slideToClickedSlide: true,
                    // 设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
                    grabCursor: false,
                    // loop: true,
                    // init: false,
                    loop: this.isPhone ? (this.list.length == 1 ? false : true) : Math.floor(this.list.length / 2) > 3 ? true : false,
                    // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
                    loopAdditionalSlides: this.isPhone ? (this.list.length == 1 ? 0 : 7) : Math.floor(this.list.length / 2) > 3 ? 7 : 0,
                    speed: this.isPhone ? 250 : 1500,

                    //设置匀速
                    // speed: 2500, //轮播动画的时间，值越大，速度越慢
                    // freeMode: true, //想要匀速运动的时候最好设置上

                    autoplay: this.isPhone
                        ? false
                        : Math.floor(this.list.length / 2) > 3
                        ? {
                              // 自动滑动
                              delay: 500, //1秒切换一次
                              disableOnInteraction: true,
                          }
                        : false,
                    // observer: true,
                    // observeParents: true,
                    // mousewheel: {
                    //     enabled: true,
                    // },

                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                })
                this.loopSwiperList()
                let res = document.querySelector('.swiper-pagination')
                if (res) {
                    if (res.offsetHeight > 75) {
                        this.maxHeight = res.offsetHeight - 75
                    }
                }
                clearTimeout(this.timer)
            }, 500)
        },
    },
}
</script>
<style lang="scss" scoped>
/* ::v-deep .swiper-container-free-mode > .swiper-wrapper { 
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
} */

.swiper_ {
    position: relative;
    z-index: 1;
    height: 793px;
    /* height: calc((100% - 50px) / 2); */

    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    @media only screen and (max-width: 1080px) {
        height: 803px;
    }
    .cover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: #000000c0;
    }
    .swiper {
        z-index: 2;
        /* height: 793px; */
        width: 100vw;
        /* min-width: 3000px; */
        min-width: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        --swiper-theme-color: transparent; /* 设置Swiper风格 */
        --swiper-navigation-color: transparent; /* 单独设置按钮颜色 */
        --swiper-navigation-size: 48px; /* 设置按钮大小 */
        padding-top: 90px;
        @media only screen and (max-width: 1080px) {
            padding-top: 84px;
        }
        .view_more {
            margin: 0 auto;
            margin-top: 25px;
            width: 224px;

            @media only screen and (max-width: 1080px) {
                margin-top: 44px;
            }
            .btn_item {
                min-width: 100% !important;
                width: 100%;
            }
        }
        .view_more_joined {
            margin: 0 auto;
            margin-top: 25px;
            width: 224px;
            .btn_item {
                min-width: 100% !important;
                width: 100%;
            }
        }
        .title {
            /* display: inline-block; */
            margin: 0 auto;

            .t {
                position: relative;
                font-family: Cabinet Grotesk;
                font-size: 40px;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: 0px;
                text-align: center;
                color: #ffffff;
                .swper1 {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    height: 44px;
                    width: 44px;
                    position: absolute;
                    top: calc(50% - 22px);
                    left: calc(50% - 215px);
                    transform: translate(-50%, -50%);
                    @media only screen and (max-width: 1080px) {
                        top: calc(50% - 42px);
                        left: calc(50% - 155px);
                    }
                }
                .swper2 {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    height: 96px;
                    width: 462px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .desc {
                position: relative;
                z-index: 999;
                margin: 0 auto;
                max-width: 80vw;
                font-family: Cabinet Grotesk;
                font-size: 24px;
                font-weight: 500;
                line-height: 40px;
                letter-spacing: 0px;
                text-align: center;
                color: #a69bbb;
            }
        }
        .btns {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 48px;
            width: 48px;
            /* position: relative; */
            z-index: 99;
        }
        .right_btn {
            transform: translateY(-50%);
            right: 30px;
        }
        .left_btn {
            transform: translateY(-50%);
            left: 30px;
        }
    }
}
.swiper-container {
    margin-top: 44px;
    /* overflow: hidden; */
    @media screen and (max-width: 500px) {
        margin-top: 53px;
    }
}
.swiper-slide {
    position: relative;
    /* overflow: hidden; */
    flex: 0 0 fit-content;
    border-radius: 12px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 16px; */
    /* border: 5px solid transparent;
    background: linear-gradient(90deg, #ffc5ac 0%, #ba99ff 100%); */

    /* border-image-source: linear-gradient(180deg, #ffc5ac 0%, #ba99ff 100%); */

    /* , linear-gradient(0deg, #ffffff, #ffffff) */
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 12px;
        height: 100%;
        width: 100%;
        /* height: calc(100% + 10px);
        width: calc(100% + 10px); */
        background: linear-gradient(180deg, #ffc5ac, #ba99ff);
    }
    img {
        border-radius: 12px;
        // width: 332px;
        // height: 480px;
        /* width: 100% !important; */
        width: 266.67px;

        height: 400px;

        object-fit: cover;
    }
}

.swiper-pagination {
    margin: 0 auto;
    width: calc(100vw - 60px);
    position: static;
    margin-top: 35px;
    padding-bottom: 54px;
    @media screen and (max-width: 500px) {
        margin-top: 27px;
        padding-bottom: 36px;
    }

    :deep(.swiper-pagination-bullet) {
        background: #eae0ff;
        margin: 0 8px;
        opacity: 1;
    }

    :deep(.swiper-pagination-bullet-active) {
        background: #9766ff;
    }
}

@media screen and (max-width: 500px) {
    .title {
        .t {
            font-size: 30px !important;
        }
        .desc {
            margin-top: 11px !important;
            line-height: 22.5px !important;
            font-size: 18px !important;
        }
    }
    .swiper-slide {
        overflow: hidden;
        flex: 0 0 fit-content;
        border-radius: 16px;
        /* margin: 0 16px; */
        img {
            /* width: 100% !important; */
            width: 266.67px !important;

            height: 400px !important;
        }
    }
}
</style>
