<template>
    <div class="collected">
        <div class="search_item">
            <div class="search_main">
                <div class="search">
                    <IN v-if="!isPhone" v-model="source.name" :placeholder="langJson('Search Campaign')"></IN>
                    <Search v-model="source.name" v-if="isPhone"></Search>
                </div>
                <div class="sort">
                    <Sel :showBorder="true" v-model="source.orderByColumn" :list="sortlist"></Sel>
                </div>
            </div>
        </div>
        <div class="artworks_box">
            <div class="group_item" v-for="(item, index) of list" :key="index">
                <CompaignItem :showHeader="showHeader" :detail="item" :isParticipant="isParticipant" :showVI="isPhone ? false : true" :showBotomBtnUnjoin="showBotomBtnUnjoin" :tagList="tagList" hs="200" @unjoinHand="unjoinHand(item)"></CompaignItem>
            </div>
        </div>
        <Model :padding="'24px'" v-model="showUnjoinTip">
            <UnjoinTip :id="id" :titleActive="titleActive" @cancel="cancel" slot="main" @complete="$emit('change')" />
        </Model>
    </div>
</template>
<script>
export default {
    props: {
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        showHeader: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        showBotomBtnUnjoin: {
            type: Boolean,
            default: () => {
                return false
            },
        },
    },
    data() {
        return {
            titleActive: '',
            id: '',

            showUnjoinTip: false,
            viewEle: {
                price: false,
                status: true,
                play: false,
            },
            source: {
                name: '',
                orderByColumn: '',
            },
            sortlist: [
                {
                    lable: this.langJson('Sort By'),
                    value: '',
                },
                {
                    lable: this.langJson('Recently created'),
                    value: 'createTime',
                },
                {
                    lable: this.langJson('Number of votes'),
                    value: 'voteNum',
                },
            ],
            tagList: [
                { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'View as a participant', isChecked: false, number: 1, status: 'live' },
                { icon_dark: 'brand_edit', icon_light: 'brand_edit_light', val: 'Edit campaign', isChecked: false, number: 2, status: 'live' },
                { icon_dark: 'brand_candle', icon_light: 'brand_candle_light', val: 'Manage submissions', isChecked: false, number: 3, status: 'live' },
                { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', isChecked: false, number: 4, status: 'live' },
            ],
        }
    },
    watch: {
        source: {
            handler(n) {
                this.$emit('change', n)
            },
            deep: true,
        },
    },
    methods: {
        cancel(close) {
            this.showUnjoinTip = close
        },
        unjoinHand(item) {
            // console.log('退出活动 确认弹框', item)
            this.id = item.id
            this.titleActive = item.name

            this.showUnjoinTip = true
        },
    },
}
</script>
<style lang="scss" scoped>
.collected {
}
.search_item {
    .search_main {
        .sort {
        }
        .search {
            background-color: var(--color_white);
            border-radius: var(--nft_select_border_radius);
            /* border: 1px solid var(--border_color); */
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    padding-top: 32px;
    width: 100%;
    border-radius: 12px;
}

.artworks_box {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    .group_item {
        max-width: 405px;
        width: 100%;
        background: var(--color_white);
        padding: 16px 24px 24px 24px;
        border-radius: 16px;
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        white-space: wrap !important;
        box-sizing: border-box;
        &:hover {
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
        }
    }
}
@media only screen and (max-width: 1080px) {
    .artworks_box {
        margin-top: 24px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        .group_item {
            max-width: 405px;
            width: 100%;
            background: var(--color_white);
            padding: 16px;
            border-radius: 16px;
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            white-space: wrap !important;
            box-sizing: border-box;
            &:hover {
                box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.4);
            }
        }
    }
    .group_item {
        max-width: 100% !important;
        /* flex: 1; */
    }
}
</style>
