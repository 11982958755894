<template>
    <div>
        <div @mouseover="openSearch" @mouseleave="closeSearch" :class="[showInput ? (isPhone ? 'input_text_appear_mobile box_search' : 'input_text_appear box_search') : 'input_text_disappear', globalThemeColor]">
            <input ref="search" v-model="value" v-show="showInput" @blur="closeSearch" class="input_main" />
            <div :class="'search_icon'">
                <GI ws="24" hs="24" name="search"></GI>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showInput: false,
            value: '',
        }
    },
    watch: {
        value(newVal) {
			this.$emit("change",newVal)
		},
		show(newVal) {
			this.value = newVal
		}
    },
	model:{
		event:"change",
		prop:"show"
	},
    props: {
        show: {
            type: String,
            default: '',
        },
    },
	created() {
		this.value = this.show
	},
    mounted() {},
    computed: {
        getBackground() {
            console.log(this.showInput)
        },
    },
    methods: {
        closeSearch() {
            // 输入框是否获取焦点
            if (this.$refs.search == document.activeElement) {
                this.showInput = true
            } else {
                //判断输入框里面是否有内容
                if (this.value.length == 0) {
                    this.showInput = false
                }
            }
        },
        openSearch() {
            this.showInput = true
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.input_text_disappear {
    background: var(--bg_color);
    border-radius: 32px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border: 1px solid var(--border_color);
    width: 52px;
    background: var(--color_white);
}
.input_text_appear {
    background: var(--bg_color);
    border-radius: 32px;
    display: flex;
    align-items: center;

    border: 1px solid var(--border_color);
    width: 200px;
    padding-left: 12px;
    background: var(--color_white);
}
.input_text_appear_mobile {
    background: var(--bg_color);
    border-radius: 32px;
    display: flex;
    align-items: center;

    border: 1px solid var(--border_color);
    width: 110px;
    padding-left: 12px;
    background: var(--color_white);
}
.input_text_disappear,
.input_text_appear {
    transition: all 1s;
}
.input_main {
    border: none;
    outline: none;
    background: transparent;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--font_modif_color);
    flex: 1;
    min-width: 0px;
    overflow: hidden;
}
.search_icon {
    box-sizing: border-box;
    width: 52px;
    height: 52px;
    background: var(--color_white);
    /* border: 1px solid var(--border_color); */
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.box_search{
   .search_icon{
        border-left: 1px solid var(--border_color);
   } 
}
.search_icon_right {
    box-sizing: border-box;
    width: 52px;
    height: 52px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--font_color);
}
.theme_dark {
    /* width: 44px; */
    border-radius: var(--nft_select_border_radius);
    .search_icon{
        width: 52px;
        height: 52px;
        border-radius: var(--nft_select_border_radius);
    }
}
</style>
