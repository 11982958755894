<template>
    <div class="mint">
        <div class="mint_main">
            
            <FormItem v-model="form.image"
            type="image" :image="imageConfig" :title="langJson('Collection preview')" :uploadTip="langJson('Maximum file size: 1MB')"></FormItem>

            <FormItem v-model="form.banner"  class="information_item"
            type="image" :image="bannerConfig" :uploadTip="langJson('Maximum file size: 5MB')" 
            :title="langJson('Collection banner')"></FormItem>

            
            
            <FormItem v-model="form.name" class="information_item" 
            type="text" :title="langJson('Collection name')" :placeholder="langJson('Please enter project name')"></FormItem>

            
            <FormItem v-model="form.bio" class="information_item" 
            type="textarea" :title="langJson('Describe')" :placeholder="langJson('Please enter describe')"></FormItem>
            


            <FormItem v-model="form.ctegory" class="information_item"  :list="classlist"
            type="select" :title="langJson('Category')" :placeholder="langJson('Select Category')"></FormItem>

            
            <FormItem v-model="form.phone" class="information_item" 
            type="text" :title="langJson('Royalty payments')" :placeholder="langJson('Please enter royalty payments (Max:100)')">
                <span class="percent" slot="text_append">%</span>
            </FormItem>




            <div class="btns">
                <BTN class="btn_item" type="active">{{langJson("Submit Collection")}}</BTN>
            </div>
        </div>

        <PushProperties v-model="showPropertie"></PushProperties>
        <CreateCollection v-model="showCollection"></CreateCollection>
        <ConfrimMint v-model="showMint" image="https://w.wallhaven.cc/full/9d/wallhaven-9dx3yd.jpg"></ConfrimMint>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showPropertie:false,
            showCollection:false,
            showMint:false,
            form:{
                image:"",
                banner:"",
                name:"",
                bio:"",
                email:"",
                phone:"",
                type:721,
                link:"",
                ctegory:""
            },
            sourceType:"image",
            imageConfig:{
                type:"small"
            },
            bannerConfig:{
                type:"big"
            },
            typelist:[
                {
                    lable:this.langJson("ERC721"),
                    value:721
                },
                {
                    lable:this.langJson("ERC1155"),
                    value:1155
                }
            ],
            classlist:[
                {
                    lable:this.langJson("Art"),
                    value:1
                },
                {
                    lable:this.langJson("Video"),
                    value:2
                },
                {
                    lable:this.langJson("Music"),
                    value:3
                }
            ]
        }
    },
    computed:{
        isPicture() {
            return this.sourceType.indexOf("image") != -1
        }
    },
    methods:{
        judgeSource(source) {
            console.log(source)
            this.sourceType = source.type
        }
    }
}
</script>
<style lang="scss" scoped>
.mint{
    .mint_main{

        .information_item{
            .percent{
                color:var(--font_deep_color);
            }
            margin-top:32px;
        }
        .btns{
            display: flex;
            align-items: center;
            margin-top:30px;
            .btn_item{
                margin-right:20px;
            }
        }
        width:100%;
        max-width:calc(var(--max_width) / 1.5);
        margin:32px auto;
        min-height: 500px;
        background-color: var(--color_white);
        border-radius: 24px;
        padding:24px;
        box-sizing: border-box;
    }
}
</style>