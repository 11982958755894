<template>
    <div style="overflow: hidden; background-color: #ffffff00">
        <Go :prev="langJson('Back')" @prev="back">
            <BTN icon_left="brand_exit" v-if="!isPhone" @handle="$router.go(-1)" type="warn" bh="52" bw="169">{{ langJson('Exit preview') }}</BTN>
            <BTN icon_left="brand_exit" v-if="isPhone" @handle="$router.go(-1)" type="mobile_warn" bh="52" bw="169">{{ langJson('Exit preview') }}</BTN>
        </Go>
        <CompaignBanner :isParticipant="false" :detail="compaignDetail"></CompaignBanner>

        <PlayerText :detail="compaignDetail"></PlayerText>
        <Swiper v-if="lenArray(globalMarket.campaignOtherArt)" :list="globalMarket.campaignOtherArt" :isParticipant="false" :detail_user="compaignDetail"></Swiper>
        <Descript :detail="compaignDetail" :detail_user="compaignDetail"></Descript>
        <Prize :detail="compaignDetail"></Prize>

        <div class="footer">
            <!-- :style="{ 'background-image': `url(${compaignDetail.brandDescriptionImg})` }" -->
            <h1 class="footer_title">{{ langJson('Connect with us via social media') }}</h1>
            <div class="share_box">
                <div class="share_btns" v-show="campaignSocialMedias && campaignSocialMedias[0] && campaignSocialMedias[0].website" v-if="compaignDetail.companyWebsite">
                    <BTN v-show="campaignSocialMedias && campaignSocialMedias[0] && campaignSocialMedias[0].website" type="active" bh="52" bw="231" @handle="openUrl(campaignSocialMedias[0].website)">{{ langJson('Join Discord channel') }}</BTN>
                    <BTN v-show="compaignDetail.companyWebsite" @handle="openUrl(compaignDetail.companyWebsite)" type="active" bh="52" :bw="isPhone ? 231 : 170">{{ langJson('View website') }}</BTN>
                </div>
                <div class="share_icons">
                    <div class="cursor" v-show="itme.website" @click="openUrl(itme.website)" v-for="(itme, index) in campaignSocialMedias" :key="index">
                        <GI :src="itme.socialMediaLogo" ws="24" hs="24"></GI>
                    </div>
                </div>
            </div>
        </div>
        <FrameFoot />
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        // 商户媒体信息
        campaignSocialMedias() {
            try {
                // return [
                //     {
                //         id: 1,
                //         title: 'Discord',
                //         logo: 'https://oss.nftwerks.io/nft/2023/07/14/b2afc00d2bac4c85b62905ff9da2b137.svg',
                //         url: 'https://discord.com/',
                //         status: 0,
                //         createTime: '2023-05-17 14:24:20',
                //         updateTime: '2023-07-14 18:30:09',
                //         website: 'www.baodu.com',
                //     },
                //     {
                //         id: 2,
                //         title: 'Twitter',
                //         logo: 'https://oss.nftwerks.io/nft/2023/07/14/bce5c44ed8244de69d8dfe5ba4812c2b.svg',
                //         url: 'https://www.twitter.com',
                //         status: 0,
                //         createTime: '2023-05-17 14:24:20',
                //         updateTime: '2023-07-14 18:30:18',
                //         website: 'www.baodu.com',
                //     },
                //     {
                //         id: 4,
                //         title: 'Tiktok',
                //         logo: 'https://oss.nftwerks.io/nft/2023/07/14/3de04bc8778a4c4b8be4f93d98c95eb1.svg',
                //         url: 'https://www.tiktok.com/',
                //         status: 0,
                //         createTime: '2023-07-05 15:50:47',
                //         updateTime: '2023-07-14 18:30:31',
                //         website: 'www.baodu.com',
                //     },
                //     {
                //         id: 5,
                //         title: 'Instagram',
                //         logo: 'https://oss.nftwerks.io/nft/2023/07/14/3aff1dc5d4c64a3ab2539dae9115cccf.svg',
                //         url: 'https://www.instagram.com/',
                //         status: 0,
                //         createTime: '2023-07-05 15:51:15',
                //         updateTime: '2023-07-14 18:31:09',
                //         website: 'www.baodu.com',
                //     },
                //     {
                //         id: 6,
                //         title: 'Facebook',
                //         logo: 'https://oss.nftwerks.io/nft/2023/07/14/192ac3c09d5b4920b67e3c981e7f601b.png',
                //         url: 'https://www.facebook.com/',
                //         status: 0,
                //         createTime: '2023-07-07 14:17:33',
                //         updateTime: '2023-07-14 18:31:44',
                //         website: 'www.baodu.com',
                //     },
                // ]
                return this.compaignDetail.campaignSocialMedias ? this.compaignDetail.campaignSocialMedias : {}
            } catch (error) {
                return {}
            }
        },
    },
    created() {
        this.detailGet()
        this.getCampaignOtherArt()
    },
    methods: {
        async detailGet() {
            try {
                let { dispatch } = this.$store
                if (this.$route.query.id == undefined) {
                    return
                }
                await dispatch('compaignDetail', {
                    id: this.$route.query.id,
                })
            } catch (err) {
                console.log(err)
            }
        },
        // 已上架的活动列表 newIn
        async getCampaignOtherArt() {
            try {
                let { dispatch } = this.$store
                let { id } = this.$route.query
                await dispatch('campaignOtherArt', { campaignId: id })
            } catch (error) {
                console.log(error)
            }
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.footer {
    /* background-image: url('../../../assets/image/brand/brand_share_bgc.png'); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 602px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 88px;

    .footer_title {
        margin-top: 79px;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 32px;
        line-height: 52px;
        color: var(--font_color);
    }

    .share_box {
        width: 424px;
        /* height: 100px; */

        .share_btns {
            margin-top: 24px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            column-gap: 24px;
        }

        .share_icons {
            margin: 24px auto;
            margin-top: 16px;
            // width: 120px;
            height: 28px;
            justify-content: center;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 24px;
        }
    }
}

@media only screen and (max-width: 1080px) {
    .footer {
        /* height: 500px; */
        padding-bottom: 90px;
    }

    .footer_title {
        text-align: center;
        margin-top: 97px !important;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 24px;
        line-height: 28px;
    }

    .share_box {
        width: 100% !important;
    }
    .share_btns {
        margin-top: 24px !important;
        margin-bottom: 0px !important;
        flex-direction: column;
        align-items: center;
        gap: 23px;
    }
    .share_icons {
        margin-top: 66px !important;
        margin-bottom: 0 !important;
    }
}
</style>
