let { saveNum, multiplication, division, charCompare } = require('h-token-staking/src/utils')
export default {
    // store state config mode
    globalUser() {
        return this.$store.state.user
    },
    // global computed
    lenArray() {
        return (list = []) => {
            try {
                return list.length
            } catch (err) {
                console.log(err)
                return 0
            }
        }
    },
    globalHelp() {
        return this.$store.state.help
    },
    globalMarket() {
        return this.$store.state.market
    },
    globalBrand() {
        return this.$store.state.brand
    },
    globalPerson() {
        return this.$store.state.person
    },

    // nftlist() {
    //     return this.globalPerson.nftlist
    // },
    compaignDetail() {
        return this.globalBrand.compaignDetail
    },
    myCompaign() {
        return this.globalBrand.myCompaign
    },
    campaignArtwork() {
        return this.globalBrand.campaignArtwork
    },
    removedArtwork() {
        return this.globalBrand.removedArtwork
    },
    brandClient() {
        return this.globalBrand.brandClient
    },
    campaignParticipants() {
        return this.globalBrand.campaignParticipants
    },
    joinCampaignList() {
        return this.globalBrand.joinCampaignList
    },
    selectCampaignWorks() {
        return this.globalBrand.selectCampaignWorks
    },
    userInfo() {
        return this.globalUser.userInfo
    },
    infoByUserId() {
        return this.globalUser.infoByUserId
    },
    globalConfig() {
        return this.$store.state.config
    },
    chainMapping() {
        return this.globalConfig.chainMapping
    },
    globalToken() {
        return this.globalConfig.globalToken
    },
    globalTheme() {
        return this.globalConfig.theme
    },
    globalTip() {
        return this.globalConfig.tips
    },
    headView() {
        return this.globalConfig.headView
    },
    isPhone() {
        return this.globalConfig.isPhone
    },

    socialMedia() {
        return this.globalConfig.socialMedia
    },
    globalImage() {
        return this.globalConfig.defaultConfig
    },
    nftCategory() {
        return this.globalConfig.nftCategory
    },
    tempVal() {
        return this.$store.state.tempVal
    },

    walletShow() {
        return this.$store.state.walletShow
    },
    langShow() {
        return this.$store.state.langShow
    },
    linkShow: {
        get() {
            return this.$store.state.linkShow
        },
        set() {},
    },
    loginShow: {
        get() {
            return this.$store.state.loginShow
        },
        set() {},
    },
    // 查找某个主链详情
    findChain() {
        return (key, value) => {
            let result = this.$store.getters.chainOf(key, value)
            return result ? result : {}
        }
    },
    // 连接的钱包详情
    walletDetail() {
        return this.$store.state.walletDetail
    },
    AssetStatus() {
        return this.$store.state.AssetStatus
    },
    platformContract() {
        return this.globalConfig.platformContract
    },
    findContractObj() {
        return (chainId) => {
            let result = this.platformContract.filter((item) => {
                return item.chainId == chainId
            })
            let obj = {}
            result.forEach((item) => {
                obj[item.name] = item.address
            })
            return obj
        }
    },
    // 汇率换算
    usdRate() {
        return (amount, symbolId) => {
            try {
                let usdRate = this.findSymbol(symbolId).usdRate
                return multiplication(amount, usdRate)
            } catch (err) {
                console.log(err)
                return 0
            }
        }
    },
    findSymbol() {
        return (id) => {
            let result = this.$store.getters.symbolOf('id', id)
            return result ? result : {}
        }
    },
    // 精确为正常大小
    toMain() {
        return (amount, symbolId) => {
            let precision = this.findSymbol(symbolId).precision
            return division(amount, Math.pow(10, precision))
        }
    },
    //
    toWei() {
        return (amount, symbolId) => {
            let precision = this.findSymbol(symbolId).precision
            return multiplication(amount, Math.pow(10, precision))
        }
    },
    addressCompare() {
        try {
            return (address_one, address_two) => {
                if (address_one && address_two) {
                    return address_one.toLocaleLowerCase() == address_two.toLocaleLowerCase()
                } else {
                    return false
                }
            }
        } catch (err) {
            console.log(err)
            return false
        }
    },
    globalThemeColor() {
        let str = ''
        if (this?.globalTheme == 'night') {
            str = 'theme_dark'
            return str
        }
        return
    }
}
