<template>
    <div class="global_tip">
        <div class="tip_list">
            <div :class="['tip_item', 'animated', time >= item.expire ? 'animate__backOutRight' : 'animate__backInRight', item.type]" v-for="(item, index) in globalTip" :key="index">{{ item.message }}</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            time: 0,
        }
    },
    mounted() {
        setInterval(() => {
            this.time = new Date().getTime()
        }, 1000)
    },
    computed: {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.global_tip {
    .tip_list {
        .tip_item {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            border-radius: 6px;

            margin-top: 12px;
            padding: 10px 15px;
            box-sizing: border-box;
            white-space: wrap;
            border: 1px solid rgba($color: #fff, $alpha: 0.05);
        }
        .error {
            background: var(--danger_color);
            color: #fff;
        }
        .danger {
            background: var(--danger_color);
            color: #fff;
        }
        .success {
            background: var(--success_color);
            color: #fff;
            background-color: rgba($color: #1fc7d4, $alpha: 1);
        }
        .warning {
            background: var(--warning_color);
            color: #fff;
        }
        width: 100%;
    }
    position: fixed;
    bottom: 20px;
    right: 20px;
    // width: 360px;
    height: auto;
    pointer-events: none;
    z-index: 30001000;
}
@media screen and (max-width: 450px) {
    .global_tip {
        left: 16px !important;
        right: 16px !important;
        bottom: 16px !important;
    }
}
@media screen and (min-width: 1029px){
    .global_tip{
        min-width: 320px !important;
    }
}
</style>
