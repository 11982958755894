<template>
  <div class="main"></div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
}
</style>
