<template>
    <div class="item">
        <div
            class="image_out"
            :style="{
                height: `${hs}px`,
                width: `${ws}px`,
            }"
        >
            <div v-if="item.isPrivate == 1" class="item_privacy">
                <GI name="brand_privacy_white" ws="20" hs="20"></GI>
            </div>
            <div
                class="item_image"
                :style="{
                    backgroundImage: `url(${item.banner})`,
                }"
                @click="jump_participant_compaign_preview({ id: item.id })"
            ></div>
        </div>

        <div class="item_info">
            <div class="item_title">
                {{ item.name }}
            </div>
            <div @click="jump_participant_campaign_participants_profileColl({ idUser: item.brandUserId })" class="item_belong">
                <div
                    class="belong_image"
                    :style="{
                        backgroundImage: `url(${brandUserVo.imageUrl})`,
                    }"
                ></div>
                <div class="belong_name">{{ brandUserVo.nickName }}</div>
            </div>
            <div class="item_desc">
                {{ item.description }}
            </div>
            <VI v-if="!isPhone" :participants="item.joinNum" :voters="item.voteNum" :days="item.endTime"></VI>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    computed: {
        // 商户信息
        brandUserVo() {
            try {
                return this.item.brandUserVo ? this.item.brandUserVo : {}
            } catch (error) {
                return {}
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.item {
    .item_info {
        .item_tags {
            .tag_item {
                margin-right: 12px;
                &:last-child {
                    margin-right: 0px;
                }
            }
            margin-top: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .item_desc {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 16px;
            color: var(--font_deep_color);

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .item_belong {
            .belong_name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_deep_color);
                margin-left: 8px;
            }
            .belong_image {
                height: 28px;
                width: 28px;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
            }
            margin-top: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .item_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        flex: 1;
    }

    .image_out {
        border-radius: 32px;
        overflow: hidden;
        margin-right: 16px;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        position: relative;
        .item_privacy {
            position: absolute;
            top:15px;
            left:15px;
            width: 40px;
            height: 40px;
            background-color: var(--danger_color);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 200;
        }
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
    display: flex;
}
@media only screen and (max-width: 1080px) {
    .item {
        .item_info {
            .item_tags {
                .tag_item {
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
                margin-top: 16px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            .item_desc {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                margin-top: 16px;
                color: var(--font_deep_color);

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            .item_belong {
                .belong_name {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--font_deep_color);
                    margin-left: 8px;
                }
                .belong_image {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    overflow: hidden;
                }
                margin-top: 8px;
                display: flex;
                align-items: center;
            }
            .item_title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 32px;
                color: var(--font_deep_color);

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            flex: 1;
        }

        .image_out {
            border-radius: 16px;
            overflow: hidden;
            margin-right: 16px;
            background-color: rgba($color: #ccc, $alpha: 0.2);
            .item_image {
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                transition: all 2s;
            }
            .item_image:hover {
                transform: scale(2);
            }
        }
        display: flex;
    }
}
</style>
