import { render, staticRenderFns } from "./MintNFT.vue?vue&type=template&id=b90e6d4e&scoped=true"
import script from "./MintNFT.vue?vue&type=script&lang=js"
export * from "./MintNFT.vue?vue&type=script&lang=js"
import style0 from "./MintNFT.vue?vue&type=style&index=0&id=b90e6d4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b90e6d4e",
  null
  
)

export default component.exports