<template>
    <div class="main">
        <div class="left_img" :style="loginBgUrl ? { backgroundImage: `url(${loginBgUrl})` } : {}"></div>

        <div class="right_box">
            <div class="theme_box">
                <Theme></Theme>
            </div>
            <div class="login_form">
                <div class="title">
                    <Logo></Logo>
                    <div class="title_content">
                        <h2>{{ langJson('Welcome to NFTWerks') }}</h2>
                        <p>{{ langJson('Please fill in these information') }}</p>
                    </div>
                </div>

                <div class="form">
                    <div class="form_input">
                        <FormItem v-model="form.name" class="information_item" type="text" :matchs="['unull', 'maxLength']" :title="langJson('Account name ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>

                        <FormItem v-model="form.email" class="information_item" type="text" :matchs="['unull', 'email']" :title="langJson('Email ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>

                        <FormItem v-model="form.password" class="information_item" type="password" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']" :title="langJson('Password ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>

                        <FormItem v-model="form.passwordAgin" class="information_item" type="password" :matchs="['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop']" :title="langJson('Confirm Password ')" :mobileColor="isPhone && globalThemeColor != 'theme_dark' ? 'var(--mobile_input_color)' : ''"></FormItem>
                    </div>
                    <div class="bottom_checkbox">
                        <CheckBox v-model="form.isAgreed"></CheckBox>

                        <div>
                            <span @click="readed" class="check_notice check_detail">{{ langJson('You agree with NFTWerks') }}</span>
                            <span class="check_click check_detail" @click="jump_article_detail({ articleId: 4 })">&nbsp;{{ langJson('Terms of Service') }}</span>
                            <span class="check_notice check_detail">&nbsp;{{ langJson('and') }}</span>
                            <!-- <br /> -->
                            <span class="check_click check_detail" @click="jump_article_detail({ articleId: 6 })">&nbsp;{{ langJson('Privacy Policy') }}</span>
                        </div>
                    </div>
                </div>
                <div class="bottom_confirm">
                    <BTN class="btn_item" :disable="!pass" :load="load_btn" @handle="signup" type="active">{{ langJson('Sign Up') }}</BTN>

                    <p class="border">
                        <span>{{ langJson('Or') }}</span>
                    </p>
                    <div :class="['text_item', loading ? 'active_text' : '']" @click="selectLogin">
                        <div class="img">
                            <GI class="icon_google" type="png" name="google" ws="24" hs="24" :noClickStop="true"></GI>
                        </div>

                        <div class="text">{{ langJson('Continue with Google') }}</div>
                    </div>

                    <div class="notice">
                        <span>{{ langJson('Already have account?') }}</span>
                        <span @click="jump_participant_login" style="cursor: pointer">{{ langJson('Log in') }}</span>
                    </div>
                </div>
            </div>
            <div class="learn_more">
                <div class="learm_more_box">
                    <span class="learn_text_1">{{ langJson('Powered by NFTWerks ') }}</span>
                    <div></div>
                    <span class="learn_text_2 cursor" @click="jump_page_path('/startup/index')">{{ langJson('Learn more ') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                email: '',
                name: '',
                password: '',
                passwordAgin: '',
                isAgreed: false,
            },
            loginBgUrl: '',
            loading: false,
        }
    },
    computed: {
        pass() {
            try {
                let { email, name, password, passwordAgin } = this.form
                let result = this.checkPass(name, ['unull', 'maxLength'], false) && this.checkPass(email, ['unull', 'email'], false) && this.checkPass(password, ['unull', 'overflow', 'haveupword', 'havelowword', 'havenum', 'haveldrop'], false) && password == passwordAgin && this.form.isAgreed

                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
    created() {
        // this.loginBgUrl = localStorage.getItem('loginBgUrl')
        // 重置 isGoogle
        localStorage.setItem('isGoogle', false)
    },
    methods: {
        async selectLogin() {
            if (this.loading) true
            this.loading = true

            try {
                let { dispatch } = this.$store
                await dispatch('authBinding', {
                    userType: 0,
                }).then(async (el) => {
                    if (el.code == 200) {
                        this.loading = false
                        localStorage.setItem('isGoogle', true)
                        window.open(el.data, '_self') //_blank _self
                    }
                })
            } catch (error) {}

            this.loading = false
        },
        async signup() {
            this.load_btn = true
            try {
                let { dispatch } = this.$store
                let { name, email, password } = this.form
                let lang = localStorage.getItem('Language')
                let parmas = {
                    userName: name,
                    email,
                    password,
                    userType: 0,
                    lang,
                    theme: this?.globalTheme == 'light' ? 0 : 1,
                }
                let result = await dispatch('signup', parmas)
                if (result && result.code == 200) {
                    this.jump_participant_login()
                } else {
                    this.pushMsg('error', this.langJson('fail to register'))
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('error', this.langJson('fail to register'))
            }
            this.load_btn = false
        },
        readed() {
            this.$set(this.form, 'isAgreed', !this.form.isAgreed)
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    width: 100%;
    display: flex;
    .left_img {
        height: 100vh;
        width: 50%;
        background-image: url('../../assets/image/login/bgc.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .right_box {
        flex: 1;
        padding-top: 40px;

        box-sizing: border-box;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: auto;
        .theme_box {
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            width: 100%;
            padding-right: 80px;
            /deep/ {
                .switch_theme {
                    background-color: var(--bg7) !important;
                }
            }
        }
        .login_form {
            box-sizing: border-box;
            width: 100%;
            max-width: 470px;
            padding: 32px;
            display: flex;
            margin: 44px auto;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            background: var(--color_white);
            border-radius: 32px;
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                .circle {
                    width: 80px;
                    height: 80px;
                    background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
                    border-radius: 50%;
                }
                .title_content {
                    p {
                        text-align: center;
                        color: var(--font_modif_color);
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    h2 {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 52px;
                        text-align: center;
                        color: var(--font_deep_color);
                    }
                }
            }
            .form {
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                .form_input {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
                .bottom_checkbox {
                    font-size: 14px;
                    min-height: 40px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    .check_detail {
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        cursor: pointer;
                        float: left;
                    }
                    .check_click {
                        color: var(--primary_color);
                        font-weight: 700 !important;
                    }
                    .check_notice {
                        color: var(--font_modif_color);
                    }
                }
            }
            .bottom_confirm {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 12px;
                .btn_item {
                    width: 100% !important;
                    height: 52px !important;
                }
                .notice {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: var(--font_modif_color);
                    span {
                        margin: 0 5px;
                    }
                }

                .border {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    &::before {
                        content: '';
                        box-sizing: border-box;
                        border-width: 0;
                        border-style: solid;
                        border-color: rgb(245, 246, 247);
                        border-top-width: 1px;
                        border-bottom-width: 1px;
                        width: 100%;
                        margin-right: 16px;
                    }
                    &::after {
                        content: '';
                        box-sizing: border-box;
                        border-width: 0;
                        border-style: solid;
                        border-color: rgb(245, 246, 247);
                        border-top-width: 1px;
                        border-bottom-width: 1px;
                        width: 100%;
                        margin-left: 16px;
                    }
                    span {
                        color: var(--font_modif_color);
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .text_item {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background-color: #1a73e8;
                    border-radius: 40px;
                    padding: 2px;
                    margin-top: 10px;
                    .img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 12px;
                        width: 44px;
                        height: 44px;
                        border-radius: 50%;
                        background-color: #fff;
                    }
                    .text {
                        flex: 1;
                        color: #fff;
                        text-align: center;
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .active_text {
                    cursor: no-drop;
                    background-color: #c9ccdd;
                }
            }
        }

        .learn_more {
            width: 100%;
            height: 20px;
            padding-right: 80px;
            box-sizing: border-box;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;
            .learm_more_box {
                width: 233px;
                height: 100%;
                display: flex;

                gap: 10px;
                align-items: center;
                .learn_text_1 {
                    color: var(--font_modif_color);
                }
                div {
                    height: 8px;
                    background: var(--color_white);
                    border: 1px solid var(--border_color);
                }
                .learn_text_2 {
                    line-height: 20px;
                    text-align: right;
                    color: var(--primary_color);
                }
                span {
                    white-space: nowrap;
                }
            }
        }
    }
    .notice {
        span:nth-child(2) {
            color: var(--primary_color);
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 1080px) {
    .title {
        gap: 12px !important;
        padding: 0px !important;
        .title_content {
            p {
                text-align: center;
                color: var(--font_modif_color);
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-top: 4px;
            }
            h2 {
                font-size: 24px !important;
                line-height: 40px !important;
            }
        }
    }

    .main {
        .right_box {
            flex: 1;
            .theme_box {
                padding-right: 20px;
            }
            .login_form {
                margin: 8px auto !important;
                background: var(--bg_color) !important;
                padding: 0px 20px;
                gap: 0px !important;
                .form_input {
                    margin-top: 50px;
                }
            }
        }
        .left_img {
            display: none;
        }
    }
    .bottom_confirm {
        margin-top: 36px;
    }
}
@media screen and (max-width: 400px) {
    .title_content {
        p {
            text-align: center;
            color: var(--font_modif_color);
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: 4px;
        }
        h2 {
            font-size: 24px !important;
            line-height: 40px !important;
        }
    }
    .right_box {
    }
    .login_form {
        max-width: 375px !important;
        margin: 8px auto !important;
        background: var(--bg_color) !important;
        /* padding: 0px !important; */
    }
    .learn_more {
        padding-right: 0px !important;
        justify-content: center !important;
    }
}
</style>
