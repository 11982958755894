import http from './base'

/**
 * 用户注册
 * @param userName
 * @param email
 * @param password
 * @param userType
 * @param lang
 * @param theme
 * @returns
 */
let signup = (params = {}) => {
    return http.postJson(`/user/signUp`, params)
}

/**
 * 用户注册
 * @param userName 用户名
 * @param password 密码
 * @param userType 0-参与者 1-品牌
 * @returns
 */
let login = (params = {}) => {
    return http.post(`/user/login`, params)
}

/**
 * 用户信息
 * @param userName
 * @param password
 * @returns
 */
let info = (params = {}) => {
    return http.get(`/user/info`, params)
}

/**
 * 用户信息
 * @param userId
 * @returns
 */
let infoByUserId = (params = {}) => {
    return http.get(`/user/infoByUserId`, params)
}

/**
 * 修改用户信息
 * @param userId
 * @param bannerUrl
 * @param nickName
 * @param bio
 * @param email
 * @returns
 */
let updateInfo = (params = {}) => {
    return http.postJson(`/user/update`, params)
}

/**
 * 修改用户密码
 * @param oldPassword
 * @param newPassword
 * @returns
 */
let updatePwd = (params = {}) => {
    return http.post(`/user/update/password`, params)
}

/**
 * 发送验证码
 * @param email
 * @returns
 */
let sendEmailCode = (params = {}) => {
    return http.post(`/user/getEmailCode`, params)
}

/**
 * 验证码验证
 * @param email
 * @param verifyCode
 * @returns
 */
let verifyCode = (params = {}) => {
    return http.post(`/user/confirm/verifyCode`, params)
}

/**
 * 新密码设置
 * @param email
 * @param verifyCode
 * @param newPassword
 * @returns
 */
let pwdReset = (params = {}) => {
    return http.post(`/user/retrieve/password`, params)
}

/**
 * 商户 邮箱 是否已经注册
 * @param email
 * @returns
 */
let userCheckSignInfo = (params = {}) => {
    return http.post(`/user/checkSignInfo`, params)
}
// /user/checkSignInfo?userName=1449813945&email=1449813945@qq.com

export default {
    userCheckSignInfo,
    signup,
    login,
    info,
    updateInfo,
    updatePwd,
    sendEmailCode,
    verifyCode,
    pwdReset,
    infoByUserId,
}
