<template>
    <div class="item">
        <div v-show="showHead" class="brand_detail">
            <div class="rank_vote" v-if="!isParticipant">{{ langJson('Brand ranking 1') }}</div>
            <div v-if="isParticipant"></div>
            <BtnPop :left="isPhone ? -80 : 0">
                <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                <GI slot="target" name="brand_more" ws="24" hs="24" class="pointer icon_"></GI>
                <div slot="main" class="type_main">
                    <div v-for="(item, index) in tagList" :key="index" class="type_content">
                        <div @click="goRoute(item)" class="type_line">
                            <GI ws="20" hs="20" :name="item.icon"></GI>
                            <span class="title">{{ item.val }}</span>
                        </div>
                    </div>
                </div>
            </BtnPop>
        </div>

        <div class="image_out" :style="{ height: `${hs}px`, width: `${ws}px` }">
            <div class="item_image cursor" :style="{ backgroundImage: `url(${item.preview})` }" @click="isParticipant ? jump_participant_profile_ntfdetail({ idNft: item.id, idUser: item.userId }) : jump_brand_profile_ntfdetail({ idNft: item.id, idUser: item.userId })">
                <div class="rank_vote2" v-show="item.contractType == 1155">x {{ item.amount }}</div>
            </div>
        </div>

        <div class="item_info">
            <div class="item_title">
                {{ item.name }}
                <!-- <br />
                <span class="item_subtitle">{{ langJson('@pimes') }}</span>
                <br />
                <div v-if="showBottom" class="compaigin_name">
                    <span class="artworks_for_compaign">{{ langJson('Campaign name') }}</span>
                    <span>{{ langJson('8 votes') }}</span>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ws: {
            type: [String, Number],
            default: '100%',
        },
        // 图片高度
        hs: {
            type: [String, Number],
            default: 'auto',
        },
        // 展示头部
        showHead: {
            type: Boolean,
            default: false,
        },
        // 展示底部按钮
        showBottom: {
            type: Boolean,
            default: false,
        },
        // 蓝字下划线
        showCompaign: {
            type: Boolean,
            default: false,
        }, // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            active: false,
            voteShow: false,
            tagList: [
                // { icon_dark: 'profile_hide', icon_light: 'profile_hide_light', val: 'Hide', number: 1 },
                // { icon_dark: 'brand_edit', icon_light: 'brand_edit_light', val: 'Edit', number: 2 },
                { icon: 'profile_share', val: this.langJson('Share'), number: 3 },
                // { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', number: 4 },
            ],
        }
    },
    methods: {
        goRoute(item) {
            let that = this
            switch (item.number) {
                case 1:
                    break
                case 2:
                    break
                case 3:
                    that.$store.commit('changeShowShare', true)
                    this.$store.commit('shareObj', {
                        ...this.globalConfig.shareObj,
                        img: this.item.preview,
                        title: this.langJson('Share your ArtWork'),
                        url: `/participant/profile/ntfDetail?idNft=${this.item.id}&idUser=${this.item.userId}`,
                        linkName: this.langJson('Artwork link'),
                    })
                    break

                default:
                    break
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.red {
    color: var(--danger_color) !important;
}
.item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    gap: 16px;

    .item_info {
        .item_desc {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_modif_color);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .item_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .item_subtitle {
            /* Body/1/Regular */
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_color);
        }
        .compaigin_name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .artworks_for_compaign {
                font-family: 'Inter';

                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                text-decoration-line: underline;
                color: #3993e6;
            }
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                /* Neutral/4 */

                color: var(--font_modif_color);
            }
        }

        flex: 1;
    }
    .brand_detail {
        display: flex;
        justify-content: space-between;
        height: 32px;
        .rank_vote {
            font-size: 12px;
            padding: 8px 16px;
            background: #ffeae0;
            border-radius: 32px;
            color: #ff9466;
            box-sizing: border-box;
            line-height: 16px;
            font-weight: 600;
        }
    }

    .image_out {
        border-radius: 12px;
        overflow: hidden;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        .item_image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            transition: all 2s;
            .rank_vote2 {
                position: absolute;
                top: 12px;
                right: 12px;
                font-family: 'Lato';
                font-size: 12px;
                width: 61px;
                background: #ffeae0;
                border-radius: 32px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                color: #ff9466;
                z-index: 10;
            }
        }
        .item_image:hover {
            transform: scale(2);
        }
    }
}

.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;
    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child {
            /* color: var(--danger_color); */
        }
        &:not(:last-child) {
            color: var(--font_deep_color);
        }
        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                white-space: nowrap;
                font-size: 14px;
                color: var(--font_deep_color);
                font-family: 'Lato';
            }
        }
    }
}
</style>
