<template>
    <div>
        <!-- 活动名 -->
        <editGo v-if="!isPhone" :status="status" :prev="compaignDetail.name" @prev="back" :descText="langJson('By ') + brandUserVo.nickName">
            <div class="right_go">
                <!-- <BTN icon_left="brand_trash" type="warn" bh="52" bw="198"  v-if="showBtn">{{ langJson('Delete campaign') }}</BTN> -->
                <!-- <BTN icon_left="eye" @handle="jump_brand_compaign_preview()" type="detail" bh="52" bw="131">{{ langJson('Preview') }}</BTN> -->
                <!-- <BTN type="active" v-if="status == 'live'" @handle="saveChanges" bh="52" bw="138">{{ langJson('Save changes') }}</BTN> -->
            </div>
        </editGo>
        <editGoMobile :tagList="tagList" v-if="isPhone" :status="status" :prev="langJson('back')" @prev="back" :lenArrayShow="lenArray(tagList) != 0 ? true : false">
            <div class="right_go">
                <BTN type="detail" v-if="status == 'live'" @handle="saveChanges" bh="40" bw="136">{{ langJson('Save changes') }}</BTN>
                <BtnPop v-if="status == 'archive'" :left="-100">
                    <!-- <Tag slot="target" type="more" icon="brand_more"></Tag> -->
                    <div slot="target" class="more_icon"><GI ws="24" hs="24" name="brand_more"></GI></div>
                    <div slot="main" class="type_main">
                        <div v-for="(item, index) in tagList" :key="index" class="type_content">
                            <div @click="goRoute(item)" class="type_line">
                                <GI ws="20" hs="20" :name="globalTheme == 'light' ? item.icon_dark : item.icon_light"></GI>
                                <span class="type_title">{{ item.val }}</span>
                            </div>
                        </div>
                    </div>
                </BtnPop>
            </div>
        </editGoMobile>

        <div class="line"></div>

        <div class="edit_preview">
            <h2 class="overview">{{ langJson('CAMPAIGN OVERVIEW') }}</h2>

            <div class="overview_box">
                <div @click="jump_brand_compaign_edit({ tab: 1, from: 'edit', id: $route.query.id })" class="overview_item">
                    <GI hs="56" ws="56" name="edit_basics"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Basics') }}</h2>
                        <span>{{ langJson('Name your project, upload cover, and establish your campaign details') }}</span>
                    </div>
                </div>
                <div @click="jump_brand_compaign_edit({ tab: 2, from: 'edit', id: $route.query.id })" class="overview_item">
                    <GI hs="56" ws="56" name="edit_rewards"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Rewards') }}</h2>
                        <span>{{ langJson('Set your rewards') }}</span>
                    </div>
                </div>
                <div @click="jump_brand_compaign_edit({ tab: 3, from: 'edit', id: $route.query.id })" v-if="status == 'archive'" class="overview_item">
                    <GI hs="56" ws="56" name="edit_clients"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Clients') }}</h2>
                        <span>{{ langJson('Set your rewards and shipping costs') }}</span>
                    </div>
                </div>
                <div @click="jump_brand_compaign_edit({ tab: 3, from: 'edit', id: $route.query.id })" class="overview_item">
                    <GI hs="56" ws="56" name="edit_links"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Links') }}</h2>
                        <span>{{ langJson('Add your website links and connect to your community') }}</span>
                    </div>
                </div>
                <div @click="jump_brand_compaign_edit({ tab: 4, from: 'edit', id: $route.query.id })" class="overview_item">
                    <GI hs="56" ws="56" name="edit_sticker"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Sticker library') }}</h2>
                        <span>{{ langJson('Add your brand’s stickers') }}</span>
                    </div>
                </div>
                <div @click="jump_brand_compaign_edit({ tab: 5, from: 'edit', id: $route.query.id })" class="overview_item">
                    <GI hs="56" ws="56" name="edit_basics"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Smart Contract Deployer') }}</h2>
                        <span>{{ langJson('Please deploy the contract') }}</span>
                    </div>
                </div>
                <div @click="jump_brand_compaign_edit({ tab: 6, from: 'edit', id: $route.query.id })" class="overview_item">
                    <GI hs="60" ws="60" name="edit_promotion_"></GI>
                    <div class="item_detail">
                        <h2>{{ langJson('Promotion') }}</h2>
                        <span>{{ langJson('Generate your campaign URL and invite participants') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <Model :title="from == 'edit' ? (isPhone ? langJson('Enter your password') : langJson('Enter your password to update your campaign')) : isPhone ? langJson('Enter your password') : langJson('Enter your password to submit your campaign')" :title2="isPhone ? (from == 'edit' ? langJson('to update your campaign') : langJson('to submit your campaign')) : ''" :padding="'24px 28px'" :headerGap="isPhone ? '36px' : '82px'" v-model="saveModel">
            <div class="notify_main" slot="main">
                <div class="notify_middle notify_common">
                    <FormItem type="password" :matchs="['haveword']" :title="langJson('Password')"></FormItem>
                </div>
                <div class="notify_detail notify_common">
                    <span>
                        {{ langJson('We’ll check to make sure it follows our Terms & Privacy.') }}
                        <br v-if="!isPhone" />
                        {{ langJson(' Please allow 24h for a response.') }}
                    </span>
                </div>
                <div class="notify_btns notify_common">
                    <BTN type="active" class="btn" :bh="isPhone ? '48' : '52'">{{ langJson('Confirm') }}</BTN>
                    <BTN type="detail" class="btn" :bh="isPhone ? '48' : '52'" @handle="cancelNotify">{{ langJson('Cancel') }}</BTN>
                </div>
            </div>
        </Model>
    </div>
</template>

<script>
export default {
    data() {
        return {
            status: this.$route.query.status,
            from: this.$route.query.from,
            saveModel: false,
            tagList: [
                // { icon_dark: 'brand_eye', icon_light: 'brand_eye_light', val: 'Preview', number: 1 },
                // { icon_dark: 'brand_trash', icon_light: 'brand_trash', val: 'Delete', number: 4 },
            ],
            showBtn: true,
        }
    },
    computed: {
        brandUserVo() {
            try {
                return this.compaignDetail.brandUserVo ? this.compaignDetail.brandUserVo : {}
            } catch (error) {
                return {}
            }
        },
    },
    created() {
        this.showBtn = true
        if (this.$route.query.from) {
            this.showBtn = false
        }
        if (this.$route.query.id) {
            this.detailGet()
        }
    },
    mounted() {},
    methods: {
        async detailGet() {
            try {
                let { dispatch } = this.$store
                await dispatch('compaignDetail', {
                    id: this.$route.query.id,
                })
            } catch (err) {
                console.log(err)
            }
        },
        saveChanges() {
            this.saveModel = true
        },
        closeNotify() {
            this.showWinner = false
        },
        cancelNotify() {
            this.saveModel = false
        },
        goRoute(item) {
            switch (item.number) {
                case 1:
                    this.jump_brand_compaign_preview({ isBrand: true })
                    break

                default:
                    break
            }
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.right_go {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}

.line {
    width: 100%;
    /* Neutral/5 */
    border-bottom: 1px solid var(--border_color);
}

.edit_preview {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    max-width: var(--max_width);

    .overview {
        margin-top: 24px;
        margin-bottom: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--font_deep_color);
    }

    .overview_box {
        width: 100%;
        border-radius: 32px;
        background: var(--color_white);

        .overview_item {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-left: 24px;
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--bg_color);
            box-sizing: border-box;
            cursor: pointer;

            .item_detail {
                padding-right: 24px;
                h2 {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--font_color_black);
                }

                span {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--font_color);
                }
            }
        }

        .overview_item:last-child {
            border-bottom: none;
        }
    }
}

.notify_main {
    width: 557px;

    box-sizing: border-box;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .notify_common {
        width: 100%;
    }

    .notify_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .notify_title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: var(--font_deep_color);
        }
    }
    .notify_detail {
        color: var(--font_modif_color);
    }
    .notify_btns {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        .btn {
            flex: 1;
        }
    }
}
.more_icon {
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.type_main {
    padding: 24px;
    background: var(--color_white);
    border: 1px solid var(--border_color);
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
    border-radius: 24px;
    cursor: pointer;

    .type_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child {
            color: var(--danger_color);
        }
        &:not(:last-child) {
            color: var(--font_deep_color);
        }

        .type_line {
            display: flex;
            align-items: center;
            gap: 8px;

            .type_title {
                white-space: nowrap;
                font-size: 14px;
                font-family: 'Lato';
            }
        }
    }
}
@media only screen and (max-width: 1080px) {
    .edit_preview {
        padding-bottom: 24px;
    }
    .notify_main {
        width: 74.4vw;
        gap: 24px !important;
        margin-top: 24px;
        .notify_top {
            .notify_title {
                font-size: 18px !important;
            }
            gap: 36px;
        }
        .notify_btns {
            flex-direction: column;
            gap: 20px !important;
        }
    }
    .overview {
        font-size: 12px !important;
        margin-top: 24px !important;
        margin-bottom: 12px !important;
    }
}
</style>
