<template>
    <div class="table_main">
        <div class="table_head">
            <div class="head_item" v-for="(item,index) in listKey" :key="index">
                {{title[item].title}}
            </div>
            <div v-if="play.length" class="head_item play">
                {{langJson("Operate")}}
            </div>
        </div>


        <div class="table_body">

            <div :class="['table_row',index % 2 != 0 ? 'row_active' : '']" v-for="(item,index) in list" :key="index">

             <!-- 表头 -->
                <div class="table_column" v-for="(subItem,subIndex) in listKey" :key="subIndex">
                    
                                
                               
                    <div class="text" v-if="title[subItem].type == 'text'">
                        {{list[index][subItem]}}
                    </div>


                    <div class="image" v-else-if="title[subItem].type == 'image'" :style="{
                        backgroundImage:`url(${list[index][subItem]})`
                    }"></div>

                    <div v-else>
                        <slot :name="subItem" :data="item"></slot>
                    </div>
                </div>


           <!-- 按钮，根据play数组和 -->
                <div v-if="play.length" class="table_column play">
                    <BTN class="btn_item" v-for="(subItem,subIndex) in play" @handle="triggleEvent(subItem.event,list[index])"
                    :key="subIndex" :type="subItem.type" size="mini" height="32px" :load="!!loadItem">{{subItem.title}}</BTN>
                </div>


            </div>
        </div>


        <div class="table_foot">
            <slot name="foot"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        list:{
            type:Array,
            default:() => {
                return []
            }
        },
        play:{
            type:Array,
            default:() => {
                return []
            }
        },
        title:{
            type:Object,
            default:() => {
                return {}
            }
        },
        loadItem:{
            type:[String,Number],
            default:""
        }
    },
    mounted(){
        Object.keys(this.$slots).forEach(item=>{
            // console.log(item)
        })
    },
    computed:{
        haveData() {
            return this.list && this.list.length
        },
        // title数组中的key值组成的表头数组
        listKey() {
            return Object.keys(this.title)
        }
    },
    methods:{
        triggleEvent(event,item) {
            this.$emit(event,item)
        }
    }
}
</script>
<style lang="scss" scoped>
.table_main{
    .table_body{
        .table_row{
            display: flex;
            .table_column{
                .link{
                    color:var(--success_color);
                    cursor: pointer;
                }
                .address{
                    color:var(--success_color);
                    cursor: pointer;
                }
                .image{
                    width:70px;
                    height:70px;
                    border-radius: 6px;
                    margin:10px 0;
                    overflow: hidden;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                }
                .text{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-family: 'Lato';
                }
                flex:1;
                min-height:50px;
                display: flex;
                align-items: center;
                min-width:120px;
                transition: all 1s;
                padding:0 10px;
                box-sizing: border-box;
                
            }
            .play{
                min-width:200px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .btn_item{
                    margin-left:10px;
                }
            }
            padding: 0 20px;
            transition: all 1s;
        }
        .row_active{
            background:var(--color_white)!important;
            transition: all 1s;
        }
        .table_row:hover{
            // .table_column{
            //     font-family: 'Lato';
            //     font-style: normal;
            //     font-weight: 400;
            //     font-size: 18px;
            //     line-height: 24px;
            //     color: var(--font_modif_color);
            // }
        }
        flex:1;
        overflow: auto;
    }
    .table_head{
        display: flex;
        .head_item{
            flex:1;
            min-width:120px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            font-size: 18px;
            line-height: 24px;
            color: var(--font_deep_color);
            display: flex;
            align-items: center;
            padding:16px 10px;
            box-sizing: border-box;
        }
        .play{
            min-width:200px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        background:var(--color_white);
        padding: 0 20px;
        overflow: auto;
    }
    background-color: rgba($color: #ccc, $alpha: 0.2);
    border-radius: 12px;
    box-sizing: border-box;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--font_modif_color);
    letter-spacing: 1px;
    overflow:hidden;
    
    height:100%;
    display: flex;
    flex-direction: column;
}

</style>