<template>
    <div class="collected">
        <div class="search_item">
            <div class="search_main">
                <div class="search">
                    <IN v-if="!isPhone" v-model="source.name" :placeholder="langJson('Search NFT')"></IN>
                    <Search v-if="isPhone" v-model="source.name"></Search>
                </div>
                <div class="sort">
                    <Sel :showBorder="true" v-model="source.orderByColumn" :list="sortlist"></Sel>
                </div>
            </div>
        </div>

        <div class="artworks_box">
            <div class="artworks_item" v-for="(item, index) of list" :key="index">
                <ArtworksNFT :item="item" :isParticipant="isParticipant" :showBottom="false" :showHead="false" hs="204"></ArtworksNFT>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        // 是 brand 还是 Participant
        isParticipant: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            viewEle: {
                price: false,
                status: true,
                play: false,
            },
            source: {
                name: '',
                orderByColumn: '',
            },
            sortlist: [
                {
                    lable: this.langJson('Sort By'),
                    value: '',
                },
                {
                    lable: this.langJson('Recently created'),
                    value: 'createTime',
                },
                // {
                //     lable: this.langJson('Number of votes'),
                //     value: 'voteNum',
                // },
            ],
        }
    },
    watch: {
        source: {
            handler(n) {
                this.$emit('change', n)
            },
            deep: true,
        },
    },
}
</script>
<style lang="scss" scoped>
.collected {
}
.search_item {
    .search_main {
        .sort {
        }
        .search {
            background-color: var(--color_white);
            border-radius: var(--nft_select_border_radius);
            /* border: 1px solid var(--border_color); */
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    padding-top: 32px;
    width: 100%;
    border-radius: 12px;
}
.artworks_box {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    .artworks_item {
        max-width: 296px;
        width: 100%;
        background: var(--color_white);
        box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
        border-radius: 16px;
        /deep/ .item{
            padding: 12px !important;
        }
    }
}
@media only screen and (max-width: 1080px) {
    .artworks_box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 24px;
        .artworks_item {
            background: var(--color_white);
            box-shadow: 4px 8px 24px rgba(187, 196, 221, 0.08);
            border-radius: 16px;
        }
    }
    .artworks_item {
        max-width: 100% !important;
        /* flex: 1; */
    }
    .search_item{
        padding-top: 24px;
    }
}
</style>
