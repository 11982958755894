<template>
    <div class="model_main">
        <div class="reset_form">
            <div class="form_main">
                <!-- 顶部 -->
                <div class="form_title">
                    <Logo></Logo>
                    <div class="title_info">
                        <h2>{{ langJson('Verify OTP') }}</h2>
                        <p>{{ langJson('Enter the verification code sent to your email') }}</p>
                    </div>
                </div>
                <!-- 输入框 -->
                <div class="form_input">
                    <FormItem v-model="form.code" class="information_item" 
                    type="text" :matchs="['unull']" :title="langJson('OTP CODE ')"></FormItem>
                </div>
                <!-- 底部 -->
                <div class="bottom_btn">
                    <!-- 判断是用户还是商户 -->
                    <BTN class="btn_item" @handle="verifyCode" :disable="!pass"
                    :bh="isPhone ? 48 : 52" :load="load_btn" type="active">{{ langJson('Confirm') }}</BTN>

                    <div v-if="countdown" class="time_notice">
                        <span class="notice">{{ langJson('Resend Code') }}</span>
                        <span class="time">({{ countdown }}s)</span>
                    </div>
                    <div v-else class="time_notice" @click="sendCode">
                        <span class="notice">{{ langJson('Send Code') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                code: ''
            },
            countdown: 0,
            time: null,
            lockSend: false,
        }
    },
    computed:{
        pass() {
            let { code } = this.form
            let result = this.checkPass(code, ['unull'], false)
            return result
        }
    },
    props: {
        
        email: {
            type: String,
            default: '',
        },
    },
    methods: {
        async verifyCode() {
            this.load_btn = true
            try{
                let { dispatch,commit } = this.$store
                let parmas = {
                    email: this.email,
                    verifyCode: this.form.code
                }
                let result = await dispatch('verifyCode', parmas)
                if (result && result.code == 200) {
                    
                    commit("tempVal",parmas)
                    this.jump_participant_resetpsd()
                }
            }catch(err) {
                console.log(err)
            }
            this.load_btn = false
        },
        async sendCode() {
            if (this.lockSend || this.countdown) return
            this.lockSend = true
            try {
                let { dispatch } = this.$store
                let result = await dispatch('sendEmailCode', {
                    email: this.email,
                })
                if (result && result.code == 200) {
					this.countdown = 59
                    this.time = setInterval(() => {
						
                        if(this.countdown > 0) {
                            this.countdown -= 1
                        }else{
                            clearInterval(this.time)
                        }
					}, 1000)
                }
            } catch (err) {
                console.log(err)
            }
            this.lockSend = false
        },
        // 重置密码，拿到验证码的时候需要返回一个字段判断是用户还是商户
        // 路由跳转
        jump() {
            this.jump_participant_resetpsd()
        },
        next() {
            this.$refs.step.next()
        },
        close() {
            this.$emit('close', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.reset_form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 406px;
    box-sizing: border-box;
    border-radius: 32px;
    .form_main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        width: 100%;
    }

    .form_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 32px;
        width: 100%;
        .title_info {
            p {
                color: var(--font_modif_color);
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-top: 4px;
            }
            h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 52px;
                text-align: center;
                color: var(--font_deep_color);
            }
        }
        .circle {
            width: 80px;
            height: 80px;
            background: linear-gradient(180deg, var(--modif_color) 0%, var(--main_color) 100%);
            border-radius: 50%;
        }
    }
    .form_input {
        width: 100%;
    }
    .bottom_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 4px;
        width: 100%;
        flex: none;
        order: 1;
        flex-grow: 0;
        .btn_item {
            width: 100% !important;
        }
        .time_notice {
            flex: 1;
            padding: 12px 0px;
            text-align: center;
            font-size: 18px;
            cursor: pointer;
            .notice {
                font-weight: 800;
                line-height: 28px;
                color: var(--primary_color);
            }
            .time {
                font-weight: 800;
                line-height: 28px;
                color: rgba(114, 118, 139, 1);
                margin-left:6px;
            }
        }
    }
}
@media screen and (max-width: 1081px) {
    .bottom_btn {
        gap: 0px !important;
        .time_notice {
            font-size: 14px !important;
            padding: 12px 0px 0px 0px !important;
            .notice {
                line-height: 20px !important;
            }
            .time {
                line-height: 20px !important;
            }
        }
    }

    .reset_form {
        width: 287px;
        gap: 20px !important;
        padding-top: 20px !important;
    }
    .form_main {
        gap: 20px !important;
    }
    .form_title {
        gap: 12px !important;
    }
    .title_info {
        h2 {
            font-size: 20px !important;
            line-height: 32px !important;
        }
    }
}
</style>
